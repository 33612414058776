import React, { useState, useEffect, useRef } from "react"
import { useTable, usePagination, useSortBy } from "react-table"
import { QueryClient, QueryClientProvider } from "react-query"
import RoleUtils from "../../util/RoleUtils"
import { PERMISSIONS } from "../../util/constants"
import { EntityV2Column } from "../../components/ag-table/columns"
import EntityApi from "../../state/entities-api"
import ReactTablePagination from "../../components/ag-table/ReactTablePagination"
import TableFilter from "../../components/ag-table/TableFilter"
import { ImCancelCircle } from "react-icons/im"
import { Sorting } from "../../components/ag-table/ag-sorting"
import {
  ENTITY_STATUS,
  ENTITY_TYPE,
  CUSTOMER_CATEGORY,
  FREGIHT_FORWARD_TYPE,
  VENDOR_CATEGORY,
  VENDOR_CATEGORY_NONIN,
} from "../../util/constants"
import EntityV2Api from "../../state/entities-api"
import EntityUtils from "../../util/entityUtils"
import {
  reducer,
  PAGE_CHANGED,
  PAGE_SIZE_CHANGED,
  PAGE_SORT_CHANGED,
  PAGE_FILTER_CHANGED,
  TOTAL_COUNT_CHANGED,
  PAYLOAD_CHANGED,
} from "../../components/ag-table/ag-reducer"
import ApiAgTable from "../../components/ag-table/api_ag-table"
import {
  AgmodalStore,
  EntityV2Store,
  configStore,
  customerStore,
  entityStore,
  loginStore,
  routerStore,
} from "../../state/CommonState"
import { useSnapshot } from "valtio"
import { DtStore } from "../../dt/DtStore"
import { loader } from "../../util/loader"
import DtApi from "../../state/dt-api"
import TableHead from "../../util/dataTable";
import Utils from "../../util/Utils"
import { EntityTypeV2 } from "../../state/Types"
import Approval_Apis from "../../state/approval-api"
import AgNewModal from "../../components/AgnewModal"

const queryClient = new QueryClient()

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 20,
  totalCount: 0,
  queryPageFilter: "",
  queryPageSortBy: [],
  queryPayload: {},
}

const DataTable = (props) => {
  useSnapshot(DtStore)
  useSnapshot(loader)
  useSnapshot(entityStore)
  useSnapshot(routerStore)
  useSnapshot(AgmodalStore)

  const [customerlist, setCustomerlist] = useState([])

  const [selectedEntityType, SetSelectedEntityType] = useState(`all`)
  const [SelectedCategory, SetSelectedCategory] = useState(`all`)
  const [selectedStatus, SetSelectedStatus] = useState(`all`)
  const [deactivatePopup, SetdeactivatePopup] = useState(false)
  const [activityStatus, SetactivityStatus] = useState("")
  const [deactivateEntitydata, SetdeactivateEntitydata] = useState({})
  const [appbtnstatus, setappbtnstatus] = useState(true)
  const [StateOFBOX, setStateOFBOX] = useState(false)
  
  const [selectedFreightforward, SetselectedFreightforward] = useState(`all`)
  const [selectedCountry, SetselectedCountry] = useState(`all`)
  const [DeactivateRemark, setDeactivateRemark] = useState("")
  const [ch, setCh] = useState(0)
  const [listcountry, setlistcountry] = useState([])
  const changech = (data) => {
    if (ch == 0) {
      setCh(1)
    } else {
      setCh(0)
    }
  }

  let column = EntityV2Column

  const [keyword, setKeyword] = useState("")
  const [data1, setData1] = useState({ results: [] })
  const onClickFilterCallback = (filter) => {
    setKeyword(filter)
  }
  let columns = column
  const [
    {
      queryPageIndex,
      queryPageSize,
      totalCount,
      queryPageFilter,
      queryPageSortBy,
      queryPayload,
    },
    dispatch,
  ] = React.useReducer(reducer, initialState)

  useEffect(() => {
    if(queryPayload.status && AgmodalStore.apiCall){
      loader.show()
      ApiAgTable.entitiesV2Data(
        queryPageIndex,
        queryPageSize,
        queryPageFilter,
        queryPageSortBy,
        queryPayload
      ).then((res) => {
        res
          .clone()
          .json()
          .then(
            (res) => {
              if (JSON.stringify(data1.results) != JSON.stringify(res.results)) {
                if (JSON.stringify(data1) != JSON.stringify(res) && res.results && res.results.length > 0) {
                  // const filteredRes = res?.results?.filter(entity => entity._id !== "TBA");
                  // console.log("FILT",filteredRes);
                  // // res.count = res.count;
                  // res.results = filteredRes
                  setData1(res)
                }else{
                  setData1({ count: 0, results: [] });
                }
              }
              loader.hide()
            },
            (err) => {
              console.log(err)
            }
          )
      })
    }
  }, [
    queryPayload,
    queryPageIndex,
    queryPageSize,
    queryPageFilter,
    queryPageSortBy,
    AgmodalStore.apiCall
  ])

  const totalPageCount = Math.ceil(totalCount / queryPageSize)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data: data1?.results || [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: queryPageSortBy,
      },
      manualPagination: true,
      pageCount: data1 ? totalPageCount : null,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  )

  const manualPageSize = []

  useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex })
  }, [pageIndex, gotoPage])

  useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize })
    gotoPage(0)
  }, [pageSize, gotoPage])

  useEffect(() => {
    dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy })
    gotoPage(0)
  }, [sortBy, gotoPage])

  useEffect(() => {
    if(keyword.length === 0){
      dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword })
      gotoPage(0)
    }
  }, [keyword])

  const onClickSearch = () => {
    if (keyword.length > 0) {
      dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword });
      gotoPage(0);
    }else{
      configStore.setModalMessage(`Please Provide Valid Input`)  
    }
  };
  React.useEffect(() => {
    if (data1?.count) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data1.count,
      })
    }
  }, [data1?.count])

  useEffect(() => {
    dispatch({
      type: PAYLOAD_CHANGED,
      payload: {
        type: selectedEntityType,
        category: SelectedCategory,
        status: selectedStatus,
        frieghtforward: selectedFreightforward,
        countryfilter: selectedCountry,
        date: Utils.getCurrentDateString(),
      },
    })
    gotoPage(0)
  }, [
    selectedEntityType,
    SelectedCategory,
    selectedStatus,
    selectedFreightforward,
    selectedCountry,
    ch,
  ])

  const handleDeactivatePopup = async (e, r) => {
    console.log(e);
 await Approval_Apis.apiCHECKDEACTIVATE(e.entityId,(d)=>{
  console.log(d);
  if (d?.available) {
    configStore.setModalMessage(d?.message)
  }
  else{
  SetdeactivatePopup(true)
  SetdeactivateEntitydata(e)
  SetactivityStatus(r)
  setDeactivateRemark("")
  document.getElementById("listEntitiespg").style.filter = "blur(15px)"
  document.getElementById("listEntitiespg").style.pointerEvents = "none"
  }
 })
  }
  const cancelpopup = () => {
    SetdeactivatePopup(false)
    SetdeactivateEntitydata({})
    setappbtnstatus(true)
    document.getElementById("listEntitiespg").style.filter = "none"
    document.getElementById("listEntitiespg").style.pointerEvents = "auto"
  }
  const handleDeactivateClick = async () => {
    if (activityStatus === "DEACTIVATE") {
      loader.show()
      deactivateEntitydata.deactivatedReason  = DeactivateRemark
      console.log(deactivateEntitydata);
      await EntityApi.deactivateEntity(deactivateEntitydata.entityId, changech)
      await EntityApi.updateEntityRemarks(deactivateEntitydata, changech)
      loader.hide()
    } else if (activityStatus === "REACTIVATE") {
        // const check = await deactivateEntitydata.timeline.find((d)=>d.event === "Created")
        // const checkrb = await deactivateEntitydata.timeline.find((d)=>d.event === "Resubmited")
        const check2 = await deactivateEntitydata.timeline.find((d)=>d.event === "Verified")
        // console.log(check);
      if (check2) {
        deactivateEntitydata?.timeline.push({
        date: Date.now(),
        event: "Resubmited",
        user: loginStore.email,
        role: RoleUtils.getUserRole()
      })
      if (deactivateEntitydata?.timeline.length === 0) {
        deactivateEntitydata.timeline=[{
          date: Date.now(),
          event: "Created",
          user: loginStore.email,
          role: RoleUtils.getUserRole()
        }]
      }
      console.log(deactivateEntitydata);
      await EntityApi.updateEntityTimeLine(deactivateEntitydata);
     }
      // }

      loader.show()
      await EntityApi.reactivateEntity(deactivateEntitydata.entityId, changech)
      loader.hide()
    } else if (activityStatus === "DELETE") {
      loader.show()
      await EntityApi.deleteEntity(deactivateEntitydata.entityId, changech)
      loader.hide()
    }
    cancelpopup()
  }
  const approveBtnnfn = (e,status) => {
    setStateOFBOX(e.target.checked)
    if (e.target.checked == true && (status ==="REACTIVATE" || status ==="DELETE")) {
      document.getElementById("approvebtnid").style.opacity = "1"
      setappbtnstatus(false)
    } else if (e.target.checked == false && (status ==="REACTIVATE"|| status ==="DELETE")) {
      document.getElementById("approvebtnid").style.opacity = "0.4"
      setappbtnstatus(true)
    }
    else if (e.target.checked == true && DeactivateRemark.trim().length > 3 && status ==="DEACTIVATE") {
      document.getElementById("approvebtnid").style.opacity = "1"
      setappbtnstatus(false)
    }
    else if ((e.target.checked == false || DeactivateRemark.trim().length < 3) && status ==="DEACTIVATE") {
      document.getElementById("approvebtnid").style.opacity = "0.4"
      setappbtnstatus(true)
    }
    // else if () {
      
    // }
  }

  const getCountrylist = async () => {
    //console.log("listcountry",listcountry)
    //if (Object.keys(vehiclelist).length == 0) {
    await EntityV2Api.getAllCountrys(setlistcountry)

    // console.log("gkvkd-", listcountry);
    // }
  }
  console.log("outli-----", listcountry)
  let sortedCountries1 = []
  for (let i = 0;i < listcountry.length;i++) {
    sortedCountries1.push(listcountry[i].entityCountry)
  }
  let sortedCountries2 = sortedCountries1.sort()
  // console.log("sutli-----", sortedCountries2);

  useEffect(() => {
    // if(!listcountry.length ){
    getCountrylist()
    // setlistcountry(listcountry);
    // }
  }, [])
  return (
    <>
      <div className="" id="listEntitiespg">
        <h1 className="title">Entities</h1>

        <nav className="level">
          <div className="level-left">
            <div className="level-item">
              <p className="subtitle is-5">{data1?.count} entities</p>
            </div>
            {(RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_ENTITYV2)) &&
              <p className="level-item">
                <button
                  className="button is-link"
                  onClick={() => {
                    EntityV2Store.EntityRec.set(new EntityTypeV2())
                    AgmodalStore.modalPage = "NewEntity";
                    AgmodalStore.pageVisible = true;
                    AgmodalStore.apiCall = false;
                  }}>
                  New
                </button>
              </p>
            }
            <div class="level-item mr-4">
              <TableFilter
                onClickSearch={onClickSearch}
                onClickFilterCallback={onClickFilterCallback}
                defaultKeyword={keyword}
              />
            </div>
          </div>
          <div class="level-item level">
            <div className="columns is-multiline is-vcentered" style={{ width: "50em" }}>
              <div className="column is-one-fifth">
                <div class="field" style={{ width: "25em" }} >
                  <p class="control has-icons-left has-icons-right">
                    <span class="select" style={{ width: "9em" }}>
                      <select
                        name="status"
                        id="status"
                        value={selectedCountry}
                        onChange={(e) => {
                          SetselectedCountry(e.target.value)
                          //  SetSelectedCategory("all");
                        }}>
                        <option value="all">Country</option>
                        {
                          (sortedCountries2.map((r) => {
                            return (

                              <option key={r}>

                                {r}
                              </option>

                            )
                          }))
                        }
                        {/* {sortedCountries2?.map(
                          ({ entityCountry }) => {
                            // console.log("lisk-",listcountry)
                            return (
                              <option key={entityCountry}>
                                {entityCountry}
                              </option>
                            )
                          }

                          // setlistcountry(listcountry)
                        )} */}

                        {/* {listcountry?.map(obj =>(
                     
                     <option >{obj.entityCountry}</option>
                   ),
                   
                   
                   )}      */}
                      </select>

                      <span class="icon">
                        <i class="fa-solid fa-filter"></i>
                      </span>
                    </span>
                  </p>
                </div>
              </div>

              <div className="column is-one-fifth">
                <div class="field">
                  <p class="control has-icons-left has-icons-right">
                    <span class="select">
                      <select
                        name="status"
                        id="status"
                        value={selectedEntityType}
                        onChange={(e) => {
                          SetSelectedEntityType(e.target.value)
                          SetSelectedCategory("all")
                        }}>
                        <option value="all">Entity Type</option>
                        {Object.entries(ENTITY_TYPE).map(([key, value]) => (
                          <option value={value}>{key}</option>
                        ))}
                      </select>
                      <span class="icon">
                        <i class="fa-solid fa-filter"></i>
                      </span>
                    </span>
                  </p>
                </div>
              </div>
              <div className="column is-one-fifth">
                <div class="field" style={{ width: "105%" }}>
                  <p class="control has-icons-left has-icons-right">
                    <span class="select">
                      <select
                        name="status"
                        id="status"
                        value={SelectedCategory}
                        disabled={selectedEntityType === "all"}
                        onChange={(e) => SetSelectedCategory(e.target.value)}>
                        <option value="all">Entity Category</option>
                        {(selectedEntityType === "Customer"
                          ? Object.entries(CUSTOMER_CATEGORY)
                          : null
                        )?.map(([key, value]) => (
                          <option key={value} value={value}>
                            {key}
                          </option>
                        ))}
                        {(selectedEntityType === "Vendor"
                          ? Object.entries(VENDOR_CATEGORY_NONIN)
                          : null
                        )?.map(([key, value]) => (
                          <option key={value} value={value}>
                            {key}
                          </option>
                        ))}
                      </select>
                      <span class="icon">
                        <i class="fa-solid fa-filter"></i>
                      </span>
                    </span>
                  </p>
                </div>
              </div>

              {SelectedCategory === "Freight Forwarder" ? (
                <div className="column is-one-fifth">
                  <div class="field" style={{ width: "100%" }}>
                    <p class="control has-icons-left has-icons-right">
                      <span class="select">
                        <select
                          name="status"
                          id="status"
                          value={selectedFreightforward}
                          onChange={(e) => {
                            SetselectedFreightforward(e.target.value)
                            // SetSelectedCategory("all");
                          }}>
                          <option value="all">Type</option>
                          {Object.entries(FREGIHT_FORWARD_TYPE).map(
                            ([key, value]) => (
                              <option value={value}>{key}</option>
                            )
                          )}
                        </select>
                        <span class="icon">
                          <i class="fa-solid fa-filter"></i>
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
              ) : null}
              <div className="column is-one-fifth">
                <div class="field">
                  <p class="control has-icons-left has-icons-right">
                    <span class="select">
                      <select
                        name="status"
                        id="status"
                        value={selectedStatus}
                        onChange={(e) => SetSelectedStatus(e.target.value)}>
                        <option value="all">Entity Status</option>
                        {Object.entries(ENTITY_STATUS).map(([key, value]) => (
                          <option value={key}>{value}</option>
                        ))}
                      </select>
                      <span class="icon">
                        <i class="fa-solid fa-filter"></i>
                      </span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </nav>

        <div className="box">
          <div className="table-container" >
            <table className="table is-fullwidth is-small ">
              <TableHead headerGroups={headerGroups} sort={true}/>
              <tbody
                className="entitiesV2List"
                style={{ fontSize: "0.9rem" }}
                {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        // eslint-disable-next-line no-lone-blocks
                        {
                          let e = cell["row"]["original"]
                          switch (cell["column"]["Header"]) {
                            case "Entity Code #":
                              return (
                                <td>
                                  <a
                                    style={e.entityType == 'Customer' ?
                                      !RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.ENTITIESV2_VIEW) ?
                                        { pointerEvents: 'none', opacity: 0.6 } : {}
                                      // || (e.entityType == 'Vendor' && e.vendor.vendorType == 'Road Transport' 
                                      : ((e?.vendor?.vendorType == 'Road Transport' && RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.ENTITIESV2_RT_VIEW)) || RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.ENTITIESV2_VENDOR_VIEW)) ? {} : { pointerEvents: 'none', opacity: 0.6 }}
                                    target="_blank"
                                    onClick={async (event) => {
                                      console.log(e)
                                      await EntityApi.viewEntity(e?.entityId)
                                      AgmodalStore.pageVisible = true
                                      AgmodalStore.modalPage = "viewEntity"
                                      event.stopPropagation()
                                    }}>
                                    {e?.entityId}
                                  </a>
                                </td>
                              )
                            case "Relationship Manager":
                              const salesPerson = EntityUtils.isCustomer(e)
                                ?  e.customer?.crossBorder?.relationshipManager || e.customer?.domesticTransport?.relationshipManager || "To be assigned"
                                : "To be assigned"
                              
                              return (
                                <td className="tooltipTitle" title={salesPerson?.length > 15 ? salesPerson : null}>
                                  <span>
                                    {salesPerson?.length > 15 ? `${salesPerson?.slice(0, 15)}...` : salesPerson}
                                  </span>
                                </td>
                              )
                            case "Customer Service":
                              const customerServicePerson = EntityUtils.isCustomer(e)
                                ? e.customer?.crossBorder?.customerService || e.customer?.domesticTransport?.customerService ||"To be assigned" 
                                : "To be assigned"
                              return (
                                <td className="tooltipTitle" title={customerServicePerson?.length > 15 ? customerServicePerson : null}>
                                  <span>
                                    {customerServicePerson?.length > 15 ? `${customerServicePerson?.slice(0, 15)}...` : customerServicePerson}
                                  </span>
                                </td>
                              )
                            case "Actions":
                              return (
                                <td>
                                  <div
                                    class="dropdown post-options is-right is-hoverable "
                                    onClick={null}
                                    style={
                                      !((e.entityType == 'Customer' && RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.CUSTOMER_ENTITYV2))
                                        || (e.entityType == 'Vendor' && e.vendor.vendorType == 'Road Transport' && RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.VENDOR_RT_ENTITYV2))
                                        || (e.entityType == 'Vendor' && e.vendor.vendorType != 'Road Transport' && RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.VENDOR_EXCEPTRT_ENTITYV2))) ?

                                        { pointerEvents: 'none', opacity: 0.6 } : {}}
                                  >
                                    <div class="dropdown-trigger">
                                      <button
                                        class="button"
                                        aria-haspopup="true"
                                        aria-controls="dropdown-menu-post">
                                        <span class="icon is-small">
                                          <i
                                            class="fas fa-ellipsis-h"
                                            aria-hidden="true"></i>
                                        </span>
                                      </button>
                                    </div>
                                    {e?.status !=="PENDING"?<div
                                      class="dropdown-menu"
                                      id="dropdown-menu-post"
                                      role="menu">
                                      <div class="dropdown-content">
                                        {/* <a
                                          class="dropdown-item"
                                          onClick={async () => {
                                            console.log(e);
                                            await EntityApi.viewEntity(e.entityId);
                                            routerStore.entityCurrentViewNew =
                                              "view";
                                          }}
                                        >
                                          View
                                        </a> */}
                                        {e?.status !==
                                          ENTITY_STATUS.DEACTIVATED && e?.status !== ENTITY_STATUS.PENDING ? (
                                          <a
                                            class="dropdown-item"
                                            onClick={async () => {
                                              EntityV2Store.EntityRec.set(new EntityTypeV2())
                                              await EntityApi.viewEntity(
                                                e.entityId
                                              )
                                              AgmodalStore.modalPage = "editEntity";
                                              AgmodalStore.pageVisible = true;
                                              AgmodalStore.apiCall = false;
                                            }}>
                                            Modify
                                          </a>
                                        ) : null}
                                        {e?.status ===
                                          ENTITY_STATUS.INCOMPLETE ? (
                                          <a
                                            class="dropdown-item"
                                            // onClick={async () => {
                                            //   loader.show();
                                            //   await EntityApi.deleteEntity(
                                            //     e.entityId,
                                            //     changech
                                            //   );
                                            //   loader.hide();
                                            // }}
                                            onClick={() =>
                                              handleDeactivatePopup(e, "DELETE")
                                            }>
                                            Delete
                                          </a>
                                        ) : null}
                                        {e?.status === ENTITY_STATUS.VERIFIED  ? (
                                          <a
                                            class="dropdown-item"
                                            onClick={() =>
                                              handleDeactivatePopup(
                                                e,
                                                "DEACTIVATE"
                                              )
                                            }>
                                            Deactivate
                                          </a>
                                        ) : null}

                                        {e?.status ===
                                          ENTITY_STATUS.DEACTIVATED ? (
                                          <a
                                            class="dropdown-item"
                                            onClick={() =>
                                              handleDeactivatePopup(
                                                e,
                                                "REACTIVATE"
                                              )
                                            }>
                                            Reactivate
                                          </a>
                                        ) : null}
                                      </div>
                                    </div>:null}
                                  </div>
                                </td>
                              )
                            default:
                              return (
                                <td {...cell.getCellProps()}>
                                  <span>{cell.render("Cell")}</span>
                                </td>
                              )
                          }
                        }
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <ReactTablePagination
              page={page}
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageSize={pageSize}
              pageIndex={pageIndex}
              pageCount={pageCount}
              setPageSize={setPageSize}
              manualPageSize={manualPageSize}
              dataLength={totalCount}
              rows={rows}
            />
          </div>
        </div>
      </div>
      {deactivatePopup ? (
        <div
          style={{
            width: "450px",
            // height: "150px",
            backgroundColor: "#f2f2f2",
            borderRadius: "10px",
            border: "1px solid black",
            position: "absolute",
            top: "40%",
            left: "43%",
            padding: "1% 1% 1% 1%",
          }}>
          <div className="is-flex is-justify-content-space-between">
            <span className="is-size-5"> {activityStatus} </span>
            <span>
              <ImCancelCircle onClick={cancelpopup} className="is-clickable" />
            </span>
          </div>

          <div className="is-flex is-justify-content-space-between">
            <span
              className="is-size-6 is-flex is-align-items-center"
              style={{ marginTop: "0%", width: "85%" }}>
              <p>
                Are you sure you want to {activityStatus}{" "}
                <span className="has-text-weight-bold">
                  {deactivateEntitydata.entityName} --{" "}
                  {deactivateEntitydata.entityId}{" "}
                </span>{" "}
                ?
              </p>
              <span>
                <input
                  type="checkbox"
                  className="is-size-2"
                  style={{ width: "20px", height: "18px" }}
                  onChange={(e) => approveBtnnfn(e,activityStatus)}
                  id="approveCkboxid"
                />
              </span>
            </span>
          </div>
          {activityStatus==="DEACTIVATE"?<>
          <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
          <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Remarks</label>
          <input style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px",padding:"0.75rem"}} type="text" value={DeactivateRemark} required placeholder="Remarks" onChange={(e)=>{
            setDeactivateRemark(e.target.value)
            if (e.target.value.trim().length >  3 && e.target.value.trim() !== "" && StateOFBOX) {
              document.getElementById("approvebtnid").style.opacity = "1"
              setappbtnstatus(false)
            }else{
              document.getElementById("approvebtnid").style.opacity = "0.4"
              setappbtnstatus(true)
            }
            }}></input>
          </div>
          </>:null}
          <div>
            <span>
              <button
                className="is-clickable"
                style={{
                  backgroundColor: "#00ccff",
                  border: "none",
                  padding: "2%",
                  borderRadius: "8px",
                  color: "white",
                  fontWeight: "600",
                  marginTop: "4%",
                  marginLeft: "80%",
                  opacity: 0.4,
                }}
                id="approvebtnid"
                disabled={appbtnstatus}
                onClick={handleDeactivateClick }>
                SUBMIT
              </button>
            </span>
          </div>
        </div>
      ) : null}
    </>
  )
}

const EntitiesList = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <DataTable />
    </QueryClientProvider>
  )
}

export default EntitiesList
