import { subscribe, useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import { AgmodalStore, bookingsStore, configStore, loginStore } from "../state/CommonState";
import TaskDatetime from "./task-datetime";
import Taskfileapproval, { validateFileApproval } from "./task-file-approval";
import Taskfiledisplay from "./task-file-display";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
import MonApi from "../state/mongo-api";
import Utils from "../util/Utils";
import {  OneLabel3 } from "./act-common";
import Api from "../state/Api";

export default function Lclao(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    useSnapshot(AgmodalStore)
    let task = taskStore.current
    let stage = task.stage

    if (stage >= 1) {
        return (
            <div className="box">
                <p>Please download your Carting Order</p>
                <p>Please note that the same is Valid Till {task.validTill}</p>
                <Taskfiledisplay />
                {task.stuffing_cutoff!==''&&(<><OneLabel3 label='Stuffing Cutoff' name='stuffing_cutoff' /><br/></>)}
                {task.cargo_cutoff!==''&&(<><OneLabel3 label='Cargo Cutoff' name='cargo_cutoff' /><br/></>)}
                {bookingsStore.current.contract.fbaOCC === 'No'&&task.leo_cutoff!==''?
                <OneLabel3 label='LEO Cutoff' name='leo_cutoff' />:<></>}
                
                
                <p>
                    <span>
                        {
                            (stage === 1) ?
                                <button style={{ float: 'left' }} className="button islink"
                                    onClick={async (e) => {
                                        taskStore.current.stage = 2
                                        taskStore.current.pendingWith = 'AGRAGA'
                                        taskStore.setComplete()
                                        taskStore.setCustomerComplete()
                                        await Taskapi.updateTask(taskStore.current)
                                        taskStore.triggerActionVisible = false
                                        taskStore.myActionVisible = false
                                        if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                            AgmodalStore.pageVisible = false
                                            AgmodalStore.apiCall = true
                                        }
                                    }}>Acknowledge</button>
                                : <></>
                        }
                    </span>
                </p>
            </div>
        )
    }
}
export function LclaoTrigger(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    const [disableButton, setDisableButton] = useState(true)
    const getFormattedDateTime = (dateTime,data) => {
        const now = new Date(dateTime); 
        const pastDate = new Date(now.getTime() - data * 60 * 60 * 1000); 
      
        const day = String(pastDate.getDate()).padStart(2, '0');
        const month = String(pastDate.getMonth() + 1).padStart(2, '0'); 
        const year = pastDate.getFullYear();
      
        const hours = String(pastDate.getHours()).padStart(2, '0');
        const minutes = String(pastDate.getMinutes()).padStart(2, '0');
        const seconds = String(pastDate.getSeconds()).padStart(2, '0');
      
        return `${year}-${month}-${day}T${hours}:${minutes}`;      
    };
    useEffect(() => {
        async function fetch(){
            const ret =  await MonApi.apost(`${process.env.REACT_APP_API_SERVER}/api/v1/common/executequery/Bookingdsr`,{filter:{_id:task.bookingNum},project:{}})
            task.stuffing_cutoff = ret[0]?.stuffing_cutoff??''
            task.cargo_cutoff = ret[0]?.cargo_cutoff??''
            task.leo_cutoff = ret[0]?.leo_cutoff??''             
        }
        fetch()
        let unsubscribe = subscribe(taskStore.current, () => {
            let disable = false
            if(!disable){
                let checkfile = []
                if(taskStore.current.files.length==0){
                    disable = true
                }
                taskStore.current.files.map((e) =>{
                    if(e.filename.length==0){
                        disable = true
                    }
                    if(checkfile.indexOf(e.label) == -1){
                        if(taskStore.current.original_file.indexOf(e.label) != -1 && e.filename.length==0){
                            disable = true
                        }else{
                            if(taskStore.current.original_file.indexOf(e.label) != -1){
                                checkfile.push(e.label)
                            }
                        }
                    }
                })
            }
            if(task.stuffing_cutoff==''){
                disable = true
            }
            setDisableButton(disable)
        })
        return unsubscribe
    }, [taskStore.current])
    const fields = ['stuffing_cutoff','leo_cutoff','cargo_cutoff']
    const close = async()=>{
        taskStore.current.stage = 1
        taskStore.current.pendingWith = 'CUSTOMER'
        taskStore.current.fbaOCC = bookingsStore.current.contract.fbaOCC
        fields.forEach(e=>{
            if(taskStore.current[e]!==''){
             let date = new Date(taskStore.current[e])
             const dd = String(date.getDate()).padStart(2, '0');
             const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
             const yyyy = date.getFullYear();
             const hours = String(date.getHours()).padStart(2, '0');
             const minutes = String(date.getMinutes()).padStart(2, '0');
             taskStore.current[e] = `${dd}-${mm}-${yyyy} ${hours}:${minutes}`
             taskStore.oldtask[e] = `${dd}-${mm}-${yyyy} ${hours}:${minutes}`
            }
         })
        await Taskapi.saveTask(taskStore.current)
        taskStore.triggerActionVisible = false
        taskStore.myActionVisible = false
    }
    return (
        <>
            {/* <TaskDatetime /> */}
            <hr />
            <Taskfiletable />
            <hr />
            <table className="table is-fullwidth is-striped is-narrow" style={{ fontSize: '0.85rem' }}>
                <tr>
                    <td>Valid Till</td>
                    <td><input type='date' value={task.validTill} onChange={(e) => task.validTill = e.target.value} /></td>
                </tr>
            </table>
            <div className="columns gap-4" >
                <div className="field">
            <label className="label is-small" style={{ marginBottom: "0px" }}>
            Stuffing Cut-off 
            </label>
            <div className="control is-fullwidth is-small">
                    <input type='datetime-local' className="input is-small" value={task.stuffing_cutoff} onChange={(e) => {
                        const inputValue = e.target.value;
                        const datePart = inputValue.split("T")[0];
                        const fixedTime = "18:00";
                        if(datePart!==''){
                            task.stuffing_cutoff = `${datePart}T${fixedTime}`;
                        } else{
                            task.stuffing_cutoff =''
                        }
                        if(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(task.stuffing_cutoff)){
                            if(task.cargo_cutoff === '' && task.stuffing_cutoff!=='') task.cargo_cutoff = getFormattedDateTime(task.stuffing_cutoff,36)
                            if(task.leo_cutoff === '' && task.stuffing_cutoff!=''&&bookingsStore.current.contract.fbaOCC === 'No') task.leo_cutoff = getFormattedDateTime(task.stuffing_cutoff,24)
                        } 
                    }} />
            </div>
            </div>
            {bookingsStore.current.contract.fbaOCC === 'No'?<div className="field">
            <label className="label is-small" style={{ marginBottom: "0px" }}>
            LEO Cut-off 
            </label>
            <div className="control is-fullwidth is-small">
                    <input type='datetime-local' className="input is-small" value={task.leo_cutoff} onChange={(e) => task.leo_cutoff = e.target.value} />
            </div>
            </div>:<></> }                   
            <div className="field">
            <label className="label is-small" style={{ marginBottom: "0px" }}>
            Cargo Cut-off 
            </label>    
            <div className="control is-fullwidth is-small">
                    <input type='datetime-local' className="input is-small" value={task.cargo_cutoff} onChange={(e) => task.cargo_cutoff = e.target.value} />
            </div>
            </div>
            </div>
            <p>
                <button className="button islink" disabled={disableButton}
                    onClick={async (e) => {
                        setDisableButton(true)
                        let ret = await Taskapi.getbookingdsr(taskStore.current.bookingNum)
                        ret[0]['cargo_cutoff'] = task.cargo_cutoff
                        ret[0]['leo_cutoff'] = task.leo_cutoff
                        ret[0]['stuffing_cutoff']=task.stuffing_cutoff
                        Api.apieditdsr(ret[0],close)
                    }}>Trigger Action</button>
            </p>
        </>
    )
}