
import React, { useState, useEffect } from "react"
import { useTable, usePagination, useSortBy } from "react-table"
import { QueryClient, QueryClientProvider } from "react-query"
import RoleUtils from "../../util/RoleUtils";
import {
  EntityApprovalColumn,
  EntityColumnnonagraga,
  EntityUsersColumn,
} from "../../components/ag-table/columns"
import ReactTablePagination from "../../components/ag-table/ReactTablePagination"
import TableFilter from "../../components/ag-table/TableFilter"
import { Sorting } from "../../components/ag-table/ag-sorting"
import {
  reducer,
  PAGE_CHANGED,
  PAGE_SIZE_CHANGED,
  PAGE_SORT_CHANGED,
  PAGE_FILTER_CHANGED,
  TOTAL_COUNT_CHANGED,
  PAYLOAD_CHANGED,
} from "../../components/ag-table/ag-reducer"
import ApiAgTable from "../../components/ag-table/api_ag-table"
import {
  loginStore,
  routerStore,
  DriverStore,
  EntityV2Store,
  creditAppStore,
  creditformStore,
  cususerStore,
  configStore,
} from "../../state/CommonState"
import { useSnapshot } from "valtio"
import { ImCancelCircle } from "react-icons/im"
import { Col6, Columns } from "../../Controls"
import { CustomeruserType } from "../../state/Types"
import UsersApis from "../../state/users-api"
import { USERS_STATUS,PERMISSIONS } from "../../util/constants"
import BranchApi from "../../state/branch-api"
import EntityUtils from "../../util/entityUtils"
import { loader } from "../../util/loader";
// import { DriverType, EntityTypeV2, CreditAappFormType } from "../state/Types";
// import InviteUser from "../agusers/invite-user";

const queryClient = new QueryClient()

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 20,
  totalCount: 0,
  queryPageFilter: "",
  queryPageSortBy: [],
  queryPayload: {},
}

const DataTable = (fn) => {
  useSnapshot(configStore);
  const domain = loginStore.email.split("@")[1]
  const Entity = EntityV2Store.EntityRec;
  // const [selectedFilter, SetSelectedFilter] = useState(`all`);
  
  const [keyword, setKeyword] = useState("")
  const [data1, setData1] = useState({ results: [] })
  const [addUserPopup, setaddUserPopup] = useState(false)
  const [remarksPopup, setremarksPopup] = useState(false)
  const [remarksPopupData, setremarksPopupData] = useState(false)
  const [remarks, setremarks] = useState("")
  const [allBranches, setallBranches] = useState([])
  const [selectedStatus, SetSelectedStatus] = useState("")
  const [selectedBranch, SetSelectedBranch] = useState("")
  const [appbtnstatus, setappbtnstatus] = useState(true)
  const onClickFilterCallback = (filter) => {
    setKeyword(filter)
  }
  
  let column = {}
  if (domain === "agraga.com") {
    column = EntityUsersColumn
  }
  let columns = column
  const [
    {
      queryPageIndex,
      queryPageSize,
      totalCount,
      queryPageFilter,
      queryPageSortBy,
      queryPayload,
    },
    dispatch,
  ] = React.useReducer(reducer, initialState)

  useEffect(() => {
    console.log("queryPayload------->", queryPayload)
    const Entity = EntityV2Store.EntityRec;
    if(Entity !== undefined){
      loader.show();
      ApiAgTable.usersList(
        Entity.entityId,
        queryPageIndex,
        queryPageSize,
        queryPageFilter,
        queryPageSortBy,
        queryPayload
      ).then((res) => {
        res
          .clone()
          .json()
          .then(
            (res) => {
              if (JSON.stringify(data1.results) !== JSON.stringify(res.results)) {
                if (JSON.stringify(data1) !== JSON.stringify(res.results)) {
                  setData1(res)
                }
              }
              loader.hide();
            },
            (err) => {
              console.log(err)
            }
          )
      })
    }

  }, [
    queryPayload,
    queryPageIndex,
    queryPageSize,
    queryPageFilter,
    queryPageSortBy,
    remarksPopup,
    // selectedStatus,
    // selectedBranch
  ])

  const totalPageCount = Math.ceil(totalCount / queryPageSize)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data: data1?.results || [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: queryPageSortBy,
      },
      manualPagination: true,
      pageCount: data1 ? totalPageCount : null,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  )
  const manualPageSize = []

  useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex })
  }, [pageIndex])

  useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize })
    gotoPage(0)
  }, [pageSize, gotoPage])

  useEffect(() => {
    dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy })
    gotoPage(0)
  }, [sortBy, gotoPage])

  useEffect(() => {
    if ((keyword.length === 0)) {
      dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword })
      gotoPage(0)
    }
  }, [keyword])

  const onClickSearch = () => {
    if ((keyword.length > 0)) {
      dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword })
      gotoPage(0);
    }else{
      configStore.setModalMessage(`Please Provide Valid Input`)  
    }
  };
  
  // useEffect(() => {
  //   dispatch({
  //     type: PAYLOAD_CHANGED,
  //     payload: {},
  //   })
  //   gotoPage(0)
  // }, [])

  useEffect(() => {
    dispatch({
      type: PAYLOAD_CHANGED,
      payload: {
        status: selectedStatus,
        branch : selectedBranch
      },
    })
    gotoPage(0)
  }, [
    selectedBranch,
    selectedStatus,
  ])

  // console.log("vgfvgf" , USERS_STATUS);
  // useEffect(() => {
  //   dispatch({ type: PAYLOAD_CHANGED, payload: { filter: selectedFilter } });
  //   gotoPage(0);
  // }, [selectedFilter]);

  React.useEffect(() => {
    if (data1?.count) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data1.count,
      })
    }
    
  }, [data1?.count])

  useEffect(()=> {
     BranchApi.getAllBranches(Entity.entityId, setallBranches)
  }, [])

  const addUser = () => {
      cususerStore.cususerRec.set(new CustomeruserType())
      routerStore.entityUserManageView = "new"
  }
  function showRole(r){
    if(r.entityList.some(e => e.entityId === Entity.entityId)){
      const i = r.entityList.findIndex(e => e.entityId === Entity.entityId)
      return r.entityList[i].role
    }else if(r.mainEntityList.some(e => e.entityId === Entity.entityId)){
      const i = r.mainEntityList.findIndex(e => e.entityId === Entity.entityId)
      return r.mainEntityList[i].role
    }

  }


  const showBranch = (r) => {
  //  let branches = []
   if(r.entityList.some(e => e.entityId === Entity.entityId)){
    const i = r.entityList.findIndex(e => e.entityId === Entity.entityId)
    return r.entityList[i].gstList
  }else if(r.mainEntityList.some(e => e.entityId === Entity.entityId)){
    const i = r.mainEntityList.findIndex(e => e.entityId === Entity.entityId)
    return r.mainEntityList[i].gstList
  }

  }

  
  const showBranchNI = (r) => {
    //  let branches = []
    //  if(r.entityList.some(e => e.entityId === Entity.entityId)){
    //   const i = r.entityList.findIndex(e => e.entityId === Entity.entityId)
    //   return r.entityList[i].branchList
    // }else if(r.mainEntityList.some(e => e.entityId === Entity.entityId)){
    //   const i = r.mainEntityList.findIndex(e => e.entityId === Entity.entityId)
    //   return r.mainEntityList[i].branchList
    // }
  
     return [Entity.tinNum ] 
    }
  function showDesignation(r){
    if(r.entityList.some(e => e.entityId === Entity.entityId)){
      const i = r.entityList.findIndex(e => e.entityId === Entity.entityId)
      return r.entityList[i].designation
    }else if(r.mainEntityList.some(e => e.entityId === Entity.entityId)){
      const i = r.mainEntityList.findIndex(e => e.entityId === Entity.entityId)
      return r.mainEntityList[i].designation
    }

  }
  function showStatus(r){
    if(r.entityList.some(e => e.entityId === Entity.entityId)){
      const i = r.entityList.findIndex(e => e.entityId === Entity.entityId)
      if(r.entityList[i].status === "DEACTIVATED"){
        return  r.entityList[i].status 
      }else {
        return r.status
      }
      // return r.entityList[i].designation
    }else if(r.mainEntityList.some(e => e.entityId === Entity.entityId)){
      const i = r.mainEntityList.findIndex(e => e.entityId === Entity.entityId)
      if(r.mainEntityList[i].status === "DEACTIVATED"){
        return  r.mainEntityList[i].status 
      }else {
        return r.status
      }
      // return r.mainEntityList[i].designation
    }

  }
  const cancelpopup = (r) => {
    // setremarksPopup(false)
    setappbtnstatus(true)
    if(r=== "afterApicall"){
      blurBackgrd('unblur')
    }
   else {
    blurBackgrd('unblur')
    setremarksPopup(false)
   }
    
  }

  function blurBackgrd (r) {
    if(r=== 'blur') {
      document.getElementById("usersPg").style.filter = "blur(15px)"
      document.getElementById("usersPg").style.pointerEvents = "none"
    }else if (r === 'unblur') {
      document.getElementById("usersPg").style.filter = "none"
      document.getElementById("usersPg").style.pointerEvents = "auto"
    }
  }

  const remarksPopUp = (e, r) => {
    e.updateStatus= r ;
    setremarksPopupData(e) ;
    setremarksPopup(true) ;
    blurBackgrd('blur')
  }

  const closeaddUser = () => {
    document.getElementById("usersPg").style.filter = "none"
    document.getElementById("usersPg").style.pointerEvents = "auto"
    setaddUserPopup(false)
  }

  const addremarks = (e) => {
    if(e.target.value.trim().length > 3){
      setopacitybtn("subremarksbtnid" , 1)
      setappbtnstatus(false)
    }else {
      setopacitybtn("subremarksbtnid" , 0.4)
      setappbtnstatus(true)
    }
    setremarks(e.target.value)
  }

  function setopacitybtn (id , value) {
    document.getElementById(`${id}`).style.opacity = `${value}`
  }

  return (
    <>
      {
        <div id="usersPg">
          
           <div
          class="back-div is-flex"
          >
          <img src="./assets/Back.png" alt="Back" class="back" width="32px" onClick={() => {
            fn.fn.fn.fn(false);
          }} />
          <h1 className="title ml-2">Manage User</h1>
        </div>
       
          <nav className="level">
            <div className="level-left">
              {/* <div className="level-item">
            <p className="subtitle is-5">{data1?.count} Drivers</p>
          </div> */}
              <span className="p-2">{data1.count}</span> <p className="level-item">Users</p>
              {/* <button
            onClick={() => {
              EntityV2Store.EntityRec.set(new CreditAappFormType());
              routerStore.entityCurrentViewNew = "new";
            }}>
            New
          </button> */}
              <div className="level-item is-hidden-tablet-only">
                <TableFilter onClickSearch={onClickSearch}
                  onClickFilterCallback={onClickFilterCallback}
                  defaultKeyword={keyword}
                />
              </div>
              { RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.MANAGE_USERS) &&
              <p className="level-item">
                <button
                  className="button is-link"
                  onClick={() => {
                    //   EntityV2Store.EntityRec.set(new EntityTypeV2());
                    //   routerStore.entityCurrentViewNew = "new";
                    // setaddUserPopup(true)
                    addUser()
                  }}
                >
                  Add User
                </button>
              </p>
              }
              <div className="column is-one-fourth">
                <div class="field">
                  <p class="control has-icons-left has-icons-right">
                    <span class="select">
                      <select
                        name="status"
                        id="status"
                        value={selectedBranch}
                        onChange={(e) => SetSelectedBranch(e.target.value)}
                        >
                        <option value="" >Branch</option>
                        {allBranches?.map((r,i) => (
                          <option value={r.branchId}>{r.gst}</option>
                        ))}
                      </select>
                      <span class="icon">
                        <i class="fa-solid fa-filter"></i>
                      </span>
                    </span>
                  </p>
                </div>
              </div>
              <div className="column is-one-fourth">
                <div class="field">
                  <p class="control has-icons-left has-icons-right">
                    <span class="select">
                      <select
                        name="status"
                        id="status"
                        value={selectedStatus}
                        onChange={(e) => SetSelectedStatus(e.target.value)}
                        >
                        <option value="">User Status</option>
                        {Object.entries(USERS_STATUS).map(([key, value]) => (
                          <option value={value}>{value}</option>
                        ))}
                      </select>
                      <span class="icon">
                        <i class="fa-solid fa-filter"></i>
                      </span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
           
          </nav>

          {
            <>
              {(typeof data1?.count === "undefined" || data1?.count === 0) && (
                <p>No results found</p>
              )}
              {data1?.count > 0 && (
                <>
                  <div className="table-container">
                    <table className="table is-bordered is-striped is-fullwidth is-hoverable ">
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            <th>S.No</th>
                            {headerGroup.headers.map((column) => (
                              <th
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                {column.render("Header")}
                                <Sorting column={column} />
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody
                        {...getTableBodyProps()}
                        className="listentityapprovaltable"
                      >
                        {page.map((row, i) => {
                          prepareRow(row)
                          return (
                            <>
                              <tr
                                {...row.getRowProps()}
                                onClick={async () => {
                                  // console.log(data1.results[i]);
                                  //EntityV2Store.EntityRec.set(data1.results[i]);
                                  //  creditAppStore.CreditAppRec.set(data1.results[i]);
                                  // CreditsApis.vie
                                  // routerStore.creditApplicationView = "new";
                                }}
                              >
                                <td>{pageIndex * pageSize + i + 1}</td>
                                {row.cells.map((cell) => {
                                  // eslint-disable-next-line no-lone-blocks
                                  {
                                    let e = cell["row"]["original"]
                                    switch (cell["column"]["Header"]) {
                                      case "Name":
                                        return (
                                          <td>
                                        <div
                                          class="dropdown post-options is-right is-hoverable "
                                        >
                                          <a
                                                href="/#"
                                                // class="dropdown-item"
                                                onClick={(event) => {
                                                   // DriverStore.DriversRec.set(e);
                                                 
                                                    cususerStore.cususerRec.set(e)
                                                    routerStore.entityUserManageView = "view"
                                                    event.stopPropagation();
                                                  }}
                                                
                                                
                                                >
                                               {e.firstName} {e.lastName}
                                              </a>
                                    
            
                                        </div>
                                      </td>
                                        );
                                      case "Actions":
                                        return (
                                          <td>
                                        <div
                                          class="dropdown post-options is-right is-hoverable "
                                          style={
                                            !(RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.MANAGE_USERS || RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.DEACTIVATE_CUSTOMER_USER))) ?
      
                                              { pointerEvents: 'none', opacity: 0.6 } : {}}
                                          onClick={null}>
                                          <div class="dropdown-trigger">
                                            <button
                                              class="button"
                                              aria-haspopup="true"
                                              aria-controls="dropdown-menu-post"
                                            >
                                              <span class="icon is-small">
                                                <i
                                                  class="fas fa-ellipsis-h"
                                                  aria-hidden="true"></i>
                                              </span>
                                            </button>
                                          </div>
                                    {
                                     
                                          <div
                                             class="dropdown-menu"
                                            id="dropdown-menu-post"
                                            role="menu">
                                            <div class="dropdown-content">
                                               {(showStatus(e) !== USERS_STATUS.DEACTIVATE && RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.MANAGE_USERS)) &&
                                                 <a
                                                href="/#"
                                                class="dropdown-item"
                                                onClick={(event) => {
                                                   // DriverStore.DriversRec.set(e);
                                                 
                                                    cususerStore.cususerRec.set(e)
                                                    routerStore.entityUserManageView = "edit"
                                                    event.stopPropagation();
                                                  }}
                                                >
                                                Edit
                                                </a>
                                              }

                                              {(( showStatus(e) !== USERS_STATUS.DEACTIVATE && showStatus(e) === USERS_STATUS.ACTIVE ) && RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.DEACTIVATE_CUSTOMER_USER) ) &&  <><a
                                                href="/#"
                                                class="dropdown-item"
                                                 onClick = {()=>remarksPopUp(e , USERS_STATUS.DEACTIVATE)}
                                                
                                                >
                                                Deactivate
                                              </a></>

                                              }
                                               {showStatus(e) === USERS_STATUS.DEACTIVATE && <>
                                                <a
                                                href="/#"
                                                class="dropdown-item"
                                                onClick = {()=>remarksPopUp(e , USERS_STATUS.ACTIVE)}   
                                                >
                                                Re-Activate
                                               </a>
                                               </> 
                                               }
                                             
                                            </div>
                                          </div>
                                    }
                                        </div>
                                      </td>
                                        ); 
                                        case "User Type":
                                          return (
                                            <td>
                                          <div
                                            class="dropdown post-options is-right is-hoverable "
                                          >
                                                <span>
                                                 {showRole(e)}
                                                </span>
              
                                          </div>
                                        </td>
                                          );
                                          case "Branch":
                                            return (
                                              <td>
                                            <div
                                              class="dropdown post-options is-right is-hoverable "
                                            >
                                                  <span>
                                                  {EntityUtils.isIndia(Entity) ?  <>{showBranch(e)?.map((r,i) => {
                                                     return <p>{r}</p>
                                                   })} </>: <>{showBranchNI(e)?.map((r,i) => {
                                                     return <p>{r}</p>
                                                   })}</>}
                                                   
                                                 
                                                  </span>
                
                                            </div>
                                          </td>
                                            );
                                          case "Designation":
                                            return (
                                              <td>
                                            <div
                                              class="dropdown post-options is-right is-hoverable "
                                            >
                                                  <span>
                                                   {showDesignation(e)}
                                                  </span>
                
                                            </div>
                                          </td>
                                            );

                                            case "Status":
                                            return (
                                              <td>
                                            <div
                                              class="dropdown post-options is-right is-hoverable "
                                            >
                                                  <span>
                                                {showStatus(e)}
                                                  </span>
                
                                            </div>
                                          </td>
                                            );
                                          
                                      default:
                                        return (
                                          <td {...cell.getCellProps()}>
                                            <span>{cell.render("Cell")}</span>
                                          </td>
                                        )
                                    }
                                  }
                                })}
                              </tr>
                            </>
                          )
                        })}
                      </tbody>
                    </table>
                    {rows.length > 0 && (
                      <>
                        <div className="columns" style={{ width: "100%" }}>
                          <div className="column is-12">
                            <ReactTablePagination
                              page={page}
                              gotoPage={gotoPage}
                              previousPage={previousPage}
                              nextPage={nextPage}
                              canPreviousPage={canPreviousPage}
                              canNextPage={canNextPage}
                              pageOptions={pageOptions}
                              pageSize={pageSize}
                              pageIndex={pageIndex}
                              pageCount={pageCount}
                              setPageSize={setPageSize}
                              manualPageSize={manualPageSize}
                              dataLength={totalCount}
                              rows={rows}
                            />
                          </div>
                          {/* <div
                            className="column is-6"
                            style={{ textAlign: "right" }}
                          >
                            <div className="select">
                              <select
                                value={pageSize}
                                onChange={(e) => {
                                  setPageSize(Number(e.target.value))
                                }}
                              >
                                {[20, 50, 100].map((pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                    Shows {pageSize}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div> */}
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          }
        </div>
      }
      {
        remarksPopup && (
          <>
           <div
          style={{
            width: "450px",
            // height: "150px",
            backgroundColor: "#f2f2f2",
            borderRadius: "10px",
            border: "1px solid black",
            position: "absolute",
            top: "40%",
            left: "43%",
            padding: "1% 1% 1% 1%",
          }}>
          <div className="is-flex is-justify-content-space-between">
            <span className="is-size-5">{remarksPopupData.updateStatus === USERS_STATUS.DEACTIVATE ? <>Deactivate</>: <>Reactivate</>} USER </span>
            <span>
              <ImCancelCircle onClick={cancelpopup} className="is-clickable" />
            </span>
          </div>
              <p>Remarks for {remarksPopupData.updateStatus === USERS_STATUS.DEACTIVATE ? <>Deactivation*</> : <>Reactivation*</>}</p>
          <div className="">     
                <textarea
                  className="is-size-7"
                  style={{ width: "80%" , height: "5em" , nowrap : "wrap" , padding : "1%" }}
                  onChange={(e) => addremarks(e)}
                  // id="approveCkboxid"
                />
          </div>
          <div>
            <span>
              <button
                className="is-clickable"
                style={{
                  backgroundColor: "#00ccff",
                  border: "none",
                  padding: "2%",
                  borderRadius: "8px",
                  color: "white",
                  fontWeight: "600",
                  marginTop: "2%",
                  marginLeft: "80%",
                  opacity: 0.4,
                }}
                id="subremarksbtnid"
                disabled={appbtnstatus}
                onClick={async (event) => {
                  let statuss 
                  if(remarksPopupData.updateStatus === USERS_STATUS.DEACTIVATE){
                    statuss = USERS_STATUS.DEACTIVATE
                  }else {
                    statuss = USERS_STATUS.ACTIVE
                  }
                  let data = {
                    status : statuss,
                    remarks : remarks ,
                    email : remarksPopupData.email ,
                    entityId : Entity.entityId
                  }
                   await UsersApis.deactivateUser(data ,setremarksPopup )
                  //  setremarksPopup(false)
                   cancelpopup("afterApicall")
                    event.stopPropagation();
                  }}    >
                SUBMIT
              </button>
            </span>
          </div>
        </div>
          </>
        )
      }

      {
      /* {addUserPopup ? (
        <div
          style={{
            width: "575px",
            backgroundColor: "#f2f2f2",
            borderRadius: "10px",
            border: "1px solid black",
            position: "absolute",
            top: "30%",
            left: "33%",
            padding: "1% 1% 1% 1%",
          }}
          className="adduserpopup"
        >
          <div className="is-flex is-justify-content-space-between">
            <span className="is-size-5 has-text-weight-bold">Add User</span>
            <span>
              <ImCancelCircle
                onClick={() => closeaddUser()}
                className="is-clickable"
              />
            </span>
          </div>
          <Columns className="is-flex">
            <Col6>
              <p>Name*</p>
              <p>
                <input placeholder="Please enter the name" className="p-1 wd_80"></input>
              </p>
            </Col6>
            <Col6>
              <p>Email*</p>
              <p>
                <input placeholder="Please enter the email" className="p-1 wd_80"></input>
              </p>
            </Col6>
          </Columns>
          <Columns className="is-flex">
            <Col6>
              <p>Contact number*</p>
              <p>
                <input placeholder="Please enter the phone no" className="p-1 wd_80"></input>
              </p>
            </Col6>
            <Col6>
              <p>User Type*</p>
              <p>
                <select className="p-1 wd_80">
                  <option>Entity</option>
                  <option>Branch</option>
                </select>
              </p>
            </Col6>
          </Columns>
          <Columns className="is-flex">
            <Col6>
              <p>Role*</p>
              <p>
                <input placeholder="" className="p-1 wd_80"></input>
              </p>
            </Col6>
            <Col6>
              <p>Branch*</p>
              <p>
                <select className="p-1 wd_80">
                  <option>Entity</option>
                  <option>Branch</option>
                </select>
              </p>
            </Col6>
          </Columns>
          <div>
            <p>Is Admin User*</p>
            <p>
              <input type="radio"></input><span>Yes</span>
              <input type="radio" className="ml-3"></input><span>No</span>
            </p>
          </div>

          <div>
            <span>
              <button
                className="is-clickable"
                style={{
                  backgroundColor: "#00ccff",
                  border: "none",
                  padding: "1%",
                  borderRadius: "8px",
                  color: "white",
                  fontWeight: "600",
                  marginTop: "3%",
                  marginLeft: "80%",
                }}
              >
                SUBMIT
              </button>
            </span>
          </div>
        </div>
      ) : null} */}
    </>
  )
}

const ListUsers = (fn) => {
  return (
    <QueryClientProvider client={queryClient}>
      <DataTable fn={fn}/>
    </QueryClientProvider>
  )
}

export default ListUsers
