import { useSnapshot } from "valtio"
import Select from 'react-select'
import { useState, useEffect } from "react"

const customStyles = {
    control: (provided) => ({
        ...provided,
        minHeight: '30px',
        border: '1px solid #ccc',
    }),

    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        display: 'none',
    }),
    option: (provided) => ({
        ...provided,
        height: '30px',
        background: '#fff',
        color: '#363636'
    }),
}

const customStyles1 = {
    control: (provided) => ({
        ...provided,
        minHeight: '30px',
        border: '1px solid #ccc',
    }),

    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        display: 'none',
    }),
    option: (provided) => ({
        ...provided,
        background: '#fff',
        color: '#363636'
    }),
}

export default function AgCustlist({ label, record, name, dataList, callback = f => f , isValid = true, validationMsg, isDisabled }) {
    const [selectedOption, setSelectedOption] = useState(null)
    const imRecord = useSnapshot(record)
    useEffect(() => {
        let entityList = dataList.filter((ele) => ele.entityId === imRecord['entityId'])
        setSelectedOption(entityList)
    }, [dataList, imRecord, name])
    return (
        <div className="field">
            <label className="label is-small" style={{ marginBottom: '0px' }}>{label}</label>
            <div className="control">
                <div className={ isValid ? "label is-small" : "label is-small is-danger"}>
                    {
                        dataList !== null ? <Select
                            value={selectedOption}
                            options={dataList}
                            onChange={(e) => {
                                record[name] = e ? e.entityId : ""
                                setSelectedOption(e)
                                callback(e ? e.entityId : "")
                            }}
                            getOptionLabel={(entityList) => {
                                if(entityList.state == undefined || entityList.state == "undefined"){
                                    return entityList.entityName
                                }else{
                                   return entityList.entityName+" "+entityList.state
                                }
                            }}
                            isDisabled={!isDisabled || !isDisabled.disabled ? false : isDisabled.disabled }
                            isSearchable={true}
                            isClearable={true}
                            placeholder={null}
                            styles={customStyles}

                        /> : ""
                    }

                </div>
            </div>
            { !isValid &&  <p class="help is-danger"> { validationMsg || `* Please select one customer` }</p> }
        </div>
    )
}

export function AgSelectNew({ label, record, name, dataList, callback = f => f , isValid = true, validationMsg, isDisabled }) {
    const [selectedOption, setSelectedOption] = useState(null)
    const imRecord = useSnapshot(record)
    useEffect(() => {
        let value = dataList.filter((ele) => ele.value === imRecord[name])
        setSelectedOption(value)
    }, [dataList, imRecord, name])
    return (
        <div className="field">
            <label className="label is-small" style={{ marginBottom: '0px' }}>{label}</label>
            <div className="control">
                <div className={ isValid ? "label is-small" : "label is-small is-danger"}>
                    {
                        dataList !== null ? <Select
                            value={selectedOption}
                            options={dataList}
                            onChange={(e) => {
                                record[name] = e ? e.value : ""
                                setSelectedOption(e)
                                callback(e ? e.value : "")
                            }}
                            getOptionLabel={(e) => {
                                return e.label
                            }}
                            isDisabled={!isDisabled || !isDisabled.disabled ? false : isDisabled.disabled }
                            isSearchable={true}
                            isClearable={true}
                            placeholder={null}
                            styles={customStyles1}

                        /> : ""
                    }

                </div>
            </div>
            { !isValid &&  <p class="help is-danger"> { validationMsg || `* Please select one customer` }</p> }
        </div>
    )
}
/*
export default function AgCustlist({ label, record, name, dataList, callback = f => f }) {
    const imRecord = useSnapshot(record)
    return (
        <div className="field">
            <label className="label is-small" style={{ marginBottom: '0px' }}>{label}</label>
            <div className="control">
                <div className="select is-small">
                    <select name={name} value={imRecord[name]}
                        onChange={(e) => {
                            console.log('FROM INSIDE AgCustlist=', record, e.target.value)
                            record[name] = e.target.value
                            callback(e.target.value)
                        }} >
                        {dataList.map(val => <option value={val.key}>{val.value}</option>)}
                    </select>
                </div>
            </div>
        </div>
    )
}
*/