import { currencyList } from "../StaticData"
import { TableEdit, TableSelect} from "../components/AgComponents"
import { useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import { EntityV2Store, configStore, entityStore } from "../state/CommonState"
import {
    quoteDataRec, fclChargesStore, containerStore
} from '../state/CommonState'

import Utils from "../util/Utils"
import { chargesValidationStore } from "../state/validation-stores"
import chargedetails from "../assets/images/chargedetails.svg";
import { Card } from "../v3-componets/AgCard"
import { Headspan } from "../v3-componets/AgHeadspan"
import { TableLabel } from "../v3-componets/AgTableLabel"

export default function FCLChargesCompv3(props) {

    const [onaccount, setonaccount] = useState(['On Your Account'])
    useSnapshot(quoteDataRec)
    useSnapshot(entityStore)
    let key = "list"
    if(props.keylist!=undefined){
        key = props.keylist
    }
    let initialLength = "initialLength"+key.replace("list","")
    const imContainerStore = useSnapshot(containerStore)
    const containerNames = imContainerStore.list.map(e => e.containerType)
    let [space, setSpace] = useState('')

    if (fclChargesStore[key].length > 0) {
        let oldchargeskey = []
        Object.entries(fclChargesStore[key][0]).filter(([key, value]) => {
            oldchargeskey.push(key)
        })
        let newchargeskey = []
        oldchargeskey.map(e => {
            if (e.indexOf("'") == -1) {
                newchargeskey.push(e)
            } else {
                containerNames.map(f => {
                    if (e.indexOf(f) > -1) {
                        newchargeskey.push(e)
                    }
                })
            }
        })
        let uniqueContainer = new Set(containerNames)
        if (oldchargeskey.sort().toString() != newchargeskey.sort().toString() && containerNames.length === uniqueContainer.size) {
            let newchargelist = []
            fclChargesStore[key].map((e) => {
                var result = Object.fromEntries(newchargeskey.map(col => [col, e[col]]));
                newchargelist.push(result)
            })
            fclChargesStore[key] = newchargelist;
        }

    }

    let imChargesStore = useSnapshot(fclChargesStore)
    let a = imChargesStore.newRec
    let b = imChargesStore.editRec

    let list = fclChargesStore[key]
    for (let i = 0; i < list.length; i++) {
        if (!list[i].onaccount || !list[i].onaccount.length) list[i].onaccount = 'On Your Account'
    }
    useEffect(() => {
        let entity = EntityV2Store.list.find((e) => e.entityId === quoteDataRec.entityId)
        if (entity) {
            let sscope = quoteDataRec.shipmentScope
            sscope = sscope.toLowerCase()
            let country = '' + entity.entityCountry
            country = country.toLowerCase()
            let cecisorigin = false
            let cecisdestination = false
            let por = ('' + quoteDataRec.portOfLoading).toLowerCase()
            let pod = ('' + quoteDataRec.finalPlaceOfDelivery).toLowerCase()
            let q = quoteDataRec
            if(entity._id == "TBA" && q.isfromAgragaEnquiry != undefined && q.isfromAgragaEnquiry=='Yes' && q.countryFromEnquiry != undefined){
                country = q.countryFromEnquiry.toLowerCase()
            }
            if(country.includes('hong kong')){
                cecisorigin = por.includes('hong kong')
                cecisdestination = pod.includes('hong kong')
            }else {
                cecisorigin = por.includes(country)
                cecisdestination = pod.includes(country)
            }

            if (cecisorigin && sscope.endsWith('port')) setonaccount(['On Your Account', `Consignee's Account`])
            else if (cecisdestination && sscope.startsWith('port')) setonaccount(['On Your Account', `Shipper's Account`])
            else setonaccount(['On Your Account'])
            fclChargesStore[key].map((e) => {
                if(sscope.length!=0 && country.length!=0 && por.length!=0 && pod.length!=0){
                    if(e.onaccount!=undefined){
                        if (cecisorigin && sscope.endsWith('port')){
                            if(['On Your Account', `Consignee's Account`].indexOf(e.onaccount)==-1){
                                e.onaccount = "On Your Account"
                            }
                        }else if (cecisdestination && sscope.startsWith('port')){
                            if(['On Your Account', `Shipper's Account`].indexOf(e.onaccount)==-1){
                                e.onaccount = "On Your Account"
                            }
                        }else{
                            e.onaccount = "On Your Account"
                        }
                    }
                }
                return e
            })
        }
        console.log('DETECTED CHANGES=', quoteDataRec.entityId, quoteDataRec.portOfReceipt, quoteDataRec.portOfDischarge)
    }, [
        quoteDataRec.entity,quoteDataRec.entityId, quoteDataRec.portOfLoading,
        quoteDataRec.finalPlaceOfDelivery, quoteDataRec.shipmentScope
    ])
    const deleteRec = (id) => {
        //if (id <= imChargesStore[initialLength]) return
        const index = fclChargesStore[key].findIndex(e => e.id === id)
        let records = fclChargesStore[key]
        records = [...records]
        records.splice(index, 1)
        for (let i = 0; i < records.length; i++) {
            records[i].id = i + 1
        }
        if(id<=fclChargesStore[initialLength]){
            fclChargesStore[initialLength] = fclChargesStore[initialLength]-1
        }
        fclChargesStore[key] = records
        if(fclChargesStore[key].length==0){
            let obj = imChargesStore.newRec
            obj = { ...obj }
            fclChargesStore[key].push({ ...obj, id: fclChargesStore[key].length + 1 })
        }
        //console.log('AFTER DELETE=', JSON.stringify(fclChargesStore.cargoList))
    }
    const Ts = ({ i, name, dataList }) => <TableSelect
        record={fclChargesStore[key][i]} name={name} dataList={dataList} isValid={chargesValidationStore.isValidCharge(name, i,key)} />
    const Te = ({ i, name }) => {
        const e = fclChargesStore[key][i]
        if (name === 'chargeDescription' && i < fclChargesStore[initialLength])
            return <TableLabel record={fclChargesStore[key][i]} name={name} />
        return (
            <TableEdit record={fclChargesStore[key][i]} name={name} isValid={chargesValidationStore.isValidCharge(name, i,key)}/>
        )
    }
    const Tn = ({ i, name }) => {
        const e = fclChargesStore[key][i]
        let disabled = false
        if (e.disabled) {
            for (let i = 0; i < e.disabled.length; i++) {
                if (name.endsWith(e.disabled[i])) {
                    disabled = true
                    break
                }
            }
        }
        if (disabled)
            return (
                <input disabled='true' style={{ width: '3rem', backgroundColor: '#dddddd', border: 'none' }} />
            )
        else return (
            <TableEdit record={fclChargesStore[key][i]} name={name} type='number' isValid={chargesValidationStore.isValidCharge(name, i,key)} />
        )
    }

    let grandTotal = {}
    let cTotal = {}
    let sTotal = {}
    let cPresent = false
    let sPresent = false
    containerStore.list.map(c => {
        grandTotal[c.containerType] = 0.0
        cTotal[c.containerType] = 0.0
        sTotal[c.containerType] = 0.0
    })
    fclChargesStore[key].map(e => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        containerStore.list.map(c => {
            let totalField = c.containerType + 'total'
            if (e.onaccount && e.onaccount.startsWith('Cons')) {
                cPresent = true
                cTotal[c.containerType] += parseFloat(e[totalField]) * erate
            }
            else if (e.onaccount && e.onaccount.startsWith('Ship')) {
                sPresent = true
                sTotal[c.containerType] += parseFloat(e[totalField]) * erate
            }
            else grandTotal[c.containerType] += parseFloat(e[totalField]) * erate
        })
    })
    if(fclChargesStore[key].length>0){
        fclChargesStore.defaultValueCalcindex(key) // Need to calculate for validation
    }
    return (
        <>
            <hr />
            <Card  onClick={(e) => {
                e.nativeEvent.preventDefault()
                e.preventDefault()
                e.nativeEvent.stopPropagation()
                e.stopPropagation()

            }} >
                                    <span><img src={chargedetails}/></span><Headspan color="#00000" fontSize= "14px" fontWeight="900" data={" Charge Details"+(props.heading!=undefined && props.heading.length>0?" - "+props.heading:"")}/>
                                    <div className="table-container"  onClick={(e) => {
                e.nativeEvent.preventDefault()
                e.preventDefault()
                e.nativeEvent.stopPropagation()
                e.stopPropagation()

            }} >
                                    <table style={{border:"none", width:"100%"}}>
                                    <thead>
                                    {
                            (containerNames.length>0)?<tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            {containerNames.map(e => (
                                <>
                                <td style={{border:"solid 0.5px #4BA7DD", backgroundColor:"#4BA7DD", color:"#fff",fontWeight:"900", padding:"10px", textAlign:"center"}}  colSpan={3}>{e}</td>
                                <th></th>
                                </>
                            ))}
                            <th></th>
                        </tr>:""
                        }
                        <tr style={{ fontWeight:"900",border:"solid 0.5px #D4D4D4"}}>
                            <th style={{color:"#2C358A", padding:"10px"}}>ID</th>
                            <th style={{color:"#2C358A", padding:"10px"}}>Charge Description</th>
                            <th style={{color:"#2C358A", padding:"10px"}}>On Account</th>
                            <th style={{color:"#2C358A", padding:"10px"}}>Currency</th>
                            {containerNames.map(e => (
                                <>
                                    <th style={{color:"#2C358A", padding:"10px", textAlign: 'center' }}>Per Container</th>
                                    <th style={{color:"#2C358A", padding:"10px", textAlign: 'center' }}>Per BL</th>
                                    <th style={{color:"#2C358A", padding:"10px", textAlign: 'center' }}>Per Ton</th>
                                    <th style={{color:"#2C358A", padding:"10px", textAlign: 'right' }}>Total</th>
                                </>
                            ))}
                            {
                                            (containerNames.length>0)?
                            <th style={{color:"#2C358A", padding:"10px", textAlign: 'center' }}>Action</th>:""}
                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                            imChargesStore[key].map((e, i) => {
                                {
                                    return <tr key={e.id} style={{border:"solid 0.5px #D4D4D4", backgroundColor:(i%2)==1?"#F3F3F3":"none"}}>
                                        <td style={{color:"#555555", padding:"10px"}}>{e.id}</td>
                                        <td style={{color:"#555555", padding:"10px"}}><Te i={i} name='chargeDescription' /></td>
                                        <td style={{color:"#555555", padding:"10px"}}><Ts i={i} name='onaccount' dataList={onaccount} /></td>
                                        <td style={{color:"#555555", padding:"10px"}}><Ts i={i} name='currency' dataList={currencyList} /></td>
                                        {containerNames.map(e1 => (
                                            <>
                                                <td style={{color:"#555555", padding:"10px", textAlign: 'center' }}><Tn i={i} name={e1 + 'perWM'} /></td>
                                                <td style={{color:"#555555", padding:"10px", textAlign: 'center' }}><Tn i={i} name={e1 + 'perShipment'} /></td>
                                                <td style={{color:"#555555", padding:"10px", textAlign: 'center' }}><Tn i={i} name={e1 + 'perTon'} /></td>
                                                <td style={{ color:"#555555", padding:"10px",textAlign: 'right' }}>
                                                    {
                                                        (e[e1 + 'total'] > 0 && e.currency != '') ?
                                                            Utils.N(e[e1 + 'total'], e.currency) : ''
                                                    }
                                                </td>
                                            </>
                                        ))}
                                        {
                                            (containerNames.length>0)?
                                            <td style={{color:"#555555", padding:"10px", textAlign: 'center' }}>
                                            <div className="field has-addons">

                                                
                                                        <p className="control">
                                                            <button className="button is-danger is-inverted"
                                                                onClick={() => {
                                                                    deleteRec(e.id)
                                                                    setSpace(space === '' ? ' ' : '')
                                                                }}>
                                                                <span className="icon">
                                                                    <i className="fas fa-trash"></i>
                                                                </span>
                                                            </button>
                                                        </p>
                                                 
                                                {
                                                    i === imChargesStore[key].length - 1 && (
                                                        <p className="control">
                                                            <button className="button is-success is-inverted"
                                                                 onClick={(e) => {
                                                                    e.nativeEvent.preventDefault()
                                                                    e.preventDefault()
                                                                    e.nativeEvent.stopPropagation()
                                                                    e.stopPropagation()
                                                                    let obj = imChargesStore.newRec
                                                                    obj = { ...obj }
                                                                    fclChargesStore[key].push({ ...obj, id: imChargesStore[key].length + 1 })
                                                                    imChargesStore.defaultValueCalcindex(key)
                                                                    setSpace(space === '' ? ' ' : '')
                                                                }}>
                                                                <span className="icon is-small">
                                                                    <i className="fas fa-plus"></i>
                                                                </span>
                                                            </button>
                                                        </p>
                                                    )
                                                }
                                            </div>
                                        </td>:""
                                        }
                                        
                                    </tr>
                                }
                            }
                            )
                        }
                        {
                                            (containerNames.length>0)?<>
                                            <tr style={{border:"solid 0.5px #D4D4D4"}}>
                            <td colSpan={4} style={{ textAlign: 'right',color:"#555555", padding:"10px", fontWeight:"900"}}>On Your Account</td>
                            {
                                containerStore.list.map(c => {
                                    return (
                                        <>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>
                                                    {Utils.N(grandTotal[c.containerType])}
                                                </strong>
                                            </td>
                                        </>
                                    )
                                })
                            }
                            <td rowSpan={(cPresent && sPresent)?4:(cPresent || sPresent)?3:2} style={{ textAlign: 'center' }}>
                            <button className="button is-info is-inverted"
                                                        
                                                        onClick={() => {
                                                            fclChargesStore.calculateChargesTotalsindex(key,quoteDataRec.emptycontainerweight);
                                    setSpace(space === '' ? ' ' : '')
                                                        }}>
                                                        <span className="icon is-small">
                                                            <i className="fas fa-calculator"></i>
                                                        </span>
                                                    </button>
                            </td>
                        </tr>
                        {
                            cPresent &&
                            <tr style={{border:"solid 0.5px #D4D4D4"}}>
                                <td colSpan={4} style={{ textAlign: 'right',color:"#555555", padding:"10px", fontWeight:"900"}}>On Consignee's Account</td>
                                {
                                    containerStore.list.map(c => {
                                        return (
                                            <>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td style={{ color:"#2C358A", padding:"10px", textAlign: 'right', fontSize: '1.1rem', fontWeight:"900"}}>
                                                    <strong>
                                                        {Utils.N(cTotal[c.containerType])}
                                                    </strong>
                                                </td>
                                            </>
                                        )
                                    })
                                }
                            </tr>
                        }
                        {
                            sPresent &&
                            <tr style={{border:"solid 0.5px #D4D4D4"}}>
                                <td colSpan={4} style={{ textAlign: 'right',color:"#555555", padding:"10px", fontWeight:"900"}}>On Shipper's Account</td>
                                {
                                    containerStore.list.map(c => {
                                        return (
                                            <>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td style={{ color:"#2C358A", padding:"10px", textAlign: 'right', fontSize: '1.1rem', fontWeight:"900"}}>
                                                    <strong>
                                                        {Utils.N(sTotal[c.containerType])}
                                                    </strong>
                                                </td>
                                            </>
                                        )
                                    })
                                }
                            </tr>
                        }
                                            </>:""}
                        
                                        </tbody>
                                        </table></div></Card>
        </>
    )
}
