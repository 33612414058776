
import logo from './aglogo.svg'
import agbg from './agbg.png'
import { useRef, useEffect, useState } from 'react'
import { configStore, loginStore } from './state/CommonState'
import { useProxy } from 'valtio/macro'
import { useSnapshot } from 'valtio'
import Api from './state/Api'
import AddCustomer from './AddCustomer'
import RegisterAguser from './agusers/register-aguser'
import { aguserStore } from './state/CommonState'
import { AguserType } from './state/Types'
import { Agpassword } from './components/AgComponents'
import AguserApi from './state/AguserApi'
import UsersApis from './state/users-api'
import { LoginPage } from './pages/LoginFormPage'
import { Box } from '@mui/material'
import { SignUpPage } from './pages/SignupFormPage'
import { EntityCreationPage } from './pages/EntityCreationPage'
import { ForgotPasswordPage } from './pages/ForgotPasswordPage'
import LoginPageNew from './pages/LoginPageNew'
import SignUpPageNew from './pages/SignUpPageNew'
import ForgotPasswordNew from './pages/ForgotPasswordNew'
import ChangePassword from './pages/ChangePassword'
import MailConfirm from './pages/MailConfirm'
import EntityCreationNew from './pages/EntityCreationNew'
import TempOnBoard from './pages/TempOnBoard'
import TrackShipMent from './pages/TrackShipMent'
import VerifyEmail from './pages/VerifyEmail'
// import { ModeProvider, useMode } from './context/AuthContext'
import { APP_VERSION } from './util/constants'

// import UsersApis from './state/users-api'

// const withModeProps = (WrappedComponent) => {
//     return (props) => {
//       const { mode } = useMode();

//       const modeFromPros = props.mode || mode;

//       return <WrappedComponent {...{...props,mode:modeFromPros}}  />;
//     };
//   };


//   const LoginWithModeProps = withModeProps(Login);




//   export default function AuthContextLogin(){
//     return <ModeProvider>
//         <LoginWithModeProps/>
//     </ModeProvider>
//   }

export default function Login(props) {
    const [signUser, setSignUser] = useState({})
    const isLogin = useRef(true);
    //let initialState = 'login'
    //if (props.mode === 'register') initialState = 'register'
    const [mode, setMode] = useState(props.mode || 'login')
    const [UserMAil, setUserMAil] = useState("")
    const [EntityData, setEntityData] = useState("")
    const [token, setToken] = useState("")
    const [source, setSource] = useState("")
    console.log(mode);
    useEffect(() => {
        console.log('INSIDE LOGIN USEEFFECT=', props.email)
        /*if (!props.email) {
            fetch(`${process.env.REACT_APP_API_SERVER}/api/v1/quotes`, { headers: Api.H() })
                .then((response) => response.json())
                .then((actualData) => console.log(actualData));
        }*/
        if (props.email) {
            AguserApi.getUser(props.email, (data) => {
                console.log('GOT DATA DURING SIGNUP=', data)
                setSignUser(data)
                setMode('register')
            })
        }
    }, [props])



    useEffect(() => {
        if (mode === 'login') {
            isLogin.current = true;
        } else if (mode === "register") {
            isLogin.current = false;
        }
        checkTOKEN()
        if (window.location.href?.includes('trackshipment')) {
            // window.location.replace('/')
            setMode('trackshipment')
        }
    }, [mode])

    const checkTOKEN = async () => {
        if (window.location.href?.split("/").includes("forgot")) {
            await AguserApi.checkToken(window.location.href?.split("/")[4], (data) => {
                console.log(data);
                setToken(data.token)
                setMode("forgot")
            })
        }
    }

    return (
        (() => {
            switch (mode) {
                case 'login':
                    return (
                        <LoginPageNew callback={setMode} source={setSource} />
                    );
                case 'register':
                    return (
                        // <SignUpPage callback={setMode} />
                        <SignUpPageNew callback={setMode} source={setSource} />
                    );
                case 'verifyEmail':
                    return (
                        // <SignUpPage callback={setMode} />
                        <VerifyEmail callback={setMode} source={source} />
                    );
                case 'reset':
                    return (
                        // <ForgotPasswordPage  callback={setMode} setuserMail={setUserMAil} />
                        <ForgotPasswordNew callback={setMode} setuserMail={setUserMAil} />
                    );
                case 'forgot':
                    return (
                        // <ForgotPasswordPage  callback={setMode}  token={token}/>
                        <ChangePassword callback={setMode} token={token} />
                    );
                case 'mailConfirm':
                    return (
                        // <ForgotPasswordPage  callback={setMode} />
                        <MailConfirm callback={setMode} userMail={UserMAil} />
                    );
                case 'entity':
                    return (
                        // <EntityCreationPage callback={setMode} isNewUser={!isLogin.current}/>
                        <EntityCreationNew callback={setMode} isNewUser={!isLogin.current} setEntityData={setEntityData} />
                    )
                case 'userpopup':
                    return (
                        // <EntityCreationPage callback={setMode} isNewUser={!isLogin.current}/>
                        <TempOnBoard callback={setMode} isNewUser={!isLogin.current} EntityData={EntityData} />
                    )
                case 'trackshipment':
                    return (
                        // <EntityCreationPage callback={setMode} isNewUser={!isLogin.current}/>
                        <TrackShipMent callback={setMode} />
                    )

                default:
                    return (<></>);
            }
        })()
    )
}
const LoginForm = (props) => {
    const [rememberMe, setRememberMe] = useState(true)
    const [ptype, setptype] = useState('password')
    const [icon, setIcon] = useState('fas fa-eye-slash')
    useEffect(() => {
        let rem = localStorage.getItem('ag_remember_user')
        if (rem) {
            if (rem === 'REMEMBER') setRememberMe(true)
            else setRememberMe(false)
        } else {
            rem = rememberMe ? 'REMEMBER' : 'FORGET'
            localStorage.setItem('ag_remember_user', rem)
        }
    }, [])
    //useProxy(userRec)
    const imUserRec = useSnapshot(loginStore)
    const txtEmail = useRef()
    const txtPassword = useRef()

    return (
        <form style={{ width: '30vw' }} >
            <div className='field has-text-right '>
                <img src={logo} style={{ width: '172px', height: '172px' }} />
            </div>
            <div className='field'>
                <label className='label'>Email</label>
                <div className='control has-icons-left'>
                    <input className='input' type='email' defaultValue={loginStore.userRec.email}
                        ref={txtEmail}
                        onKeyUp={(e) => {
                            e.target.value = e.target.value.replace(/ /g, '')
                            // console.log(e.target.value)
                        }}
                        placeholder='me@mymail.com' />
                    <span className="icon is-small is-left">
                        <i className="fa fa-envelope"></i>
                    </span>
                </div>
            </div>

            {/*<div className="field">
                <label className="label">Password</label>
                <div className='field has-addons'>
                    <div className="control has-icons-left">
                        <input className="input" type={ptype}
                            ref={txtPassword} placeholder="********" required />
                        <span className="icon is-small is-left">
                            <i className="fa fa-lock"></i>
                        </span>
                    </div>
                    <div class="control">
                        <button class="button is-flat" onClick={(e) => {
                            e.preventDefault()
                            if (ptype === 'password') {
                                setptype('text')
                                setIcon('fas fa-eye')
                            } else {
                                setptype('password')
                                setIcon('fas fa-eye-slash')
                            }
                        }}>
                            <span className="icon is-small">
                                <i className={icon}></i>
                            </span>
                        </button>
                    </div>
                </div>
                    </div>*/}
            <Agpassword myref={txtPassword} />
            <div className="field has-text-right">
                <div class="field">
                    <label className='checkbox'>
                        <input style={{ marginRight: '5px' }} type="checkbox" checked={rememberMe}
                            onClick={(e) => {
                                setRememberMe(e.target.checked)
                                console.log('REMEMBER ME=', e.target.checked)
                                const rem = e.target.checked ? 'REMEMBER' : 'FORGET'
                                localStorage.setItem('ag_remember_user', rem)
                            }} />
                        Remember Me
                    </label>
                </div>
                <button className="button is-link" onClick={
                    async (e) => {
                        e.preventDefault()
                        //console.log('loginStore=', JSON.stringify(loginStore))
                        configStore.currentSelectedEntity = {}
                        loginStore.email = txtEmail.current.value
                        const rememberMe = localStorage.getItem('ag_remember_user')
                        if (rememberMe === 'REMEMBER') {
                            localStorage.setItem('ag_email', loginStore.email)
                        }
                        let email = ""
                        if (txtEmail && txtEmail.current && txtEmail.current.value) {
                            email = txtEmail.current.value.toLowerCase()
                        }
                        // await Api.getUser(email, txtPassword.current.value, (userRec) => {
                        //     console.log(userRec,'userRec....');
                        //     if(userRec?.aguserRec?.token && userRec?.aguserRec?.token.length>0){
                        //         localStorage.setItem('devicetoken', userRec.aguserRec.token)
                        //         if(userRec?.aguserRec?.selectgst && userRec?.aguserRec?.selectgst.length>0){
                        //             for (let i = 0; i < userRec.companyList.length; i++) {
                        //                 if(userRec.companyList[i]['_id'] == userRec.aguserRec.selectgst){
                        //                     localStorage.setItem('ag_current_entity', JSON.stringify(userRec.companyList[i]))
                        //                 }
                        //             }
                        //         }
                        //         if(userRec?.aguserRec?.tour && userRec?.aguserRec?.tour.length>0){
                        //             localStorage.setItem('tour', userRec.aguserRec.tour)
                        //         }



                        //         const rememberMe = localStorage.getItem('ag_remember_user')
                        //         if (rememberMe === 'REMEMBER') {
                        //             const userRecString = JSON.stringify(userRec)
                        //             localStorage.setItem('ag_userrec', userRecString)
                        //         }
                        //     }

                        // })
                        let data = {
                            email: email,
                            password: txtPassword.current.value
                        }

                        await UsersApis.loginUser(data, (userRec) => {
                            console.log(userRec);

                            if (userRec?.aguserRec?.token && userRec?.aguserRec?.token.length > 0) {
                                localStorage.setItem('devicetoken', userRec.aguserRec.token)

                                localStorage.setItem('version', APP_VERSION)
                                // if(userRec?.aguserRec?.selectgst && userRec?.aguserRec?.selectgst.length>0){
                                //     for (let i = 0; i < userRec.companyList.length; i++) {
                                //         if(userRec.companyList[i].branches['gst'] == userRec.aguserRec.selectgst){
                                //             localStorage.setItem('ag_current_entity', JSON.stringify(userRec.companyList[i]))
                                //         }
                                //     }
                                // }
                                if (userRec?.aguserRec?.tour && userRec?.aguserRec?.tour.length > 0) {
                                    localStorage.setItem('tour', userRec.aguserRec.tour)
                                }




                                const rememberMe = localStorage.getItem('ag_remember_user')
                                if (rememberMe === 'REMEMBER') {
                                    const userRecString = JSON.stringify(userRec)
                                    localStorage.setItem('ag_userrec', userRecString)
                                }
                            }

                        })
                    }}>
                    Login
                </button>

                {/*<button style={{ float: 'right' }} className="button is-danger" onClick={
                    (e) => {
                        aguserStore.aguserRec = new AguserType()
                        props.callback('register')
                    }}>
                    Register
                </button>*/}
                <div>
                    Not registered? <a className='has-text-link is-underlined'
                        href='#' onClick={() => {
                            aguserStore.aguserRec = new AguserType()
                            props.callback('register')
                        }}>Create Account</a>
                </div>
                <div>
                    <a className='has-text-link is-underlined'
                        href='#' onClick={() => {
                            props.callback('reset');
                        }}>Forgot Password</a>
                </div>
            </div>
        </form>
    )
}

const ResetPasswordAguser = (props) => {
    const emailRef = useRef()
    return (
        <form style={{ width: '30vw' }} >
            <div className='field has-text-right '>
                <img src={logo} style={{ width: '172px', height: '172px' }} />
            </div>
            <div className='field'>
                <label className='label is-small'>Enter email address</label>
                <div className='control has-icons-left'>
                    <input className='input' type='email' defaultValue={loginStore.userRec.email}
                        ref={emailRef} placeholder='me@mymail.com' />
                    <span className="icon is-small is-left">
                        <i className="fa fa-envelope"></i>
                    </span>
                </div>
            </div>
            <div className="field has-text-right">
                <button className="button is-link" onClick={
                    (e) => {
                        e.preventDefault();
                        if (!emailRef.current.value || !emailRef.current.value.match(/^[a-zA-Z0-9_.+]+(?<!^[0-9]*)@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)) {
                            configStore.setModalMessage(`Invalid Email ID ${emailRef.current.value}`);
                        } else {
                            AguserApi.forgotPasswordAguser(emailRef.current.value, () => {
                                configStore.setModalMessage("Please check your E-Mail.")
                                props.callback('login');
                            });
                        }
                    }}>
                    Send Mail
                </button>
            </div>
        </form>
    )
}