import Api from "../state/Api";
import { configStore, contractsStore, loginStore } from "../state/CommonState";
import MonApi from "../state/mongo-api";

export default class ChartApi {
    static async getByList(url, entityIds) {
        try {
            let headers = Api.H();

            if (!loginStore.isClient()) {
                headers['Current-Entity'] = configStore.chartSelectedEntity;
            }
            headers["Content-Type"] = "application/json"
            if (!Array.isArray(entityIds) || entityIds.length === 0) {
                console.warn("No entity IDs provided for API request.")
                return null
            }
            // console.log("entityIds", entityIds);

            let payload = {
                entityIds: entityIds
            }
            const requestOptions = {
                headers: headers,
                method: "POST",
                body: JSON.stringify(payload)
            }
            const response = await fetch(url, requestOptions);
            return await response.json();
        } catch (error) {
            console.error("Error fetching data:", error);
            return null;
        }
    }
    
    static async get(url) {
        let h = Api.H()
        if(!loginStore.isClient()){
           h['Current-Entity'] = configStore.chartSelectedEntity
        }
        const requestOptions = {
            headers: h
        }

        return await fetch(url, requestOptions).then(res => res.json())
    }

    static async post(url , id) {
        let h = Api.H()
        if(!loginStore.isClient()){
           h['Current-Entity'] = configStore.chartSelectedEntity
        }
        // h.append('entityCustomerCode', id)
        let payload =  {
            entityCustomerCode : id  
          }
        const requestOptions = {
            headers: h,
            params : id,
            method: "POST",
            body : JSON.stringify(payload)
        }

        return await fetch(url, requestOptions).then(res => res.json())
    }

    static async getEnquirySummary(callback = f=>f) {
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/chart/getenquiriesdata`
        let ret = await ChartApi.get(url)
        callback(ret)
        return ret
    }
    static async getEnquiryCount() {
        let filter = {}
        if (loginStore.isClient()) filter = {'gst': configStore.currentSelectedEntity.gst }
        let ret = await MonApi.aggregate('Enquiries', [
            {
                '$match': filter
            },
            {
                '$count':'count'
            }
        ])
        console.log('RETURN FROM ENQUIRIES QUERY=', filter, ret)
        if (ret.length > 0) return ret[0].count
        return 0
    }
    static async getEnquiryQuote() {
        let filter = {}
        if (loginStore.isClient()) filter = {'gst': configStore.currentSelectedEntity.gst }
        let ret = await MonApi.aggregate('Enquiries', [
            {
                '$match': filter
            },
            {
                '$lookup':{
                    from:'Quotes',
                    localField:'_id',
                    foreignField:'_id',
                    as:'quotes'
                }
            },
            {
                '$unwind':'$quotes'
            },
            {
                '$project':{
                    _id:1,
                    'gst':1,
                    'quotes.mailedOn':1,
                    'enquiryDate':1
                }
            }
        ])
        return ret
    }
    static async getProductData(callback = f=>f) {
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/chart/getproductdata`
        let ret = await ChartApi.get(url)
        callback(ret)
        return ret
    }
    static async getFinanceData(callback = f=>f , customerCode) {
        // let url = `${process.env.REACT_APP_API_SERVER}/api/v1/common/financialsnew`
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/common/financialserp`
        if(customerCode.includes('/')){
            customerCode = customerCode.split("/")[0] 
        }
        let ret = await ChartApi.post(url , customerCode)
        callback(ret)
        return ret
    }

    static async getAllBookingsForChart(callback = f => f, entityIds) {
        
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/bookings/bookingcharts`
        let ret = await ChartApi.getByList(url, entityIds)
        callback(ret)
        return ret
    }

    static async getContractsForChart(callback = f => f, entityIds) {
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/contracts/contractscharts`
        let data = await ChartApi.getByList(url, entityIds)
        if (!contractsStore.navigateFromCharts) contractsStore.list = data
        console.log('*** LOADED NEW CONTRACTS ***')
        callback(data)
        return data
    }
}