import React, { useEffect, useRef, useState, useReducer } from "react"
import { AgmodalStore, EntityV2Store, configStore, entityStore, modalStore, quoteDataRec } from "../state/CommonState"
import EnquiriesApi from "../state/enq-api"
import Utils from "../util/Utils"
import { enquiryStore } from "../enquiry/enquiry-data"
import { loginStore } from "../state/CommonState"
import { subscribe, useSnapshot } from "valtio"
import { Nodata } from "../mui-components/nodatafound"
import Api from "../state/Api"
import RoleUtils from "../util/RoleUtils"
import { PERMISSIONS } from "../util/constants"
import ReactTablePagination from "../components/ag-table/ReactTablePagination"
import { useTable, usePagination, useSortBy } from "react-table"
import { DRS_COLUMNS } from "../components/ag-table/columns";
import { reducer, PAGE_CHANGED, PAGE_SIZE_CHANGED, PAGE_SORT_CHANGED, PAGE_FILTER_CHANGED, TOTAL_COUNT_CHANGED, PAYLOAD_CHANGED } from "../components/ag-table/ag-reducer"
import ApiAgTable from "../components/ag-table/api_ag-table"
import { loader } from "../util/loader"
import Agproducttab from "../components/Agproducttab"
import TableFilter from "../components/ag-table/TableFilter"

export default function ListEnquiryV3(props) {
    useSnapshot(configStore)
    useSnapshot(entityStore)
    useSnapshot(enquiryStore)
    useSnapshot(modalStore)
    useSnapshot(AgmodalStore)
    const [currentEntity, setCurrentEntity] = useState(configStore.currentSelectedEntity.gst)
    const [list, setList] = useState([])
    const txtSearch = useRef()

    const filterStatus = {
        'ENQUIRY': 'ENQUIRY',
        'DRAFT': 'DRAFT',
        'FINAL': 'FINAL',
        'DRAFT(Enquiry)': 'DRAFT(Enquiry)',
        'CANCELLED': 'CANCELLED',
        // 'ACCEPTED': 'ACCEPTED'
    }

    const shipmentTypeStatus = {
        'fcl': 'FCL',
        'lcl': 'LCL',
        'air': 'AIR'
    }

    function getstatus(type) {
        console.log("the type came here is ",type)
        return {
            'ENQUIRY': 'In-process',
            'DRAFT': 'In-process',
            'FINAL': 'Quoted',
            //   'ACCEPTED': 'Quoted'
        }[type]
    }

    const statusFilter = {
        'Enquiry':'ENQUIRY',
        'Yet to be Quoted':'DRAFT(Enquiry)',
        'Draft':'DRAFT',
        'Final':'FINAL',
        'Cancelled':'CANCELLED'
    }
    const [selectedFilter, setSelectedFilter] = useState(`Enquiry`);
    const [shipmentFilter, setShipmentFilter] = useState(`LCL`)
    const [usertypeFilter, setUsertypeFilter] = useState(`ALL`)
    const [search, setSearch] = useState('')
    const [entityFilter, setEntityFilter] = useState([])
    const [selectedEntityFilter, setSelectedEntityFilter] = useState(`ALL`)
    const [createdFilter, setCreatedFilter] = useState([])
    const [selectedCreatedFilter, setSelectedCreatedFilter] = useState(`ALL`)
    const maxDate = new Date();
    const today = new Date();
    const oneMonthBefore = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    const formatDate = date => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const [startDate, setStartDate] = useState(formatDate(oneMonthBefore))
    const [endDate, setEndDate] = useState(formatDate(today))

    const onClickFilterCallback = (filter) => {
        setSearch(filter)
    }

    const validateDateRange = (from, to) => {
        if (from && to && from > to) {
            // setValidationError('End date must be equal to or after the start date.');
        } else {
            // setValidationError('');
        }
    };

    const handleStartChange = (e) => {
        setStartDate((e.target.value))
        validateDateRange(startDate, endDate)
    }

    const handleEndChange = (e) => {
        setEndDate(e.target.value)
        validateDateRange(startDate, endDate)
    }


    function contractsopen(id) {
        if (loginStore.isClient()) {
            window
            .open(`${window.location.href}contractsv2/view/${id+"C"}`, "_blank")
            .focus();
        } else {
            Api.getQuote(id);
            AgmodalStore.modalPage = 'QuoteViewv3';
            AgmodalStore.pageVisible = true;
        }

    }

    const getEntityName = (entityId) => {
        if(entityId === "TBA")
            return "YET TO BE ASSIGNED"
        let entity = EntityV2Store.list.filter(e => e._id === entityId)

        if (entity.length === 0) return entityId
        return entity[0].entityName
    }

    const createNewEnquiry=()=>{
        enquiryStore.initialize();
        quoteDataRec.quotationNum = ""
        configStore.disableShipmentType = ""
        configStore.homescreenCurrentShipmentType = "LCL"
        configStore.homescreenCurrentShipmentScope = "Port-to-Port"
        enquiryStore.currentCargoTableKey = "" + new Date().getMilliseconds();
        enquiryStore.current.createdBy = loginStore.email;
        enquiryStore.current.gst = configStore.currentSelectedEntity.gst;
        enquiryStore.current.shipmentType = configStore.homescreenCurrentShipmentType;
        enquiryStore.current.countryFromEnquiry =  "India"
        configStore.homescreenCurrentView = "new";
        configStore.scheduleOrCarrierform = "schedule";
        AgmodalStore.pageVisible = true
        AgmodalStore.apiCall = false
        AgmodalStore.modalPage = "AgEnquiry-v3"
        modalStore.checkingOrigin = false
        modalStore.checkingDestination = false
        configStore.buttondisable = false
        configStore.acceptMail = false
        configStore.checkedEmptyDropdown = false
    }

    function ShipmentType (){

        // let list = enquiryStore.list.filter(e => {
        //     let str = Object.values(e).join('')
        //     str = str.toLowerCase()
        //     if (str.indexOf(configStore.homescreenCurrentShipmentType.toLowerCase()) >= 0) return e
        // })
        setList(enquiryStore.list)
        // txtSearch.current?.value = ''    
    }
    const initialState = {
        queryPageIndex: 0,
        queryPageSize: 10,
        totalCount: 0,
        queryPageFilter: "",
        queryPageSortBy: [],
        queryPayload: {}
    };
    const [{ queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy, queryPayload }, dispatch] =
        React.useReducer(reducer, initialState)
    const totalPageCount = Math.ceil(totalCount / queryPageSize)
    const [data1, setData1] = useState({ results: [] })
    let columns = DRS_COLUMNS

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageCount,
        pageOptions,
        gotoPage,
        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable({
        columns,
        data: data1?.results,
        initialState: {
            pageIndex: queryPageIndex,
            pageSize: queryPageSize,
            sortBy: queryPageSortBy,
        },
        manualPagination: true,
        pageCount: data1 ? totalPageCount : null,
        autoResetSortBy: false,
        autoResetExpanded: false,
        autoResetPage: false
    },
        useSortBy,
        usePagination,
    );

    const manualPageSize = []

    useEffect(() => {
        dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    }, [pageIndex, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
        gotoPage(0);
    }, [pageSize, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAYLOAD_CHANGED, payload: { filter: statusFilter[selectedFilter], shipmentType: shipmentFilter, fromDate: startDate, toDate: endDate, customerName: selectedEntityFilter, createdBy: selectedCreatedFilter,usertypeFilter:usertypeFilter } });
        gotoPage(0);
    }, 
    [selectedFilter, shipmentFilter, startDate, endDate, selectedEntityFilter, selectedCreatedFilter, gotoPage,usertypeFilter]);

    useEffect(() => {
        if(search.length === 0){
            dispatch({ type: PAYLOAD_CHANGED, payload: { filter: statusFilter[selectedFilter], shipmentType: shipmentFilter, fromDate: startDate, toDate: endDate, customerName: selectedEntityFilter, createdBy: selectedCreatedFilter,usertypeFilter:usertypeFilter } });
            gotoPage(0);
        }
    }, [search]);
    
    const onClickSearch = () => {
        if ((search.length > 0)) {
         
          dispatch({ type: PAYLOAD_CHANGED, payload: { search: search, filter: statusFilter[selectedFilter], shipmentType: shipmentFilter, fromDate: startDate, toDate: endDate, customerName: selectedEntityFilter, createdBy: selectedCreatedFilter,usertypeFilter:usertypeFilter } });
          gotoPage(0);
          
        }else{
          configStore.setModalMessage(`Please Provide Valid Input`)  
        }
    };

    useEffect(() => {
        if (data1?.count) {
            dispatch({ type: TOTAL_COUNT_CHANGED, payload: data1.count });
        }
    }, [data1?.count]);

    useEffect(() => {
        async function fetchdata() {
            try {
                // EnquiriesApi.getAllEnquiries()
                if(AgmodalStore.apiCall){
                    if (queryPayload.fromDate && queryPayload.toDate) {
                        loader.show()
                        const filters = EnquiriesApi.getFilter(queryPayload, (data) => {
                            setEntityFilter(data.customerNameList)
                            setCreatedFilter(data.createdList)
    
                        })
                        console.log(filters, "filteres");
                        const response = await ApiAgTable.getAllEnquiries(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload)
                        const resp = await response.json();
                        if (resp.results && resp.results.length > 0) {
                            enquiryStore.list = resp.results
                            setData1(resp)
                        }else{
                            enquiryStore.list = []
                            setData1({ count: 0, results: [] });
                        }
                        loader.hide()
                    }
                    let unsubscribe = subscribe(configStore, async () => {
                        if (currentEntity != configStore.currentSelectedEntity.gst) {
                            setCurrentEntity(configStore.currentSelectedEntity.gst)
                            // EnquiriesApi.getAllEnquiries()
                            const response = await ApiAgTable.getAllEnquiries(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload)
                            const resp = await response.json();
                            if (resp.results && resp.results.length > 0) {
                                enquiryStore.list = resp.results
                                setData1(resp)
                            } else {
                                enquiryStore.list = []
                                setData1(resp)
                                enquiryStore.list = [];
                                setData1({count: 0, results: []})
                            }
                        } else {
                            ShipmentType()
                        }
                    })
                    return unsubscribe
                }
                
            } catch (error) {
                console.log("errrr", error);
                enquiryStore.list = []
                setData1([])
            }
        }
        fetchdata()

    }, [configStore, currentEntity, configStore.currentSelectedEntity, configStore.currentSelectedBranch, queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload, AgmodalStore.apiCall])
    // useEffect(() => {
    //     let list = enquiryStore.list.filter(e => {
    //         let str = Object.values(e).join('')
    //         str = str.toLowerCase()
    //         console.log("the filter is ", selectedFilter, " ", str.indexOf(selectedFilter.toLowerCase()), " ", str)
    //         if (selectedFilter === "ALL") {
    //             if (str.indexOf("final") == -1) return e
    //         } else if (str.indexOf(selectedFilter.toLowerCase()) !== -1) {
    //             return e
    //         }

    //     })
    //     setList(list)
    // }, [selectedFilter])

    useEffect(()=>{
        let unsubscribe = subscribe(enquiryStore, () => {
            if(loginStore.isClient()){
                ShipmentType()
            } else {
                // let list = enquiryStore.list.filter(e => {
                //     let str = Object.values(e).join('')
                //     str = str.toLowerCase()

                //     if (str.indexOf("Final") == -1) return e

                // })
                setList(enquiryStore.list)
            }
        })
        return unsubscribe

    },[enquiryStore])

    useEffect(()=>{
        console.log("the entity list isa ", entityStore.list)
        entityStore.list = loginStore.userRec.companyList;
    },[])

    // const search = () => {
    //     const searchStr = txtSearch.current.value.trim().toLowerCase();
    //     let list;
    //     if (searchStr.length > 0) {
    //         const regex = new RegExp(searchStr, 'i');
    //         list = enquiryStore.list.filter(e => regex.test(e._id.toLowerCase()));
    //         setList(list);
    //     } else {
    //         setList(enquiryStore.list);
    //     }
    // };
    // const search = (value='ALL') => {
    //     SetSelectedFilter(value)
    //     const searchStr = txtSearch.current.value.toLowerCase()
    //     // console.log(enquiryStore.list,"searchStrsearchStr"); 
    //     let list = enquiryStore.list.filter(e => {
    //         let str = Object.values(e).join('')
    //         str = str.toLowerCase()
    //         if(loginStore.isClient()){
    //             console.log("inside 1 if",configStore.homescreenCurrentShipmentType.toLowerCase());             
    //             // 44A0062
    //             if (str.indexOf(configStore.homescreenCurrentShipmentType.toLowerCase()) >= 0) return e
    //         }else{
    //             console.log("inside 1 else");             
    //             return e
    //         }
    //     })

    //     if(!loginStore.isClient() && value!='ALL'){
    //         console.log("inside 2 if")         
    //         list = list.filter(e => {
    //             let str = Object.values(e).join('')
    //             str = str.toLowerCase()
    //             if (str.indexOf(value.toLowerCase()) >= 0) return e
    //         })
    //     }
    //     if(searchStr.length>0){
    //         console.log(list,"list");         
    //         list = list.filter(e => {
    //             let str = Object.values(e).join('')+Object.values(e.enqRoute).join('')+Utils.convertDateformat(e.enquiryDate)
    //             str = str.toLowerCase()
    //             if (str.indexOf(searchStr) >= 0) return e
    //         })
    //     }
    //     setList(list)
    //   }
    // if (entityStore.list.length === 0) return <></>
    return (
        <>
            {
                (!props.viewonly && loginStore.isClient())?
                    <>
                        <div class="columns">
                            <div class="column">
                                <h5 className="title is-4"
                                    style={{ color: '#313131', fontFamily: 'product_sans_bold' }}>Recent Enquiries</h5>
                            </div>
                            <div class="column ">
                                <div className="field has-addons" style={{float:"right"}}>
                                    <p className="control" >
                                        <input ref={txtSearch} className="input is-small" type="text" placeholder="Search"
                                            style={{ width: '20rem' }} onKeyUp={(e) => {
                                                setSearch((e.target.value).trim())
                                            }} />
                                    </p>
                                </div>
                            </div></div>

                    </>
                    :
                    <>
                        <h5 className="title is-4"
                            style={{ color: '#313131', fontFamily: 'product_sans_bold' }}>Enquiries</h5>
                        <div className="field has-addons">
                            {/* {
                                (RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.AGRAGA_ENQUIRY_BUTTON)) ?
                                    <p className="control">
                                        <button className="button is-normal islink" style={{ marginRight: "1em", width: "5em", borderRadius: "5px" }} onClick={
                                            // enquiryStore.currentEnquiryListComponent = "new"
                                            createNewEnquiry

                                        }>
                                            New
                                        </button>
                                    </p> : <></>
                            } */}
                            <div class="level-item">
                                <TableFilter
                                    onClickSearch={onClickSearch}
                                    onClickFilterCallback={onClickFilterCallback}
                                    defaultKeyword={search}
                                />
                            </div>

                            {
                                (!loginStore.isClient()) ?
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1 }}>
                                        <div className="level-item level-left ml-2">
                                            <label style={{ marginRight: '5px', fontSize: 'large', fontWeight: 'bold' }}>From: </label>
                                            <input type="date" className="input is-normal" value={startDate} max={endDate} onChange={handleStartChange} ></input>
                                        </div>
                                        <div className="level-item level-left ml-2">
                                            <label style={{ marginRight: '5px', fontSize: 'large', fontWeight: 'bold' }}>To: </label>
                                            <input type="date" className="input is-normal" value={endDate} min={startDate} max={formatDate(maxDate)} onChange={handleEndChange} ></input>
                                        </div>
                                    </div> : <></>
                            }


                        </div>
                        <div>
                            {

                                (!loginStore.isClient()) ? <>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        {/* <div class="level-item level-left ml-2">
                                            <div class="field">
                                                <p class="control has-icons-left has-icons-right">
                                                    <span class="select">
                                                        <select name="status" id="status" value={selectedFilter} onChange={(e) => {
                                                            console.log("the slected filter is ", e.target.value)
                                                            setSelectedFilter(e.target.value)
                                                        }}>
                                                            <option value="ALL" disabled>Status</option>
                                                            <option value="ALL">ALL</option>
                                                            {
                                                                Object.entries(filterStatus).map(([key, value]) => (
                                                                    <option value={value}>{value}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <span class="icon">
                                                            <i class="fa-solid fa-filter"></i>
                                                        </span>
                                                    </span>
                                                </p>
                                            </div>
                                        </div> */}
                                        {/* <div class="level-item level-left ml-2">
                                            <div class="field">
                                                <p class="control has-icons-left has-icons-right">
                                                    <span class="select">
                                                        <select name="status" id="status" value={shipmentFilter}
                                                            // style={{maxWidth:'130px'}}
                                                            onChange={(e) => {
                                                                setShipmentFilter(e.target.value)
                                                            }}>
                                                            <option value="ALL" disabled>Shipment Type</option>
                                                            <option value="ALL">ALL</option>
                                                            {
                                                                Object.entries(shipmentTypeStatus).map(([key, value]) => (
                                                                    <option value={value}>{value}</option>
                                                                ))
                                                            }
                                                            ShipmentType
                                                        </select>
                                                        <span class="icon">
                                                            <i class="fa-solid fa-filter"></i>
                                                        </span>
                                                    </span>
                                                </p>
                                            </div>
                                        </div> */}
                                        <div class="level-item level-left ml-2">
                                            <div class="field">
                                                <p class="control has-icons-left has-icons-right">
                                                    <span class="select">
                                                        <select placeholder="Customer Name" name="status" id="status" value={selectedEntityFilter}  onChange={(e) => 
                                                        setSelectedEntityFilter(e.target.value)}
                                                            style={{ maxWidth: '200px', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                                            <option value="ALL" disabled>Customer Name</option>
                                                            <option value="ALL">ALL</option>
                                                            {
                                                                entityFilter.map((value) => (
                                                                    <option value={value} key={value}>{value}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <span class="icon">
                                                            <i class="fa-solid fa-filter"></i>
                                                        </span>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="level-item level-left ml-2">
                                            <div class="field">
                                                <p class="control has-icons-left has-icons-right">
                                                    <span class="select">
                                                        <select name="status" id="status" value={selectedCreatedFilter} style={{ maxWidth: '200px', whiteSpace: 'normal', wordWrap: 'break-word' }} onChange={(e) => {
                                                            setSelectedCreatedFilter(e.target.value)
                                                        }}>
                                                            <option value="ALL" disabled>Created By</option>
                                                            <option value="ALL">ALL</option>
                                                            {
                                                                createdFilter.map((value) => (
                                                                    <option value={value}>{value}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <span class="icon">
                                                            <i class="fa-solid fa-filter"></i>
                                                        </span>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="level-item level-left ml-2">
                                            <div class="field">
                                                <p class="control has-icons-left has-icons-right">
                                                    <span class="select">
                                                        <select name="status" id="status" value={usertypeFilter} onChange={(e) => {
                                                            setUsertypeFilter(e.target.value)
                                                        }}>
                                                            <option value="ALL" disabled>User Type</option>
                                                            <option value="ALL">ALL</option>
                                                            <option value="Non-Agraga">Non-Agraga</option>
                                                            <option value="Agraga-User">Agraga-User</option>
                                                        </select>
                                                        <span class="icon">
                                                            <i class="fa-solid fa-filter"></i>
                                                        </span>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </>
                                    : <></>
                            }
                        </div>
                    </>

            }
            <div>
                <Agproducttab padding={'5px'} productList={['Enquiry','Yet to be Quoted','Draft','Final', 'Cancelled']}
                    activeProduct={selectedFilter} setActiveProduct={setSelectedFilter} />
            </div>
            <div>
                <Agproducttab productList={['LCL', 'FCL', 'AIR']}
                    activeProduct={shipmentFilter} setActiveProduct={setShipmentFilter} />
            </div>

            <div className="box" style={{ flex: 1, paddingBottom: "10px" }}>
                <div className="table-container">
                    <table className="table is-narrow is-small is-fullwidth">
                        <thead>
                            <tr>
                                <th>Enquiry #</th>
                                {
                                    (!props.viewonly && loginStore.isClient())?
                                        <>
                                            <th>
                                                Customer Ref #
                                            </th>
                                            <th>
                                                Enquiry Date & Time
                                            </th>

                                        </>:
                                        <th>Entity</th>
                                }
                                <th>Created By</th>
                                <th>Origin</th>
                                <th>Destination</th>
                                {/* {
                                    (!props.viewonly && loginStore.isClient()) ?
                                        <></> :
                                        <th>Shipment Type</th>
                                } */}

                                {/* <th>Status</th> */}
                                {
                                    (!props.viewonly && loginStore.isClient()) ?
                                        <></> :
                                        <th style={{ width: '10rem' }}>Created On</th>
                                }
                                {
                                    (!props.viewonly && loginStore.isClient()) ?
                                        <></> :
                                        <th>TAT</th>
                                }

                            </tr>
                        </thead>
                        <tbody className="contractlist" style={{ fontSize: '0.9rem' }}>
                            {
                                list.map(e => {
                                    return (
                                        <tr onClick={() => {
                                            switch (e.status) {
                                                // case 'CANCELLED':
                                                //     break;
                                                case 'FINAL':
                                                    contractsopen(e._id)
                                                    break;
                                                case 'ACCEPTED':
                                                    contractsopen(e._id)
                                                    break;
                                                case 'DRAFT':
                                                    Api.getQuote(e._id);
                                                    AgmodalStore.pageVisible = true;
                                                    AgmodalStore.modalPage = "QuoteViewMainv3";
                                                    break;
                                                case 'DRAFT(Enquiry)':
                                                    Api.getQuote(e._id);
                                                    AgmodalStore.modalPage = "Quotev3";
                                                    AgmodalStore.pageVisible = true;
                                                    break;
                                                default:
                                                    if(!loginStore.isClient()){
                                                        EnquiriesApi.getEnquiry(e._id, (data) => {
                                                            let stype = data.shipmentType.substr(0, 3).toLowerCase()
                                                            if (stype === 'lcl') configStore.homescreenCurrentShipmentType = 'LCL'
                                                            else if (stype === 'fcl') configStore.homescreenCurrentShipmentType = 'FCL'
                                                            else configStore.homescreenCurrentShipmentType = 'Air'
                                                            configStore.homescreenCurrentShipmentScope = data.shipmentScope

                                                        })
                                                        AgmodalStore.modalPage = "ViewEnquiry-v3";
                                                        AgmodalStore.pageVisible = true;
                                                    }
                                                    break;
                                            }



                                        }}>
                                            <td>{e._id}</td>
                                            {
                                                (!props.viewonly && loginStore.isClient()) ?
                                                    <>
                                                        <td>{e.enqRoute.customerReferenceNum}</td>
                                                        <td>{Utils.convertDateformat(e.enquiryDate)}</td>
                                                    </> :
                                                    <td>{getEntityName(e.entityId)}</td>
                                            }
                                            <td>{e.createdBy}</td>
                                            <td>{e.enqRoute?.origin}</td>
                                            {
                                                (e.enqRoute?.destination2!=undefined)?
                                                <td>Multiple</td>:
                                                <td>{e.enqRoute?.destination}</td>
                                            }
                                            {/* {
                                                (!props.viewonly && loginStore.isClient()) ?
                                                    <></> :
                                                    <td>{e.shipmentType === 'LCL' ? e.shipmentType : (e.shipmentType === 'Air' ? e.shipmentType : (e.shipmentType === 'FCL (Dock/Dock)' ? 'FCL (CFS/CFS)' : (e.shipmentType === 'FCL (Dock/Factory)' ? 'FCL (CFS/Factory)' : (e.shipmentType === 'FCL (Factory/Dock)' ? 'FCL (Factory/CFS)' : (e.shipmentType === 'FCL (Factory/Factory)' ? 'FCL (Factory/Factory)' : '')))))}</td>
                                            } */}
                                            {/* <td title={e.status === 'CANCELLED' ? e.reason : ''}>{

                                                (!loginStore.isClient()) ?
                                                    e.status :
                                                    <>{
                                                        getstatus(e.status)
                                                    }</>
                                            }
                                            </td> */}
                                            {
                                                (!props.viewonly && loginStore.isClient()) ?
                                                    <></> :
                                                    <td>{Utils.convertDateformat(e.enquiryDate)}</td>
                                            }
                                            {
                                                (!props.viewonly && loginStore.isClient()) ?
                                                    <></> :
                                                    <td>{e.status !== 'CANCELLED' ?
                                                        Utils.gettimer(
                                                            e.status === 'ENQUIRY' ? e.tsCreatedOn :
                                                                e.status === 'DRAFT(Enquiry)' ? (e.tsDraftEnquiryOn??'NA') :
                                                                    e.status === 'FINAL' ? e.tsFinalizedOn :
                                                                        e.status === 'DRAFT' ? (e?.tsQuoteSubmittedOn ?? 'NA') : '')
                                                        : ''}</td>
                                            }

                                        </tr>
                                    )
                                })
                            }
                        </tbody>

                    </table>
                    {list.length === 0 && loginStore.isClient() && <Nodata width="250px" height="250px" />}
                </div>
                <ReactTablePagination
                    page={page}
                    gotoPage={gotoPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageOptions={pageOptions}
                    pageSize={pageSize}
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    setPageSize={setPageSize}
                    manualPageSize={manualPageSize}
                    dataLength={totalCount}
                    rows={list}
                />

            </div>
        </>
    )
}