import { useEffect, useState } from "react"
import { subscribe, useSnapshot } from "valtio"
import Agswitch, { Agswitch1 } from "../components/ag-switch"
import AgLabel from "../components/AgLabel"
import Api from "../state/Api"
import { configStore, contractsStore, entityStore, loginStore, modalStore, quoteStore, enquiriesValidationStore } from "../state/CommonState"
import SchApi from "../state/sch-api"
import AirScheduleComp from "./AirScheduleComp"
import HomescreenCargo from "./homescreen-cargo"
import HomescreenContainer from "./homescreen-container"
import CarrierWebForm from "./CarrierWebForm"
import ContainerClientView from "./ContainerClientView"
import LCLSchedules from "./LCLSchedules"
import HomescreenRoute from "./homescreen-route"
import { enquiryStore, routeFlag } from "./enquiry-data"
import EnquiryConfirmation from "./enquiry-confirmation"
import EnquiriesApi from "../state/enq-api"
import Agproducttab from "../components/Agproducttab"
import Utils from "../util/Utils"
import QuoteCalc from "../util/quote-calc"
import { ContainerDetails } from "../state/Types"
import { CargoDetails } from "../QuotationData"
import Newapi from "../state/new-api"
import { BookingLocations } from "./booking-locations"
import ScheduleUtils from "../util/SchedulesUtils"
import { DummySchedule } from "./dummy-schedule"
import QuotationUtils from "../util/quotationUtil"
import ListEnquiry from "./list-enquiry"
import { useGlobalState } from "../GlobalState"
import RoleUtils from "../util/RoleUtils";
import { PERMISSIONS } from "../util/constants";
import { IconButton } from "@mui/material"
import backarrow  from "../assets/images/backarrow.svg";

export default function HomeScreenLCL(props) {

    const { currentMenu } = useGlobalState();
    console.log('INSIDE HOMESCREEN LCL')
    useSnapshot(contractsStore)
    useSnapshot(enquiryStore)
    useSnapshot(configStore)
    useSnapshot(entityStore)
    useSnapshot(modalStore)
    const rec = contractsStore.current

    let client = loginStore.isClient()
    let isUserAuthorized = RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.CONVERT_QUOTATIONS_TO_CONTRACTS)

useEffect(()=>{
  return ()=>{
    enquiryStore.initialize();
    configStore.homescreenCurrentView = "list";
        configStore.displaySchedules = false; 
        if(configStore.showQoute){
            configStore.showQoute = false;
                }
  }
},[])

    // console.log(rec)
    const [saveEnabled, setSaveEnabled] = useState(false)
    const [schedules, setSchedules] = useState([])
    const [fclSchedules, setFclSchedules] = useState([])
    const [activeProduct, setActiveProduct] = useState(configStore.homescreenCurrentShipmentType)
    let productList = ['LCL', 'FCL', 'Air']
    let e = enquiryStore.current
    if (loginStore.isClient() && contractsStore.current.isFromEnquiry && e.tsClientviewOn == undefined) {
        e.tsClientviewOn = Date.now()
        let id = contractsStore.current._id
        if (id.endsWith('C')) {
            id = id.substr(0, id.length - 1)
        }
        EnquiriesApi.updateToClientView(id)
    }

    // below line is a temporary fix to hide Air. To be removed.
    // productList = ['LCL', 'FCL']
    if (contractsStore.current['mode'] == 'CONTRACT' && contractsStore.current['quoteStatus'] != 'DECLINED' && contractsStore.current['quoteStatus'] != 'ACCEPTED') {
        contractsStore.current['quoteStatus'] = "ACCEPTED"
    }


    // let enquiryNum = configStore.enquiryModalNum

    const NmO_onClick = () => {
        let stat = EnquiriesApi.saveEnquiry()
        EnquiriesApi.getAllEnquiries()
        
        if (stat) {setActiveProduct("LCL")
        configStore.displaySchedules = false
        configStore.showEnquiries = true
        contractsStore.current = {}
        modalStore.checkingDestination = false
        configStore.homescreenCurrentShipmentType = "LCL"}
        
    }

    let enquiriesValidationStoreSnap = useSnapshot(enquiryStore.current)


    const onClick = () => {
        enquiriesValidationStore.cargoReadinessDate = enquiriesValidationStoreSnap.enqRoute.cargoReadinessDate.trim() === ''
        enquiriesValidationStore.origin = enquiriesValidationStoreSnap.enqRoute.origin.trim() === ''
        enquiriesValidationStore.destination = enquiriesValidationStoreSnap.enqRoute.destination.trim() === ''


        configStore.displaySchedules = true
        if (props.viewonly) {
            if (activeProduct.toLowerCase() === "fcl" && !enquiryStore.current.containerDetails.every(QuotationUtils.isValidContainer)) {
                configStore.setModalMessage("Invalid Container details.");
                return;
            }
            if (activeProduct.toLowerCase() !== "fcl" || (activeProduct.toLowerCase() === "fcl" && QuotationUtils.hasCargoDetail(enquiryStore.current.shipmentType, enquiryStore.current.shipmentScope))) {
                if (enquiryStore.current.cargoDetails.length === 0 || !enquiryStore.current.cargoDetails.every(cargo => QuotationUtils.isValidCargo(activeProduct, enquiryStore.current.shipmentScope, cargo))) {
                    console.log("🚀 ~ file: home-screen-lcl.js ~ line 170 ~ HomeScreenLCL ~ enquiryStore", enquiryStore.current.cargoDetails)
                    configStore.setModalMessage("Invalid Cargo details.");
                    return;
                }
            }

            contractsStore.current = enquiryStore.current.mergeIntoContract(rec)


        } else {
            Api.getMergedContract()
        }
    }


    useEffect(() => {
        if (!configStore.displaySchedules) configStore.showEnquiries = true
        if (props.viewonly || !props.viewonly) {
            (activeProduct === 'LCL') ?
                SchApi.getSchedules(rec._id, setSchedules) :
                SchApi.getSchedules(rec._id, setFclSchedules)
        }
        if (props.viewonly) {
            if (!configStore.displaySchedules) configStore.displaySchedules = true
        }
    }, [rec._id], e.stuffingType, e.destuffingType)
    //console.log('>>>>>>>>>>>', rec, props.viewonly)
    if (!rec._id && props.viewonly) return <></>
    let contractId = rec._id
    if (rec.mode === 'QUOTE') contractId = contractId.substr(0, contractId.length - 1)
    let title = `Create Booking - ${contractId}`
    if (!props.viewonly) title = 'Enquiries'
    let displayCargo4fcl = false


    {
        let e = enquiryStore.current
        // let stype = activeProduct.toLowerCase()
        let stype = e.shipmentType.substr(0, 3).toLowerCase()
        if (stype === 'fcl' && (modalStore.checkingOrigin === false && modalStore.checkingDestination === true)) {
            if (e.destuffingType === 'Dock') {
                displayCargo4fcl = true
            }

        }
        else if (stype === 'fcl' && (modalStore.checkingOrigin === true && modalStore.checkingDestination === false)) {
            if (e.stuffingType === 'Dock') {
                displayCargo4fcl = true
            }
        }
        else if (stype === 'fcl' && (modalStore.checkingOrigin === true && modalStore.checkingDestination === true)) {
            displayCargo4fcl = true
            if (e.stuffingType === 'Factory' && e.destuffingType === 'Factory') {
                displayCargo4fcl = false
            }
        }
        console.log('#########>>>', modalStore.checkingOrigin, modalStore.checkingDestination, e.stuffingType, e.stuffingType, stype, displayCargo4fcl)
    }

    { //assign shipping type to enquiry
        let e = enquiryStore.current
        let stype = activeProduct.toLowerCase()
        if (stype === 'lcl') {
            e.shipmentType = 'LCL'
        } else if (stype === 'air') {
            // if (e.temperatureControlled === 'Yes')
            //     e.shipmentType = 'Air (Temperature Controlled)'
            // else
            //     e.shipmentType = 'Air (Normal)'
            e.shipmentType = 'Air'
        } else {
            // if (e.temperatureControlled === 'Yes') {
            //     e.shipmentType = 'FCL (Temperature Controlled)'
            // } else {
            //     e.shipmentType = `FCL (${e.stuffingType}/${e.destuffingType})`
            // }
            e.shipmentType = `FCL (${e.stuffingType}/${e.destuffingType})`
        }
        console.log('*** SHIPMNET TYPE ***', e.shipmentType)
    }
    {
        if (currentMenu == "Create Booking" || currentMenu == "Upload Schedules") {
            if (contractsStore.current._id) {
                let reqdata = {}
                let shipmentType = configStore.homescreenCurrentShipmentType.toUpperCase()

                if (configStore.homescreenCurrentShipmentType == 'LCL') {
                    let currentDate = new Date()
                    let cargoReadinessDate = (contractsStore.current.cargoReadinessDate && contractsStore.current.cargoReadinessDate.length >= 10) ?
                        new Date(contractsStore.current.cargoReadinessDate) : currentDate
                    if (cargoReadinessDate < currentDate) cargoReadinessDate = currentDate
                    if (isNaN(cargoReadinessDate)) cargoReadinessDate = currentDate
                    let startDays = (contractsStore.current.portOfLoading === contractsStore.current.portOfReceipt) ? 2 : 8 // the logic in mail is >= 4/13, equivalent to this > 3/12
                    let startDate = Utils.mmddyyyy(Utils.addDays(cargoReadinessDate, startDays))
                    let endDate = Utils.mmddyyyy(Utils.newDate(contractsStore.current.validUpto))
                    reqdata = {
                        shipmentType: shipmentType,
                        startDate: startDate,
                        endDate: endDate
                    }
                }
                if (configStore.homescreenCurrentShipmentType == 'FCL') {
                    reqdata = {
                        shipmentType: shipmentType
                    }
                }
                if (shipmentType == 'LCL' || shipmentType == 'FCL') {
                    Api.getSchedulesList(contractsStore.current._id, reqdata, (data) => {
                        if (data.length > 0) {
                            if (shipmentType == 'LCL' && (schedules.join('') != data.join(''))) {
                                setSchedules(data)
                            }
                            if (shipmentType == 'FCL') {
                                setFclSchedules(data)
                            }
                        }
                    })
                }
            }
        }
    }
    const parent = "agi4"
    var parent_container
    var newHandle = function (e) {
        let parent_container1 = null
        if (parent) {
            parent_container = e.target;
            do {
                parent_container = parent_container?.parentNode;

                if (!parent_container || parent_container === document.body) {
                    break;
                }

                if (parent_container.id != parent) {
                    parent_container1 = null;
                } else {
                    parent_container1 = parent_container;
                }
            } while (parent_container1 == null);

        }
    };
    var newHandle1 = function (e) {
        if (parent && parent_container) {
            let i = parent_container.getElementsByTagName('input')
            i[0].files = e.clipboardData.files
            if (e.clipboardData.files.length > 0) {
                change(i[0].files)
            }
        }
    };
    if (parent) {
        document.getElementsByTagName('body')[0].onclick = function (e) { newHandle(e) }
        document.getElementsByTagName('body')[0].onpaste = function (e) { newHandle1(e) }

    }

    function change(files) {
        contractsStore.current.hasToShowDummySchedule = false;
        const formData = new FormData()
        formData.append('myFile', files[0])
        formData.append('action', 'schedule_upload')
        formData.append('shipmentType', rec.shipmentType)
        formData.append('id', rec._id)
        fetch(`${process.env.REACT_APP_API_SERVER}/api/v1/common/convertxltojson`, {
            method: 'POST',
            headers: Api.H1(),
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                console.log('got data', data)
                setSaveEnabled(true)
                if (data.error) { if (data.error != 'No Error' || data.error != 'Invalid Token') { configStore.setModalMessage(data.error) } }
                else {
                    (activeProduct === 'LCL') ?
                        setSchedules(data) :
                        setFclSchedules(data)
                    document.getElementById(parent).getElementsByTagName('input')[0].value = ""
                }
            })
            .catch(error => {
                console.error(error)
            })
    }

    console.log(activeProduct, 'activeProduct');
    console.log(displayCargo4fcl, 'displayCargo4fcl2');
    // console.log("Consoling the Enquiry",configStore.homescreenCurrentShipmentType)
    return (
        <>

            <EnquiryConfirmation />
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%"}}>
            <div style={{display:"flex",flexDirection:"row",gap:1,alignItems:"center"}}>
            <IconButton disableRipple onClick={()=>{
                   if(configStore.showQoute){
                     configStore.showQoute = false;
                     return;
                   }
                   configStore.homescreenCurrentView = "list"
            }}>
                <img src={backarrow}/>
            </IconButton>
            <h5 className="title is-4" style={{ color: '#2c358a', fontFamily: 'product_sans_bold' }}>
                {title}
            </h5>
            </div>
            { !loginStore.isClient()  && <button  className="button is-small islink"
                                onClick={ (e) => {
                                    enquiryStore.initialize();
                                    configStore.homescreenCurrentView = "list"
                                }}>
                                <span className="icon ">
                                    <i className="fas fa-xmark"></i>
                                </span>
                            </button>
}
            </div>         
            {
                (!props.viewonly) ?
                    <Agproducttab productList={productList}
                        activeProduct={activeProduct} setActiveProduct={setActiveProduct} />
                    : <></>
            }
            <div className="columns">
                <div className="column is-4">
                    <HomescreenRoute {...props} />
                </div>
                <div className="column is-8">
                    {
                        (activeProduct === 'FCL') ?
                            <HomescreenContainer {...props} /> :
                            <HomescreenCargo {...props} isFromUploadSchedules={true} />
                    }
                </div>
            </div>
            {/* {
                (displayCargo4fcl) ?
                    <div className="columns">
                        <div className="column is-2">
                        </div>
                        <div className="column is-10">
                            <HomescreenCargo {...props}
                             displayCargo4fcl={true}
                              />
                        </div>
                    </div> : <></>
            } */}
            {
                (displayCargo4fcl
                ) ?
                    <div className="columns">
                        <div className="column is-2">
                        </div>
                        <div className="column is-10">
                            <HomescreenCargo {...props}
                                displayCargo4fcl={true}
                            />
                        </div>
                    </div> : <></>
            }
            {
                (!loginStore.isClient() && ['lcl', 'fcl'].includes(activeProduct.toLowerCase())) ?
                    <div className="columns">
                        <div className="column is-2">
                        </div>
                        <div className="column is-10">
                            <BookingLocations />
                        </div>
                    </div> : <></>
            }
            <div className="columns">
                <div className="column is-12">
                    {
                        (props.viewonly && loginStore.isClient()) ?
                            <button style={{ float: 'left' }}
                                className="button is-rounded is-small islink" onClick={() => {
                                    let customTC = []
                                    if (rec['customTC']) {
                                        customTC = rec['customTC'].split(/\r?\n/)
                                    }
                                    rec['tc'].map(e => customTC.push(e))
                                    configStore.setModalMessage("", [], false, customTC)
                                }}>Terms and Conditions</button> :
                            <></>


                    }
                    {
                        (props.viewonly && rec.quoteStatus != 'ACCEPTED' && rec.quoteStatus != 'DECLINED' && (new Date(rec.validUpto + " 23:59:00").getTime() > new Date().getTime()) && (isUserAuthorized || client)) ?
                            <button style={{ float: 'right', marginRight: "20px" }}
                                className="button is-rounded is-small islink is-danger" onClick={() => {
                                    configStore.setModalDecline(rec._id)
                                }}>Decline</button> :
                            <></>


                    }
                    {
                        (props.viewonly && rec.quoteStatus != 'ACCEPTED' && rec.quoteStatus != 'DECLINED' && (new Date(rec.validUpto + " 23:59:00").getTime() > new Date().getTime()) && (isUserAuthorized || client)) ?
                            <button style={{ float: 'right', marginRight: "20px" }}
                                className="button is-rounded is-small islink is-success" onClick={() => {
                                    Api.createContractFromQuote(rec._id)
                                    Api.getContracts()
                                    configStore.setModalMessage("Quotation is accepted.")
                                    contractsStore.current['quoteStatus'] = "ACCEPTED"
                                }}>Accept</button> :
                            <></>


                    }

                    {/* {
                        (!props.viewonly && displayMoreOptions && enquiriesValidationStoreSnap.enqRoute.cargoReadinessDate && enquiriesValidationStoreSnap.enqRoute.origin && enquiriesValidationStoreSnap.enqRoute.destination) ?
                            <button style={{ float: 'right' }} onClick={() => {
                                NmO_onClick()
                            }}
                                className="button is-small is-rounded islink">
                                Need more Options
                            </button> :
                            <></>

                    } */}

                    {
                        (!props.viewonly) ?
                            <button
                                style={{ float: 'right' }}
                                className="button is-rounded is-small islink"
                                onClick={() => {
                                    // if (configStore.addressvalidation) {
                                    onClick();
                                    if (enquiriesValidationStoreSnap.enqRoute.cargoReadinessDate && configStore.addressvalidation && enquiriesValidationStoreSnap.enqRoute.origin && enquiriesValidationStoreSnap.enqRoute.destination) {
                                        NmO_onClick()
                                    }
                                    // } else {
                                    //     configStore.setModalMessage('Please check input fields');

                                    // };

                                }}
                            >
                                Submit
                            </button> :
                            <>
                                {
                                    (!configStore.displaySchedules) ?
                                        <button style={{ float: 'right' }}
                                            className="button is-rounded is-small islink" onClick={() => {
                                                onClick()
                                            }}>Re-calulate</button> :
                                        <></>
                                }
                            </>
                    }

                </div>
            </div >
            {
                (loginStore.userRec.aguserRec.role.indexOf('CLIENT') < 0 &&
                    configStore.homescreenCurrentShipmentType !== 'Air' &&
                    rec.status === "UPLOAD_SCHEDULES" && 
                    configStore.scheduleOrCarrierform === 'schedule') ?
                    <div className="columns" style={{ border: '2px solid blue' }} id="agi4">
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }} id="agi4">
                            <label style={{ float: 'right' }} for="schedulefile">Load Schedules, verify and Save</label>
                            <input id="schedulefile" type="file" onChange={(e1) => {
                                const pattern = /^\S[\s\S]*\S$|^\S$/;
                                if (pattern.test(e1.target.files[0]['name'])) {
                                    change(e1.target.files)
                                } else {
                                    configStore.setModalMessage("Please Check File Name and Format")
                                    return e1.target.value = ""
                                }
                            }} />
                            {
                                (activeProduct === 'LCL' ?
                                    <><a style={{ fontSize: "12px", textDecoration: "underline" }} className="is-link" href="./LCL_Template.csv" download="LCL_Template.csv">Download LCL Template</a></> :

                                    <><a style={{ fontSize: "12px", textDecoration: "underline" }} className="is-link" href="./FCL_Template.csv" download="FCL_Template.csv">Download FCL Template</a></>)
                            }
                            {
                                ((activeProduct === 'LCL' ? schedules : fclSchedules)
                                    .every(schedule => !ScheduleUtils.isValidSchedule(activeProduct.toLowerCase(), schedule, contractsStore.current)) &&
                                    !contractsStore.current?.hasToShowDummySchedule &&
                                    contractsStore.current.validUpto >= Utils.getCurrentDateString() && (isUserAuthorized || client))
                                && <button className="button is-info is-small" onClick={() => {
                                    setSaveEnabled(true)
                                    setSchedules([]);
                                    setFclSchedules([]);
                                    contractsStore.current.hasToShowDummySchedule = true;
                                }}>Create Dummy</button>
                            }
                            {
                                (saveEnabled && (isUserAuthorized || client)) ?
                                    <button className="button is-danger is-small" onClick={async () => {
                                        (activeProduct === 'LCL') ?
                                            SchApi.saveSchedules(rec._id, schedules) :
                                            SchApi.saveSchedules(rec._id, fclSchedules)
                                        await Newapi.releaseContractLock(rec._id)
                                        rec.status = "SCHEDULES_UPLOADED"
                                        setSaveEnabled(false)
                                        // configStore.homescreenCurrentView = 'list'
                                        configStore.scheduleOrCarrierform = 'schedule'
                                    }}>
                                        Save Schedules
                                    </button>
                                    : <></>
                            }
                        </div>
                    </div> : <></>
            }
            {
                (configStore.displaySchedules) ?
                    <div className="columns">
                        <div className="column is-12">
                            {
                                (() => {
                                    if (configStore.scheduleOrCarrierform === 'schedule') {
                                        if (contractsStore.current?.hasToShowDummySchedule && contractsStore.current.validUpto >= Utils.getCurrentDateString()) return <DummySchedule stype={activeProduct} />;
                                        if (configStore.homescreenCurrentShipmentType === 'Air' && contractsStore.current.validUpto >= Utils.getCurrentDateString()) return <AirScheduleComp />;
                                        else return <LCLSchedules data={activeProduct === 'LCL' ? schedules : fclSchedules} />;
                                    } else return <CarrierWebForm />;
                                })()
                                // (configStore.scheduleOrCarrierform === 'schedule') ?
                                //     (configStore.homescreenCurrentShipmentType === 'Air') ?
                                //         <AirScheduleComp />
                                //         :
                                //         <LCLSchedules data={activeProduct === 'LCL' ? schedules : fclSchedules} />
                                //     :
                                //     <CarrierWebForm />
                            }
                        </div>
                    </div>
                    : <></>
            }
            {/* {
                (!props.viewonly && loginStore.isClient() && configStore.showEnquiries) ?
                    <ListEnquiry {...props} /> :
                    <></>
            } */}
        </>

    )
}