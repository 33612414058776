import { subscribe, useSnapshot } from "valtio";
import { configStore } from "../state/CommonState";
import TaskDatetime from "./task-datetime";
import Taskfileapproval, { validateFileApproval } from "./task-file-approval";
import Taskfiledisplay from "./task-file-display";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
import { useEffect, useState } from "react";

export default function Otheraction(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    let stage = task.stage

    if (stage >= 1) {
        return (
            <>
                <hr />
                <Taskfiledisplay validation={true} />
            </>
        )
    }
}
export function OtheractionTrigger(props) {
    useSnapshot(taskStore)
    const [disableButton,setDisableButton] = useState(true)
    useEffect(()=>{
        let unsubscribe = subscribe(taskStore, () => {
            const hasFilename = taskStore.current.files.some(e => e.filename !== '')
            setDisableButton(!hasFilename); 
            })
        return unsubscribe
    },[taskStore])
    return (
        <>
            <Taskfiletable />
            <p>
                <span>
                    <button disabled={disableButton} style={{ float: 'left' }} className="button islink"
                        onClick={async (e) => {
                            taskStore.current.stage = 1
                            taskStore.current.pendingWith = 'AGRAGA'
                            taskStore.current.status = 'COMPLETED'
                            taskStore.setComplete()
                            taskStore.setCustomerComplete()
                            setDisableButton(true)
                            await Taskapi.saveTask(taskStore.current)
                            taskStore.triggerActionVisible = false
                            taskStore.myActionVisible = false
                        }}>Trigger Action</button>
                </span>
            </p>
        </>
    )
}