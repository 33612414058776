import { useSnapshot } from "valtio";
import { AgmodalStore, loginStore } from "../state/CommonState";
import QuoteViewMainv2 from "../quotes/quote-view-main-v2";
import QuoteViewv2 from "../quotes/quote-view-v2"
import Quotev2 from "../quotes/quote-v2";
import ViewEnquiryv2 from "../enquiryv2/view-enquiry-v2";
import HomeScreenv2 from "../enquiryv2/home-screen-v2";
import TaskContainer from "../mytasks/task-container";
import ViewEnquiry from "../enquiry/view-enquiry";
import ListEnquiry from "../enquiry/list-enquiry"
import AgEnquiry from "../enquiry/AgEnquiry";
import DtQuoteView from "../dt/DtQuoteView";
import DtQuote from "../dt/DtQuote";
import DtMail from "../dt/DtMail";
import EditEntity from "../entity-v2/Entities/edit-entity-v2";
import EditBranch from "../entity-v2/Branches/edit-branch";
import EditCreditApproval from "../entity-approvals/edit-creditApproval"
import EditEntityApproval from "../entity-approvals/edit-entityApproval"
import EditDriverApproval from "../entity-approvals/edit-driverApproval"
import EditVehicleApproval from "../entity-approvals/edit-vehicleApproval"
import EditBranchApproval from "../entity-approvals/edit-branchApproval"
import EditUserApproval from "../entity-approvals/edit-userApproval"
import EditVehicle from "../vehicle/edit-vehicle-new"
import EditDrivers from "../drivers/edit-drivers-new"
import ViewAguser from "../agusers/view-aguser"
import EditAguser from "../agusers/edit-aguser"
import DtBooking from "../dt/DtBooking";
import { taskStore } from "../mytasks/task-store";
import AgEnquiryv3 from "../enquiryv3/AgEnquiry-v3";
import ViewEnquiryv3 from "../enquiryv3/view-enquiry-v3";
import Quotev3 from "../quotesv3/quote-v3";
import QuoteViewv3 from "../quotesv3/quote-view-v3";
import HomeScreenv3 from "../enquiryv3/home-screen-v3";
import MainEntityApproval from "../entity-approvals/main-entityApproval";

export default function AgNewModal() {
    useSnapshot(AgmodalStore)
    let pageClass = 'modal';
    if (AgmodalStore.pageVisible) pageClass += ' is-active';

      const renderComponent = (component) => {
        switch (component) {
            //  CROSS-BORDER
            case 'Quotev2':
                return <Quotev2 />; 
            case 'QuoteViewMainv2':
                return <QuoteViewMainv2 />;
            case 'QuoteViewv2':
                return <QuoteViewv2 />;
            case 'Quotev3':
                return <Quotev3 />; 
            case 'QuoteViewMainv3':
                return <QuoteViewv3 view={"view"}/>;
            case 'QuoteViewv3':
                return <QuoteViewv3 view={"pureView"}/>;
            case 'listEnquiry': 
                return <ListEnquiry />;
            case 'ViewEnquiryv2':
                return <ViewEnquiryv2 viewonly='1' showbooking='1' />;
            case 'HomeScreenv2':
                return <HomeScreenv2 viewonly='1' />;
            case 'HomeScreenv3':
                return <HomeScreenv3 viewonly='1' />;
            case 'TaskContainer':
                return <TaskContainer />;
            case 'ViewEnquiry':
                return <ViewEnquiry viewonly='1' />;
            case 'AgEnquiry':
                return <AgEnquiry />;
            case 'AgEnquiry-v3':
                return <AgEnquiryv3 />;
            case 'ViewEnquiry-v3':
                return <ViewEnquiryv3 viewonly='1' />;
            case 'ViewEnquiryBooking-v3':
                return <ViewEnquiryv3 viewonly='1' showbooking='1' />;

            // DOMESTIC-TRANSPORT
            case 'DtQuoteView':
                return <DtQuoteView />;
            case 'DtQuote':
                return <DtQuote />;
            case 'DtMail':
                return <DtMail />;
            case 'BookingPage': 
                return <DtBooking />
            default:
                return <></>;
            // ENTITIES
            case 'NewEntity':
                return <EditEntity mode="new" />;
            case 'viewEntity':
                return <EditEntity mode="view" />;
            case 'editEntity':
                return <EditEntity mode="edit" />;
            // BRANCHES
            case 'editBranches':
                return <EditBranch mode="edit" />;
            // APPROVALS
            case "newCreditApproval":
                return <EditCreditApproval mode="new" />
            case "entityAPP":
                return <EditEntityApproval mode="entityAPP" />
            case "driverAPP":
                return <EditDriverApproval mode="driverAPP" />
            case "vehicleAPP":
                return <EditVehicleApproval mode="vehicleAPP" />
            case "branchAPP":
                return <EditBranchApproval mode="branchAPP" />
            case "userAPP":
                return <EditUserApproval mode="userAPP" />
            // VECHILE
            case "viewVehicle":
                return <EditVehicle mode="view" />
            case "editVehicle":
                return <EditVehicle mode="edit" />
            case "newVehicle":
                return <EditVehicle mode="new" />
            case "listVehicle":
                return <EditVehicle mode="list" />
            // DRIVERS
            case "viewDrivers":
                return <EditDrivers mode="view"  />;
            case "editDrivers":
                return <EditDrivers mode="edit"  />;
            case "newDrivers":
                return <EditDrivers mode="new"  />;
            // USERS
            case "ViewAguser": 
                return <ViewAguser />
            case 'editAguser': 
                return <EditAguser mode='edit'/>
            case 'newAguser': 
                return <EditAguser mode='new'/>
        }
      };
      console.log("+==========",AgmodalStore.modalPage)

    return (
        <>
            {   
                !loginStore.isClient() && AgmodalStore.pageVisible ? 
                <div className={pageClass} onClick={() => { AgmodalStore.pageVisible = false }} style={{zIndex:100, justifyContent: "unset", top: "52px" }}>
                    <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{  borderRadius: '5px', width: '100%', height: '100vh' }} >
                        <section className="modal-card-body" >
                            <button className="button is-danger" style={{ float: 'right', borderRadius: '50%', width: '25px', height: '32px', margin: '0 20px 0 20px' }}
                                onClick={() => { 
                                    if(AgmodalStore.modalPage=="TaskContainer"){
                                        AgmodalStore.modalPage = ""
                                    }
                                    if(AgmodalStore.modalPage == "QuoteViewMainv3" || AgmodalStore.modalPage=="Quotev3" || AgmodalStore.apiCall === false){
                                        AgmodalStore.apiCall = true;  
                                    }
                                    AgmodalStore.pageVisible = false
                                    if(taskStore.triggerActionVisible) {
                                        taskStore.triggerActionVisible = false;
                                    }
                                    if(taskStore.vendorAssignment) {
                                        taskStore.vendorAssignment = false;
                                    }
                                    }}>X</button>
                            { renderComponent(AgmodalStore.modalPage) }
                        </section>
                    </div>
                </div> : <></>
            }
        </>
    )
}