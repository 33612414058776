import React from 'react';
import ResultsCount from './ResultsCount';
import TableFilter from '../../components/ag-table/TableFilter';
import CreateButton from './Button';
import RoleUtils from '../../util/RoleUtils';
import { PERMISSIONS } from '../../util/constants';

const SecondaryHeader = ({ data1, onClickSearch, onClickFilterCallback,defaultKeyword, addButton,icon, allBranches, selectedBranch, setSelectedBranch, selectedStatus, setSelectedStatus }) => {
  return (
    <nav className="level">
      <div className="level-left">
        <ResultsCount count={data1.count} title="Company" />
        {(RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_SHIPPER)) && <CreateButton addButtonCallback={addButton} icon={icon} butttonText="Add Company" />}
        <TableFilter onClickSearch={onClickSearch} onClickFilterCallback={onClickFilterCallback} defaultKeyword={defaultKeyword} placeholder={"Search Company Details"} />
        {/* <BranchSelect allBranches={allBranches} selectedBranch={selectedBranch} setSelectedBranch={setSelectedBranch} /> */}
        {/* <UserStatusSelect selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} /> */}
      </div>
    </nav>
  );
};

export default SecondaryHeader;
