import { useSnapshot } from "valtio";
import { AgTextShow } from "../components/AgCustomComponents";
import { contractsStore } from "../state/CommonState";
import { enquiryStore } from "../enquiry/enquiry-data"

const getUNCodeFromLocationString = (locString) =>
  locString
    .trim()
    .slice(locString.lastIndexOf("(") + 1, locString.length - 1)
    .trim();

export function BookingLocationsv3(props) {
  useSnapshot(contractsStore);
  useSnapshot(enquiryStore);
  let carrier = ''
  if(contractsStore.current.multicharge==undefined || contractsStore.current.multicharge.length==0){
    carrier = getUNCodeFromLocationString(
      contractsStore.current.carrier
    )
  }else{
    let c = []
    for (let i = 0; i < contractsStore.current.multicharge.length>0; i++) {
      let k = contractsStore.current.multicharge[i]
      c.push(getUNCodeFromLocationString(k.carrier))
    }
    carrier = c.join(', ')
  }
  return (
    <div
      style={{ boxShadow: "5px 5px #dddddd", border: "1px solid lightgrey" }}
    >
      <div className="card-content">
        <div className="content">
          <div className="columns">
            <div className="column">
              <AgTextShow
                label="Place of Receipt"
                value={getUNCodeFromLocationString(
                  contractsStore.current.portOfReceipt
                )}
              />
            </div>
            <div className="column ">
              <AgTextShow
                label="Port of Loading"
                value={getUNCodeFromLocationString(
                  contractsStore.current.portOfLoading
                )}
              />
            </div>
            <div className="column">
              <AgTextShow
                label="Port of Discharge"
                value={getUNCodeFromLocationString(
                  contractsStore.current.portOfDischarge
                )}
              />
            </div>
            <div className="column ">
              <AgTextShow
                label="Final Place of Delivery"
                value={getUNCodeFromLocationString(
                  contractsStore.current.finalPlaceOfDelivery
                )}
              />
            </div>
            <div className="column ">
              {contractsStore.current.shipmentType
                .toLowerCase()
                .includes("lcl") ? (
                <AgTextShow
                  label={"Consolidator(s)"}
                  value={contractsStore.current.consolidatorList
                    .map(({ scacCode }) => scacCode)
                    .join(`, `)}
                />
              ) : (
                <AgTextShow
                  label={"Carrier"}
                  value={carrier}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
