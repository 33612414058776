import logo from './logonew.png'
import entityicon from './entity.png'
import { lazy, Suspense, useRef, useState } from 'react'
import { useProxy } from 'valtio/macro'
import { routerStore, loginStore, quoteDataRec } from './state/CommonState'
import Quote from './Quote'
import TestForm from './TestForm'
import { useGlobalState } from './GlobalState'
import { configStore } from './state/CommonState'
import AgModal from './components/AgModal'
import { subscribe, useSnapshot } from 'valtio'
import { useEffect } from 'react'
import Api from './state/Api'
import EditAguser from './agusers/edit-aguser'
import AguserApi from './state/AguserApi'
import MyProfile from './agusers/my-profile'
import MyProfileNew from './agusers/my-profile-new'
import AcceptRegistration from './agusers/accept-registration'
import { AgSelect } from './components/AgComponents'
import OperatingCompany from './components/operating-company'
import { menucountStore } from './state/menuStore'
import { tourStore } from './tourStore'
import { AgNavBar } from './mui-components/AgNavBar'
import RoleUtils from './util/RoleUtils'
import { PERMISSIONS } from './util/constants'
import MobileAppTemp from './MobileAppTemp'
import Utils from './util/Utils'
import { enquiryStore } from './enquiry/enquiry-data'
const Layout = lazy(()=>import("./pages/Layoutpage").then(module=>({default:module.Layout})))

function Navbar() {
    const { leftMenuHidden, setLeftMenuHidden, changeCurrentMenu } = useGlobalState()
    useSnapshot(loginStore)
    useSnapshot(tourStore)
    let menuToggleClass = leftMenuHidden ? 'fa-solid fa-angles-right' : 'fa-solid fa-angles-left'
    return (
        <nav className='navbar has-shadow is-dark' style={{ backgroundColor: '#2c358a' }}>
            <div className='navbar-brand'>
                <div className='navbar-item'>

                    <span className="icon is-small" onClick={() => setLeftMenuHidden(!leftMenuHidden)}>
                        <i className={menuToggleClass}></i>
                    </span>

                </div>
                <a className='navbar-item'>
                    <img src={logo} />
                </a>
                <div className="navbar-burger">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div className='navbar-menu'>
                <div className="navbar-start">
                    <div className="navbar-item">
                        <small>Engineering the Supply-chain of Tomorrow</small>
                    </div>

                </div>
                <div className="navbar-end" style={{ paddingRight: '5rem' }}>
                    {
                        (loginStore?.userRec?.aguserRec?.tour == 'no' && loginStore?.userRec?.aguserRec?.email.indexOf('agraga.com')==-1)?<a className="navbar-item" onClick={()=>{
                            tourStore.enable = true
                            tourStore.show = true
                            let d = JSON.parse(localStorage.getItem('ag_userrec'))
                            d.aguserRec.tour = 'yes'
                            d = JSON.stringify(d)
                            localStorage.setItem('ag_userrec',d)
                            loginStore.userRec.aguserRec.tour = 'yes'
                            let data = {email:loginStore.userRec.email,tour:"yes"}
                            AguserApi.updatetour(data)
                        }}>Show Tour</a>:""
                    }
                    {
                        (loginStore.isClient()) ? <OperatingCompany /> : <></>
                    }

                    <div className="navbar-item has-dropdown is-hoverable tour2" onMouseOver={()=>tourStore.changestep(tourStore.step)}>
                        <div className={"navbar-link"+ (tourStore.step==4 && tourStore.enable ? ' is-active' : '')}  >
                            {loginStore.userRec.firstName} {loginStore.userRec.lastName}
                            <span className="icon is-small" style={{ marginLeft: '5px' }}>
                                <i className="fa-regular fa-user"></i>
                            </span>
                        </div>
                        <div className="navbar-dropdown tour3" style={{display: tourStore.step==4 && tourStore.enable ? 'block' : ''}}>
                            <a className="navbar-item " onClick={() => {
                                tourStore.nextstep()
                                // AguserApi.getUser(loginStore.email)
                                configStore.currentMainComponent = <MyProfileNew />

                            }}>
                                <div>
                                    <span className="icon ">
                                        <i className="fa fa-user-circle"></i>
                                    </span>
                                    <span className='ml-2'>Profile</span>
                                </div>
                            </a>
                            <a className="navbar-item" onClick={() => {
                                let token = localStorage.getItem("devicetoken")
                                let d = JSON.parse(localStorage.getItem("ag_userrec"))
                                if(!d){
                                    loginStore.reinitialize()
                                    localStorage.removeItem('ag_email')
                                    localStorage.removeItem('tour')
                                    localStorage.removeItem('ag_current_entity')
                                    localStorage.removeItem('ag_userrec')
                                    localStorage.removeItem('devicetoken')
                                }else{
                                    AguserApi.logout({token:token,email:d.email},()=>{
                                        console.log("logout")
                                    })
                                }
                                // loginStore.reinitialize()
                                // localStorage.removeItem('ag_email')
                                // localStorage.removeItem('ag_userrec')
                            }}>
                                <div>
                                    <span className="icon is-small">
                                        <i className="fa fa-sign-out"></i>
                                    </span>
                                    <span className='ml-2'>Sign Out</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}
function menuFilter(e) {

    if(e?.isRoleBased) {
        switch(e.key) {
            case 'Spot Booking - LCL':
                return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.SPOT_BOOKING_LCL);
            case 'Upload Schedulesv2':
                return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.UPLOAD_SCHEDULES);
            case 'Users':
                return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.USERS);
            case 'Enquiries':
                return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.ENQUERIES_RECEIVED);
            case 'Quotationv2':
                return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.QUOTATIONS_CB);
            case 'Bookingsv2':
                return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.BOOKINGS_CB);
            case 'Visibility':
                return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.VISIBILITY);
            case 'My Actions':
                return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.MY_ACTIONS_CB);
            case 'Domestic Quotations':
                return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.QUOTATIONS_DT);
            case 'Domestic Transport Booking':
                return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.BOOKINGS_DT);
            case 'Domestic Transport Actions':
                return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.MY_ACTIONS_DT);
            case 'Config':
                return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.CONFIG);
            case 'Uploads':
                return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.UPLOADS);
            // case 'Entities':
            //     return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.ENTITIES);
            case 'Entitiesv2':
                return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.ENTITIES_V2);
            case 'Vehicle List':
                return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.VEHICLES);
            case 'Drivers List':
                return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.DRIVERS);
            case 'Audit':
            //     return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.AUDIT);
                return process.env.REACT_APP_AUDIT && JSON.parse(process.env.REACT_APP_AUDIT).includes(loginStore?.email);
            case 'Site-Information':
                return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.SITE_INFORMATION);
            case 'Home':
                // return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.HOME);
                // return configStore.homeUsersList?.length > 0 ? configStore.homeUsersList?.includes(loginStore?.email) : false
                return configStore.homeUsersList?.includes(loginStore?.email)
            // case 'Operations CB':
            //     return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.OPERATIONS_CB);
            // case 'Sales CB':
            //     return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.SALES_CB);
            case 'Approvals':
                return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.APPROVALS);
            case 'FCL Pricing':
                return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.ANALYTICS_FCL_PRICING);
            case 'Operations CB':
                return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.REMAINING_ANALYTICS);
            case 'Sales CB':
                return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.REMAINING_ANALYTICS);
            case 'Customers':
                if(Utils.isProductionURL())
                return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.CUSTOMERS);
            case '':
                if(e.name === 'Cross-Border'){
                    return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.CROSS_BORDER);
                } else if (e.name === 'Domestic Transport'){
                    return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.DOMESTIC_TRANSPORT);
                } else if(e.name === 'Analytics'){
                    return RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.ANALYTICS);
                }
                else {
                    return false;
                }
            default:
                return true;
        }

    } else if (e?.exclude && !e?.isRoleBased) {
        if (e?.exclude.indexOf(loginStore.userRec.aguserRec.role) >= 0) {
            return false
        }
    }
    else if( e?.excludeMenu && configStore?.currentSelectedEntity?.isTrackMyBooking !== "Yes"){
        return false
    }
    return true
}

function Leftmenu() {
    useSnapshot(menucountStore)
    let mc = menucountStore
    const [check, setCheck] = useState(false)
    useEffect(() => {
        //Api.getmenucount()
        let unsubscribe = subscribe((menucountStore, configStore.currentSelectedEntity), () => {
            mc = menucountStore
        })
        return unsubscribe
    }, [menucountStore, configStore.currentSelectedEntity])

    if (!check) {
        setCheck(true)
        // Api.getmenucount()
    }
    const checkpermission = (keyname)=>{
        switch (keyname) {
            // case "Site-Information":
            //     return (process.env.REACT_APP_SITEINFORMATION!=undefined && JSON.parse(process.env.REACT_APP_SITEINFORMATION).indexOf(loginStore.email) != -1)?true:false
            // case "Analytics":
            //     return ((process.env.REACT_APP_SALES_CB!=undefined && JSON.parse(process.env.REACT_APP_SALES_CB).indexOf(loginStore.email) != -1) || (process.env.REACT_APP_OPEARTIONS_CB!=undefined && JSON.parse(process.env.REACT_APP_OPEARTIONS_CB).indexOf(loginStore.email) != -1))?true:false
            // case "Opeartions CB":
            //     return (process.env.REACT_APP_OPEARTIONS_CB!=undefined && JSON.parse(process.env.REACT_APP_OPEARTIONS_CB).indexOf(loginStore.email) != -1)?true:false
            // case "Sales CB":
            //     return (process.env.REACT_APP_SALES_CB!=undefined && JSON.parse(process.env.REACT_APP_SALES_CB).indexOf(loginStore.email) != -1)?true:false
            // case "Home":
            //     return (process.env.REACT_APP_HOME!=undefined && JSON.parse(process.env.REACT_APP_HOME).indexOf(loginStore.email) != -1)?true:false
            // case "Uploads":
            //     return (process.env.REACT_APP_UPLOADS!=undefined && JSON.parse(process.env.REACT_APP_UPLOADS).indexOf(loginStore.email) != -1)?true:false
            // case "Audit":
            //     return (process.env.REACT_APP_AUDIT!=undefined && JSON.parse(process.env.REACT_APP_AUDIT).indexOf(loginStore.email) != -1)?true:false
            default:
                return false
        }
    }
    const checkskip = (keyname)=>{
        if(loginStore.isClient()) return true
        // if(["Site-Information","Analytics","Opeartions CB","Sales CB","Home","Uploads","Audit"].indexOf(keyname) == -1){return true}else{return false}
        // if([].indexOf(keyname) == -1){return true}else{return false}
        return true;
    }

    let { menuList, clientMenuList, currentMenu, changeCurrentMenu } = useGlobalState()
    if (loginStore.isClient()) menuList = clientMenuList
    if (loginStore.isClient()) mc.Visibility = 0

    return (
        <nav className='menu' id='menu' style={{ padding: '0px', zoom: '0.9' }}>
            {/*<p className='menu-label' style={{color: 'white'}}>
                Menu
    </p>*/}
            <ul className="menu-list">
                {(loginStore.userRec.aguserRec.status === 'ACTIVE') ?
                    menuList.filter(menuFilter).map(menu => {
                        if (menu.children != undefined) {
                            return (
                                <>
                                    {
                                        ((checkpermission(menu.name) && !checkskip(menu.name)) ||
                                            (!checkpermission(menu.name) && checkskip(menu.name)))?<li key={menu.key} className={menu.classname+"-menu"}>
                                            <a className={menu.classname+"-menu"}style={{fontWeight: 'bold', fontStyle: 'italic', marginBottom: '0px'}}>
                                                {menu.name} {(mc[menu.key.replace(/ /g, '')] != undefined && mc[menu.key.replace(/ /g, '')] != 0) ? `(${mc[menu.key.replace(/ /g, '')]})` : ''}

                                            </a>
                                            <ul className="menu-list" style={{marginTop: '0px'}}>{
                                                menu.children.filter(menuFilter).map(menu1 => {
                                                    let classname = ''
                                                    if (menu1.key === currentMenu) classname = 'is-active'
                                                    let menu1Style = {}
                                                    if (menu1.key === currentMenu) menu1Style = { backgroundColor: '#eea724', color: 'black' }
                                                    menu1Style.textAlign = 'left'
                                                    return (
                                                        <>
                                                            {
                                                                ((checkpermission(menu1.name) && !checkskip(menu1.name)) ||
                                                                    (!checkpermission(menu1.name) && checkskip(menu1.name)))?<li key={menu1.key} className={menu1.classname+"-menu"}>
                                                                    <a style={menu1Style} href={menu1.href}
                                                                        onClick={(e) => {
                                                                            e.preventDefault()
                                                                            if(menu1.key=="Spot Booking WIP"){
                                                                                enquiryStore.initialize();
                                                                                quoteDataRec.quotationNum = ""
                                                                                configStore.disableShipmentType = ""
                                                                                configStore.homescreenCurrentShipmentType = "LCL"
                                                                                configStore.homescreenCurrentShipmentScope = "Port-to-Port"
                                                                                enquiryStore.currentCargoTableKey = "" + new Date().getMilliseconds();
                                                                                enquiryStore.current.createdBy = loginStore.email;
                                                                                enquiryStore.current.gst = configStore.currentSelectedEntity.gst;
                                                                                enquiryStore.current.shipmentType = configStore.homescreenCurrentShipmentType;
                                                                                enquiryStore.current.countryFromEnquiry =  "India"
                                                                            }
                                                                            changeCurrentMenu(menu1.key)
                                                                        }}>
                                                                        {
                                                                            (menu1.key === 'Home') ?
                                                                                <span className="icon" style={{ marginRight: '5px' }}>
                                                                                    <i class="fa-solid fa-house"></i>
                                                                                </span>
                                                                                : <></>
                                                                        }
                                                                        {menu1.name} {(mc[menu1.key.replace(/ /g, '')] != undefined && mc[menu1.key.replace(/ /g, '')] != 0) ? `(${mc[menu1.key.replace(/ /g, '')]})` : ''}
                                                                    </a>
                                                                </li>:""}
                                                        </>
                                                    )
                                                })
                                            }

                                            </ul>
                                        </li>:""
                                    }
                                </>

                            )
                        } else {
                            let classname = ''
                            if (menu.key === currentMenu) classname = 'is-active'
                            let menuStyle = {}
                            if (menu.key === currentMenu) menuStyle = { backgroundColor: '#eea724', color: 'black' }
                            menuStyle.textAlign = 'left'
                            return (
                                <>
                                    {
                                        (checkpermission(menu.name))?<li key={menu.key} className={menu.classname+"-menu"}>
                                            <a style={menuStyle} href={menu.href}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    changeCurrentMenu(menu.key)
                                                }}>
                                                {
                                                    (menu.key === 'Home') ?
                                                        <span className="icon" style={{ marginRight: '5px' }}>
                                                            <i class="fa-solid fa-house"></i>
                                                        </span>
                                                        : <></>
                                                }
                                                {menu.name} {(mc[menu.key.replace(/ /g, '')] != undefined && mc[menu.key.replace(/ /g, '')] != 0) ? `(${mc[menu.key.replace(/ /g, '')]})` : ''}
                                            </a>
                                        </li>:''
                                    }
                                    {
                                        (checkskip(menu.name))?<li key={menu.key} className={menu.classname+"-menu"}>
                                            <a style={menuStyle} href={menu.href}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    changeCurrentMenu(menu.key)
                                                }}>
                                                {
                                                    (menu.key === 'Home') ?
                                                        <span className="icon" style={{ marginRight: '5px' }}>
                                                            <i class="fa-solid fa-house"></i>
                                                        </span>
                                                        : <></>
                                                }
                                                {menu.name} {(mc[menu.key.replace(/ /g, '')] != undefined && mc[menu.key.replace(/ /g, '')] != 0) ? `(${mc[menu.key.replace(/ /g, '')]})` : ''}
                                            </a>
                                        </li>:''
                                    }

                                </>

                            )
                        }

                    }) : <></>
                }
            </ul>
        </nav>
    )
}

function Content() {
    useSnapshot(configStore)
    const { leftMenuHidden, setLeftMenuHidden, menuList, currentMenu } = useGlobalState()
    let classnames = 'column is-2 '
    if (leftMenuHidden) classnames += 'is-hidden'
    if (loginStore.userRec.aguserRec.status !== 'ACTIVE') AguserApi.getUser(loginStore.userRec.email)
    return (
        <section className='section' style={{ paddingTop: '0px', marginTop: '0px' }}>
            <div className='columns'>
            { loginStore.isClient() ? <></> :  <div className={classnames} style={{
                    height: '95vh', overflow: 'auto',
                    backgroundColor: '#2c358a', borderRadius: '0px 0px 0px 0px', paddingTop: '2rem',paddingLeft:"1.5rem"
                }}>
                    <Leftmenu />
                </div>
            }
                <div className='column' style={{ height: '95vh', overflow: 'scroll', paddingTop: '1rem',overflowX:"hidden" }}>
                    {
                        //menuList.filter(menu => menu.key === currentMenu).map(menu => menu.component)
                        (loginStore.userRec.aguserRec.status === 'ACTIVE') ?
                            configStore.currentMainComponent :
                            <AcceptRegistration id={loginStore.userRec.email} />
                    }
                </div>
            </div>
        </section>
    )
}

export default function Main() {
    const getExchangerates = async( ) =>{
        await Api.getExchangerates()
      }
    useEffect(() => {
        getExchangerates()
    }, [])
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    const updateMedia = () => {
      setIsMobile(window.innerWidth <= 600);
    };
  
    useEffect(() => {
      window.addEventListener('resize', updateMedia);
      return () => {
        window.removeEventListener('resize', updateMedia);
      };
    }, [window.innerWidth]);
    return (
        !isMobile?(  loginStore.isClient() 
       ? 
       <Suspense fallback={<div>Loading...</div>}> 
            <Layout/> 
       </Suspense>

       : <>
           { 
           loginStore.isClient() 
           ? <AgNavBar setTabIndex={()=>{}} selectedTabIndex={0} /> : <Navbar /> }
            <Content />
        </>
        ):<><MobileAppTemp/></>
    )
}