import Utils from "../util/Utils"
import MyactionUtils from "./Myactionutils"
import { graphNode } from "./shipment-graph"

export class Visibility {
    static GraphLine(rec){
        let _date = (dt) => {
            return Utils.formatDateShort(new Date(dt))
        }
        // console.log("recc",rec?.icegateResData);
        let st = rec.shipmentType
        let sc = rec.shipmentScope
        let pod = rec.pod
        let fpod = rec.fpod
        let por = rec.por
        let pol = rec.pol
        let schedule = rec.booking.schedule
        let isDummy = rec.booking.hasToShowDummySchedule ? true : false
        let oneGraph = []
        if(["Door-to-Port","Door-to-Door"].indexOf(sc)!=-1){
            let g = new graphNode()
            g.r2 = 'Origin'
            let cv = 0
            if(("FCL (Factory/Factory)"==st || "FCL (Factory/Dock)"==st) && st.toLowerCase()!='lcl'){
                let n = Number(rec.booking.containershow)
                let a = []
                if(n==-1){
                    if (rec.containerlist != undefined && rec.containerlist.length != 0) {
                        cv = rec.containerlist.length
                        for (let i = 0; i < rec.containerlist.length; i++) {
                            if(rec.containerlist[i]['loaded_and_dispatch']!=undefined && rec.containerlist[i]['loaded_and_dispatch'].length>0){
                                a.push(rec.containerlist[i]['loaded_and_dispatch'])
                            }
                        }
                        if(a.length>0){
                            g.isActive = true
                            g.r3 = 'Picked-up ' + MyactionUtils.getmindate(a)
                            g.cn = `${a.length} out of ${cv}`
                        }   
                    }
                }else{
                    cv = rec.containerlist.length
                    for (let i = 0; i < rec.containerlist.length; i++) {
                        if(rec.containerlist[i]['loaded_and_dispatch']!=undefined && rec.containerlist[i]['loaded_and_dispatch'].length>0){
                            a.push(rec.containerlist[i]['loaded_and_dispatch'])
                        }
                    }
                    if(rec.containerlist[n] && rec.containerlist[n]['loaded_and_dispatch']!=undefined && rec.containerlist[n]['loaded_and_dispatch'].length>0){
                        g.isActive = true
                        g.r3 = 'Picked-up ' + rec.containerlist[n]['loaded_and_dispatch']
                        g.cn = `${a.length} out of ${cv}`
                    }
                    
                }
            }else{
                let cv = 0
                if (rec.vorgin != undefined && rec.vorgin.length != 0) {
                    cv = rec.vorgin.length
                    let a = []
                    for (let i = 0; i < rec.vorgin.length; i++) {
                        if(rec.vorgin[i]['cargo_pickup']!=undefined && rec.vorgin[i]['cargo_pickup'].length>0){
                            a.push(rec.vorgin[i]['cargo_pickup'])
                        }
                    }
                    if(a.length>0){
                        g.isActive = true
                        g.r3 = 'Picked-up ' + MyactionUtils.getmindate(a)
                        g.vl = `${a.length} out of ${cv}`
                    }
                }
            }
            if(rec['cargo_pickup']!=undefined && rec['cargo_pickup'].length>0){
                g.isActive = true
                g.r3 = 'Picked-up ' + rec['cargo_pickup']
            }
            oneGraph.push(g)
        }
        if(st.toLowerCase().indexOf('air') == -1){
            
            if(por != pol){
                if(rec.por && rec.por.length>0){
                    let g = new graphNode()
                    let t = ''
                    if (schedule['ETD (POL)']) t = schedule['ETD (POL)']
                    else if (schedule['oetd']) t = schedule['oetd']
                    let arr = t.split('-')
                    if (t) t = `${arr[2]}-${arr[1]}-${arr[0]}`
                    let etdPol = new Date(t)
                    let cutOff
                    if (t) {
                        cutOff = Utils.addDays(etdPol, -3)
                        cutOff = Utils.ddmmyyyy(cutOff)
                    }
                    if (!isDummy) g.r1 = 'Cut-Off ' + cutOff
                    if(rec.por_location && rec.por_location.length>0){
                        g.r2 = rec.por_location
                    }else{
                        g.r2 = rec.por
                    }
                    if(rec.booking.createdFromPricing){
                        if (!isDummy){
                            if(schedule?.cutoffpor!=undefined && schedule?.cutoffpor.trim().length>0){
                                g.r1 = 'Cut-Off ' +  _date(schedule?.cutoffpor)
                            }
                        }
                        if(schedule.etdpol){
                            g.r3 = 'ETD ' + _date(schedule.etdpol)
                        }
                    }
                    
                    let n = Number(rec.booking.containershow)
                    let a = []
                    if(n==-1){
                        if (rec.containerlist != undefined && rec.containerlist.length != 0) {
                            let cv = rec.containerlist.length
                            for (let i = 0; i < rec.containerlist.length; i++) {
                                if(rec.containerlist[i]['container_handover']!=undefined && rec.containerlist[i]['container_handover'].length>0){
                                    a.push(rec.containerlist[i]['container_handover'])
                                }
                            }
                            if(a.length>0){
                                g.isActive = true
                                g.r1 = 'Handover ' + MyactionUtils.getmindate(a)
                                g.tcn = `${a.length} out of ${cv}`
                            }
                        }
                    }else{
                        let cv = rec.containerlist.length
                        for (let i = 0; i < rec.containerlist.length; i++) {
                            if(rec.containerlist[i]['container_handover']!=undefined && rec.containerlist[i]['container_handover'].length>0){
                                a.push(rec.containerlist[i]['container_handover'])
                            }
                        }
                        if(rec.containerlist[n] && rec.containerlist[n]['container_handover']!=undefined && rec.containerlist[n]['container_handover'].length>0){
                            g.isActive = true
                            g.r1 = 'Handover ' + rec.containerlist[n]['container_handover']
                            g.tcn = `${a.length} out of ${cv}`
                        }
                        
                    }
                    a = []
                    if(n==-1){
                        if (rec.containerlist != undefined && rec.containerlist.length != 0) {
                            let cv = rec.containerlist.length
                            for (let i = 0; i < rec.containerlist.length; i++) {
                                if(rec.containerlist[i]['por_to_pol_movement']!=undefined && rec.containerlist[i]['por_to_pol_movement'].length>0){
                                    a.push(rec.containerlist[i]['por_to_pol_movement'])
                                }
                            }
                            if(a.length>0){
                                g.isActive = true
                                g.r3 = 'Gate-out ' + MyactionUtils.getmindate(a)
                                g.cn = `${a.length} out of ${cv}`
                            } 
                        }
                    }else{
                        let cv = rec.containerlist.length
                        for (let i = 0; i < rec.containerlist.length; i++) {
                            if(rec.containerlist[i]['por_to_pol_movement']!=undefined && rec.containerlist[i]['por_to_pol_movement'].length>0){
                                a.push(rec.containerlist[i]['por_to_pol_movement'])
                            }
                        }
                        if(rec.containerlist[n] && rec.containerlist[n]['por_to_pol_movement']!=undefined && rec.containerlist[n]['por_to_pol_movement'].length>0){
                            g.isActive = true
                            g.r3 = 'Gate-out ' + rec.containerlist[n]['por_to_pol_movement']
                            g.cn = `${a.length} out of ${cv}`
                        }
                        
                    }

                    
                    if(rec['container_handover']!=undefined && rec['container_handover'].length>0){
                        g.isActive = true
                        g.r1 = 'Handover ' + rec['container_handover']
                    }
                    if(rec['por_to_pol_movement']!=undefined && rec['por_to_pol_movement'].length>0){
                        g.isActive = true
                        g.r3 = 'Gate-out ' + rec['por_to_pol_movement']
                    }
                    
                    oneGraph.push(g)
                }
            }
            if(rec.pol && rec.pol.length>0){
                let g = new graphNode()
                if(rec.pol_location && rec.pol_location.length>0){
                    g.r2 = rec.pol_location
                }else{
                    g.r2 = rec.pol
                }
                if(rec.booking.createdFromPricing){
                    if(schedule.etdpol){
                        g.r3 = 'ETD ' + _date(schedule.etdpol)
                    }
                }
                let n = Number(rec.booking.containershow)
                let a = []
                if(n==-1){
                    if (rec.containerlist != undefined && rec.containerlist.length != 0) {
                        let cv = rec.containerlist.length
                        for (let i = 0; i < rec.containerlist.length; i++) {
                            if(rec.containerlist[i]['gatein_pol']!=undefined && rec.containerlist[i]['gatein_pol'].length>0){
                                a.push(rec.containerlist[i]['gatein_pol'])
                            }
                        }
                        if(a.length>0){
                            g.isActive = true
                            g.r1 = 'Gate-in ' + MyactionUtils.getmindate(a)
                            g.tcn = `${a.length} out of ${cv}`
                        }
                    }
                }else{
                    let cv = rec.containerlist.length
                    for (let i = 0; i < rec.containerlist.length; i++) {
                        if(rec.containerlist[i]['gatein_pol']!=undefined && rec.containerlist[i]['gatein_pol'].length>0){
                            a.push(rec.containerlist[i]['gatein_pol'])
                        }
                    }
                    if(rec.containerlist[n] && rec.containerlist[n]['gatein_pol']!=undefined && rec.containerlist[n]['gatein_pol'].length>0){
                        g.isActive = true
                        g.r1 = 'Gate-in ' + rec.containerlist[n]['gatein_pol']
                        g.tcn = `${a.length} out of ${cv}`
                    }
                    
                }
                if(rec.etd_at_pol != undefined && rec.etd_at_pol.length != 0){
                    g.r3 = 'ETD ' + rec.etd_at_pol
                }
                // if(rec.container_handover!=undefined && rec.container_handover.length !=0 && rec.por == rec.pol){
                //     // g.isActive = true
                //     g.r1 = 'Handover ' + rec.container_handover

                // }
                if(rec.gatein_pol !=undefined && rec.gatein_pol.length !=0){
                    g.isActive = true
                    g.r1 = 'Gate-in  ' + rec.gatein_pol

                }
                if(rec.sob_pol != undefined && rec.sob_pol.length != 0){
                    g.isActive = true
                    g.r3 = 'SOB ' + rec.sob_pol
                }
                if(rec.booking.createdFromPricing){
                    if(schedule['VESSEL NAME']){
                        g.al = schedule['VESSEL NAME']
                    }
                    if(schedule['VOYAGE']){
                        g.bl = schedule['VOYAGE']
                    }
                }
                if(rec.fl_vessel_name != undefined && rec.fl_vessel_name.length != 0){
                    g.al = rec.fl_vessel_name
                }
                if(rec.fl_vessel_number != undefined && rec.fl_vessel_number.length != 0){
                    g.bl = rec.fl_vessel_number
                }
                oneGraph.push(g)
            }
            if(st.toLowerCase().indexOf('lcl') != -1){
                if(rec.reworking_pol_port != undefined && rec.reworking_pol_port.length != 0){
                    let g = new graphNode()
                    if(rec.reworking_pol_port_location && rec.reworking_pol_port_location.length>0){
                        g.r2 = rec.reworking_pol_port_location
                    }else{
                        g.r2 = rec.reworking_pol_port
                    }
                    if(rec.reworking_pol_vessel_name != undefined && rec.reworking_pol_vessel_name.length != 0){
                        g.al = rec.reworking_pol_vessel_name
                    }
                    if(rec.reworking_pol_vessel_number != undefined && rec.reworking_pol_vessel_number.length != 0){
                        g.bl = rec.reworking_pol_vessel_number
                    }
                    if(rec.reworking_pol_arrival_estimate != undefined && rec.reworking_pol_arrival_estimate.length != 0){
                        g.r1= "ETA "+ rec.reworking_pol_arrival_estimate
                    }
                    if(rec.reworking_pol_arrival != undefined && rec.reworking_pol_arrival.length != 0){
                        g.isActive = true
                        g.r1= "ATA "+ rec.reworking_pol_arrival
                    }
                    if(rec.reworking_pol_departure_estimate != undefined && rec.reworking_pol_departure_estimate.length != 0){
                        g.r3= "ETD "+ rec.reworking_pol_departure_estimate
                    }
                    if(rec.reworking_pol_departure != undefined && rec.reworking_pol_departure.length != 0){
                        g.isActive = true
                        g.r3= "ATD "+ rec.reworking_pol_departure
                    }
                    oneGraph.push(g)
                }else{
                    if(rec.booking.createdFromPricing){
                        if(schedule.rep){
                            let g = new graphNode()
                            g.r2 = schedule.rep
                            if(schedule.etdrw){
                                g.r3 = 'ETD ' + _date(schedule.etdrw)
                            }
                            if(schedule.etarw){
                                g.r1 = 'ETA ' + _date(schedule.etarw)  
                            }
                            oneGraph.push(g)
                        }
                        
                    }
                }
            }

            for (let i = 0; i < 4; i++) {
                if(rec[`ts${i+1}_port`]!=undefined && rec[`ts${i+1}_port`]!=null && rec[`ts${i+1}_port`].trim().length > 0){
                    
                    let g = new graphNode()
                    
                    if(rec[`ts${i+1}_port_location`] && rec[`ts${i+1}_port_location`].length>0){
                        g.r2 = rec[`ts${i+1}_port_location`]
                    }else{
                        g.r2 = rec[`ts${i+1}_port`]
                    }
                    if(rec[`ts${i+1}_vessel_name`] != undefined && rec[`ts${i+1}_vessel_name`].length != 0){
                        g.al = rec[`ts${i+1}_vessel_name`]
                    }
                    if(rec[`ts${i+1}_vessel_number`] != undefined && rec[`ts${i+1}_vessel_number`].length != 0){
                        g.bl = rec[`ts${i+1}_vessel_number`]
                    }
                    if(rec[`ts${i+1}_arrival_estimate`] != undefined && rec[`ts${i+1}_arrival_estimate`].length != 0){
                        g.r1= "ETA "+ rec[`ts${i+1}_arrival_estimate`]
                    }
                    if(rec[`ts${i+1}_arrival`] != undefined && rec[`ts${i+1}_arrival`].length != 0){
                        g.isActive = true
                        g.r1= "ATA "+ rec[`ts${i+1}_arrival`]
                    }
                    if(rec[`ts${i+1}_departure_estimate`] != undefined && rec[`ts${i+1}_departure_estimate`].length != 0){
                        g.r3= "ETD "+ rec[`ts${i+1}_departure_estimate`]
                    }
                    if(rec[`ts${i+1}_departure`] != undefined && rec[`ts${i+1}_departure`].length != 0){
                        g.isActive = true
                        g.r3= "ATD "+ rec[`ts${i+1}_departure`]
                    }
                    oneGraph.push(g)
                }else{
                    if (schedule[`ts${i+1}code`]) {
                        let g = new graphNode()
                        g.r2 = schedule[`ts${i+1}code`]
                        if (schedule[`ts${i+1}eta`]) {
                            g.r1 = schedule[`ts${i+1}eta`]
                        }
                        if (schedule[`ts${i+1}etd`]) {
                            g.r3 = schedule[`ts${i+1}etd`]
                        }
                        oneGraph.push(g)
                    }
                }
            }
            if(st.toLowerCase().indexOf('lcl') != -1){
                if(rec.reworking_pod_port != undefined && rec.reworking_pod_port.length != 0){
                    let g = new graphNode()
                    if(rec.reworking_pod_port_location && rec.reworking_pod_port_location.length>0){
                        g.r2 = rec.reworking_pod_port_location
                    }else{
                        g.r2 = rec.reworking_pod_port
                    }
                    if(rec.reworking_pod_vessel_name != undefined && rec.reworking_pod_vessel_name.length != 0){
                        g.al = rec.reworking_pod_vessel_name
                    }
                    if(rec.reworking_pod_vessel_number != undefined && rec.reworking_pod_vessel_number.length != 0){
                        g.bl = rec.reworking_pod_vessel_number
                    }
                    if(rec.reworking_pod_arrival_estimate != undefined && rec.reworking_pod_arrival_estimate.length != 0){
                        g.r1= "ETA "+ rec.reworking_pod_arrival_estimate
                    }
                    if(rec.reworking_pod_arrival != undefined && rec.reworking_pod_arrival.length != 0){
                        g.isActive = true
                        g.r1= "ATA "+ rec.reworking_pod_arrival
                    }
                    if(rec.reworking_pod_departure_estimate != undefined && rec.reworking_pod_departure_estimate.length != 0){
                        g.r3= "ETD "+ rec.reworking_pod_departure_estimate
                    }
                    if(rec.reworking_pod_departure != undefined && rec.reworking_pod_departure.length != 0){
                        g.isActive = true
                        g.r3= "ATD "+ rec.reworking_pod_departure
                    }
                    oneGraph.push(g)
                }
            }



            if(pod != fpod){
            if(rec.pod != undefined && rec.pod.length != 0){
                let g = new graphNode()
                if(rec.pod_location && rec.pod_location.length>0){
                    g.r2 = rec.pod_location
                }else{
                    g.r2 = rec.pod
                }
                if(rec.booking.createdFromPricing){
                    if(schedule.etapod){
                        g.r1 = 'ETA ' + _date(schedule.etapod)
                    }
                }
                if(rec.eta_at_pod != undefined && rec.eta_at_pod.length != 0){
                    g.r1= "ETA "+ rec.eta_at_pod
                }
                if(rec.pod_arrival != undefined && rec.pod_arrival.length != 0){
                    g.isActive = true
                    g.r1= "ATA "+ rec.pod_arrival
                }
                if(rec.etd_pod != undefined && rec.etd_pod.length != 0){
                    g.r3= "ETD "+ rec.etd_pod
                }
                let n = Number(rec.booking.containershow)
                let a = []
                if(n==-1){
                    if (rec.containerlist != undefined && rec.containerlist.length != 0) {
                        let cv = rec.containerlist.length
                        for (let i = 0; i < rec.containerlist.length; i++) {
                            if(rec.containerlist[i]['gateout_to_fpod']!=undefined && rec.containerlist[i]['gateout_to_fpod'].length>0){
                                a.push(rec.containerlist[i]['gateout_to_fpod'])
                            }
                        }
                        if(a.length>0){
                            g.isActive = true
                            g.r3 = 'ATD ' + MyactionUtils.getmindate(a)
                            g.cn = `${a.length} out of ${cv}`
                        }
                    }
                }else{
                    let cv = rec.containerlist.length
                    for (let i = 0; i < rec.containerlist.length; i++) {
                        if(rec.containerlist[i]['gateout_to_fpod']!=undefined && rec.containerlist[i]['gateout_to_fpod'].length>0){
                            a.push(rec.containerlist[i]['gateout_to_fpod'])
                        }
                    }
                    if(rec.containerlist[n] && rec.containerlist[n]['gateout_to_fpod']!=undefined && rec.containerlist[n]['gateout_to_fpod'].length>0){
                        g.isActive = true
                        g.r3 = 'ATD ' + rec.containerlist[n]['gateout_to_fpod']
                        g.cn = `${a.length} out of ${cv}`
                    }
                    
                }
                
                if(rec.gateout_to_fpod!=undefined && rec.gateout_to_fpod.length>0){
                    g.isActive = true
                    g.r3 = 'ATD ' + rec.gateout_to_fpod
                }
                
                oneGraph.push(g)
            }
        }
            if(st.toLowerCase().indexOf('lcl') == -1){
                if(rec.fpod != undefined && rec.fpod.length != 0){
                    let g = new graphNode()
                    if(rec.fpod_location && rec.fpod_location.length>0){
                        g.r2 = rec.fpod_location
                    }else{
                        g.r2 = rec.fpod
                    }
                    if(rec.eta_fpod != undefined && rec.eta_fpod.length != 0){
                        g.r1= "ETA "+ rec.eta_fpod
                    }
                    let n = Number(rec.booking.containershow)
                    let a = []
                    let b = []
                    if(n==-1){
                        if (rec.containerlist != undefined && rec.containerlist.length != 0) {
                            let cv = rec.containerlist.length
                            for (let i = 0; i < rec.containerlist.length; i++) {
                                if(rec.containerlist[i]['gatein_fpod']!=undefined && rec.containerlist[i]['gatein_fpod'].length>0){
                                    a.push(rec.containerlist[i]['gatein_fpod'])
                                }
                                if(rec.containerlist[i]['container_pickup_fpod']!=undefined && rec.containerlist[i]['container_pickup_fpod'].length>0){
                                    b.push(rec.containerlist[i]['container_pickup_fpod'])
                                }
                            }
                            if(a.length>0){
                                g.isActive = true
                                g.r1 = 'ATA ' + MyactionUtils.getmindate(a)
                                g.tcn = `${a.length} out of ${cv}`
                            }
                            if(b.length>0){
                                g.r3 = 'Picked-up ' + MyactionUtils.getmindate(b)
                                g.isActive = true
                                g.cn = `${b.length} out of ${cv}`
                            }
                        }
                    }else{
                        let cv = rec.containerlist.length
                        for (let i = 0; i < rec.containerlist.length; i++) {
                            if(rec.containerlist[i]['gatein_fpod']!=undefined && rec.containerlist[i]['gatein_fpod'].length>0){
                                a.push(rec.containerlist[i]['gatein_fpod'])
                            }
                            if(rec.containerlist[i]['container_pickup_fpod']!=undefined && rec.containerlist[i]['container_pickup_fpod'].length>0){
                                b.push(rec.containerlist[i]['container_pickup_fpod'])
                            }
                        }
                        if(rec.containerlist[n] && rec.containerlist[n]['gatein_fpod']!=undefined && rec.containerlist[n]['gatein_fpod'].length>0){
                            g.r1 = 'ATA ' + rec.containerlist[n]['gatein_fpod']
                            g.tcn = `${a.length} out of ${cv}`
                        }
                        if(rec.containerlist[n] && rec.containerlist[n]['container_pickup_fpod']!=undefined && rec.containerlist[n]['container_pickup_fpod'].length>0){
                            g.r3 = 'Picked-up ' + rec.containerlist[n]['container_pickup_fpod']
                            g.isActive = true
                            g.cn = `${a.length} out of ${cv}`
                        }
                        
                    }
                    if(rec['gatein_fpod']!=undefined && rec['gatein_fpod'].length>0){
                        g.isActive = true
                        g.r1 = 'ATA ' + rec['gatein_fpod']
                    }
                    if(rec['container_pickup_fpod']!=undefined && rec['container_pickup_fpod'].length>0){
                        g.isActive = true
                        g.r3 = 'Picked-up ' + rec['container_pickup_fpod']
                    }
                    oneGraph.push(g)
                }
            }else{
                if(rec.fpod != undefined && rec.fpod.length != 0){
                    let g = new graphNode()
                    if(rec.fpod_location && rec.fpod_location.length>0){
                        g.r2 = rec.fpod_location
                    }else{
                        g.r2 = rec.fpod
                    }
                    if(rec.booking.createdFromPricing){
                        if(schedule.etafpod){
                            g.r1 = 'ETA ' + _date(schedule.etafpod)
                        }
                    }
                    if(rec.eta_fpod != undefined && rec.eta_fpod.length != 0){
                        g.r1= "ETA "+ rec.eta_fpod
                    }
                    if(rec.gatein_fpod != undefined && rec.gatein_fpod.length != 0){
                        g.isActive = true
                        g.r1= "ATA "+ rec.gatein_fpod
                    }
                    if(rec.cargo_picked_up_by_consignee != undefined && rec.cargo_picked_up_by_consignee.length != 0){
                        g.isActive = true
                        g.r3= 'Picked-up '+ rec.cargo_picked_up_by_consignee
                    }
                    let cv = 0
                    let a = []
                    for (let i = 0; i < 5; i++) {
                        let k = ""
                        if(i==0){
                            k = 'vdes'
                        }else{
                            k = 'vdes'+(i+1)
                        }
                        if (rec[k] != undefined && rec[k].length != 0) {
                            cv = cv+rec[k].length
                            for (let i = 0; i < rec[k].length; i++) {
                                if(rec[k][i]['atdfrompod']!=undefined && rec[k][i]['atdfrompod'].length>0){
                                    a.push(rec[k][i]['atdfrompod'])
                                }
                            } 
                        }
                    }
                    if(a.length>0){
                        g.isActive = true
                        g.r3 = 'Picked-up ' + MyactionUtils.getmindate(a)
                        g.vl = `${a.length} out of ${cv}`
                    }
                    if(rec['atdfrompod']!=undefined && rec['atdfrompod'].length>0){
                        g.isActive = true
                        g.r3 = 'Picked-up ' + rec['atdfrompod']
                    }
                    oneGraph.push(g)
                }
            }
        

        }else{
            if (rec.departure_airport && rec.departure_airport.length>0) {
                let g = new graphNode()
                if(rec.departure_airport_location && rec.departure_airport_location.length>0){
                    g.r2 = rec.departure_airport_location
                }else{
                    g.r2 = rec.departure_airport
                }
                let cv = 0
                if (rec.vorgin != undefined && rec.vorgin.length != 0) {
                    cv = rec.vorgin.length
                    let a = []
                    for (let i = 0; i < rec.vorgin.length; i++) {
                        if(rec.vorgin[i]['arrival_dispatch_airport']!=undefined && rec.vorgin[i]['arrival_dispatch_airport'].length>0){
                            a.push(rec.vorgin[i]['arrival_dispatch_airport'])
                        }
                    }
                    if(a.length>0){
                        g.isActive = true
                        g.r1 = 'Gate-in ' + MyactionUtils.getmindate(a)
                        g.tvl = `${a.length} out of ${cv}`
                    }
                    
                }
                if(rec['arrival_dispatch_airport']!=undefined && rec['arrival_dispatch_airport'].length>0){
                    g.isActive = true
                    g.r1 = 'Gate-in ' + rec['arrival_dispatch_airport']
                }
                if (rec.etd_airport_of_departure && rec.etd_airport_of_departure.length>0) {
                    g.r3 = 'ETD ' + rec.etd_airport_of_departure
                }
                if (rec.loaded_on_board && rec.loaded_on_board.length>0) {
                    g.isActive = true
                    g.r3 = 'ATD ' + rec.loaded_on_board
                }
                if(rec.flight_number_departure_airport && rec.flight_number_departure_airport.length>0){
                    g.al = rec.flight_number_departure_airport
                }
                oneGraph.push(g)
            }

            for (let i = 0; i < 2; i++) {
                if(rec[`ts${i+1}_airport`]!=undefined && rec[`ts${i+1}_airport`]!=null && rec[`ts${i+1}_airport`].trim().length > 0){
                    let g = new graphNode()
                    if(rec[`ts${i+1}_airport_location`] && rec[`ts${i+1}_airport_location`].length>0){
                        g.r2 = rec[`ts${i+1}_airport_location`]
                    }else{
                        g.r2 = rec[`ts${i+1}_airport`]
                    }
                    if(rec[`ts${i+1}_arrival_estimate`] && rec[`ts${i+1}_arrival_estimate`].length>0){
                        g.r1 = 'ETA '+rec[`ts${i+1}_arrival_estimate`]
                    }
                    if(rec[`ts${i+1}_arrival`] && rec[`ts${i+1}_arrival`].length>0){
                        g.isActive = true
                        g.r1 = 'ATA '+rec[`ts${i+1}_arrival`]
                    }
                    if(rec[`ts${i+1}_departure_estimate`] && rec[`ts${i+1}_departure_estimate`].length>0){
                        g.r3 = 'ETD '+rec[`ts${i+1}_departure_estimate`]
                    }
                    if(rec[`ts${i+1}_departure`] && rec[`ts${i+1}_departure`].length>0){
                        g.isActive = true
                        g.r3 = 'ATD '+rec[`ts${i+1}_departure`]
                    }
                    if(rec[`flight_number_ts${i+1}`] && rec[`flight_number_ts${i+1}`].length>0){
                        g.al = rec[`flight_number_ts${i+1}`]
                    }
                    oneGraph.push(g)
                }
            }

            if (rec.arrival_airport && rec.arrival_airport.length>0) {
                let g = new graphNode()
                if(rec.arrival_airport_location && rec.arrival_airport_location.length>0){
                    g.r2 = rec.arrival_airport_location
                }else{
                    g.r2 = rec.arrival_airport
                }
                if (rec.eta_at_arrival_airport && rec.eta_at_arrival_airport.length>0) {
                    g.r1 = 'ETA ' + rec.eta_at_arrival_airport
                }
                if (rec.ata_at_arrival_airport && rec.ata_at_arrival_airport.length>0) {
                    g.isActive = true
                    g.r1 = 'ATA ' + rec.ata_at_arrival_airport
                }
                let cv = 0
                let a = []
                for (let i = 0; i < 5; i++) {
                    let k = ""
                    if(i==0){
                        k = 'vdes'
                    }else{
                        k = 'vdes'+(i+1)
                    }
                    if (rec[k] != undefined && rec[k].length != 0) {
                        cv = cv+rec[k].length
                        for (let i = 0; i < rec[k].length; i++) {
                            if(rec[k][i]['dispatched_from_arrival_airport']!=undefined && rec[k][i]['dispatched_from_arrival_airport'].length>0){
                                a.push(rec[k][i]['dispatched_from_arrival_airport'])
                            }
                        } 
                    }
                }
                if(a.length>0){
                    g.isActive = true
                    g.r3 = 'Gate-out ' + MyactionUtils.getmindate(a)
                    g.vl = `${a.length} out of ${cv}`
                }
                if(rec['dispatched_from_arrival_airport']!=undefined && rec['dispatched_from_arrival_airport'].length>0){
                    g.isActive = true
                    g.r3 = 'Gate-out ' + rec['dispatched_from_arrival_airport']
                }
                oneGraph.push(g)
            }

        }


        if(["Port-to-Door","Door-to-Door"].indexOf(sc)!=-1){
            let g = new graphNode()
            if(rec["destination2"]!=undefined && rec.destination.length>0){
                let m = []
                if(rec?.booking?.contract?.multidest){
                    m = JSON.parse(JSON.stringify(rec.booking.contract.multidest))
                }
                for (let i = 0; i < m.length; i++) {
                    let k = ""
                    if(i==0){
                        k = 'vdes'
                    }else{
                        k = 'vdes'+(i+1)
                    }
                    let a = []
                    let a1 = false
                    if (rec[k] != undefined && rec[k].length != 0) {
                        for (let j = 0; j < rec[k].length; j++) {
                            if(rec[k][j]['actual_delivery_date']!=undefined && rec[k][j]['actual_delivery_date'].length>0){
                                a.push(rec[k][j]['actual_delivery_date'])
                                a1 = true
                            }else{
                                a1 = false
                                j = rec[k].length+1
                            }
                        } 
                    }
                    if(a1){
                        m[i]['actual_delivery_date'] = MyactionUtils.getmindate(a)
                    }else{
                        m[i]['actual_delivery_date'] = ""
                    }

                }
                g.multidest = m
                g.r2 = 'Multiple'
            }else{
                g.r2 = 'Destination'
            }
            let cv = 0
            if("FCL (Factory/Factory)"==st || "FCL (Dock/Factory)"==st){
                let n = Number(rec.booking.containershow)
                let a = []
                let b = []
                if(n==-1){
                    if (rec.containerlist != undefined && rec.containerlist.length != 0) {
                        cv = rec.containerlist.length
                        for (let i = 0; i < rec.containerlist.length; i++) {
                            if(rec.containerlist[i]['estimate_delivery_date_to_consignee']!=undefined && rec.containerlist[i]['estimate_delivery_date_to_consignee'].length>0){
                                a.push(rec.containerlist[i]['estimate_delivery_date_to_consignee'])
                            }
                            if(rec.containerlist[i]['container_delivery']!=undefined && rec.containerlist[i]['container_delivery'].length>0){
                                b.push(rec.containerlist[i]['container_delivery'])
                            }
                        }
                        if(a.length>0){
                            g.r1 = 'EDD ' + MyactionUtils.getmindate(a)
                        }
                        if(b.length>0){
                            g.isActive = true
                            g.r1 = 'Delivered-on ' + MyactionUtils.getmindate(b)
                            g.tcn = `${b.length} out of ${cv}`
                        }
                        
                        
                    }
                }else{
                    cv = rec.containerlist.length
                    if(rec.containerlist[n] && rec.containerlist[n]['estimate_delivery_date_to_consignee']!=undefined && rec.containerlist[n]['estimate_delivery_date_to_consignee'].length>0){
                        g.r1 = 'EDD ' + rec.containerlist[n]['estimate_delivery_date_to_consignee']
                    }
                    if(rec.containerlist[n] && rec.containerlist[n]['container_delivery']!=undefined && rec.containerlist[n]['container_delivery'].length>0){
                        g.isActive = true
                        g.r1 = 'Delivered-on ' + rec.containerlist[n]['container_delivery']
                        g.tcn = `${b.length} out of ${cv}`
                    }
                    
                }
                if(rec['estimate_delivery_date_to_consignee']!=undefined && rec['estimate_delivery_date_to_consignee'].length>0){
                    g.r1 = 'EDD ' + rec['estimate_delivery_date_to_consignee']
                }
                if(rec['container_delivery']!=undefined && rec['container_delivery'].length>0){
                    g.isActive = true
                    g.r3 = 'Delivered-on ' + rec['container_delivery']
                }
            }else{
                let cv = 0
                let a = []
                let b = []
                for (let i = 0; i < 5; i++) {
                    let k = ""
                    if(i==0){
                        k = 'vdes'
                    }else{
                        k = 'vdes'+(i+1)
                    }
                    if (rec[k] != undefined && rec[k].length != 0) {
                        cv = cv+rec[k].length
                        for (let i = 0; i < rec[k].length; i++) {
                            if(rec[k][i]['estimate_delivery_date_to_consignee']!=undefined && rec[k][i]['estimate_delivery_date_to_consignee'].length>0){
                                a.push(rec[k][i]['estimate_delivery_date_to_consignee'])
                            }
                            if(rec[k][i]['actual_delivery_date']!=undefined && rec[k][i]['actual_delivery_date'].length>0){
                                b.push(rec[k][i]['actual_delivery_date'])
                            }
                        } 
                    }
                }
                if(a.length>0){
                    g.r1 = 'EDD ' + MyactionUtils.getmindate(a)
                }
                if(b.length>0){
                    g.isActive = true
                    g.r1 = 'Delivered-on ' + MyactionUtils.getmindate(b)
                    g.tvl = `${b.length} out of ${cv}`
                }
                if(rec['estimate_delivery_date_to_consignee']!=undefined && rec['estimate_delivery_date_to_consignee'].length>0){
                    g.r1 = 'EDD ' + rec['estimate_delivery_date_to_consignee']
                }
                if(rec['actual_delivery_date']!=undefined && rec['actual_delivery_date'].length>0){
                    g.isActive = true
                    g.r3 = 'Delivered-on ' + rec['actual_delivery_date']
                }
                
            }
            
            oneGraph.push(g)
        }

        return oneGraph
    }
    static oceanGraphLine(rec) {
        let schedule = rec.booking.schedule
        let isDummy = rec.booking.hasToShowDummySchedule ? true : false
        let stype = rec.shipmentType
        let sc = rec.shipmentScope
        let oneGraph = []
        let g
        { // origin
            if(["Door-to-Port","Door-to-Door"].indexOf(sc)!=-1){
            if (rec.origin !== rec.por) {
                g = new graphNode()
                g.r2 = 'Origin'  // rec.origin - origin not to be shown as text now
                if (rec.cargo_pickup_date) {
                    g.isActive = true
                    g.r3 = 'Picked-up ' + rec.cargo_pickup_date
                }
                if (rec.container_arrival_factory) {
                    g.isActive = true
                    g.r3 = 'Picked-up ' + rec.container_arrival_factory
                }
                
                //commented line for updating new record on numerator/denominator in visiblity graph
                // g.al = 'Above Line'
                // g.bl = 'Below Line'
                oneGraph.push(g)
            }}
        
        }
        { // por and pol
            g = new graphNode()
            let t = ''
            if (schedule['ETD (POL)']) t = schedule['ETD (POL)']
            else if (schedule['oetd']) t = schedule['oetd']
            let arr = t.split('-')
            if (t) t = `${arr[2]}-${arr[1]}-${arr[0]}`
            let etdPol = new Date(t)
            if (rec.por === rec.pol) {
                let cutOff
                if (t) {
                    cutOff = Utils.addDays(etdPol, -3)
                    cutOff = Utils.ddmmyyyy(cutOff)
                }
                if (stype !== 'Air' && !isDummy) g.r1 = 'Cut-Off ' + cutOff
                if(rec.pol_location && rec.pol_location.length>0){
                    g.r2 = rec.pol_location
                }else{
                    g.r2 = rec.pol
                }
                if (!isDummy) g.r3 = 'ETD ' + Utils.ddmmyyyy(etdPol)
                if(rec.etd_at_pol) g.r3 = 'ETD ' + rec.etd_at_pol
                if (rec.gatein_pol) {
                    g.isActive = true
                    g.r1 = 'Gate-in ' + rec.gatein_pol
                }
                if (rec.sob_pol) {
                    g.isActive = true
                    g.r3 = 'SOB ' + rec.sob_pol
                }
                oneGraph.push(g)
            } else {
                let cutOff = Utils.addDays(etdPol, -12)
                cutOff = Utils.ddmmyyyy(cutOff)
                if (stype !== 'Air' && !isDummy) g.r1 = 'Cut-Off ' + cutOff
                if(rec.por_location && rec.por_location.length>0){
                    g.r2 = rec.por_location
                }else{
                    g.r2 = rec.por
                }
                if (rec.arrival_at_por) {
                    g.isActive = true
                    g.r1 = 'Gate-in ' + rec.arrival_at_por
                }
                if (rec.por_to_pol_movement) {
                    g.isActive = true
                    g.r3 = 'Gate-out ' + rec.por_to_pol_movement
                }
                oneGraph.push(g)

                g = new graphNode()
                if(rec.pol_location && rec.pol_location.length>0){
                    g.r2 = rec.pol_location
                }else{
                    g.r2 = rec.pol
                }
                if (!isDummy) g.r3 = 'ETD ' + Utils.ddmmyyyy(etdPol)
                if(rec.etd_at_pol) g.r3 = 'ETD ' + rec.etd_at_pol
                if (rec.pol_arrival) {
                    g.isActive = true
                    g.r1 = 'ATA ' + rec.pol_arrival
                }
                if (rec.sob_pol) {
                    g.isActive = true
                    g.r3 = 'SOB ' + rec.sob_pol
                }
                oneGraph.push(g)
            }
        }
        g = new graphNode()
        if (schedule.ts1code) {
            g.r1 = 'ETA ' + schedule.ts1eta
            g.r2 = schedule.ts1code
            g.r3 = 'ETD ' + schedule.ts1etd
        }
        if (rec.ts1_port) {
            if(rec.ts1_port_location && rec.ts1_port_location.length>0){
                g.r2 = rec.ts1_port_location
            }else{
                g.r2 = rec.ts1_port
            }
            if (rec.ts1_arrival_estimate) {
                g.r1 = 'ETA ' + rec.ts1_arrival_estimate
            }
            if (rec.ts1_departure_estimate) {
                g.r3 = 'ETD ' + rec.ts1_departure_estimate
            }
            if (rec.ts1_arrival) {
                g.isActive = true
                g.r1 = 'ATA ' + rec.ts1_arrival
            }
            if (rec.ts1_departure) {
                g.isActive = true
                g.r3 = 'ATD ' + rec.ts1_departure
            }
        }
        if (schedule.ts1code || rec.ts1_port) oneGraph.push(g)
        g = new graphNode()
        if (schedule.ts2code) {
            g.r1 = 'ETA ' + schedule.ts2eta
            g.r2 = schedule.ts2code
            g.r3 = 'ETD ' + schedule.ts2etd
        }
        if (rec.ts2_port) {
            if(rec.ts2_port_location && rec.ts2_port_location.length>0){
                g.r2 = rec.ts2_port_location
            }else{
                g.r2 = rec.ts2_port
            }
            if (rec.ts2_arrival_estimate) {
                g.r1 = 'ETA ' + rec.ts2_arrival_estimate
            }
            if (rec.ts2_departure_estimate) {
                g.r3 = 'ETD ' + rec.ts2_departure_estimate
            }
            if (rec.ts2_arrival) {
                g.isActive = true
                g.r1 = 'ATA ' + rec.ts2_arrival
            }
            if (rec.ts2_departure) {
                g.isActive = true
                g.r3 = 'ATD ' + rec.ts2_departure
            }
        }
        if (schedule.ts2code || rec.ts2_port) oneGraph.push(g)
        g = new graphNode()
        if (schedule.ts3code) {
            g.r1 = 'ETA ' + schedule.ts3eta
            g.r2 = schedule.ts3code
            g.r3 = 'ETD ' + schedule.ts3etd
        }
        if (rec.ts3_port) {
            if(rec.ts3_port_location && rec.ts3_port_location.length>0){
                g.r2 = rec.ts3_port_location
            }else{
                g.r2 = rec.ts3_port
            }
            if (rec.ts3_arrival_estimate) {
                g.r1 = 'ETA ' + rec.ts3_arrival_estimate
            }
            if (rec.ts3_departure_estimate) {
                g.r3 = 'ETD ' + rec.ts3_departure_estimate
            }
            if (rec.ts3_arrival) {
                g.isActive = true
                g.r1 = 'ATA ' + rec.ts3_arrival
            }
            if (rec.ts3_departure) {
                g.isActive = true
                g.r3 = 'ATD ' + rec.ts3_departure
            }
        }
        if (schedule.ts3code || rec.ts3_port) oneGraph.push(g)
        if (rec.reworking_port) {
            g = new graphNode()
            if(rec.reworking_port_location && rec.reworking_port_location.length>0){
                g.r2 = rec.reworking_port_location
            }else{
                g.r2 = rec.reworking_port
            }
            if (rec.reworking_arrival_estimate) {
                g.r1 = 'ETA ' + rec.reworking_arrival_estimate
            }
            if (rec.reworking_departure_estimate) {
                g.r3 = 'ETD ' + rec.reworking_departure_estimate
            }
            if (rec.reworking_arrival) {
                g.isActive = true
                g.r1 = 'ATA ' + rec.reworking_arrival
            }
            if (rec.sob_reworking) {
                g.isActive = true
                g.r3 = 'ATD ' + rec.sob_reworking
            }
            oneGraph.push(g)
        }
        // now get the pod done. this should come from schedule and overridden by dsr
        
        let pod = stype === 'LCL' ? schedule['POD UNLOC'] : schedule['dcode']
        let eta = stype === 'LCL' ? schedule['ETA (POD)'] : schedule['deta']
        if(pod || rec.pod){
            g = new graphNode()
            g.r2 = pod
            if (!isDummy) g.r1 = 'ETA ' + eta
            if(rec.eta_at_pod) g.r1 = 'ETA ' + rec.eta_at_pod
            if (rec.pod !== rec.fpod) {
                if(rec.pod_location && rec.pod_location.length>0){
                    g.r2 = rec.pod_location
                }else{
                    g.r2 = rec.pod
                }
                if (rec.pod_arrival) {
                    g.isActive = true
                    g.r1 = 'ATA ' + rec.pod_arrival
                }
                if (rec.gateout_to_fpod) {
                    g.isActive = true
                    g.r3 = 'ATD ' + rec.gateout_to_fpod
                }
                oneGraph.push(g)
            }
        }
        
        
        if(rec.fpod){

        
        g = new graphNode() // this is so that if pod != fpod we add pod and create fresh node for fpod
        if (rec.pod === rec.fpod) {
            if(rec.eta_at_pod) g.r1 = 'ETA ' + rec.eta_at_pod
        }
        if(rec.fpod_location && rec.fpod_location.length>0){
            g.r2 = rec.fpod_location
        }else{
            g.r2 = rec.fpod
        }
        if (rec.gatein_fpod) {
            g.isActive = true
            g.r1 = 'ATA ' + rec.gatein_fpod
        }
        if (rec.cargo_pickup_fpod) {
            g.isActive = true
            g.r3 = 'Picked-up ' + rec.cargo_pickup_fpod
        }
        if (rec.container_pickup_fpod) {
            g.isActive = true
            g.r3 = 'Picked-up ' + rec.container_pickup_fpod
        }
        oneGraph.push(g)
    }
        if((["Port-to-Door","Door-to-Door"].indexOf(sc)!=-1)){
        if (rec.destination && rec.fpod !== rec.destination) {
            g = new graphNode()
            g.r2 = 'Destination' // rec.destination is not to be shown now
            if (rec.cargo_delivery) {
                g.isActive = true
                g.r1 = 'Delivered ' + rec.cargo_delivery
            }
            if (rec.container_delivery) {
                g.isActive = true
                g.r1 = 'Delivered ' + rec.container_delivery
            }
            oneGraph.push(g)
        }}
        return oneGraph
    }
    static airGraphLine(rec) {
        let schedule = rec.booking.schedule
        let stype = rec.shipmentType
        let oneGraph = []
        let g
        { // origin
            if (rec.origin && (!rec.departure_airport || rec.departure_airport !== rec.origin)) {
                g = new graphNode()
                g.r2 = 'Origin'// rec.origin
                if (rec.cargo_pickup_date) {
                    g.isActive = true
                    g.r3 = 'Picked-up ' + rec.cargo_pickup_date
                }
                oneGraph.push(g)
            }
        }
        { // departure airport
            if (rec.departure_airport) {
                g = new graphNode()
                g.r2 = rec.departure_airport
                if (rec.arrival_at_departure_airport) {
                    g.isActive = true
                    g.r1 = 'Gate-in ' + rec.arrival_at_departure_airport
                }
                if (rec.loaded_on_board) {
                    g.isActive = true
                    g.r3 = 'ATD ' + rec.loaded_on_board
                }
                oneGraph.push(g)
            }
        }
        { // ts1 airport
            if (rec.ts1_airport) {
                g = new graphNode()
                g.r2 = rec.ts1_airport
                if (rec.ts1_airport_arrival) {
                    g.isActive = true
                    g.r1 = 'ATA ' + rec.ts1_airport_arrival
                }
                if (rec.ts1_airport_departure) {
                    g.isActive = true
                    g.r3 = 'ATD ' + rec.ts1_airport_departure
                }
                oneGraph.push(g)
            }
        }
        { // ts2 airport
            if (rec.ts2_airport) {
                g = new graphNode()
                g.r2 = rec.ts2_airport
                if (rec.ts2_airport_arrival) {
                    g.isActive = true
                    g.r1 = 'ATA ' + rec.ts2_airport_arrival
                }
                if (rec.ts2_airport_departure) {
                    g.isActive = true
                    g.r3 = 'ATD ' + rec.ts2_airport_departure
                }
                oneGraph.push(g)
            }
        }
        { // arrival airport
            if (rec.arrival_airport) {
                g = new graphNode()
                g.r2 = rec.arrival_airport
                if (rec.arrival_date) {
                    g.isActive = true
                    g.r1 = 'ATA ' + rec.arrival_date
                }
                if (rec.cargo_pickup_airport_of_arrival) {
                    g.isActive = true
                    g.r3 = 'Gate-out ' + rec.cargo_pickup_airport_of_arrival
                }
                oneGraph.push(g)
            }
        }
        { // destination
            if (rec.destination && (!rec.arrival_airport || rec.arrival_airport !== rec.destination)) {
                g = new graphNode()
                g.r2 = 'Destination' //rec.destination
                if (rec.cargo_delivery) {
                    g.isActive = true
                    g.r1 = 'Delivered ' + rec.cargo_delivery
                }
                oneGraph.push(g)
            }
        }

        return oneGraph
    }
}