import { subscribe, useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import { Agyesno } from "../components/Agyesno";
import { AgmodalStore, EntityV2Store, configStore, loginStore } from "../state/CommonState";
import Utils from "../util/Utils";
import { OneInput, OneInput1, OneLabel, OneLabel1 } from "./act-common";
import TaskDatetime from "./task-datetime";
import Taskfileapproval, { validateFileApproval } from "./task-file-approval";
import Taskfiledisplay from "./task-file-display";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
import { invoicePayment } from "./tasks-static-data";
import { getAction } from "./tasks-static-data"
import { MdDelete } from "react-icons/md";
import { BiPlus } from "react-icons/bi";
import Api from "../state/Api";
import CreditsApis from "../state/credit-api";
import { AgCustomFile } from "../components/AgFile";

export default function InvoicePayment(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    useSnapshot(AgmodalStore)
    let task = taskStore.current
    if(task.currencyType == undefined||task.currencyType == '') {
        task.currencyType = 'INR'
    }
    const Entity = task.financeInformation
    let stage = task.stage
    const [disableButton, setDisableButton] = useState(true)
    const [approved, setApproved] = useState(false)

    let docs = task.files
    if (!docs) docs = []
    const filecheck = () =>{
        let l = true
        docs.map((e, i) =>{
            if(e.approved != 'Approve'){
                l = false
            }
        })
        if(l!=approved){
            setApproved(l)
        }
    }
    if(stage==1){
        filecheck()
    }
    if(stage==10){
        stage = 5
    }
    function check(){

        let disable = false
        let currentPayment = task.currentPayment
        let ret = validateFileApproval(task)
        if (!ret.rejected){
            disable = true
            if(currentPayment?.transAmount && currentPayment.transDate && currentPayment.transNum){
                if(Utils.toNum(currentPayment.transAmount)==0 || currentPayment.transDate.length == 0 || currentPayment.transNum.length == 0 || (task.currencyType !== 'INR' && currentPayment.swiftUpload === "")||
                task.stage === 3&&task.isFullPayment === 'No' && (task.paymentReceived==''||task.paymentReceived==0)||
                task.stage === 3&&task.isFullPayment === 'Yes' && (task["Amount Received"]==''||task["Amount Received"]==0)
                ){
                    disable = true
                }else{
                    disable = false
                }
            }
            setDisableButton(disable)
        }else{
            setDisableButton(disable)
        }
    }
    useEffect(() => {
        check()
        let unsubscribe = subscribe(taskStore.current, () => {
            check()
        })
        return unsubscribe
    }, [taskStore.current])
    const filecat = '' + Date.now()

    if (stage === 1) {
        return (
            <div className="" style={{ width: '100%' }}>
                <p>
                    Please Approve the attached invoice by <strong>{Utils.formatToDateTime1(task.dueDate, task.dueTime)}</strong>
                </p>
                {
                    (task.remarks) ?
                        <p>Remarks : {task.remarks}</p>
                        : <></>
                }
                <hr />
                <Taskfileapproval additionalField={true} disable={true} />
                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                {task.currencyType?<tr>
                <td>
                <div style={{ marginRight: '5px', marginTop: '3px' }}>Currency Type </div>
                </td>
                <td>
                    <p>{task.currencyType}</p>
                </td>
                </tr>:<></>}
                    <tr>
                        <OneLabel label='Net Invoice Amount' name='netAmount' />
                    </tr>
                    <tr>
                        <OneLabel label='GST Amount' name='gstAmount' />
                    </tr>
                    <tr>
                        <OneLabel label='Total Amount' name='totalAmount' />
                    </tr>
                </table>

                {
                    (approved) ?
                        <div>
                            <hr />
                            {/* {task?.phonepayEnabled=="ON"?<> */}
                                <strong>Enter the details below</strong>
                                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                                   
                                   {task.currencyType === 'INR'?<tr>
                                        <OneInput label='NEFT/ RTGS Transaction Reference #'
                                            record={task.currentPayment} name='transNum' />
                                    </tr>:<tr>
                                        <OneInput label='SWIFT Transaction Reference #'
                                            record={task.currentPayment} name='transNum' />
                                    </tr>} 
                                    <tr>
                                        <OneInput type='date' label='Transaction Date'
                                            record={task.currentPayment} name='transDate' />
                                    </tr>
                                    <tr>
                                        <OneInput type='number' label='Transaction Amount'
                                            record={task.currentPayment} name='transAmount' />
                                    </tr>
                                    <tr>
                                        <OneInput label='Any Deductions?'
                                            record={task.currentPayment} name='deductions' />
                                    </tr> 
                                    {task.currencyType !== 'INR'?
                                    <tr>
                                            <td>
                                            <div style={{ marginRight: '5px', marginTop: '8px' }}>Swift Upload </div>
                                            </td>
                                                <td>{ task.currentPayment.swiftUpload?
                                                    <p><a href={process.env.REACT_APP_FILE_SERVER + '/clientfiles/' + task.currentPayment.swiftUpload} target="_blank">{task.currentPayment.swiftUpload}</a>
                                                                <span className="icon has-text-danger" style={{ cursor: 'pointer' }} onClick={() => {
                                                                    task.currentPayment.swiftUpload = ""
                                                                }}>
                                                                    <i className="fas fa-trash"></i>
                                                                </span>
                                                            </p> :
                                                    <AgCustomFile cat={filecat} multi={false} filetype={'swiftUpload'} callback={(filename)=>{
                                                        task.currentPayment.swiftUpload = filename;
                                                    }}/>
                                                }
                                                </td>
                                        </tr>:<></>
    }
                                </table>
                                {Entity && Entity.length >0 ?
                    <div className="px-4">
                        <div>
                            <p className="is-size-5 has-text-weight-bold mt-4">
                                Finance Contact Information
                            </p>
                            <div className="is-flex pt-4 ">
                                <table className="table_caf_fin wd_80">
                                    <thead>
                                        <td>Contact Person</td>
                                        <td>Description/Department</td>
                                        <td>Contact Number</td>
                                        <td>Email</td>
                                    </thead>
                                    <tbody>
                                        {Entity?.map((r, i) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <input
                                                            className={`inp_table_caf is-size-6`}
                                                            value={
                                                                Entity[i]?.customercontact
                                                            }
                                                            disabled={true}>
                                                        </input>
                                                    </td>
                                                    <td>
                                                        <input
                                                            className={`inp_table_caf is-size-6`}
                                                            value={Entity[i]?.department}
                                                            disabled={true}>
                                                        </input>
                                                    </td>
                                                    <td
                                                        style={{ borderRight: "none" }}
                                                        className={`is-flex wd_101`}>
                                                        <span className="contNumcodespn mt-1"></span>
                                                        <input
                                                            type="number"
                                                            className={`inp_table_caf is-size-6`}
                                                            value={Entity[i]?.contactnum}
                                                            disabled={true}
                                                            onKeyDown={(e) =>
                                                                ["e", "E", "+", "-", "."].includes(e.key) &&
                                                                e.preventDefault()
                                                            }
                                                            min={0}></input>
                                                    </td>
                                                    <td style={{ border: "1px solid black" }}>
                                                        <input
                                                            className={`inp_table_caf is-size-6`}
                                                            value={Entity[i]?.email}
                                                            disabled={true}
                                                        ></input>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
            </div>:<></>}
                                <p>
                                    <span>
                                        <button style={{ float: 'left' }} className="button islink" disabled={disableButton}
                                            onClick={async (e) => {
                                                let task = taskStore.current
                                                let ret = validateFileApproval(task)
                                                if (ret.errors.length > 0) {
                                                    configStore.setModalMessage(ret.errors.join(','))
                                                    return
                                                }
                                                if (ret.rejected)
                                                    [task.stage, task.pendingWith] = [2, 'AGRAGA']
                                                else {
                                                    [task.stage, task.pendingWith] = [3, 'AGRAGA']
                                                    //task.previousPayments.push(task.currentPayment)
                                                }
                                                taskStore.setCustomerComplete()
                                                await Taskapi.updateTask(taskStore.current)
                                                taskStore.triggerActionVisible = false
                                                taskStore.myActionVisible = false
                                                if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                                    AgmodalStore.pageVisible = false
                                                    AgmodalStore.apiCall = true
                                                }
                                            }}>Submit</button>
                                    </span>
                                </p>
                                {/* </>:null} */}

                        </div>
                        :  <p>
                            <span>
                                <button style={{ margin:'10px',float: 'left' }} className="button islink" disabled={disableButton}
                                    onClick={async (e) => {
                                        let task = taskStore.current
                                        let ret = validateFileApproval(task)
                                        if (ret.errors.length > 0) {
                                            configStore.setModalMessage(ret.errors.join(','))
                                            return
                                        }
                                        if (ret.rejected)
                                            [task.stage, task.pendingWith] = [2, 'AGRAGA']
                                        else {
                                            [task.stage, task.pendingWith] = [3, 'AGRAGA']
                                            //task.previousPayments.push(task.currentPayment)
                                        }
                                        taskStore.setCustomerComplete()
                                        await Taskapi.updateTask(taskStore.current)
                                        taskStore.triggerActionVisible = false
                                        taskStore.myActionVisible = false
                                        if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                            AgmodalStore.pageVisible = false
                                            AgmodalStore.apiCall = true
                                        }
                                    }}>Submit</button>
                            </span>
                        </p>
                }

            </div>
        )
    }
    if (stage === 2) {
        return (
            <>
                <p><strong>The Invoice was rejected.</strong></p>
                <Taskfiletable showRejectReason={true} additionalField={true} />
                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                {task.currencyType?<tr>
                <td>
                <div style={{ marginRight: '5px', marginTop: '3px' }}>Currency Type </div>
                </td>
                <td>
                    <p>{task.currencyType}</p>
                </td>
                </tr>:<></>}
                    <tr>
                        <OneInput label='Net Invoice Amount' name='netAmount' />
                    </tr>
                    <tr>
                        <OneInput label='GST Amount' name='gstAmount' />
                    </tr>
                    <tr>
                        <OneLabel1 label='Total Amount' name='totalAmount' />
                    </tr>
                </table>
                <TaskDatetime />
                <p>
                    <span><strong>Remarks</strong></span>
                    <br />
                    <span><textarea cols='50'
                        value={taskStore.current.remarks} onChange={(e) => {
                            taskStore.current.remarks = e.target.value
                        }} /></span>
                </p>
                <p>
                    <span>

                        <button style={{ float: 'left' }} className="button islink"
                            onClick={async (e) => {
                                taskStore.current.stage = 1
                                taskStore.current.pendingWith = 'CUSTOMER'
                                taskStore.current.customerStatus = 'PENDING'
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                                if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                    AgmodalStore.pageVisible = false
                                    AgmodalStore.apiCall = true
                                }
                            }}>Submit</button>
                    </span>
                </p>
            </>
        )
    }
    if (stage === 3) {
        return (
            <div style={{ width: '100%' }}>
                <p>The Invoice was approved.</p>
                <hr />
                <Taskfiledisplay additionalField={true} />
                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                {task.currencyType?<tr>
                <td>
                <div style={{ marginRight: '5px', marginTop: '3px' }}>Currency Type </div>
                </td>
                <td>
                    <p>{task.currencyType}</p>
                </td>
                </tr>:<></>}
                    <tr>
                        <OneLabel label='Net Invoice Amount' name='netAmount' />
                    </tr>
                    <tr>
                        <OneLabel label='GST Amount' name='gstAmount' />
                    </tr>
                    <tr>
                        <OneLabel label='Total Amount' name='totalAmount' />
                    </tr>
                    <tr>
                        <OneLabel label='Due Date' name='dueDate' />
                    </tr>
                </table>
                <div>
                    <hr />
                    <strong>Payment Details are as below1</strong>
                    <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                    {task.currencyType === 'INR'?<tr>
                            <OneLabel label='NEFT/ RTGS Transaction Reference #'
                                record={task.currentPayment} name='transNum' />
                        </tr>:<OneLabel label='SWIFT Transaction Reference #'
                                record={task.currentPayment} name='transNum' />}
                        <tr>
                            <OneLabel type='date' label='Transaction Date'
                                record={task.currentPayment} name='transDate' />
                        </tr>
                        <tr>
                            <OneLabel type='number' label='Transaction Amount'
                                record={task.currentPayment} name='transAmount' />
                        </tr>
                        <tr>
                            <OneLabel label='Any Deductions?'
                                record={task.currentPayment} name='deductions' />
                        </tr>
                    </table>
                </div>
                <div>
                    <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                        <tr>
                            <td></td>
                            <td>
                                <Agyesno label='Full Payment Received?' record={task} name='isFullPayment' />
                            </td>
                        </tr>
                        <tr>
                            {
                        (task.isFullPayment === 'Yes') ? <td style={{width:"70%"}}>
                                    <td>
                                        <div
                                            style={{ marginRight: '5px', marginTop: '3px' }}
                                        >Amount Received</div>
                                    </td>
                                    <td >
                        <input style={{position:"relative",left:"25rem"}} className={`input is-small ${ (!taskStore.current.isValid && taskStore.current.isValidfield.indexOf("Amount Received") != -1)  ? "is-danger" : ""} `} value={task["Amount Received"]}  defaultValue={task.totalAmount}
                                            onChange={(e) => {
                                                task["Amount Received"] = e.target.value
                                                console.log('changed value =', task["Amount Received"])
                                            }} />
                                    </td></td> : <OneInput label='Amount Received' type='number' name='paymentReceived' />
                            }
                        </tr>
                    </table>
                    {Entity && Entity.length >0 ?
                        <div className="px-4">
                            <div>
                                <p className="is-size-5 has-text-weight-bold mt-4">
                                    Finance Contact Information
                                </p>
                                <div className="is-flex pt-4 ">
                                    <table className="table_caf_fin wd_80">
                                        <thead>
                                            <td>Contact Person</td>
                                            <td>Description/Department</td>
                                            <td>Contact Number</td>
                                            <td>Email</td>
                                        </thead>
                                        <tbody>
                                            {Entity?.map((r, i) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <input
                                                                className={`inp_table_caf is-size-6`}
                                                                value={
                                                                    Entity[i]?.customercontact
                                                                }
                                                                disabled={true}>
                                                            </input>
                                                        </td>
                                                        <td>
                                                            <input
                                                                className={`inp_table_caf is-size-6`}
                                                                value={Entity[i]?.department}
                                                                disabled={true}>
                                                            </input>
                                                        </td>
                                                        <td
                                                            style={{ borderRight: "none" }}
                                                            className={`is-flex wd_101`}>
                                                            <span className="contNumcodespn mt-1"></span>
                                                            <input
                                                                type="number"
                                                                className={`inp_table_caf is-size-6`}
                                                                value={Entity[i]?.contactnum}
                                                                disabled={true}
                                                                onKeyDown={(e) =>
                                                                    ["e", "E", "+", "-", "."].includes(e.key) &&
                                                                    e.preventDefault()
                                                                }
                                                                min={0}></input>
                                                        </td>
                                                        <td style={{ border: "1px solid black" }}>
                                                            <input
                                                                className={`inp_table_caf is-size-6`}
                                                                value={Entity[i]?.email}
                                                                disabled={true}
                                                            ></input>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
            </div>:<></>}

                    {/* <div>{task.totalAmount}</div> */}
                </div>
                {
                    (stage === 3) ?
                        <p>
                            <button style={{ margin:'10px',float: 'left' }} className="button islink" disabled={disableButton}
                                onClick={async (e) => {
                                    if (task.isFullPayment === 'No') {
                                        taskStore.current.stage = 4
                                        taskStore.current.previousPayments.push(taskStore.current.currentPayment)
                                        taskStore.current.currentPayment = { ...invoicePayment }
                                        taskStore.current.pendingWith = 'CUSTOMER'
                                        taskStore.current.customerStatus = 'PENDING'
                                        await Taskapi.updateTask(taskStore.current)
                                    } else {
                                        taskStore.current.stage = 5
                                        taskStore.current.pendingWith = 'AGRAGA'
                                        taskStore.setComplete()
                                        Taskapi.sendActionAgragaTriggerEmail()
                                        await Taskapi.updateTask(taskStore.current)
                                    }
                                    taskStore.triggerActionVisible = false
                                    taskStore.myActionVisible = false
                                    if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                        AgmodalStore.pageVisible = false
                                        AgmodalStore.apiCall = true
                                    }
                                }}>Submit</button>

                        </p>
                        : <></>
                }
            </div>
        )
    }
    if (stage === 4) {
        return (
            <div className="" style={{ width: '100%' }}>
                <p>
                    <strong>Please complete your payment</strong>
                </p>
                <hr />
                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                {task.currencyType?<tr>
                <td>
                <div style={{ marginRight: '5px', marginTop: '3px' }}>Currency Type </div>
                </td>
                <td>
                    <p>{task.currencyType}</p>
                </td>
                </tr>:<></>}
                    <tr>
                        <OneLabel label='Net Invoice Amount' name='netAmount' />
                    </tr>
                    <tr>
                        <OneLabel label='GST Amount' name='gstAmount' />
                    </tr>
                    <tr>
                        <OneLabel label='Total Amount' name='totalAmount' />
                    </tr>
                    <tr>
                        <OneLabel label='Payment Received' name='paymentReceived' />
                    </tr>
                    <tr>
                        <td>Balance to be paid</td>
                        <td style={{color:"red",fontWeight:"bold"}}>{Utils.toNum(task.totalAmount) - Utils.toNum(task.paymentReceived)}</td>
                    </tr>
                </table>
                <hr />
                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                    {
                        task.previousPayments.map(e => {
                            return <tr>
                                <td>{e.transNum}</td>
                                <td>{Utils.formatToDateTime1(e.transDate, '')}</td>
                                <td>{e.transAmount}</td>
                            </tr>
                        })
                    }
                </table>
                <hr />
                <strong>Enter payment details below</strong>
                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                                   
                                   {task.currencyType === 'INR'?<tr>
                                        <OneInput label='NEFT/ RTGS Transaction Reference #'
                                            record={task.currentPayment} name='transNum' />
                                    </tr>:<tr>
                                        <OneInput label='SWIFT Transaction Reference #'
                                            record={task.currentPayment} name='transNum' />
                                    </tr>} 
                                    <tr>
                                        <OneInput type='date' label='Transaction Date'
                                            record={task.currentPayment} name='transDate' />
                                    </tr>
                                    <tr>
                                        <OneInput type='number' label='Transaction Amount'
                                            record={task.currentPayment} name='transAmount' />
                                    </tr>
                                    <tr>
                                        <OneInput label='Any Deductions?'
                                            record={task.currentPayment} name='deductions' />
                                    </tr>
                                    {task.currencyType !== 'INR'?                                    
                                    <tr>
                                            <td>
                                            <div style={{ marginRight: '5px', marginTop: '8px' }}>Swift Upload </div>
                                            </td>
                                                <td>{ task.currentPayment.swiftUpload?
                                                    <p><a href={process.env.REACT_APP_FILE_SERVER + '/clientfiles/' + task.currentPayment.swiftUpload} target="_blank">{task.currentPayment.swiftUpload}</a>
                                                                <span className="icon has-text-danger" style={{ cursor: 'pointer' }} onClick={() => {
                                                                    task.currentPayment.swiftUpload = ""
                                                                }}>
                                                                    <i className="fas fa-trash"></i>
                                                                </span>
                                                            </p> :
                                                    <AgCustomFile cat={filecat} multi={false} filetype={'swiftUpload'} callback={(filename)=>{
                                                        task.currentPayment.swiftUpload = filename;
                                                    }}/>
                                                }
                                                </td>
                                        </tr>:<></>
                                    }
                                </table>
                {Entity && Entity.length >0 ?
                        <div className="px-4">
                            <div>
                                <p className="is-size-5 has-text-weight-bold mt-4">
                                    Finance Contact Information
                                </p>
                                <div className="is-flex pt-4 ">
                                    <table className="table_caf_fin wd_80">
                                        <thead>
                                            <td>Contact Person</td>
                                            <td>Description/Department</td>
                                            <td>Contact Number</td>
                                            <td>Email</td>
                                        </thead>
                                        <tbody>
                                            {Entity?.map((r, i) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <input
                                                                className={`inp_table_caf is-size-6`}
                                                                value={
                                                                    Entity[i]?.customercontact
                                                                }
                                                                disabled={true}>
                                                            </input>
                                                        </td>
                                                        <td>
                                                            <input
                                                                className={`inp_table_caf is-size-6`}
                                                                value={Entity[i]?.department}
                                                                disabled={true}>
                                                            </input>
                                                        </td>
                                                        <td
                                                            style={{ borderRight: "none" }}
                                                            className={`is-flex wd_101`}>
                                                            <span className="contNumcodespn mt-1"></span>
                                                            <input
                                                                type="number"
                                                                className={`inp_table_caf is-size-6`}
                                                                value={Entity[i]?.contactnum}
                                                                disabled={true}
                                                                onKeyDown={(e) =>
                                                                    ["e", "E", "+", "-", "."].includes(e.key) &&
                                                                    e.preventDefault()
                                                                }
                                                                min={0}></input>
                                                        </td>
                                                        <td style={{ border: "1px solid black" }}>
                                                            <input
                                                                className={`inp_table_caf is-size-6`}
                                                                value={Entity[i]?.email}
                                                                disabled={true}
                                                            ></input>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
            </div>:<></>}
                
                <p>
                    <span>
                        <button style={{margin:'10px',float: 'left' }} className="button islink" disabled={disableButton}
                            onClick={async (e) => {
                                let task = taskStore.current
                                task.stage = 3
                                task.pendingWith = 'AGRAGA'
                                taskStore.setCustomerComplete()
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                                if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                    AgmodalStore.pageVisible = false
                                    AgmodalStore.apiCall = true
                                }
                            }}>Submit</button>
                    </span>
                </p>
            </div>
        )
    }
    if (stage === 5) {
        return (
            <div className="" style={{ width: '100%' }}>
                <p>
                    <strong>Invoice and payment details</strong>
                </p>
                <Taskfiledisplay additionalField={true} />


                <hr />
                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                {task.currencyType?<tr>
                <td>
                <div style={{ marginRight: '5px', marginTop: '3px' }}>Currency Type </div>
                </td>
                <td>
                    <p>{task.currencyType}</p>
                </td>
                </tr>:<></>}
                    <tr>
                        <OneLabel label='Net Invoice Amount' name='netAmount' />
                    </tr>
                    <tr>
                        <OneLabel label='GST Amount' name='gstAmount' />
                    </tr>
                    <tr>
                        <OneLabel label='Total Amount' name='totalAmount' />
                    </tr>
                    <tr>
                        <OneLabel label='Payment Received' name='paymentReceived' />
                    </tr>
                </table>
                <hr />
                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                    {
                        task.previousPayments.map(e => {
                            return <tr>
                                <td>{e.transNum}</td>
                                <td>{Utils.formatToDateTime1(e.transDate, '')}</td>
                                <td>{e.transAmount}</td>
                            </tr>
                        })
                    }
                </table>
                {Entity && Entity.length >0 ?
                    <div className="px-4">
                        <div>
                            <p className="is-size-5 has-text-weight-bold mt-4">
                                Finance Contact Information
                            </p>
                            <div className="is-flex pt-4 ">
                                <table className="table_caf_fin wd_80">
                                    <thead>
                                        <td>Contact Person</td>
                                        <td>Description/Department</td>
                                        <td>Contact Number</td>
                                        <td>Email</td>
                                    </thead>
                                    <tbody>
                                        {Entity?.map((r, i) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <input
                                                            className={`inp_table_caf is-size-6`}
                                                            value={
                                                                Entity[i]?.customercontact
                                                            }
                                                            disabled={true}>
                                                        </input>
                                                    </td>
                                                    <td>
                                                        <input
                                                            className={`inp_table_caf is-size-6`}
                                                            value={Entity[i]?.department}
                                                            disabled={true}>
                                                        </input>
                                                    </td>
                                                    <td
                                                        style={{ borderRight: "none" }}
                                                        className={`is-flex wd_101`}>
                                                        <span className="contNumcodespn mt-1"></span>
                                                        <input
                                                            type="number"
                                                            className={`inp_table_caf is-size-6`}
                                                            value={Entity[i]?.contactnum}
                                                            disabled={true}
                                                            onKeyDown={(e) =>
                                                                ["e", "E", "+", "-", "."].includes(e.key) &&
                                                                e.preventDefault()
                                                            }
                                                            min={0}></input>
                                                    </td>
                                                    <td style={{ border: "1px solid black" }}>
                                                        <input
                                                            className={`inp_table_caf is-size-6`}
                                                            value={Entity[i]?.email}
                                                            disabled={true}
                                                        ></input>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
            </div>:<></>}
            </div>
        )
    }
}
