import TC from "../TC";
import {
  ENTITY_TYPE,
  VENDOR_CATEGORY_NONIN,
  STAKEHOLDER_DEFAULT_VALUES,
  VENDOR_CATEGORY,
} from "../util/constants";



class Quotation {
  set(e) {
    Object.assign(this, new Quotation());
    Object.assign(this, e);
    //console.log('\n\n\nWITHIN SET=', e.vehicleDetails, this.vehicleDetails, '\n\n\n')
  }
  intialize() {
    this.sender = "";
    this.salesperson = "";
    this.customerService = "";
    this.quoteSenderEmail = "";
    this.customerEmail = ""; // this is to be populated with the entityAdmin mail if present
    this.entityName = ""; // will contain the entityName with state
    this.entityId = "";
    // this.gst = ""; // this will contain the gst
    this.emails = "";
    this.shipmentType = "LCL";
    this.shipmentScope = "Port-to-Port";
    this.origin = "";
    this.destination = "";
    this.cargoReadinessDate = "";
    this.portOfLoading = "";
    this.portOfDischarge = "";
    this.originAirport = "";
    this.destinationAirport = "";
    this.portOfReceipt = "";
    this.finalPlaceOfDelivery = "";
    this.isfromAgragaEnquiry = ""
    this.countryFromEnquiry = ""
    this.customerReferenceNum = "";
    this.quotationNum = "";
    this.validUpto = "";
    this.hazardous = "No";
    this.quoteClass = "";
    this.packingGroup = "";
    this.unnumber = "";
    // this.carrierMBL = 'No'
    this.nonStackable = "No";
    this.commodityType = "";
    this.incoTerms = "";
    this.carrier = "";
    this.transitTime = 0;
    this.factoryOrDockStuffing = "";
    this.stuffingType = "Factory";
    this.destuffingType = "Factory";
    this.temperatureControlled = "No";
    this.temperature = "";
    this.cargoValue = 0;
    this.minTemperature = 0;
    this.maxTemperature = 0;
    this.airline = "";
    this.exchangeUSD2INR = 0;
    this.exchangeSpecif2INR = 0;
    this.tc = TC;
    this.customTC = "";

    this.status = "DRAFT";
    this.mailedOn = "";
    this.isFromEnquiry = false;
    this.consolidatorList = null;
    this.outCome = ``;
    this.quoteStatus = "";
    this.decline_reason = "";
    this.tsDeclineBy = "";
    this.tsDeclineOn = "";
    this.LostReason = "";
    this.tsLostBy = "";
    this.tsLostOn = "";
    this.InvalidReason = "";
    this.tsInvalidBy = "";
    this.tsInvalidOn = "";
    this.tsWonBy = "";
    this.tsWonOn = "";
    this.vehicleDetails = null
    this.procurement = ""
    this.fba = "No"
    this.pick_up_by_amazon = 'No'
    this.hasToShowDummySchedule = false
    this.emptycontainerweight = "yes"
    this.cargoDimensionUnit = "Cms"
    this.fbaIorRequired = 'No'
    this.fbaOCC = 'No'
    this.fbaDCC = 'No'
    this.fbaSurvey = 'No'
    this.fbaSAD = 'No'
    this.fbaPAL = 'No'
    this.fbaDays = 0
    this.multidest = []
    this.destinationFreeTime = 0
  }
  constructor() {
    this.intialize();
  }
}

class SchedulesPortToPort{
  intialize() {
    this.origin= ''
    this.destination=""
    this.scheduleDate=""
    this.next=42
    this.carrier=""
    this.consolidatorList=""
    this.portOfReceipt=""
  
  }
  constructor(){
    this.intialize();
  }
  set(o) {
    Object.assign(this, new SchedulesPortToPort());
    Object.assign(this, o);
  }
}

class ShipperConsignee{
  intialize() {
    this.address= ''
    this.pincode=""
    this.linkedEntity=""
    this.firstName=""
    this.lastName=""
    this.emailId=""
    this.mobileNumber=""
    this.country="India"
    this.state=""
    this.city=""
    this.companyName=""
  }
  constructor(){
    this.intialize();
  }
  set(o) {
    Object.assign(this, new ShipperConsignee());
    Object.assign(this, o);
  }
}
class CargoDetails {
  constructor() {
    this.id = 0;
    this.packageType = "";
    this.numPackages = 0;
    this.wtPerPackage = 0;
    this.weightPerPackage = 'Kgs';
    this.dimensions = 'Cms';
    this.length = 0;
    this.width = 0;
    this.height = 0;
    this.volPerPackage = 0;
    this.totalWeight = 0;
    this.totalVolume = 0;
    this.chargeableWeight = 0;
  }
}
class ContainerDetails {
  constructor() {
    this.id = 0;
    this.containerType = "";
    this.numContainers = 0;
    this.wtPerContainer = 0;
  }
}
class FCLCharge {
  constructor() {
    this.chargeKey = "";
    this.perWM = 0;
    this.perShipment = 0;
    this.total = 0;
  }
}
class FCLQuoteCharges {
  constructor() {
    this.id = 0;
    this.chargeDescription = "";
    this.currency = "";
    this.fclCharges = [];
  }
}
class QuoteCharges {
  constructor() {
    this.id = 0;
    this.chargeDescription = "";
    this.currency = "";
    this.perWM = 0;
    this.minimum = 0;
    this.perShipment = 0;
    this.total = 0;
  }
}
class AirQuoteCharges {
  constructor() {
    this.id = 0;
    this.chargeDescription = "";
    this.currency = "";
    this.perKg = 0;
    this.minimum = 0;
    this.perAWB = 0;
    this.total = 0;
  }
}
class QuoteCharges1 {
  constructor() {
    this.id = 0;
    this.chargeDescription = "";
    this.currency = "";
    this.perCBM = 0;
    this.perTon = 0;
    this.minimum = 0;
    this.maximum = 0;
    this.perShipment = 0;
    this.total = 0;
  }
}
class AgUserEntity {
  constructor() {
    this.email = ""; // this is the aguser id
    this.gst = ""; // this is the entity id
    this.role = "CLIENT USER"; // can also be 'CLIENT ADMIN'
  }
}

class EntityType {
  constructor() {
    this._id = ''
    this.entityName = ''
    this.gst = ''
    this.clientId = ''
    this.pan = ''
    this.address = ''
    this.salesPerson = ''
    this.customerService = ''
    this.state = ''
    this.country = ''
    this.pinCode = ''
    this.admin = ''
    this.IECCode = ''
    this.isCreditCustomer = 'No'
    this.isTrackMyBooking = 'No'
    this.msme = 'No'
    this.isAirChecked = false
    this.isOceanChecked = false
    this.isDTChecked = false
    this.creditCustomerdaysCount = 1
    this.creditLimitCurrencyType = 'INR'
    this.creditLimit = 0
    this.status = 'TEMPORARY'
    this.companyType=""
    this.logisticInformation = [
      {
        customercontact: "",
        department: "",
        contactnum: "",
        email: "",
        function: "",
        product: [],
        type: "",
        location: ""
      }
    ];
    this.documents = [
      { name: 'IEC Code', fileName: 'iec_code', fileLink: '' },
      { name: 'PAN Card', fileName: 'pan_card', fileLink: '' },
      { name: 'GST Certificate', fileName: 'gst_cert', fileLink: '' },
      { name: 'Credit Application Form (CAF)', fileName: 'caf', fileLink: '' }
    ]
  }
  set(o) {
    Object.assign(this, new EntityType())
    Object.assign(this, o)
  }
}
class AguserType {
  constructor() {
    this._id = "";
    this.linkedinProfile = "";
    this.profilePic = "";
    this.firstName = "";
    this.lastName = "";
    this.companyName = "";
    this.phonePrefix = "+91";
    this.phone = "";
    this.email = "";
    this.country = "India";
    this.password = "";
    this.status = "NEWREG"; //NEWREG, ACCEPTED, INACTIVE, ACTIVE
    this.role = [];
    this.department = "";
    this.entityList = []; // Array of AgUserentity
    this.client_id = null;
    this.gst = "";
    this.vertical = '';
    this.role = '';
    this.AdminSalesVertical = [];
    this.RMSalesVertical = [];
    this.division = '';
    this.branch = '';
    this.regional = '';
    this.zonal = '';
    this.national = '';
    this.reportingManager = '';
    this.isAgragaUser = 'No';
    this.mainEntityList = [];
    this.inviteSend=false;
    this.mobileVerified=false;
    this.notification={
      whatsapp:false,
      email:false
    }
  }
  set(o) {
    Object.assign(this, new AguserType());
    Object.assign(this, o);
  }
}

class CustomeruserType {
  constructor() {
    this._id = "";
    this.firstName = "";
    this.lastName = "";
    this.phonePrefix = "+91";
    this.phone = "";
    this.email = "";
    this.remarks = "";
    this.country = "India";
    this.password = "";
    this.status = "NEWREG"; //NEWREG, ACCEPTED, INACTIVE, ACTIVE
    this.entityList = []; // Array of AgUserentity
    this.mainEntityList= [];  
    this.client_id = null;
    this.role= "";  
    this.vertical = '';
    this.division = '';
    this.branch = '';
    this.regional = '';
    this.zonal = '';
    this.national = '';
    this.reportingManager = '';
    this.isAgragaUser = 'No';
    this.inviteSend = false;
    this.mobileVerified=false;
  }
  set(o) {
    Object.assign(this, new CustomeruserType());
    Object.assign(this, o);
  }
}
class Customer {
  constructor() { 
    this.customerType = "";
    this.crossBorder = {
      salesVertical: "",
      agragaBranch:"",
      relationshipManager: "",
      customerService: "",
    }
    this.domesticTransport = {
      relationshipManager: "",
      agragaBranch:"",
      customerService: "",
      branchOperationManager: "",
      billingCycle:"",
      podInvoiceAcceptance :""
       
    }
    // this.customerType = "";
    // this.relationshipManager = "";
    // this.customerService = "";
    // this.branchOperationManager = "";
  }
}

class Vendor {
  constructor(entity) {
    this.vendorType = "";
    if (entity?.vendor?.vendorType === VENDOR_CATEGORY.ROAD_TRANSPORT) {
      this.operatorType = "";
      this.scopeOfActivityRT = [];
    } else if (entity?.vendor?.vendorType === VENDOR_CATEGORY.CUSTOMS_BROKER) {
      this.scopeOfActivityCB = [];
    } else if (
      entity?.vendor?.vendorType === VENDOR_CATEGORY.FREIGHT_FORWARDER
    ) {
      this.isRoadTransportApplied = false;
      this.isCustomBrokerApplied = false;
      if (entity?.vendor?.isRoadTransportApplied) {
        this.operatorType = "";
        this.scopeOfActivityRT = [];
      }
      if (entity?.vendor?.isCustomBrokerApplied) {
        this.scopeOfActivityCB = [];
      }
    }else if (entity?.vendor?.vendorType === VENDOR_CATEGORY_NONIN.FREIGHT_FORWARDER) {
      this.scopeOfActivityFreitForward =[];
    } 


    this.fileVendorAgreement = "";
    this.MsmeAgreement = "";
    this.isVendorAgreementApproved = false;
    this.creditFacility = false; // Yes
    if (entity?.vendor?.creditFacility) {
      this.creditDays = 0;
      this.currency = STAKEHOLDER_DEFAULT_VALUES.CURRENCY;
      this.creditLimit = 0;
    }

    this.advancePayable = "";
    this.loginUrl = "";
    this.userId = "";
    this.password = "";
    this.remarks = "";
    this.handlingCharges = {
      perCbm: {
        currency: STAKEHOLDER_DEFAULT_VALUES.CURRENCY,
        value: 0,
      },
      perBL: {
        currency: STAKEHOLDER_DEFAULT_VALUES.CURRENCY,
        value: 0,
      },
      perContainer: {
        currency: STAKEHOLDER_DEFAULT_VALUES.CURRENCY,
        value: 0,
      },
      perAWB: {
        currency: STAKEHOLDER_DEFAULT_VALUES.CURRENCY,
        value: 0,
      },
    };

    this.accountHolderName = "";
    this.bankName = "";
    this.accountNo = "";
    this.fileCancelledChequeOrEmail = "";
  }
}

class EntityTypeV2 {
  constructor() {
    this.api = {
      key : "",
      active:"No"
    };
    this.entityId = "";
    this.entityName = "";
    this.entityCountry = "India";
    this.entityCountryCode = "IN";
    this.entityType = "";
    this.agragaBranch = "";
    this.panNum = "";
    this.isPanApproved = false;
    this.aadharNum = "";
    this.IECCode = "";
    this.isActive = true;
    this.status = "INCOMPLETE";
    this.mandatoryCheck = false;
    this.isIECDocumentApproved = false;
    this.kycVerification = false;
    this.operatorType = "";
    this.advanceReceivableForDT = "";
    this.advancePayable = "";
    this.tinNum = "";
    this.isTaxApproved = "";
    this.swiftCode = "";
    this.reactivatedReason = "";
    this.deactivatedReason = "";
    this.createdBy = "";
    this.updatedBy = "";
    this.verifiedBy = "";
    this.rejectedBy = "";
    this.isTrackMyBooking="No";
    this.isAirChecked = false;
    this.isOceanChecked = false;
    this.isDTChecked = false;
    this.erpnumber = "";
    this.agragaBranch = "";
    this.scopeOfBusiness = [];
    this.timeline = [
      // {
      //   date: "",
      //   event: "",
      //   user: "",
      //   role: "",

      // },
    ];
    this.entityLogo = {
      fileLink : "",
      fileName : "",
      pkey : ""
    }
    this.logisticInformation = [
      {
        customercontact: "",
        department: "",
        contactnum: "",
        email: "",
        function: "",
        product: [],
        type: "",
        location: ""
      }
    ];  
    this.financeInformation = [
      {
        customercontact: "",
        department: "",
        contactnum: "",
        email: ""
      }
    ];  
       this.Additionaldocs = [
      {
        name: "",
        fileName: "",
        pkey: "",
        fileLink: ""
      }
    ];  
    this.customer = new Customer();
    this.vendor = {
      vendorType: "",
      isRoadTransportApplied: false,
      isCustomBrokerApplied: false,
      operatorType: "",
      isSoleProp: false,
      isMsme: false,
      isRoadTransportService :false,
      isWarehouseService:false,
      gstType: "",
      scopeOfActivityRT: [],
      scopeOfActivityCB: [],
      scopeOfActivityFreitForward: [],
      aadhaar: "",
      isCreditFacilitated: false,
      creditDays: "",
      currency: "INR",
      creditLimit: "",

      relationShipManagerSupply: "",
      loginUrl: "",
      userId: "",
      password: "",
      remarks: "",
      handlingCharges: {
        perCbm: { currency: "INR", value: 0 },
        perBL: { currency: "INR", value: 0 },
        perContainer: { currency: "INR", value: 0 },
        perAWB: { currency: "INR", value: 0 },
      },
      isVendorAgreementApproved: false,
      isMsmeAgreementApproved: false,
     
      accountHolderName: "",
      bankName: "",
      accountNo: "",
      IFSCcode: "",
      swiftCode: "",
      fileCancelledChequeOrEmail: "",
      tradeLine: [
        {
          fromState: "",
          fromCity: "",
          toState: "",
          toCity: "",
        }
      ],
      serviceAbilityCities: [
      ]
    };
    this.branch = [
      {
        entityId: "",
        branchId: "",
        branchName: "",
        branchAddresses: [
          {
            address: "",
            city: "",
            pincode: "",
            zipcode: ""
          }
        ],
        state: "",
        country: "",
        branchcountryCode: "",
        advanceReceivableForDT: "",
        gst: "",
        documents: [
          { name: "Identifer Reference Number", fileName: "", pkey: "", fileLink: "" },
          { name: "gstCert", fileName: "", pkey: "", fileLink: "" },
        ],
        uidName: "",
        uidRefNo: "",
        status: "PENDING",
        isActive: true,
      },
    ];
    this.documents = [
      { name: "IEC CODE", fileName: "", pkey: "", fileLink: "" },
      { name: "panCard", fileName: "", pkey: "", fileLink: "" },
      { name: "gstCertificate", fileName: "", pkey: "", fileLink: "" },
      { name: "aadharCert", fileName: "", pkey: "", fileLink: "" },
      { name: "tinCert", fileName: "", pkey: "", fileLink: "" },
      { name: "taxresCert", fileName: "", pkey: "", fileLink: "" },
      { name: "fileVendorAgreement", fileName: "", pkey: "", fileLink: "" },
      { name: "UID", fileName: "", pkey: "", fileLink: "" },
      { name: "CkLeafOrBankStat", fileName: "", pkey: "", fileLink: "" },
      { name: "gstDeclaration", fileName: "", pkey: "", fileLink: "" },
      { name: "MsmeAgreement", fileName: "", pkey: "", fileLink: "" },
      { name: "InvoiceFormat", fileName: "", pkey: "", fileLink: "" },
    ];
  }
  set(o) {
    Object.assign(this, new EntityType());
    Object.assign(this, o);
  }
  isCustomer(entity) {
    return (
      entity?.entityType?.toLowerCase() === ENTITY_TYPE.CUSTOMER.toLowerCase()
    );
  }

  isVendor(entity) {
    return (
      entity?.entityType?.toLowerCase() === ENTITY_TYPE.VENDOR.toLowerCase()
    );
  }

  hasValidData(value) {
    if (Array.isArray(value)) {
      for (const item of value) {
        if (!this.hasValidData(item)) {
          return false;
        }
      }
      return true;
    }

    if (typeof value === "object" && value !== null) {
      for (const key in value) {
        if (!key === this.documents) {
          if (!this.hasValidData(value[key])) {
            return false;
          }
        }
      }
      return true;
    }

    return !!value;
  }
}

class VehicleType {
  constructor() {
    this._id = "";
    this.Regnumber = "";
    this.Vechtype = "";
    this.Vechownname = "";
    this.VechBaseLoc = "";
    this.InsExpdate = "";
    this.InsExpdateFlag = false;
    this.PucExpdate = "";
    this.PucExpdateFlag = false;
    this.FcExpdate = "";
    this.FcExpdateFlag = false;
    this.RcIssuedate = "";
    this.RcExpdate = "";
    this.RcExpdateFlag = false;
    this.RcIssuedateFlag = false;
    this.VehicleDetailsFlag= false;
    this.Status = "TEMPORARY";
    this.Country = "";
    this.State = "";
    this.StateFlag = false;
    this.City = "";
    this.admin = "";
    this.Remarks = "";
    this.createdBy = "";
    this.updatedBy = "";
    this.verifiedBy = "";
    this.rejectedBy="";
    this.ulip = false;
    this.vehicleDataFlag=false;
    this.StateCode = "";
    this.MakerModel = ''
    this.timeline = []
    this.Documents = [
      {
        name: "Upload RC Document",
        fileName: "rc_document",
        fileLink: "",
        pkey: "",
      },
      {
        name: "Upload Agreement of sale",
        fileName: "agreement_sale",
        fileLink: "",
        pkey: "",
      },
      {
        name: "Upload Insurance",
        fileName: "insurance",
        fileLink: "",
        pkey: "",
      },
      { name: "Upload PUC", fileName: "puc", fileLink: "", pkey: "" },
      { name: "Upload FC", fileName: "fc", fileLink: "", pkey: "" },
    ];
  }
  set(o) {
    Object.assign(this, new VehicleType());
    Object.assign(this, o);
  }
}

class DriverType {
  constructor() {
    this._id = "";
    this.Name = "";
    this.DlNumber = "";
    this.MobileNumber = "";
    this.DlExpiryDate = "";
    this.DOB = "";
    this.Country = "";
    this.State = "";
    this.City = "";
    this.Status = "";
    this.Remarks = "";
    this.isActive = "";
    this.OldDlNum = "";
    this.Comments = "";
    this.createdBy = "";
    this.updatedBy = "";
    this.verifiedBy = "";
    this.rejectedBy="";
    this.DriverDataFlag = false; 
    this.ulip = false;
    this.StateCode = "";
    this.DlExpiryDateFlag = false
    this.DlNTExpiryDateFlag = false
    this.timeline = [
      // {
      //   date: "",
      //   event: "",
      //   user: "",
      //   role: "",

      // },
    ];
    this.documents = [
      {
        name: "Driving License",
        fileName: "driving_license",
        pkey: "",
        fileLink: "",
      },
    ];
  }
  set(o) {
    Object.assign(this, new DriverType());
    Object.assign(this, o);
  }
}

class CreditFormType {
  constructor() {
    this.entityId = "";
    this.entityName = "";
    this.panNum = "";
    this.tinNum = "";
    this.gstList = [];
    this.customer={};
    this.duration = "";
    this.creditlimit = "";
    this.natureofbusiness = "";
    this.natureofcargo = "";
    this.turnover = {
      currency: "",
      value: "",
    };
    this.financialsummary = {
      International: {
        totalpotential: "",
        monthlyrevenue: ""
      },
      Domestic: {
        totalpotential: "",
        monthlyrevenue: ""
      }
    };
    this.finance = [
      {
        customercontact: "",
        department: "",
        contactnum: "",
        email: "",
        countryCode : "",
      }
    ];
    this.financeescalation = [
      {
        customercontact: "",
        department: "",
        contactnum: "",
        email: "",
        countryCode : "",
      }
    ];
    this.status = "";
    this.remarks = "";
    this.creditagency = "";
    this.creditscore = "";
    this.rejectedrole = "";
    this.overallcreditscore = "";
    this.invoicediscountavail = false;
    this.virtualaccountnumber = "";
    this.invoicediscountpartner = "";
    this.createdBy = "";
    this.createdAt = "";
    this.action = "";
    this.timeline = [
      {
        date: "",
        event: "",
        user: "",
        role: "",

      },
    ];
    this.documents = [
      {
        name: "Signed Credit Application",
        fileName: "signedcreditappication",
        pkey: "",
        fileLink: "",
      },
    ];
  }
  set(o) {
    Object.assign(this, new CreditFormType());
    Object.assign(this, o);
  }
}
class BranchType {
  constructor() {
    this._id = "";
    this.branchName = "";
    this.address = "";
    this.pincode = "";
    this.zipcode = "";
    this.country = "";
    this.branchId = "";
    this.state = "";
    this.city = "";
    this.entityId = "";
    this.advanceReceivableForDT = "";
    this.advancePayable = "";
    this.uidName = "";
    this.uidRefNo = "";
    this.isActive = true;
    this.mandatoryCheck = false;
    this.branchcountryCode = "";
    this.status = "";
    this.gst = "";
    this.remarks = ""
    this.relationshipManager = "";
    this.customerService = "";
    this.branchOperationManager = ""
    this.relationShipManagerSupply = "";
    this.scopeOfBusiness = [];
    this.timeline = []
    this.agragaBranch = "";
    this.crossBorder = {
      agragaBranch:"",
      relationshipManager: "",
      customerService: "",
      salesVertical: "",
    }
    this.domesticTransport = {
      relationshipManager: "",
      agragaBranch:"",
      customerService: "",
      branchOperationManager: "",
    }
    this.branchAddresses = [
      {
        address: "",
        city: "",
        pincode: "",
        zipcode: ""
      }
    ]
    this.documents = [
      {
        name: "Identifer Reference Number",
        fileName: "identifer_refer_number",
        pkey: "",
        fileLink: "",
      },
      {
        name: "gstCert",
        fileName: "gst",
        pkey: "",
        fileLink: "",
      },
    ];

  }
  set(o) {
    Object.assign(this, new BranchType());
    Object.assign(this, o);
  }
}

class CreditAappFormType {
  constructor() {
    this._id = "";
    this.entityName = "";
    this.panNum = "";
    this.gst = "";
    this.salesPerson = "";
    this.agragaBranch = "";
    this.duration = "";
    this.entityId = "";
    this.creditlimit = "";
    this.natureofbusiness = "";
    this.natureofcargo = "";

    this.turnover = {
      currency: "",
      value: "",
    };

    this.financialsummary = {
      International: {
        totalpotential: "",
        monthlyrevenue: "",
      },
      Domestic: {
        totalpotential: "",
        monthlyrevenue: "",
      },
    };

    this.finance = [
      {
        customercontact: "",
        department: "",
        contactnum: "",
        email: "",
      },
    ];

    this.financeescalation = [
      {
        customercontact: "",
        department: "",
        contactnum: "",
        email: "",
      },
    ];
    this.timeline = [
      {
        date: "",
        event: "",
        email: "",
        role: "",

      },
    ];

    this.gstList = [
    ]
    this.status = "Approval Pending-sales";

    this.remarks = "";
  }
  set(o) {
    Object.assign(this, new CreditAappFormType());
    Object.assign(this, o);
  }
}

class ApprovalTab {
  constructor() {
   this.userRole = ""
   this.status = ""
   this.action = ""
  }
  set(o) {
    Object.assign(this, new ApprovalTab());
    Object.assign(this, o);
  }
}

class DSRObject {
  constructor() {
    this._id = "";
      this.entityId = "";
      this.entityName = "";
      this.sendReport = false;
      this.milestones = {
        bookingInformation: [
          {
            "key": "agraga_booking_#",
            "label": "Agraga Booking Reference Number",
            "typeSelection": {
              "LCL": true,
              "AIR": true,
              "FCL_F_F": true,
              "FCL_F_CFS": true,
              "FCL_CFS_F": true,
              "FCL_CFS_CFS": true
            }
          },
          {
            "key": "cust_ref_number",
            "label": "Customer Reference Number",
            "typeSelection": {
              "LCL": true,
              "AIR": true,
              "FCL_F_F": true,
              "FCL_F_CFS": true,
              "FCL_CFS_F": true,
              "FCL_CFS_CFS": true
            }
          },
          {
            "key": "status",
            "label": "Booking Status",
            "typeSelection": {
              "LCL": true,
              "AIR": true,
              "FCL_F_F": true,
              "FCL_F_CFS": true,
              "FCL_CFS_F": true,
              "FCL_CFS_CFS": true
            }
          },
          {
            "key": "shipmentScope",
            "label": "Shipment Scope",
            "typeSelection": {
              "LCL": true,
              "AIR": true,
              "FCL_F_F": true,
              "FCL_F_CFS": true,
              "FCL_CFS_F": true,
              "FCL_CFS_CFS": true
            }
          },
          {
            "key": "shipper_name",
            "label": "Shipper Name",
            "typeSelection": {
              "LCL": true,
              "AIR": true,
              "FCL_F_F": true,
              "FCL_F_CFS": true,
              "FCL_CFS_F": true,
              "FCL_CFS_CFS": true
            }
          },
          {
            "key": "consignee_name",
            "label": "Consignee Name",
            "typeSelection": {
              "LCL": true,
              "AIR": true,
              "FCL_F_F": true,
              "FCL_F_CFS": true,
              "FCL_CFS_F": true,
              "FCL_CFS_CFS": true
            }
          },
          {
            "key": "carrier",
            "label": "Carrier name",
            "path": "contract",
            "typeSelection": {
              "LCL": true,
              "AIR": false,
              "FCL_F_F": true,
              "FCL_F_CFS": true,
              "FCL_CFS_F": true,
              "FCL_CFS_CFS": true
            }
          },
          {
            "key": "origin",
            "label": "Origin",
            "typeSelection": {
              "LCL": true,
              "AIR": true,
              "FCL_F_F": true,
              "FCL_F_CFS": true,
              "FCL_CFS_F": true,
              "FCL_CFS_CFS": true
            }
          },
          {
            "key": "destination",
            "label": "Destination",
            "typeSelection": {
              "LCL": true,
              "AIR": true,
              "FCL_F_F": true,
              "FCL_F_CFS": true,
              "FCL_CFS_F": true,
              "FCL_CFS_CFS": true
            }
          },
          {
            "key": "por",
            "label": "Place of Receipt",
            "typeSelection": {
              "LCL": true,
              "AIR": false,
              "FCL_F_F": true,
              "FCL_F_CFS": true,
              "FCL_CFS_F": true,
              "FCL_CFS_CFS": true
            }
          },
          {
            "key": "pol",
            "label": "Port of Loading",
            "typeSelection": {
              "LCL": true,
              "AIR": false,
              "FCL_F_F": true,
              "FCL_F_CFS": true,
              "FCL_CFS_F": true,
              "FCL_CFS_CFS": true
            }
          },
          {
            "key": "pod",
            "label": "Port of Discharge (POD)",
            "typeSelection": {
              "LCL": true,
              "AIR": false,
              "FCL_F_F": true,
              "FCL_F_CFS": true,
              "FCL_CFS_F": true,
              "FCL_CFS_CFS": true
            }
          },
          {
            "key": "fpod",
            "label": "Final Place of Delivery (FPOD)",
            "typeSelection": {
              "LCL": true,
              "AIR": false,
              "FCL_F_F": true,
              "FCL_F_CFS": true,
              "FCL_CFS_F": true,
              "FCL_CFS_CFS": true
            }
          },
          {
            "key": "airline",
            "label": "Airline Name",
            "path" : "contract",
            "typeSelection": {
              "LCL": false,
              "AIR": true,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "departure_airport",
            "label": "Airport of Departure",
            "typeSelection": {
              "LCL": false,
              "AIR": true,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "arrival_airport",
            "label": "Airport of Arrival",
            "typeSelection": {
              "LCL": false,
              "AIR": true,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "airline_booking_release",
            "label": "Airline Release Order",
            "typeSelection": {
              "LCL": false,
              "AIR": true,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "airline_booking_ref_number",
            "label": "Airline Booking Reference Number",
            "typeSelection": {
              "LCL": false,
              "AIR": true,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false 
            }
          },
          
        ],
        originMilestones: [
          {
            "key": "carrier",
            "label": "Carrier",
             "path": "contract",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "consolidator",
            "label": "Consolidator",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "cargo_pickup",
            "label": "Cargo Pick-up Date",
            "path" : "vorgin",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "cargo_arrival_cfs",
            "label": "Cargo Arrival at CFS",
            "path": "vorgin",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "stuffing_confirmation",
            "label": "CFS Stuffing Date",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "carrier_booking_ref_number",
            "label": "Carrier Booking Reference Number",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },  {
            "key": "carrier_booking_release",
            "label": "Carrier Release Order",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },  {
            "key": "vgm_cut_off",
            "label": "VGM Cut off Date",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },  {
            "key": "vgm_c/o_time",
            "label": "VGM Cut-off Time",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },  {
            "key": "shipping_instructions_cut_off",
            "label": "SI Cut-off Date",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          }, 
          {
            "key": "shipping_instructions_cut_off",
            "label": "SI Cut-off  Time",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "gate_cut_off",
            "label": "Gate Cut off Date",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "gate_cut_off",
            "label": "Gate Cut-off Time",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "empty_container_pickup",
            "label": "Empty Container Pick-up",
            "path": "containerlist",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "container_arrival_factory",
            "label": "Empty Container Arrival at Factory",
            "path": "containerlist",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "cargo_arrival_cfs",
            "label": "Container Arrival @ Origin CFS",
            "path": "vorgin",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "loaded_and_dispatch",
            "label": "Loaded & Dispatched",
            "path": "containerlist",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          }, 
            {
            "key": "container_number",
            "label": "Container Number(s)",
            "path": "containerlist",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "container_handover",
            "label": "Container Handover",
            "path": "containerlist",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "por_to_pol_movement",
            "label": "Railout / Truck out Status",
            "path": "containerlist",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "rail_yard_to_cfs",
            "label": "Movement from Rail Yard to POL CFS",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "destuffing_at_pol",
            "label": "De-stuffing Status @ POL",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "restuffing_at_pol",
            "label": "Re-stuffing Status @ POL",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "gatein_pol",
            "label": "Gate-in at POL",
            "path" : "containerlist", 
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "pol_container_number",
            "label": "POL Container Number",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "fl_vessel_name",
            "label": "1st Leg Vessel Name",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "fl_vessel_number",
            "label": "1st Leg Voyage Number",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "hbl_number",
            "label": "HBL Number",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "mbl_number",
            "label": "MBL Number",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "etd_at_pol",
            "label": "ETD at POL",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "sob_pol",
            "label": "Shipped-on-Board",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "arrival_dispatch_airport",
            "label": "Gate-in @ Departure Airport",
            "path": "vorgin",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "etd_airport_of_departure",
            "label": "ETD @ Departure Airport",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "loaded_on_board",
            "label": "Loaded-on-Board",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "flight_number_departure_airport",
            "label": "Flight Number from Departure Airport",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "hawb_number",
            "label": "HAWB Number",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },  
          {
            "key": "mawb_number",
            "label": "MAWB Number",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
        ],
        inTransitMilestones: [
          {
            "key": "ts1_port",
            "label": "Transhipment Port (if any)",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "ts1_airport",
            "label": "Transhipment 1 Airport (if any)",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "ts1_arrival_estimate",
            "label": "ETA Transhipment 1",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "ts1_arrival",
            "label": "ATA Transhipment 1",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "flight_number_ts1",
            "label": "Flight Number from Transhipment 1 Airport",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "ts1_departure_estimate",
            "label": "ETD Transhipment 1",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "ts1_departure",
            "label": "ATD Transhipment 1",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "ts1_vessel_name",
            "label": "2rd Leg Vessel Name",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "ts1_vessel_number",
            "label": "2rd Leg Voyage Number",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "ts2_port",
            "label": "Transhipment Port 2 (if any)",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "ts2_airport",
            "label": "Transhipment 2 Airport (if any)",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "ts2_arrival_estimate",
            "label": "ETA Transhipment 2",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "ts2_arrival",
            "label": "ATA Transhipment 2",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "ts2_departure_estimate",
            "label": "ETD Transhipment 2",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "ts2_departure",
            "label": "ATD Transhipment 2",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "reworking_pol_port",
            "label": "Re-working 1 Port(if any)",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "reworking_pol_arrival_estimate",
            "label": "ETA Re-working 1",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "reworking_pol_arrival",
            "label": "ATA Re-working 1",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "reworking_pol_destuffing",
            "label": "De-stuffing @ Re-working 1 Port",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "reworking_pol_restuffing",
            "label": "Re-stuffing @ Re-working 1 Port",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "gatein_reworking",
            "label": "Container Gate-in at Re-working Port",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "new_carrier_name",
            "label": "New Carrier Name",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "new_carrier_mbl_number",
            "label": "New Carrier MBL Number",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "reworking_pol_container_number",
            "label": "Re-working 1 Container ",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "reworking_pol_departure_estimate",
            "label": "ETD Re-working 1",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "reworking_pol_departure",
            "label": "ATD Re-working 1",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "rail_yard_to_cfs",
            "label": "Movement from port to POD CFS",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "reworking_pod_destuffing",
            "label": "De-stuffing Status @ POD",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "reworking_pod_restuffing",
            "label": "Re-stuffing Status @ POD",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "ts2_vessel_name",
            "label": "3rd Leg Vessel Name",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "ts2_vessel_number",
            "label": "3rd Leg Voyage Number",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "reworking_pod_port",
            "label": "Re-working 2 Port(if any)",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "reworking_pod_arrival_estimate",
            "label": "ETA Re-working 2",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "reworking_pod_arrival",
            "label": "ATA Re-working 2",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "reworking_pod_destuffing",
            "label": "De-stuffing @ Re-working 2 Port",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "reworking_pod_restuffing",
            "label": "Re-stuffing @ Re-working 2 Port",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          }, 
           {
            "key": "reworking_pod_container_number",
            "label": "Re-working 2 Container ",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "reworking_pod_departure_estimate",
            "label": "ETD Re-working 2",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "reworking_pod_departure",
            "label": "ATD Re-working 2",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
        ],
        destinationMilestones: [
          {
            "key": "arrival_notice",
            "label": "Arrival Notice to Consignee",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "eta_at_pod",
            "label": "ETA @ POD",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "eta_at_arrival_airport",
            "label": "ETA @ arrival airport",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "pod_arrival",
            "label": "ATA @ POD",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "ata_at_arrival_airport",
            "label": "ATA @ arrival airport",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "etd_pod",
            "label": "ETD from POD",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "gateout_to_fpod",
            "label": "ATD from POD",
            "path": "containerlist",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "eta_fpod",
            "label": "ETA @ FPOD",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "gatein_fpod",
            "label": "ATA @ FPOD",
            "path": "containerlist",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "destuffing_fpod",
            "label": "De-stuffing Status @ fpod",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "last_free_date_at_fpod",
            "label": "Last Free Date @ FPOD",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "gateout_to_fpod",
            "label": "Gate-out from POD (ATD @ POD)",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "delivery_order_release",
            "label": "Delivery Order Released to Consignee",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "cargo_picked_up_by_consignee",
            "label": "Cargo Picked-up by Consignee",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },{
            "key": "atdfrompod",
            "label": "Cargo Picked-up for Delivery",
            "path" : "vdes",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "container_pickup_fpod",
            "label": "Container Pick-up @ FPOD",
            "path": "containerlist",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "container_arrival_at_fpod",
            "label": "Container Arrival @ Destination CFS",
            "path": "containerlist",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "container_de_vanning",
            "label": "Container De-vanning",
            "path": "containerlist",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          }
          
          ,{
            "key": "estimate_delivery_date_to_consignee",
            "label": "Estimated Date of Delivery to Consignee",
            "path" : "vdes",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          }, 
           {
            "key": "actual_delivery_date",
            "label": "Actual Delivery Date (Delivered-On)",
            "path" : "vdes",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
           {
            "key": "container_delivery",
            "label": "Container Delivered to Consignee",
            "path": "containerlist",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          }, 
          {
            "key": "empty_return",
            "label": "Empty Container Return to Carrier",
            "path": "containerlist",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "remarks",
            "label": "Special Remarks",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },{
            "key": "status_closed",
            "label": "Is the Booking Closed?",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "arrival_at_pod",
            "label": "Arrival at POD",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "gatein_pod",
            "label": "Container Gate-in at POD",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "pod_to_dest_movement",
            "label": "Inland Movement (FPOD to destination)",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "pod_deconsolidation",
            "label": "Deconsolidation at POD",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "custom_clearance",
            "label": "Custom Clearance",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "release_order_obtained",
            "label": "Release Order Obtained",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "delivered_to_customer",
            "label": "Delivered to Customer",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
          {
            "key": "dispatched_from_arrival_airport",
            "label": "Cargo Picked-up for Delivery",
            "path" : "vdes",
            "typeSelection": {
              "LCL": false,
              "AIR": false,
              "FCL_F_F": false,
              "FCL_F_CFS": false,
              "FCL_CFS_F": false,
              "FCL_CFS_CFS": false
            }
          },
        ]
      } ;

    this.scheduler = {
      time: "",
      days: [],
      createdBy: "",
      createdOn: "",
      updatedBy: "",
      updatedOn: "",
    };
      this.createdBy = "";
      this.createdOn = "";
      this.updatedBy = "";
      this.updatedOn = "";
 }
  
  set(o) {
    Object.assign(this, new DSRObject());
    Object.assign(this, o);
  }
}
class COICreation{
  intialize() {
    this.riskcommencementlocation= ''
    this.riskendinglocation=""
    this.commericalInvoiceNumber=""
    this.commericalInvoiceDate=""
    this.commericalInvoiceValue=0
  
  }
  constructor(){
    this.intialize();
  }
  set(o) {
    Object.assign(this, new COICreation());
    Object.assign(this, o);
  }
}
export {
  Quotation,
  CargoDetails,
  ContainerDetails,
  QuoteCharges,
  AirQuoteCharges,
  QuoteCharges1,
  FCLQuoteCharges,
  FCLCharge,
  AguserType,
  EntityType,
  VehicleType,
  DriverType,
  BranchType,
  CreditAappFormType,
  EntityTypeV2,
  CreditFormType,
  CustomeruserType,
  ApprovalTab,
  Customer,
  ShipperConsignee,
  SchedulesPortToPort,
  DSRObject,
  COICreation
};
