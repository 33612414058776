import Utils from "../util/Utils";

class UniqueIdGenerator {
    constructor() {
      this.generatedIds = new Set();
    }
  
    generate() {
      let id;
      do {
        id = this._generateUniqueId();
      } while (this.generatedIds.has(id));
  
      this.generatedIds.add(id);
      return id;
    }
  
    _generateUniqueId() {
      // You can customize this logic as needed, e.g., using timestamp + random or other approaches
      return 'id-' + Math.random().toString(36).slice(2, 9) + Date.now().toString(36);
    }
  }

let uuid = new UniqueIdGenerator();  


export const filtersGenerationRules = [
    {
        label : ({shipmentType="FCL"}) => shipmentType.split(" ")[0].toLowerCase() === "fcl" ? "Carrier" : "Airline",
        includeFilter : ({shipmentType})=> shipmentType?.toLowerCase()!=="lcl",
        generateOptions:(records)=>{
            let options = [];
            let keysThatHaveValues = ["scac","airline"];
            records?.forEach((record)=>{
                let value = Utils.findAndReturnFirstValueMatches(record,keysThatHaveValues);
                let curRecord = {uid:uuid.generate(),...record}
                if(value){
                    let option = options.find(({title})=>title===value)
                    if(!option){
                        options.push({title:value,isChecked:false,records:[curRecord]})
                    }else{
                        option.records.push(curRecord)
                    }
                }
            })
            return options;
        },
    },
    {
        label : "POR Available",
        includeFilter : ()=>true,
        generateOptions:(records)=>{
            let options = [];
            let keysThatHaveValues = "por";
            records?.forEach((record)=>{
               if(record[keysThatHaveValues]!==record["pol"]){
                let value = Utils.isValidValue(record[keysThatHaveValues]);
                if(value){
                    value = Utils.getPortLocation(value);
                    let option = options.find(({title})=>title===value)
                    let curRecord = {uid:uuid.generate(),...record}
                    if(!option){
                        options.push({title:value,isChecked:false,records:[curRecord]})
                    }else{
                        option.records.push(curRecord)
                    }
                }
               } 
            })
            return options;
        }
    }
]