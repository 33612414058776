import { useSnapshot } from "valtio";
import { myactionsstore } from "./store-myactions";
import { configStore } from "../state/CommonState";
import { useEffect, useRef, useState } from "react";
import Select from 'react-select';
import MonApi from "../state/mongo-api";
import {SHIPMENT_TYPE, SHIPMENT_SCOPE} from '../util/constants'

    
export function ExceptionModal(props){
    useSnapshot(myactionsstore);
    useSnapshot(configStore);
    let ret = configStore.modalvisibledata;
    let status = [{ value: "Open", label: "Open" }, { value: "Closed", label: "Closed" }]
    let mileStoneArr;
    let destination = ret?.destination2 ? 'Multiple' : 'Destination'
    if(ret.shipmentType !== SHIPMENT_TYPE.air && ret.shipmentScope === SHIPMENT_SCOPE.p2p && ret.por === ret.pol){
        mileStoneArr = [
            { value: "pol_location", label: "POL" },
            // { value: "fpod_location", label: "FPOD" },
            ];
    }

    if(ret.shipmentType !== SHIPMENT_TYPE.air && ret.shipmentScope === SHIPMENT_SCOPE.p2p && ret.por !== ret.pol){
        mileStoneArr = [
            { value: "por_location", label: "POR" },
            { value: "pol_location", label: "POL" },
            { value: "pod_location", label: "POD" },
            // { value: "fpod_location", label: "FPOD" },
            ];
    }

    if(ret.shipmentType !== SHIPMENT_TYPE.air && ret.shipmentScope === SHIPMENT_SCOPE.p2d && ret.por === ret.pol){
        mileStoneArr = [
            { value: "pol_location", label: "POL" },
            { value: "fpod_location", label: "FPOD" },
            // { value: destination, label: destination }
            ];
    }

    if(ret.shipmentType !== SHIPMENT_TYPE.air && ret.shipmentScope === SHIPMENT_SCOPE.p2d  && ret.por !== ret.pol){
        mileStoneArr = [
            { value: "por_location", label: "POR" },
            { value: "pol_location", label: "POL" },
            { value: "pod_location", label: "POD" },
            { value: "fpod_location", label: "FPOD" },
            // { value: destination, label: destination }
            ];
    }

    if(ret.shipmentType !== SHIPMENT_TYPE.air && ret.shipmentScope === SHIPMENT_SCOPE.d2d && ret.por === ret.pol){
        mileStoneArr = [
            { value: "Origin", label: "Origin" },
            { value: "pol_location", label: "POL" },
            { value: "fpod_location", label: "FPOD" },
            // { value: destination, label: destination }
            ];
    }

    if(ret.shipmentType !== SHIPMENT_TYPE.air && ret.shipmentScope === SHIPMENT_SCOPE.d2d && ret.por !== ret.pol){
        mileStoneArr = [
            { value: "Origin", label: "Origin" },
            { value: "por_location", label: "POR" },
            { value: "pol_location", label: "POL" },
            { value: "pod_location", label: "POD" },
            { value: "fpod_location", label: "FPOD" },
            // { value: destination, label: destination }
            ];
    }

    if(ret.shipmentType !== SHIPMENT_TYPE.air && ret.shipmentScope === SHIPMENT_SCOPE.d2p && ret.por === ret.pol){
        mileStoneArr = [
            { value: "Origin", label: "Origin" },
            { value: "pol_location", label: "POL" },
            // { value: "fpod_location", label: "FPOD" },
            ];
    }
    if(ret.shipmentType !== SHIPMENT_TYPE.air && ret.shipmentScope === SHIPMENT_SCOPE.d2p && ret.por !== ret.pol){
        mileStoneArr = [
            { value: "Origin", label: "Origin" },
            { value: "por_location", label: "POR" },
            { value: "pol_location", label: "POL" },
            { value: "pod_location", label: "POD" },
            // { value: "fpod_location", label: "FPOD" },
            ];
    }

    if(ret.shipmentType == SHIPMENT_TYPE.air && ret.shipmentScope === SHIPMENT_SCOPE.p2p){
        mileStoneArr = [
            { value: "departure_airport_location", label: "AOD" },
            ];
    }

    if(ret.shipmentType == SHIPMENT_TYPE.air && ret.shipmentScope === SHIPMENT_SCOPE.p2d){
        mileStoneArr = [
            { value: "departure_airport_location", label: "AOD" },
            { value: "arrival_airport_location", label: "AOA" },
            ];
    }
    if(ret.shipmentType == SHIPMENT_TYPE.air && ret.shipmentScope === SHIPMENT_SCOPE.d2p){
        mileStoneArr = [
            { value: "Origin", label: "Origin" },
            { value: "departure_airport_location", label: "AOD" },
            ];
    }
    if(ret.shipmentType == SHIPMENT_TYPE.air && ret.shipmentScope === SHIPMENT_SCOPE.d2d){
        mileStoneArr = [
            { value: "Origin", label: "Origin" },
            { value: "departure_airport_location", label: "AOD" },
            { value: "arrival_airport_location", label: "AOA" },
            ];
    }

    const exception = {
        optionSelected: '',
        milestoneSelected: '',
        remarks: "",
        isActive: true,
        notifyCustomer: false,
        email: false,
    }

    const [errors, setErrors] = useState([]);
    const [formData, setFormData] = useState(ret?.exceptionData && ret?.exceptionData?.length > 0  ? ret.exceptionData : [exception]);
    const [optionSelected, setSelected] = useState(ret?.exceptionData?.exceptionlist ? ret.exceptionData.exceptionlist : []);
    const [exceptionList, setException] = useState([]);
    const defaultException = [
        "Delayed Arrival at CFS",
        "Export Clearance Issues",
        "Container Roll-over by Carrier due to Load Count Restrictions",
        "Rolled-over due to Less Inducement",
        "Import Clearance Issues",
        "Pick-up by Transporter Delayed",
        "Pending for Appointment with Amazon",
        "Pending for Appointment with Consignee",
        "Incorrect Delivery Information Provided and On-hold for Approvals"
    ];

    const fetchFields = async () => {
        try {
            const url = `${process.env.REACT_APP_API_SERVER}/api/v1/common/visibility/exceptions`;
            const response = await MonApi.apost(url, {});

            if (response && response.allFields) {
                response.allFields.map((ele) => defaultException.push(ele));
                let exceptionData = defaultException.map(item => ({value: item, label: item}))
                exceptionData = [...exceptionData, {value: "", label: "", isDisabled: true}]
                setException(exceptionData);
            }else{
                setException(defaultException.map(item => ({
                    value: item,
                    label: item
                })));
            }
        } catch (error) {
            console.error("Error fetching fields:", error);
        }
    };
    
    useEffect(() =>{
        fetchFields()
    }, [])

    const updateRow = (index, key, value) => {
        setFormData((prev) =>
          prev.map((item, i) =>
            i === index ? { ...item, [key]: key === 'isActive' ? 
            value.label === 'Closed' ? false : true : value } : item
          )
        );
    };

    function hasDuplicate(data) {
        let seen = new Set();
        return data.some(item => {
            let key = `${item.optionSelected.value}|${item.milestoneSelected.value}`;
            if (seen.has(key)) {
                return true;
            }
            seen.add(key);
            return false;
        });
    }
      
    function checkValidation() {
        let newErrors = formData.map((ele, rowIndex) => ({
            optionSelected: !ele.optionSelected,
            milestoneSelected: !ele.milestoneSelected,
            remarks: ele.remarks.trim() === "",
        }));
        setErrors(newErrors);
        if(hasDuplicate(formData)){
            configStore.setModalMessage("Same ExceptionType and Milestone Not Allowed") 
        }else if(newErrors.every((err) => !err.optionSelected && !err.milestoneSelected && !err.remarks )){
            return true
        }else{
            configStore.setModalMessage("Please Check the Fields") 
        }
    }   

    const onsubmit = () => {
        if(checkValidation()){
            let li = Object.keys(ret)
            let lvs = {}
            for (let i = 0; i < li.length; i++) {
                lvs[li[i]] = ret[li[i]]
            }
            let exceptionlistArr = [];
            optionSelected.map((ele) => exceptionlistArr.push(ele));
            lvs.exceptionData = formData;
            configStore.modalvisibledata = lvs;
            myactionsstore.exceptionModalVisible = false;
        }
        
    };


    let modalClass = 'modal'
    if (myactionsstore.exceptionModalVisible) modalClass += ' is-active'
    return(
        <>  
            { myactionsstore.exceptionModalVisible && formData && formData.length > 0  ?
                <div class={modalClass} onClick={() => { myactionsstore.exceptionModalVisible = false }}>
                    <div className="modal-background" ></div>
                    <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{overflow: 'visible', borderRadius: '10px',width:"75rem"}} >
                        <section className="p-8 modal-card-body" style={{overflow: formData.length >= 4 ? 'scroll' : 'visible', width: "70em",borderRadius:"7px",display: 'flex', 
                            flexDirection: 'column', margin: 'auto', height: formData.length >= 4 ? '425px' : ''}} >
                            <h1 class="mb-3 text-xl font-semibold">Exception and Alerts</h1>
                            {formData.map((ele, index) =>(
                                <div class='flex mt-2 mb-3' key={index+1}>
                                    <Select 
                                        options={exceptionList}
                                        isDisabled={ele.email} 
                                        onChange={(selected) => updateRow(index, "optionSelected", selected)}
                                        value={ele.optionSelected}
                                        isSelectAll={true}
                                        menuPlacement={"bottom"}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                marginRight: '10px',
                                                width: '210px',
                                                borderColor: errors[index]?.optionSelected ? "red" : provided.borderColor
                                            }),
                                        }}
                                        placeholder={'Select Excetion Type'}
                                        maxMenuHeight={200}
                                    />
                                    <Select 
                                        options={mileStoneArr}
                                        isDisabled={ele.email} 
                                        onChange={(selected) => updateRow(index, "milestoneSelected", selected)}
                                        value={ele.milestoneSelected}
                                        isSelectAll={true}
                                        menuPlacement={"bottom"}
                                        styles={{
                                        control: (provided) => ({
                                                ...provided,
                                                marginRight: '10px',
                                                width: '210px',
                                                borderColor: errors[index]?.milestoneSelected ? "red" : provided.borderColor
                                            }),
                                        }}
                                        placeholder={'Select MileStone'}
                                        maxMenuHeight={200}
                                    />
                                    <textarea placeholder="Remarks" rows='3'
                                        disabled={!ele.isActive} 
                                        style={{ 
                                            width: "230px",
                                            height: "38px",
                                            padding: "6px 10px",
                                            background: !ele.isActive ? '#f2f2f2' : '',
                                            borderColor: errors[index]?.remarks ? "red" : "#cccccc",
                                            borderRadius: "5px",
                                            fontSize: "16px",
                                            outline: "none",
                                            resize: "vertical",
                                            transition: "border 0.3s ease",
                                            color: !ele.isActive ? '#999999' : '',
                                            marginRight: '10px'
                                        }} 
                                        value={ele.remarks}
                                        maxLength={200}
                                        onChange={(e) => updateRow(index, "remarks", e.target.value)} />
                                    <Select 
                                        isDisabled={!ele.isActive && !ret.exceptionData[index]?.isActive}
                                        options={[{ value: "Open", label: "Open" }, { value: "Closed", label: "Closed", isDisabled: !ele.email}]}
                                        defaultValue={status.filter((sts) => ele.isActive ? sts.label === 'Open' : sts.label === 'Closed')}
                                        onChange={(selected) => updateRow(index, "isActive", selected)}
                                        value={status.filter((sts) => ele.isActive ? sts.label === 'Open' : sts.label === 'Closed')}
                                        isSelectAll={true}
                                        menuPlacement={"bottom"}
                                        styles={{
                                        control: (provided) => ({
                                                ...provided,
                                                marginRight: '10px',
                                                width: '110px',
                                            }),
                                        }}
                                        placeholder={'Select MileStone'}
                                        maxMenuHeight={150}
                                    />
                                    <div class="flex items-center space-x-2">
                                        <input
                                            type="checkbox"
                                            id="notifyCustomer"
                                            checked={ele.notifyCustomer}
                                            onChange={(e) => updateRow(index, "notifyCustomer", e.target.checked)}
                                            class="text-green-600"
                                        />
                                        <label htmlFor={`notifyCustomer-${index}`} className="font-medium text-[#2C358A] ml-2">Notify Customer</label>
                                    </div>
                                    <div>
                                        {
                                            formData.length - 1 === index ? 
                                            <button className="ml-3 button is-success is-inverted is-hovered is-active"
                                                onClick={() => {
                                                    setFormData((prev) => [...prev, { ...exception }])
                                                }}>
                                                <span className="icon is-small">
                                                    <i className="fas fa-plus"></i>
                                                </span>
                                            </button> : <></>
                                        }
                                        {
                                            formData.length > 1 ? 
                                            <button className="ml-3 button is-danger is-inverted is-hovered is-active"
                                                onClick={() => {
                                                    setFormData((prev) => prev.filter((_, i) => i !== index));
                                                }}>
                                                <span className="icon is-small">
                                                    <i className="fas fa-trash"></i>
                                                </span>
                                            </button> : <></>
                                        }
                                    </div>
                                </div>
                            ))}
                            <div class="my-4 is-flex is-justify-content-end">
                                <button class="mr-5 button is-warning" onClick={() => myactionsstore.exceptionModalVisible = false} >cancel</button>
                                <button class="button is-danger" onClick={onsubmit}>Submit</button>
                            </div>
                        </section>
                    </div>
                </div>
            : <></> }
        </>
    )
}