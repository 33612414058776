import Api from "./Api"
import { AgmodalStore, aguserStore, configStore, loginStore,routerStore } from "./CommonState"
const AGUSERURI = {
    LIST: `${process.env.REACT_APP_API_SERVER}/api/v1/aguser`,
    AUDITLIST: `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/get/audit`,
    GET: (id) => `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/${id}`,
    PUT: `${process.env.REACT_APP_API_SERVER}/api/v1/aguser`,
    PUTPROFILE: `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/Profile`,
    POST: `${process.env.REACT_APP_API_SERVER}/api/v1/aguser`,
    GETROLES: `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/get/roles`,
    GET4ROLE: (role) => `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/get/role/${role}`,
    GET4ROLE_NEW: (role) => `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/get/role_new/${role}`,
    UPDATESTATUS: (email, status) => `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/updatestatus/${email}/${status}`,
    SETPASSWORD: (email, password) => `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/setnewpassword/${email}/${password}`,
    TEMPUSER:`${process.env.REACT_APP_API_SERVER}/api/v1/aguser/tempregister`,
    REGISTER: `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/register`,
    NEWREGISTER: `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/newregister`,
    REGISTER2: `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/registerv2`,
    FORGOTPASSWORD: (email) => `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/forgotpassword/${email}`,
    CHECKTOKEN: (token) => `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/CheckToken/${token}`,
    FORGOTPASSWORDNEW: () => `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/forgotPassword`,
    LOGOUT: `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/logout`,
    DEVICELIST: `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/devicelist/get`,
    UPDATETOUR: `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/updatetour`,
    GETPROFILE:`${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/profile/getprofile`,
    GETOTP: () => `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/getOTP`,
    VERIFYOTP: () => `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/VerifyOTP`,
    GETSALESCB: `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/get/CBAdminSalesVertical`,
}
export default class AguserApi {
    static getUsers4role(role, callback = f => f) {
        Api.fetch(AGUSERURI.GET4ROLE(role), (data) => {
            console.log('fetched users for role', role)
            data.sort()
            const list = [{ email: '', firstName: '', lastName: '' }, ...data]
            callback(list)
        })
    }
    static getUsers4role_new(role, callback = f => f) {
        Api.fetch(AGUSERURI.GET4ROLE_NEW(role), (data) => {
            console.log('fetched users for role', role)
            data.sort()
            callback(data)
        })
    }
    static getRoles(callback = f => f) {
        Api.fetch(AGUSERURI.GETROLES, (data) => {
            console.log('fetched roles')
            callback(data)
            console.log('done calling callback for roles')
        })
    }
    static getList(callback = f => f) {
        Api.fetch(AGUSERURI.LIST, (data) => {
            aguserStore.list = data
            callback(data)
        })
    }
    static getAuditList(callback = f => f) {
        Api.fetch(AGUSERURI.AUDITLIST, (data) => {
            aguserStore.auditList = data
            callback(data)
        })
    }
    static getUser(id, callback = f => f) {
        Api.fetch(AGUSERURI.GET(id), (data) => {
            aguserStore.aguserRec = data
            callback(data)
        })
    }
    static updateUserStatus(id, status) {
        Api.fetch(AGUSERURI.UPDATESTATUS(id, status))
    }
    static setNewPassword(id, password, callback) {
        Api.fetch(AGUSERURI.SETPASSWORD(id, password), callback)
    }
    static saveUser(callback,site) {
        console.log('about to call api.put')
        Api.put(AGUSERURI.PUT, aguserStore.aguserRec, (data) => {
            if(site != "customer"){
                AgmodalStore.pageVisible = false
                configStore.setModalMessage('User Details Saved')
            } 
            console.log("the updated data is ", data);
            AgmodalStore.apiCall = true
            routerStore.agusersCurrentView ="list"
            if(callback){
                callback(data);
            }
        })
    }
    static createUser(callback = f => f) {
        console.log('about to call api.post')
        Api.post(AGUSERURI.POST, aguserStore.aguserRec, (data) => {
            callback(true)
            AgmodalStore.pageVisible = false
            AgmodalStore.apiCall = true
            routerStore.agusersCurrentView ="list"
            configStore.setModalMessage('User Details Saved')
            
        })
    }
    static getCBAdminSalesVer(callback = f => f) {
            Api.fetch(AGUSERURI.GETSALESCB, (data) => {
                callback(data.result)
            })
    }
    static registerUser(callback = f => f) {
        console.log('about to call api.post')
        let aguser = aguserStore.aguserRec
        Api.post(AGUSERURI.POST, aguserStore.aguserRec, (data) => {
            let from = `Agraga Admin <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
            let to = aguser.email
            let subject = 'User Registration'
            let message = `
                <p>Please click on <<server>> to access the applicaiton.</p>
                <p>Use the following credentials to log on and complete Registration</p>
                <table>
                <tbody>
                <tr>
                <td>User Name</td><td>${aguser.email}</td>
                </tr>
                <tr>
                <td>Password</td><td>${aguser.password}</td>
                </tr>
                </tbody>
                </table>
                <p></p><p>Team Agraga</p>`
            console.log('CALLING REGISTRATION MAIL WITH', from, to, subject, message)
            Api.sendClientMail(from, to, subject, message, 'Registration')
        })
    }

    static registerNewUserV2(reqbody,callback = f => f) {
        Api.post(AGUSERURI.REGISTER2, reqbody , (data) => {
            callback(data)
        })
    }

    static registerNewUser(callback = f => f) {
        Api.post(AGUSERURI.REGISTER, aguserStore.aguserRec, (data) => {
            callback(data)
        })
    }
    static async NEWregisterNewUser(otp,callback = f => f) {
        Api.post(AGUSERURI.NEWREGISTER, {otp,userRec:aguserStore.aguserRec}, (data) => {
            callback(data)
        })
    }
    static tempUser(callback = f => f) {
        Api.post(AGUSERURI.TEMPUSER, aguserStore.aguserRec, (data) => {
            callback(data)
        })
    }
    static registerExistingUser(aguser, callback = f => f) {
        console.log('about to call api.post')
        Api.put(AGUSERURI.PUT, aguser, (data) => {
            let from = `Agraga Admin <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
            let to = aguser.email
            let subject = 'User Registration'
            let message = `
                <p>Please click on <<server>> to access the applicaiton.</p>
                <p>Use the following credentials to log on</p>
                <table>
                <tbody>
                <tr>
                <td>User Name</td><td>${aguser.email}</td>
                </tr>
                <tr>
                <td>Password</td><td>${aguser.password}</td>
                </tr>
                </tbody>
                </table>
                <p></p><p>Team Agraga</p>`
            console.log('CALLING REGISTRATION MAIL WITH', from, to, subject, message)
            Api.sendClientMail(from, to, subject, message, 'Registration')
        })
    }
    static signUpUser(user, callback = f => f) {
        console.log('about to call api.post')
        const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const passwordLength = 12;
        let password = "";
        for (var i = 0; i <= passwordLength; i++) {
            var randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber + 1);
        }
        user.password = password
        Api.post(AGUSERURI.POST, user, (data) => {
            let u = loginStore.userRec.aguserRec
            let from = u.firstName + ' ' + u.lastName + ` <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
            let to = user.email
            let subject = 'User Invitation'
            let message = `
            <p>You have been registered by your Administrator</p>
                <p>Please click on <<server>> to access the Application.</p>
                <p>Use the following credentials to log on</p>
                <table>
                <tbody>
                <tr>
                <td>User Name</td><td>${user.email}</td>
                </tr>
                <tr>
                <td>Password</td><td>${user.password}</td>
                </tr>
                </tbody>
                </table>
                <p></p><p>Team Agraga</p>`
            console.log('CALLING REGISTRATION MAIL WITH', from, to, subject, message)
            Api.sendClientMail(from, to, subject, message, 'Registration')
        })
    }

    static resetPasswordAguser(emailId, callback) {
        const adminEmailID = `Agraga Admin <${process.env.REACT_APP_SUPPORTMAIL_ID}>`;
        const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const passwordLength = 12;
        let password = "";
        for (var i = 0; i <= passwordLength; i++) {
            var randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber + 1);
        }
        Api.fetch(AGUSERURI.SETPASSWORD(emailId, password), (response) => {
            Api.sendClientMail(adminEmailID, emailId, `Reset Password`,
                `<p>Your request for resetting password has been successfully completed. </p>
                <p>Please click on <<server>> to access the Application.</p>
                <p>Use the following credentials to log on</p>
                <table>
                <tbody>
                <tr>
                <td>User Name</td><td>${emailId}</td>
                </tr>
                <tr>
                <td>Password</td><td>${password}</td>
                </tr>
                </tbody>
                </table>
                <p></p><p>Team Agraga</p>`,
                'Reset Password Request');
            callback();
        });
    }

    static forgotPasswordAguser(emailId, callback) {
        Api.fetch(AGUSERURI.FORGOTPASSWORD(emailId), (response) => {
            callback(response)
        });
    }
    static async checkToken(token, callback) {
        // Api.fetch(AGUSERURI.CHECKTOKEN(token), (response) => {
        //     callback(response)
        // },"errr");
 
            const response = await fetch(AGUSERURI.CHECKTOKEN(token));
            const data = await response.json();
            console.log(data);
      
            if (data?.error) {
                configStore.setModalMessage(data?.error)
                setTimeout(() => {
                window.location.replace('/')                
                }, 3000);
            }else{
                callback(data)
            }     
    }

    static getAguserList() {
        return new Promise((resolve, reject) => {
            Api.fetch(AGUSERURI.LIST, (data) => {
                resolve(data);
            })
        });
    }

    static logout(data, callback = f => f) {
        Api.post(AGUSERURI.LOGOUT,data, (res) => {
            let token = localStorage.getItem("devicetoken")
            if(token == data.token){
                loginStore.reinitialize()
                localStorage.removeItem('ag_email')
                localStorage.removeItem('tour')
                localStorage.removeItem('ag_current_entity')
                localStorage.removeItem('ag_current_branch')
                localStorage.removeItem('ag_userrec')
                localStorage.removeItem('devicetoken')
                localStorage.clear()
               
            }
            callback(data)
        })
    }

    static devicelist(callback=f=>f){
        Api.fetch(AGUSERURI.DEVICELIST, (data) => {
            callback(data);
        })
    }
    static updatetour(data) {
        Api.post(AGUSERURI.UPDATETOUR,data, (res) => {
            console.log(res)
        })
    }

    static async getManagersForAgusers(role, callback = f => f) {
        const url = `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/list/managers/users`;
        const finalUrl = `${url}?role=${role}`;
        Api.fetch(finalUrl, (result) => {
            callback(result.data)
        })

    }

    static async getbranchesForTypes(aguser, callback = f => f) {
        const url = `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/list/branches/type`;
        const finalUrl = `${url}?vertical=${aguser.vertical}&division=${aguser.division}&role=${aguser.role}`;
        console.log(aguser.role,'final.');
        Api.fetch(finalUrl, (result) => {
            callback(result.data)
        })
    }
    static getProfile(data, callback) {
        Api.post(AGUSERURI.GETPROFILE, data, (res) => {
            callback(res)
            console.log(res)
        })
    }
    static async forgotPasswordAguserNew(reqbody, callback = f => f) {
        Api.post(`${process.env.REACT_APP_API_SERVER}/api/v1/aguser/forgotPassword`, reqbody, (data) => {
            callback(data)
        })
    }
    static async resetPasswordAguserNew(reqbody, callback = f => f) {
        Api.post(`${process.env.REACT_APP_API_SERVER}/api/v1/aguser/resetPassword`, reqbody, (data) => {
            callback(data)
        })
    }
    static async GetOTP(reqbody, callback = f => f) {
        Api.post(`${process.env.REACT_APP_API_SERVER}/api/v1/aguser/getOTP`, reqbody, (data) => {
            callback(data)
        })
    }
    static async GetMobileOTP(reqbody, callback = f => f) {
        Api.post(`${process.env.REACT_APP_API_SERVER}/api/v1/aguser/getMobileOTP`, reqbody, (data) => {
            callback(data)
        })
    }
    static async VerifyOTP(reqbody, callback = f => f) {
        Api.post(`${process.env.REACT_APP_API_SERVER}/api/v1/aguser/VerifyOTP`, reqbody, (data) => {
            callback(data)
        })
    }
    static async VerifyMobileOTP(reqbody, callback = f => f) {
        Api.post(`${process.env.REACT_APP_API_SERVER}/api/v1/aguser/VerifyMobileOTP`, reqbody, (data) => {
            callback(data)
        })
    }

    static async outlookVerify(reqbody, callback = f => f) {
        Api.post(`${process.env.REACT_APP_API_SERVER}/api/v1/aguser/outlookverify`, reqbody, (data) => {
            callback(data)
        })
    }
    static async saveProfileUser(callback) {
        // console.log('about to call api.put')
        Api.put(AGUSERURI.PUTPROFILE, aguserStore.aguserRec, (data) => {
            if(!loginStore.isClient()){
                AgmodalStore.pageVisible = false
                configStore.setModalMessage('User Details Saved')
            } 
            // console.log("the updated data is ", data);
            AgmodalStore.apiCall = true
            routerStore.agusersCurrentView ="list"
            if(callback){
                callback(data);
            }
        })
    }
}
