import { useEffect, useState } from "react"
import MonApi from "../state/mongo-api"
import { loader } from "../util/loader"
import { configStore } from "../state/CommonState"
import { useSnapshot } from "valtio"

export default function PowerbiConfig(props) {
    const [dashboard,setDashboard] = useState("")
    const [link,setLink] = useState("")
    const [list,setList] = useState([])
    const [dashboardlist,setDashboardList] = useState([])
    const [disabled,setDisabled] = useState(true)
    useSnapshot(configStore)
    let url = `${process.env.REACT_APP_API_SERVER}/api/v1/powerbi`
    const add = async ()=>{
        if(dashboardlist.indexOf(dashboard)==-1){
            loader.show()
            setDisabled(true)
            let d = {
                dashboard:dashboard,
                link:link
            }
            let ret = await MonApi.apost(url+'/add',d)
            if(ret && ret?.status=="success"){
                setDashboard("")
                setLink("")
                await getlist()
            }else{
                if(ret?.error){
                    configStore.setModalMessage(ret?.error)
                }
                loader.hide()
            }
        }else{
            configStore.setModalMessage("Dashboard already exists.")
        }
    }
    const getlist = async () =>{
        loader.show()
        let ret = await MonApi.aget(url)
        if(JSON.stringify(ret)!=JSON.stringify(list)){
            setList(ret)
        }
        let a = []
        ret.map(e=>{
            a.push(e.dashboard)
        })
        if(JSON.stringify(a)!=JSON.stringify(dashboardlist)){
            setDashboardList(a)
        }
        setDisabled(false)
        loader.hide()
    }
    const del = async (id) =>{
        loader.show()
        let d = {
            _id:id
        }
        let ret = await MonApi.apost(url+'/delete',d)
        if(ret && ret?.status=="success"){
            await getlist()
        }else{
            if(ret?.error){
                configStore.setModalMessage(ret?.error)
            }
            loader.hide()
        }
    }
    useEffect(()=>{
        getlist()
    },[])
    return (
        <>
            <div class="box">
                <div class="columns is-multiline is-vcentered">
                    <div class="column is-5">
                        <div class="field">
                            <label class="label">Dashboard</label>
                            <div class="control">
                                <input class="input" type="text" value={dashboard} placeholder="Dashboard" onChange={(e)=>{
                                    setDashboard(e.target.value)
                                }}/>
                            </div>
                        </div>
                    </div>
                    <div class="column is-5">
                        <div class="field">
                            <label class="label">Link</label>
                            <div class="control">
                                <input class="input" type="text" value={link} placeholder="Link" onChange={(e)=>{
                                    setLink(e.target.value)
                                }}/>
                            </div>
                        </div>
                    </div>
                    <div class="column is-2">
                        <div class="field">
                            <label class="label">&nbsp;</label>
                            <div class="control">
                                <button class="button is-primary" disabled={((dashboard.trim().length==0 || link.trim().length==0) && disabled)?true:false} onClick={add}>Add</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="box">
                <div class="columns is-multiline is-vcentered">
                    {
                        (list.length==0)?
                        <div class="column is-12">
                            No PowerBI List
                        </div>:
                        <>
                        <div class="column is-5">
                            <div class="field">
                                <label class="label">Dashboard</label>
                            </div>
                        </div>
                        <div class="column is-5">
                            <div class="field">
                                <label class="label">Link</label>
                            </div>
                        </div>
                        <div class="column is-2">
                            <div class="field">
                                <label class="label">Actions</label>
                            </div>
                        </div>
                            {
                                list.map(e=>{
                                    return <>
                                    <div class="column is-5">
                                        {e.dashboard}
                                    </div>
                                    <div class="column is-5" style={{wordBreak:"break-all"}}>
                                        {e.link}
                                    </div>
                                    <div class="column is-2">
                                        <button class="button is-danger" onClick={()=>{del(e._id)}}>Remove</button>
                                    </div>
                                    </>
                                })
                            }
                        </>
                    }
                    
                </div>
            </div>
        </>
    )
}