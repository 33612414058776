const ENTITY_STATUS = {
  INCOMPLETE: `INCOMPLETE`,
  PENDING: `PENDING`,
  VERIFIED: `VERIFIED`,
  REJECTED: `REJECTED`,
  DEACTIVATED: `DEACTIVATED`,
};

const FREGIHT_FORWARD_TYPE ={
  LCL:`LCL`,
  FCL:`FCL`,
  AIR:`AIR`
}


const SHIPMENT_TYPES_DSR ={
  LCL:`LCL`,
  FCL_F_F:`FCL (Factory to Factory)`,
  FCL_F_CFS:`FCL (Factory to CFS)`,
  FCL_CFS_F:`FCL (CFS to Factory)`,
  FCL_CFS_CFS:`FCL (CFS to CFS)`,
  AIR:`AIR`
}

const ABNORMAL_COUNTRY_NAMES = [
  {name:"Turkey", possibleCases:["Türkiye"]},
  {name:"Hong Kong", possibleCases:["Hong Kong S.A.R."]},
  {name:"Cote d'Ivoire", possibleCases:["Côte d'Ivoire", "Côte d'Ivoire (Ivory Coast)"]}
]

const STAKEHOLDER_DEFAULT_VALUES = {
  COUNTRY: "india",
  CURRENCY: "INR",
};

const ENTITY_TYPE = {
  CUSTOMER: `Customer`,
  VENDOR: `Vendor`,
};

const CUSTOMER_BILLING_CYCLE = {
  WEEKLY: `Weekly`,
  MONTHLY: `Monthly`,
};
const POD_TYPE_INVOICE = {
  SOFTCOPY: `Soft Copy`,
  HARDCOPY: `Hard Copy`,
};

const CUSTOMER_CATEGORY = {
  MSME: `MSME`,
  ENTERPRISE: `Enterprise`,
};
  // CONTRACT_MANUFACTURER: `Contract Manufacturer`,

const VENDOR_CATEGORY = {
  ROAD_TRANSPORT: "Road Transport",
  CUSTOMS_BROKER: "Customs Broker",
  OCEAN_CARRIER: "Ocean Carrier",
  AIRLINE: "Airline",
  NVOCC: "NVOCC",
  // FREIGHT_FORWARDER: "Freight Forwarder",
  LCL_CONSOLIDATOR: "LCL Consolidator",
  WAREHOUSE_OPERATOR: "Warehouse Operator",
  CONTAINER_FREIGHT_STATION:"Container Freight Station",
  INLAND_CONTAINER_DEPOT:"Inland Container Depot",
  SURVEYOR:"Surveyor",
  LASHING_AND_CHOKING:"Lashing & Choking",
  FUMIGATION:"Fumigation",
  PACKAGING:"Packaging",
  SECONGHAND_CONTAINER_VENDOR:"Second-hand Container Vendor",
};

const VENDOR_CATEGORY_NONIN = {
  ROAD_TRANSPORT: "Road Transport",
  CUSTOMS_BROKER: "Customs Broker",
  OCEAN_CARRIER: "Ocean Carrier",
  AIRLINE: "Airline",
  NVOCC: "NVOCC",
  FREIGHT_FORWARDER: "Freight Forwarder",
  LCL_CONSOLIDATOR: "LCL Consolidator",
  WAREHOUSE_OPERATOR: "Warehouse Operator",
  CONTAINER_FREIGHT_STATION:"Container Freight Station",
  INLAND_CONTAINER_DEPOT:"Inland Container Depot",
  SURVEYOR:"Surveyor",
  LASHING_AND_CHOKING:"Lashing & Choking",
  FUMIGATION:"Fumigation",
  PACKAGEING:"Packaging",
  SECONGHAND_CONTAINER_VENDOR:"Second-hand Container Vendor",

};

const OPERATOR_TYPE = {
  ROAD_TRANSPORT: "Transporter",
  CUSTOMS_BROKER: "Broker",
  OCEAN_CARRIER: "Fleet-Owner",
};

const SCOPE_ROAD_TRANSPORT = {
  LTL: "LTL",
  FTL: "FTL",
  CONTAINER_DRAYAGE: "Container Drayage",
  TRANS_LOADING: "Trans Loading",
};
const SCOPE_OF_ACTIVITY = {
  Cross_Border: "Cross Border",
  Domestic_Transport: "Domestic Transport",
};

const SCOPE_CUSTOMS_BROKER = {
  AIR_FREIGHT_EXPORT: "Air Freight(Export)",
  AIR_FREIGHT_IMPORT: "Air Freight(Import)",
  OCEAN_FREIGHT_EXPORT: "Ocean Freight(Export)",
  OCEAN_FREIGHT_IMPORT: "Ocean Freight(Import)",
};

const BRANCH_STATUS = {
  PENDING: `PENDING`,
  VERIFIED: `VERIFIED`,
  REJECTED: `REJECTED`,
};

const CREDIT_STATUS = {
  NOTAVAILED: `Not Availed`,
  INITIATED: `Initiated`,
  APPPENSALES: `Approval Pending- Sales`,
  APPPENFINANCE: `Approval Pending- Finance`,
  SIGCPYUPLOAD: `Signed Credit Application- Upload Pending`,
  SIGNED_REUPL_PEN: "Signed Credit Application- Re-Upload Pending",
  SIGCPYUPLOADED: `Signed Credit Application Uploaded`,
  REJECTED: `Rejected`,
  VERIFIED: `Verified`
};

const USERS_STATUS = {
  NEW_REG : "NEWREG",
  // ACCEPTED : "ACCEPTED",
  ACTIVE : "ACTIVE",
  INACTIVE : "INACTIVE",
  DEACTIVATE : "DEACTIVATED",

};

const SHIPMENT_SCOPE = {
  p2p: "Port-to-Port",
  p2d: "Port-to-Door",
  d2p: "Door-to-Port",
  d2d: "Door-to-Door"
}

const SHIPMENT_TYPE = {
  lcl:"LCL",
  fcl_f_f: "FCL (Factory/Factory)",
  fcl_f_cfs:"FCL (Factory/Dock)",
  fcl_cfs_f: "FCL (Dock/Factory)",
  fcl_cfs_cfs:"FCL (Dock/Dock)",
  air: "Air",
};

const VENDOR_TYPES ={
  ORIGIN_TRANSPORT:"Origin Transport",
  ORIGIN_WAREHOUSE:"Origin Warehouse",
  ORIGIN_CUSTOMS_CLEARANCE:"Origin Customs Clearance",
  ORIGIN_CFS_OPERATOR:"Origin CFS Operator Vendor",
  DESTINATION_CFS_OPERATOR:"Destination CFS Operator Vendor",
  LCL_CONSOLIDATOR:"LCL Consolidator",
  OCEAN_CARRIER:"Ocean Carrier",
  AIR_FREIGHT:"Air Freight",
  AWB_RELEASE:"AWB Release",
  BL_RELEASE:"BL Release",
  DO_RELEASE:"DO Release",
  DESTINATION_CUSTOMS_CLEARANCE:"Destination Customs Clearance",
  DESTINATION_WAREHOUSE:"Destination Warehouse",
  DESTINATION_TRANSPORT:"Destination Transport"
}

const CREDIT_APPLICATION_ACTIONS = {
  APP_PEN_SALES: "Credit Application- Approval- Sales",
  APP_PEN_FINANCE: "Credit Application- Approval- Finance",
  APPROVED_SALES: "Credit Application- Approved- Sales",
  APPROVED_FINANCE: "Credit Application- Approved- Finance",
  UPL_SGN_COPY: "Signed Credit Application Upload",
  SGN_COPY_UPLD: "Signed Credit Application Uploaded",
  UPLSGN_APP_FIN: "Signed credit Application- Approval -Finance",
  SGN_APP_FIN : "Signed Credit Application Approved- Finance ",
  SGN_APP_REJFIN : "Signed Credit Application Rejected- Finance ",
  REUPLD_SGN_APP : "Re-Upload -Signed Credit Application",
  SGN_APP_REUPLD : "Signed Credit Application Re-Uploaded ",
  VERIFIED: "Verified",
  REJ_SALES: "Credit Application- Rejected- Sales",
  REJ_FINANCE: "Credit Application- Rejected- Finance",
  VERIFIED_CREDIT: "Credit Application- Verified",
}

const HTTP_STATUS_CODES = {
  // Success
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,

  // Client Errors
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  CONFLICT: 409,
  PAYLOAD_TOO_LARGE: 413,
  UNSUPPORTED_MEDIA_TYPE: 415,
  TOO_MANY_REQUESTS: 429,

  // Server Errors
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
};

const VERTICALS = {
  CROSS_BORDER: `Cross-Border`,
  DOMESTIC_TRANSPORT: `Domestic Transport`,
  CENTRAL: `Central`,
  CB_DT:`CB-DT`
};

const STATUSFIELD = {
  'NEWREG':'NEWREG',
  'ACTIVE': 'ACTIVE',
  'INACTIVE': 'INACTIVE',
  // 'ACCEPTED': 'ACCEPTED'
}

const CUSTOMERROLES = {
  CLIENT_USER: `CLIENT USER`,
  CLIENT_ADMIN: `CLIENT ADMIN`,
  BRANCH_USER:`Branch User`,
  Client_admin:`Client Admin`
};

const DT = {
  ADMIN: `Admin`,
    BRANCH_HEAD:`Branch Head`,
    RELATIONSHIP_MANAGER_DEMAND: `Relationship Manager (Demand)`,
    CUSTOMER_SUCCESS: `Customer Success`,
    DT_OPERATIONS: `DT Operations`,
    RELATIONSHIP_MANAGER_SUPPLY: `Relationship Manager (Supply)`,
    INVOICE_LEAD: `Invoice Lead`,
    RMSUPPLY_OPERATIONSANDINVOICING: `RM (Supply),Operations & Invoicing`,
}

const CB ={
  ADMIN: `Admin`,
  BRANCH_HEAD: `Branch Head`,
  PROCUREMENT: `Procurement`,
  // PROCUREMENT_LCL: `Procurement-LCL`,
  // PROCUREMENT_FCL: `Procurement-FCL`,
  // PROCUREMENT_AIR: `Procurement-Air`,
  RELATIONSHIP_MANAGER: `Relationship Manager`,
  CUSTOMER_SUCCESS: `Customer Success`,
} 

const CB_PROCUREMENT_SUBROLES = {
  LCL_Procurement:`LCL Procurement`,
  FCL_Procurement:`FCL Procurement`,
  AIR_Procurement:`AIR Procurement`,
}

const CENTRAL_OPERATION_SUBROLES ={
  OCEAN_EXPORT_COORDINATION :`Ocean export Coordination`,
  OCEAN_IMPORT_COORDINATION:`Ocean Import Coordination`,
  AIR_EXPORT_COORDINATION:`Air export Coordination`,
  AIR_IMPORT_COORDINTAION:`Air Import Coordination`,
  OCEAN_EXPORT_DOCUMENTATION:`Ocean export Documentation`,
  OCEAN_IMPORT_DOCUMENTATION:`Ocean Import Documentation`,
  AIR_EXPORT_DOCUMENTATION:`Air export Documentation`,
  AIR_IMPORT_DOCUMENTAION:`Air Import Documentation`,
  OCEAN_VISIBILITY:`Ocean Visibility`,
  AIR_VISIBILITY:`Air Visibility`,
  DOMESTIC_TRANSPORT:`Domestic Transport`,
  VENDOR_MANAGEMENT:`Vendor Management`,
  VEHICLE_MANAGEMENT:`Vehicle Management`,
  DRIVER_MANAGEMENT:`Driver Management`
}

const CB_DT = {
  ADMIN: `Admin`,
    BRANCH_HEAD:`Branch Head`,
    RELATIONSHIP_MANAGER: `Relationship Manager`,
    CUSTOMER_SUCCESS: `Customer Success`,
}


const CENTRAL = {
  AGRAGA_ADMIN: `Agraga Admin`,
  TECHANDPRODUCT: `Tech & Product`,
  BPO: `BPO`,
  DATABASE: `Database`,
  HR: `HR`,
  FINANCE: `Finance`,
  CENTRAL_OPERATIONS: `Central Operations`,
  PRODUCT_ADOPTION:`Product Adoption`
}

const SALESVERTICAL = {
  AUTOMOTIVE: `Automotive`,
  PHARMA: `Pharma`,
  RENEWABLE_ENERGY: `Renewable Energy`,
  NEW_AGE_ENTERPRISE: `New Age Enterprise`,
  MARKET_PLACE: `Market Place`,
  MSME: `MSME`,
  ENTERPRISE: `Enterprise`,
  CONTRACT_MANUFACTURER: `Contract Manufacturer`
}

const ROLESFILTER = {
  "Domestic Transport":
    DT,
  "Cross-Border": 
    CB,
  "Central": 
   CENTRAL,
  ALL:
    {...DT, ...CB, ...CENTRAL}
  
};

const ROLES = {
  DT:
    DT,
  CB: 
    CB,
  CENTRAL: 
   CENTRAL,
   CB_DT: 
   CB_DT,
   CB_PROCUREMENT_SUBROLES:CB_PROCUREMENT_SUBROLES,
   CENTRAL_OPERATION_SUBROLES:CENTRAL_OPERATION_SUBROLES
};

const DIVISION = {
  NATIONAL: `National`,
  ZONAL: `Zonal`,
  REGIONAL: `Regional`,
  BRANCH: `Branch`,
};

const PERMISSIONS = {
  OLD: {
    NEW_QUOTATIONS: "Edit quotations",
    MYACTIONS_TYPE: "Myactions type",
    MYACTIONS_ROLE: "Myactions role",
    NEW_ENQUIRY_QUOTATIONS: "Agraga enquiry quotation"
  },  
  EDIT: {
    CB_QUOTATION_CREATE_DUMMY: "Cb quotation create dummy",
    SAVE_UPLOAD_SCHEDULES_ONLY: "Save upload schedules only",
    CONVERT_QUOTATIONS_TO_CONTRACTS: "Convert quotations to contracts",
    CB_QUOTATION_AR: "Cb quotation accept reject",
    CUSTOMER_REF_NUMBER: "Customer ref number",
    ERP_NUMBER: "Erp number",
    ENTITIES: "Entities",
    DESTINATION_DETAILS: "Destination details",
    VEHICLE_STATUS: "Vehicle status",
    VENDOR_DETAILS: "Vendor details",
    STOP_TRACKING: "Stop tracking",
    REJECTED_VEHICLES: "Rejected vehicles",
    REJECTED_DRIVERS: "Rejected drivers",
    APPROVAL_VEHICLES: "Approval vehicles",
    APPROVAL_DRIVERS: "Approval drivers",
    APPROVAL_VEHICLES_ACKNOWLEDGEMENT: "Approval vehicles acknowledgement",
    APPROVAL_DRIVERS_ACKNOWLEDGEMENT: "Approval drivers acknowledgement",
    APPROVAL_VENDOR: "Approval vendor",
    APPROVAL_RT_VENDOR: "Approval rt vendor",
    APPROVAL_CUSTOMER_ENTITY_ACKNOW: "Approval customer entity acknowledgement",
    APPROVAL_VENDOR_RT_ENTITY_ACKNOW: "Approval vendor rt entity acknowledgement",
    APPROVAL_VENDOR_ENTITY_ACKNOW: "Approval vendor entity acknowledgement",
    APPROVAL_RT_VENDOR_BRANCH: "Approval rt vendor breanch",
    ENTITIESV2_VENDOR_RTREJECTED: "Entities v2 ven rt rejected",
    BOOKING_DETAILS: "Booking details",
    VEHICLE_DETAILS: "Vehicle details",
    DRIVER_DETAILS: "Driver details",
    AGRAGA_FREIGHT_INVOICE_DETAILS: "Agraga freight invoice details",
    MYACTIONS_BUSINESS: "Myactions business",
    MYACTIONS_BUSINESS_VIEW: "Myactions business view",
    MYACTIONS_BUSINESS_TAB_VIEW: "Myactions business view",
    MYACTIONS_FINANCE: "Myactions finance",
    MYACTIONS_OPERATIONS: "Myactions operations",
    MYACTIONS_FINANCE_OPERATIONS_VIEW: "Myactions finance operations view",
    MYACTIONS_FINANCE_BUSINESS_VIEW: "Myactions finance business view",
    MY_AGRAGA_ACTIONS_OPERATIONS: "Agraga actions operations",
    MYACTIONS_CUSTOMER_FOLLOW_UP: "Customer follow up",
    CREDIT_APPROVALS: "Credit approvals",
    ENQUIRYTOQUOTATION: "Enquiry to quotation",
    CUSTOMER_USERS: "Customer users",
    REJECTED_ENTITES_V2_CUSTOMER: "Rejected entities v2 customers",
    NAV_MENU: "Nav menu",
    ENTITIES_KYCVERIFIED: "Entities kycverified",
    ENTITIES_STATUS: "Entities status",
    PENDING_CUSENTITYV2_VR: "Pending customerentityv2 verifyreject",
    PENDING_VENENTITYV2_VR: "Pending vendorentityv2 verifyreject",
    PENDING_VENENTITYV2RT_VR: "Pending vendorentityv2roadtransport verifyreject",
    VEHICLES_APPROVE_REJECT: "Vehicles approve reject",
    DRIVERS_APPROVE_REJECT: "Drivers approve reject",
    CUSTOMER_ENTITYV2: "Customer entityv2",
    VENDOR_RT_ENTITYV2: "Vendor rt entityv2",
    VENDOR_EXCEPTRT_ENTITYV2: "Vendor exceptrt entityv2",
    UPLOADS_FILE: "Uploads file",
    EDIT_VISIBILITY: "Edit visibility",
    EDIT_UPLOADS: "Edit uploads",
    ENTITIES_VIEW: "Entities view",
    ENTITIESV2_VIEW: "Entitiesv2 view",
    ENTITIESV2_RT_VIEW: "Entitiesv2 roadtransport view",
    ENTITIESV2_VENDOR_VIEW: "Entitiesv2 vendor view",
    ENTITIES_VERIFIED: "Entities verified",
    APPROVALS_FINANCE: "Approvals finance",
    APPROVALS_SALES: "Approvals sales",
    APPROVALS_OPERATIONS: "Approvals operations",
    APPROVALS_SALES_API: "Approvals sales api",
    APPROVALS_OPERATIONS_API: "Approvals operations api",
    AGRAGA_USER: "Agraga user",
    BOOKING_ACTION: "Booking action",
    BOOKING_VENDOR: "Booking vendor",
    CB_CUSTOMER_REF_NUMBER: "Cb customer reference number",
    CB_ERP_SHIPMENT_NUMBER: "Cb erp shipment number",
    PROCUREMENT_ONLY_SUBMIT: "Procurement only submit",
    AGRAGA_ENQUIRY_BUTTON: "Agraga enquiry button",
    Razor_Pay: "Razor pay",
    GENERATE_ENTITIES: "Generate entities",
    GENERATE_ENTITIES_VENDOR_RT: "Generate entities vendor rt",
    GENERATE_DRIVERS: "Generate drivers",
    GENERATE_VEHICLES: "Generate vehicles",
    GENERATE_USERS: "Generate users",
    GENERATE_USERS_AGRAGA_ONLY: "Generate users agraga only",
    GENERATE_USERS_CUSTOMER_ONLY:'Generate_Users_Customer_Only',
    GENERATE_DSR_MY_ACTION_REPORT: "Generate dsr my action report",
    GENERATE_DT_REPORTS: "Generate dt reports",
    GENERATE_REAL_TIME_PRICING: "Generate real time pricing",
    GENERATE_DSR_NOT_UPDATED: "Generate dsr not updated",
    GENERATE_ENQUIRY: "Generate enquiry",
    GENERATE_MY_ACTIONS: "Generate my actions",
    GENERATE_QUOTATION_REPORT: "Generate quotation report",
    GENERATE_SALES_REPORT: "Generate sales report",
    DEACTIVATE_CUSTOMER_USER: "Deactivate customer user",
    CUSTOMER_ENTITIES: "Customer entities",
    ALL_VENDOR_ENTITIES: "All vendor entities",
    RT_VENDOR_ENTITIES: "Rt vendor entities",
    CREDIT_APPLICATION: "Credit application",
    DRIVER_APPLICATION: "Driver application",
    VEHICLE_APPLICATION: "Vehicle application",
    USER_APPLICATION: "User application"
  },
  CREATE: {
    MANAGE_CREDIT: "Manage credit",
    SERVICEABILITY: "Serviceability",
    MANAGE_USERS: "Manage users",
    NEW_BRANCH_CUSTOMER: "New branch customer",
    NEW_BRANCH_VENDOR: "New branch vendor",
    NEW_BRANCH_VENDOR_RT_ONLY: "New branch vendor rt only",
    ALL_ENTITIES: "All entities",
    NEW_VEHICLES: "New vehicles",
    NEW_DRIVERS: "New drivers",
    NEW_ENTITYV2: "New entityv2",
    NEW_ENTITYV2_ROADTRANSPORT: "New entityv2 roadtransport",
    NEW_ENTITYV2_ROADTRANSPORT_ONLY: "New entityv2 roadtransport only",
    NEW_ENTITYV2_REMVENDOR: "New entityv2 remainingvendor",
    INITIATE_ADVANCE: "Initiate advance",
    INITIATE_BALANCE_PAYMENT: "Initiate balance payment",
    NEW_ENTITY: "New entity",
    NEW_CUSTOMER_USER: "New customer user",
    NEW_ENTITYV2_CUSTOMERS: "New entityv2 customer",
    NEW_ENTITYV2_CUSTOMERS_CB_DT: "New entityv2 customer",
    BOOKING: "Booking",
    DTBOOKING: "Dt booking",
    NEW_AGRAGAUSER: "New agragauser",
    NEW_QUOTATIONS: "New quotation",
    NEW_USER: "New user",
    NEW_SHIPPER: "New shipper",
    UPDATE_SHIPPER: "Update shipper",
    LOGIN_REPORT: "Login report"
  },  
  NAV_MENU: {
    REMAINING_ANALYTICS: "Remaining analytics",
    ALL_ACCESS: "All acsess",
    CROSS_BORDER: "Cross border",
    DOMESTIC_TRANSPORT: "Domestic transport",
    ANALYTICS: "Analytics",
    ANALYTICS_FCL_PRICING: "Analytics fcl pricing",
    APPROVALS: "Approvals",
    ENQUERIES_RECEIVED: "Enquiries received",
    QUOTATIONS_CB: "Quotations cb",
    QUOTATIONS_DT: "Quotations dt",
    SPOT_BOOKING_LCL: "Spot booking lcl",
    UPLOAD_SCHEDULES: "Upload schedules",
    BOOKINGS_CB: "Bookings cb",
    BOOKINGS_DT: "Bookings dt",
    VISIBILITY: "Visibility",
    MY_ACTIONS_CB: "My actions cb",
    MY_ACTIONS_DT: "My actions dt",
    OPERATIONS_CB: "Opeartions cb",
    SALES_CB: "Sales cb",
    CONFIG: "Config",
    UPLOADS: "Uploads",
    ENTITIES: "Entities",
    ENTITIES_V2: "Entities v2",
    VEHICLES: "Vehicles",
    DRIVERS: "Drivers",
    USERS: "Users",
    CUSTOMERS: "Customers",
    REPORTS: "Reports",
    ACCESS_CONTROL: "Access control",
    AUDIT: "Audit",
    SITE_INFORMATION: "Site information",
    HOME: "Home"
  }  
};

const APP_VERSION = "v1.8"
const DEFAULT_AGRAGA_BRANCH = "Chennai"

export {
  ABNORMAL_COUNTRY_NAMES,
  ENTITY_STATUS,
  ENTITY_TYPE,
  CUSTOMER_CATEGORY,
  VENDOR_CATEGORY,
  VENDOR_CATEGORY_NONIN,
  OPERATOR_TYPE,
  SCOPE_ROAD_TRANSPORT,
  SCOPE_CUSTOMS_BROKER,
  BRANCH_STATUS,
  STAKEHOLDER_DEFAULT_VALUES,
  HTTP_STATUS_CODES,
  FREGIHT_FORWARD_TYPE,
  VERTICALS,
  ROLES,
  DIVISION,
  CUSTOMERROLES,
  PERMISSIONS,
  CREDIT_STATUS,
  CREDIT_APPLICATION_ACTIONS,
  SCOPE_OF_ACTIVITY,
  STATUSFIELD,
  ROLESFILTER,
  USERS_STATUS,
  CUSTOMER_BILLING_CYCLE,
  POD_TYPE_INVOICE, 
  APP_VERSION,
  SALESVERTICAL,
  SHIPMENT_TYPES_DSR,
  DEFAULT_AGRAGA_BRANCH,
  SHIPMENT_SCOPE,
  SHIPMENT_TYPE,
  VENDOR_TYPES
};
