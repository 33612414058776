import { useSnapshot } from "valtio"
import {
    quoteDataRec, airChargesStore
} from '../state/CommonState'
import Utils from "../util/Utils"
import { configStore } from "../state/CommonState"
import { TransportTable1 } from "../enquiry/LCLChargeClientView"
import { Card } from "../v3-componets/AgCard"
import { Headspan } from "../v3-componets/AgHeadspan"
import pepiconspopperson from "../assets/images/pepicons-pop_person.svg";
import pepiconspopperson1 from "../assets/images/pepicons-pop_person1.svg";

export default function AirChargesViewv3 (props) {

    let imChargesStore = useSnapshot(airChargesStore)
    let key = "list"
    if(props.keylist!=undefined){
        key = props.keylist
    }
    console.log('Inside ChargesView render=', imChargesStore.list.length)
    console.log('Full list = ', JSON.stringify(airChargesStore[key]))
    const Tn = ({ i, name, list }) => {
        const e = list[i]
        if (e.disabled && e.disabled.indexOf(name) >= 0)
            return (
                <div style={{color:'#dddddd',  border: 'none' }} >.</div>
            )
        else {
            console.log(name, e.currency, e[name])
            let num = e[name]
            num = isNaN(num)?0.0:Number(num)
            return num>0?<span>{Utils.N(num, e.currency)}</span>:<></>
        }
    }
    const ifNotFCL = () => quoteDataRec.shipmentType.indexOf('FCL') < 0
    const displayRec = (e) => {
        const perKg = parseFloat(e.perKg)
        const perAWB = parseFloat(e.perAWB)
        const total = parseFloat(e.total)
        console.log(perKg, perAWB, total)
        if (perKg != 0 || perAWB != 0 || total != 0) return true
        return false
    }
    if (!props.data) {
        let listo = []
        let listc = []
        let lists = []
        for (let i = 0; i < airChargesStore[key].length; i++) {
            let e = airChargesStore[key][i]
            e = { ...e }
            if (e.onaccount && e.onaccount.startsWith('Cons')) listc.push(e)
            else if (e.onaccount && e.onaccount.startsWith('Ship')) lists.push(e)
            else listo.push(e)
        }
        return <>
        {
            (listo.length>0)?<AirChargesViewv3 data={listo} chargeType={'On Your Account'+(props.heading!=undefined && props.heading.length>0?" - "+props.heading:"")} isMailBody={props.isMailBody}/>:""
        }
        {
            (listc.length>0)?<AirChargesViewv3 data={listc} chargeType={'On Consignee Account'+(props.heading!=undefined && props.heading.length>0?" - "+props.heading:"")} isMailBody={props.isMailBody}/>:""
        }
        {
            (lists.length>0)?<AirChargesViewv3 data={lists} chargeType={'On Shipper Account'+(props.heading!=undefined && props.heading.length>0?" - "+props.heading:"")} isMailBody={props.isMailBody}/>:""
        }
        </>
    }
    let counter = 1
    let finalTotal = 0.0
    props.data.map((e) => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        finalTotal += e.total * erate
    })
    let checkValue = false;
    props.data.forEach(element => {
        if((+element.perKg) !== 0 || (+element.minimum) !== 0 || (+element.perAWB) !== 0 || (+element.total) !== 0){
            checkValue = true;
        }
    });
    return (
        <>
            {
                checkValue ? 
                <>
                <div style={{marginTop:"10px"}}>
                                <Card>
                                    {!props.isMailBody?<span><img src={props.chargeType=="On Your Account"?pepiconspopperson:pepiconspopperson1}/></span>:<></>}<Headspan color="#00000" fontSize= "14px" fontWeight="900" data={" "+props.chargeType}/>
                                    <div className="table-container">
                                    <table style={{border:"none", width:"100%"}}>
                    <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colSpan='2' style={{border:"solid 0.5px #4BA7DD", backgroundColor:"#4BA7DD", color:"#fff",fontWeight:"900", padding:"10px", textAlign:"center"}} className="has-text-centered">Per Unit</th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr style={{ fontWeight:"900",border:"solid 0.5px #D4D4D4"}}>
                                <th style={{color:"#2C358A", padding:"10px"}}>ID</th>
                                <th style={{color:"#2C358A", padding:"10px",width: '20rem' }}>Charge Description</th>
                                <th style={{color:"#2C358A", padding:"10px"}}>Currency</th>
                                <th style={{color:"#2C358A", padding:"10px", textAlign: 'center' }}>Per Kg</th>
                                <th style={{color:"#2C358A", padding:"10px", textAlign: 'center' }}>Minimum</th>
                                <th style={{color:"#2C358A", padding:"10px", textAlign: 'center' }}>Per AWB</th>
                                <th style={{color:"#2C358A", padding:"10px", textAlign: 'right' }}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.data.map((e, i) => {
                                    {
                                        return (displayRec(e)) ? 
                                        <tr key={e.id} style={{border:"solid 0.5px #D4D4D4", backgroundColor:(i%2)==1?"#F3F3F3":"none"}}>
                                            <td style={{color:"#555555", padding:"10px"}}>{counter++}</td>
                                            <td style={{color:"#555555", padding:"10px"}}>{
                                                (e.transportRec) ?
                                                    <div className="transporttooltip">{e.chargeDescription}
                                                        <span className="transporttooltiptext"><TransportTable1 data={e.transportRec} /> </span>
                                                    </div>
                                                    :
                                                    e.chargeDescription
                                            }</td>
                                            <td style={{color:"#555555", padding:"10px"}}>{e.currency}</td>
                                            <td style={{color:"#555555", padding:"10px", textAlign: 'center' }}><Tn i={i} name='perKg' list={props.data} /></td>
                                            <td style={{color:"#555555", padding:"10px", textAlign: 'center' }}><Tn i={i} name='minimum' list={props.data} /></td>
                                            <td style={{color:"#555555", padding:"10px", textAlign: 'center' }}><Tn i={i} name='perAWB' list={props.data}/></td>
                                            <td style={{ color:"#555555", padding:"10px",textAlign: 'right' }}>{Utils.N(e.total, e.currency)}</td>
                                        </tr> : <></>
                                    }
                                }
                                )
                            }
                            <tr style={{border:"solid 0.5px #D4D4D4"}}>
                                <td colSpan={6}></td>
                                <td style={{ color:"#2C358A", padding:"10px", textAlign: 'right', fontSize: '1.1rem', fontWeight:"900"}}>{Utils.N(finalTotal)}</td>
                            </tr>
                            <tr>
                                <td colSpan={7} style={{textAlign: 'right'}}>Taxes & Duties additional, as applicable</td>
                            </tr>

                        </tbody>
                    </table>
                    </div>
                                </Card>
                                </div>
                </> : <></>
            }
        </>
    )
}
