import { useState } from "react"
import { useSnapshot } from "valtio"
import { AgSelect } from "../components/AgComponents"
import AgFile from "../components/AgFile"
import { configStore, loginStore } from "../state/CommonState"
import { myactionsstore } from "../my-actions/store-myactions"
import AgLastUpload from "../components/AgLastUpload"
import Api from "../state/Api"
import RoleUtils from "../util/RoleUtils"
import { PERMISSIONS } from "../util/constants"

export default function Accounts() {
    const store = useSnapshot(myactionsstore)
    useSnapshot(loginStore)
    const [data, setData] = useState({})
    const [checkstatus, setcheckstatus] = useState(false)
    const [isUserAuthorized, setIsUserAuthorized] = useState(false)
    const [update, setUpdate] = useState([])

    function check(){
        const checkUser = process.env.REACT_APP_ERP_UPLOAD && JSON.parse(process.env.REACT_APP_ERP_UPLOAD).includes(loginStore?.email)
        setIsUserAuthorized(checkUser);
        setcheckstatus(true)
        Api.getlastupload("finance",setUpdate)
    }
    if(!checkstatus){
        check()
    }
    let errors = data.validationErrors
    return (
        <div>
            <table className="table is-bordered is-striped is-fullwidth is-hoverable ">
                <tbody>
                    {/* <tr id="ta1">
                        <td>
                            <div className="title is-6">Accounts Receivables</div>
                        </td>
                        {(RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.UPLOADS_FILE)) &&
                        <td >
                            <AgFile source='entity-upload' cat='direct' parent="ta1"
                                filetype='finance'
                                callback={(filename) => {
                                    configStore.setModalMessage('Dashboard finance upload successful.')
                                }} />
                        </td>  }
                        <td><a  style={{fontSize:"12px",textDecoration:"underline"}} className="is-link" href="financials.csv" download="financials.csv">Download financials Template</a></td>
                    </tr> */}
                    <tr id="ta1">
                        <td>
                            <div className="title is-6">ERP Reports Upload</div>
                        </td>
                        {(RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.UPLOADS_FILE)) &&
                        <td >
                            <AgFile source='entity-upload' cat='direct' parent="ta1"
                                filetype='erpreport'
                                callback={(filename) => {
                                    configStore.setModalMessage('Dashboard finance upload successful.')
                                }} />
                        </td>  }
                        {/* <td><a  style={{fontSize:"12px",textDecoration:"underline"}} className="is-link" href="financials.csv" download="financials.csv">Download financials Template</a></td> */}
                    </tr>
                    {(isUserAuthorized) && ( 
                        <>
                    <tr id="ta1">
                        <td>
                            <div className="title is-6">Financial dashboard Users Permissions Upload</div>
                        </td>
                        
                        <td >
                            <AgFile source='entity-upload' cat='direct' parent="ta1"
                                filetype='financialDashPerm'
                                callback={(filename) => {
                                    configStore.setModalMessage('Dashboard finance dashboard permissions upload successful.')
                                }} />
                        </td>  
                        {/* <td><a  style={{fontSize:"12px",textDecoration:"underline"}} className="is-link" href="financials.csv" download="financials.csv">Download financials Template</a></td> */}
                    </tr>
                            <tr id="ta2">
                                <td>
                                    <div className="title is-6">RM CS Update Upload</div>
                                </td>
                                <td >
                                    <AgFile source='entity-upload' cat='direct' parent="ta1"
                                        filetype='rmCSUpdate'
                                        callback={(filename) => {
                                            configStore.setModalMessage('RM CS upload successful.')
                                        }} />
                                </td>
                            </tr>
                 </>)}
                    {/* {
                        (update.length>0)?<tr>
                        <td colSpan={2}>
                            <AgLastUpload data={update[0]}/>
                        </td>
                    </tr>:<></>
                    } */}
                </tbody>
            </table>
        </div>
    )
}