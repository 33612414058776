import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import BookingsApi from "../state/bookings-api";
import { bookingsStore, configStore } from "../state/CommonState";
import submittedLogo from './status.booked.png'
import inprogressLogo from './status.ip.png'
import archivedLogo from './status.archived.png'
import percent95 from './95.percent.png'
import { Chart } from "react-google-charts"
import PercentChart from "./chart.percent";
import Agpercent from "./agpercent";
import ChartApi from "./chart.api";

export default function ChartBookings
    (props) {
    useSnapshot(bookingsStore)
    useSnapshot(configStore)
    const chartEntities = props?.chartSelectedEntity ?? []
    const [results, setResults] = useState({})
    let gaugeData = [
        ["Label", "Value"],
        ["Shipments", 95]
    ]
    const guageOptions = {
        width: 90,
        height: 90,
        minorTicks: 1,
        redFrom: 0,
        redTo: 30,
        yellowFrom: 30,
        yellowTo: 70,
        greenFrom: 70,
        greenTo: 100
    }

    const summarize = (data) => {
        let val = {
            'SUBMITTED': 0,
            'INPROGRESS': 0,
            'ARCHIVED': 0,
            onTime: -1
        }
        let totalEstTransit = 0
        let totalTransitDeviation = 0
        if(data && data.length>0){
            data.map(e => {
                val[e.status] += 1
                if (e.estTransit) totalEstTransit += e.estTransit
                if (e.transitDeviation) totalTransitDeviation += e.transitDeviation
            })
        }
        if (totalEstTransit) {
            val.onTime = 1 - (totalTransitDeviation / totalEstTransit)
            val.onTime = Math.round(val.onTime)
        }
        setResults(val)
    }
    useEffect(() => {
        ChartApi.getAllBookingsForChart(summarize, chartEntities)
    }, [chartEntities])
    let styleName = 'column is-3'
    if (results.onTime < 0) styleName = 'column is-4'
    return (
        <>
            <div className="columns" style={{ textAlign: 'center', alignItems: 'center' }}>
                <div className={styleName} >
                    <div style={{ fontSize: '2rem', fontWeight: 'bold', color: '#2c358a', marginTop: '0px' }}>
                        <a style={{ color: '#2c358a' }} href={`${window.location.href}bookingsv2/submitted`}
                            target="_blank" onClick={(e) => {
                                e.stopPropagation();
                            }}>{results['SUBMITTED']}
                        </a>
                    </div>
                    <div style={{ fontSize: '1.3rem', fontWeight: 'bold', color: '#2c358a' }}>Booking Requested</div>
                </div>
                <div className={styleName} >
                    <div style={{ fontSize: '2rem', fontWeight: 'bold', color: '#2c358a', marginTop: '0px' }}>
                        <a style={{ color: '#2c358a' }} href={`${window.location.href}visibility`}
                            target="_blank" onClick={(e) => {
                                e.stopPropagation();
                            }}>{results['INPROGRESS']}
                        </a>
                    </div>
                    <div style={{ fontSize: '1.3rem', fontWeight: 'bold', color: '#2c358a', whiteSpace: 'nowrap' }}>In-Progress</div>
                </div>
                <div className={styleName} >
                    <div style={{ fontSize: '2rem', fontWeight: 'bold', color: '#2c358a', marginTop: '0px' }}>
                        <a style={{ color: '#2c358a' }} href={`${window.location.href}bookingsv2/archived`}
                            target="_blank" onClick={(e) => {
                                e.stopPropagation();
                            }}>{results['ARCHIVED']}
                        </a>
                    </div>
                    <div style={{ fontSize: '1.3rem', fontWeight: 'bold', color: '#2c358a', whiteSpace: 'nowrap' }}>Completed</div>
                </div>
                {
                    (results.onTime > 0) ?
                        <div className="column is-3">
                            <div style={{ marginTop: '-30px' }}>
                                <Agpercent val={results.onTime} />
                            </div>
                            <div style={{ fontSize: '1.3rem', fontWeight: 'bold', color: '#2c358a', whiteSpace: 'nowrap' }}>On-Time</div>
                        </div>
                        : <></>
                }
            </div>

        </>
    )
}