import cancel from "../assets/images/CancelIcon1.svg";
export function CustomPopup({ isOpen, onClose, title, children,width}){
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
      <div className={`bg-white rounded-lg shadow-lg ${width? `w-[${width}]` : "max-w-1/2" }`}>
        <div className="flex justify-between items-center p-4 border-b-[2px] border-b-blue-dark">
          <h3 className="text-lg font-semibold">{title}</h3>
          {/* <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            close   
          </button> */}
          <div onClick={onClose} className='flex items-center justify-center h-[30px] w-[30px] rounded-[50%] bg-blue-dark cursor-pointer'>
               <img src={cancel} />
          </div>
        </div>
        <div className="bg-blue-dark h-[2px] w-full"></div>
        <div className="p-4">
          {children}
        </div>
      </div>
    </div>
  );
};

