import { bookingsStore, configStore, contractsStore, loginStore } from '../state/CommonState'
import dash from './Dashboard.jpg'
import { useGlobalState } from '../GlobalState'
import Api from '../state/Api'
import { useEffect } from 'react'
import EntityApi from '../state/entity-api'
import MonApi from '../state/mongo-api'
import { useNavigate } from 'react-router-dom'
import QuoteMain from '../QuoteMain'
import HomeMain from '../enquiry/home-main'
import BookingsApi from '../state/bookings-api'
import { enquiryStore } from '../enquiry/enquiry-data'
import { myactionsstore } from '../my-actions/store-myactions'
import Newapi from '../state/new-api'
import { ROLES, VERTICALS } from "../util/constants"

export default function Dashboard(props) {
    const { changeCurrentMenu } = useGlobalState()
    let navigate = useNavigate()

    const getExchangerates = async( ) =>{
      await Api.getExchangerates()
    }

    useEffect(() => {
        EntityApi.getList()
    
        if (configStore.currentURL.startsWith("/quote/book")) {
            getExchangerates()

            const contractNum = configStore.currentURL.split('/')[3]
            changeCurrentMenu('Create Booking')
            Api.getContracts()
            Api.getContract(contractNum)
            Api.createContractFromQuote(contractNum)
            configStore.quoteClientCurrentView = 'view'
            configStore.scheduleOrCarrierform = 'schedule'
            configStore.currentURL = '/'
            navigate('/', { replace: true })
            return;
        }
        if (configStore.currentURL.split('/').length > 0) {
            switch (configStore.currentURL.toString().toLowerCase().split("/").at(1)) {
                case 'myactions':
                    changeCurrentMenu('My Actions');
                    navigate('/', { replace: true })
                    return;
            }
        }
        if (configStore.currentURL.split('/').length > 0) {
            switch (configStore.currentURL.toString().toLowerCase().split("/").at(1)) {
                case 'bookings':
                    let bookingTab = configStore.currentURL.toString().toLowerCase().split("/").at(2)
                    bookingsStore.listActiveTab = 'Submitted Bookings'
                    if (bookingTab && bookingTab === 'inprogress') bookingsStore.listActiveTab = 'Shipments In-Progress'
                    if (bookingTab && bookingTab === 'archived') bookingsStore.listActiveTab = 'Archives'
                    if (!loginStore.isClient()) changeCurrentMenu('Bookings')
                    else changeCurrentMenu('My Bookings')
                    navigate('/', { replace: true })
                    return;
                case 'bookingsv2':
                    let bookingTabv2 = configStore.currentURL.toString().toLowerCase().split("/").at(2)
                    bookingsStore.listActiveTab = 'Submitted Bookings'
                    if (bookingTabv2 && bookingTabv2 === 'inprogress') bookingsStore.listActiveTab = 'Shipments In-Progress'
                    if (bookingTabv2 && bookingTabv2 === 'archived') bookingsStore.listActiveTab = 'Archives'
                    if (!loginStore.isClient()) changeCurrentMenu('Bookingsv3')
                    else changeCurrentMenu('Bookingsv3')
                    navigate('/', { replace: true })
                    return;
                case 'visibility':
                    let visibilityMenu = 'Visibility'
                    if (loginStore.isClient()) visibilityMenu = 'Visibility'
                    myactionsstore.navigateFromCharts = true
                    changeCurrentMenu(visibilityMenu);
                    navigate('/', { replace: true })
                    return;
                case 'toexpirecontracts':
                    Newapi.getToExpireContracts()
                    let contractsmenu = 'Upload Schedulesv3'
                    if (loginStore.isClient()) contractsmenu = 'Create Bookingv3'
                    changeCurrentMenu(contractsmenu);
                    navigate('/', { replace: true })
                    return;
                case 'quotation':
                    changeCurrentMenu("Quotationv3");
                    navigate('/', { replace: true })
                    return;
                case 'approvals':
                    changeCurrentMenu("Approvals");
                    navigate('/', { replace: true })
                    return
            }
        }
        if (!loginStore.isClient() && configStore.currentURL.split('/').length > 0) {
            switch (configStore.currentURL.toString().toLowerCase().split("/").at(1)) {
                /*case 'bookings':
                    let bookingTab = configStore.currentURL.toString().toLowerCase().split("/").at(2)
                    bookingsStore.listActiveTab = 'Submitted Bookings'  
                    if (bookingTab && bookingTab === 'inprogress') bookingsStore.listActiveTab = 'Shipments In-Progress'
                    if (bookingTab && bookingTab === 'archived') bookingsStore.listActiveTab = 'Archives'
                    changeCurrentMenu('Bookings');
                    navigate('/', {replace: true})
                    return ;*/
                case 'contracts':
                    changeCurrentMenu('Upload Schedules');
                    navigate('/', { replace: true })
                    return;
                case 'contractsv2':
                    changeCurrentMenu('Upload Schedulesv3');
                    navigate('/', { replace: true })
                    return;
                default:
                    const user = loginStore.userRec.aguserRec
                    switch (true) {
                        case user?.role.indexOf(ROLES.CENTRAL.AGRAGA_ADMIN) != -1:
                          changeCurrentMenu("Spot Booking WIP")
                          break
                        case user?.role.indexOf(ROLES.CENTRAL.TECHANDPRODUCT) != -1:
                          changeCurrentMenu("Spot Booking WIP")
                          break
                        case user?.role.indexOf(ROLES.CENTRAL.PRODUCT_ADOPTION) != -1:
                          changeCurrentMenu("Spot Booking WIP")
                          break
                        case user?.role.indexOf(ROLES.CENTRAL.DATABASE) != -1:
                          changeCurrentMenu("Spot Booking WIP")
                          break
                        case user?.role.indexOf(ROLES.CENTRAL.BPO) != -1:
                          changeCurrentMenu("Upload Schedulesv3")
                          break
                        case user?.role.indexOf(ROLES.CENTRAL.HR) != -1:
                          changeCurrentMenu("Users")
                          break
                        case user?.role.indexOf(ROLES.CENTRAL.FINANCE) != -1:
                          changeCurrentMenu("Spot Booking WIP")
                          break
                        case user?.role.indexOf(ROLES.CENTRAL.CENTRAL_OPERATIONS) != -1:
                          changeCurrentMenu("Spot Booking WIP")
                          break
                        case user?.role.indexOf(ROLES.DT.ADMIN) != -1:
                          if (user.vertical === VERTICALS.DOMESTIC_TRANSPORT) {
                            changeCurrentMenu("Domestic Quotations")
                          } else if (user.vertical === VERTICALS.CROSS_BORDER) {
                            changeCurrentMenu("Spot Booking WIP")
                          } else if (user.vertical === VERTICALS.CB_DT) {
                            changeCurrentMenu("Spot Booking WIP")
                          }
                          break
                        case user?.role.indexOf(ROLES.DT.BRANCH_HEAD) != -1:
                          if (user.vertical === VERTICALS.DOMESTIC_TRANSPORT) {
                            changeCurrentMenu("Domestic Quotations")
                          } else if (user.vertical === VERTICALS.CROSS_BORDER) {
                            changeCurrentMenu("Spot Booking WIP")
                          } else if (user.vertical === VERTICALS.CB_DT) {
                            changeCurrentMenu("Spot Booking WIP")
                          }
                          break
                        case user?.role.indexOf(ROLES.DT.RELATIONSHIP_MANAGER_DEMAND) != -1:
                          changeCurrentMenu("Domestic Quotations")
                          break
                        case user?.role.indexOf(ROLES.DT.CUSTOMER_SUCCESS) != -1:
                          if (user.vertical === VERTICALS.DOMESTIC_TRANSPORT) {
                            changeCurrentMenu("Domestic Quotations")
                          } else if (user.vertical === VERTICALS.CROSS_BORDER) {
                            changeCurrentMenu("Spot Booking WIP")
                          } else if(user.vertical === VERTICALS.CB_DT){
                            changeCurrentMenu("Spot Booking WIP")
                          }
                          break
                        case user?.role.indexOf(ROLES.CB_DT.RELATIONSHIP_MANAGER) != -1:
                          if (user.vertical === VERTICALS.CROSS_BORDER) {
                            changeCurrentMenu("Spot Booking WIP")
                          } else if (user.vertical === VERTICALS.CB_DT) {
                            changeCurrentMenu("Spot Booking WIP")
                          }
                          break
                        case user?.role.indexOf(ROLES.DT.DT_OPERATIONS) != -1:
                          changeCurrentMenu("Domestic Transport Booking")
                          break
                        case user?.role.indexOf(ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY) != -1:
                          changeCurrentMenu("Domestic Transport Booking")
                          break
                        case user?.role.indexOf(ROLES.DT.INVOICE_LEAD) != -1:
                          changeCurrentMenu("Domestic Transport Booking")
                          break
                        case user?.role.indexOf(ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING) != -1:
                          changeCurrentMenu("Domestic Transport Booking")
                          break
                        default:
                          changeCurrentMenu("Spot Booking WIP")
                          break
                      }

                    navigate("/", { replace: true })
              return
          }
        }

        // For temporary disable dashboard screen for client login
        if (loginStore.isClient()) {
            switch (configStore.currentURL.toString().toLowerCase().split("/").at(1)) {
                case 'contracts':
                    changeCurrentMenu('Create Booking');
                    navigate('/', { replace: true })
                    return;
                case 'contractsv2':
                    changeCurrentMenu('Create Bookingv2');
                    navigate('/', { replace: true })
                    return;
                default:
                    changeCurrentMenu('Home') // This is what navigates customers to Home menu
                    navigate('/', { replace: true })
            }

        } else {
            changeCurrentMenu('Quotation');
        }
    }, [])
    return (
        <div className="box" style={{ width: '100%', height: '100%' }}>
            <img src={dash} style={{ height: '100%', width: '100%' }} onClick={async () => {
                let ret = await MonApi.nextnum('somekey')
                console.log('Got return as ', ret)
            }} />
        </div>
    )
}