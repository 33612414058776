import React, { useState, useEffect, useRef } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { QueryClient, QueryClientProvider } from "react-query";
import RoleUtils from "../util/RoleUtils";
import { PERMISSIONS, VERTICALS } from "../util/constants";

import {
  DriverColumn,
  EntityColumnnonagraga,
} from "../components/ag-table/columns";

import ReactTablePagination from "../components/ag-table/ReactTablePagination";
import TableFilter from "../components/ag-table/TableFilter";

import { Sorting } from "../components/ag-table/ag-sorting";
import {
  reducer,
  PAGE_CHANGED,
  PAGE_SIZE_CHANGED,
  PAGE_SORT_CHANGED,
  PAGE_FILTER_CHANGED,
  TOTAL_COUNT_CHANGED,
  PAYLOAD_CHANGED,
} from "../components/ag-table/ag-reducer";
import ApiAgTable from "../components/ag-table/api_ag-table";
import { loginStore, routerStore, DriverStore, AgmodalStore, configStore } from "../state/CommonState";
import { useSnapshot } from "valtio";
import { DriverType } from "../state/Types";
import InviteUser from "../agusers/invite-user";
import DriverApi from "../state/driver_api";
import { loader } from "../util/loader";

const queryClient = new QueryClient();

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 20,
  totalCount: 0,
  queryPageFilter: "",
  queryPageSortBy: [],
  queryPayload: {},
};

const DataTable = (props) => {
  useSnapshot(DriverStore);
  useSnapshot(AgmodalStore);
  useSnapshot(configStore);
  const domain = loginStore.email.split("@")[1];
  const filterStatus = {
    Pending: "PENDING",
    Verified: "VERIFIED",
    Rejected: "REJECTED",
    DlExpired: "DL-EXPIRED",
  };

  const [selectedFilter, SetSelectedFilter] = useState(`all`);

  const [keyword, setKeyword] = useState("");
  const [inviteGst, setInviteGst] = useState("");
  const [data1, setData1] = useState({ results: [] });
  const onClickFilterCallback = (filter) => {
    setKeyword(filter);
  };
  let column = {};
  if (domain === "agraga.com") {
    column = DriverColumn;
  } else {
    column = EntityColumnnonagraga;
  }
  let columns = column;
  const [
    {
      queryPageIndex,
      queryPageSize,
      totalCount,
      queryPageFilter,
      queryPageSortBy,
      queryPayload,
    },
    dispatch,
  ] = React.useReducer(reducer, initialState);

  useEffect(() => {
    if(queryPayload.filter && AgmodalStore.apiCall){
      loader.show()
      ApiAgTable.driversData(
        queryPageIndex,
        queryPageSize,
        queryPageFilter,
        queryPageSortBy,
        queryPayload
      ).then((res) => {
        res
          .clone()
          .json()
          .then(
            (res) => {
              if (
                JSON.stringify(data1.results) !==
                JSON.stringify(res.results.drivers)
              ) {
                if (JSON.stringify(data1) !== JSON.stringify(res.results) && res.results.drivers && res.results.drivers.length > 0) {
                  setData1(res);
                }else{
                  setData1({ count: 0, results: [] });
                }
              }
              loader.hide()
            },
            (err) => {
              console.log(err);
            }
          );
      });
    }
  }, [
    queryPayload,
    queryPageIndex,
    queryPageSize,
    queryPageFilter,
    queryPageSortBy,
    AgmodalStore.apiCall
  ]);

  const totalPageCount = Math.ceil(totalCount / queryPageSize);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data: data1?.results?.drivers || [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: queryPageSortBy,
      },
      manualPagination: true,
      pageCount: data1 ? totalPageCount : null,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  );
  const manualPageSize = [];

  useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
  }, [pageIndex]);

  useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
    gotoPage(0);
  }, [pageSize, gotoPage]);

  useEffect(() => {
    dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
    gotoPage(0);
  }, [sortBy, gotoPage]);

  useEffect(() => {
    if(keyword.length === 0){
      dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword });
      gotoPage(0);
    }
  }, [keyword]);

  const onClickSearch = () => {
    if (keyword.length > 0) {
      dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword })
      gotoPage(0);
    }else{
      configStore.setModalMessage(`Please Provide Valid Input`)  
    }
  };

  useEffect(() => {
    dispatch({ type: PAYLOAD_CHANGED, payload: { filter: selectedFilter } });
    gotoPage(0);
  }, [selectedFilter]);

  React.useEffect(() => {
    if (data1?.count) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data1.count,
      });
    }
  }, [data1?.count]);

  console.log(headerGroups, " headerGroups");

  return (
    <>
      <h1 className="title">Driver Database</h1>
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <p className="subtitle is-5">{data1?.count} Drivers</p>
          </div>
          {
            RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_DRIVERS) &&
          <p className="level-item">
            <a
              className="button is-link"
              onClick={() => {
                DriverStore.DriversRec.set(new DriverType());
                AgmodalStore.modalPage = "newDrivers";
                AgmodalStore.apiCall = false
                AgmodalStore.pageVisible = true;
              }}
            >
              Add New Driver
            </a>
          </p>
          }

          <div className="level-item">
            <TableFilter
              onClickSearch={onClickSearch}
              onClickFilterCallback={onClickFilterCallback}
              defaultKeyword={keyword}
            />
          </div>
          <div class="level-item level-left ml-6">
            <div class="field">
              <p class="control has-icons-left has-icons-right">
                <span class="select">
                  <select
                    name="status"
                    id="status"
                    value={selectedFilter}
                    onChange={(e) => SetSelectedFilter(e.target.value)}
                  >
                    <option value="all">ALL</option>
                    {Object.entries(filterStatus).map(([key, value]) => (
                      <option value={key}>{value}</option>
                    ))}
                  </select>
                  <span class="icon">
                    <i class="fa-solid fa-filter"></i>
                  </span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </nav>

      
            <div className="box">
              <div className="table-container" style={{ overflow: "initial" }}>
                <table className="table is-fullwidth is-small ">
                  <thead className="is-scrollable-header has-background-white-ter is-unselectable">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        <th>S.No</th>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                            <Sorting column={column} />
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody
                   className="contractlist"
                   style={{ fontSize: "0.9rem" }}
                  {...getTableBodyProps()}>
                    {page.map((row, i) => {
                      prepareRow(row);

                      return (
                        <>
                          <tr {...row.getRowProps()}>
                            <td>{pageIndex * pageSize + i + 1}</td>
                            {row.cells.map((cell) => {
                              // eslint-disable-next-line no-lone-blocks
                              {
                                let e = cell["row"]["original"];

                                switch (cell["column"]["Header"]) {
                                  case "Driver License No":
                                    return (
                                      <td>
                                        <a
                                          onClick={async (event) => {
                                            //  DriverStore.DriversRec.set(e);
                                            DriverApi.viewDriver(e.DlNumber);
                                            AgmodalStore.pageVisible = true;
                                            AgmodalStore.modalPage = "viewDrivers";
                                            event.stopPropagation();
                                          }}
                                        >
                                          {e.DlNumber}
                                        </a>
                                      </td>
                                    );
                                  case "Actions":
                                    return (
                                        <td>
                                        <div
                                          class="dropdown post-options is-right is-hoverable "
                                          onClick={null}>
                                          <div class="dropdown-trigger">
                                            <button
                                              class="button"
                                              aria-haspopup="true"
                                              aria-controls="dropdown-menu-post"
                                              //   onClick={() => {
                                              //     vehicleStore.vehicleRec.set(e);
                                              //     routerStore.vehicleCurrentView =
                                              //       "view";
                                              //   }}
                                            >
                                              <span class="icon is-small">
                                                <i
                                                  class="fas fa-ellipsis-h"
                                                  aria-hidden="true"></i>
                                              </span>
                                            </button>
                                          </div>
                                    {
                                      (RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.REJECTED_DRIVERS)&& e.Status !=="Pending") &&
                                          <div
                                            class="dropdown-menu"
                                            id="dropdown-menu-post"
                                            role="menu">
                                            <div class="dropdown-content">
                                              <a
                                                href="/#"
                                                class="dropdown-item"
                                                onClick={(event) => {
                                                   // DriverStore.DriversRec.set(e);
                                                   DriverApi.viewDriver(e.DlNumber);
                                                   AgmodalStore.modalPage = "editDrivers";
                                                   AgmodalStore.apiCall = false;
                                                   AgmodalStore.pageVisible = true;
                                                    event.stopPropagation();
                                                  }}
                                                
                                                
                                                >
                                                Modify
                                              </a>
                                              {/* <a
                                                    href="/#"
                                                    class="dropdown-item"
                                                    onClick={() => {
                                                      console.log(e);
                                                      // BranchApi.viewBranch(e._id);
                                                      VehicleApi.getList(e._id);
                                                      routerStore.branchCurrentView =
                                                        "view";
                                                    }}>
                                                    View
                                                  </a> */}
                                            </div>
                                          </div>
                                    }
                                        </div>
                                      </td>
                                    );
                                  default:
                                    return (
                                      <td {...cell.getCellProps()}>
                                        <span>{cell.render("Cell")}</span>
                                      </td>
                                    );
                                }
                              }
                            })}
                          </tr>
                          {inviteGst === row.original.gst ? (
                            <tr>
                              <td
                                style={{ backgroundColor: "grey" }}
                                colSpan={9}
                              >
                                <InviteUser
                                  gst={row.original.gst}
                                  resetState={setInviteGst}
                                  admin="Yes"
                                  style={{ float: "right" }}
                                />
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })}
                  </tbody>
                </table>
                <ReactTablePagination
                  page={page}
                  gotoPage={gotoPage}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  pageOptions={pageOptions}
                  pageSize={pageSize}
                  pageIndex={pageIndex}
                  pageCount={pageCount}
                  setPageSize={setPageSize}
                  manualPageSize={manualPageSize}
                  dataLength={totalCount}
                  rows={rows}
                />
              </div>
            </div>
       
    </>
  );
};

const ListDrivers = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <DataTable />
    </QueryClientProvider>
  );
};

export default ListDrivers;
