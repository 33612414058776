import { contractsStore } from "../state/CommonState";
import Utils from "./Utils";

export default class ScheduleUtils {
  static isValidSchedule(shipmentType, schedule, contract,replaceCode) {
    switch (shipmentType) {
      case "lcl":
        return ScheduleUtils.isValidLCLSchedule(schedule, contract);
      case "fcl":
        return ScheduleUtils.isValidFCLSchedule(schedule, replaceCode);
      case "uploadfcl":
        return ScheduleUtils.isValidUploadFCLSchedule(schedule, contract);
      case "air":
        return true;
      default:
        return false;
    }
  }
  static isValidLCLSchedule(schedule, contract) {
    if (!schedule["POR (CFS / ICD / PORT)"])
      schedule["POR (CFS / ICD / PORT)"] = schedule["POL"];
    let srec = {
      por: schedule["POR (CFS / ICD / PORT)"],
      pol: schedule["POL"],
      pod: schedule["POD UNLOC"],
      vessel: schedule["VESSEL NAME"],
      voyage: schedule["VOYAGE"],
      cutOffDt: schedule["STUFFING CUT OFF DATE"],
      etdPol: schedule["ETD (POL)"],
      etaPod: schedule["ETA (POD)"],
      coLoader: schedule["CO-LOADER"],
    };
    const rec = contract;
    if (!rec) return false;
    let etdDate = Utils.newDate(srec.etdPol);
    let startDate = Utils.newDate(rec.mailedOn);
    startDate = Utils.addDays(startDate, 3);
    // The below overrides the above startDate and cutoff. The below considers only the current date
    // and not the quote start date
    let currentDate = new Date();
    let cargoReadinessDate =
      rec.cargoReadinessDate && rec.cargoReadinessDate.length >= 10
        ? new Date(rec.cargoReadinessDate)
        : currentDate;
    if (cargoReadinessDate < currentDate) cargoReadinessDate = currentDate;
    if (isNaN(cargoReadinessDate)) cargoReadinessDate = currentDate;
    let startDays = srec.pol === srec.por ? 2 : 8; // the logic in mail is >= 4/13, equivalent to this > 3/12
    startDate = Utils.addDays(cargoReadinessDate, startDays);
    if (etdDate <= startDate) return false;

    let endDate = Utils.newDate(rec.validUpto);
    let endDays = srec.por !== srec.pol ? 8 : 2;
    endDate = Utils.addDays(endDate, endDays * -1);
    // the below overrides the above
    endDate = Utils.newDate(rec.validUpto);
    if (etdDate >= endDate) return false;
    return true;
  }

  static isValidFCLSchedule(schedule, replaceCode=true) {
    if (!schedule['POR Code']) schedule['POR Code'] = schedule['ocode']
    const rec = contractsStore.current
    if (!rec) return false;
   if(replaceCode){
    schedule['POR Name'] =schedule['POR Code']
    schedule['oname'] = schedule['ocode']
    schedule['ts1name'] = schedule['ts1code']
    schedule['ts2name'] = schedule['ts2code']
    schedule['dname'] = schedule['dcode']
   }
    let srec = {
        porname: schedule['POR Name'],
        porcode: schedule['POR Code'],
        departurePor: schedule['Departure from POR'],
        oname: schedule['oname'],
        ocode: schedule['ocode'],
        oetd: schedule['oetd'] ? Utils.formatDateShort(Utils.newDate(schedule['oetd'])) : '',
        r1code: schedule['r1code'],
        v1name: schedule['v1name'],
        s1voy: schedule['s1voy'],
        ts1name: schedule['ts1name'],
        ts1code: schedule['ts1code'],
        ts1eta: schedule['ts1eta'] ? Utils.formatDateShort(Utils.newDate(schedule['ts1eta'])) : '',
        ts1etd: schedule['ts1etd'] ? Utils.formatDateShort(Utils.newDate(schedule['ts1etd'])) : '',
        r2code: schedule['r2code'],
        v2name: schedule['v2name'],
        s2voy: schedule['s2voy'],
        ts2name: schedule['ts2name'],
        ts2code: schedule['ts2code'],
        ts2eta: schedule['ts2eta'] ? Utils.formatDateShort(Utils.newDate(schedule['ts2eta'])) : '',
        ts2etd: schedule['ts2etd'] ? Utils.formatDateShort(Utils.newDate(schedule['ts2etd'])) : '',
        r3code: schedule['r3code'],
        v3name: schedule['v3name'],
        s3voy: schedule['s3voy'],
        dname: schedule['dname'],
        dcode: schedule['dcode'],
        deta: schedule['deta'] ? Utils.formatDateShort(Utils.newDate(schedule['deta'])) : '',
        cscac: schedule['cscac']
    }
    let eta = schedule['deta']
    let etd = schedule['oetd']
    //console.log('ETD =', etd, schedule['oetd'])
    let etaDate = Utils.newDate(eta)
    let etdDate = Utils.newDate(etd)
    let porCode = schedule['POR Code']
    let polCode = schedule['ocode']
    let cutoffDate = (porCode === polCode) ? Utils.addDays(etdDate, -3) : Utils.addDays(etdDate, -12)
    let cscac = schedule['cscac']
    //console.log('CSCAC=', cscac)

    let startDate = Utils.newDate(rec.mailedOn)
    //if (!startDate) startDate = new Date()
    //console.log('%%%%%%%%% startDate=', startDate)
    startDate = Utils.addDays(startDate, 3)
    // The below overrides the above startDate. The below considers only the current date
    // and not the quote start date
    let currentDate = new Date()
    let cargoReadinessDate = (rec.cargoReadinessDate && rec.cargoReadinessDate.length >= 10) ?
        new Date(rec.cargoReadinessDate) : currentDate
    if (cargoReadinessDate < currentDate) cargoReadinessDate = currentDate
    if (isNaN(cargoReadinessDate)) cargoReadinessDate = currentDate
    let startDays = (porCode === polCode) ? 2 : 8 // the logic in mail is >= 4/13, equivalent to this > 3/12
    startDate = Utils.addDays(cargoReadinessDate, startDays)
    if (etdDate < startDate  ) return false;
    let endDate = Utils.newDate(rec.validUpto)
    //endDate = Utils.addDays(endDate, endDays*-1)
    if (etdDate > endDate  ) return false;
    return true;
  }

  static isValidUploadFCLSchedule(schedule, contract) {
    if (!schedule['POR Code']) schedule['POR Code'] = schedule['ocode']
    const rec = contract
    if (!rec) return false;
    const check = (rec,schedule,validUpto,carrier) => {
      let m = [
        'POR Name',
        'POR Code',
        "oname", 
        "ocode", 
        "oetd", 
        "r1code", 
        "v1name", 
        "s1voy", 
        "dname", 
        "dcode", 
        "deta",
        "cscac" 
        ]
      let mvaild = true
      for (let i = 0; i < m.length; i++) {
        if(schedule[m[i]].trim().length==0){
          mvaild = false
          i = m.length+i
        }
      }
      if (!mvaild) return false;
      if(rec.portOfReceipt.indexOf(schedule['POR Name'])==-1) return false
      if(rec.portOfLoading.indexOf(schedule['oname'])==-1) return false
      if(rec.finalPlaceOfDelivery.indexOf(schedule['dname'])==-1) return false
      let scac = carrier.substring(carrier.lastIndexOf("(") + 1,carrier.lastIndexOf(")")).trim()
      if(scac.toLowerCase() != schedule['cscac'].toLowerCase()) return false
      let etd = schedule['oetd']
      let etdDate = Utils.newDate(etd)
      let porCode = schedule['POR Code']
      let polCode = schedule['ocode']
      let startDate = Utils.newDate(rec.mailedOn)
      startDate = Utils.addDays(startDate, 3)
      let currentDate = new Date()
      let cargoReadinessDate = (rec.cargoReadinessDate && rec.cargoReadinessDate.length >= 10) ?
          new Date(rec.cargoReadinessDate) : currentDate
      if (cargoReadinessDate < currentDate) cargoReadinessDate = currentDate
      if (isNaN(cargoReadinessDate)) cargoReadinessDate = currentDate
      let startDays = (porCode === polCode) ? 2 : 8 // the logic in mail is >= 4/13, equivalent to this > 3/12
      startDate = Utils.addDays(cargoReadinessDate, startDays)
      if (etdDate < startDate  ) return false;
      let endDate = Utils.newDate(validUpto)
      if (etdDate > endDate  ) return false;
      return true;
    }
    if(rec.multicharge==undefined || rec.multicharge.length==0){
      return check(rec,schedule,rec.validUpto,rec.carrier)
    }else{
      let ch = true
      for (let i = 0; i < rec.multicharge.length; i++) {
        ch = check(rec,schedule,rec.multicharge[i].validUpto,rec.multicharge[i].carrier)
        if(ch){
          i = rec.multicharge.length+1
        }
      }
      return ch
    }
  }
}
