import React from "react";

const ShipmentGraphTemp = ({ data }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", padding: "20px", overflowX: "auto" }}>
      {data.map((entry, index) => (
        <div key={index} style={{ display: "flex", alignItems: "center", position: "relative" }}>
      
          <div style={{ width: "12px", height: "12px", backgroundColor: "blue", borderRadius: "50%" }}></div>

  
          <div style={{ marginLeft: "10px", textAlign: "center" }}>
            {entry.atd && (
              <div style={{ fontSize: "12px", color: "gray" }}>
                <strong>ATD:</strong> {new Date(entry.atd).toLocaleString()}
              </div>
            )}
            {entry.ata && (
              <div style={{ fontSize: "12px", color: "gray" }}>
                <strong>ATA:</strong> {new Date(entry.ata).toLocaleString()}
              </div>
            )}
            {entry.etd && (
              <div style={{ fontSize: "12px", color: "gray" }}>
                <strong>ETD:</strong> {new Date(entry.etd).toLocaleString()}
              </div>
            )}
            {entry.eta && (
              <div style={{ fontSize: "12px", color: "gray" }}>
                <strong>ETA:</strong> {new Date(entry.eta).toLocaleString()}
              </div>
            )}
            <div style={{ fontSize: "14px", fontWeight: "bold", marginTop: "5px" }}>
              {entry.origin} ➝ {entry.destination}
            </div>
          </div>

          {index < data.length - 1 && (
            <div
              style={{
                height: "2px",
                backgroundColor: "gray",
                width: "80px",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            ></div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ShipmentGraphTemp;