import React, {useState, useEffect, useMemo} from "react"
import { useTable, usePagination, useSortBy } from "react-table"
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'

import {LCLSCHEDULES_COLUMNS } from "./columns"

import ReactTablePagination from './ReactTablePagination'
import TableFilter  from "./TableFilter"

import { Sorting } from "./ag-sorting"
import { reducer,PAGE_CHANGED,
    PAGE_SIZE_CHANGED, 
    PAGE_SORT_CHANGED ,
     PAGE_FILTER_CHANGED, 
    TOTAL_COUNT_CHANGED  } from "./ag-reducer"
import ApiAgTable from "./api_ag-table"
import { configStore } from "../../state/CommonState"

const queryClient = new QueryClient()

const initialState = {
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    queryPageFilter:"",
    queryPageSortBy: [],
};



const DataTable = (props) => {
    const {COLUMNS} = props
    const [keyword, setKeyword] = useState('');
    const [useFilter, setUseFilter] = useState(false);
    const [data1,setData1] = useState({results:[]})
    const onClickFilterCallback = ( filter ) => {
        setUseFilter(true)
        setKeyword(filter)
    }

    let columns = useMemo( () => COLUMNS, [])
    

    const [{ queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy }, dispatch] =
    React.useReducer(reducer, initialState);

    const { isLoading, error, data, isSuccess } = useQuery(
        [queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy],
        () => ApiAgTable.lclschedulesData(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy),
        {
            keepPreviousData: false,
            staleTime: Infinity,
        }
    );
    if(data){
        try {
            data.clone().json().then((res)=>{
                if(JSON.stringify(data1.results)!=JSON.stringify(res.results)){
                    setData1(res)
                }
            },err=>{
                console.log(err)
            })
        } catch (error) {
            
        }
        
    }
    

    const totalPageCount = Math.ceil(totalCount / queryPageSize)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageCount,
        pageOptions,
        gotoPage,
        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable({
        columns,
        data: data1?.results || [],
        initialState: {
            pageIndex: queryPageIndex,
            pageSize: queryPageSize,
            sortBy: queryPageSortBy,
        },
        manualPagination: true,
        pageCount: data1 ? totalPageCount : null,
        autoResetSortBy: false,
        autoResetExpanded: false,
        autoResetPage: false
    },
    useSortBy,
    usePagination,
    );
    const manualPageSize = []
    
    useEffect(() => {
        dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    }, [pageIndex]);

    useEffect(() => {
        dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
        gotoPage(0);
    }, [pageSize, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
        gotoPage(0);
    }, [sortBy, gotoPage]);

    useEffect(() => {
        if(keyword.length === 0){
          dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword })
          gotoPage(0)
        }
      }, [keyword])

    const onClickSearch = () => {
        if ((keyword.length > 0)) {
          dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword })
          gotoPage(0);
        }else{
          configStore.setModalMessage(`Please Provide Valid Input`)  
        }
      };

    React.useEffect(() => {
        if (data1?.count) {
            dispatch({
            type: TOTAL_COUNT_CHANGED,
            payload: data1.count,
            });
        }
    }, [data1?.count]);

    if (error) {
        return <p>Error</p>;
    }

    
    if(isSuccess || isLoading)
    return (
            <>
                <div className='table react-table'>
                    <form className="form form--horizontal">
                        <div className="form-form-group">
                            <div className="col-md-9 col-lg-9">
                                <TableFilter onClickSearch={onClickSearch} onClickFilterCallback={onClickFilterCallback} defaultKeyword={keyword} />
                            </div>
                        </div>
                    </form>
                    {
                        (isLoading)?<p>Loading...</p>:<>
                        {
                        (typeof data1?.count === 'undefined' || data1?.count==0) && <p>No results found</p>
                    }
                    {( data1?.count>0) && 
                    <>
                    <table {...getTableProps()} className="table">
                        <thead>
                            {headerGroups.map( (headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map( column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <Sorting column={column} />
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody className="table--bordered" {...getTableBodyProps()}>
                            {page.map( row => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {
                                            row.cells.map( cell => {
                                                return <td {...cell.getCellProps()}><span>{cell.render('Cell')}</span></td>
                                            })
                                        }
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    </>
                }
                        </>
                    }
                    
                </div>
                {(rows.length > 0) && (
                    <>
                    <div className="columns" style={{width:"100%"}}>
                        <div className="column is-6">
                        <ReactTablePagination
                            page={page}
                            gotoPage={gotoPage}
                            previousPage={previousPage}
                            nextPage={nextPage}
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            pageOptions={pageOptions}
                            pageSize={pageSize}
                            pageIndex={pageIndex}
                            pageCount={pageCount}
                            setPageSize={setPageSize}
                            manualPageSize={manualPageSize}
                            dataLength={totalCount}
                        />
                        </div>
                        <div className="column is-6" style={{textAlign:"right"}}>
                        <div className="select">
                            <select
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                            }}
                            >
                            {[5, 10,20, 50, 100].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                                </option>
                            ))}
                            </select>
                        </div>
                        </div>

                    </div>
                        
                        
                    </>
                )}
            </>
    )
}



const LclSchedulesTable = (props) => {
    return (
        <QueryClientProvider client={queryClient}>
            <DataTable COLUMNS ={LCLSCHEDULES_COLUMNS}/>
        </QueryClientProvider>
    )
}

export default LclSchedulesTable;