import React from 'react'
// import Select from 'react-select'

// const statusOptions = [
//     {'value':'', 'label':'All'},
//     {'value':'Active', 'label':'Active'},
//     {'value':'Inactive', 'label':'Inactive'},
//     {'value':'Pending', 'label':'Pending'},
// ]

const TableFilter = ({onClickFilterCallback, onClickSearch, defaultKeyword,deBouncingFlag,placeholder}) => {
    const [keyword, setKeyword] = React.useState(defaultKeyword)
    const onKeywordChange = ( e ) => {
      setKeyword( e.target.value )
      onClickFilterCallback(e.target.value) 
    }
    const onKeyUpHandler = (e) => {
      if (e.key === 'Enter') {
          onClickFilterCallback(keyword);
          onClickSearch()
      }
    };
    const search = () => {
      onClickSearch()
    }
    return (
        <div className="field has-addons">
      <p className="control">
      <input 
                    className='input'
                        value={keyword}
                        onChange={onKeywordChange}
                        onKeyUp={onKeyUpHandler} 
                        type="text"
                        pattern="^[a-zA-Z0-9]+$"
                        placeholder={placeholder?placeholder:"Search"}
                        style={{
                          borderColor: "none",
                          boxShadow: "none",
                        }}
                    />
      </p>
      <p className="control">
        <span className="button is-link" onClick={search}>Search</span>
      </p>
    </div>
        
    )
}

export default TableFilter;