import { useSnapshot } from "valtio";
import { myactionsstore } from "./store-myactions";
import { bookingsStore, configStore } from "../state/CommonState";
import { OneDatetable, OneInput5table } from "../mytasks/act-common";
import { useEffect, useRef, useState } from "react";
import Api from "../state/Api";
import { DtStore } from "../dt/DtStore";
import { DtMap } from "../dt/DtMap";
import {origin_pickup_LCL,
  origin_pickup_fcl_FACTORY_FACTORY,
  origin_pickup_fcl_FACTORY_CFS,
  origin_pickup_fcl_CFS_FACTORY,
  origin_pickup_fcl_CFS_CFS,
  origin_pickup_AIR,
  destination_delivery_LCL,
  destination_delivery_fcl_FACTORY_FACTORY,
  destination_delivery_fcl_FACTORY_CFS,
  destination_delivery_fcl_CFS_CFS,
  destination_delivery_fcl_CFS_FACTORY,
  destination_delivery_AIR,
  gatein_mandatory_fields,
  getUpdatedFreezeFields} from './dsr_static'
import { SHIPMENT_SCOPE, SHIPMENT_TYPE } from "../util/constants";

const  VEHICLE_ROW_ERROR_DEFAULT = {
  origin: true,
  destination: true,
  driver_number: true,
  vehicle: true,
  number_of_package: true,
  total_package: true,
};
export function Vmodal({data, setIsDisabledForActualDD, 
  setIsDisabledForCargoPickup, originDisableStatus,
   setCargoPickUp, setActualDD, destinationDisableStatus}) {
  const booking = data;
  const por = data.por
  const pol = data.pol
  console.log("props-------->", booking.shipmentType, booking);
  
  let originDateSelector;
  if (booking.shipmentType === SHIPMENT_TYPE.lcl) {
    originDateSelector = origin_pickup_LCL;
  }
  if (booking.shipmentType === SHIPMENT_TYPE.fcl_f_f) {
    originDateSelector = origin_pickup_fcl_FACTORY_FACTORY;
  }
  if (booking.shipmentType === SHIPMENT_TYPE.fcl_f_cfs) {
    originDateSelector = origin_pickup_fcl_FACTORY_CFS;
  }
  if (booking.shipmentType === SHIPMENT_TYPE.fcl_cfs_cfs) {
    originDateSelector = origin_pickup_fcl_CFS_CFS;
  }
  if (booking.shipmentType === SHIPMENT_TYPE.fcl_cfs_f) {
    originDateSelector = origin_pickup_fcl_CFS_FACTORY;
  }
  if (booking.shipmentType === SHIPMENT_TYPE.air) {
    originDateSelector = origin_pickup_AIR;
  }

  let destinationDateSelector;
  if (booking.shipmentType === SHIPMENT_TYPE.lcl) {
    destinationDateSelector = destination_delivery_LCL;
  }
  if (booking.shipmentType === SHIPMENT_TYPE.fcl_f_f) {
    destinationDateSelector = destination_delivery_fcl_FACTORY_FACTORY;
  }
  if (booking.shipmentType === SHIPMENT_TYPE.fcl_f_cfs) {
    destinationDateSelector = destination_delivery_fcl_FACTORY_CFS;
  }
  if (booking.shipmentType === SHIPMENT_TYPE.fcl_cfs_cfs) {
    destinationDateSelector = destination_delivery_fcl_CFS_CFS;
  }
  if (booking.shipmentType === SHIPMENT_TYPE.fcl_cfs_f) {
    destinationDateSelector = destination_delivery_fcl_CFS_FACTORY;
  }
  if (booking.shipmentType === SHIPMENT_TYPE.air) {
    destinationDateSelector = destination_delivery_AIR;
  }
  useSnapshot(myactionsstore);
  useSnapshot(DtStore);
  useSnapshot(configStore);
  const [ch, setCh] = useState(0);
  const [origin, setOrgin] = useState([]);
  const [des, setDes] = useState([]);
  const [multipleDes, setMultipleDes] = useState([]);
  const [originDisable, setOriginDisable] = useState(setIsDisabledForCargoPickup.length === 0  ? [] : setIsDisabledForCargoPickup)
  const [destinationDisable, setDestinationDisable] = useState(setIsDisabledForActualDD.length === 0  ? [] : setIsDisabledForActualDD)
  // const [disable, setDisable] = useState(isValueTrue)
  const [isPicked, setisPicked] = useState(false);
  const [mailPickup, setMailPickup] = useState(false);
  const [mailDelivery, setMailDelivery] = useState(false);
  const [isDelivered, setisDelivered] = useState(false);
  const [deliveryMailed, setDeliveryMailed] = useState(false);
  const [pickupMailed, setPickupMailed] = useState(false);
  const [mapVisible, setMapVisible] = useState(false);
  const [total_package, setTotalPackage] = useState(false);
  const [originError, setOriginError] = useState([]);
  const [destinationError, setDestinationError] = useState([]);
  const [multidestError, setMultiDestError] = useState([]);
  const updatedFreezeFieldsRef = useRef(gatein_mandatory_fields);
  let ret = configStore.modalvisibledata;
  let st = ret.shipmentType;
  let sc = ret.shipmentScope;
  const multidest = booking?.booking?.contract?.multidest

  console.log("sc---------?", ret);
  console.log("originInitial---------?", booking.vorgin);
  console.log("originInitial else---------?", booking.vdes);
  useEffect(() => {
    let origin_destination = ""
    let b = booking?.booking
    if(b?.contract?.portOfReceipt.trim().length==0){
        origin_destination = b?.contract?.portOfLoading
    }else{
        origin_destination = b?.contract?.portOfReceipt
    }
    let destination_origin = ""
    if(b?.contract?.finalPlaceOfDelivery.trim().length==0){
        destination_origin = b?.contract?.portOfDischarge
    }else{
        destination_origin = b?.contract?.finalPlaceOfDelivery
    }
    let originInitial = new Array();
    if ((booking?.vorgin === undefined || booking?.vorgin?.length === 0) 
      && (sc === SHIPMENT_SCOPE.d2p || sc === SHIPMENT_SCOPE.d2d) && st != SHIPMENT_TYPE.fcl_f_cfs) {
      originInitial.push({
        origin: booking.origin,
        destination: origin_destination,
        driver_number: "",
        driver_name: "",
        vehicle: "",
        number_of_package: "",
        status: "",
        total_package: booking?.booking?.contract?.cargoList?.reduce((sum, cargo) => sum + (+cargo.numPackages), 0),
        ...Object.keys(originDateSelector).reduce((ele, key) => {
          ele[key] = "";
          return ele;
        }, {}),
      })
    }else if (booking?.vorgin && booking?.vorgin?.length > 0 
      && (sc === SHIPMENT_SCOPE.d2p || sc === SHIPMENT_SCOPE.d2d) && (st != SHIPMENT_TYPE.fcl_f_cfs)) {
      originInitial = booking?.vorgin;
      originInitial.forEach((ele) => {
        ele.origin = ele.origin === undefined ? booking.origin : ele.origin;
        ele.destination = origin_destination;
        ele.driver_number = ele.driver_number === undefined ? "" : ele.driver_number;
        ele.driver_name = ele.driver_name === undefined ? "" : ele.driver_name;
        ele.vehicle = ele.vehicle === undefined ? "" : ele.vehicle;
        ele.number_of_package = ele.number_of_package === undefined ? "" : ele.number_of_package;
        ele.status = ele.status === undefined ? "" : ele.status;
        ele.total_package = ele.total_package > 0 ? ele.total_package : 
        booking?.booking?.contract?.cargoList?.reduce((sum, cargo) => sum + (+cargo.numPackages), 0);
      })
    }
    if(originDateSelector.cargo_pickup && !originDisableStatus){
      let check = originInitial.map((ele) => ele.cargo_pickup === "");
      setOriginDisable(check)
      setCargoPickUp(check)
    }

    setOrgin(originInitial);
    setOriginError(originInitial.map(ele => (booking.shipmentType ===  SHIPMENT_TYPE.lcl ? {...VEHICLE_ROW_ERROR_DEFAULT, vehicle: true, driver_number: true} : VEHICLE_ROW_ERROR_DEFAULT)))
    let destinationInitial = new Array();
    if ((booking?.vdes === undefined || booking?.vdes?.length === 0) && (sc == SHIPMENT_SCOPE.p2d || sc == SHIPMENT_SCOPE.d2d) 
      && st != SHIPMENT_TYPE.fcl_cfs_f && (multidest?.length === 1 || multidest === undefined)) {
        destinationInitial.push({
          origin: destination_origin,
          destination: booking.destination,
          driver_number: "",
          driver_name: "",
          vehicle: "",
          number_of_package: "",
          status: "",
          total_package: booking?.booking?.contract?.cargoList?.reduce((sum, cargo) => sum + (+cargo.numPackages), 0),
          ...Object.keys(destinationDateSelector).reduce((ele, key) => {
            ele[key] = "";
            return ele;
          }, {}),
        })
        if(booking.shipmentType === SHIPMENT_TYPE.fcl_cfs_cfs || booking.shipmentType === SHIPMENT_TYPE.fcl_f_cfs){
          destinationInitial[0].container_number_vehicle = "";
        }
    }else if (booking?.vdes?.length > 0 && (sc == SHIPMENT_SCOPE.p2d || sc == SHIPMENT_SCOPE.d2d) && 
      st != SHIPMENT_TYPE.fcl_cfs_f && (multidest === undefined || multidest?.length === 1) ) {
      destinationInitial = booking?.vdes;
      destinationInitial.forEach((ele) => {
        ele.origin = destination_origin;
        ele.destination = ele.destination === undefined ? booking.destination : ele.destination;
        ele.driver_number = ele.driver_number === undefined ? "" : ele.driver_number;
        ele.driver_name = ele.driver_name === undefined ? "" : ele.driver_name;
        ele.vehicle = ele.vehicle === undefined ? "" : ele.vehicle;
        ele.number_of_package = ele.number_of_package === undefined ? "" : ele.number_of_package;
        ele.status = ele.status === undefined ? "" : ele.status;
        ele.total_package = ele.total_package > 0 ? ele.total_package :
        booking?.booking?.contract?.cargoList?.reduce((sum, cargo) => sum + (+cargo.numPackages), 0);
      })
      if(booking.shipmentType === SHIPMENT_TYPE.fcl_cfs_cfs || booking.shipmentType === SHIPMENT_TYPE.fcl_f_cfs){
        destinationInitial.forEach((ele) => {
          ele.container_number_vehicle = ele.container_number_vehicle === undefined ? "" : ele.container_number_vehicle;
        })
      }
    }
    if(destinationDateSelector.actual_delivery_date && !destinationDisableStatus){
      let check = destinationInitial.map((ele) => ele.actual_delivery_date === "");
      setDestinationDisable(check)
      setActualDD(check)
    }
    setDes(destinationInitial);
    if(booking.shipmentType === SHIPMENT_TYPE.fcl_cfs_cfs || booking.shipmentType === SHIPMENT_TYPE.fcl_f_cfs){
      let errorMap = VEHICLE_ROW_ERROR_DEFAULT;
      errorMap.container_number_vehicle = true;
      setDestinationError(destinationInitial.map(ele => errorMap))
    }else{
      setDestinationError(destinationInitial.map(ele => booking.shipmentType ===  SHIPMENT_TYPE.lcl ? 
        {...VEHICLE_ROW_ERROR_DEFAULT, vehicle: true, driver_number: true} : VEHICLE_ROW_ERROR_DEFAULT))
    }
    let multipleDestinationInitial = new Array();
    let destination;
    if((booking?.vdes === undefined || booking?.vdes.length === 0) && multidest?.length > 1 
    && (sc == SHIPMENT_SCOPE.p2d || sc == SHIPMENT_SCOPE.d2d) && st != SHIPMENT_TYPE.fcl_cfs_f){
      multidest.forEach((ele, index) => {
        destination = [{
          origin: destination_origin,
          destination: ele.destination,
          driver_number: "",
          driver_name: "",
          vehicle: "",
          number_of_package: "",
          status: "",
          total_package: ele.cargoDetails.reduce((sum, cargo) => sum + (+cargo.numPackages), 0),
          ...Object.keys(destinationDateSelector).reduce((selector, key) => {
            selector[key] = "";
            return selector;
          }, {}),
        }];
        if(booking.shipmentType === SHIPMENT_TYPE.fcl_cfs_cfs || booking.shipmentType === SHIPMENT_TYPE.fcl_f_cfs){
          destination[0].container_number_vehicle = "";
        }
        multipleDestinationInitial.push(destination)
      })
      
    }
    else if(booking?.vdes && multidest?.length > 1 && 
      (sc == SHIPMENT_SCOPE.p2d || sc == SHIPMENT_SCOPE.d2d) && st != SHIPMENT_TYPE.fcl_cfs_f){
      let a = []
      for (let i = 0; i < multidest?.length; i++) {
          if(i==0){
              if(booking.vdes != undefined && booking.vdes.length > 0) {
                  a.push(booking.vdes)
              }
          }else{
              let k = i+1
              if(booking['vdes'+k] != undefined && booking['vdes'+k].length > 0) {
                  a.push(booking['vdes'+k])
              }
          }
      }
      multipleDestinationInitial = a;
    }
    if(multipleDestinationInitial.length > 0 && destinationDateSelector.actual_delivery_date && !destinationDisableStatus){
      let check = multipleDestinationInitial.map((ele) => ele.map(e => e.actual_delivery_date === ""));
      setDestinationDisable(check)
      setActualDD(check)
    }
    setMultipleDes(multipleDestinationInitial)
    if(booking.shipmentType === SHIPMENT_TYPE.fcl_cfs_cfs || booking.shipmentType === SHIPMENT_TYPE.fcl_f_cfs){
      let errorMap = [VEHICLE_ROW_ERROR_DEFAULT];
      errorMap[0].container_number_vehicle = true;
      setMultiDestError(multipleDestinationInitial?.map(ele => ele.map(e => errorMap)));
    }else{
      setMultiDestError(multipleDestinationInitial?.map(ele => ele.map(e => [VEHICLE_ROW_ERROR_DEFAULT])));
    }
  }, [])
      const shipmentYettoStart = configStore.modalvisibledata?.booking?.status === "SUBMITTED"
  const areAllCargoPickupFilled = () => {
    // Check if any cargo_pickup field is empty
    for (let i = 0; i < origin.length; i++) {
      if (!origin[i].cargo_pickup) {
        return false; // Return false if any cargo_pickup field is empty
      }
    }
    return true; // Return true if all cargo_pickup fields are filled
  };
  const areAllCargoDeliveryFilled = () => {
    // Check if any cargo_pickup field is empty
    for (let i = 0; i < des.length; i++) {
      if (!des[i].actual_delivery_date) {
        return false; // Return false if any cargo_pickup field is empty
      }
    }
    return true; // Return true if all cargo_pickup fields are filled
  };
  useEffect(() => {
    const updatedFreeze = getUpdatedFreezeFields(
        gatein_mandatory_fields,
        st,
        sc,
        por,
        pol
    );
    updatedFreezeFieldsRef.current = updatedFreeze.updatedFreeze
}, [st, sc, por, pol]);
  const handlePicked = () => {
    if (areAllCargoPickupFilled()) {
      setisPicked(!isPicked);
    } else {
      setMailPickup(false);
      setisPicked(false);
    }
  };

  const handlePickToggle = () => {
    if (areAllCargoPickupFilled()) {
      setMailPickup(!mailPickup);
    } else {
      setMailPickup(false);
    }
  };
  const handleDeliveryToggle = () => {
    if (areAllCargoDeliveryFilled()) {
      setMailDelivery(!mailDelivery);
    } else {
      setMailDelivery(false);
    }
  };
  const handleDelivered = () => {
    if (areAllCargoDeliveryFilled()) {
      setisDelivered(!isDelivered);
    } else {
      setisDelivered(false);
    }
  };
  const seorde = () => {
    setPickupMailed(ret.picked !== true);
    setDeliveryMailed(ret.delivered !== true);
    if (sc == SHIPMENT_SCOPE.p2d || sc == SHIPMENT_SCOPE.d2d) {
      if (ret.vdes != undefined && ret.vdes.length > 0) {
        setDes(ret.vdes);
      }
    }
    if (sc == SHIPMENT_SCOPE.d2p || sc == SHIPMENT_SCOPE.d2d) {
      if (ret.vorigin != undefined && ret.vorigin.length > 0) {
        setOrgin(ret.vorigin);
      }
    }
    changech();
  };

  const changech = () => {
    if (ch == 0) {
      setCh(1);
    } else {
      setCh(0);
    }
  };


  const add1 = (e) => {
    e.preventDefault()
    let origin_destination = ""
    let b = booking?.booking
    if(b?.contract?.portOfReceipt.trim().length==0){
        origin_destination = b?.contract?.portOfLoading
    }else{
        origin_destination = b?.contract?.portOfReceipt
    }
    let o = {
      origin: booking.origin,
      destination: origin_destination,
      driver_number: "",
      driver_name: "",
      vehicle: "",
      number_of_package: "",
      status: "",
      total_package: booking?.booking?.contract?.cargoList?.reduce((sum, cargo) => sum + (+cargo.numPackages), 0),
      ...Object.keys(originDateSelector).reduce((ele, key) => {
        ele[key] = "";
        return ele;
      }, {}),
    };
    setOriginError([...originError, VEHICLE_ROW_ERROR_DEFAULT]);
    setOrgin([...origin, o]);
  };

  const del1 = (id) => {
    let l = [];
    for (let i = 0; i < origin.length; i++) {
      if (i != id) {
        l.push(origin[i]);
      }
    }
    setOrgin(l);
    let err = [];
    for (let i = 0; i < originError.length; i++) {
      if (i != id) {
        err.push(originError[i]);
      }
    }
    setOriginError([...err]);
  };

  const del2 = (id) => {
    let l = [];
    for (let i = 0; i < des.length; i++) {
      if (i != id) {
        l.push(des[i]);
      }
    }
    setDes(l);
    let err = [];
    for (let i = 0; i < destinationError.length; i++) {
      if (i != id) {
        err.push(destinationError[i]);
      }
    }
    setDestinationError([...err]);
  };

  const add2 = (e) => {
    e.preventDefault()
    let b = booking?.booking
    let destination_origin = ""
    if(b?.contract?.finalPlaceOfDelivery.trim().length==0){
        destination_origin = b?.contract?.portOfDischarge
    }else{
        destination_origin = b?.contract?.finalPlaceOfDelivery
    }
    if(des && des.length > 0){
      let d = {
        origin: destination_origin,
        destination: booking.destination,
        driver_number: "",
        driver_name: "",
        vehicle: "",
        number_of_package: "",
        status: "",
        total_package: booking?.booking?.contract?.cargoList?.reduce((sum, cargo) => sum + (+cargo.numPackages), 0),
        ...Object.keys(destinationDateSelector).reduce((ele, key) => {
          ele[key] = "";
          return ele;
        }, {}),
      };
      setDestinationError([...destinationError, VEHICLE_ROW_ERROR_DEFAULT]);
      setDes([...des, d]);
    }
  }
  
  const addMultiDestination = (destinationIndex) => {
    let b = booking?.booking
    let destination_origin = ""
    if(b?.contract?.finalPlaceOfDelivery.trim().length==0){
        destination_origin = b?.contract?.portOfDischarge
    }else{
        destination_origin = b?.contract?.finalPlaceOfDelivery
    }
    let multipleDestination = {
      origin: destination_origin,
      destination: multipleDes[destinationIndex][0].destination,
      driver_number: "",
      driver_name: "",
      vehicle: "",
      number_of_package: "",
      status: "",
      total_package: multipleDes[destinationIndex][0].total_package,
      ...Object.keys(destinationDateSelector).reduce((selector, key) => {
        selector[key] = "";
        return selector;
      }, {}),
    };
   
    setMultipleDes((prevMultipleDes) => {
      return prevMultipleDes.map((subArray, index) => {
        if (index === destinationIndex) {
          return [...subArray, multipleDestination];
        }
        return subArray;
      });
    });
    

    let validation = [...multidestError];
    validation[destinationIndex].push(VEHICLE_ROW_ERROR_DEFAULT)
    setMultiDestError(validation);
  }

  const removeMultiDestination = (destinationArray, index) => {
    let updatedDestination = [...multipleDes]
    updatedDestination[destinationArray] = updatedDestination[destinationArray].filter((_, i) => i !== index);
    setMultipleDes(updatedDestination);

    let validation = [...multidestError]
    validation[destinationArray] = validation[destinationArray].filter((_, i) => i !== index);
    setMultiDestError(validation);
  }

  let formRows;
  if (booking?.booking?.originCount === "India") {
    formRows = origin;
  }
  if ((booking?.booking?.destinationCount === "India" && multidest?.length === 1) ||
  booking?.booking?.destinationCount === "India" && multidest === undefined) {
    formRows = des;
  }
  if (booking?.booking?.destinationCount === "India" && multidest?.length > 1) {
    formRows = multipleDes;
  }

  

  const checkValidation = (route, key) =>{
    let formRows = route;
    let driverNumber = [];
      if(key === "origin"){
        origin.map((ele) => driverNumber.push(ele.driver_number))
      }
      if(key === "des"){
        des.map((ele) => driverNumber.push(ele.driver_number))
      }
      let uniquePhNumber = new Set(driverNumber);
      if(booking?.booking?.originCount === "India"){
        
      }
      function checValidNumber(phNumber){
        if(key === "origin"){
          if(booking?.booking?.originCount === "India"){
            return phNumber.length === 10;
          }else{
            return phNumber.length >= 7 && phNumber.length <= 15;
          }
        }
        if(key === "des"){
          if(booking?.booking?.destinationCount === "India"){
            return phNumber.length === 10;
          }else{
            return phNumber.length >= 7 && phNumber?.length <= 15;
          }
        }
      }
      let errorArr;
      
      if(key === "des" && (booking.shipmentType === SHIPMENT_TYPE.fcl_cfs_cfs || booking.shipmentType === SHIPMENT_TYPE.fcl_f_cfs)){
        let regex = /^[A-Z]{1,4}[0-9]{1,7}/;
        errorArr = formRows.map(obj => ({
          container_number_vehicle: obj.container_number_vehicle !== "" && regex.test(obj.container_number_vehicle) && obj.container_number_vehicle.length === 11,
          origin: obj.origin !== "",
          destination: obj.destination !== "",
          driver_number: checValidNumber(obj.driver_number) && driverNumber.length === uniquePhNumber.size,
          vehicle: obj.vehicle !== "" && obj.vehicle.length <= 20,
          number_of_package: (+formRows.reduce((sum, obj) => {
            return sum + (parseInt(obj.number_of_package) || 0);
          }, 0)) <= (+obj.total_package)
        }))
      }else {
        errorArr = formRows.map(obj => ({
          origin: obj.origin !== "",
          destination: obj.destination !== "",
          driver_number: booking.shipmentType === SHIPMENT_TYPE.lcl ? obj.driver_number !== "" ? 
          obj.driver_number.length === 10 && driverNumber.length === uniquePhNumber.size : true : checValidNumber(obj.driver_number) &&  driverNumber.length === uniquePhNumber.size,
          vehicle: booking.shipmentType === SHIPMENT_TYPE.lcl ? obj.vehicle.length <= 20 : obj.vehicle !== ""  && obj.vehicle.length <= 20,
          number_of_package: (+formRows.reduce((sum, obj) => {
            return sum + (parseInt(obj.number_of_package) || 0);
          }, 0)) <= (+obj.total_package)
        }))
      }
      if(key === "origin"){
        setOriginError(errorArr)
      }
      if(key === "des"){
        setDestinationError(errorArr)
      } 
      return formRows.length > 0 && errorArr.every(obj => 
        Object.values(obj).every(value => value === true)
      );
  }

  function checkMultiDestinationValid() {
    if (multidest?.length > 1) {
      let driverNumber = [];
      multipleDes.map((row) => row.map((ele) => driverNumber.push(ele.driver_number) ));
      let uniquePhNumber = new Set(driverNumber);
      const regex = /^[A-Z]{1,4}[0-9]{1,7}$/;
      const mapInnerArray = (innerArray, includeContainer) => {
        return innerArray.map((obj) => {
          const baseValidation = {
            origin: obj.origin !== "",
            destination: obj.destination !== "",
            driver_number: booking.shipmentType === SHIPMENT_TYPE.lcl ? obj.driver_number !== "" ? obj.driver_number.length === 10 && driverNumber.length === uniquePhNumber.size : true 
            : obj.driver_number.length === 10 && driverNumber.length === uniquePhNumber.size,
            vehicle: booking.shipmentType === SHIPMENT_TYPE.lcl ? obj.vehicle.length <= 20 :  obj.vehicle !== "" && obj.vehicle.length <= 20,
            number_of_package:
              innerArray.reduce((sum, obj) => {
                return sum + (parseInt(obj.number_of_package) || 0);
              }, 0) <= +obj.total_package,
          };
  
          if (includeContainer) {
            return {
              ...baseValidation,
              container_number_vehicle:
                obj.container_number_vehicle !== "" &&
                regex.test(obj.container_number_vehicle) &&
                obj.container_number_vehicle.length === 11,
            };
          }
          return baseValidation;
        });
      };
  
      const includeContainer =
        booking.shipmentType === "FCL (Dock/Dock)" ||
        booking.shipmentType === "FCL (Factory/Dock)";
  
      const errorArr = multipleDes.map((innerArray, index) =>{
        if(innerArray.length === 1){
          return innerArray.every(obj => {
            return Object.keys(obj).every(key => {
              if (key === "origin" || key === "destination" || key === "total_package") {
                return true;
              }
              return obj[key] === "";
            });
          }) ? multidestError[index].map(obj =>
            Object.fromEntries(Object.keys(obj).map(key => [key, true]))
        ) : mapInnerArray(innerArray, includeContainer)
        }else{
          return mapInnerArray(innerArray, includeContainer)
        }
      });
      setMultiDestError(errorArr)
      return errorArr.every(innerArray =>
        innerArray.every(obj =>
          Object.values(obj).every(value => value === true)
        )
      );
    }
    return false;
  }

  const checkNumPackage = (route, key) => {
    let formRows = route;
    const errorArr = formRows.map(obj => ({
      number_of_package: (+formRows.reduce((sum, obj) => {
        return sum + (parseInt(obj.number_of_package) || 0);
      }, 0)) <= (+obj.total_package)
    }));
    if (key === "origin") {
      setOriginError(errorArr);
    }
    if (key === "des") {
      setDestinationError(errorArr);
    }
    return formRows.length > 0 && errorArr.every(obj => obj.number_of_package === true);
  };

  function checkMultiDestinationNumpackage() {
    if (multidest?.length > 1) {
      const errorArr = multipleDes.map((innerArray) => {
        return innerArray.map((obj) => ({
          number_of_package:
            innerArray.reduce((sum, obj) => {
              return sum + (parseInt(obj.number_of_package) || 0);
            }, 0) <= +obj.total_package,
        }));
      });
  
      setMultiDestError(errorArr);
  
      return errorArr.every((innerArray) =>
        innerArray.every((obj) => obj.number_of_package === true)
      );
    }
    return false;
  }

  function checkDestination(){
    let checkDestination;
    if(booking?.booking?.destinationCount !== "India"){
      checkDestination = checkNumPackage(des, "des");
    }else{
      if(des.length === 1){
        checkDestination = des.every(obj => {
          return Object.keys(obj).every(key => {
            if (key === "origin" || key === "destination" || key === "total_package") {
              return true;
            }
            return obj[key] === "";
          });
        }) ? true : checkValidation(des, "des")
      }else{
        checkDestination = checkValidation(des, "des");
      }
    }
    return checkDestination;
  }

  function checkOrigin(){
    let checkOrigin;
    if(booking?.booking?.originCount !== "India"){
      checkOrigin = checkNumPackage(origin, "origin");;
    }else{
      if(origin.length === 1){
        checkOrigin = origin.every(obj => {
          return Object.keys(obj).every(key => {
            if (key === "origin" || key === "destination" || key === "total_package") {
              return true;
            }
            return obj[key] === "";
          });
        }) ? true : checkValidation(origin, "origin")
      }else{
        checkOrigin = checkValidation(origin, "origin");
      }
    }
    return checkOrigin;
  }

  const onsubmit = () => {
    console.log("2409LCLCEN05710016 onsubmit-------------->", formRows)

    let isVehicleRowError;
    if(sc == SHIPMENT_SCOPE.d2d || sc == SHIPMENT_SCOPE.p2d){
      if(st == SHIPMENT_TYPE.fcl_cfs_f){
        if(origin.length > 0){
          isVehicleRowError = checkOrigin(); 
        }
      }
      if(st == SHIPMENT_TYPE.fcl_f_cfs){
        if(des.length > 0 || multipleDes.length > 0){
          let checkDes = checkDestination();
          let multipleDestination = booking?.booking?.destinationCount !== "India" 
          ? checkMultiDestinationNumpackage() : checkMultiDestinationValid()
          isVehicleRowError = checkDes || multipleDestination;
        }
      }
      if(origin.length > 0 && des.length > 0){
        let checkOrg = checkOrigin();
        let checkDes = checkDestination();
        isVehicleRowError = checkOrg && checkDes;
      }
      if(origin.length > 0 && multipleDes.length > 0){
        let checkOrg = checkOrigin();
        let multipleDestination = booking?.booking?.destinationCount !== "India" 
        ? checkMultiDestinationNumpackage() : checkMultiDestinationValid();
        isVehicleRowError = checkOrg && multipleDestination;
      }
    }
    if(sc == SHIPMENT_SCOPE.d2p){
      if(origin.length > 0 || des.length > 0){
        let checkOrg = checkOrigin();
        let checkDes = checkDestination();
        isVehicleRowError = checkOrg || checkDes;
      }
    }
    if(sc == SHIPMENT_SCOPE.p2d){ 
      if(des.length > 0 || multipleDes.length > 0){
        let checkDes = checkDestination();
        let multipleDestination = booking?.booking?.destinationCount !== "India" 
        ? checkMultiDestinationNumpackage() : checkMultiDestinationValid();
        isVehicleRowError = checkDes || multipleDestination;
      }
    }
    if (isVehicleRowError) {
      if (origin && origin.length > 0) {
        ret.vorgin = origin;
      } 
      if(des && des.length > 0){
        ret.vdes = des;
      }
      
      if ((multipleDes && multipleDes.length > 0)) {
        for (let i = 0; i < multipleDes.length; i++) {
          if(i==0){
            ret.vdes = multipleDes[i]
          }else{
            let l = i+1
            ret['vdes'+l] = multipleDes[i]
          }
        }
      }
      console.log("ret---------->", ret.vorgin)
      console.log("ret---------->", ret.vdes)
      console.log("ret---------->", ret)
      myactionsstore.vModalVisible = false;
    } 
    else {
      configStore.setModalMessage("Please check all fields");
    }
  };
  function convertDateFormat(dateStr) {
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (dateFormatRegex.test(dateStr)) {
        return dateStr;
    }
    if (!dateStr) return "";
    const [day, month, year] = dateStr.split("-");
    return `${year}-${month}-${day}`;
  }
  let modalClass = "modal";
  if (myactionsstore.vModalVisible) modalClass += " is-active";

  const updateOrigin = (index, key, value) => {
    if (key === "number_of_package" && (value <= 0 || /[-eE]/.test(value))) {
      value = "";
    }
    const updatedOrigin = origin.map((item, idx) =>
      idx === index ? { ...item, [key]: value } : item
    );
    setOrgin(updatedOrigin);
  };

  const updateDestination = (index, key, value) => {
    if (key === "number_of_package" && (value <= 0 || /[-eE]/.test(value))) {
      value = "";
    }
    const updatedDestination = des.map((item, idx) =>
      idx === index ? { ...item, [key]: value } : item
    );
    setDes(updatedDestination);
  };

  const updateMultiplDes = (outerIndex, innerIndex, key, value) => {
    if (key === "number_of_package" && (value <= 0 || /[-eE]/.test(value))) {
      value = "";
    }
    const updatedMultipleDes = multipleDes.map((outerItem, oIdx) =>
      oIdx === outerIndex 
        ? outerItem.map((item, iIdx) =>
            iIdx === innerIndex
              ? { ...item, [key]: value } : item
          )
        : outerItem
    );
    setMultipleDes(updatedMultipleDes);
  };
  console.log("2409LCLCEN05710016 origin", origin, originError)
  console.log("2409LCLCEN05710016 des", des, destinationError)
  console.log("2409LCLCEN05710016 multipleDes", multipleDes, multidestError)
  console.log("2409LCLCEN05710016 originDisable", originDisable)
  console.log("2409LCLCEN05710016 destinationDisable", destinationDisable)
  return (
    <>
        
        <div className={modalClass}>
          <div className="modal-background"></div>
          <div
            className="modal-card"
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{ borderRadius: "10px", width: "95%" }}
          >
            <section className="modal-card-body">
              <div
                className="table-container"
                onClick={(e) => e.stopPropagation()}
              >
                {(sc == SHIPMENT_SCOPE.d2p || sc == SHIPMENT_SCOPE.d2d) &&
                st != SHIPMENT_TYPE.fcl_f_cfs && origin && origin.length > 0 ? (
                  <>
                    <div>
                      <div class="px-5 py-2 flex align-center justify-between">
                        <p class="text-base font-semibold">Origin Pick-up</p>
                        <div>
                          <div class="flex align-center">
                            <p class="text-base font-semibold">
                              Total No. of Packages: {(+origin[0].total_package)}
                            </p>
                          </div>
                        </div>
                        <div></div>
                      </div>
                      {origin.map((e, i) => {
                        return (
                          <div
                            id={i + "0"}
                            key={i + "0"}
                            class="mb-5 p-5 bg-custom-gray rounded-md"
                          >
                            <div class="flex justify-between">
                              <div class="flex align-center">
                              </div>
                              <div class="flex align-center">
                                <p class="text-sm font-semibold">
                                  Vehicle {i + 1}
                                </p>
                              </div>
                              <div class="flex">
                                {origin.length > 1 ? (
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault()
                                      del1(i);
                                    }}
                                    className="ml-2 button is-danger is-inverted"
                                  >
                                    <span className="icon">
                                      <i className="fas fa-trash"></i>
                                    </span>
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <div class="flex py-2">
                              <div class="flex w-3/5">
                                <div class="w-2/4 px-4">
                                  <p class="text-sm">Origin*</p>
                                  <OneInput5table
                                    disabled={true}
                                    name="origin"
                                    isValid={originError?.[i]?.origin}
                                    record={JSON.parse(JSON.stringify(e))}
                                    label="Origin"
                                    errorMsg="Origin is required"
                                    onChange={(key, value) => updateOrigin(i, key, value)}
                                  />
                                </div>
                                <div class="w-2/4 px-4">
                                  <p class="text-sm">Destination*</p>
                                  <OneInput5table
                                    name="destination"
                                    isValid={originError?.[i]?.destination}
                                    record={JSON.parse(JSON.stringify(e))}
                                    label="Destination"
                                    errorMsg="Destination is required"
                                    onChange={(key, value) => updateOrigin(i, key, value)}
                                  />
                                </div>
                              </div>
                              <div class="flex w-2/5">
                                <div class="w-2/4 px-4">
                                  <p class="text-sm">Vehicle Number{`${booking.shipmentType !== SHIPMENT_TYPE.lcl ? '*' : ''}`}</p>
                                  <OneInput5table
                                    name="vehicle"
                                    isValid={originError?.[i]?.vehicle}
                                    record={JSON.parse(JSON.stringify(e))}
                                    label="Vehicle#"
                                    errorMsg={`${booking.shipmentType !== SHIPMENT_TYPE.lcl ? 'Vehicle is required' : ''}`}
                                    onChange={(key, value) => updateOrigin(i, key, value)}
                                  />
                                </div>
                                <div class="w-2/4 px-4">
                                  <p class="text-sm">No. of Packages*</p>
                                  <OneInput5table
                                    disable={originDisable?.[i]}
                                    name="number_of_package"
                                    isValid={
                                      originError?.[i]?.number_of_package
                                    }
                                    record={JSON.parse(JSON.stringify(e))}
                                    label="Number Of Package"
                                    errorMsg="Sum of vehicle packages should be equal to total No.of Packages"
                                    onChange={(key, value) => updateOrigin(i, key, value)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="flex px-2 py-2">
                              <div class="w-full px-2">
                                <p class="text-sm">Driver Number{`${booking.shipmentType !== SHIPMENT_TYPE.lcl ? '*' : ''}`}</p>
                                <OneInput5table
                                  name="driver_number"
                                  isValid={originError?.[i]?.driver_number}
                                  record={JSON.parse(JSON.stringify(e))}
                                  label="Driver Number"
                                  errorMsg="Must be Unique & Enter Valid Phone Number"
                                  onChange={(key, value) => updateOrigin(i, key, value)}
                                />
                              </div>
                              <div class="w-full p  x-2">
                                <p class="text-sm">Driver Name</p>
                                <OneInput5table
                                  name="driver_name"
                                  record={JSON.parse(JSON.stringify(e))}
                                  label="Driver Name"
                                  onChange={(key, value) => updateOrigin(i, key, value)}
                                />
                              </div>
                              {Object.entries(originDateSelector).map(
                                ([key, value], index) => (
                                  <div className="w-full px-2" key={index}>
                                    <p className="text-sm">{value}</p>
                                    <input
                                      disabled= {shipmentYettoStart && updatedFreezeFieldsRef.current.includes(key)}
                                      type="date"
                                      value={convertDateFormat(e[key])}
                                      className="border-solid border w-full rounded-md p-1.5"
                                      id={key}
                                      name={key}
                                      onChange={(event) => {
                                          const newOrigin = origin.map((item, idx) =>
                                            idx === i ? { ...item, [key]: event.target.value } : item
                                          );
                                          setOrgin(newOrigin);
                                      }}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        );
                      })}

                      <div class="mt-2 flex justify-end">
                        <button
                          onClick={add1}
                          class="w-32 h-9 border-0 border-slate-800 bg-custom-gray px-4 py-2 border-transparent text-black font-semibold rounded-md"
                        >
                          + Add Vehicle
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {(sc == SHIPMENT_SCOPE.p2d || sc == SHIPMENT_SCOPE.d2d) && 
                st != SHIPMENT_TYPE.fcl_cfs_f && des && des.length > 0 ? (
                  <>
                    <div>
                    <div class="px-5 py-2 flex align-center justify-between">
                        <p class="text-base font-semibold">Destination Delivery</p>
                        <div>
                          <div class="flex align-center">
                            <p class="text-base font-semibold">
                              Total No. of Packages: {(+des[0].total_package)}
                            </p>
                          </div>
                        </div>
                        <div></div>
                      </div>
                      { des && des.length > 0 ? des.map((e, i) => {
                        return (
                          <div class="mb-5 p-5 bg-custom-gray rounded-md">
                            <div class="flex justify-between">
                              <div class="flex align-center">
                              </div>
                              <div class="flex align-center">
                                <p class="text-sm font-semibold">
                                  Vehicle {i + 1}
                                </p>
                              </div>
                              <div class="flex">
                                {des.length > 1 ? (
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      del2(i)
                                    }}
                                    className="ml-2 button is-danger is-inverted"
                                  >
                                    <span className="icon">
                                      <i className="fas fa-trash"></i>
                                    </span>
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <div class="flex py-2">
                              <div class="flex w-3/5">
                                <div class="w-2/4 px-4">
                                  <p class="text-sm">Origin*</p>
                                  <OneInput5table
                                    name="origin"
                                    isValid={destinationError[i].origin}
                                    record={JSON.parse(JSON.stringify(e))}
                                    label="Origin"
                                    errorMsg="Origin is required"
                                    onChange={(key, value) => updateDestination(i, key, value)}
                                  />
                                </div>
                                <div class="w-2/4 px-4">
                                  <p class="text-sm">Destination*</p>
                                  <OneInput5table
                                    disabled={true}
                                    name="destination"
                                    isValid={destinationError[i].destination}
                                    record={JSON.parse(JSON.stringify(e))}
                                    label="Destination"
                                    errorMsg="Destination is required"
                                    onChange={(key, value) => updateDestination(i, key, value)}
                                  />
                                </div>
                                { (booking.shipmentType === SHIPMENT_TYPE.fcl_cfs_cfs || booking.shipmentType === SHIPMENT_TYPE.fcl_f_cfs) &&
                                  <div class="w-2/4 px-4">
                                    <p class="text-sm">Container Number*</p>
                                    <OneInput5table
                                      name="container_number_vehicle"
                                      isValid={destinationError[i].container_number_vehicle}
                                      record={JSON.parse(JSON.stringify(e))}
                                      label="Container Number"
                                      errorMsg="Container Number is required"
                                      onChange={(key, value) => updateDestination(i, key, value)}
                                    />
                                </div>
                                }
                              </div>
                              <div class="flex w-2/5">
                                <div class="w-2/4 px-4">
                                  <p class="text-sm">Vehicle Number{`${booking.shipmentType !== SHIPMENT_TYPE.lcl ? '*' : ''}`}</p>
                                  <OneInput5table
                                    name="vehicle"
                                    isValid={destinationError[i].vehicle}
                                    record={JSON.parse(JSON.stringify(e))}
                                    label="Vehicle#"
                                    errorMsg="Vehicle is required"
                                    onChange={(key, value) => updateDestination(i, key, value)}
                                  />
                                </div>
                                <div class="w-2/4 px-4">
                                  <p class="text-sm">No. of Packages*</p>
                                  <OneInput5table
                                    disable={destinationDisable?.[i]}
                                    name="number_of_package"
                                    isValid={destinationError[i].number_of_package}
                                    record={JSON.parse(JSON.stringify(e))}
                                    label="Number Of Package"
                                    errorMsg="Sum of vehicle packages should be equal to total No.of Packages"
                                    onChange={(key, value) => updateDestination(i, key, value)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="flex px-2 py-2">
                              <div class="w-full px-2">
                                <p class="text-sm">Driver Number{`${booking.shipmentType !== SHIPMENT_TYPE.lcl ? '*' : ''}`}</p>
                                <OneInput5table
                                  name="driver_number"
                                  isValid={destinationError[i].driver_number}
                                  record={JSON.parse(JSON.stringify(e))}
                                  label="Driver Number"
                                  errorMsg="Must be Unique & Enter Valid Phone Number"
                                  onChange={(key, value) => updateDestination(i, key, value)}
                                />
                              </div>
                              <div class="w-full p  x-2">
                                <p class="text-sm">Driver Name</p>
                                <OneInput5table
                                  name="driver_name"
                                  record={JSON.parse(JSON.stringify(e))}
                                  label="Driver Name"
                                  onChange={(key, value) => updateDestination(i, key, value)}
                                />
                              </div>
                              {Object.entries(destinationDateSelector).map(
                                ([key, value], index) => (
                                  <div className="w-full px-2" key={index}>
                                    <p className="text-sm">{value}</p>
                                    <input
                                      disabled= {shipmentYettoStart && updatedFreezeFieldsRef.current.includes(key)}
                                      type="date"
                                      value={convertDateFormat(e[key])}
                                      className="border-solid border w-full rounded-md p-1.5"
                                      id={key}
                                      name={key}
                                      onChange={(event) => {
                                        const newdes = des.map((item, idx) =>
                                          idx === i ? { ...item, [key]:  event.target.value } : item
                                        );
                                        setDes(newdes)
                                      }}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        )
                      }) : <></>}
                      <div class="mt-2 flex justify-end">
                        <button
                          onClick={add2}
                          class="w-32 h-9 border-0 border-slate-800 bg-custom-gray px-4 py-2 border-transparent text-black font-semibold rounded-md"
                        >
                          + Add Vehicle
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {(sc == SHIPMENT_SCOPE.p2d || sc == SHIPMENT_SCOPE.d2d) && st != SHIPMENT_TYPE.fcl_cfs_f
                && multipleDes && multipleDes.length > 0 ? multipleDes.map((ele, index) =>{
                  return (
                    <div key={index + "1"}>
                      <div class="px-5 py-2 grid grid-cols-3 items-center w-full">
                        <p class="text-base font-semibold">
                          Destination Delivery: {index + 1} 
                        </p>
                          <p class="text-base font-semibold col-start-2 col-span-1 text-center">
                            Total No. of Packages: 
                            {(+multipleDes[index][0].total_package)}
                          </p>
                      </div>
                      {
                        ele.map((e, i) => {
                          return(
                            <div key={i+"11"}>
                              <div class="mb-5 p-5 bg-custom-gray rounded-md">
                                <div class="flex justify-between">
                                  <div class="flex align-center">
                                  </div>
                                  <div class="flex align-center">
                                    <p class="text-sm font-semibold">
                                      Vehicle {i + 1}
                                    </p>
                                  </div>
                                  <div class="flex">
                                    {ele && ele.length > 1 ? (
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          removeMultiDestination(index, i);
                                        }}
                                        className="ml-2 button is-danger is-inverted"
                                      >
                                        <span className="icon">
                                          <i className="fas fa-trash"></i>
                                        </span>
                                      </button>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                                <div class="flex py-2">
                                  <div class="flex w-3/5">
                                    <div class="w-2/4 px-4">
                                      <p class="text-sm">Origin*</p>
                                      <OneInput5table
                                        name="origin"
                                        isValid={multidestError[index][i].origin}
                                        record={JSON.parse(JSON.stringify(e))}
                                        label="Origin"
                                        errorMsg="Origin is required"
                                        onChange={(key, value) => updateMultiplDes(index, i, key, value)}
                                      />
                                    </div>
                                    <div class="w-2/4 px-4">
                                      <p class="text-sm">Destination*</p>
                                      <OneInput5table
                                        disabled={true}
                                        name="destination"
                                        isValid={multidestError[index][i].destination}
                                        record={JSON.parse(JSON.stringify(e))}
                                        label="Destination"
                                        errorMsg="Destination is required"
                                        onChange={(key, value) => updateMultiplDes(index, i, key, value)}
                                      />
                                    </div>
                                    { (booking.shipmentType === SHIPMENT_TYPE.fcl_cfs_cfs || booking.shipmentType === SHIPMENT_TYPE.fcl_f_cfs) &&
                                      <div class="w-2/4 px-4">
                                        <p class="text-sm">Container Number*</p>
                                        <OneInput5table
                                          name="container_number_vehicle"
                                          isValid={multidestError[index][i].container_number_vehicle}
                                          record={JSON.parse(JSON.stringify(e))}
                                          label="Container Number"
                                          errorMsg="Container Number is required"
                                          onChange={(key, value) => updateMultiplDes(index, i, key, value)}
                                        />
                                      </div>
                                    }
                                  </div>
                                  <div class="flex w-2/5">
                                    <div class="w-2/4 px-4">
                                      <p class="text-sm">Vehicle Number{`${booking.shipmentType !== SHIPMENT_TYPE.lcl ? '*' : ''}`}</p>
                                      <OneInput5table
                                        name="vehicle"
                                        isValid={multidestError[index][i].vehicle}
                                        record={JSON.parse(JSON.stringify(e))}
                                        label="Vehicle#"
                                        errorMsg="Vehicle is required"
                                        onChange={(key, value) => updateMultiplDes(index, i, key, value)}
                                      />
                                    </div>
                                    <div class="w-2/4 px-4">
                                      <p class="text-sm">No. of Packages*</p>
                                      <OneInput5table
                                        disable={destinationDisable?.[index]?.[i]}
                                        name="number_of_package"
                                        isValid={ multidestError[index][i].number_of_package }
                                        record={JSON.parse(JSON.stringify(e))}
                                        label="Number Of Package"
                                        errorMsg="Sum of vehicle packages should be equal to total No.of Packages"
                                        onChange={(key, value) => updateMultiplDes(index, i, key, value)}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="flex px-2 py-2">
                                  <div class="w-full px-2">
                                    <p class="text-sm">Driver Number{`${booking.shipmentType !== SHIPMENT_TYPE.lcl ? '*' : ''}`}</p>
                                    <OneInput5table
                                      name="driver_number"
                                      isValid={multidestError[index][i].driver_number}
                                      record={JSON.parse(JSON.stringify(e))}
                                      label="Driver Number"
                                      errorMsg="Must be Unique & Enter Valid Phone Number"
                                      onChange={(key, value) => updateMultiplDes(index, i, key, value)}
                                    />
                                  </div>
                                  <div class="w-full p  x-2">
                                    <p class="text-sm">Driver Name</p>
                                    <OneInput5table
                                      name="driver_name"
                                      record={JSON.parse(JSON.stringify(e))}
                                      label="Driver Name"
                                      onChange={(key, value) => updateMultiplDes(index, i, key, value)}
                                    />
                                  </div>
                                  {Object.entries(destinationDateSelector).map(
                                    ([key, value], destinationDateSelectorIndex) => (
                                      <div className="w-full px-2" key={destinationDateSelectorIndex}>
                                        <p className="text-sm">{value}</p>
                                        <input
                                          disabled= {ret?.booking?.status === "SUBMITTED" && updatedFreezeFieldsRef.current.includes(key)}
                                          type="date"
                                          value={convertDateFormat(e[key])}
                                          className="border-solid border w-full rounded-md p-1.5"
                                          id={key}
                                          name={key}
                                          onChange={(event) => {
                                            const newdes = multipleDes.map((outerItem, oIdx) =>
                                              oIdx === index 
                                                ? outerItem.map((item, iIdx) =>
                                                    iIdx === i
                                                      ? { ...item, [key]:  event.target.value }
                                                      : item
                                                  )
                                                : outerItem
                                            );
                                            setMultipleDes(newdes)
                                          }}
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                      <div class="mt-2 mb-2 flex justify-end">
                        <button
                          onClick={(e) => {
                            e.preventDefault(); 
                            addMultiDestination(index)
                          }}
                          class="w-32 h-9 border-0 border-slate-800 bg-custom-gray px-4 py-2 border-transparent text-black font-semibold rounded-md"
                        >
                          + Add Vehicle
                        </button>
                      </div>
                    </div>
                  )
                })
                : <></> }
                <div class="mt-5 flex justify-end">
                  <button
                    class="w-32 mr-3 h-9 text-blue border-0 border-slate-800 bg-custom-gray px-4 py-2 border-transparent text-black font-semibold rounded-md"
                    onClick={() => {
                      myactionsstore.vModalVisible = false;
                      setOrgin([])
                      setDes([])
                      setMultipleDes([])
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    class="w-32 ml-3 h-9 text-blue bg-blue border-0 border-slate-800 bg-custom-gray px-4 py-2 border-transparent text-black font-semibold rounded-md"
                    onClick={(e) => {
                      e.preventDefault();
                      onsubmit()
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* { mapVisible && <DtMap /> } */}
    </>
  );
  };

  
