import { useEffect, useState } from "react"
import MonApi from "../state/mongo-api"
import { loader } from "../util/loader"

export default function PowerbiPage(props) {
    const [list,setList] = useState([])
    const [showName,setShowName] = useState("")
    const [showLink,setShowLink] = useState("")
    let url = `${process.env.REACT_APP_API_SERVER}/api/v1/powerbi`
    const getlist = async () =>{
        loader.show()
        let ret = await MonApi.aget(url)
        if(JSON.stringify(ret)!=JSON.stringify(list)){
            setList(ret)
        }
        loader.hide()
    }
    useEffect(()=>{
        getlist()
    },[])

    return(
        <>
            {
                (list.length==0)?<div class="box mt-5"><div class="columns is-multiline is-vcentered">No PowerBI List</div></div>:
                <div class="columns is-multiline is-vcentered has-text-centered">
                    {
                        list.map(e=>{
                            return <div class="column is-4">
                                <div class="box m-5">
                                    <p class="has-text-weight-bold">{e.dashboard}</p>
                                    <hr />
                                    <button class="button is-link" onClick={()=>{
                                        setShowLink(e.link)
                                        setShowName(e.dashboard)
                                    }}>Show</button>
                                </div>
                            </div>
                        })
                    }
                </div>
            }
            {
                (showName.trim().length>0)?<div class="modal is-active" style={{zIndex:1000000}}>
                    <div class="modal-background"></div>
                    <div class="modal-card" style={{width:"90%"}}>
                        <header class="modal-card-head">
                        <p class="modal-card-title">{showName}</p>
                        <button class="delete" aria-label="close" onClick={()=>{
                            setShowName("")
                            setShowLink("")
                        }}></button>
                        </header>
                        <section class="modal-card-body">
                        <iframe title={showName} width="100%" height="1060" src={showLink} frameborder="0" allowFullScreen="true"></iframe>
                        </section>
                    </div>
                </div>:""
            }
        </>
    )
}