import { useCallback, useEffect, useRef, useState } from "react";
import { ViewBox } from "../tailwind-components/ViewBox";
import { useFieldArray, useForm } from "react-hook-form";
import { ShippingContextProvider, useShipping } from "../context/ShippingContext";
import LabeledTextField from "../mui-components/LabeledTextField";
import { CenteredCircularProgress } from "../mui-components/CircularProgress";
import { rtpStore } from "../pricing/pricing-store";
import BranchApi from "../state/branch-api";
import destinationIcon from "../assets/images/destinationimg.svg";
import downloadIcon from "../assets/images/downloadicon.svg";
import { EntityV2Store, bookingsStore, breadCrumbStore, configStore, contractsStore, enquiriesValidationStore, entityStore, filtersState, newDashboardState} from "../state/CommonState";
import { isArray } from "lodash";
import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { AgMuiSelect2 } from "../mui-components/Controlled";
import Utils from "../util/Utils";
import ShipperApi from "../state/shipper-api";
import { Country, State } from "country-state-city";
import AgAutoComplete from "../mui-components/AgAutoComplete";
import EntityV2Api from "../state/entities-api";
import { MuiPopup } from "../mui-components/MuiPopUp";
import { commodityList, hazardousList, incoTermsList } from "../StaticData";
import { ChooseFile } from "../mui-components/ChooseFile";
import Api from "../state/Api";
import { useSnapshot } from "valtio";
import { SelectedRadio, UnSelectedRadio } from "../tailwind-components/CustomRadioButton";
import paynow from "../assets/images/paynow.svg";
import paylater from "../assets/images/paylater.svg";
import arrow from "../assets/images/actionarrow.svg";
import BookingsApi from "../state/bookings-api";
import { CopyText } from "../mui-components/CopyText";
import { TruckLoader, truckLoader } from "../mui-components/TruckLoader";
import { CustomPopup } from "../tailwind-components/CustomPopup";
import { enquiryStore } from "../enquiry/enquiry-data";
import { taskStore } from "../mytasks/task-store";
import Taskapi, { Task } from "../mytasks/tasks-api";
import { calcCargoDetails } from "../CargoComp";
import { AntSwitch } from "../mui-components/AntSwitch";
import MblConfirmationPopup from "../enquiryv3/mblConfirmationPopup";
import { FbaDetails } from "../mui-components/FbaDetails";
const labelStyle = {fontSize:"12px",fontWeight:'bold',color:"black"}

const consigneeDetailsDefault = {
    _id:"",
    companyName: "",
    address: "",
    city: "",
    country: "",
    pincode: "",
    state: "",
    firstName: "",
    lastName: "",
    emailId: "",
    mobileNumber: "",
    linkedEntity:"",
    newShipper : true
}

const shippingStages = [
    {
        title: "Shipper/ Consignee Details",
        component: <ShipperConsigneeDetails/>
    },
    {
        title: "Shipping Details",
        component: <ShippingDetails/>
    },
    {
        title: "Upload Documents",
        component: <UploadDocuments/>,
        buttonName : "Next",
        handler:(brec)=>{

           if(brec.commercialInvoice_filename_list?.length>0 && brec.packingList_filename_list?.length===0){
              configStore.setModalMessage('Packinglist is mandatory')
              return false;
           }

           if(brec.packingList_filename_list?.length>0 && brec.commercialInvoice_filename_list?.length===0){
            configStore.setModalMessage('Commercial Invoice is mandatory')
            return false;
         }

           if(brec.contract.hazardous === 'Yes' && brec.iipcert_filename?.length===0){
            configStore.setModalMessage("IIP Certificate is mandatory");
            return false;
           }
           return true;
        }
    },
    {
        title: "Invoicing & Payment",
        component: <InvoicingAndPayment/>
    },
];

function LabeledSelect({ label, control, name, options,rules,onChange,disable}) {
    return <Box sx={{ width: "100%",gap:"4px",display:"flex",flexDirection:"column" }}>
        <Typography sx={{ textAlign:"start",fontFamily:"Figtree",...labelStyle }}>{label}</Typography>
        <AgMuiSelect2 rules={rules} onChangeHandler={(value) => {
            if(onChange){
                onChange(value);
            }
        }} fontSize="12px" width="100%" style={{width:"100%"}}  fullwidth options={options} control={control} name={name} disabled={disable}    />
    </Box>
}

function uploadFile(file,updateFileState,updateFailureState,source,cat,fileType,callback,freezeButton){ 
    const formData = new FormData()
    formData.append('myFile', file)
    formData.append('source', source)
    formData.append('category', cat)
    formData.append('filetype', fileType)
    if(freezeButton){
        freezeButton(false)
    }
    fetch(`${process.env.REACT_APP_FILE_SERVER}/api/v1/common/receivefile`, {
     method: 'POST',
     headers: Api.H1(),
     body: formData
 })
     .then(response => response.json())
     .then(data => {
         console.log('got data', data)
         if (data.error) { if (data.error!='No Error' || data.error!='Invalid Token') { 
             if(updateFailureState){
                 updateFailureState();
            }
            if(freezeButton){
                freezeButton(true)
            }
         }}else {
             //console.log('setting the filelink',  `/clientfiles/${files[0].name}`)
             if(updateFileState){
                 updateFileState(()=>{
                    callback(data.filename)
                    if(freezeButton){
                        freezeButton(true)
                    }
                 });
             }
         }
     })
     .catch(error => {
         console.error(error)
     })
}

function UploadDocuments(){
    const store = useSnapshot(bookingsStore)
    const filecat = '' + Date.now();
    const {setMoveToNextStep} = useShipping()
    const brec = bookingsStore.current;
    console.log("the booking store updayedf is ",brec)
    const [filesToBeUploaded,setfilesForUpload] = useState([]);
    useEffect(()=>{
       let filesForUpload = [ <ChooseFile documentView={true} onFileDeleted={(file,onSuccess,onFailure)=>{
       let arr = brec.commercialInvoice_filename_list.filter(item => item !== file);
       brec.commercialInvoice_filename_list = arr;
       onSuccess();
       }} key={"commercialInvoice"+brec.commercialInvoice_filename_list?.length} data={brec.commercialInvoice_filename_list}   onFileSelected={(file,updateFileState,updateFailureState)=>{
        uploadFile(file,updateFileState,updateFailureState,"carrierwebform",filecat,"commercialInvoice",(filename)=>{
          brec.commercialInvoice_filename_list.push(filename)
        },setMoveToNextStep)
  }} direction="row" title="Upload Commercial Invoice"/>,
  <ChooseFile documentView={true} onFileDeleted={(file,onSuccess,onFailure)=>{
    let arr = brec.packingList_filename_list.filter(item => item !== file);
    brec.packingList_filename_list = arr;
    onSuccess();
    }}  key={"packingList"+brec.packingList_filename_list?.length} data={brec.packingList_filename_list}  onFileSelected={(file,updateFileState,updateFailureState)=>{
    uploadFile(file,updateFileState,updateFailureState,"carrierwebform",filecat,"packingList",(filename)=>{
      brec.packingList_filename_list.push(filename)
    },setMoveToNextStep)
}} direction="row" title="Upload Packaging List"/>];

if(((brec.commodityType && brec.commodityType.startsWith('Non-Hazardous Chemicals'))
    || (brec.contract.hazardous === 'Yes')) ){
        filesForUpload.push(<ChooseFile documentView={true} onFileDeleted={(file,onSuccess,onFailure)=>{
            brec.msds_filename = ""
            onSuccess();
            }} data={brec.msds_filename?.length>0 ? [brec.msds_filename] : []} key={"msds"+brec.msds_filename} onFileSelected={(file,updateFileState,updateFailureState)=>{
        uploadFile(file,updateFileState,updateFailureState,"carrierwebform",filecat,"msds",(filename)=>{
          brec.msds_filename = filename
        },setMoveToNextStep)
  }} direction="row" title="MSDS"/>)
    }

    if((brec.commodityType && brec.commodityType.startsWith('Non-Hazardous Chemicals'))){
        filesForUpload.push(<ChooseFile documentView={true} onFileDeleted={(file,onSuccess,onFailure)=>{
            brec.nondgdecl_filename = ""
            onSuccess();
            }}   data={brec.nondgdecl_filename?.length>0?[brec.nondgdecl_filename]:[]} key={"nondgdecl"+brec.nondgdecl_filenam} onFileSelected={(file,updateFileState,updateFailureState)=>{
            uploadFile(file,updateFileState,updateFailureState,null,filecat,"nondgdecl",(filename)=>{
              brec.nondgdecl_filename = filename
            },setMoveToNextStep)
      }} direction="row" title="Non-DG Declaration"/>)
    }
    if((brec.contract.hazardous === 'Yes')){
        filesForUpload.push(<ChooseFile documentView={true} ParentComponent={<a href="./Non_Hazardous_Declaration.doc" className="h-[40px] w-[200px] bg-blue-light border-0 rounded-[7px]">
            <div className="flex flex-row justify-center items-center h-full gap-2 text-white">
<img src={downloadIcon} />
            <p>Download Template</p>
            </div></a>} onFileDeleted={(file,onSuccess,onFailure)=>{
            brec.dgdecl_filename = ""
            onSuccess();
            }} data={ brec.dgdecl_filename?.length>0 ? [brec.dgdecl_filename] : []} key={"dgdecl"+brec.dgdecl_filename} onFileSelected={(file,updateFileState,updateFailureState)=>{
            uploadFile(file,updateFileState,updateFailureState,null,filecat,"dgdecl",(filename)=>{
              brec.dgdecl_filename = filename
            },setMoveToNextStep)
      }} direction="row" title="DG Declaration"/>);
      filesForUpload.push(<ChooseFile documentView={true}  onFileDeleted={(file,onSuccess,onFailure)=>{
        brec.iipcert_filename = ""
        onSuccess();
        }} data={brec.iipcert_filename?.length>0 ? [brec.iipcert_filename] : []} key={"iipcert"+brec.iipcert_filename} onFileSelected={(file,updateFileState,updateFailureState)=>{
        uploadFile(file,updateFileState,updateFailureState,null,filecat,"iipcert",(filename)=>{
          brec.iipcert_filename = filename
        },setMoveToNextStep)
  }} direction="row" title="IIP Certificate"/>)
    }
setfilesForUpload(filesForUpload)
    },[store])
   
  
    return <ViewBox title="List of Documents">
{filesToBeUploaded.map((component,i)=><div className={`flex items-center justify-center w-full min-h-[60px] ${i%2===0? "bg-[#F5F5F5]" : "bg-white"} px-3`}>
   {component}
</div>)}
    </ViewBox>
}

function ShippingDetails(){
    const {form} = useShipping();
    const {control,setValue,} = form;
    const [showConfirm, setShowConfirm] = useState(false);
    const [pendingValue, setPendingValue] = useState(false);
    const filecat = '' + Date.now();
    useSnapshot(bookingsStore);
    const enquiry = useSnapshot(enquiryStore);
    const brec = bookingsStore.current;
    const {contract} = breadCrumbStore.state
    const isFba = enquiryStore.current.fba === "Yes"
    const { fields: fbaList} = useFieldArray({
        control: control,
        name: `fbaList`
    })
    useEffect(() => {
       
        if (enquiry?.current?.commodityType) {
            setValue("commoditytype", enquiry.current.commodityType);
        }
    }, [setValue, enquiry?.current?.commodityType]); 

    useEffect(() => {
        if (enquiry?.current?.incoTerms) {
            setValue("incoterms", enquiry.current.incoTerms);
        }
    }, [setValue, enquiry?.current?.incoTerms]); 
    const handleToggle = (val) => {
        if (val.target.value === "on") {
            setPendingValue("Yes");
            setShowConfirm(true);
        } else {
            brec.carrierMBL = "No";
            setPendingValue("No");
        }
    };

    const confirmToggle = () => {
        brec.carrierMBL = "Yes";
        setShowConfirm(false);
    };

    const cancelToggle = () => {
        brec.carrierMBL = "No"
        setPendingValue("No");
        setShowConfirm(false);
    };   
   
    useEffect(()=>{
        if(contract.incoTerms?.length>0){
            enquiryStore.current.incoTerms = contract.incoTerms;
        }
        if(contract.commodityType?.length>0){
            enquiryStore.current.commodityType = contract.commodityType;
        }

        if(isFba && fbaList.length===0){
           let fbaLists = enquiry.current.multidest?.map((des)=>[{purchaseOrderID:"",fbaID:""}])
           setValue("fbaList",fbaLists)
        }
    },[])
console.log(" the fba list is ",fbaList);
    return <div className="flex flex-col p-4 bg-white rounded-lg w-full flex-grow gap-2">
        <div className="grid grid-cols-3 gap-2.5 w-full">
           {/* { enquiryStore.current.fba === "Yes"  && 
           <>
           <LabeledTextField fontSize="12px" height="34.25px" labelStyle={{fontSize:"12px",fontWeight:'bold',color:"black"}} fullwidth={true} rules={{pattern: {
              value: /^[a-zA-Z0-9]+$/,
              message: "Only alphanumeric characters are allowed",
            },validate:(val)=>{
            if(val && val?.length>14){
                return "Value should not exceed 14"
            }
           }}} placeholder="Enter your Fba Id" control={control} label="FBA ID" name="fbaID"  />
            <LabeledTextField fontSize="12px" height="34.25px" labelStyle={{fontSize:"12px",fontWeight:'bold',color:"black"}} fullwidth={true} rules={{pattern: {
              value: /^[a-zA-Z0-9]+$/,
              message: "Only alphanumeric characters are allowed",
            },validate:(val)=>{
            if(val && val?.length>8){
                return "Value should not exceed 8"
            }
           }}} placeholder="Enter your Amazon Reference ID" control={control} label="Amazon Reference ID" name="purchaseOrderID"  />
            <LabeledTextField fontSize="12px" height="34.25px" labelStyle={{fontSize:"12px",fontWeight:'bold',color:"black"}} fullwidth={true} rules={{pattern: {
              value: /^[a-zA-Z0-9]+$/,
              message: "Only alphanumeric characters are allowed",
            },validate:(val)=>{
            if(val && val?.length>14){
                return "Value should not exceed 14"
            }
           }}} placeholder="Enter your merchant Id" control={control} label="Merchant ID" name="merchantID"  />
            </>
            } */}
        <LabeledSelect disable={enquiry.current.incoTerms?.length>0} fullwidth={true} rules={{required : "Incoterms is required"}} control={control} label="Incoterms*" name="incoterms" options={incoTermsList}/>
        {enquiryStore.current.commodityType !=""?<LabeledSelect  fullwidth={true} onChange={(val)=>{
                    brec.commodityType = val;
                }} rules={{required : "Commodity type is required"}}  control={control} label="Commodity Type*" name="commoditytype"  options={enquiryStore.current.hazardous.toLowerCase() === "yes" ? hazardousList : commodityList} defaultValue={enquiry.current.commodityType} disable={true} />:<LabeledSelect  fullwidth={true} onChange={(val)=>{
                    brec.commodityType = val;
                }} rules={{required : "Commodity type is required"}}  control={control} label="Commodity Type*" name="commoditytype"  options={enquiryStore.current.hazardous.toLowerCase() === "yes" ? hazardousList : commodityList} defaultValue={enquiry.current.commodityType}  />}
       {isFba && <LabeledTextField type="text" inputProps={{maxLength:14}} fontSize="12px" height="34.25px" labelStyle={{fontSize:"12px",fontWeight:'bold',color:"black"}} fullwidth={true} rules={{pattern: {
              value: /^[a-zA-Z0-9]+$/,
              message: "Only alphanumeric characters are allowed",
            },validate:(val)=>{
            if(val && val?.length>14){
                return "Value should not exceed 14"
            }
           }}} placeholder="Enter your merchant Id" control={control} label="Merchant ID" name="merchantID"  />}
        </div>
        <div className="flex flex-col w-full gap-2">
        {isFba && <FbaDetails form={form} autoUpdate={false} />}
        </div>
       { 
    //    enquiryStore.current.shipmentType?.includes("FCL") &&  enquiryStore.current.shipmentScope?.endsWith("Port") && 
        <div className="flex felx-row items-center gap-2 my-2">
          <p className="text-[14px] text-black font-normal">Do you require a Direct Carrier MBL?</p>
          <AntSwitch checked={pendingValue === "Yes"} onChange={handleToggle}/>
        </div>
        }
        {showConfirm && (
                                            <MblConfirmationPopup onConfirm={confirmToggle} onCancel={cancelToggle} />
                                        )}
        <LabeledTextField alignItems="start" placeholder="Type your special instructions..." height="100px" multiline={true} maxRows={3}  labelStyle={labelStyle} label="Special Instructions"  control={control} name="specialinstructions"/>
        <ChooseFile documentView={true} onFileDeleted={(file,onSuccess,onFailure)=>{
    let arr = brec.specialInstructions_filename_list.filter(item => item !== file);
    brec.specialInstructions_filename_list = arr;
    onSuccess();
    }}  key={"specialinstructions"+brec.specialInstructions_filename_list.length} data={brec.specialInstructions_filename_list}  onFileSelected={(file,updateFileState,updateFailureState)=>{
    uploadFile(file,updateFileState,updateFailureState,"carrierwebform",filecat,"specialInstructions",(filename)=>{
      brec.specialInstructions_filename_list = [...brec.specialInstructions_filename_list,filename]
    })
}} direction="row" title="Upload Special Instructions"/>
    </div>
}


function ShipperDetail() {
    const {form,bookingState:{isExport,isImport,entityState:entityData}} = useShipping();
    const {control,setValue,watch} = form;
    const [branches, setbranches] = useState([]);
    const [address,setAddress] = useState([]);
    const branchId = watch("shipperDetails.branchId")
    let brec = bookingsStore.current

    useEffect(()=>{
        if(branchId?.length >0){
            let selectedbranch = entityData?.branch?.filter((r,i)=>r.branchId === branchId)
            setAddress(selectedbranch[0]?.branchAddresses);
        }
    },[branchId])

  

    useEffect(()=>{
        rtpStore.doCaseType = 'Direct Master AWB'
        BranchApi.getAllBranches(brec.entityId, (data)=>
            {
              if(isArray(data)){
                  setbranches(data)
              }
            }
            )
            setValue("shipperDetails.company",configStore.currentSelectedEntity.entityName)
            setValue("shipperDetails.firstName",brec.firstName)
            setValue("shipperDetails.lastName",brec.lastName)
            setValue("shipperDetails.emailid",brec.emailid)
            setValue("shipperDetails.phone",brec.phone)
            setValue("shipperDetails.country",brec.country)
    },[])
    
    return <ViewBox title={isExport?`Shipper Details ${ isExport && "- Invoicing"}` : `Consignee Details ${ isImport && "- Invoicing"}`}>
        <div className="flex flex-col w-full bg-white gap-2.5">
        <LabeledTextField disabled={true} labelStyle={labelStyle} fontSize="12px" control={control} name="shipperDetails.company" label={`${isExport?"Shipper Name*" : "Consignee Name"}`} rules={{required:"Shipper name is required"}} />
        <div className="grid grid-cols-2 w-full gap-2.5">
        <LabeledTextField placeholder="Enter first name" labelStyle={labelStyle} fontSize="12px" control={control} name="shipperDetails.firstName" label="First Name*" rules={{required:"First name is required"}} />
        <LabeledTextField placeholder="Enter last name"  labelStyle={labelStyle} fontSize="12px" control={control} name="shipperDetails.lastName" label="Last Name" />
        <LabeledTextField placeholder="Enter email"  labelStyle={labelStyle} fontSize="12px" control={control} name="shipperDetails.emailid" label="Email Id*" rules={{required:"Email id is required"}} />
        <LabeledTextField placeholder="Enter contact number"  labelStyle={labelStyle} fontSize="12px" control={control} name="shipperDetails.phone" type="number" label="Contact Number*" rules={{required:"Contact number is required"}} />
        </div>
       <div className="grid grid-cols-2 w-full gap-2.5">
        <div className="flex flex-col w-full">
        <Typography sx={{fontFamily:"Figtree",fontSize:"12px",color:"black",fontWeight:"bold",marginBottom:"4px"}}>Branch*</Typography>
                    <AgMuiSelect2  placeholder="Select Branch" rules={{required:"Branch is required"}}  fontSize="12px" width="100%" fullwidth values={branches.map((r)=>r.branchId)} options={branches.map((r)=>`${r.gst} - ${r?.state?.split("/")[0]}`)} onChangeHandler={(branchId)=>{
          brec.branchId = branchId  
          setValue("shipperDetails.address","")
          setValue("shipperDetails.pin","")
        //    let selectedbranch = entityData?.branch?.filter((r,i)=>r.branchId === branchId)
        //     setAddress(selectedbranch[0]?.branchAddresses);
        }} control={control} name={"shipperDetails.branchId"} />
        </div>
        <div className="flex flex-col w-full">
                        <Typography sx={{fontFamily:"Figtree",fontSize:"12px",color:"black",fontWeight:"bold",marginBottom:"4px"}}>Address*</Typography>
                    <AgMuiSelect2  rules={{required:"Address is required"}} onChangeHandler={(value) => {
                         if(value !== ""){
                            let data = JSON.parse(value)
                            brec.address = data.address
                            brec.pin = data.pincode|| data.zipcode
                            setValue("shipperDetails.pin",brec.pin)
                            // setaddressObj(r.target.value)
                        }
        }} fontSize="12px" width="100%" fullwidth values={address.map((r)=>JSON.stringify(r))} options={address.map((r)=>r.address)} control={control} name={"shipperDetails.address"} />
                    </div>
                    <LabeledTextField  disabled={true} labelStyle={labelStyle}  fontSize="12px" control={control} name="shipperDetails.pin" label="Pincode"  />
                    <LabeledTextField disabled={true} labelStyle={labelStyle} fontSize="12px" control={control} name="shipperDetails.country" label="Country"  />
                    </div>
                 
        </div>
    </ViewBox>
}

function ConsigneeDetail() {
    const {form,bookingState:{isExport,isImport},setMoveToNextStep,canMoveToNextStep} = useShipping();
    const {control,setValue,watch,trigger,getValues,clearErrors,resetField,formState} = form;
    const brec = bookingsStore.current;
    brec.originCount = brec.contract.shipmentType.toLowerCase().includes('air') ?
        Utils.extractCodeAndReturnCountry(brec.contract.originAirport)
        : Utils.extractCodeAndReturnCountry(brec.contract.portOfReceipt)
    brec.destinationCount = Utils.extractCodeAndReturnCountry(brec.contract.finalPlaceOfDelivery)
    const [consigneeOptions,setConsigneeOptions] = useState([]);
    const [states,setStates] = useState([]);
    const companyName = watch("consigneeDetails.companyName");
    const [disableFields,setDisableFields] = useState(true);
    const [EntityList, setEntityList] = useState([]);
    const [openEntity,setOpenEntity] = useState(false);
    const [checkState,setCheckState] = useState(false);
  const country = isExport ? brec.destinationCount : brec.originCount;

  useEffect(()=>{
    const shipperDetail = consigneeOptions.find((detail)=>detail.companyName===companyName);
    if(shipperDetail){
        // console.log("the grdtrdr tfytg fy uug  bhbbh   ",shipperDetail)
        setDisableFields(true);
        setValue("consigneeDetails._id",shipperDetail._id)
        setValue("consigneeDetails.country",country)
        setValue("consigneeDetails.isActive",shipperDetail.isActive)
        setValue("consigneeDetails.firstName",shipperDetail.firstName)
        setValue("consigneeDetails.lastName",shipperDetail.lastName)
        setValue("consigneeDetails.emailId",shipperDetail.emailId)
        setValue("consigneeDetails.mobileNumber",shipperDetail.mobileNumber)
        setValue("consigneeDetails.address",shipperDetail.address)
        setValue("consigneeDetails.pincode",shipperDetail.pincode)
        setValue("consigneeDetails.city",shipperDetail.city)
        setValue("consigneeDetails.state",shipperDetail.state)
        setValue("consigneeDetails.newShipper",false)
        setValue("consigneeDetails.companyName",companyName)
        clearErrors("consigneeDetails");
    }
  },[companyName,consigneeOptions]);

  useEffect(() => {
    const CountryList = Country.getAllCountries().filter(
      (r) => r.name === (brec.destinationCount === "Hong Kong" ? "Hong Kong S.A.R." : country)
  );

    const Statess = State.getStatesOfCountry(CountryList[0]?.isoCode);
    setStates(Statess);
   
    return()=>{
        truckLoader.open = false;
        setMoveToNextStep(true);
    }
}, []);

// useEffect(()=>{
//   truckLoader.open = !canMoveToNextStep;
// },[canMoveToNextStep])


  useEffect(()=>{
    //    if(!isImport){
        const fetchShipperData = async () => {
            try {
               
                const res = await ShipperApi.getShipper(brec.entityId,country);
                setValue("consigneeDetails.country",country)
                setConsigneeOptions(res.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchShipperData();
    //    }
    },[])

    const theCountry = watch("consigneeDetails.country")


const validateEmail = async (email) => {
    const emailRegex = /^[^\s@#]+@(?!agraga\.com$)[^\s@#]+\.[^\s@#]+$/;

    const emailRegexOnlyAgraga = /^[^\s@]+@agraga\.com$/;

if (emailRegexOnlyAgraga.test(email)) {
    return "Invalid email"
}

    if (!emailRegex.test(email)) {
        return "Invalid email";
    }

    try {
          if(canMoveToNextStep){
               setMoveToNextStep(false)
          }
          let isEmailValid = new Promise((resolve,reject)=>{
            EntityV2Api.GetEntityListWithEmail(email,brec.entityId, (response) => {
                if(response?.data?.length>0){
                    setEntityList(response?.data)
                    setOpenEntity(true);
                    setMoveToNextStep(false);
                    return resolve();
                }else if(response?.errors){
                    // setShipperDetails(prevState => ({ ...prevState, emailId: "" }));
                    setValue("consigneeDetails.emailId","");
                    setMoveToNextStep(true);
                    return resolve(response?.errors);
                }
                setMoveToNextStep(true);
                return resolve();
              });
          });
          return await isEmailValid;
} catch (error) {
    console.log(error);
    return "Invalid Email"
}

}

const debouncingCall = useCallback(Utils.debounce(validateEmail,400),[])

useEffect(()=>{
    console.log(" the company name ",companyName," the export is ",isExport)
const isRequired = ()=>{
    return (companyName?.length>0 && isExport) || (!isImport && !isExport) || (companyName?.length>0 && isImport);
}
setCheckState(isRequired());
},[isExport,isImport,companyName])

useEffect(()=>{
   console.log(" the form state is ",formState)
},[companyName])




return <ViewBox title={isExport?"Consignee Details" : `Shipper Details`}>
        <div className="flex w-full bg-white flex-col gap-2.5">
            <div className="flex flex-col w-full">
            <Typography sx={{fontFamily:"Figtree",fontSize:"12px",color:"black",fontWeight:"bold",marginBottom:"4px"}}>{isExport?"Consignee Name*" : "Shipper Name*" }</Typography>
                   <AgAutoComplete key={1}  onChange={(val)=>{
                    // console.log(" the change from the ag auto complete ",val);
                    // setValue("consigneeDetails.companyName",val)
                    // setConsigneeDetail(val);
                    if((!val || val.length===0) && isExport && !isImport){
                        resetField("consigneeDetails")
                        clearErrors("consigneeDetails")
                    }
                    const shipperDetail = consigneeOptions.find((detail)=>detail.companyName===val);
                    if(!shipperDetail){
                        // console.log("the grdtrdr tfytg fy uug  bhbbh   ",shipperDetail)
                        setValue("consigneeDetails",{...consigneeDetailsDefault})
                        setValue("consigneeDetails.country",country)
                        setDisableFields(false);
                    }
                //     const shipperDetail = consigneeOptions.find((detail)=>detail.companyName===val);
                //     if(shipperDetail){
                //     setValue("consigneeDetails",{country:destination,
                //      firstName:shipperDetail.firstName,
                //      lastName:shipperDetail.lastName,
                //      emailId:shipperDetail.emailId,
                //      mobileNumber : shipperDetail.mobileNumber,
                //      address : shipperDetail.mobileNumber,
                //      pincode : shipperDetail.pincode,
                //      city : shipperDetail.city,
                //      state : shipperDetail.state
                //  })
                //     }else{
                //      setValue("consigneeDetails",{...consigneeDetailsDefault})
                //      setValue("consigneeDetails.country",destination)
                //      setDisableFields(false);
                //  }
                   }} 
                   rules={checkState?{required:"Consignee name is required"}:undefined} 
                   name="consigneeDetails.companyName" control={control} onInputChange={(value)=>{
                    
                    // setConsigneeDetail(value); 
                    if(value){
                        setValue("consigneeDetails.companyName",value)    
                        const shipperDetail = consigneeOptions.find((detail)=>detail.companyName===value);
                    if(!shipperDetail){
                        // console.log("the grdtrdr tfytg fy uug  bhbbh   ",shipperDetail)
                        setValue("consigneeDetails",{...consigneeDetailsDefault})
                        setValue("consigneeDetails.country",country)
                        setDisableFields(false);
                    }
                    }
                   }} options={consigneeOptions.map((consignee)=>consignee.companyName)} />
            </div>
            <div className="grid grid-cols-2 w-full gap-2.5">
            <LabeledTextField disabled={disableFields}  placeholder="Enter first name" labelStyle={labelStyle} fontSize="12px" control={control} name="consigneeDetails.firstName" label="First Name*" rules={checkState?{required:"First name is required"}:undefined} />
            <LabeledTextField disabled={disableFields}  placeholder="Enter last name"  labelStyle={labelStyle} fontSize="12px" control={control} name="consigneeDetails.lastName" label="Last Name" />
            <LabeledTextField  disabled={disableFields} 
            // onBlur={async(val)=>{
            //                  const isErrorFree =  await trigger("consigneeDetails.emailId")

            //                  if(isErrorFree){
            //                   try {
            //                     setMoveToNextStep(false);
            //                       EntityV2Api.GetEntityListWithEmail(val,brec.entityId, (response) => {
            //                         if(response?.data?.length>0){
            //                             setEntityList(response?.data)
            //                             setOpenEntity(true);
            //                             setMoveToNextStep(true);
            //                         }else if(response?.errors){
            //                             // setShipperDetails(prevState => ({ ...prevState, emailId: "" }));
            //                             setValue("consigneeDetails.emailId","");
            //                             configStore.setModalMessage(response?.errors)
            //                         }
                              
            //                       });
            //             } catch (error) {
            //                 console.log(error);
            //             }
            //                  }
            //             }} 
                          placeholder="Enter email" 
                        //   onKeyDown={(e)=>{
                        //     if(e.target.value==="#"){
                        //         e.preventDefault();
                        //     }
                        //   }} 
                         labelStyle={labelStyle} fontSize="12px" control={control} name="consigneeDetails.emailId" label="Email Id*" rules={checkState?{required:"Email id is required",validate:async(value)=>{
                            if(!disableFields){
                                return validateEmail(value);
                            }
                            return true;
                        }}:undefined} />
                          <LabeledTextField disabled={disableFields}  placeholder="Enter contact number"  labelStyle={labelStyle} fontSize="12px" control={control} name="consigneeDetails.mobileNumber" label="Contact Number*" rules={checkState?{required:"Contact number is required"}:undefined} />
                          <LabeledTextField placeholder="Address"  labelStyle={labelStyle} fontSize="12px" disabled={disableFields} control={control} name="consigneeDetails.address" label="Address" rules={checkState?{required:"Address is required"}:undefined} />
                          <LabeledTextField placeholder="City" disabled={disableFields}  labelStyle={labelStyle} fontSize="12px" control={control} name="consigneeDetails.city" label="City" rules={checkState?{required:"City is required"}:undefined} />
                          {  disableFields ? <LabeledTextField  disabled={disableFields}  labelStyle={labelStyle} fontSize="12px" control={control} name="consigneeDetails.state" label="State*" rules={checkState?{required:"State is required"}:undefined} /> : <div className="flex flex-col w-full"><Typography sx={{fontFamily:"Figtree",fontSize:"12px",color:"black",fontWeight:"bold",marginBottom:"4px"}}>State*</Typography>
                    <AgMuiSelect2 rules={checkState?{required:"State is required"}:undefined} onChangeHandler={(value) => {
        }} fontSize="12px" width="100%" fullwidth options={states.map((r)=>r.name)} control={control} name={"consigneeDetails.state"} />
        </div>
        }
 <LabeledTextField rules={checkState?{required:"Pincode is required",validate:(val)=>{
                            if(disableFields){
                                return ;
                            }
                            let pincodeRegex;
                            if ( theCountry === "India") {
                                pincodeRegex = /^\d{0,6}$/;
                            } else {
                                pincodeRegex = /^\d{0,10}$/;
                            }
                            if(!pincodeRegex.test(val)){
                                  return "Invalid pincode"
                            }
                        }}:undefined} disabled={disableFields} labelStyle={labelStyle} fontSize="12px" control={control} name="consigneeDetails.pincode" label="Pincode"  />
<LabeledTextField disabled={true} labelStyle={labelStyle} fontSize="12px" control={control} name="consigneeDetails.country" label="Country"  />
</div>
        </div>
        { openEntity && <ChooseEntity open={openEntity} values={EntityList.map((entity)=>entity._id)} options={EntityList.map((entity)=>`${entity._id}-${entity.entityName}-${entity.entityCountry}`)} onCancel={()=>{
            setOpenEntity(false);
            setMoveToNextStep(true);
          }} onSubmit={(val)=>{
            setOpenEntity(false);
            setMoveToNextStep(true);
            setValue("consigneeDetails.linkedEntity",val);
          }} />}
    </ViewBox>
}

function ChooseEntity({open,values=[],options=[],onCancel,onSubmit}){
    const [value, setValue] = useState('');
  
    const handleChange = (event) => {
        setValue(event.target.value);
      };
    return <MuiPopup open={open}>
         <Box sx={{width:"100%",height:"100%",display:"flex",flexDirection:"column",alignItems:"end"}}>
                <Box sx={{display:"flex",flex:1,width:"100%",flexDirection:"column",justifyContent:"center",alignItems:"center",gap:2}}>
                <FormControl component="fieldset">
<RadioGroup
  value={value}
  onChange={handleChange}
>
{options.map((option,i)=> <FormControlLabel style={{color:"black",fontWeight:"bold",fontSize:"18px"}} value={values.length>0?values[i] :option} control={<Radio  />} label={option} />)}
</RadioGroup>
</FormControl>
<Box sx={{display:"flex",flexDirection:"row",justifyContent:"end",gap:1,width:"100%"}}>
                  <Button onClick={()=>{
                    if(onCancel){
                        onCancel();
                    }
                  }} sx={{textTransform:"capitalize",backgroundColor:"#F5F5F5",color:"#2B6ED4",borderRadius:"5px","&:hover":{
                    backgroundColor:"#F5F5F5",color:"#2B6ED4"
                  }}}>Cancel</Button>
                    <Button onClick={()=>{
                        if(onSubmit){
                            onSubmit(value);
                        }
                    }} sx={{textTransform:"capitalize", backgroundColor:"#2B6ED4",color:"#F5F5F5",borderRadius:"5px","&:hover":{
                    backgroundColor:"#2B6ED4",color:"#F5F5F5"
                  }}}>Submit</Button>
                  </Box>
                </Box>
         </Box>
    </MuiPopup>
}

function Invoicing(){
   
    const {form,bookingState:{isExport,isImport,entityState:entityData}} = useShipping();
    const {control,setValue,watch} = form;
    let brec = bookingsStore.current;
    const [branches, setbranches] = useState([]);
    const [address,setAddress] = useState([]);
    
    const disable = isExport || isImport;
    const branchId = watch("invoiceDetails.branchId");

    useEffect(()=>{
        if(branchId?.length >0){
            let selectedbranch = entityData?.branch?.filter((r,i)=>r.branchId === branchId)
            setAddress(selectedbranch[0]?.branchAddresses);
        }
    },[branchId])

    useEffect(()=>{
        rtpStore.doCaseType = 'Direct Master AWB'
        if(isExport||isImport){
            let selectedbranch = entityData?.branch?.filter((r,i)=>r.branchId === brec.branchId)
            setAddress(selectedbranch[0]?.branchAddresses);
        }
        BranchApi.getAllBranches(brec.entityId, (data)=>
            {
              if(isArray(data)){
                  setbranches(data)
              }
            }
            )
            setValue("invoiceDetails.country",brec.country);
    },[])
    return <ViewBox title="Invoice Details">
      
           <div className="flex flex-col w-full gap-1">
           <Typography sx={{fontFamily:"Figtree",fontSize:"12px",color:"black",fontWeight:"bold",marginBottom:"4px"}}>Branch*</Typography>
                 <AgMuiSelect2 disabled={disable} placeholder="Select Branch" rules={{required:"Branch is required"}}  fontSize="12px" width="100%" fullwidth values={branches.map((r)=>r.branchId)} options={branches.map((r)=>`${r.gst} - ${r?.state?.split("/")[0]}`)} onChangeHandler={(branchId)=>{
          brec.branchId = branchId  
          setValue("invoiceDetails.address","")
          setValue("invoiceDetails.pin","")
           let selectedbranch = entityData?.branch?.filter((r,i)=>r.branchId === branchId)
            setAddress(selectedbranch[0]?.branchAddresses);
        }} control={control} name={ disable ? "shipperDetails.branchId" : "invoiceDetails.branchId"} />
           </div>
           <div className="flex flex-col w-full gap-1">
           <Typography sx={{fontFamily:"Figtree",fontSize:"12px",color:"black",fontWeight:"bold",marginBottom:"4px"}}>Address*</Typography>
            <AgMuiSelect2 disabled={disable}  rules={{required:"Address is required"}} onChangeHandler={(value) => {
                         if(value !== ""){
                            let data = JSON.parse(value)
                            brec.address = data.address
                            brec.pin = data.pincode|| data.zipcode
                            setValue("invoiceDetails.pin",brec.pin)
                            // setaddressObj(r.target.value)
                        }
        }} fontSize="12px" width="100%" fullwidth values={address.map((r)=>JSON.stringify(r))} options={address.map((r)=>r.address)} control={control} name={disable ? "shipperDetails.address" : "invoiceDetails.address"} />
           </div>
           <div className="grid grid-cols-2 w-full gap-3">
           <LabeledTextField  disabled={true} labelStyle={labelStyle} fontSize="12px" control={control} name={disable ? "shipperDetails.pin":"invoiceDetails.pin"} label="Pincode"  />
           <LabeledTextField disabled={true} labelStyle={labelStyle} fontSize="12px" control={control} name={disable ? "shipperDetails.country" : "invoiceDetails.country"} label="Country"  />
        </div>
    </ViewBox>
}

const PAYMENTENUM = {
    PAYNOW : "Pay now",
    PAYLATER : "Pay later"
}

function PaymentCard({src,title,selected=false,onClick}){
    return <div onClick={()=>{
        if(onClick){
            onClick();
        }
    }} className={`flex items-center justify-center rounded-xl h-[88px] w-[246px] cursor-pointer border-[1.5px] border-solid ${selected ? "border-blue-dark bg-[#DDEBFF]" : "border-[#D4D4D4]"}`}>
<div className="flex flex-row items-center gap-[22px]">
    {selected ? <SelectedRadio/> : <UnSelectedRadio/>}
    <img style={{height:"40px",width:"40px",filter:selected ? "brightness(0) saturate(100%) invert(16%) sepia(43%) saturate(3788%) hue-rotate(226deg) brightness(91%) contrast(91%)" : "brightness(0) saturate(100%) invert(31%) sepia(4%) saturate(12%) hue-rotate(323deg) brightness(94%) contrast(79%)"}} src={src} />
    <p className={`text-xl ${selected? "text-blue-dark" : "text-grey"} font-medium`}>{title}</p>
</div>
    </div>
}

function Payment(){
    const [paymentType,setPaymentType] = useState(PAYMENTENUM.PAYNOW)
    return <ViewBox title="Payment Details">
        <div className="flex flex-row gap-2.5">
            <PaymentCard selected={paymentType===PAYMENTENUM.PAYNOW} title="Pay Now" src={paynow} onClick={()=>{
                setPaymentType(PAYMENTENUM.PAYNOW)
            }} />
            <PaymentCard selected={paymentType===PAYMENTENUM.PAYLATER} title="Pay Later" src={paylater} onClick={()=>{
                setPaymentType(PAYMENTENUM.PAYLATER)
            }} />
        </div>
    </ViewBox>
}

function InvoicingAndPayment(){
    
    return <div className="flex flex-row gap-2 w-full">
        <div className="flex flex-1">
             <Invoicing/>
            </div>
            {/* <div className="flex flex-1">
                 <Payment/>
            </div> */}
    </div>
}

function ShipperConsigneeDetails() {
    const {bookingState,form} = useShipping();
    const {getValues} = form;
    useEffect(()=>{
        console.log("the consignee valu eis on ",getValues("consigneeDetails"),"the shipper detail are ",getValues("shipperDetails"))
        return ()=>{
            console.log("the consignee valu eis on left ",getValues("consigneeDetails"),"the shipper detail are ",getValues("shipperDetails"))
        }
    },[])
    return <div className="flex flex-row gap-2 w-full">
        <div className="flex flex-1">
        { bookingState.isExport ? <ShipperDetail /> : <ConsigneeDetail /> }
        </div>
        
        { (bookingState.isExport || bookingState.isImport) && (
             bookingState.isImport ?  <div className="flex flex-1"><ShipperDetail /></div> : <div className="flex flex-1"><ConsigneeDetail/> </div> )}
        </div>
}

export function ShippingDetailsV2() {
    useEffect(()=>{
        newDashboardState.moveToHome = true;
        return ()=>{
            newDashboardState.moveToHome = false;
        }
    },[])
    return <ShippingContextProvider>
                     <ShippingDetailsParent/>
    </ShippingContextProvider>
}

const validateFormKeys = [
    {
        validateForm : (isExport,isImport)=>{
            let tobeChecked = [];
            if(isExport){
                tobeChecked.push("shipperDetails")
            }else{
                tobeChecked.push("consigneeDetails")
            }

            if(isExport || isImport){
                if(isImport){
                    tobeChecked.push("shipperDetails")
                }else{
                    tobeChecked.push("consigneeDetails")
                }
            }

             return  tobeChecked;
        },
        index:0
    },{
        validateForm : ()=>{
             return ["incoterms","commoditytype"]
        },
        index : 1
    }
]

export function ShippingDetailsParent(){
    const [selectedStage, setSelectedStage] = useState(0);
    const {isLoading,bookingState,form,canMoveToNextStep} = useShipping();
    const {handleSubmit,trigger} = form;
    const [disable,setDisable] = useState(false);
    const [open,setOpen] = useState(false)
    const brec = bookingsStore.current;
    
    useEffect(()=>{
        truckLoader.open = disable;
    },[disable])

    useEffect(()=>{
   return ()=>{
    breadCrumbStore.id = null;
   }
    },[])

    const onSubmit = async(data)=>{
        const isContract = bookingsStore.current.contract.mode === "CONTRACT";
        let proceed = true;
        if(shippingStages[selectedStage].handler){
           proceed = shippingStages[selectedStage].handler(bookingsStore.current);
        }
        if(!proceed){
            return;
        }
        if(selectedStage!==shippingStages.length-1){
            if(!canMoveToNextStep){
                return;
            }
            setSelectedStage((prev)=>++prev);
        }else{
            const shipperDetails = data.consigneeDetails;
     
           bookingsStore.current.commodityType = data.commoditytype;
           bookingsStore.current.incoTerms = data.incoterms;
           bookingsStore.current.specialInstructions = data.specialinstructions;
           bookingsStore.current.fba = enquiryStore.current.fba;
            shipperDetails.entityId = bookingsStore.current.contract.entityId;
            if(enquiryStore.current.fba?.toLowerCase()==="yes"){
                bookingsStore.current.merchantID = data.merchantID
                bookingsStore.current.fbaDetails = data.fbaList
            }
            if (bookingState.isExport){
              shipperDetails.role = "consignee"
              bookingsStore.current.consignee = data.consigneeDetails.companyName
              bookingsStore.current.shipper = data.shipperDetails.company
          }else{
              shipperDetails.role = "shipper"
              bookingsStore.current.shipper = data.consigneeDetails.companyName
              bookingState.isExport?brec.consignee = data.shipperDetails.company: brec.consignee = ""
  
          }

         

          const cecOc = bookingState.isExport;
          const cecOd = bookingState.isImport;
          let role =[]
          //Ocean
          const entityDetails = contractsStore.current
          if(entityDetails.shipmentType !== 'Air' && cecOc){
              role.push('Ocean export Coordination')
          }
          if(entityDetails.shipmentType !== 'Air' && cecOd){
              role.push('Ocean Import Coordination')
          }
          if(entityDetails.shipmentType !== 'Air' && !cecOc && !cecOd ){
              role.push('Ocean export Coordination')
              role.push('Ocean Import Coordination')
          }
          //Air
          if(entityDetails.shipmentType === 'Air' && cecOc){
              role.push('Air export Coordination')
          }
          if(entityDetails.shipmentType === 'Air' && cecOd){
              role.push('Air Import Coordination')
          }
          if(entityDetails.shipmentType === 'Air' && !cecOc && !cecOd ){
              role.push('Air export Coordination')
              role.push('Air Import Coordination')
          }
          shipperDetails.toUsers =role
             
              setDisable(true)
           
            if (brec.contract.hazardous === 'Yes') {
                if (!brec.iipcert_filename || brec.iipcert_filename.length < 1) {
                   //  setValidIIPCertificate(false)
                   //  hasError = true
                   configStore.setModalMessage("IIp certificate is mandatory")
                   return;
                }else{
                   //  setValidIIPCertificate(true)
                }
            }
            
   
           let pricingRec ;
           if(rtpStore.routeSubscript>=0){
               pricingRec =   filtersState.filteredList[rtpStore.routeSubscript]
           }
            
           let filteredCharges = [];
           if(!isContract){
           filteredCharges = bookingsStore.current.contract.chargesList.filter(e => e.isIncluded == 'Yes')
           }else{
            filteredCharges = bookingsStore.current.contract.chargesList;
           }
           if (pricingRec &&
               pricingRec.doCaseType == 1 &&
               pricingRec.hasHawb &&
               pricingRec.hasAgent &&
               rtpStore.doCaseType == 'Direct Master AWB') {   
               bookingsStore.current.contract.quoteValue = bookingsStore.current.contract.quoteValue - pricingRec.hawbAmt - pricingRec.agentAmt
               bookingsStore.current.contract.quoteValueDeduction = 0
               filteredCharges = filteredCharges.filter(e => e.chargeDescription != pricingRec.hawbChargeHead && e.chargeDescription != pricingRec.agentChargeHead)
           } else {
               bookingsStore.current.contract.quoteValueDeduction = 0
           }
           if (brec.contract.oih) {
               filteredCharges = filteredCharges.filter(e => e.chargeDescription != 'Origin Inland Haulage')
               brec.contract.quoteValue -= brec.contract.oih
               brec.contract.oih = 0
           }
        
           
           if((bookingsStore.current.contract?.entityId?.length === 0 ||bookingsStore.current.contract?.entityId === undefined )&& bookingsStore.current?.entityId?.length > 0 ){
               bookingsStore.current.contract.entityId =  bookingsStore.current.entityId
           }
           if (pricingRec && pricingRec.oih && rtpStore.shipperpda == ' ') {
               let pdatc = `Origin Inland Charges from ${pricingRec.por} to ${pricingRec.pol} are on Shipper's account`
               if (pricingRec.oihScac != 'PRIVATE' && pricingRec.oihScac != 'CONCUR') pdatc += ', subject to Carrier Approval'
               bookingsStore.current.contract.tc.push(pdatc)
           }
   
           bookingsStore.current.contract.chargesList = [...filteredCharges];
           console.log(" the bookinxsgdcfhb dcfgvhb fcvghb ", breadCrumbStore.state.theSchedule)
           if(isContract){
            let contract = bookingsStore.current.contract;
            console.log(" the contract id is ",contract);
            contract.quotationNum = contract._id?.substring(0, contract._id?.length - 1);
            bookingsStore.current.contractNum = contract._id;
            bookingsStore.current.quotationNum = contract.quotationNum
            bookingsStore.current.contractRefNum = contract._id;
            // bookingsStore.current.schedule = breadCrumbStore.state.theSchedule;
            if(contractsStore.current.fba!=undefined && (brec.fba==undefined || contractsStore.current.fba!=brec.fba)){
                brec.fba=contractsStore.current.fba
            }
            // brec.Branches = configStore.currentSelectedBranch;
            // brec.Entities = configStore.currentSelectedEntity;
            
            bookingsStore.current.createdFromPricing = false;
           
            
           }

           if(breadCrumbStore.state.subscript>=0){ 
            bookingsStore.current.schedule = {...breadCrumbStore.state.theSchedule};
            bookingsStore.current.hasToShowDummySchedule = false;
        }else{
            bookingsStore.current.schedule = {}
            bookingsStore.current.hasToShowDummySchedule = true;
        }
           
           const callback = (d) =>{
               let url = window.location.href
               let toUsers = Utils.getRespectiveMember(EntityV2Store.EntityRec.customer)
               let rec = contractsStore.current
               let CustomerName = EntityV2Store.EntityRec.entityName
               let shipType = rec.shipmentType
               let shipScope = rec.shipmentScope
               let origin = rec.origin
               let destination = rec.destination
               let from = `Agraga <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
               let to = toUsers
               if (url.indexOf('booking.agraga.com') >= 0) {
                   to = to + ',sanjeev.gupta@agraga.com'
               }
               let subject = 'Booking - ' + configStore.bookingModalNum
               let message = `Hi Team,<br />
               <br />
               &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; We have received a booking from ${CustomerName}.<br />
               &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;     Booking Number: ${d._id}.<br />
               &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;    Shipment Type: ${shipType}.<br />
               &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;    Scope :${shipScope}.<br />
               &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;    Origin: ${origin}.<br />
               &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;     Destination: ${destination}.</p>
               <br />
               Team Agraga</p>`
               Api.sendClientMailwithoutMessage(from, to, subject, message)
               setDisable(false);
               setOpen(true);
           }
           bookingsStore.current.premium = enquiryStore.current.premium;
           bookingsStore.current.sum_insured = enquiryStore.current.sum_insured;
           bookingsStore.current.insurance_Amount = enquiryStore.current.insurance_Amount
           bookingsStore.current.marineInsuranceEnabled = enquiryStore.current.marineInsuranceEnabled
           if(bookingsStore.current.marineInsuranceEnabled=="Yes"){
            bookingsStore.current.insurance_Amount_currency_type =enquiryStore.current.insurance_Amount_currency_type
            bookingsStore.current.contract.quoteValue = bookingsStore.current.contract.quoteValue+(enquiryStore.current.premium-500)
            bookingsStore.current.contract.quoteValueUSD =bookingsStore.current.contract.quoteValue/configStore.getExchangeRate("USD")
           }
        //    taskStore.current.stage = 1;
        //    taskStore.current.pendingWith = "CUSTOMER";
           //await Taskapi.getEmailsForEntity(taskStore.current.gst)
           //await Taskapi.saveTask(taskStore.current);
           BookingsApi.saveBooking(shipperDetails,callback,(async (data)=>{
            if(bookingsStore.current.marineInsuranceEnabled=="Yes"){
                var today = new Date();
                var dayAfterTomorrow = new Date();
                dayAfterTomorrow.setDate(today.getDate()+2);
                taskStore.current = new Task('')
                bookingsStore.current._id=data._id
                taskStore.current.initialize(bookingsStore.current, entityStore.entityRec)
                taskStore.current.actionName="COI Creation (Insurance)"
                taskStore.current.stage = 1;
                taskStore.current.pendingWith = "CUSTOMER";
                taskStore.current.dueDate=dayAfterTomorrow.toISOString().split("T")[0]
                taskStore.current.files = [{
                    label: 'Commercial Invoice',
                    filename: "",
                    invoiceNo: '' ,
                    invoiceDate: '',
                    approved: '',
                    rejectReason: ''
                  }]
                console.log("Ds",taskStore.current)
                //await Taskapi.getEmailsForEntity(taskStore.current.gst)
                await Taskapi.saveTaskForEnablingMarineInsurance(taskStore.current);
            }
            // bookingsStore.initialize();
           }),()=>{
            setDisable(false);
           })
           
        //     setDisable(true)
        //     const shipperDetails = data.consigneeDetails;

        //     let hasError = false
        //    // shipperDetails.isActive = true;        
        //    bookingsStore.current.commodityType = data.commoditytype;
        //    bookingsStore.current.incoTerms = data.incoterms;
        //    bookingsStore.current.specialInstructions = data.specialinstructions;
        //     if (brec.contract.hazardous === 'Yes') {
        //         if (!brec.iipcert_filename || brec.iipcert_filename.length < 1) {
        //            //  setValidIIPCertificate(false)
        //            //  hasError = true
        //            configStore.setModalMessage("IIpcert is mandatory")
        //            return;
        //         }else{
        //            //  setValidIIPCertificate(true)
        //         }
        //     }
        //     shipperDetails.entityId = bookingsStore.current.contract.entityId;
        //      if (bookingState.isExport){
        //        shipperDetails.role = "consignee"
        //        bookingsStore.current.consignee = data.consigneeDetails.companyName
        //        bookingsStore.current.shipper = data.shipperDetails.company
        //    }else{
        //        shipperDetails.role = "shipper"
        //        bookingsStore.current.shipper = data.consigneeDetails.companyName
        //        bookingState.isExport?brec.consignee = data.shipperDetails.company: brec.consignee = ""
   
        //    }
   
        //    let pricingRec ;
        //    if(rtpStore.routeSubscript>=0){
        //        pricingRec =   filtersState.filteredList[rtpStore.routeSubscript]
        //    }
               
        //    let filteredCharges = bookingsStore.current.contract.chargesList.filter(e => e.isIncluded == 'Yes')
        //    if (pricingRec &&
        //        pricingRec.doCaseType == 1 &&
        //        pricingRec.hasHawb &&
        //        pricingRec.hasAgent &&
        //        rtpStore.doCaseType == 'Direct Master AWB') {   
        //        bookingsStore.current.contract.quoteValue = bookingsStore.current.contract.quoteValue - pricingRec.hawbAmt - pricingRec.agentAmt
        //        bookingsStore.current.contract.quoteValueDeduction = 0
        //        filteredCharges = filteredCharges.filter(e => e.chargeDescription != pricingRec.hawbChargeHead && e.chargeDescription != pricingRec.agentChargeHead)
        //    } else {
        //        bookingsStore.current.contract.quoteValueDeduction = 0
        //    }
        //    if (brec.contract.oih) {
        //        filteredCharges = filteredCharges.filter(e => e.chargeDescription != 'Origin Inland Haulage')
        //        brec.contract.quoteValue -= brec.contract.oih
        //        brec.contract.oih = 0
        //    }
        
           
        //    if((bookingsStore.current.contract?.entityId?.length === 0 ||bookingsStore.current.contract?.entityId === undefined )&& bookingsStore.current?.entityId?.length > 0 ){
        //        bookingsStore.current.contract.entityId =  bookingsStore.current.entityId
        //    }
        //    if (pricingRec && pricingRec.oih && rtpStore.shipperpda == ' ') {
        //        let pdatc = `Origin Inland Charges from ${pricingRec.por} to ${pricingRec.pol} are on Shipper's account`
        //        if (pricingRec.oihScac != 'PRIVATE' && pricingRec.oihScac != 'CONCUR') pdatc += ', subject to Carrier Approval'
        //        bookingsStore.current.contract.tc.push(pdatc)
        //    }
   
        //    bookingsStore.current.contract.chargesList = [...filteredCharges]
        //    const callback = (d) =>{
        //        let url = window.location.href
        //        let toUsers = Utils.getRespectiveMember(EntityV2Store.EntityRec.customer)
        //        let rec = contractsStore.current
        //        let CustomerName = EntityV2Store.EntityRec.entityName
        //        let shipType = rec.shipmentType
        //        let shipScope = rec.shipmentScope
        //        let origin = rec.origin
        //        let destination = rec.destination
        //        let from = `Agraga <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
        //        let to = toUsers
        //        if (url.indexOf('booking.agraga.com') >= 0) {
        //            to = to + ',sanjeev.gupta@agraga.com'
        //        }
        //        let subject = 'Booking - ' + configStore.bookingModalNum
        //        let message = `Hi Team,<br />
        //        <br />
        //        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; We have received a booking from ${CustomerName}.<br />
        //        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;     Booking Number: ${d._id}.<br />
        //        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;    Shipment Type: ${shipType}.<br />
        //        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;    Scope :${shipScope}.<br />
        //        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;    Origin: ${origin}.<br />
        //        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;     Destination: ${destination}.</p>
        //        <br />
        //        Team Agraga</p>`
        //        Api.sendClientMailwithoutMessage(from, to, subject, message)
        //        setDisable(false);
        //        setOpen(true);
        //    }
        //    BookingsApi.saveBooking(shipperDetails,callback,()=>{
        //     setDisable(false);
        //    })
        }
    }
    return  <div className="flex flex-col gap-2.5 w-full">
    <div className="w-full h-[50px] bg-blue-dark rounded-t-md flex flex-row">
        {shippingStages.map((stage, i) => <div onClick={() => {
            if(selectedStage >=i){
            setSelectedStage(i);
            }
        }} className={`h-full px-[24px] text-white text-[17px] flex items-center justify-center  ${selectedStage >=i ? "hover:cursor-pointer" : "hover:cursor-not-allowed" } ${i === 0 && "rounded-tl-md"} ${i === selectedStage && "bg-yellow"}`}>{stage.title}</div>)}
    </div>
   {isLoading ?  <CenteredCircularProgress/> :  
   <form className="flex flex-col gap-2.5 w-full" onSubmit={handleSubmit(onSubmit)}>
   <div className="flex w-full">{shippingStages[selectedStage].component}</div>
   <div className={`flex w-full ${ selectedStage>0 ? "justify-between" : "justify-end"}`}>
    { selectedStage>0 && <button onClick={()=>{
        setSelectedStage((prev)=>prev-1);
    }} type="button" className="bg-blue-dark rounded-[5px] h-[40px] px-[10px] text-[white]">
        <div className="flex flex-row gap-2 items-center">
        <img style={{height:"9.3px",width:"5.4px",objectFit:"contain",filter:"brightness(0) invert(1)",transform:"rotate(180deg)"}} src={arrow} />
        <p className="text-white">Previous</p>
        </div>
    </button>}
    <button 
    // disabled={!canMoveToNextStep}  
    // type={canMoveToNextStep?"submit":"button"} 
    type="submit"
    className="bg-blue-dark rounded-[5px] h-[40px] px-[10px]"><div className="flex flex-row gap-2 items-center">
        <p className="text-white">{ selectedStage === shippingStages.length-1 ?  "Confirm Booking" : shippingStages[selectedStage].buttonName || "Next"}</p>
        
        { !(selectedStage === shippingStages.length-1) && <img style={{height:"9.3px",width:"5.4px",objectFit:"contain",filter:"brightness(0) invert(1)"}} src={arrow} />}
       
        </div></button>
   </div>
   </form>}
   <TruckLoader/>
   { open &&  <CustomPopup title="Booking Confirmed" isOpen={open} onClose={()=>{
                setOpen(false);
                newDashboardState.selectedTab = 5;
                breadCrumbStore.setBreadCrumbs(["My Bookings"])
                breadCrumbStore.onBackArrow = undefined;
                // breadCrumbStore.setCurrentCrumb(2);
              }}>
                <Box sx={{width:"600px",height:"100%",display:"flex",flexDirection:"column",alignItems:"end"}}>
                <Box sx={{display:"flex",flex:1,width:"100%",flexDirection:"column",justifyContent:"center",alignItems:"center",gap:2}}>
                <Typography sx={{fontFamily:"Figtree",color:"black"}}>Booking Submitted Successfully</Typography>
                    <Typography sx={{fontFamily:"Figtree",color:"black",textAlign:"center"}}>Thank you!</Typography>
                    <Typography sx={{fontFamily:"Figtree",color:"black",textAlign:"center"}}>Your booking reference number : <CopyText onClick={()=>{
                        // setOpen(false);
                        // breadCrumbStore.setCurrentCrumb(2);
                    }} text={configStore.bookingModalNum} /></Typography>
                    <Typography sx={{fontFamily:"Figtree",color:"black",textAlign:"center"}}>Thank you for the information provided. Your Booking is Confirmed, and our team will get back to you along-with further information.</Typography>
                    <Typography sx={{fontFamily:"Figtree",color:"black",textAlign:"center"}}>If you need immediate assistance, please reach out to us on +91 97318 15003  or write us at support@agraga.com
We appreciate your patience.</Typography>
<Typography sx={{fontFamily:"Figtree",color:"black"}}>Team Agraga</Typography>
              
                </Box>
                </Box>
                </CustomPopup>}
</div>
}