import { useSnapshot } from "valtio"
import {
  configStore,
  loginStore,
  vehicleStore,
  routerStore,
  AgmodalStore,
} from "../state/CommonState"
import { Columns, Col, Col2, Col3, Col4, Col5, Col6, Col12 } from "../Controls"
import { $c, $cg, $cr } from "../components/AgComponents"
import VehicleApi from "../state/vehicle_api"
import AguserApi from "../state/AguserApi"
import { useEffect, useState } from "react"
import Api from "../state/Api"

import { MdDelete } from "react-icons/md"
import { Roles } from "../StaticData"
import { useRef } from "react"
import DtApi from "../state/dt-api"
import { State, City } from "country-state-city"
import Utils from "../util/Utils"
import { CgMoreVertical } from "react-icons/cg"
import { ImCancelCircle } from "react-icons/im"
import { ReactComponent as Loader } from "../assets/loader.svg"
//import { BsFillEyeFill } from "react-icons/bs";
import { BsFillEyeFill } from "react-icons/bs"
import RoleUtils from "../util/RoleUtils"
import { PERMISSIONS } from "../util/constants"
import { loader } from "../util/loader"
//import { colors } from "react-select/dist/declarations/src/theme";
export default function EditVehicle(props) {
 

  const attachmentNameMapping = {
    "Upload RC Document": "rc_document",
    "Upload Agreement of sale": "agreement_sale",
    "Upload Insurance": "insurance",
    "Upload PUC": "puc",
    "Upload FC": "fc",
  }

  const domain = loginStore.email.split("@")[1]
  const [salesList, setSalesList] = useState([])
  const [csList, setCsList] = useState([])
  const [cadminList, setCadminList] = useState([])
  const [cursor, setCursor] = useState(0)
  const [vehiclelist, setVehiclelist] = useState({})
  const [states, setstates] = useState([])
  const ref = useRef(null)
  const hiddenFileInput1 = useRef()
  const hiddenFileInput2 = useRef()
  const hiddenFileInput3 = useRef()
  const hiddenFileInput4 = useRef()
  const hiddenFileInput5 = useRef()
  const hiddenFileInput6 = useRef()
  const hiddenFileInput7 = useRef()
  const hiddenFileInput8 = useRef()
  const hiddenFileInput = useRef()
  const hiddenFileInput233 = useRef()
  const [state, setstate] = useState("")
  const [cities, setcities] = useState([])
  const [approvepopup, setapprovepopup] = useState(false)
  const [ulippopup, setulippopup] = useState(true)
  const [rejectpopup, setrejectpopup] = useState(false)
  //const [title, settitle] = useState("");
  const [updateDripopup, setupdateDripopup] = useState(false)
  const [newdlno, setnewdlno] = useState("")
  const [setstatesel, setsetstatesel] = useState({})
  const [updVehCopy, setupdVehCopy] = useState("")
  const [setcitysel, setsetcitysel] = useState({})
  const [alertcomment, setalertcomment] = useState("")
  const [appbtnstatus, setappbtnstatus] = useState(true)
  var todaydate = Utils.getCurrentDateStringFuture()
  let pastdate = Utils.getCurrentDateString()
  const [selecttag, setselecttag] = useState(false)
  const [rejbtnstatus, setrejbtnstatus] = useState(true)
  const [loading, setloading] = useState(false)
  const [message, setMessage] = useState("")
  const [docArray, setDocArray] = useState({})
  const [isVehicleRefreshed, setIsVehicleRefreshed] = useState(false)
  const [formObject, setformObject] = useState({});
  const vehicle = vehicleStore.vehicleRec
  
  useSnapshot(vehicleStore)
  let title = ""
  switch (props.mode) {
    case "edit": //(${Branch?._id}) ${Branch?.status}
      title = `Modify (${vehicle?.Regnumber}) ${vehicle?.Status}`
      break
    case "view":
      title = `View (${vehicle?.Regnumber}) ${vehicle?.Status}`
      break
    case "new":
      title = "Add New Vehicle"
      break
    default:
      title = "Vehicle"
      break
  }
  const delDocs = (r) => {
    vehicle.Documents[r].fileLink = ""
    vehicle.Documents[r].fileKey = ""
  }
  const delDocs1 = (r) => {
    vehicle.Documents[r].fileLink = ""
    vehicle.Documents[r].fileKey = ""
  }
  const setLists = (users) => {
    let agusers = users.filter((e) => e.email?.endsWith("agraga.com"))
    agusers.sort((a, b) => a.firstName?.localeCompare(b.firstName))
    setSalesList(agusers)
    setCsList(agusers)
  }
  useEffect(() => {
    const input = ref.current
  }, [ref, cursor])

  function trimStr(str) {
    if (!str) return str
    return str.replace(/^\s+/g, "")
  }
  const setvlist = (data) => {
    let d = {}
    if (data["Vehicle Masters"]) {
      let l = data["Vehicle Masters"]
      for (let i = 0;i < l.length;i++) {
        d[l[i]["Vehicle type"]] = l[i]["Vehicle type"]
      }
      setVehiclelist(d)
    }
  }

  const handleChange = (e, r) => {
    setCursor(e.target.selectionStart)
    //r === "Vechownname"
    if (r === "Regnumber") {
      if (r === "Regnumber") {
        let re = new RegExp("^([A-Za-z0-9]{0,10})$")
        if (!re.test(e.target.value)) {
          return null
        }
      }
      vehicle[r] = trimStr(e.target.value.toUpperCase())
    } else if (r === "State") {
      vehicle.City = ""
      vehicle[r] = trimStr(e.target.value)
    } else if (r === "Vechownname") {
      if (r === "Vechownname") {
        let re = new RegExp("^([A-Za-z0-9_ ]{0,20})$")
        if (!re.test(e.target.value)) {
          return null
        }
      }
      vehicle[r] = trimStr(e.target.value)
    } else {
      if (updateDripopup || (!isVehicleRefreshed && isRejectedStatus())) {
        if (r === "InsExpdate") {
          if(isInsExpiredStatus()){
          vehicle.InsExpdateFlag = true
          }
          setupdVehCopy(r)
          if (updateDripopup || (!isVehicleRefreshed && isRejectedStatus()) ) {
            const insurancedoc = vehicle.Documents.filter(
              (r, i) => r.name === "Upload Insurance"
            )
            insurancedoc[0].fileName = ""
            insurancedoc[0].fileLink = ""
            insurancedoc[0].pkey = ""
          }
        }
        if (r === "PucExpdate") {
          if(isPucExpiredStatus()){
          vehicle.PucExpdateFlag = true  
          }
          setupdVehCopy(r)
          //setalertcomment("Please upload new PUC copy");
          if (updateDripopup || (!isVehicleRefreshed && isRejectedStatus())) {
            const insurancedoc = vehicle.Documents.filter(
              (r, i) => r.name === "Upload PUC"
            )
            insurancedoc[0].fileName = ""
            insurancedoc[0].fileLink = ""
            insurancedoc[0].pkey = ""
          }
        }
        if (r === "FcExpdate") {
          if(isFcExpiredStatus()){
          vehicle.FcExpdateFlag = true
          }
          setupdVehCopy(r)
          // setalertcomment("Please upload new Fc copy");
          if (updateDripopup || (!isVehicleRefreshed && isRejectedStatus())) {
            const insurancedoc = vehicle.Documents.filter(
              (r, i) => r.name === "Upload FC"
            )
            insurancedoc[0].fileName = ""
            insurancedoc[0].fileLink = ""
            insurancedoc[0].pkey = ""
          }
        }
        if (r === "RcExpdate") {
          vehicle.RcExpdateFlag = true
          setupdVehCopy(r)
          // setalertcomment("Please upload new Fc copy");
          if (updateDripopup || (!isVehicleRefreshed && isRejectedStatus())) {
            const rcExpiredoc = vehicle.Documents.filter(
              (r, i) => r.name === "Upload RC Document"
            )
            rcExpiredoc[0].fileName = ""
            rcExpiredoc[0].fileLink = ""
            rcExpiredoc[0].pkey = ""
          }
        }
      }
      vehicle[r] = e.target.value.trim()
    }
  }
  const handleClick = (event) => {
    hiddenFileInput?.current?.click()
  }
  const selectState = (e) => {
    const statee = State.getStateByCodeAndCountry(e.target.value, "IN")
    console.log("statee", statee)
    setstate(statee)
    vehicle.State = statee.name
    console.log("sss-,", state)
    const cities = City.getCitiesOfState("IN", e.target.value)
    setcities(cities)
    vehicle.City = ""
  }
  const selectcity = (e) => {
    vehicle.State = state.name
    vehicle.City = e.target.value
    // let cityy = cities.filter((r) => r.name == e.target.value);
    // setsetcitysel(cityy);
    vehicle.Country = "IN"
  }
  const getlist = async () => {
    if (Object.keys(vehiclelist).length == 0) {
      await DtApi.getVechicleList(setvlist)
    }
  }
  useEffect(() => {
    let form = JSON.parse(JSON.stringify(vehicleStore.vehicleRec));
    setformObject(form);
    AguserApi.getList(setLists)

    if (domain === "agraga.com")
      AguserApi.getUsers4role(Roles.CLADMIN, setCadminList)
    else {
      let list = [
        {
          email: loginStore.email,
          firstName: loginStore.userRec.aguserRec.firstName,
          lastName: loginStore.userRec.aguserRec.lastName,
        },
      ]
      setCadminList(list)
    }

    if(vehicle.timeline === null || !vehicle.timeline) {
      vehicle.timeline = []
    }
  }, [])

  const renderLink = (fileName) => {
    if (fileName && fileName.length > 3) {
      return (
        <a
          href={process.env.REACT_APP_API_SERVER + "/clientfiles/" + fileName}
          target="_blank">
          {fileName}
        </a>
      )
    } else return <></>
  }

  const StatesforBranch = (code) => {
    const states = State.getStatesOfCountry(code)
    setstates(states)
  }
  const getStateandCityValue = async () => {
    const Vehicleee = await vehicleStore.vehicleRec
    // console.log("RTYTY" , Entityy);
    //const EntityCountryy = await Entityy?.entityCountry;
    // console.log(EntityCountryy);
    // const EntityCountry = Country.getAllCountries().filter(
    //   (r) => r.name ===  "IN"
    // );
    const Statess = State.getStatesOfCountry("IN")
    console.log("Stt", Statess)
    setstates(Statess)
    const Statecode = Statess.filter((r) => r.name === vehicle?.State)
    // setstateCode(Statecode[0]?.isoCode);
    const cities = City.getCitiesOfState("IN", Statecode[0]?.isoCode)
    setcities(cities)
  }

  useEffect(() => {
    async function setStateCity() {
      getStateandCityValue()
    }
    // const serviceabilityData = set
    setStateCity()
  }, [])

  const stateandcityfn = async () => {
    let states = State.getStatesOfCountry("IN")
    let stateee = await states.filter((r) => r.name == vehicle.State)

    setsetstatesel(stateee[0])
    let city = await City.getCitiesOfState("IN", stateee[0]?.isoCode)
    setcities(city)
    let cityy = await city.filter((r) => r.name == vehicle.City)
    setsetcitysel(cityy[0])
  }

  function change(files, label, filekey) {
    let url = `${process.env.REACT_APP_FILE_SERVER}/api/v1/filestore/storefile`
    const formData = new FormData()
    formData.append("myFile", files[0])
    formData.append("label", label)
    formData.append("key", filekey)
    fetch(url, {
      method: "POST",
      headers: Api.H1(),
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) configStore.setModalMessage(data.error)
        else {
          console.log(data)
          if (
            updateDripopup &&
            filekey === "insurance" &&
            updVehCopy === "InsExpdate"
          ) {
            setalertcomment("")
          }
          if (
            updateDripopup &&
            filekey === "puc" &&
            updVehCopy === "PucExpdate"
          ) {
            setalertcomment("")
          }
          if (
            updateDripopup &&
            filekey === "fc" &&
            updVehCopy === "FcExpdate"
          ) {
            setalertcomment("")
          }
          if (
            updateDripopup &&
            filekey === "rc_document" &&
            updVehCopy === "RcExpdate"
          ) {
            setalertcomment("")
          }
          if (filekey === "rc_document") {
            vehicle.Documents[0].fileLink = data.key.filename
            vehicle.Documents[0].pkey = data.key.pkey
          } else if (filekey === "agreement_sale") {
            vehicle.Documents[1].fileLink = data.key.filename
            vehicle.Documents[1].pkey = data.key.pkey
          } else if (filekey === "insurance") {
            vehicle.Documents[2].fileLink = data.key.filename
            vehicle.Documents[2].pkey = data.key.pkey
          } else if (filekey === "puc") {
            vehicle.Documents[3].fileLink = data.key.filename
            vehicle.Documents[3].pkey = data.key.pkey
          } else if (filekey === "fc") {
            vehicle.Documents[4].fileLink = data.key.filename
            vehicle.Documents[4].pkey = data.key.pkey
          }
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
  const handleClick1 = (event) => {
    hiddenFileInput1?.current?.click()
  }
  const handleClick2 = (event) => {
    hiddenFileInput2?.current?.click()
  }
  const handleClick6 = (event) => {
    hiddenFileInput6?.current?.click()
  }
  const handleClick7 = (event) => {
    hiddenFileInput7?.current?.click()
  }
  const handleClick8 = (event) => {
    hiddenFileInput8?.current?.click()
  }
  const handleClick3 = (event) => {
    hiddenFileInput3?.current?.click()
  }
  const handleClick4 = (event) => {
    hiddenFileInput4?.current?.click()
  }
  const handleClick5 = (event) => {
    hiddenFileInput5?.current?.click()
  }
  useEffect(() => {
    getlist()
    if (props.mode === "edit") {
      stateandcityfn()
    }
  }, [])

  const approvefn = () => {
    setapprovepopup(true)
    document.getElementById("editdriversmaincon").style.filter = "blur(15px)"
    document.getElementById("editdriversmaincon").style.pointerEvents = "none"
    setMessage("")
  }
  const rejectfn = () => {
    setrejectpopup(true)
    document.getElementById("editdriversmaincon").style.filter = "blur(15px)"
    document.getElementById("editdriversmaincon").style.pointerEvents = "none"
    setMessage("")
  }
  // console.log("vehicle--", vehicle);
  // console.log(docArray);
  const approvedrifn = () => {
    let url = `${process.env.REACT_APP_API_SERVER}/api/v1/vechicles/updatestatus`

    const formData = new FormData()
    formData.append("Id", vehicle.Regnumber)
    formData.append("Status", "Verified")
    formData.append("Remarks", "")
    formData.append("updatedBy", loginStore.email)
    formData.append("verifiedBy", loginStore.email)

    fetch(url, {
      method: "POST",
      headers: Api.H1(),
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) configStore.setModalMessage(data.error)
        else {
          configStore.setModalMessage(data.msg)
          vehicle.Status = "Verified"
          routerStore.vehicleCurrentView = "list"
          cancelpopup()
          setappbtnstatus(true)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const rejectdrifn = () => {
    let url = `${process.env.REACT_APP_API_SERVER}/api/v1/vechicles/updatestatus`
    let Remarks = document.getElementById("rejectremarksid").value
    // console.log("Remarks--",Remarks)
    const formData = new FormData()
    formData.append("Id", vehicle.Regnumber)
    formData.append("Status", "Rejected")
    formData.append("Remarks", Remarks)
    formData.append("updatedBy", loginStore.email)
    formData.append("updatedBy", vehicle.verifiedBy)
    formData.append("rejectedBy", loginStore.email)
    fetch(url, {
      method: "POST",
      headers: Api.H1(),
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) configStore.setModalMessage(data.error)
        else {
          configStore.setModalMessage(data.msg)
          vehicle.Status = "Rejected"
          vehicle.Remarks = Remarks
          routerStore.vehicleCurrentView = "list"

          if (vehicle.Remarks === "") {
            configStore.setModalMessage("Please add Remarks")
          }
          cancelpopup()
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const cancelpopup = () => {

    // vehicle.Documents = docArray
    setupdateDripopup(false)
    setrejectpopup(false)
    setapprovepopup(false)
    setalertcomment("")
    document.getElementById("editdriversmaincon").style.filter = "none"
    document.getElementById("editdriversmaincon").style.pointerEvents = "auto"
  }

  const rejectdrifnremove = () => {
    let url = `${process.env.REACT_APP_API_SERVER}/api/v1/vechicles/updatestatus`
    let Remarks = document.getElementById("rejectremarksid").value

    const formData = new FormData()
    formData.append("Id", vehicle.Regnumber)
    formData.append("Status", "Pending")
    formData.append("Remarks", Remarks)
    fetch(url, {
      method: "POST",
      headers: Api.H1(),
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) configStore.setModalMessage(data.error)
        else {
          configStore.setModalMessage(data.msg)
          vehicle.Status = "Pending"
          cancelpopup()
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const statusdropdown = () => {
    let element = document.getElementById("drivstatusdp")
    if (element.style.display === "block") {
      return (document.getElementById("drivstatusdp").style.display = "none")
    }
    document.getElementById("drivstatusdp").style.display = "block"
  }

  function titlefn() {
    if (props.mode === "new") {
      // settitle("Add New Vehicle");
      setselecttag(true)
    } else if (props.mode === "edit") {
      // settitle(`#${vehicle.Regnumber}--->${vehicle.Status}`);
    }
  }

  useEffect(() => {
    titlefn()
  })

  const updatevehfn = () => {
    let docobj = [...vehicle.Documents]
    setnewdlno(vehicle.Vechownname)
    setupdateDripopup(true)
    setDocArray(docobj)
    document.getElementById("editdriversmaincon").style.filter = "blur(15px)"
    document.getElementById("editdriversmaincon").style.pointerEvents = "none"
  }
  const UpdateVehicleDetails = () => {
    if (alertcomment.length > 0) {
      return configStore.setModalMessage(alertcomment)
    }
    console.log(vehicle.Documents)

    let url = `${process.env.REACT_APP_API_SERVER}/api/v1/vechicles/VechileDetailsUpdate`
    // let Comments = `Driving license number changed from ${vehicle.Vechownname} to ${newdlno}`;

    const formData = new FormData()
    formData.append("Vechownname", vehicle.Vechownname)
    formData.append("Status", "Pending")
    formData.append("InsExpdate", vehicle.InsExpdate)
    formData.append("PucExpdate", vehicle.PucExpdate)
    formData.append("FcExpdate", vehicle.FcExpdate)
    formData.append("Documents", JSON.stringify(vehicle.Documents))
    formData.append("Regnumber", vehicle.Regnumber)
    fetch(url, {
      method: "PUT",
      headers: Api.H1(),
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) configStore.setModalMessage(data.error)
        else {
          configStore.setModalMessage(data.msg)
          vehicle.Status = "Pending"
          routerStore.vehicleCurrentView = "list"
          cancelpopup()
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const approvebtnnfn = () => {
    if (document.getElementById("approvebtnid").disabled == true) {
      document.getElementById("approvebtnid").style.opacity = "1"
      setappbtnstatus(false)
      return (document.getElementById("approvebtnid").disabled = false)
    } else if (document.getElementById("approvebtnid").disabled == false) {
      document.getElementById("approvebtnid").style.opacity = "0.4"
      setappbtnstatus(true)
      return (document.getElementById("approvebtnid").disabled = true)
    }
  }

  // let element = document.getElementById("colordate");
  //element.innerHTML = (income > outcome ? "" : "-") + "$";
  // element.style.color = vehicle.InsExpdate > todaydate ? "red" : "black";

  const colordate = () => {
    console.log("jfdndjdn")
    console.log("dddddd--", vehicle.InsExpdate)
    console.log("ttttttt--", todaydate)
    if (vehicle.InsExpdate < todaydate) {
      return (document.getElementById("colordate").style.borderColor = "red") // style={{ color: "skyblue" }}
    }
  }

  const showselecttag = () => {
    const StateCode = State.getStatesOfCountry("IN").filter(
      (r, i) => r.name === vehicle.State
    )
    const statee = State.getStateByCodeAndCountry(StateCode[0].isoCode, "IN")
    setstate(statee)
    const cities = City.getCitiesOfState("IN", StateCode[0].isoCode)
    setcities(cities)
    setselecttag(!selecttag)
  }
  const submitRemarks = (e) => {
    // if (
    //   e.target.value.length === 0
    // ) {
    //   setrejbtnstatus(true);
    //   configStore.setModalMessage("Please add Remarks");
    //   return;
    // }

    // vehicle[r] = trimStr(e.target.value);
    if (e.target.value.trim().length > 1) {
      setrejbtnstatus(false)

      return (document.getElementById("rejectbtnid").style.opacity = "1")
    } else if (e.target.value.trim().length < 1) {
      setrejbtnstatus(true)
      return (document.getElementById("rejectbtnid").style.opacity = "0.4")
    }
  }

  const handleMessageChange = (event) => {
    if (event.target.value.length > 0) {
      // if(setappbtnstatus(true))

      document.getElementById("rejectbtnid").style.opacity = "1"
      setrejbtnstatus(false)
    } else if (event.target.value.length === 0) {
      document.getElementById("rejectbtnid").style.opacity = "0.4"
      setrejbtnstatus(true)
    }

    setMessage(event.target.value)
    //  setappbtnstatus(false);
  }
  function isEditMode() {
    return props.mode === "edit"
  }

  function isViewMode() {
    return props.mode === "view"
  }
  function isNewMode() {
    return props.mode === "new"
  }

  function isPendingStatus() {
    return vehicle?.Status === "Pending"
  }

  function isRejectedStatus() {
    return vehicle?.Status === "Rejected"
  }
  function isVerifiedStatus() {
    return vehicle?.Status === "Verified"
  }
  function isFcExpiredStatus() {
    return vehicle?.Status.includes("FcExpired")
  }
  function isInsExpiredStatus() {
    return vehicle?.Status.includes("InsExpired")
  }
  function isPucExpiredStatus() {
    return vehicle?.Status.includes("PUCExpired")
  }

  function isRcExpiredStatus() {
    return vehicle?.Status === "RcExpired"
  }




  const getVechileDetails = (isRefreshed) => {
    if (vehicle.Regnumber === "") {
      configStore.setModalMessage(
        `Please enter the Registration Number`
      )
      return
    }
    loader.show()
    let url = `${process.env.REACT_APP_API_SERVER}/api/v1/vechicles/getVechileDetails?vehicleNumber=${vehicle['Regnumber']}&updateFlag=${isRefreshed}`


    const fetchData = async () => {
      try {
        const abortController = new AbortController()

        const timeoutPromise = new Promise((_, reject) =>
          setTimeout(() => {
            abortController.abort()
            loader.hide()
          }, 10000)
        )


        const response = await Promise.race([
          fetch(url, {
            method: "GET",
            headers: Api.H1(),
            signal: abortController.signal,
          }),
          timeoutPromise,
        ])



        const data = await response.json()

        if (data) {
          loader.hide()
          vehicle.vehicleDataFlag = true
        }
        if (!data.error) {
          if (isRefreshed) {
            setIsVehicleRefreshed(isRefreshed)
            vehicle.Documents.map(doc => {
              if ((data.rc_regn_upto == "" || data.rc_regn_upto > todaydate) && doc.fileLink != "" && doc.pkey != "" && doc.name == "Upload RC Document") {
                doc.fileLink = ""
                doc.pkey = ""
              }
              if ((data.rc_insurance_upto == "" || data.rc_insurance_upto > todaydate) && doc.fileLink != "" && doc.pkey != "" && doc.name == "Upload Insurance") {
                doc.fileLink = ""
                doc.pkey = ""
              }
              if ((data.rc_pucc_upto == "" || data.rc_pucc_upto > todaydate) && doc.fileLink != "" && doc.pkey != "" && doc.name == "Upload PUC") {
                doc.fileLink = ""
                doc.pkey = ""
              }
              if ((data.rc_fit_upto == "" || data.rc_fit_upto > todaydate) && doc.fileLink != "" && doc.pkey != "" && doc.name == "Upload FC") {
                doc.fileLink = ""
                doc.pkey = ""
              }
            })
          }
          vehicle.ulip = true
          vehicle.MakerModel = data.rc_maker_model
          vehicle.State = data.state
          if(data.state_cd == "CG"){
            vehicle.StateCode = "CT"
          }else if(data.state_cd == "DN"){
            vehicle.StateCode = "DH"
          }else if(data.state_cd == "OD"){
            vehicle.StateCode = "OR"
          }else if(data.state_cd == "UK"){
            vehicle.StateCode = "UT"
          }else{
            vehicle.StateCode = data.state_cd
          }
          // vehicle.Vechownname = data.rc_owner_name;
          vehicle.InsExpdate = data.rc_insurance_upto
          vehicle.PucExpdate = data.rc_pucc_upto
          vehicle.FcExpdate = data.rc_fit_upto
          vehicle.RcIssuedate = data.rc_regn_dt
          vehicle.RcExpdate = data.rc_regn_upto
          console.log('vehicleData', vehicle)
        }
      } catch (error) {
        loader.hide()
        configStore.setModalMessage(`ULIP server is not reachable. Try again later or add manually`)
        vehicle.vehicleDataFlag = true
      }

    }

    fetchData()
    setulippopup(false)

  }



  return (
    <>
       <div id="editdriversmaincon" style={(ulippopup && ((vehicle.Status.includes('Expired') && vehicle.ulip)|| (vehicle.Status == 'Rejected' && vehicle.ulip)) && props.mode != 'view') ? {filter:"blur(15px)",pointerEvents:"none"}:{}}>
        <h1 className="title is-flex is-flex-direction-row is-justify-content-space-between">
          <span> {title}</span>
          {(((vehicle.Status === "Verified" && !vehicle.ulip) || (vehicle.Status.includes('Expired') && !vehicle.ulip)) && !isViewMode()) ?
          (<>              
          <button 
          className="is-clickable" 
          style={{ border: "1px solid #0099CC", borderRadius: "5px", height: "35px", color: "#0099CC", opacity: 0.4, }} 
          onClick={updatevehfn}>                
          Update Vehicle Details              
          </button>            
          </>) : null}

      
          {props.mode === "edit" ? (
            <span className="is-clickable is-flex is-flex-direction-column is-align-item-center">
              {vehicle?.Status === "Pending" ? (
                <CgMoreVertical
                  onClick={statusdropdown}
                  style={{
                    float: "right",
                    marginRight: "2em",
                    cursor: "pointer",
                  }}
                />
              ) : null}
              <span
                className=""
                id="drivstatusdp"
                style={{ display: "none", width: "5rem" }}>
                {
                  (RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.VEHICLES_APPROVE_REJECT)) &&
                  <div className="is-size-6 ">
                    <p
                      className=""
                      style={{
                        border: "1px solid black",
                        padding: "3% 2%",

                        borderRadius: "0.4rem",
                      }}
                      onClick={approvefn}>
                      Approve
                    </p>
                    <p
                      style={{
                        border: "1px solid black",
                        padding: "3% 2%",
                        borderRadius: "0.4rem",
                      }}
                      onClick={rejectfn}>
                      Reject
                    </p>
                  </div>
                }
              </span>
            </span>
          ) : null}
        </h1>

        <div style={{ padding: "0rem 5rem" }}>
          <Columns>
            <Col5>
              <p className="label is-small" style={{ marginBottom: "0px" }}>
                Registration Number *
              </p>

              <input
                maxLength={10}
                type="text"
                className="input is-small "
                value={vehicle?.Regnumber}
                ref={ref}
                id="gst"
                pattern="[A-Z0-9]{10}"
                onChange={(e) => handleChange(e, "Regnumber")}
                {...(isViewMode() || isEditMode() || (vehicle?.ulip && vehicle?.Regnumber && isNewMode())
                  ? { disabled: true }
                  : {
                    ...(isEditMode() &&
                      (isPendingStatus() || isVerifiedStatus())
                      ? { disabled: true }
                      : {}),
                  })}
              />
            </Col5>
            {(!vehicle['vehicleDataFlag'] && isNewMode()) &&
              <Col3>
                <button
                  className="is-clickable"
                  onClick={(e) => getVechileDetails(false)}
                  style={{ margin: '20px', backgroundColor: "#2a75de", padding: "1%", fontWeight: '600', color: 'white' }}
                >Get Vehicle Details</button>
              </Col3>}
            {((vehicle['vehicleDataFlag'] && isNewMode()) || isEditMode() || isViewMode()) &&
              <Col5>
                <p className="label is-small" style={{ marginBottom: "0px" }}>
                  Vehicle Type *
                </p>
                <div className="is-flex">
                  <select
                    className="select is-small "
                    style={{ width: "100%", border: "1px solid #d1d1e0" }}
                    value={vehicle.Vechtype}
                    {...(isViewMode() ? { disabled: true } : {})}
                    onChange={(e) => (vehicle.Vechtype = e.target.value)}
                    // {...(isViewMode()
                    //   ? { disabled: true }
                    //   : {
                    //       ...(isEditMode() &&
                    //       (isPendingStatus() || isVerifiedStatus())
                    //         ? { disabled: true }
                    //         : {}),
                    //     })}
                    {...(isViewMode()
                      ? { disabled: true }
                      : {
                        ...((isEditMode() && isRejectedStatus()) || isNewMode()
                          ? {}
                          : { disabled: true }),
                      })}>
                    <option value="">Select Vehicle Type</option>

                    {Object.entries(vehiclelist).map(([key, value]) => (
                      <option value={value} placeholder="">
                        {value}
                      </option>
                    ))}
                  </select>
                  {
                    vehicle.ulip &&
                    <button title={vehicle['MakerModel'] && vehicle['MakerModel']} className="button is-dark is-inverted"
                      style={{ paddingLeft: '15px' }} onClick={null}>
                      <span className="icon is-small">
                        <i className="fas fa-eye"></i>
                      </span>
                    </button>
                  }
                </div>
              </Col5>}

          </Columns>

          {/* // {selecttag ? (
           */}
          {((vehicle['vehicleDataFlag'] && isNewMode()) || isEditMode() || isViewMode()) &&
            <Columns>
              <Col5>
                <p className="label is-small" style={{ marginBottom: "0px" }}>
                  State*
                </p>

                <select
                  className="select is-small"
                  // type="text"
                  style={{ width: "100%", border: "1px solid #d1d1e0" }}
                  ref={ref}
                  //value={state.isoCode}
                  value={vehicle?.State}
                  // onChange={(e) => selectState(e)}
                  onChange={(e) => {
                    vehicle.StateFlag = true
                    handleChange(e, "State")
                  }}
                  {...((isViewMode() || (vehicle?.ulip && vehicle?.State && !vehicle.StateFlag && (isNewMode() || isEditMode()))) ? { disabled: true } : {})}
                // {...(isViewMode()
                //   ? { disabled: true }
                //   : {
                //       ...(isEditMode()
                //         ? { disabled: true }
                //         : {}),
                //     })}
                >
                  <option value="">{vehicle?.State ? vehicle?.State : 'Select State'}</option>
                  {
                    State.getStatesOfCountry("IN").map((r, i) => {
                      //  console.log("Satte",State)
                      return (
                        <option value={r.name + "/" + r.isoCode}>{r.name}</option>
                      )
                    })}
                </select>
              </Col5>
              <Col5>
                <p className="label is-small" style={{ marginBottom: "0px" }}>
                  City *
                </p>

                <select
                  className="select is-small"
                  // type="text"
                  style={{ width: "100%", border: "1px solid #d1d1e0" }}
                  ref={ref}
                  value={vehicle?.City}
                  // onChange={(e) => selectcity(e)}

                  {...(isViewMode() ? { disabled: true } : {})}
                  //onChange={(e) => (vehicle.City = e.target.value)}
                  onChange={(e) => handleChange(e, "City")}
                // {...(isViewMode()
                //   ? { disabled: true }
                //   : {
                //       ...(isEditMode() && isPendingStatus()
                //         ? { disabled: true }
                //         : {}),
                //     })}
                >
                  <option value="">Select City</option>
                  {City?.getCitiesOfState(
                    // Entity?.entityCountryCode,
                    "IN",
                    vehicle['StateCode'] ? vehicle['StateCode'] : vehicle.State?.split("/")[1]
                  ).map((r, i) => {
                    return <option value={r.name}>{r.name}</option>
                  })}
                </select>
              </Col5>
            </Columns>}


          {((vehicle['vehicleDataFlag'] && isNewMode()) || isEditMode() || isViewMode()) &&
            <Columns>
              <Col5>
                <p className="label is-small" style={{ marginBottom: "0px" }}>
                  Vehicle Owner Name*
                </p>

                <input
                  type="text"
                  className="input is-small "
                  value={vehicle?.Vechownname}
                  ref={ref}
                  pattern="[a-zA-Z0-9]"
                  onKeyDown={(e) =>
                    ["+", "-", "_"].includes(e.key) && e.preventDefault()
                  }
                  onChange={(e) => handleChange(e, "Vechownname")}
                  // {...(isViewMode()
                  //   ? { disabled: true }
                  //   : {
                  //       ...(isEditMode() &&
                  //       (isPendingStatus() || isVerifiedStatus())
                  //         ? { disabled: true }
                  //         : {}),
                  //     })}

                  {...(isViewMode()
                    ? { disabled: true }
                    : {
                      ...((isEditMode() && isRejectedStatus()) || isNewMode() 
                        ? {}
                        : { disabled: true }),
                    })}
                />
              </Col5>
              <Col5>
                <p className="label is-small" style={{ marginBottom: "0px" }}>
                  Insurance Expiry Date*
                </p>  
                <div className="is-flex">
                  <input
                    type="date"
                    min={todaydate}
                    className="input is-small"
                    id="colordate"
                    value={vehicle.InsExpdate}
                    // style={{ color: "red" }}
                    style={{
                      border:
                        vehicle.InsExpdate < todaydate
                          ? isEditMode()
                            ? "1px solid red"
                            : null
                          : null,
                      outline: "none",
                      //  borderColor: "1px solid blue"
                      // borderColor: vehicle.InsExpdate < todaydate ? "1px solid blue" :null,
                    }}
                    ref={ref}
                    onChange={(e) => {
                      vehicle.InsExpdateFlag = true
                      handleChange(e, "InsExpdate")
                      colordate()
                    }}
                    {...((isViewMode() || (vehicle?.ulip && vehicle?.InsExpdate && isNewMode() && !vehicle.InsExpdateFlag) || (isVehicleRefreshed && vehicle?.InsExpdate && isEditMode())|| vehicle.Status.includes('Expire') || isVerifiedStatus())
                      ? { disabled: true }
                      : {
                        ...((isEditMode() && isRejectedStatus()) || isNewMode() || (vehicle?.InsExpdate < todaydate) || (isEditMode() && isVehicleRefreshed && vehicle.InsExpdateFlag) 
                          ? {}
                          : { disabled: true }),
                      })}
                  />

                  <span class="icon mt-1 ml-3 is-size-4" style={{ cursor: "pointer", }}>
                    {vehicle?.Documents[2]?.fileLink?.length > 0 ? (
                      <>
                        <span className="ml-2 mr-1">
                          <a
                            href={
                              process.env.REACT_APP_FILE_SERVER +
                              "/api/v1/filestore/getfile/" +
                              vehicle?.Documents[2]?.pkey +
                              "/" +
                              vehicle?.Documents[2]?.fileLink
                            }
                            target="_blank"
                            rel="noreferrer"
                            alt="">
                            <BsFillEyeFill />
                          </a>
                        </span>
                        {isNewMode() || isRejectedStatus() || (isEditMode() && vehicle.ulip && (!isVehicleRefreshed && (isRejectedStatus() || vehicle.Status.includes('Expire')))) ? (
                          <>
                            <span>
                              <MdDelete

                                style={{ cursor: "pointer", }}
                                onClick={() => {
                                  delDocs(2)
                                  vehicle.InsExpdateFlag = true
                                  if(hiddenFileInput2?.current){
                                  (hiddenFileInput2.current.value = "")}
                                }}
                              />
                            </span>
                          </>
                        ) : null}
                      </>
                    ) : null}
                    {((((vehicle['vehicleDataFlag'] && vehicle.InsExpdateFlag) || (vehicle?.InsExpdate < todaydate)) && isNewMode()) || ((vehicle.InsExpdateFlag || (vehicle?.InsExpdate < todaydate)) && isEditMode()) ) ? (
                      // {isNewMode() || isEditMode() || (vehicle['vehicleDataFlag'] && vehicle.InsExpdateFlag) || (vehicle?.InsExpdate < todaydate) ?(
                      <i
                        class="fa-solid fa-file-arrow-up"

                        onClick={handleClick2}
                        {...(isViewMode()
                          ? { disabled: true }
                          : {
                            ...((isEditMode() && isRejectedStatus()) ||
                              isNewMode()
                              ? {}
                              : { disabled: true }),
                          })}
                        style={{
                          ...(vehicle?.Documents[2]?.fileLink?.length > 0
                            ? { display: "none" }
                            : null),


                        }}>
                        <input
                          type="file"
                          onChange={(e1) => {
                            const pattern = /^\S[\s\S]*\S$|^\S$/
                            if (pattern.test(e1.target.files[0]["name"])) {
                              change(e1.target.files, "Regnumber", "insurance")
                            } else {
                              configStore.setModalMessage(
                                "Please Check File Name and Format"
                              )
                              return (e1.target.value = "")
                            }
                          }}
                          name="image"
                          accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                          multiple=""
                          ref={hiddenFileInput2}
                          data-original-title="upload photos"
                          style={{ display: "none" }}
                          {...(isViewMode()
                            ? { disabled: true }
                            : {
                              ...((isEditMode() && isRejectedStatus()) || (isEditMode() && isVehicleRefreshed) ||(isEditMode() && vehicle.ulip) ||
                                isNewMode()
                                ? {}
                                : { disabled: true }),
                            })}
                        />
                      </i>
                    ) : null}
                  </span>

                </div>
              </Col5>
            </Columns>}
          {((vehicle['vehicleDataFlag'] && isNewMode()) || isEditMode() || isViewMode()) &&
            <Columns>
              <Col5>
                <p className="label is-small" style={{ marginBottom: "0px" }}>
                  PUC Expiry Date*
                </p>
                <div className="is-flex">
                  <input
                    type="date"
                    min={todaydate}
                    className="input is-small "
                    value={vehicle.PucExpdate}
                    ref={ref}
                    style={{
                      border:
                        vehicle.PucExpdate < todaydate
                          ? isEditMode()
                            ? "1px solid red"
                            : null
                          : null,
                      outline: "none",
                      //  borderColor: "1px solid blue"
                      // borderColor: vehicle.InsExpdate < todaydate ? "1px solid blue" :null,
                    }}
                    onChange={(e) => {
                      vehicle.PucExpdateFlag = true
                      handleChange(e, "PucExpdate")
                    }}
                    {...((isViewMode() || (vehicle?.ulip && vehicle?.PucExpdate && isNewMode() && !vehicle.PucExpdateFlag) || (isVehicleRefreshed && vehicle?.PucExpdate && isEditMode())|| vehicle.Status.includes('Expire') || isVerifiedStatus())
                      ? { disabled: true }
                      : {
                        ...((isEditMode() && isRejectedStatus()) || isNewMode() || (vehicle?.PucExpdate < todaydate) || (isEditMode() && isVehicleRefreshed && vehicle.PucExpdateFlag)
                          ? {}
                          : { disabled: true }),
                      })}
                  />

                  <span class="icon mt-1 ml-3 is-size-4" style={{ cursor: "pointer", }}>
                    {vehicle?.Documents[3]?.fileLink?.length > 0 ? (
                      <>
                        <span className="ml-2 mr-1">
                          <a
                            href={
                              process.env.REACT_APP_FILE_SERVER +
                              "/api/v1/filestore/getfile/" +
                              vehicle?.Documents[3]?.pkey +
                              "/" +
                              vehicle?.Documents[3]?.fileLink
                            }
                            target="_blank"
                            rel="noreferrer"
                            alt="">
                            <BsFillEyeFill />
                          </a>
                        </span>
                        {isNewMode() || (isEditMode() && isRejectedStatus()) || (isEditMode() && vehicle.ulip && (!isVehicleRefreshed && (isRejectedStatus() || vehicle.Status.includes('Expire')))) ? (
                          <>
                            <span>
                              <MdDelete
                                style={{ cursor: "pointer", }}
                                // onClick={() => (
                                //   delDocs(3),
                                //   (hiddenFileInput4.current.value = "")
                                // )}
                                onClick={() => {
                                  delDocs(3)
                                  vehicle.PucExpdateFlag = true
                                  if(hiddenFileInput4?.current){
                                  (hiddenFileInput4.current.value = "")}
                                }}
                              />
                            </span>
                          </>
                        ) : null}
                      </>
                    ) : null}
                    {((((vehicle['vehicleDataFlag'] && vehicle.PucExpdateFlag) || (vehicle?.PucExpdate < todaydate)) && isNewMode()) || ((vehicle.PucExpdateFlag || (vehicle?.PucExpdate < todaydate)) && isEditMode())) ? (
                      <i
                        class="fa-solid fa-file-arrow-up "

                        onClick={handleClick4}
                        {...(isViewMode()
                          ? { disabled: true }
                          : {
                            ...((isEditMode() && isRejectedStatus()) ||
                              isNewMode()
                              ? {}
                              : { disabled: true }),
                          })}
                        style={{
                          ...(vehicle?.Documents[3]?.fileLink?.length > 0
                            ? { display: "none" }
                            : null),

                        }}>
                        <input
                          type="file"
                          onChange={(e1) => {
                            const pattern = /^\S[\s\S]*\S$|^\S$/
                            if (pattern.test(e1.target.files[0]["name"])) {
                              change(e1.target.files, "Regnumber", "puc")
                            } else {
                              configStore.setModalMessage(
                                "Please Check File Name and Format"
                              )
                              return (e1.target.value = "")
                            }
                          }}
                          name="image"
                          accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                          multiple=""
                          ref={hiddenFileInput4}
                          data-original-title="upload photos"
                          style={{ display: "none" }}
                          {...(isViewMode()
                            ? { disabled: true }
                            : {
                              ...((isEditMode() && isRejectedStatus()) || (isEditMode() && isVehicleRefreshed) || (isEditMode() && vehicle.ulip) ||
                                isNewMode()
                                ? {}
                                : { disabled: true }),
                            })}
                        />
                      </i>
                    ) : null}
                  </span>
                </div>
              </Col5>
              <Col5>
                <p className="label is-small" style={{ marginBottom: "0px" }}>
                  FC Expiry Date*
                </p>
                <div className="is-flex">
                  <input
                    type="date"
                    min={todaydate}
                    className="input is-small "
                    value={vehicle.FcExpdate}
                    style={{
                      border:
                        vehicle.FcExpdate < todaydate
                          ? isEditMode()
                            ? "1px solid red"
                            : null
                          : null,
                      outline: "none",
                      //  borderColor: "1px solid blue"
                      // borderColor: vehicle.InsExpdate < todaydate ? "1px solid blue" :null,
                    }}
                    ref={ref}
                    onChange={(e) => {
                      vehicle.FcExpdateFlag = true
                      handleChange(e, "FcExpdate")
                    }}
                    // {...(isViewMode()
                    //   ? { disabled: true }
                    //   : {
                    //       ...(isEditMode() &&
                    //       (isPendingStatus() || isVerifiedStatus()
                    //       ||
                    //       isInsExpiredStatus() ||
                    //       isPucExpiredStatus() ||
                    //       isFcExpiredStatus()

                    //       )
                    //         ? { disabled: true }
                    //         : {}),
                    //     })}

                    {...((isViewMode() || (vehicle?.ulip && vehicle?.FcExpdate && isNewMode() && !vehicle.FcExpdateFlag) || (isVehicleRefreshed && vehicle?.FcExpdate && isEditMode()) || vehicle.Status.includes('Expire') || isVerifiedStatus())
                      ? { disabled: true }
                      : {
                        ...((isEditMode() && isRejectedStatus()) || isNewMode() || (vehicle?.FcExpdate < todaydate) || (isEditMode() && isVehicleRefreshed && vehicle.FcExpdateFlag)
                          ? {}
                          : { disabled: true }),
                      })}
                  />

                  <span class="icon mt-1 ml-3 is-size-4" style={{ cursor: "pointer", }}>
                    {vehicle?.Documents[4]?.fileLink?.length > 0 ? (
                      <>
                        <span className="ml-2 mr-1">
                          <a
                            href={
                              process.env.REACT_APP_FILE_SERVER +
                              "/api/v1/filestore/getfile/" +
                              vehicle?.Documents[4]?.pkey +
                              "/" +
                              vehicle?.Documents[4]?.fileLink
                            }
                            target="_blank"
                            rel="noreferrer"
                            alt="">
                            <BsFillEyeFill />
                          </a>
                        </span>
                        {isNewMode() || (isEditMode() && isRejectedStatus()) || (isEditMode() && vehicle.ulip && (!isVehicleRefreshed && (isRejectedStatus() || vehicle.Status.includes('Expire')))) ? (
                          <>
                            <span>
                              <MdDelete
                                style={{ cursor: "pointer", }}
                                // onClick={() => (
                                //   delDocs(4),
                                //   (hiddenFileInput5.current.value = "")
                                // )}
                                onClick={() => {
                                  delDocs(4)
                                  vehicle.FcExpdateFlag = true
                                  if(hiddenFileInput5?.current){
                                  (hiddenFileInput5.current.value = "")}
                                }}
                              />
                            </span>
                          </>
                        ) : null}
                      </>
                    ) : null}
                    {((((vehicle['vehicleDataFlag'] && vehicle.FcExpdateFlag) || (vehicle?.FcExpdate < todaydate)) && isNewMode()) || ((vehicle.FcExpdateFlag || (vehicle?.FcExpdate < todaydate)) && isEditMode())) ? (
                         <i
                        class="fa-solid fa-file-arrow-up "
                        onClick={handleClick5}
                        {...(isViewMode()
                          ? { disabled: true }
                          : {
                            ...((isEditMode() && isRejectedStatus()) ||
                              isNewMode()
                              ? {}
                              : { disabled: true }),
                          })}
                        style={{
                          ...(vehicle?.Documents[4]?.fileLink?.length > 0
                            ? { display: "none" }
                            : null),
                        }}>
                        <input
                          type="file"
                          onChange={(e1) => {
                            change(e1.target.files, "Regnumber", "fc")
                          }}
                          name="image"
                          accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                          multiple=""
                          ref={hiddenFileInput5}
                          data-original-title="upload photos"
                          style={{ display: "none" }}
                          {...(isViewMode()
                            ? { disabled: true }
                            : {
                              ...((isEditMode() && isRejectedStatus()) || (isEditMode() && isVehicleRefreshed) || (isEditMode() && vehicle.ulip) ||
                                isNewMode()
                                ? {}
                                : { disabled: true }),
                            })}
                        />
                      </i>
                    ) : null}
                  </span>
                </div>
              </Col5>
            </Columns>}
          {((vehicle['vehicleDataFlag'] && isNewMode()) || isEditMode() || isViewMode()) &&
            <Columns>
              <Col5>
                <p className="label is-small" style={{ marginBottom: "0px" }}>
                  RC Issue Date*
                </p>

                <input
                  type="date"
                  max={pastdate}
                  className="input is-small "
                  value={vehicle.RcIssuedate}
                  ref={ref}
                  onChange={(e) => {
                    vehicle.RcIssuedateFlag = true
                    handleChange(e, "RcIssuedate")
                  }}
                  // {...(isViewMode()
                  //   ? { disabled: true }
                  //   : {
                  //       ...(isEditMode() &&
                  //       (isPendingStatus() || isVerifiedStatus()
                  //       ||
                  //         isInsExpiredStatus() ||
                  //         isPucExpiredStatus() ||
                  //         isFcExpiredStatus()

                  //       )
                  //         ? { disabled: true }
                  //         : {}),
                  //     })}

                  {...((isViewMode() || (vehicle?.ulip && vehicle?.RcIssuedate && isNewMode()) || (isVehicleRefreshed && vehicle?.RcIssuedate && isEditMode()))
                    ? { disabled: true }
                    : {
                      ...((isEditMode() && isRejectedStatus()) || isNewMode() || (isEditMode() && isVehicleRefreshed && vehicle.RcIssuedateFlag) || vehicle.Status.includes('Expire')
                        ? {}
                        : { disabled: true }),
                    })}
                />
              </Col5>
              <Col5>
                <p className="label is-small" style={{ marginBottom: "0px" }}>
                  RC Expiry Date*
                </p>
                <div className="is-flex">
                  <input
                    type="date"
                    min={todaydate}
                    className="input is-small "
                    value={vehicle.RcExpdate}
                    style={{
                      border:
                        vehicle.RcExpdate < todaydate
                          ? isEditMode()
                            ? "1px solid red"
                            : null
                          : null,
                      //  borderColor:  isNewMode() ? "1px solid blue" :null
                      // borderColor: vehicle.InsExpdate < todaydate ? "1px solid blue" :null,
                    }}
                    ref={ref}
                    onChange={(e) => {
                      vehicle.RcExpdateFlag = true
                      handleChange(e, "RcExpdate")
                    }}
                    // {...(isViewMode()
                    //   ? { disabled: true }
                    //   : {
                    //       ...(isEditMode() &&
                    //       (isPendingStatus() || isVerifiedStatus()
                    //       ||
                    //       isInsExpiredStatus() ||
                    //       isPucExpiredStatus() ||
                    //       isFcExpiredStatus()

                    //       )
                    //         ? { disabled: true }
                    //         : {}),
                    //     })}

                    {...((isViewMode() || (vehicle?.ulip && vehicle?.RcExpdate && isNewMode() && !vehicle.RcExpdateFlag) || (isVehicleRefreshed && vehicle?.RcExpdateFlag && isEditMode()) || vehicle.Status.includes('Expire') || isVerifiedStatus())
                      ? { disabled: true }
                      : {
                        ...((isEditMode() && isRejectedStatus()) || isNewMode() || (vehicle?.RcExpdate < todaydate) || (isEditMode() && isVehicleRefreshed && vehicle.RcExpdateFlag) 
                          ? {}
                          : { disabled: true }),
                      })}
                  />
                </div>
              </Col5>
            </Columns>}

          <Columns>
            {((vehicle['vehicleDataFlag'] && !vehicle.ulip) || (isEditMode() && !vehicle.ulip) || ((isEditMode()||isNewMode()) && vehicle.RcExpdateFlag) || isViewMode() || isRcExpiredStatus() || (isPendingStatus() && vehicleStore.vehicleRec.Documents[0].fileLink.length > 0)) &&
              <Col4>
                <div className="is-flex">
                  <p
                    className="label is-small"
                    style={{
                      marginTop: "18px",
                      fontSize: "15px",
                      marginRight: "8px",
                    }}>
                    Upload RC Document*
                  </p>

                  <span class="icon mt-5 ml-2 is-size-4" style={{ cursor: "pointer", }}>
                    {vehicle?.Documents[0]?.fileLink?.length > 0 ? (
                      <>
                        <span className="ml-2 mr-1">
                          <a
                            href={
                              process.env.REACT_APP_FILE_SERVER +
                              "/api/v1/filestore/getfile/" +
                              vehicle?.Documents[0]?.pkey +
                              "/" +
                              vehicle?.Documents[0]?.fileLink
                            }
                            target="_blank"
                            rel="noreferrer"
                            alt="">
                            <BsFillEyeFill />
                          </a>
                        </span>
                        {isNewMode() || (isEditMode() && isRejectedStatus()) || (isEditMode() && vehicle.ulip && (!isVehicleRefreshed && (isRejectedStatus() || vehicle.Status.includes('Expire')))) ? (
                          <>
                            <span>
                              <MdDelete
                                style={{ cursor: "pointer", }}
                                onClick={() => (
                                  delDocs(0),vehicle.RcExpdateFlag = true,
                                  (hiddenFileInput1.current.value = "")

                                )}
                              />
                            </span>
                          </>
                        ) : null}
                      </>
                    ) : null}
                    {isNewMode() || isEditMode() ? (
                      <i
                        class="fa-solid fa-file-arrow-up"
                        onClick={handleClick1}
                        {...(isViewMode()
                          ? { disabled: true }
                          : {
                            ...((isEditMode() && isRejectedStatus()) ||
                              isNewMode()
                              ? {}
                              : { disabled: true }),
                          })}
                        style={{
                          ...(vehicle?.Documents[0]?.fileLink?.length > 0
                            ? { display: "none" }
                            : null),
                        }}>
                        <input
                          type="file"
                          onChange={(e1) => {
                            const pattern = /^\S[\s\S]*\S$|^\S$/
                            if (pattern.test(e1.target.files[0]["name"])) {
                              change(e1.target.files, "Regnumber", "rc_document")
                            } else {
                              configStore.setModalMessage(
                                "Please Check File Name and Format"
                              )
                              return (e1.target.value = "")
                            }
                          }}
                          name="image"
                          accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                          multiple=""
                          ref={hiddenFileInput1}
                          data-original-title="upload photos"
                          style={{ display: "none" }}
                          {...(isViewMode()
                            ? { disabled: true }
                            : {
                              ...((isEditMode() && isRejectedStatus()) || (isEditMode() && isVehicleRefreshed) || (isEditMode() && vehicle.ulip) ||
                                isNewMode()
                                ? {}
                                : { disabled: true }),
                            })}
                        />
                      </i>
                    ) : null}
                  </span>
                </div>
              </Col4>
            }
            {((vehicle['vehicleDataFlag'] && isNewMode()) || isEditMode() || isViewMode()) &&
              <Col4>
                <div className="is-flex">
                  <p
                    className="label is-small"
                    style={{
                      marginTop: "18px",
                      fontSize: "15px",
                      marginRight: "8px",
                    }}>
                    Upload Agreement of Sale
                  </p>
                  <span class="icon mt-5 ml-2 is-size-4" style={{ cursor: "pointer", }}>
                    {vehicle?.Documents[1]?.fileLink?.length > 0 ? (
                      <>
                        <span className="ml-2 mr-1">
                          <a
                            href={
                              process.env.REACT_APP_FILE_SERVER +
                              "/api/v1/filestore/getfile/" +
                              vehicle?.Documents[1]?.pkey +
                              "/" +
                              vehicle?.Documents[1]?.fileLink
                            }
                            target="_blank"
                            rel="noreferrer"
                            alt="">
                            <BsFillEyeFill />
                          </a>
                        </span>
                        {isNewMode() || isRejectedStatus() || (isEditMode() && vehicle.ulip && (!isVehicleRefreshed && (isRejectedStatus() || vehicle.Status.includes('Expire')))) ? (
                          <>
                            <span>
                              <MdDelete
                                style={{ cursor: "pointer", }}
                                onClick={() => (

                                  delDocs(1),
                                  (hiddenFileInput3.current.value = "")
                                )}
                              />
                            </span>
                          </>
                        ) : null}
                      </>
                    ) : null}
                    {isNewMode() || isEditMode() ? (
                      <i
                        class="fa-solid fa-file-arrow-up "
                        onClick={handleClick3}
                        {...(isViewMode()
                          ? { disabled: true }
                          : {
                            ...((isEditMode() && isRejectedStatus()) ||
                              isNewMode()
                              ? {}
                              : { disabled: true }),
                          })}
                        style={{
                          ...(vehicle?.Documents[1]?.fileLink?.length > 0
                            ? { display: "none" }
                            : null),
                        }}>
                        <input
                          type="file"
                          onChange={(e1) => {
                            const pattern = /^\S[\s\S]*\S$|^\S$/
                            if (pattern.test(e1.target.files[0]["name"])) {
                              change(
                                e1.target.files,
                                "Regnumber",
                                "agreement_sale"
                              )
                            } else {
                              configStore.setModalMessage(
                                "Please Check File Name and Format"
                              )
                              return (e1.target.value = "")
                            }
                          }}
                          name="image"
                          accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                          multiple=""
                          ref={hiddenFileInput3}
                          data-original-title="upload photos"
                          style={{ display: "none" }}
                          {...(isViewMode()
                            ? { disabled: true }
                            : {
                              ...((isEditMode() && isRejectedStatus()) || (isEditMode() && vehicle.ulip) ||
                                isNewMode()
                                ? {}
                                : { disabled: true }),
                            })}
                        />
                      </i>
                    ) : null}
                  </span>
                </div>
              </Col4>}
          </Columns>
        </div>
        {vehicle.Status == "Rejected" ? (
          <div className="is-size-5 ml-6" style={{ marginTop: "15px" }}>
            <span className="ml-6 has-text-weight-bold ">Remarks :</span>
            <span className="ml-3">{vehicle.Remarks}</span>
          </div>
        ) : null}
        <div className="columns">
          <$c n="1"></$c>
          <$c n="9"></$c>
        </div>
        {((vehicle['vehicleDataFlag'] && isNewMode()) || isEditMode() || isViewMode()) &&
          <div className="columns">
            <$cr n="1"></$cr>
            <$c n="3">
              <div className="is-flex">
                <>
                  <button
                    className="button is-primary"
                    onClick={() =>{
                      AgmodalStore.pageVisible = false;
                      AgmodalStore.apiCall = true;
                      if (props.fn) {
                        return routerStore.creditApplicationView ="list"
                      }
                    } }>
                    Cancel
                  </button>
                </>
                {isViewMode() ? null : (
                  <button
                    className="button is-primary"
                    style={{ marginLeft: "20px", width: "80px" }}
                    disabled={loading}
                    onClick={(e) => {
                      // alert("woking");     ^[A-Z]{2}[-][0-9]{1,2}(?: [A-Z])?(?: [A-Z]*)? [0-9]{4}$
                      let re = new RegExp("^([A-Za-z0-9_ ]{0,10})$")
                      if (
                        !vehicleStore.vehicleRec.Regnumber.match(
                          /^[A-Za-z0-9]/
                        ) ||
                        vehicle.Regnumber.length < 7
                      ) {
                        configStore.setModalMessage(
                          `Please Enter Registration Number  eg: AP05BJ9353`
                        )
                        return
                      }
                      if (vehicleStore.vehicleRec.Vechtype === "") {
                        configStore.setModalMessage(
                          `Please select Vehicle Type.`
                        )
                        return
                      }
                      if (vehicleStore.vehicleRec.State === "") {
                        configStore.setModalMessage(`Please select the state.`)
                        return
                      }
                      if (vehicleStore.vehicleRec.City === "") {
                        configStore.setModalMessage(`Please select the city.`)
                        return
                      }
                      if (!vehicle.Vechownname.match(/^[A-Za-z0-9*]/)) {
                        configStore.setModalMessage(
                          `Please enter the Vehicle Owner Name`
                        )
                        return
                      }
                      if (vehicleStore.vehicleRec.InsExpdate === "") {
                        configStore.setModalMessage(
                          `Please select the Insurance Expiry Date.`
                        )
                        return
                      }
                      if (vehicleStore.vehicleRec.PucExpdate === "") {
                        configStore.setModalMessage(
                          `Please select the PUC Expiry Date.`
                        )
                        return
                      }
                      if (vehicleStore.vehicleRec.FcExpdate === "") {
                        configStore.setModalMessage(
                          `Please select the FC Expiry Date.`
                        )
                        return
                      }
                      if (vehicleStore.vehicleRec.RcIssuedate === "") {
                        configStore.setModalMessage(
                          `Please select the RC Issue Date.`
                        )
                        return
                      }
                      if (vehicleStore.vehicleRec.RcExpdate === "") {
                        configStore.setModalMessage(
                          `Please select the RC Expiry Date.`
                        )
                        return
                      }
                      if (
                        vehicleStore.vehicleRec.Documents[2].fileLink.length === 0 && vehicle.InsExpdateFlag
                      ) {
                        configStore.setModalMessage("Upload Insurance Document")
                        return
                      }

                      if (
                        vehicleStore.vehicleRec.Documents[3].fileLink.length === 0 && vehicle.PucExpdateFlag
                      ) {
                        configStore.setModalMessage("Upload PUC Document")
                        return
                      }
                      if (
                        vehicleStore.vehicleRec.Documents[4].fileLink.length === 0 && vehicle.FcExpdateFlag
                      ) {
                        configStore.setModalMessage("Upload FC Document")
                        return
                      }
                      if (
                        vehicleStore.vehicleRec.Documents[0].fileLink.length === 0 && vehicle.RcExpdateFlag
                      ) {
                        configStore.setModalMessage("Upload RC Document")
                        return
                      }

                      if (props.mode === "new") {
                        if (vehicle.timeline.length === 0) {
                          vehicle.timeline = [{
                            date: Date.now(),
                            event: "Created",
                            user: loginStore.email,
                            role: RoleUtils.getUserRole()
                          }]
                        }
                        // if (domain !== "agraga.com") vehicle.admin = loginStore.email;
                        vehicle.createdBy = loginStore.email
                        setloading(true)
                        return VehicleApi.createVehicle(setloading)
                      } else {
                        if (JSON.stringify(vehicle) === JSON.stringify(formObject)) {
                          setloading(false);
                          return configStore.setModalMessage(
                            "Please Check the values, we got same value before we Stored"
                          );
                        }
                        const timelineCheck =  vehicle.timeline[vehicle.timeline.length-1]?.event == "Rejection-Acknowledged"
                        const timelineCheckIsRejected =  vehicle.timeline[vehicle.timeline.length-2]?.event == "Rejected"
                        const timelineCheckIsVerified =  vehicle.timeline[vehicle.timeline.length-1]?.event == "Verified"
                        const timelineCheckIsCreated =  vehicle.timeline[vehicle.timeline.length-3]?.event == "Created"
                        const timelineCheckIsResubmited =  vehicle.timeline[vehicle.timeline.length-3]?.event == "Resubmited"
                        if ((timelineCheckIsVerified  && vehicle.Status !== "Verified" && vehicle.timeline.length !== 0)) {
                         //&& vehicle.Status === "Pending"
                          vehicle.timeline.push({
                            date: Date.now(),
                            event: "Resubmited",
                            user: loginStore.email,
                            role: RoleUtils.getUserRole()
                          })
                        }
                        if ((timelineCheckIsRejected && timelineCheck && vehicle.timeline.length !== 0 && (timelineCheckIsCreated || timelineCheckIsResubmited )  && vehicle.Status !== "Verified")) {
                         //&& vehicle.Status === "Pending"
                          vehicle.timeline.push({
                            date: Date.now(),
                            event: "Resubmited",
                            user: loginStore.email,
                            role: RoleUtils.getUserRole()
                          })
                        }
                        if (vehicle.timeline.length === 0) {
                          vehicle.timeline = [{
                            date: Date.now(),
                            event: "Created",
                            user: loginStore.email,
                            role: RoleUtils.getUserRole()
                          }]
                        }

                        setloading(true)
                        vehicle.updatedBy = loginStore.email
                        VehicleApi.saveVehicle(setloading)
                        if (props.fn) {
                          AgmodalStore.apiCall = true;
                          return routerStore.creditApplicationView ="list"
                        }
                      }
                    }}>
                    {!loading ? (
                      props.mode === "edit" ? (
                        "UPDATE"
                      ) : (
                        "SUBMIT"
                      )
                    ) : (
                      <Loader className="spinner" />
                    )}
                  </button>
                )}
              </div>
            </$c>
          </div>}
      </div>
      {approvepopup ? (
        <div
          style={{
            width: "450px",
            height: "150px",
            backgroundColor: "#F2F2F2",
            borderRadius: "10px",
            border: "1px solid black",
            position: "absolute",
            top: "40%",
            left: "43%",
            padding: "1% 1% 1% 1%",
          }}>
          <div className="is-flex is-justify-content-space-between">
            <span
              className="is-size-6 has-text-weight-bold"
              style={{ marginTop: "5%" }}>
              Approve :
              <span className="is-size-6">
                {vehicle.Vechownname} {vehicle.Regnumber}
              </span>
              <span>
                <input
                  type="checkbox"
                  className="is-size-2"
                  style={{ width: "20px", height: "18px" }}
                  onChange={approvebtnnfn}
                />
              </span>
            </span>
            <span>
              <ImCancelCircle onClick={cancelpopup} className="is-clickable" />
            </span>
          </div>
          <div>
            <span>
              <button
                className="is-clickable"
                style={{
                  backgroundColor: "#00CCFF",
                  border: "none",
                  padding: "2%",
                  borderRadius: "8px",
                  color: "white",
                  fontWeight: "600",
                  marginTop: "7%",
                  marginLeft: "80%",
                  opacity: 0.4,
                }}
                id="approvebtnid"
                disabled={appbtnstatus}
                onClick={approvedrifn}>
                SUBMIT
              </button>
            </span>
          </div>
        </div>
      ) : null}

      {rejectpopup ? (
        <div
          style={{
            width: "450px",
            backgroundColor: "#F2F2F2",
            borderRadius: "10px",
            border: "1px solid black",
            position: "absolute",
            top: "40%",
            left: "43%",
            padding: "1%",
          }}>
          <div className="is-flex is-justify-content-space-between">
            <span
              className="is-size-6 has-text-weight-bold"
              style={{ marginTop: "0%" }}>
              Reject{" "}
              <span className="is-size-6">
                {vehicle.Vechownname} {vehicle.Regnumber}
              </span>
            </span>
            <span>
              <ImCancelCircle onClick={cancelpopup} className="is-clickable" />
            </span>
          </div>
          <div
            className="is-size-6 has-text-weight-bold"
            style={{ marginTop: "0%" }}>
            Remarks:{" "}
          </div>
          <textarea
            id="rejectremarksid"
            placeholder="Remarks"
            // value={message}
            // onChange={handleMessageChange}
            style={{
              height: "60px",
              width: "300px",
              border: "1px solid grey",
              borderRadius: "5px",
              outline: "none",
              padding: "1%",
            }}
            onChange={(e) => submitRemarks(e)}></textarea>
          <div>
            <span>
              <button
                className="is-clickable"
                onClick={() => {
                  if (vehicle.Remarks.trim().length < 0) {
                    configStore.setModalMessage("Please add Remarks")

                    return
                  }
                  rejectdrifn()
                }}
                id="rejectbtnid"
                disabled={rejbtnstatus}
                style={{
                  backgroundColor: "#00CCFF",
                  border: "none",
                  padding: "2%",
                  borderRadius: "8px",
                  color: "white",
                  fontWeight: "600",
                  marginTop: "0%",
                  marginLeft: "80%",
                  opacity: "0.4",
                }}>
                SUBMIT
              </button>
            </span>
          </div>
        </div>
      ) : null}
      {updateDripopup ? (
        <div
          style={{
            width: "450px",
            backgroundColor: "#F2F2F2",
            borderRadius: "10px",
            border: "1px solid black",
            position: "absolute",
            top: "10%",
            left: "43%",
            padding: "1% 1% 1% 1%",
          }}>
          <div className="is-flex is-justify-content-space-between">
            <span className="is-size-6">Update </span>
            <span>
              <ImCancelCircle
                //onClick={cancelpopup} 
                onClick={() => (cancelpopup(), routerStore.vehicleCurrentView = "list")}
                className="is-clickable"

              />
            </span>
          </div>
          <p className="mt-4 has-text-danger">{alertcomment}</p>
          <p className="mt-4" style={{ width: "90%" }}>
            Vehicle Owner Name*
          </p>
          <p>
            <input
              placeholder="Enter Vehicle Owner Name"
              // value={newdlno}
              type="text"
              className="input"
              value={vehicle?.Vechownname}
              ref={ref}
              style={{
                width: "90%",
                padding: "1.5%",
                borderRadius: "5px",
                border: "1px solid black",
                outline: "none",
              }}
              pattern="[a-zA-Z0-9]"
              onKeyDown={(e) =>
                ["+", "-", "_"].includes(e.key) && e.preventDefault()
              }
              // onChange={(e) => setnewdlno(e.target.value)}
              onChange={(e) => handleChange(e, "Vechownname")}></input>
          </p>
          {/* <p className="mt-4 has-text-danger">
            <span>{alertcomment}</span>
          </p> */}
          {/* Second Line  start  */}

          <div className="columns">
            <p
              className="mt-4"
              style={{ marginLeft: "15px", marginRight: "10px" }}>
              Upload RC Document*
            </p>
            <span
              class="icon mt-5 ml-2 is-size-4"
              style={{ cursor: "pointer", marginLeft: "15px" }}
            >
              {vehicle?.Documents[0]?.fileLink?.length > 0 ? (
                <>
                  <span className="ml-2 mr-1" style={{ marginLeft: "15px" }}>
                    <a
                      href={
                        process.env.REACT_APP_FILE_SERVER +
                        "/api/v1/filestore/getfile/" +
                        vehicle?.Documents[0]?.pkey +
                        "/" +
                        vehicle?.Documents[0]?.fileLink
                      }
                      target="_blank"
                      rel="noreferrer"
                      alt="">
                      <BsFillEyeFill />
                    </a>
                  </span>
                  {/* {isNewMode() || (isEditMode() && isVerifiedStatus()) ? (
                   */}

                  <>
                    <span>
                      <MdDelete
                        style={{ cursor: "pointer", }}
                        onClick={() => (
                          delDocs1(0), (hiddenFileInput1.current.value = "")
                        )}
                      />
                    </span>
                  </>
                  {/* ) : null} */}
                </>
              ) : null}
              {isNewMode() || isEditMode() ? (
                <i
                  class="fa-solid fa-file-arrow-up"
                  onClick={handleClick1}
                  style={{
                    ...(vehicle?.Documents[0]?.fileLink?.length > 0
                      ? { display: "none" }
                      : null),
                  }}>
                  <input
                    type="file"
                    onChange={(e1) => {
                      const pattern = /^\S[\s\S]*\S$|^\S$/
                      if (pattern.test(e1.target.files[0]["name"])) {
                        change(e1.target.files, "Regnumber", "rc_document")
                      } else {
                        configStore.setModalMessage(
                          "Please Check File Name and Format"
                        )
                        return (e1.target.value = "")
                      }
                    }}
                    name="image"
                    accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                    multiple=""
                    ref={hiddenFileInput1}
                    data-original-title="upload photos"
                    style={{ display: "none" }}
                  />
                </i>
              ) : null}
            </span>
          </div>

          <div className="columns">
            <p
              className="mt-4"
              style={{ marginLeft: "15px", marginRight: "10px" }}>
              {" "}
              Upload Agreement of Sale{" "}
            </p>

            <span class="icon mt-5 ml-2 is-size-4" style={{ cursor: "pointer", }}>
              {vehicle?.Documents[1]?.fileLink?.length > 0 ? (
                <>
                  <span className="ml-2 mr-1">
                    <a
                      href={
                        process.env.REACT_APP_FILE_SERVER +
                        "/api/v1/filestore/getfile/" +
                        vehicle?.Documents[1]?.pkey +
                        "/" +
                        vehicle?.Documents[1]?.fileLink
                      }
                      target="_blank"
                      rel="noreferrer"
                      alt="">
                      <BsFillEyeFill />
                    </a>
                  </span>
                  {/*  {isNewMode() || (isEditMode() && isVerifiedStatus()) ? (
                    
                */}
                  <>
                    <span>
                      <MdDelete
                        style={{ cursor: "pointer", }}
                        onClick={() => (
                          delDocs1(1), (hiddenFileInput3.current.value = "")
                        )}
                      />
                    </span>
                  </>
                  {/*   ) : null} */}
                </>
              ) : null}
              {isNewMode() || isEditMode() ? (
                <i
                  class="fa-solid fa-file-arrow-up "
                  onClick={handleClick3}
                  style={{
                    ...(vehicle?.Documents[1]?.fileLink?.length > 0
                      ? { display: "none" }
                      : null),
                  }}>
                  <input
                    type="file"
                    onChange={(e1) => {
                      const pattern = /^\S[\s\S]*\S$|^\S$/
                      if (pattern.test(e1.target.files[0]["name"])) {
                        change(e1.target.files, "Regnumber", "agreement_sale")
                      } else {
                        configStore.setModalMessage(
                          "Please Check File Name and Format"
                        )
                        return (e1.target.value = "")
                      }
                    }}
                    name="image"
                    accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                    multiple=""
                    ref={hiddenFileInput3}
                    data-original-title="upload photos"
                    style={{ display: "none" }}
                  />
                </i>
              ) : null}
            </span>
          </div>

          <Columns>
            <Col12>
              <p className="label is-small" style={{ marginBottom: "0px" }}>
                Insurance Expiry Date*
              </p>
              <div className="is-flex">
                <input
                  type="date"
                  min={todaydate}
                  className="input is-small "
                  value={vehicle.InsExpdate}
                  style={{
                    width: "90%",
                    padding: "1.5%",
                    borderRadius: "5px",
                    border: "1px solid black",
                    outline: "none",
                    // border:
                    //     vehicle.InsExpdate < todaydate
                    //       ? "1px solid red"
                    //       : null,
                  }}
                  ref={ref}
                  onChange={(e) => {
                    handleChange(e, "InsExpdate")
                    colordate()
                  }}
                />

                <span class="icon mt-1 ml-3 is-size-4 " style={{ cursor: "pointer", }}>
                  {vehicle?.Documents[2]?.fileLink?.length > 0 ? (
                    <>
                      <span className="ml-2 mr-1">
                        <a
                          href={
                            process.env.REACT_APP_FILE_SERVER +
                            "/api/v1/filestore/getfile/" +
                            vehicle?.Documents[2]?.pkey +
                            "/" +
                            vehicle?.Documents[2]?.fileLink
                          }
                          target="_blank"
                          rel="noreferrer"
                          alt="">
                          <BsFillEyeFill />
                        </a>
                      </span>
                      {/* {isNewMode() || (isEditMode() && isVerifiedStatus()) ? (
                        
                         */}
                      <>
                        <span>
                          <MdDelete
                            style={{ cursor: "pointer", }}
                            onClick={() => (
                              delDocs1(2), (hiddenFileInput6.current.value = "")
                            )}
                          />
                        </span>
                      </>
                      {/* ) : null}*/}
                    </>
                  ) : null}
                  {isNewMode() || isEditMode() ? (
                    <i
                      class="fa-solid fa-file-arrow-up"
                      onClick={handleClick6}
                      style={{
                        ...(vehicle?.Documents[2]?.fileLink?.length > 0
                          ? { display: "none" }
                          : null),
                      }}
                      >
                      <input
                        type="file"
                        onChange={(e1) => {
                          const pattern = /^\S[\s\S]*\S$|^\S$/
                          if (pattern.test(e1.target.files[0]["name"])) {
                            change(e1.target.files, "Regnumber", "insurance")
                          } else {
                            configStore.setModalMessage(
                              "Please Check File Name and Format"
                            )
                            return (e1.target.value = "")
                          }
                        }}
                        name="image"
                        accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                        multiple=""
                        ref={hiddenFileInput6}
                        data-original-title="upload photos"
                        style={{ display: "none" }}
                      />
                    </i>
                  ) : null}
                </span>
              </div>
            </Col12>
          </Columns>

          <Columns>
            <Col12>
              <p className="label is-small" style={{ marginBottom: "0px" }}>
                PUC Expiry Date*
              </p>
              <div className="is-flex">
                <input
                  type="date"
                  min={todaydate}
                  className="input is-small "
                  style={{
                    width: "90%",
                    padding: "1.5%",
                    borderRadius: "5px",
                    border: "1px solid black",
                    outline: "none",
                  }}
                  value={vehicle.PucExpdate}
                  ref={ref}
                  onChange={(e) => handleChange(e, "PucExpdate")}
                />
                <span class="icon mt-1 ml-3 is-size-4 " style={{ cursor: "pointer", }}>
                  {vehicle?.Documents[3]?.fileLink?.length > 0 ? (
                    <>
                      <span className="ml-2 mr-1">
                        <a
                          href={
                            process.env.REACT_APP_FILE_SERVER +
                            "/api/v1/filestore/getfile/" +
                            vehicle?.Documents[3]?.pkey +
                            "/" +
                            vehicle?.Documents[3]?.fileLink
                          }
                          target="_blank"
                          rel="noreferrer"
                          alt="">
                          <BsFillEyeFill />
                        </a>
                      </span>
                      {/*  {isNewMode() || (isEditMode() && isVerifiedStatus()) ? (
                       */}

                      <>
                        <span>
                          <MdDelete
                            style={{ cursor: "pointer", }}
                            onClick={() => (
                              delDocs1(3), (hiddenFileInput7.current.value = "")
                            )}
                          />
                        </span>
                      </>
                      {/* ) : null} */}
                    </>
                  ) : null}
                  {isNewMode() || isEditMode() ? (
                    <i
                      class="fa-solid fa-file-arrow-up "

                      onClick={handleClick7}
                      style={{
                        ...(vehicle?.Documents[3]?.fileLink?.length > 0
                          ? { display: "none" }
                          : null),
                      }}>
                      <input
                        type="file"
                        onChange={(e1) => {
                          const pattern = /^\S[\s\S]*\S$|^\S$/
                          if (pattern.test(e1.target.files[0]["name"])) {
                            change(e1.target.files, "Regnumber", "puc")
                          } else {
                            configStore.setModalMessage(
                              "Please Check File Name and Format"
                            )
                            return (e1.target.value = "")
                          }
                        }}
                        name="image"
                        accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                        multiple=""
                        ref={hiddenFileInput7}
                        data-original-title="upload photos"
                        style={{ display: "none" }}
                      // {...(isViewMode()
                      //   ? { disabled: true }
                      //   : {
                      //       ...(isEditMode() &&
                      //       (isPendingStatus() || isVerifiedStatus())
                      //         ? { disabled: true }
                      //         : {}),
                      //     })}
                      />
                    </i>
                  ) : null}
                </span>
              </div>
            </Col12>
          </Columns>
          <Columns>
            <Col12>
              <p className="label is-small" style={{ marginBottom: "0px" }}>
                FC Expiry Date*
              </p>
              <div className="is-flex">
                <input
                  type="date"
                  min={todaydate}
                  className="input is-small "
                  style={{
                    width: "90%",
                    padding: "1.5%",
                    borderRadius: "5px",
                    border: "1px solid black",
                    outline: "none",
                  }}
                  value={vehicle.FcExpdate}
                  ref={ref}
                  onChange={(e) => handleChange(e, "FcExpdate")}
                />
                <span class="icon mt-1 ml-3 is-size-4  " style={{ cursor: "pointer", }}>
                  {vehicle?.Documents[4]?.fileLink?.length > 0 ? (
                    <>
                      <span className="ml-2 mr-1">
                        <a
                          href={
                            process.env.REACT_APP_FILE_SERVER +
                            "/api/v1/filestore/getfile/" +
                            vehicle?.Documents[4]?.pkey +
                            "/" +
                            vehicle?.Documents[4]?.fileLink
                          }
                          target="_blank"
                          rel="noreferrer"
                          alt="">
                          <BsFillEyeFill />
                        </a>
                      </span>
                      {/* {isNewMode() || (isEditMode() && isVerifiedStatus()) ? (
                       */}
                      <>
                        <span>
                          <MdDelete
                            style={{ cursor: "pointer", }}
                            onClick={() => (
                              delDocs1(4), (hiddenFileInput8.current.value = "")
                            )}
                          />
                        </span>
                      </>
                      {/* ) : null}*/}
                    </>
                  ) : null}
                  {isNewMode() || isEditMode() ? (
                    <i
                      class="fa-solid fa-file-arrow-up"
                      onClick={handleClick8}
                      style={{
                        ...(vehicle?.Documents[4]?.fileLink?.length > 0
                          ? { display: "none" }
                          : null),
                      }}>
                      <input
                        type="file"
                        onChange={(e1) => {
                          change(e1.target.files, "Regnumber", "fc")
                        }}
                        name="image"
                        accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                        multiple=""
                        ref={hiddenFileInput8}
                        data-original-title="upload photos"
                        style={{ display: "none" }}
                      // {...(isViewMode()
                      //   ? { disabled: true }
                      //   : {
                      //       ...(isEditMode() &&
                      //       (isPendingStatus() || isVerifiedStatus())
                      //         ? { disabled: true }
                      //         : {}),
                      //     })}
                      />
                    </i>
                  ) : null}
                </span>
              </div>
            </Col12>
          </Columns>

          <Columns>
            <Col12>
              <p className="label is-small" style={{ marginBottom: "0px" }}>
                RC Expiry Date*
              </p>
              <div className="is-flex">
                <input
                  type="date"
                  min={todaydate}
                  className="input is-small "
                  style={{
                    width: "90%",
                    padding: "1.5%",
                    borderRadius: "5px",
                    border: "1px solid black",
                    outline: "none",
                  }}
                  value={vehicle.RcExpdate}
                  ref={ref}
                  onChange={(e) => handleChange(e, "RcExpdate")}
                />
              </div>
            </Col12>
          </Columns>
          <div>
            <div className="is-flex">
              <button
                className="is-clickable"
                style={{
                  backgroundColor: "#00CCFF",
                  border: "none",
                  padding: "2%",
                  borderRadius: "8px",
                  color: "white",
                  fontWeight: "600",
                  marginTop: "2%",
                  marginLeft: "80%",
                  width: "75px",
                }}
                id="approvebtnid"
                disabled={loading}
                onClick={(e) => {
                  // if (vehicle.Documents[0].fileLink.length === 0) {
                  //   configStore.setModalMessage("Upload RC Document");
                  //   return;
                  // }

                  if (vehicle.Vechownname === "") {
                    configStore.setModalMessage(
                      "Please enter the Vehicle Owner Name"
                    )
                    return
                  }
                  if (vehicle.InsExpdate === "") {
                    configStore.setModalMessage(
                      `Please select the Insurance Expiry Date.`
                    )
                    return
                  }
                  if (vehicle.PucExpdate === "") {
                    configStore.setModalMessage(
                      `Please select the PUC Expiry Date.`
                    )
                    return
                  }
                  if (vehicle.FcExpdate === "") {
                    configStore.setModalMessage(
                      `Please select the FC Expiry Date.`
                    )
                    return
                  }

                  if (vehicle.RcExpdate === "") {
                    configStore.setModalMessage(
                      `Please select the RC Expiry Date.`
                    )
                    return
                  }
                  if (
                    vehicleStore.vehicleRec.Documents[2].fileLink.length === 0
                  ) {
                    configStore.setModalMessage(
                      "Upload new Insurance Document"
                    )
                    return
                  }
                  if (
                    vehicleStore.vehicleRec.Documents[3].fileLink.length === 0
                  ) {
                    configStore.setModalMessage("Upload new PUC Document")
                    return
                  }
                  if (
                    vehicleStore.vehicleRec.Documents[4].fileLink.length === 0
                  ) {
                    configStore.setModalMessage("Upload new FC Document")
                    return
                  }
                  if (
                    vehicleStore.vehicleRec.Documents[0].fileLink.length === 0
                  ) {
                    configStore.setModalMessage("Upload new RC Document")
                    return
                  } else {
                    if (JSON.stringify(vehicle) === JSON.stringify(formObject)) {
                      setloading(false);
                      return configStore.setModalMessage(
                        "Please Check the values, we got same value before we Stored"
                      );
                    }
                    const timelineCheck =  vehicle.timeline[vehicle.timeline.length-1]?.event == "Rejection-Acknowledged"
                    const timelineCheckIsRejected =  vehicle.timeline[vehicle.timeline.length-2]?.event == "Rejected"
                    const timelineCheckIsVerified =  vehicle.timeline[vehicle.timeline.length-1]?.event == "Verified"
                    const timelineCheckIsCreated =  vehicle.timeline[vehicle.timeline.length-2]?.event == "Created"
                    const timelineCheckIsResubmited =  vehicle.timeline[vehicle.timeline.length-2]?.event == "Resubmited"
                    if ((timelineCheckIsVerified && (timelineCheckIsCreated || timelineCheckIsResubmited ))) {
                      //&& vehicle.Status === "Pending"
                       vehicle.timeline.push({
                         date: Date.now(),
                         event: "Resubmited",
                         user: loginStore.email,
                         role: RoleUtils.getUserRole()
                       })
                     }
                    setloading(true)
                    vehicle.updatedBy = loginStore.email
                    VehicleApi.saveVehicle(setloading)
                  }

                  //</span>       UpdateVehicleDetails();
                }}
              // onClick={UpdateVehicleDetails}
              // onClick={() => VehicleApi.saveVehicle()}
              >
                {!loading ? "SUBMIT" : <Loader className="spinner" />}
              </button>
            </div>
          </div>
        </div>
      ) : null}


      {(ulippopup && ((vehicle.Status.includes('Expired') && vehicle.ulip)|| (vehicle.Status == 'Rejected' && vehicle.ulip)) && props.mode != 'view') ? (
        <div
          style={{
            width: "450px",
            height: "150px",
            backgroundColor: "#F2F2F2",
            borderRadius: "10px",
            border: "1px solid black",
            position: "absolute",
            top: "40%",
            left: "43%",
            padding: "1% 1% 1% 1%",
          }}>
          <div className="is-flex is-justify-content-space-between">
            <span
              className="is-size-6 has-text-weight-bold"
              style={{ marginTop: "5%" }}>
              Kindly refresh the Vehicle Details
            </span>
            <span>
              <ImCancelCircle onClick={() => (routerStore.vehicleCurrentView = "list")} className="is-clickable" />
            </span>
          </div>
          <div>
            <span>
            <button
              className="is-clickable"
              style={{
                    border: "1px solid #0099CC",
                    borderRadius: "5px",
                    height: "28px",
                    width:'70px',
                    color: "white",
                    marginTop: "10%",
                    marginLeft: "84%",
                    backgroundColor: "#00CCFF",
                    // opacity: 0.4,
          }}
              // onClick={updatevehfn}>
              onClick={(e) => getVechileDetails(true)}>
              Refresh
            </button>
            </span>
          </div>
        </div>
      ) : null}
    </>
  )
}
