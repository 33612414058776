export default function MblConfirmationPopup ({ onConfirm, onCancel }) {
    return (
        <div className="modal is-active" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-background" onClick={onCancel}></div>
            <div className="modal-content">
                <div className="box" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <p style={{ textAlign: 'justify', marginBottom: '1rem' }}>
                        Are you sure you wish to avail of a Direct Carrier MBL? 
                        <br /><br />
                        There are certain advantages of involving our HBL, including but not limited to the following:
                        <ul style={{ marginTop: '1rem', textAlign: 'left', paddingLeft: '1.5rem' }}>
                            <li>We do not need to depend on the Ocean Carriers for BL Release.</li>
                            <li>
                                Carriers will release the BL only after payment realization, and this process becomes significantly
                                longer when payments are to be made in USD.
                            </li>
                            <li>
                                Our House Bill of Lading can be released immediately upon Shipped-on-Board confirmation,
                                subject to agreed Credit/Payment Terms.
                            </li>
                            <li>
                                Our HBLs are equivalent to the Carrier MBLs, authorized by MTO, and recognized & accepted by Banks
                                under Letters of Credit.
                            </li>
                            <li>
                                Our HBL is Federal Maritime Commission (FMC) registered, making it fully valid for US shipments.
                            </li>
                        </ul>
                    </p>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '1rem' }}>
                        <button className="button is-danger" onClick={onConfirm}>
                            I will Risk it
                        </button>
                        <button className="button is-success" onClick={onCancel}>
                            Proceed with HBL
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};