import { AgmodalStore, EntityV2Store,  aguserStore,  cargoStore,  ccEmailStore,  configStore, containerStore,  entityStore, quoteDataRec, toEmailStore } from "../state/CommonState"
import { useSnapshot } from "valtio"
import { getTC } from "../TC"
import Utils from "../util/Utils"
import airlineCodes from "../static-data/airlines"
import QuotationUtils from "../util/quotationUtil"
import Api from "../state/Api"
import LCLChargesViewv3 from "./lcl-charges-view-v3"
import AirChargesViewv3 from "./air-charges-view-v3"
import FCLChargesViewv3 from "./fcl-charges-view-v3"
import VehicleViewv3 from "./vehicle-view-v3"
import EntityApi from "../state/entity-api"
import { loader } from "../util/loader"
import AguserApi from "../state/AguserApi"
import icbaselinerequestquote from "../assets/images/ic_baseline-request-quote.svg"
import containerdetail from "../assets/images/containerdetailsicon.svg";
import cargoDetail from "../assets/images/worldwide-shipping.svg";
import carbonrulefilled from "../assets/images/carbon_rule-filled.svg";
import evaemailfill from "../assets/images/eva_email-fill.svg";
import { Headspan } from "../v3-componets/AgHeadspan"
import { Card } from "../v3-componets/AgCard"
import { ConsolidatorView } from "../v3-componets/AgConsolidatorView"
import { ContainerView } from "../v3-componets/AgContainerView"
import { CargoView } from "../v3-componets/AgCargoView"
import { QuoteDetails } from "../v3-componets/AgQuoteDetails"
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Columns, Col, Col2, Col3, Col4, Col6, Col12 } from "../Controls"
import EntityV2Api from '../state/entities-api';
import { ENTITY_STATUS } from '../util/constants';
import TagsInput from 'react-tagsinput';

const dt = (dt1) => {
    if (!dt1) return dt1
    if (dt1.length < 10) return dt1
    let s = dt1.split('-')
    return `${s[2]}-${s[1]}-${s[0]}`
}

export default function QuoteViewv3(props) {
    let isMailBody = props.isMailBody
    useSnapshot(configStore)
    useSnapshot(containerStore)
    useSnapshot(toEmailStore)
    useSnapshot(ccEmailStore)
    useSnapshot(aguserStore)
    const [isButtonClicked, setButtonClicked] = useState(false);

    const handleChange = (e)=>{
        if(e.length>0){
            e[e.length-1] = e[e.length-1].toLowerCase()
            if (Utils.isEmail(e[e.length-1])) {
                if(toEmailStore.emails.indexOf(e[e.length-1])==-1){
                    toEmailStore.emails.push(e[e.length-1])
                }else{
                    toEmailStore.emails = e
                }
            }
        }else{
            toEmailStore.emails = e
        }
    }
    const handleChange1 = (e)=>{
        if(e.length>0){
            e[e.length-1] = e[e.length-1].toLowerCase()
            if (Utils.isEmail(e[e.length-1])) {
                if(ccEmailStore.emails.indexOf(e[e.length-1])==-1){
                    ccEmailStore.emails.push(e[e.length-1])
                }else{
                    ccEmailStore.emails = e
                }
            }
        }else{
            ccEmailStore.emails = e
        }
    }
    const [space, setSpace] = useState('')
    const [rec, setRec] = useState({})
    const [rec1, setRec1] = useState([])
    useLayoutEffect(() => {
        Api.getExchangerates()
        setTimeout(() => {
            setSpace(' ')
        }, 300);
    }, [])
    const imQuote = useSnapshot(quoteDataRec)
    let mailedOnDate = imQuote.mailedOn
    if (!mailedOnDate || mailedOnDate.length < 8) mailedOnDate = Utils.getCurrentDateString()
    const S = (props) => <strong>{props.children}</strong>
    let quoteTC = getTC(imQuote.shipmentType)
    let customTC = imQuote.customTC.split(/\r?\n/)
    if(imQuote.multicharge==undefined || imQuote.multicharge.length==0){
        quoteTC.map(e => customTC.push(e))
        quoteTC = customTC
    }
    let multiTC = []

    let reqdata = {}
    if(quoteDataRec.shipmentType == 'LCL'){
        let currentDate = new Date()
        let cargoReadinessDate = (quoteDataRec.cargoReadinessDate && quoteDataRec.cargoReadinessDate.length >= 10) ?
            new Date(quoteDataRec.cargoReadinessDate) : currentDate
        if (cargoReadinessDate < currentDate) cargoReadinessDate = currentDate
        if (isNaN(cargoReadinessDate)) cargoReadinessDate = currentDate
        let startDays = (quoteDataRec.portOfLoading === quoteDataRec.portOfReceipt) ? 2 : 8 // the logic in mail is >= 4/13, equivalent to this > 3/12
        let startDate = Utils.mmddyyyy(Utils.addDays(cargoReadinessDate, startDays))
        let endDate = Utils.mmddyyyy(Utils.newDate(quoteDataRec.validUpto))
        reqdata = {
            shipmentType:quoteDataRec.shipmentType,
            startDate:startDate,
            endDate:endDate
        }
    }else if(quoteDataRec.shipmentType.toLowerCase().indexOf('fcl')!=-1){
        reqdata = {
            shipmentType:"FCL"
        }
    }else{
        reqdata = {
            shipmentType:quoteDataRec.shipmentType
        }
    }
    

    if(quoteDataRec.reqdata==undefined || JSON.stringify(quoteDataRec.reqdata) != JSON.stringify(reqdata)){
        quoteDataRec.reqdata = reqdata
    }
    const getpr = (d)=>{
        let l = d
        for (let i = 0; i < aguserStore.list.length; i++) {
            let e = aguserStore.list[i]
            if(d==e['email']){
                l = `${e['firstName']} ${e['lastName']} (${e['email']})`
            }
        }
        return l
    }
    let a = {}
        a['Origin'] = imQuote.origin
        let containerlistall = []
        if(imQuote.multidest!=undefined){
            for (let i = 0; i < imQuote.multidest.length; i++) {
                if(imQuote.multidest.length==1){
                    a['Destination'] = imQuote.multidest[i].destination
                }else{
                    a['Destination'+(i+1)] = imQuote.multidest[i].destination
                }
            }
        }
        a['Cargo Readiness Date'] = dt(imQuote.cargoReadinessDate)
        if (imQuote.shipmentType.indexOf('Air') >= 0) {
            a['Airport of Receipt'] = imQuote.portOfReceipt
            a['Airport of departure'] = imQuote.portOfLoading
            a['Airport of Arrival'] = imQuote.portOfDischarge
        }else{
            a['Place of Receipt'] = imQuote.portOfReceipt
            a['Port of Loading'] = imQuote.portOfLoading
            a['Port of Discharge'] = imQuote.portOfDischarge
        }
        a['Final Place of Delivery'] = imQuote.finalPlaceOfDelivery
        a['Customer Reference#'] = imQuote.customerReferenceNum
        if(imQuote.shipmentType.toLowerCase().indexOf('lcl') != -1){
            a['Valid Upto'] = imQuote.validUpto
        }
        a['Commodity Type'] = imQuote.commodityType
        a['Incoterms'] = imQuote.incoTerms
        a['Sales Person'] = imQuote.salesperson
        a['Hazardous'] = imQuote.hazardous
        if(imQuote.hazardous === 'Yes'){
            a['Class'] = imQuote.quoteClass
            a['Packing Group'] = imQuote.packingGroup
            a['UN Number'] = imQuote.unnumber
        }
        if(Utils.displayNonStackable(imQuote.shipmentType, imQuote.shipmentScope)){
            a['Non Stackable'] = imQuote.nonStackable
        }
        if(imQuote.shipmentType.toLowerCase().indexOf('lcl') != -1){
            a['Carrier'] = imQuote.carrier
            a['Tentative transit Time'] = imQuote.transitTime
        }
        if(!isMailBody && imQuote.procurement!=undefined && imQuote.procurement.length>0){
            if(imQuote.shipmentType.toLowerCase().indexOf('lcl') != -1){
                a['Procurement'] = getpr(imQuote.procurement)
            }
        }
        if(imQuote.fba==undefined){
            quoteDataRec.fba = 'No'
        }
        if(imQuote.fbaOCC!=undefined){
            a["Origin Customs Clearance"] = imQuote.fbaOCC
        }else{
            a["Origin Customs Clearance"] = 'No'
        }
        if(imQuote.fbaDCC!=undefined){
            a["Destination Customs Clearance"] = imQuote.fbaDCC
        }else{
            a["Destination Customs Clearance"] = 'No'
        }
        a['FBA'] = imQuote.fba
        if(Utils.displayTemperatureControlled(imQuote.shipmentType)){
            a['Temperature Controlled'] = imQuote.temperatureControlled
            if (imQuote.temperatureControlled === 'Yes') {
                if (imQuote.shipmentType.toLowerCase().includes('air')) {
                    a['Minimum Temperature'] = imQuote.minTemperature
                    a['Maximum Temperature'] = imQuote.maxTemperature
                }else{
                    a['Temperature'] = imQuote.temperature
                    a['Cargo Value (USD)'] = imQuote.cargoValue
                }
            }
        }
        if(JSON.stringify(a)!=JSON.stringify(rec)){
            setRec(a)
        }
        if(imQuote.multicharge!=undefined && imQuote.multicharge.length>0){
            let k = []
            multiTC = []
            for (let i = 0; i < imQuote.multicharge.length; i++) {
                let customTC1 = imQuote.multicharge[i].customTC.split(/\r?\n/)
                quoteTC.map(e => customTC1.push(e))
                multiTC.push(customTC1)
                let a1 = {}
                if(imQuote.shipmentType.toLowerCase().indexOf('air') != -1){
                    let displayAirline = imQuote.multicharge[i].airline
                    if (imQuote.multicharge[i].airline) {
                        let temp = airlineCodes.filter(e => e.code == imQuote.multicharge[i].airline)
                        if (temp.length > 0) displayAirline = temp[0].name
                    }
                    a1['Airline'] = displayAirline
                }else{
                    
                    a1['Carrier'] = imQuote.multicharge[i].carrier
                }
                a1['Valid Upto'] = imQuote.multicharge[i].validUpto
                a1['Tentative transit Time'] = imQuote.multicharge[i].transitTime
                if(!isMailBody && imQuote.multicharge[i].procurement!=undefined && imQuote.multicharge[i].procurement.length>0){
                    a1['Procurement'] = getpr(imQuote.multicharge[i].procurement)
                }
                if(imQuote.shipmentType.toLowerCase().indexOf('fcl')!=-1 && imQuote.multicharge[i].destinationFreeTime!=undefined && imQuote.multicharge[i].destinationFreeTime>0){
                    a1['Destination Free Time'] = imQuote.multicharge[i].destinationFreeTime
                }
                k.push(a1)
            }
            if(JSON.stringify(k)!=JSON.stringify(rec1)){
                setRec1(k)
            }
        }
        
    useEffect(()=>{
        loader.show()
        const callback = (data)=>{
            if(JSON.stringify(data)!=JSON.stringify(entityStore.list)){
                entityStore.list = data
            }
            const callback1 = (data)=>{
                if(JSON.stringify(data)!=JSON.stringify(configStore.currencyList)){
                    configStore.currencyList = data
                }
                loader.hide()
            }
            Api.getLiveExchangerates(callback1)
        }
        EntityApi.getList(callback) 
    },[])
    useEffect(()=>{      
        
    },[imQuote])
    console.log("++++++++++++++++++++90",imQuote)
    const displayCargo4fcl = Utils.outGaugeContainerCheck(containerStore.list)

    return (
        <>
            {space}
            <body>
                <div>
                    <Headspan color="#555555" fontSize= "20px" fontWeight="700" data="Quotation Ref. No# " />
                    <Headspan color="#2C358A" fontSize= "20px" fontWeight="700" data={imQuote.quotationNum} />
                </div>
                <div>
                    <Headspan color="#555555" fontSize= "14px" fontWeight="600" data="Customer: " />
                    <Headspan color="#4BA7DD" fontSize= "14px" fontWeight="600" data={EntityV2Store.getEntityById(imQuote.entityId)} />
                    <Headspan color="#2C358A" fontSize= "30px" fontWeight="900" data=" . " />
                    <Headspan color="#555555" fontSize= "14px" fontWeight="600" data="Quote Date: " />
                    <Headspan color="#4BA7DD" fontSize= "14px" fontWeight="600" data={dt(mailedOnDate)}/>
                    <Headspan color="#2C358A" fontSize= "30px" fontWeight="900" data=" . " />
                    <Headspan color="#555555" fontSize= "14px" fontWeight="600" data="Customer Ref. No.: " />
                    <Headspan color="#4BA7DD" fontSize= "14px" fontWeight="600" data={imQuote.customerReferenceNum}/>
                </div>
                        <br />
                        {
                            (props.view=="view")?
                            <div>
                    <Card>
                    <span><img src={evaemailfill}/></span><Headspan color="#00000" fontSize= "14px" fontWeight="900" data=" Send Emails"/>
                        <Columns>
                <Col6>
                    <p className='emailtag'>TO:</p>
                    <TagsInput inputProps={{placeholder: 'Enter Email'}} value={toEmailStore.emails} onChange={handleChange} addOnBlur={true} onlyUnique={true} addKeys={["Enter"," ","Tab"]} />
                </Col6>
                <Col6>
                    <p className='emailtag'>CC:</p>
                    <TagsInput inputProps={{placeholder: 'Enter Email'}} value={ccEmailStore.emails} onChange={handleChange1} addOnBlur={true} onlyUnique={true} addKeys={["Enter"," ","Tab"]}/>
                </Col6>
            </Columns>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
                <div className="control">
                    <button class="button is-danger" onClick={() => {
                        AgmodalStore.pageVisible = false;
                        AgmodalStore.apiCall = true;
                    }}>
                        Cancel
                    </button>
                </div>
                <div className="control">
                    <button className="button is-link"
                    disabled={isButtonClicked}
                        onClick={(e) => {
                            if(toEmailStore.emails.length > 0 ){
                                setButtonClicked(true)
                            EntityV2Api.viewEntity(quoteDataRec.entityId , (data)=> {
                            if(data.status === ENTITY_STATUS.DEACTIVATED){
                            configStore.setModalMessage(`Entity ${data.entityName} is Deactivated , please Activate it for sending mail`)
                            setButtonClicked(false)
                            }else {
                                if( quoteDataRec.entityId === "TBA") {
                                Api.send3Mail()
                            }else{
                                const callback = (d) =>{
                                    setButtonClicked(false)
                                }
                                Api.send2Mail(callback)
                            }
                            
                            if(toEmailStore.emails.length < 1 ){
                                e.target.disabled = false
                                setButtonClicked(false)
                            }

                            }
                            })
                            }else{
                                configStore.setModalMessage(`Invalid To Emails`)
                            }
                            
                        
                            // else{
                            //     e.target.disabled = true
                            // }

                        }}>
                        Send mail
                    </button>
                </div>
            </div>
            </Card></div>:""
                        }
                        
                {
                    (imQuote.quoteStatus == 'DECLINED') ?
                        <>
                            <div class="notification is-danger">
                                <div class="columns">
                                    <div class="column">
                                        <strong>Decline Reason : </strong>{imQuote.decline_reason}
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column">
                                        <strong>Decline By : </strong>{imQuote.tsDeclineBy}
                                    </div>
                                    <div class="column">
                                        <strong>Decline On : </strong>{Utils.formatToDateTime(Number(imQuote.tsDeclineOn))}
                                    </div>
                                </div>
                            </div>
                        </> :
                        <></>
                }
                {
                    (imQuote.quoteStatus == 'LOST') ?
                        <>
                            <div class="notification is-danger">
                                <div class="columns">
                                    <div class="column">
                                        <strong>Lost Reason : </strong>{imQuote.LostReason}
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column">
                                        <strong>Lost By : </strong>{imQuote.tsLostBy}
                                    </div>
                                    <div class="column">
                                        <strong>Lost On : </strong>{Utils.formatToDateTime(Number(imQuote.tsLostOn))}
                                    </div>
                                </div>
                            </div>
                        </> :
                        <></>
                }
                {
                    (imQuote.quoteStatus == 'INVALID') ?
                        <>
                            <div class="notification is-danger">
                                <div class="columns">
                                    <div class="column">
                                        <strong>Invalid Reason : </strong>{imQuote.InvalidReason}
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column">
                                        <strong>Invalid By : </strong>{imQuote.tsInvalidBy}
                                    </div>
                                    <div class="column">
                                        <strong>Invalid On : </strong>{Utils.formatToDateTime(Number(imQuote.tsInvalidOn))}
                                    </div>
                                </div>
                            </div>
                        </> :
                        <></>
                }
                {
                    (imQuote.quoteStatus == 'WON') ?
                        <>
                            <div class="notification is-success">
                                <div class="columns">
                                    <div class="column">
                                        <strong>WON By : </strong>{imQuote.tsWonBy}
                                    </div>
                                    <div class="column">
                                        <strong>Won On : </strong>{Utils.formatToDateTime(Number(imQuote.tsWonOn))}
                                    </div>
                                </div>
                            </div>
                        </> :
                        <></>
                }
                <section class="section">
                    <div style={{marginTop:props.view=="view"?"10px":""}}>
                    <Card>
                        <div style={{display:"flex"}}>
                            {!isMailBody?<span><img src={icbaselinerequestquote}/></span>:<></>}<Headspan color="#00000" fontSize= "14px" fontWeight="900" data=" Quotation Details"/>
                        </div>
                        <div className="table-container">
                        <table style={{border:"none", width:"100%"}}>
                            <tbody style={{border:"none"}}>
                                <QuoteDetails rec={rec}/>
                            </tbody>
                        </table>
                        </div>
                    </Card>
                    {
                        (rec1.length>0)?<>
                        <div style={{marginTop:"10px"}}>
                        <Card>
                        <div style={{display:"flex"}}>
                            {!isMailBody?<span><img src={icbaselinerequestquote}/></span>:<></>}<Headspan color="#00000" fontSize= "14px" fontWeight="900" data={quoteDataRec.shipmentType.indexOf("Air")!=-1?" Airline Details":" Carrier Details"}/>
                        </div>
                        {
                                    rec1.map((e,i)=>{
                                        return <><div className="table-container">
                        <table style={{border:"none", width:"100%"}}>
                            <tbody style={{border:"none"}}>
                                <QuoteDetails rec={e}/>
                                </tbody>
                        </table>
                        </div>
                        {
                            (rec1.length>1 && i!=rec1.length-1)?<hr className="m-0"/>:""
                          }</>
                        })
                    }
                    </Card></div>
                        </>:""
                    }
                    {
                        (!isMailBody)?<VehicleViewv3 vehicleDetails={quoteDataRec.vehicleDetails} />:""
                    }
                        
                        {
                            (!isMailBody && quoteDataRec.consolidatorList != null && quoteDataRec.consolidatorList?.length !== 0 && quoteDataRec.shipmentType.toLowerCase().includes('lcl')) && <>
                                <div style={{marginTop:"10px"}}>
                                <Card>
                                    <Headspan color="#00000" fontSize= "14px" fontWeight="900" data="Consolidators"/>
                                    <table style={{border:"none", marginTop:"5px"}}>
                                        <tbody style={{border:"none"}}>
                                            <ConsolidatorView rec={quoteDataRec}/>
                                        </tbody>
                                    </table>
                                </Card>
                                </div>
                            </>
                        }
                        {
                            (quoteDataRec.shipmentType.indexOf('FCL') >= 0) ?
                            <div style={{marginTop:"10px"}}>
                                <Card>
                                {!isMailBody?<span><img src={containerdetail}/></span>:<></>}<Headspan color="#00000" fontSize= "14px" fontWeight="900" data=" Container Requirements"/>
                                <ContainerView rec={containerStore.list}/>
                                </Card>
                                </div>
                                 :
                                <></>
                        }
                        {((Utils.displayNonStackable(imQuote.shipmentType, imQuote.shipmentScope) && QuotationUtils.hasCargoDetail())||displayCargo4fcl) ?
                            <div style={{marginTop:"10px"}}>
                            <Card>
                            {!isMailBody?<span><img src={cargoDetail}/></span>:<></>}<Headspan color="#00000" fontSize= "14px" fontWeight="900" data={Utils.labelForOutGauge1(quoteDataRec,containerStore.list)?" Cargo Details for Out-Gauge Containers":" Cargo Details"}/>
                            <CargoView rec={quoteDataRec} displayCargo4fcl={displayCargo4fcl}/>
                            </Card>
                            </div>
                             :
                            <></>
                        }
                        {
                            (quoteDataRec.shipmentType.indexOf('LCL') >= 0) ?
                                <LCLChargesViewv3 isMailBody={isMailBody} /> :
                                <></>
                        }
                        {
              (quoteDataRec.multicharge!=undefined && quoteDataRec.multicharge.length>0)?
              <>
              {
                quoteDataRec.multicharge.map((e,i)=>{
                  let key = "list"+(i==0?"":i+1)
                  return<>
                  {quoteDataRec.shipmentType.indexOf("Air") >= 0 ? (
          <AirChargesViewv3 keylist={key} heading={(quoteDataRec.multicharge.length>1)?Utils.getAirline(e.airline):""} isMailBody={isMailBody}/>
        ) : (
          <></>
        )}
        {quoteDataRec.shipmentType.indexOf("FCL") >= 0 ? (
          <FCLChargesViewv3 keylist={key} heading={(quoteDataRec.multicharge.length>1)?e.carrier:""} isMailBody={isMailBody}/>
        ) : (
          <></>
        )}
        {
                            (!isMailBody) ?
                                <>
                                <div style={{marginTop:"10px"}}>
                                <Card>
                                    {!isMailBody?<span><img src={carbonrulefilled}/></span>:<></>}
                                    {
                                                quoteDataRec.shipmentType.indexOf("Air") >= 0?
                                                <Headspan color="#00000" fontSize= "14px" fontWeight="900" data={"Terms & Condition"+((e.airline!=undefined && e.airline.length>0 && quoteDataRec.multicharge.length>1)?" - "+Utils.getAirline(e.airline):"")}/>:
                                                <Headspan color="#00000" fontSize= "14px" fontWeight="900" data={"Terms & Condition"+((e.carrier!=undefined && e.carrier.length>0 && quoteDataRec.multicharge.length>1)?" - "+e.carrier:"")}/>
                                              }
                                    <ol style={{paddingLeft:'30px',paddingBottom:'10px'}}>
                                        {
                                            multiTC[i].filter(e => e.length > 0).map((e, i) => <li>{e}</li>)
                                        }
                                    </ol>
                                </Card>
                                </div>
                                </> :
                                <>
                                <div style={{marginTop:"10px"}}>
                                <Card>
                                    <Headspan color="#00000" fontSize= "14px" fontWeight="900" data="Terms and Conditions apply"/>
                                </Card>
                                </div>
                                </>
                        }
                  </>
                })
              }
              
              </>:""
              }
                        
                        {(imQuote.shipmentType.indexOf("LCL") >= 0)?<>
                            {
                            (!isMailBody) ?
                                <>
                                <div style={{marginTop:"10px"}}>
                                <Card>
                                    {!isMailBody?<span><img src={carbonrulefilled}/></span>:<></>}<Headspan color="#00000" fontSize= "14px" fontWeight="900" data="Terms & Condition"/>
                                    <ol style={{paddingLeft:'30px',paddingBottom:'10px'}}>
                                        {
                                            quoteTC.filter(e => e.length > 0).map((e, i) => <li>{e}</li>)
                                        }
                                    </ol>
                                </Card>
                                </div>
                                </> :
                                <>
                                <div style={{marginTop:"10px"}}>
                                <Card>
                                    <Headspan color="#00000" fontSize= "14px" fontWeight="900" data="Terms and Conditions apply"/>
                                </Card>
                                </div>
                                </>
                        }
                        </>:""}
                        


                    </div>
                </section>
            </body>
        </>
    )
}