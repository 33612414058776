import Api from "./Api"
import { contractsStore, configStore, entityStore, modalStore, loginStore } from "./CommonState"
import EntityApi from "../state/entity-api";
import { Cargo, enquiryStore, routeFlag } from "../enquiry/enquiry-data"
import { useGlobalState } from "../GlobalState"
import Utils from "../util/Utils"
import { lclWeightForPort, maxDimensions, maxWeightPerContainerFcl, maxcargoWeightPerContainer, weightPerPackage } from "../StaticData"
const ENQUIRIESURI = {
    GETFILTER: `${process.env.REACT_APP_API_SERVER}/api/v1/enquiries/get_filter`,
    GET: (enquiryNum) => `${process.env.REACT_APP_API_SERVER}/api/v1/enquiries/${enquiryNum}`,
    GETALL: `${process.env.REACT_APP_API_SERVER}/api/v1/enquiries`,
    POST: `${process.env.REACT_APP_API_SERVER}/api/v1/enquiries`,
    DELETE: `${process.env.REACT_APP_API_SERVER}/api/v1/enquiries/cancel`,
    UPDATETOQUOTE: (enquiryNum) => `${process.env.REACT_APP_API_SERVER}/api/v1/enquiries/updatetoquote/${enquiryNum}`,
    UPDATETOCLIENTVIEW: (enquiryNum) => `${process.env.REACT_APP_API_SERVER}/api/v1/enquiries/updatetoclientview/${enquiryNum}`
}

const getRespectiveMember = (customer) => {
    if (customer?.crossBorder?.relationshipManager && customer?.crossBorder?.customerService) {
        return `${customer?.crossBorder?.relationshipManager} ,${customer?.crossBorder?.customerService}`
    } else if (customer?.domesticTransport?.relationshipManager && customer?.domesticTransport?.customerService) {
        console.log(customer?.domesticTransport?.relationshipManager, "***", customer?.domesticTransport?.customerService);
        return `${customer?.domesticTransport?.relationshipManager} ,${customer?.domesticTransport?.customerService}`
    } else {
        return `pat@agraga.com`
    }
}

export default class EnquiriesApi {
    static getAllEnquiries(callback = f => f) {
        Api.fetch(ENQUIRIESURI.GETALL, (data) => {
            enquiryStore.list = data
            callback(data)
        })
    }
    static getEnquiry(enquiryNum, callback = f => f) {
        Api.fetch(ENQUIRIESURI.GET(enquiryNum), (data) => {
            enquiryStore.current = data
            callback(data)
        })
    }
    static saveEnquiry(callback) {
        console.log('about to call api.put', enquiryStore.current.shipmentType)
        /*let list = [...enquiryStore.current.cargoDetails]
        let newlist = list.filter(e => e.packageType.length > 0)
        enquiryStore.current.cargoDetails = newlist

        list = [...enquiryStore.current.containerDetails]
        newlist = list.filter(e => e.containerType.length > 0)
        enquiryStore.current.containerDetails = newlist*/
        let o
        let rec = enquiryStore.current
        if (rec.entityId !== "TBA" && loginStore.isClient()) {
            enquiryStore.current.entityId = configStore.currentSelectedEntity.entityId
        }
        o = rec.enqRoute
        // if (!o.origin?.length || !o.destination?.length || !o.cargoReadinessDate?.length) {
        //     //changes
        //     configStore.setModalMessage('Origin/Destination/Cargo Readiness Date are mandatory!')
        //     return
        // }

        // let S_Email
        // let C_Email
        // if(rec.isfromAgragaEnquiry == 'Yes' && rec.entityId === "TBA"){
        //     S_Email = rec.createdBy
        //     C_Email = ""
        // }else{
        // //    S_Email = entityStore.getCustomerSEmail(rec.gst)
        // //    C_Email = entityStore.getCustomerCEmail(rec.gst)
        //     // getRespectiveMember()
        // }

        let CustomerName = entityStore.getCustomer4gstOnly(rec.gst)
        let shipType = rec.shipmentType
        let shipScope = rec.shipmentScope
        let origin = rec.enqRoute.origin
        let destination = rec.enqRoute.destination
        let stype = rec.shipmentType.substr(0, 3).toLowerCase()
        let scope = rec.shipmentScope
        console.log('REC.HAZARDOUS=', rec.hazardous)
        console.log(rec.minTemperature, rec.minTemperature, 'temprature');
        if (rec.hazardous === 'Yes') {
            console.log('VALIDATING', rec.hazardous, rec.packingGroup, rec.quoteClass, rec.unnumber, '***')
            if (!rec.quoteClass.length) {
                configStore.setModalMessage('Quote class need to be specified')
                return
            }
            if (!rec.packingGroup?.length) {
                configStore.setModalMessage('Packing Group need to be specified')
                return
            }
            if (!rec.unnumber.length) {
                configStore.setModalMessage('UN Number need to be specified')
                return
            }
            if (rec.unnumber.length > 4) {
                configStore.setModalMessage('UN Number length should br below 4 characters')
                return
            }
        }
        if (stype === 'air' && rec.temperatureControlled === 'Yes') {
            rec.minTemperature = parseFloat(rec.minTemperature);
            rec.maxTemperature = parseFloat(rec.maxTemperature);

            if (rec.minTemperature === '' || isNaN(rec.minTemperature) || rec.minTemperature > 30 || rec.minTemperature < -35) {
                configStore.setModalMessage('Minimum Temperature range should be between -35 to 30.')
                return
            }
            if (rec.maxTemperature === '' || isNaN(rec.maxTemperature) || rec.maxTemperature > 30 || rec.maxTemperature < -35) {
                configStore.setModalMessage('Maximum Temperature range should be between -35 to 30.')
                return
            }
            if (rec.minTemperature != 0 && (rec.minTemperature >= rec.maxTemperature)) {
                configStore.setModalMessage('Minimum Temperature should not be greater than Maximum Temperature.')
                return
            }
        }

        if (stype === 'fcl' && rec.temperatureControlled === 'Yes') {

            if (rec.temperature === '' || isNaN(rec.temperature) || rec.temperature < -35 || rec.temperature > 30) {
                configStore.setModalMessage('Temperature range should be between -35 to 30.')
                return
            }
            if (!(rec.cargoValue > 0 && rec.cargoValue < 100000)) {
                configStore.setModalMessage('Cargo value should be within 5 digits')
                return
            }
        }
        if (stype === 'fcl') {
            let con = rec.containerDetails
            if (con.length < 1) {
                configStore.setModalMessage('Container details cannot be empty')
                return
            }
            for (let i = 0; i < con.length; i++) {
                let e = con[i]
                if (!e.containerType.length) {
                    configStore.setModalMessage('Containers type need to be specified')
                    return
                }
                if (e.numContainers < 1) {
                    configStore.setModalMessage('Number of containers  need to be specified')
                    return
                }
                if (e.wtPerContainer < 1) {
                    configStore.setModalMessage('Weight per container need to be specified')
                    return
                }
                let maxValue = maxWeightPerContainerFcl[e.containerType][e.unit.toLowerCase()]
                if (parseFloat(e.wtPerContainer) > maxValue) {
                    configStore.setModalMessage(`Weight for ${e.containerType} container should be less than ${maxValue}.`)
                    return;
                }
            }
        }
        let displayCargo4fcl = false
        {
            let e = enquiryStore.current

            if (stype === 'fcl' && e.shipmentScope === 'Port-to-Door') {
                if (e.destuffingType === 'Dock')
                    displayCargo4fcl = true
            }
            else if (stype === 'fcl' && e.shipmentScope === 'Door-to-Port') {
                if (e.stuffingType === 'Dock')
                    displayCargo4fcl = true
            }
            else if (stype === 'fcl' && e.shipmentScope === 'Door-to-Port') {
                displayCargo4fcl = true
                if (e.stuffingType === 'Factory' && e.destuffingType === 'Factory')
                    displayCargo4fcl = false
            } else if (stype === 'fcl' && e.shipmentScope === 'Door-to-Door') {
                displayCargo4fcl = false
                if (e.stuffingType === 'Dock' || e.destuffingType === 'Dock')
                    displayCargo4fcl = true
            }
            console.log('#########>>>>>>>>>', stype, displayCargo4fcl, e.shipmentScope)
        }
        let car = rec.cargoDetails

        if (!loginStore.isClient()) {

            if (stype !== 'fcl' || (stype === 'fcl' && displayCargo4fcl)) {
                for (let i = 0; i < car.length; i++) {
                    let e = car[i]
                    console.log(e.totalWeight, 'weight1');
                    console.log(e.weight, 'weight2');
                    if (!e.packageType.length) {
                        configStore.setModalMessage('Package Type cannot be empty')
                        return
                    }
                    /*if (stype === 'fcl') {
                        if (!e.packageType.length || !e.quantity || !e.weight.value || !e.volume.value) {
                            configStore.setModalMessage('Cargo weight and volume need to be specified')
                            return
                        }
                    }*/
                    if (stype === 'lcl' && scope === 'Port-to-Port') {
                        if (!e.quantity || parseInt(e.quantity) <= 0) {
                            configStore.setModalMessage('Cargo quantity need to be specified')
                            return
                        }
                        if (!e.totalWeight.value || parseFloat(e.totalWeight.value) <= 0) {
                            configStore.setModalMessage('Cargo weight need to be specified')
                            return
                        } else {
                            let l = Utils.toNum(e.quantity)
                            if(l>0){
                                if (Number(e.totalWeight.value) > (l*weightPerPackage[stype][e.totalWeight.unit.toLowerCase()])) {
                                    configStore.setModalMessage(`Weight per ${e.packageType} package should be less than ${l*weightPerPackage[stype][e.totalWeight.unit.toLowerCase()]}.`)
                                    return;
                                }
                            }
                        }


                    } else /*if (stype === 'lcl' && scope !== 'Port-to-Port')*/ {

                        if (!e.quantity || parseInt(e.quantity) <= 0) {
                            configStore.setModalMessage('Cargo quantity need to be specified')
                            return
                        }

                        if ((!e.totalWeight || parseFloat(e.totalWeight.value) <= 0) && (!e.weight.value || parseFloat(e.weight.value) <= 0)) {
                            configStore.setModalMessage('Cargo weight need to be specified')
                            return
                        }
                        if (scope != 'Port-to-Port' && (!e.dimensions.length || !e.dimensions.width || !e.dimensions.height || ['length', 'width', 'height'].some(val => parseFloat(e.dimensions[val]) <= 0))) {
                            configStore.setModalMessage('Cargo dimensions need to be specified')
                            return
                        }


                        if (["air", "lcl"].indexOf(stype) != -1) {
                            if (Number(e.weight.value) > weightPerPackage[stype][e.weight.unit.toLowerCase()]) {
                                configStore.setModalMessage(`Weight per ${e.packageType} package should be less than ${weightPerPackage[stype][e.weight.unit.toLowerCase()]}.`)
                                return;
                            }
                        }
                        if (["air", "lcl"].indexOf(stype) != -1) {
                            let maxValue = maxDimensions[stype][rec.cargoDimensionUnit.toLowerCase()]
                            if (parseFloat(e.dimensions['length']) > maxValue['length']) {
                                configStore.setModalMessage(`Length for ${e.packageType} package should be less than ${maxValue['length']}.`)
                                return;
                            }
                            if (parseFloat(e.dimensions['width']) > maxValue['width']) {
                                configStore.setModalMessage(`Width for ${e.packageType} package should be less than ${maxValue['width']}.`)
                                return;
                            }
                            if (parseFloat(e.dimensions['height']) > maxValue['height']) {
                                configStore.setModalMessage(`Height for ${e.packageType} package should be less than ${maxValue['height']}.`)
                                return;
                            }
                        }
                        // if (stype === 'air' ? Utils.stdUnits(parseFloat(e.weight.value), e.weight.unit) > 6500 : Utils.stdUnits(parseFloat(e.weight.value), e.weight.unit) > 3000) {
                        //     configStore.setModalMessage(`Weight per ${e.packageType} package should be less than ${stype === 'air' ? 6500 : 3000}.`)
                        //     return;
                        // }
                    }
                }
            }
        }

        if (stype === "lcl") {
            let totalWeight = 0;
            let totalVolume = 0;
            for (let i = 0; i < enquiryStore.current.cargoDetails.length; i++) {
                let cargo = enquiryStore.current.cargoDetails[i];
                if (scope === 'Port-to-Port') {
                    totalWeight += parseFloat(cargo?.totalWeight?.value || 0.0);
                    totalVolume += parseFloat(cargo?.totalVolume?.value || 0.0)
                } else {
                    totalWeight += parseFloat(cargo?.weight?.value || 0.0);
                }
            }
            let weightUnit;
            if (scope === "Port-to-Port") {
                weightUnit = enquiryStore.current.cargoDetails[0].totalWeight.unit;
            } else {
                weightUnit = enquiryStore.current.cargoDetails[0].weight.unit;
            }
            let volumeUnit = enquiryStore.current.cargoDetails[0].totalVolume.unit;
            let maxWeight = lclWeightForPort["totalweight"][weightUnit.toLowerCase()];
            let maxVolume = lclWeightForPort["totalvolume"][volumeUnit.toLowerCase()]
            if (totalWeight > maxWeight) {
                configStore.setModalMessage(`Total weight per shipment can't be greater than ${maxWeight} ${weightUnit}`)
                return;
            } else if (scope === "Port-to-Port" && totalVolume > maxVolume) {
                configStore.setModalMessage(`Total volume per shipment can't be greater than ${maxVolume} ${volumeUnit}`)
                return;
            }
        }
        // configStore.quotationModalVisible4 = true
        if (loginStore.isClient() && stype === "fcl") {
            enquiryStore.current.shipmentType = `FCL (${enquiryStore.current.stuffingType}/${enquiryStore.current.destuffingType})`;
            let stuffingType = enquiryStore.current.stuffingType;
            let destuffingType = enquiryStore.current.destuffingType;
            let origin = enquiryStore.current.shipmentScope.split("-")[0];
displayCargo4fcl = Utils.outGaugeContainerCheck(rec.containerDetails)
            let destination = enquiryStore.current.shipmentScope.split("-")[2];

            if (!((origin?.toLowerCase() === "door" && stuffingType.toLowerCase() === "dock") || (destination?.toLowerCase() === "door" && destuffingType.toLowerCase() === "dock") || (enquiryStore.current.shipmentScope !== "Port-to-Port" && stuffingType.toLowerCase() === "dock" && destuffingType.toLowerCase() === "dock")||displayCargo4fcl)) {
                enquiryStore.current.cargoDetails = [new Cargo()]
            }
        }
        enquiryStore.current.entity = configStore.currentSelectedEntity.entityName
        console.log(enquiryStore.current.entityId, 'enquiryStore.current.entityId');
        EntityApi.getEntity(enquiryStore.current.entityId, entityData => {
            let S_Email
            let C_Email
            let toUsers
            if (rec.isfromAgragaEnquiry == 'Yes' && rec.entityId === "TBA") {
                S_Email = rec.createdBy
                C_Email = ""
                toUsers = `${S_Email} ,${C_Email}`
            } else {
                toUsers = Utils.getRespectiveMember(entityData.customer)
                //    S_Email = entityStore.getCustomerSEmail(rec.gst)
                //    C_Email = entityStore.getCustomerCEmail(rec.gst)
                // getRespectiveMember()
            }
            Api.post(ENQUIRIESURI.POST, enquiryStore.current, (data) => {
                console.log("QQQQQ", enquiryStore.current)

                if (!loginStore.isClient()) {
                    configStore.enquiryModalNum = data._id
                } else {
                    configStore.setEnquiryModalMessage(data._id)
                }

                configStore.enquiryModalNum = data._id
                if (!loginStore.isClient() && rec.isfromAgragaEnquiry == 'Yes') {
                    callback(data)
                }
                console.log(entityData.entityName, 'entityData.entityName');
                // if(configStore.acceptMail == true){
                // setTimeout(()=>{
                let from = `Agraga <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
                let to = `procurement@agraga.com`
                let cc = toUsers;
                let subject = 'Enquiry - ' + configStore.enquiryModalNum
                let message = `Hi Team,<br />
                        <br />
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; We have received an enquiry from ${entityData.entityName}.<br />
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;        Enquiry Number:${configStore.enquiryModalNum}.<br />
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Shipment Type: ${shipType}.<br />
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Scope : ${shipScope}.<br />
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Origin: ${origin}.<br />`
                        if(rec.multidest!=undefined && rec.multidest.length>1){
                            for (let i = 0; i < rec.multidest.length; i++) {
                                message=message+`&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;        Destination${i+1}: ${rec.multidest[i]['destination']}.<br />`
                            }
                        }else{
                        message=message+`&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;        Destination: ${destination}.<br />`
                        }
                        message=message+`${(!loginStore.isClient() && rec.isfromAgragaEnquiry == 'Yes') ? `&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;        Special Instructions to Procurement: ${(enquiryStore.current.agragareason.trim().length > 0 ? enquiryStore.current.agragareason : "Nil")}.<br />` : ``
                    }
                        <br />
                        Team Agraga<br />`
                if (!loginStore.isClient() && rec.isfromAgragaEnquiry == 'Yes') {
                    Api.sendClientMailwithoutMessage2(from, to, subject, message,cc,shipType)
                } else {
                    Api.sendClientMailwithoutMessage2(from, to, subject, message,cc, shipType)
                }


                // }, 20000)
                // }

                if(callback){
                    callback();
                }


                // setTimeout(() => {
                //     configStore.setEnquiryModalMessage(data._id)
                // }, 1000);


            })
        })




        routeFlag.originRouteFlag = 'No'
        routeFlag.destinationFlag = 'No'
        configStore.buttondisable = true
        return true
    }
    static saveEnquiry4pricing(callback) {
        console.log('about to call api.put', enquiryStore.current.shipmentType)
        let o
        let rec = enquiryStore.current
        if (rec.entityId !== "TBA" && loginStore.isClient()) {
            enquiryStore.current.entityId = configStore.currentSelectedEntity.entityId
        }
        o = rec.enqRoute

        let CustomerName = entityStore.getCustomer4gstOnly(rec.gst)
        let shipType = rec.shipmentType
        let shipScope = rec.shipmentScope
        let origin = rec.enqRoute.origin
        let destination = rec.enqRoute.destination
        let stype = rec.shipmentType.substr(0, 3).toLowerCase()
        let scope = rec.shipmentScope
        console.log('REC.HAZARDOUS=', rec.hazardous)
        console.log(rec.minTemperature, rec.minTemperature, 'temprature');
        
        if (stype === 'fcl') {
            let con = rec.containerDetails
            if (con.length < 1) {
                configStore.setModalMessage('Container details cannot be empty')
                return
            }
            for (let i = 0; i < con.length; i++) {
                let e = con[i]
                if (!e.containerType.length) {
                    configStore.setModalMessage('Containers type need to be specified')
                    return
                }
                if (e.numContainers < 1) {
                    configStore.setModalMessage('Number of containers  need to be specified')
                    return
                }
                if (e.wtPerContainer < 1) {
                    configStore.setModalMessage('Weight per container need to be specified')
                    return
                }
                let maxValue = maxWeightPerContainerFcl[e.containerType][e.unit.toLowerCase()]
                if (parseFloat(e.wtPerContainer) > maxValue) {
                    configStore.setModalMessage(`Weight for ${e.containerType} container should be less than ${maxValue}.`)
                    return;
                }
            }
        }
        let displayCargo4fcl = false
        {
            let e = enquiryStore.current

            if (stype === 'fcl' && e.shipmentScope === 'Port-to-Door') {
                if (e.destuffingType === 'Dock')
                    displayCargo4fcl = true
            }
            else if (stype === 'fcl' && e.shipmentScope === 'Door-to-Port') {
                if (e.stuffingType === 'Dock')
                    displayCargo4fcl = true
            }
            else if (stype === 'fcl' && e.shipmentScope === 'Door-to-Port') {
                displayCargo4fcl = true
                if (e.stuffingType === 'Factory' && e.destuffingType === 'Factory')
                    displayCargo4fcl = false
            } else if (stype === 'fcl' && e.shipmentScope === 'Door-to-Door') {
                displayCargo4fcl = false
                if (e.stuffingType === 'Dock' || e.destuffingType === 'Dock')
                    displayCargo4fcl = true
            }
            console.log('#########>>>>>>>>>', stype, displayCargo4fcl, e.shipmentScope)
        }
        let car = rec.cargoDetails

        if (!loginStore.isClient()) {

            if (stype !== 'fcl' || (stype === 'fcl' && displayCargo4fcl)) {
                for (let i = 0; i < car.length; i++) {
                    let e = car[i]
                    console.log(e.totalWeight, 'weight1');
                    console.log(e.weight, 'weight2');
                    if (!e.packageType.length) {
                        configStore.setModalMessage('Package Type cannot be empty')
                        return
                    }
                    /*if (stype === 'fcl') {
                        if (!e.packageType.length || !e.quantity || !e.weight.value || !e.volume.value) {
                            configStore.setModalMessage('Cargo weight and volume need to be specified')
                            return
                        }
                    }*/
                    if (stype === 'lcl' && scope === 'Port-to-Port') {
                        if (!e.quantity || parseInt(e.quantity) <= 0) {
                            configStore.setModalMessage('Cargo quantity need to be specified')
                            return
                        }
                        if (!e.totalWeight.value || parseFloat(e.totalWeight.value) <= 0) {
                            configStore.setModalMessage('Cargo weight need to be specified')
                            return
                        } else {
                            let l = Utils.toNum(e.quantity)
                            if(l>0){
                                if (Number(e.totalWeight.value) > (l*weightPerPackage[stype][e.totalWeight.unit.toLowerCase()])) {
                                    configStore.setModalMessage(`Weight per ${e.packageType} package should be less than ${l*weightPerPackage[stype][e.totalWeight.unit.toLowerCase()]}.`)
                                    return;
                                }
                            }
                        }


                    } else /*if (stype === 'lcl' && scope !== 'Port-to-Port')*/ {

                        if (!e.quantity || parseInt(e.quantity) <= 0) {
                            configStore.setModalMessage('Cargo quantity need to be specified')
                            return
                        }

                        if ((!e.totalWeight || parseFloat(e.totalWeight.value) <= 0) && (!e.weight.value || parseFloat(e.weight.value) <= 0)) {
                            configStore.setModalMessage('Cargo weight need to be specified')
                            return
                        }
                        if (scope != 'Port-to-Port' && (!e.dimensions.length || !e.dimensions.width || !e.dimensions.height || ['length', 'width', 'height'].some(val => parseFloat(e.dimensions[val]) <= 0))) {
                            configStore.setModalMessage('Cargo dimensions need to be specified')
                            return
                        }

                    }
                }
            }
        }

        // configStore.quotationModalVisible4 = true
        if (loginStore.isClient() && stype === "fcl") {
            enquiryStore.current.shipmentType = `FCL (${enquiryStore.current.stuffingType}/${enquiryStore.current.destuffingType})`;
            let stuffingType = enquiryStore.current.stuffingType;
            let destuffingType = enquiryStore.current.destuffingType;
            let origin = enquiryStore.current.shipmentScope.split("-")[0];

            let destination = enquiryStore.current.shipmentScope.split("-")[2];

            if (!((origin?.toLowerCase() === "door" && stuffingType.toLowerCase() === "dock") || (destination?.toLowerCase() === "door" && destuffingType.toLowerCase() === "dock") || (enquiryStore.current.shipmentScope !== "Port-to-Port" && stuffingType.toLowerCase() === "dock" && destuffingType.toLowerCase() === "dock"))) {
                enquiryStore.current.cargoDetails = [new Cargo()]
            }
        }
        enquiryStore.current.entity = configStore.currentSelectedEntity.entityName
        console.log(enquiryStore.current.entityId, 'enquiryStore.current.entityId');
        if (!loginStore.isClient() && rec.isfromAgragaEnquiry != undefined && rec.isfromAgragaEnquiry != 'Yes') {
            rec.isfromAgragaEnquiry = 'Yes'
        }
        EntityApi.getEntity(enquiryStore.current.entityId, entityData => {
            let S_Email
            let C_Email
            let ccUsers
            if (rec.isfromAgragaEnquiry == 'Yes' && rec.entityId === "TBA") {
                S_Email = rec.createdBy
                C_Email = ""
                ccUsers = `${S_Email} ,${C_Email}`
            } else {
                ccUsers = Utils.getRespectiveMember(entityData.customer)
                //    S_Email = entityStore.getCustomerSEmail(rec.gst)
                //    C_Email = entityStore.getCustomerCEmail(rec.gst)
                // getRespectiveMember()
            }
            Api.post(ENQUIRIESURI.POST, enquiryStore.current, (data) => {
                console.log("QQQQQ", enquiryStore.current)

                if (!loginStore.isClient()) {
                    configStore.enquiryModalNum = data._id
                } else {
                    configStore.setEnquiryModalMessage(data._id)
                }

                configStore.enquiryModalNum = data._id
                if (!loginStore.isClient() && rec.isfromAgragaEnquiry == 'Yes') {
                    callback(data)
                }
                console.log(entityData.entityName, 'entityData.entityName');
                // if(configStore.acceptMail == true){
                // setTimeout(()=>{
                let from = `Agraga <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
                let cc = ccUsers;
                let to = `procurement@agraga.com`
                let subject = 'Enquiry - ' + configStore.enquiryModalNum
                let message = `Hi Team,<br />
                        <br />
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; We have received an enquiry from ${entityData.entityName}.<br />
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;        Enquiry Number:${configStore.enquiryModalNum}.<br />
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Shipment Type: ${shipType}.<br />
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Scope : ${shipScope}.<br />
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Origin: ${origin}.<br />`
                if(rec.multidest!=undefined && rec.multidest.length>1){
                    for (let i = 0; i < rec.multidest.length; i++) {
                        message=message+`&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;        Destination${i+1}: ${rec.multidest[i]['destination']}.<br />`
                    }
                }else{
                    message=message+`&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;        Destination: ${destination}.<br />`
                }
                message=message+`${(!loginStore.isClient() && rec.isfromAgragaEnquiry == 'Yes') ? `&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;        Special Instructions to Procurement: ${(enquiryStore.current.agragareason.trim().length > 0 ? enquiryStore.current.agragareason : "Nil")}.<br />` : ``}`
                if (rec.uploads && rec.uploads.length > 0) { 
                    message = message + `please find the ${rec.uploads
                            .map(
                                (e) =>
                                    `<a href="${process.env.REACT_APP_FILE_SERVER}/clientfiles/${e.fileName}" download target="_blank">${e.label}</a>`
                            )
                            .join(', ')
                        } for your reference <br/>`
                }  
                message  = message +`
                <br />
                Team Agraga<br />`
                if (!loginStore.isClient() && rec.isfromAgragaEnquiry == 'Yes') {
                    Api.sendClientMailwithoutMessage2(from, to, subject, message,cc,shipType)
                } else {
                    Api.sendClientMailwithoutMessage2(from, to, subject, message, cc,shipType)
                }

            })
        })
        routeFlag.originRouteFlag = 'No'
        routeFlag.destinationFlag = 'No'
        configStore.buttondisable = true
        return true
    }
    static updateToQuote(enquiryNum, callback = f => f) {
        console.log('update to quote called')
        Api.put(ENQUIRIESURI.UPDATETOQUOTE(enquiryNum), {}, callback)
    }
    static updateToClientView(enquiryNum, callback = f => f) {
        console.log('update to quote called')
        Api.put(ENQUIRIESURI.UPDATETOCLIENTVIEW(enquiryNum), {}, callback)
    }

    static getFilter(queryPayload,callback) {
        const body = {
            filter: queryPayload.filter,
            shipmentType: queryPayload.shipmentType,
            fromDate: queryPayload.fromDate,
            toDate: queryPayload.toDate
        }
        Api.post(ENQUIRIESURI.GETFILTER,body,(data)=>{
            callback(data)
        })

    }

    static cancelEnquiry(enquiryNum, reason, remarks,callback) {
        const body = {
            enquiryNum: enquiryNum,
            reason: reason,
            remarks:remarks
        }
        Api.post(ENQUIRIESURI.DELETE, body, (data) => {
            callback(data)
            // configStore.setModalMessage(data)
        })
    }
}