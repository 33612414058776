import { ROLES, PERMISSIONS, VERTICALS, DIVISION } from "../util/constants"
import { loginStore } from "../state/CommonState"
import { ServerLists } from "../StaticData";
class RoleUtils {

  static isUserAuthorized(permissionToCheck) {
    let user = loginStore.userRec.aguserRec;
    let userRoles = user?.role;
    const userVertical = user?.vertical;
    const userDivision = user?.division;
    const rolePermissionList = ServerLists?.rolePermissionsList

    const isAuthorized = (role, vertical) => {
      const permission = rolePermissionList.find(item => item?.permission_name === permissionToCheck);

      if (!permission) return false;
      return permission.roles.some(permittedRole => {
        if (
          (permissionToCheck === PERMISSIONS.NAV_MENU.CROSS_BORDER ||
            permissionToCheck === PERMISSIONS.EDIT.Razor_Pay) &&
          role === permittedRole.role
        ) {
          return true;
        }
        return role === permittedRole.role && vertical === permittedRole.vertical;
      });
    };

    const modifyUserRole = (role, division, vertical) => {
      if ((role === ROLES.CB.ADMIN && division === DIVISION.REGIONAL && vertical === VERTICALS.CROSS_BORDER) ||
        (role === ROLES.CB.ADMIN && division === DIVISION.ZONAL && vertical === VERTICALS.CROSS_BORDER)) {
        return ROLES.CB.BRANCH_HEAD;
      } else if ((role === ROLES.DT.ADMIN && division === DIVISION.REGIONAL && vertical === VERTICALS.DOMESTIC_TRANSPORT) ||
        (role === ROLES.DT.ADMIN && division === DIVISION.ZONAL && vertical === VERTICALS.DOMESTIC_TRANSPORT)) {
        return ROLES.DT.BRANCH_HEAD;
      }
      return role;
    };

    for (const userRole of userRoles) {
      const modifiedRole = modifyUserRole(userRole, userDivision, userVertical);
      if (isAuthorized(modifiedRole, userVertical)) {
        return true;
      }
    }
    return false;
  }

  static getUserRole = () => {
    const userRec = loginStore.userRec.aguserRec
    return userRec.role
  };
}

export default RoleUtils
