import { useState } from "react"
import { useSnapshot } from "valtio"
import Agproducttab from "../components/Agproducttab"
import PricingMarkups from "./pricing-markups"
import AirpricingMarkups from "./airpricing-markups"
import FCLpricingMarkupEdit from "./fclpricing-markup-edit"
import FBAMarkupEdit from "./fba-markup-edit"
import FCLpricingCmacgmMarkupEdit from "./cmacgm-markup-edit"
import VisibilityExceptionsConfig from "../my-actions/visibilityExceptionsConfig"
import PowerbiConfig from "../powerbi/powerbiConfig"


let menuList = ['LCL Margins', 'Air Margins', 'FreightFy', 'FBA','CMACGM', 'Visibility Exceptions', 'PowerBI Config']

export default function PricingConfig() {
    const [currentMenu, setCurrentMenu] = useState('LCL Margins')
    const [data, setData] = useState({})
    let Comp = PricingMarkups
    if (currentMenu == 'Air Margins') Comp = AirpricingMarkups
    if (currentMenu == 'FreightFy') Comp = FCLpricingMarkupEdit
    if (currentMenu == 'FBA') Comp = FBAMarkupEdit
    if (currentMenu == 'CMACGM') Comp = FCLpricingCmacgmMarkupEdit
    if (currentMenu === 'Visibility Exceptions') Comp = VisibilityExceptionsConfig
    if (currentMenu === 'PowerBI Config') Comp = PowerbiConfig
    return (
        <div>
            <h4 className="title is-3">Config</h4>
            <div className="columns">
                <div className="column is-12" style={{zoom: '1.1'}}>
                <Agproducttab productList={menuList}
                        activeProduct={currentMenu} setActiveProduct={setCurrentMenu} />
                </div>
            </div>
            <Comp />
        </div>
    )
}