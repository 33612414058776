import {subscribe, useSnapshot } from "valtio";
import { DummySchedule } from "../enquiry/dummy-schedule";
import AirScheduleComp from "../enquiry/AirScheduleComp";
import FCLScheduleComp,{ FCLScheduleActionComp } from "../enquiry/FCLScheduleComp";
import LCLScheduleComp, { LCLScheduleActionComp } from "../enquiry/LCLScheduleComp";
import { AgmodalStore, bookingsStore, configStore, contractsStore, loginStore } from "../state/CommonState";
import { taskStore } from "./task-store";
import { useEffect, useState } from "react";
import Api from "../state/Api";
import LCLSchedules from "../enquiry/LCLSchedules";
import Utils from "../util/Utils";
import Taskapi from "./tasks-api";
import TaskDatetime from "./task-datetime";

function OriginalSchedule(props) {
    useSnapshot(bookingsStore)
    useSnapshot(taskStore)
    useSnapshot(configStore)
    useSnapshot(AgmodalStore)
    let brec = bookingsStore.current
    const stype = () => brec.contract.shipmentType.toUpperCase().substr(0, 3)
    let showbooking = { showbooking: '1' }
    return (
        <div style={{ fontSize: '0.8rem' }}>
            {
                bookingsStore.current?.hasToShowDummySchedule ? <DummySchedule stype={stype()} viewonly={true} />
                    : stype() === 'LCL' ?
                        <LCLScheduleActionComp data={bookingsStore.current.schedule} viewonly={true} {...showbooking} /> :
                        stype() === 'FCL' ?
                            <FCLScheduleActionComp data={bookingsStore.current.schedule} viewonly={true} {...showbooking} /> :
                            <AirScheduleComp viewonly={true} {...showbooking} />
            }
        </div>
    )
}
export function Schedulechange(props) {
    useSnapshot(bookingsStore)
    useSnapshot(taskStore)
    let task = taskStore.current
    useSnapshot(configStore)
    useSnapshot(AgmodalStore)
    let brec = bookingsStore.current

    if (task.stage === 1) {
        return (
            <>
                <Schchangemodal label='Enter reason for Rejection' name='rejectReason'
                    callback={async () => {
                        taskStore.current.stage = 3
                        taskStore.current.pendingWith = 'AGRAGA'
                        // taskStore.setCustomerComplete()
                        await Taskapi.updateTask(taskStore.current)
                        taskStore.myActionVisible = false
                        if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                            AgmodalStore.pageVisible = false
                            AgmodalStore.apiCall = true
                        }
                    }} />
                <hr />
                <p><strong>Original Schedule</strong></p>
                <OriginalSchedule />
                <hr />
                <div className="columns" style={{ marginLeft: '1rem' }}>
                    <hr />
                    <p><strong>Revised Schedule(s)</strong></p>
                </div>
                <div className="columns" style={{ marginLeft: '1rem' }}>
                    <p>Please note that we were unable to get a
                        booking on the schedule you have opted for,
                        due to <strong>{task.changeReason !== 'Other' ?
                            '' + task.changeReason : '' + task.changeReasonText}</strong>.
                        Please find below the revised schedule options and request you to
                        kindly select / approve the same.
                    </p>

                </div>
                <br />
                <div className="columns">
                    <p>
                        <DisplaySchedules />
                    </p>
                </div>
                <hr />
                <span>
                    <button className="button islink"
                        onClick={async (e) => {
                            let data = taskStore.current.schedules
                            data = data.filter(e => e.custAccept)
                            if (!data || data.length === 0) {
                                configStore.setModalMessage('At least one schedule needs to be selected')
                                return
                            }
                            taskStore.current.stage = 2
                            taskStore.current.pendingWith = 'AGRAGA'
                            taskStore.setCustomerComplete()
                            await Taskapi.updateTask(taskStore.current)
                            taskStore.myActionVisible = false
                            if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                AgmodalStore.pageVisible = false
                                AgmodalStore.apiCall = true
                            }
                        }}>Accept</button>
                    <button style={{ float: 'right' }} className="button is-danger"
                        onClick={() => {
                            taskStore.modalVisible = true;
                        }}>Reject</button>
                </span>
            </>
        )
    }
    if (task.stage === 2) {
        return (
            <>
                <hr />
                <p><strong>Original Schedule</strong></p>
                <OriginalSchedule />
                <hr />
                <div className="columns" style={{ marginLeft: '1rem' }}>
                    <hr />
                    <p><strong>Revised Schedule(s) selected by customer</strong></p>
                </div>
                <br />
                <div className="columns">
                    <DisplaySchedules />
                </div>
                <hr />
                <span>
                    <button className="button islink"
                        onClick={async (e) => {
                            let data = taskStore.current.schedules
                            console.log(data)
                            data = data.filter(e => e.agragaAccept)
                            if (!data || data.length !== 1) {
                                configStore.setModalMessage('Please select one schedule to acknowledge')
                                return
                            }
                            bookingsStore.current.schedule = data[0]
                            bookingsStore.current.hasToShowDummySchedule = false
                            console.log(bookingsStore.current)
                            let ret = await Taskapi.replaceBooking(bookingsStore.current)
                            if (!ret) return
                            taskStore.current.stage = 4
                            taskStore.current.pendingWith = 'AGRAGA'
                            taskStore.current.status = 'COMPLETED'
                            taskStore.setComplete()
                            await Taskapi.updateTask(taskStore.current)
                            taskStore.myActionVisible = false
                            if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                AgmodalStore.pageVisible = false
                                AgmodalStore.apiCall = true
                            }
                        }}>Acknowledge</button>
                </span>
            </>
        )
    }
    if (task.stage === 3) {
        return <SchedulechangeTrigger stage={3} />
    }
    if (task.stage === 4) {
        return (
            <>
                <hr />
                <p><strong>Original Schedule</strong></p>
                <OriginalSchedule />
                <hr />
                <div className="columns" style={{ marginLeft: '1rem' }}>
                    <hr />
                    <p><strong>Revised Schedule(s)</strong></p>
                </div>
                <br />
                <div className="columns">
                    <DisplaySchedules />
                </div>
            </>
        )
    }
}

export function SchedulechangeTrigger(props) {
    useSnapshot(bookingsStore)
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    const [schedules, setSchedules] = useState([])
    let brec = bookingsStore.current
    const stype = () => brec.contract.shipmentType.toUpperCase().substr(0, 3)
    
    const [disableButton, setDisableButton] = useState(true)
    
    const rec = bookingsStore.current.contract
    let showbooking = { showbooking: '1' }
    function check(){
        let disable = false
        if(!disable){
            let checkfile = []
            taskStore.current.files.map((e) =>{
                if(checkfile.indexOf(e.label) == -1){
                    if(taskStore.current.original_file.indexOf(e.label) != -1 && e.filename.length==0){
                        disable = true
                    }else{
                        if(taskStore.current.original_file.indexOf(e.label) != -1){
                            checkfile.push(e.label)
                        }
                    }
                }
            })
        }
        if(taskStore.current.schedules.length  <= 0){
            disable = true
        }
        if (rec.shipmentType.startsWith('FCL')){
            taskStore.current.schedules.map((e,i) => {
                let etd = e['oetd']
                let etdDate = Utils.newDate(etd)
                let porCode = e['POR Code']
                let polCode = e['ocode']
                let startDate = Utils.newDate(rec.mailedOn)
                startDate = Utils.addDays(startDate, 3)
                let currentDate = new Date()
                let cargoReadinessDate = (rec.cargoReadinessDate && rec.cargoReadinessDate.length >= 10) ?
                    new Date(rec.cargoReadinessDate) : currentDate
                if (cargoReadinessDate < currentDate) cargoReadinessDate = currentDate
                if (isNaN(cargoReadinessDate)) cargoReadinessDate = currentDate
                let startDays = (porCode === polCode) ? 2 : 8 
                startDate = Utils.addDays(cargoReadinessDate, startDays)
            
                if (etdDate < startDate && !e.invalidschedules) {
                    e.invalidschedules = true
                }
                let endDate = Utils.newDate(rec.validUpto)
                if (etdDate > endDate && !e.invalidschedules) {
                    e.invalidschedules = true
                }
            })
        }
        if (rec.shipmentType.startsWith('LCL')){
            taskStore.current.schedules.map((e,i) => {
                if (!e['POR (CFS / ICD / PORT)']) e['POR (CFS / ICD / PORT)'] = e['POL']
                let srec = {
                    por: e['POR (CFS / ICD / PORT)'],
                    pol: e['POL'],
                    etdPol: e['ETD (POL)']
                }
                let etdDate = Utils.newDate(srec.etdPol)
                let currentDate = new Date()
                let cargoReadinessDate = (rec.cargoReadinessDate && rec.cargoReadinessDate.length >= 10) ?
                    new Date(rec.cargoReadinessDate) : currentDate
                if (cargoReadinessDate < currentDate) cargoReadinessDate = currentDate
                if (isNaN(cargoReadinessDate)) cargoReadinessDate = currentDate
                let startDays = (srec.pol === srec.por) ? 2 : 8
                let startDate = Utils.addDays(cargoReadinessDate, startDays)
                if (etdDate < startDate && !e.invalidschedules) {
                    e.invalidschedules = true
                }
            
                let endDate = Utils.newDate(rec.validUpto)
                let endDays = srec.por.location !== srec.pol.location ? 8 : 2
                endDate = Utils.addDays(endDate, endDays * -1)
                endDate = Utils.newDate(rec.validUpto)
                if (etdDate > endDate && !e.invalidschedules) {
                    e.invalidschedules = true
                }
            })
        }
        setDisableButton(disable)
    }
    useEffect(() => {
        check()
        let unsubscribe = subscribe(taskStore.current, () => {
            check()
        })
        return unsubscribe
    }, [taskStore.current])
    let triggerLabel = 'Trigger Action'
    if (props.stage === 3) {
        triggerLabel = 'Re-trigger Action'
    }

    const parent = "agi7"
    var parent_container
    var newHandle = function(e) { 
        let parent_container1 = null
        if(parent){
            parent_container = e.target;
            do {
                parent_container = parent_container.parentNode;

                if( parent_container.id!=parent){
                    parent_container1 = null
                }else{
                    parent_container1 = parent_container
                }
            }
            while( parent_container1==null && parent_container !== document.body );
            parent_container = parent_container1
        }
    };
    var newHandle1 = function(e) { 
        if(parent && parent_container){
            let i = parent_container.getElementsByTagName('input')
            i[0].files = e.clipboardData.files
            if(e.clipboardData.files.length>0){
                change(i[0].files)
            }
        }
    };
    if(parent){
        document.getElementsByTagName('body')[0].onclick = function(e) {newHandle(e)}
        document.getElementsByTagName('body')[0].onpaste = function(e) {newHandle1(e)}
    
    }

    function change(files){
        const formData = new FormData()
        formData.append('myFile', files[0])
        formData.append('action', 'schedule_upload')
        formData.append('shipmentType', brec.contract.shipmentType)
        formData.append('id', brec.contract._id)
        fetch(`${process.env.REACT_APP_API_SERVER}/api/v1/common/convertxltojson`, {
            method: 'POST',
            headers: Api.H1(),
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                console.log('got data', data)
                if (data.error) { if (data.error!='No Error' || data.error!='Invalid Token') { configStore.setModalMessage(data.error) }}
                else {
                    for (let i = 0; i < data.length; i++) {
                        let e
                        e = data[i]
                        e.custAccept = false
                        e.agragaAccept = false
                        data[i].invalidschedules = false
                        //[e.custAccept, e.agragaAccept] = [false, false]
                    }

                    taskStore.current.schedules = data   
                    document.getElementById(parent).getElementsByTagName('input')[0].value = ""                                 
                }
            })
            .catch(error => {
                console.error(error)
            })
    }
    return (
        <>
            {
                (props.stage === 3) ?
                    <div>
                        The revised schedule has been rejected by the Customer due to <strong>{task.rejectReason}</strong>
                    </div>
                    : <TaskDatetime />
            }
            <hr />
            <div><strong>Reason</strong></div>
            <div className="select">
                <select className="select is-small" value={task.changeReason}
                    onChange={(e) => {
                        task.changeReason = e.target.value
                    }}>
                    <option value='Schedule Not available from selected carrier'>Schedule Not available from selected carrier</option>
                    <option value='Vessel allocation is full'>Vessel allocation is full</option>
                    <option value='Others'>Others</option>
                </select>
            </div>
            <br />
            {
                (task.changeReason === 'Others') ?
                    <textarea placeholder="Enter reason for schedule change" cols='50' rows='3' value={task.changeReasonText} onChange={(e) => {
                        task.changeReasonText = e.target.value
                    }} />
                    : <></>
            }
            <hr />
            <p><strong>Original Schedule</strong></p>
            <div style={{ fontSize: '0.8rem' }}>
                {
                    bookingsStore.current?.hasToShowDummySchedule ? <DummySchedule stype={stype()} viewonly={true} />
                        : stype() === 'LCL' ?
                            <LCLScheduleActionComp data={bookingsStore.current.schedule} viewonly={true} {...showbooking} /> :
                            stype() === 'FCL' ?
                                <FCLScheduleActionComp data={bookingsStore.current.schedule} viewonly={true} {...showbooking} /> :
                                <AirScheduleComp viewonly={true} {...showbooking} />
                }
            </div>
            <hr />
            <div className="columns" id="agi7">
                <div className="column is-3">
                    <p><strong>Upload Revised Schedules</strong></p>
                </div>
                <div className="column is-3" >
                    <input id="schedulefile" type="file" onChange={(e1) => {
                      
                       const pattern = /^\S[\s\S]*\S$|^\S$/;
            if(pattern.test(e1.target.files[0]['name'])){
                change(e1.target.files)
            }else{
                configStore.setModalMessage("Please Check File Name and Format")
                return e1.target.value = ""
            }
                    }} />
                </div>
                <div className="column is-3>">
                    {
                        (schedules.length > 0) ?
                            <button className="button islink is-rounded">Save</button>
                            : <></>
                    }
                </div>
            </div>
            <p>
                <DisplaySchedules />
            </p>
            <hr />
            <p>
                <button className="button islink" disabled={disableButton}
                    onClick={async (e) => {
                        taskStore.current.stage = 1
                        taskStore.current.pendingWith = 'CUSTOMER'
                        if (props.stage === 3) {
                            await Taskapi.updateTask(taskStore.current)
                            taskStore.myActionVisible = false
                            if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                AgmodalStore.pageVisible = false
                                AgmodalStore.apiCall = true
                            }
                        } else {
                            setDisableButton(true)
                            await Taskapi.saveTask(taskStore.current)
                            taskStore.triggerActionVisible = false
                            if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                AgmodalStore.pageVisible = false
                                AgmodalStore.apiCall = true
                            }
                        }
                    }}>{triggerLabel}</button>
                
            </p>
        </>
    )
}

export function DisplaySchedules(props) {
    useSnapshot(contractsStore)
    useSnapshot(taskStore)
    let task = taskStore.current
    const rec = contractsStore.current
    let data = taskStore.current.schedules
    if (task.stage === 2) {
        data = data.filter(e => e.custAccept)
    }
    data.sort((o1, o2) => {
        if (o1.oetd) return Utils.compareDates(o1.oetd, o2.oetd)
        return Utils.compareDates(o1['ETD (POL)'], o2['ETD (POL)'])
    })
    let Comp = <></>
    if (rec.shipmentType.startsWith('LCL')) Comp = LCLScheduleActionComp
    if (rec.shipmentType.startsWith('FCL')) Comp = FCLScheduleActionComp
    return (
        <>
            <div style={ loginStore.isClient()  ? {borderRadius:"10px",backgroundColor:"white",width:"100vw"}  :  { boxShadow: '5px 5px #dddddd', border: '1px solid lightgrey' }}>
                <div className="card-content" style={{ paddingTop: '0px' }}>
                    <div className="content">
                        {
                            data.map((e,i) => {
                                 console.log("the validity of schedule is ", e.invalidschedules)
                                return (
                                    <div className="columns">
                                        <div className="column is-11">
                                            <Comp data={e} viewonly='1' />
                                        </div>
                                        {
                                            (task.stage !== 0) ?
                                                <div className="column is-1" style={{ fontSize: '0.8rem' }}>
                                                    {
                                                        (task.stage === 1) ?
                                                            <>
                                                                <div style={{ marginTop: '50px' }}>
                                                                    <label className="checkbox">
                                                                        <input className="custcheckbox" type="checkbox" checked={e.custAccept} disabled={e.invalidschedules}
                                                                            onChange={(e1) => e.custAccept = e1.target.checked} />
                                                                    </label>
                                                                </div>
                                                                <hr />
                                                            </>
                                                            : <></>
                                                    }
                                                    {
                                                        (task.stage === 2) ?
                                                            <div style={{ marginTop: '50px' }}>
                                                                <label className="radio" for="agragaAccept">
                                                                    <input className="agcheckbox" name="agragaAccept" type="radio" checked={e.agragaAccept}
                                                                        onClick={(e1) =>{
                                                                            for (let d = 0; d < data.length; d++) {
                                                                                if(d!=i){
                                                                                    data[d]['agragaAccept'] = false
                                                                                }
                                                                            }
                                                                             e.agragaAccept = e1.target.checked
                                                                             } }/>
                                                                </label>
                                                            </div>
                                                            : <></>
                                                    }
                                                    {
                                                        (task.stage === 4) ?
                                                            <div style={{ textAlign: 'center' }}>
                                                                <div style={{ marginTop: '20px' }}>
                                                                    <div><strong>Customer</strong></div>
                                                                    <label className="checkbox">
                                                                        <input className="custcheckbox" type="checkbox" checked={e.custAccept} />
                                                                    </label>
                                                                </div>
                                                                <div style={{ marginTop: '10px' }}>
                                                                    <div><strong>Agraga</strong></div>
                                                                    <label className="checkbox">
                                                                        <input className="agcheckbox" type="checkbox" checked={e.agragaAccept} />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            : <></>
                                                    }
                                                </div>
                                                : <></>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export function Schchangemodal(props) {
    const { label, name, callback } = props
    const [message, setMessage] = useState('')
    const [saveDisabled, setSaveDisabled] = useState(true)
    useSnapshot(configStore)
    useSnapshot(AgmodalStore)
    useSnapshot(taskStore)
    let modalClass = 'modal'
    if (taskStore.modalVisible) modalClass += ' is-active'
    useEffect(() => {
        setMessage(!taskStore.current[name] ? '' : taskStore.current[name])
    }, [taskStore.modalVisible])
    useEffect(() => {
        if (message.length > 0 && saveDisabled) setSaveDisabled(false)
        else if (message.length === 0 && !saveDisabled) setSaveDisabled(true)
    }, [message])
    return (
        <div className={modalClass} onClick={() => { taskStore.modalVisible = false }}>
            <div className="modal-background" ></div>
            <div className="modal-card" onClick={(e) => { e.stopPropagation() }}
                style={{ borderRadius: '10px' }}>

                <section className="modal-card-body" style={{ textAlign: 'center' }}>
                    <button className="delete" style={{ float: 'right' }}
                        onClick={() => { taskStore.modalVisible = false }}></button>
                    <div><strong>{label}</strong></div>
                    <div>
                        <textarea cols='70' rows='5' value={message}
                            onChange={(e) => {
                                setMessage(e.target.value)
                            }} />
                        <div>
                            <button hidden={false} disabled={saveDisabled} className="button is-small is-link"
                                onClick={() => {
                                    taskStore.current[name] = message
                                    callback()
                                    taskStore.modalVisible = false
                                    if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                        AgmodalStore.pageVisible = false
                                        AgmodalStore.apiCall = true
                                    }
                                }}>
                                Save
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}
