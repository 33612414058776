import { Box, Button, Checkbox, CircularProgress, Container, Dialog, Grid, IconButton, Typography } from '@mui/material'
import React, { useState,useRef, useEffect } from 'react'
import "./signUP.styles.css"
import InputField from '../mui-components/InputField'
import logo from "../assets/images/Logo-new.svg"
import { useForm } from "react-hook-form"
import { aguserStore, configStore, loginStore, viewBookingStore } from "../state/CommonState";
import Carousel from '../mui-components/Carousel'
import { useSnapshot } from "valtio";
import UsersApis from '../state/users-api'
import ApiAgTable from '../components/ag-table/api_ag-table'
import { enquiryStore } from '../enquiry/enquiry-data'
import { SelectedBooking } from './SelectedBooking'
import closeIcon from "../assets/images/closeicon.svg"
// import { useMode } from '../context/AuthContext'
import { LoaderButton } from '../mui-components/LoaderButton'
import Utils from '../util/Utils'
function TrackShipMent({callback}) {
  // const {setMode : callback} = useMode();
  const [error, setError] = useState("")
  const [isToggled, setisToggled] = useState(true)
  
  const loginForm = useForm({
      defaultValues:{
        trackingId:""
      }
  })

  const [disable,setDisable] = useState(false);
  const [openBooking,setBookingOpen] = useState(false);

  const {handleSubmit,register,formState:{errors},control,getValues,setValue,watch,reset} = loginForm;

  
  
  const submit = async (data)=>{
      try {
        setDisable(true);
        setError("");
        let searchBasedOn = "";
        
        if(TabType[selectedType]==='Booking Number'){
          searchBasedOn = "ref";
        }else if(TabType[selectedType]==='HBL / HAWB Number'){
          searchBasedOn =  "hbl"
        }else if(TabType[selectedType]==='MBL / MAWB Number'){
          searchBasedOn = "mbl"
        }


        /**
         * uncomment this code to show the same html content for mobile web view 
         */
        // if(Utils.isMobile()){
        //       window.location.href = process.env.REACT_APP_API_SERVER+"/api/v1/common/tracking/"+getValues("trackingId")+"?search="+searchBasedOn
        // } 
        

         ApiAgTable.getDsrById(data.trackingId,searchBasedOn,(data)=>{
            if(data.results1?.length>0){
              const booking = data.results1[0];
              viewBookingStore.accordionToBeOpended = 1;
              viewBookingStore.current = booking;
              viewBookingStore.commonView = true;
              enquiryStore.initializeWithConract(booking.booking.contract);
              
              setBookingOpen(true);
              reset();
              setDisable(false);
            }else{
              setError(`No booking found for given ${TabType[selectedType]}`)
              setDisable(false);
            }
         })
      } catch (error) {
        setDisable(false);
         configStore.setModalMessage("Something went wrong")
      }finally{
        //  setDisable(false);
      }
  }
  const TabType = ['Booking Number', 'HBL / HAWB Number', 'MBL / MAWB Number'];
  const [selectedType, setType] = useState(0);

  useEffect(()=>{
    if(window.location.href?.includes("trackshipment")){
        let trackingId = window.location.href.split("/").pop();
        if(trackingId && trackingId!=="trackshipment"){
          const urlParams = new URLSearchParams(window.location.search);
          const searchParam = urlParams.get('search');
          if(searchParam){
             if(searchParam==="hbl"){
                 setType(1)
             }else if(searchParam==="mbl"){
                 setType(2)
             }
             trackingId = trackingId.split("?")[0];
          }
          let getEntity = Utils.getEntityFromBookingId(trackingId)
          if(getEntity){
            if(loginStore.isClient()){
              let isEntityFound = loginStore.userRec?.companyList?.find((e) =>e._id == getEntity)
              if(isEntityFound){
               window.location.href = window.location.origin+"/visibility/view/"+trackingId;
               return;
              }  
             }
          }
            setValue("trackingId",trackingId);
        }else{
          setDisable(false);
          setError("");
          setValue("trackingId","");
        }
     }
  },[selectedType]);

 

  useEffect(()=>{
   return ()=>{
     viewBookingStore.commonView = false;
   }
  },[])
  return (
    <>
  <div className='signIN_container'>
    <div className='info_container'>
      <div className='logo_container'>
      <img src={logo}></img>
      </div>
    <Typography variant="h3" fontFamily={"Figtree, sans-serif"} fontWeight={900} marginBottom={"1rem"} gutterBottom>Lead your supply chain
    with ease.</Typography>
    <Typography variant="h6" fontFamily={"Figtree, sans-serif"} color={"#555"} fontWeight={400} marginBottom={"1rem"} gutterBottom>Experience seamless solutions, Transparent value chains and Boundless trade!</Typography>
    <Carousel/>
    </div>
    <div className='field_container'>
        <div className='trackshipment_btns_container'>
        <button className="button" style={{border:"2px solid #2c358a",fontWeight:500}} onClick={()=> {
        callback('login')
        window.history.pushState('signin', 'signin', '/');
        }}>Sign In</button>
        <button className="button is-link" style={{backgroundColor:"#2c358a",fontWeight:500}} onClick={()=> {
      window.history.pushState('signup', 'signup', '/');
      callback('register')
       }}>Sign Up</button>
        </div>

      <form className='field_wrapper' onSubmit={handleSubmit(submit)}>
      <Typography variant="h5" fontFamily={"Figtree, sans-serif"} fontWeight={600} textAlign={"center"} marginBottom={"1rem"} gutterBottom>Track your Shipment</Typography>
      <Box sx={{ display: "flex", flexDirection: "row", width: "100%",marginBottom:"2rem", marginTop:"2rem", height: "18%", backgroundColor: "#F5F5F5", borderRadius: "20px", padding: "4px", fontSize: "14px", fontFamily: "Figtree" }}>
                        {TabType.map((type, i) => <Box onClick={() => {
                            setType(i);
                        }} sx={{
                            borderRadius: "20px", backgroundColor: selectedType === i ? "#2C358A" : "transparent", color: selectedType === i ? "white" : "#555555", flex: 1, display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold", fontFamily: "Figtree",
                            "&:hover": {
                                cursor: "pointer"
                            }
                        }} >{type}</Box>)}
                    </Box>
      <InputField 
      placeholder={`Enter ${TabType[selectedType]}`}
    //   label={"Email"}
      label_class={"label_styles"}
      mandatory={false}
      input_class={"inputBox_styles"}
      errorsmsg={errors?.trackingId?.message || error}
      input_wrapeer={"input_wrapeer"}
      type={"text"}
      // onkeyup={(e)=> e.target.value = e.target?.value?.toLowerCase()}
      children={{...register("trackingId",{required: "Tracking Id is Required"})}}
      callback={(e)=>console.log(e)}
      name={"trackingId"}
      ></InputField>
      <LoaderButton variant="contained"  enableLoader={disable} buttonContent="Track Now"/>
        {/* <Button disabled={disable}  sx={{textTransform:"capitalize"}} size='medium' variant="contained" className='btn_color' type='submit'    fullWidth>{ disable ? <CircularProgress size={25} sx={{color:"white"}} /> :  "Track Now"}</Button> */}
      </form> 
    </div>
  </div>
    <Dialog PaperProps={{
      style : {
        backgroundColor : "#2C358A !important"
      }
    }} sx={{margin:2,"&::-webkit-scrollbar-thumb": {
            backgroundColor: "#2C358A !important"
        } }} fullScreen open={openBooking}>
      <Box sx={{width:"100%",flex:1,display:"flex",flexDirection:"column",gap: 1,backgroundColor:"#EEEFF4",padding:"10px","&::-webkit-scrollbar-thumb": {
            backgroundColor: "#2C358A !important"
        } }}>
      <Box sx={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
            <Typography sx={{fontFamily:"Figtree",fontSize:"20px",fontWeight:"bold",color:"#2D48A6"}}>Booking Id - {viewBookingStore.current._id}</Typography>
            <IconButton onClick={()=>{
              viewBookingStore.commonView = false;
               setBookingOpen(false);
            }}><img style={{height:"18.4px",width:"18.4px"}} src={closeIcon}/></IconButton>
        </Box> 
        <SelectedBooking callback={callback} />
      </Box>
    </Dialog>
    </>
  )
}



export default TrackShipMent