import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { bookingsStore, configStore } from "../state/CommonState";
import Newapi from "../state/new-api";
import Utils from "../util/Utils";
import ChartApi from "./chart.api";

export default function ChartNewContracts(props) {
    useSnapshot(bookingsStore)
    useSnapshot(configStore)
    const [conSummary, setConSummary] = useState({})
    const [toexpirecontracts, settoexpirecontracts] = useState([])
    let curDate = Utils.getCurrentDateString()
    let nextWeek = Utils.getCurrentDateString(Utils.addDays(new Date(), 7))
    let chartEntity = props?.chartSelectedEntity

    
    const summarizeContracts = (data) => {
        let val = {
            'active': 0,
            'expired': 0,
            'toexpire': 0
        }
        let arr = []
        if(data && data.length>0){
            data.filter(e => e.mode === 'CONTRACT').map(e => {
                let d = e.validUpto
                if (d >= curDate) val['active'] += 1
                else val['expired'] += 1
                if (d >= curDate && d < nextWeek) {
                    val['toexpire'] += 1
                    arr.push(e)
                }
            })
        }
        settoexpirecontracts(arr)
        setConSummary(val)
    }
    useEffect(() => {
        ChartApi.getContractsForChart(summarizeContracts, chartEntity)
    }, [chartEntity])

    return (
        <>
            <div className="columns" style={{ textAlign: 'center', alignItems: 'center' }}>
                <div className="column is-4">
                    <div style={{ fontSize: '2rem', fontWeight: 'bold', color: '#2c358a', marginTop: '0px' }}>
                        {conSummary['active']}
                    </div>
                    <div style={{ fontSize: '1.3rem', fontWeight: 'bold', color: '#2c358a', whiteSpace: 'nowrap'}}>Active</div>
                </div>
                <div className="column is-4">
                    <div style={{ fontSize: '2rem', fontWeight: 'bold', color: '#2c358a', marginTop: '0px' }}>
                        {conSummary['expired']}
                    </div>
                    <div style={{ fontSize: '1.3rem', fontWeight: 'bold', color: '#2c358a', whiteSpace: 'nowrap'}}>Expired</div>
                </div>
                <div className="column is-4">
                    <a href={`${window.location.href}toexpirecontracts`} target="_blank">
                        <div style={{ fontSize: '2rem', fontWeight: 'bold', color: '#2c358a', 
                        marginTop: '0px' }}>
                            {conSummary['toexpire']}
                        </div>
                    </a>
                    <div style={{ fontSize: '1.3rem', fontWeight: 'bold', color: '#2c358a', whiteSpace: 'nowrap'}}>Expiring in 7 days</div>
                </div>
            </div>
        </>
    )
}