import Utils from "../util/Utils"


export const FFF = [
    {
        downStreamMilestone: () => true,
        milestone: "origin",
        location:(booking)=>{
            return Utils.getCity(booking.origin,true)
        },
        dynamicIcon: false,
        isPortMilestone: false,
        milestoneAchievedBasedOn: (booking, index) => {
            let milestoneKeys = ["loaded_and_dispatch", "container_handover", "gatein_pol", "vgm_cut_off"];
            let vehicleKeys = ["cargo_pickup"]

            if ((booking["containerlist"] && booking["containerlist"].length > 0) || (booking["vorgin"] && booking["vorgin"].length > 0)) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                if (booking.shipmentType.split("/")[0].includes("Dock") && booking.shipmentScope.split("-")[0] === "Door") {
                    console.log("the origin of the FCL is ")
                    return booking["vorgin"].map((container) => vehicleKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneAdvancedBasedOn: (booking, index) => {
            let milestoneKeys = ["loaded_and_dispatch"];
            let vehicleOriginKeys = ["cargo_pickup"]
            let otherMileStoneKeys = ["vgm_cut_off"];
            let findFrom = "containerlist";
            let keys = milestoneKeys;

            if (Utils.isValueExists(otherMileStoneKeys, booking)) {
                return true;
            }

            if (booking.shipmentType.split("/")[0].includes("Dock") && booking.shipmentScope.split("-")[0] === "Door") {
                findFrom = "vorgin";
                keys = vehicleOriginKeys;
            }

            if (booking[findFrom] && booking[findFrom].length > 0) {
                if (index > -1 && index < booking[findFrom].length   && findFrom === "containerlist") {
                    return Utils.isValueExists(keys, booking[findFrom][index])
                }
                return booking[findFrom].map((container) => keys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["container_handover", "gatein_pol"]

            let otherMileStoneKeys = ["vgm_cut_off"];
            if (Utils.isValueExists(otherMileStoneKeys, booking)) {
                return true;
            }

            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        isMilestoneAddsToThePath: ({ shipmentScope }) => shipmentScope.split("-")[0].toLowerCase() === "door",
        milestoneDetails: [
            // {
            //     isLabel: true,
            //     label: "Cargo Picked-Up",

            // },
            {
                isLabel: false,
                label: "Picked-Up",
                activeLabel: "Picked-Up",
                estimate: "loaded_and_dispatch",
                count: (booking) => {
                    let key = "loaded_and_dispatch";
                    let vehicleOriginKey = "cargo_pickup";
                    let findFrom = "containerlist";
                    let keys = key;
                    if (booking.shipmentType.split("/")[0].includes("Dock") && booking.shipmentScope.split("-")[0] === "Door") {
                        findFrom = "vorgin";
                        keys = vehicleOriginKey;
                    }
                    return { parentKey: findFrom, childKey: keys }
                },
                actual: (booking, isMilestoneAchieved, index) => {
                    let key = "loaded_and_dispatch";
                    let vehicleOriginKey = "cargo_pickup";
                    let findFrom = "containerlist";
                    let keys = key;
                    if (booking.shipmentType.split("/")[0].includes("Dock") && booking.shipmentScope.split("-")[0] === "Door") {
                        findFrom = "vorgin";
                        keys = vehicleOriginKey;
                    }
                    if (isMilestoneAchieved) {
                        if (index > -1 && index < booking[findFrom].length && findFrom === "containerlist") {
                            return booking[findFrom][index][keys]
                        }
                        if(booking[findFrom] && booking[findFrom].length>0){
                        let dayDiff = booking[findFrom].map((container) => Utils.getDateDifferenceForProgress(container[keys], new Date()));
                        let maxIndex = 0;

                        for (let i = 1; i < dayDiff.length; i++) {
                            if (dayDiff[maxIndex] > dayDiff[i]) {
                                maxIndex = i;
                            }
                        }
                        return booking[findFrom][maxIndex][keys];
                    }
                    }
                    return "";
                }
            }
        ]
    },
    {
        milestone: "por",
        location: "por_location",
        dynamicIcon: true,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        isMilestoneAddsToThePath: ({ por, pol }) => por !== pol,
        milestoneAchievedBasedOn: (booking, index) => {
            let milestoneKeys = ["container_handover"]
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneAdvancedBasedOn: (booking, index) => {
            let milestoneKeys = ["por_to_pol_movement"]
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_pol"]
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).every((isTrue) => isTrue);
            }
            return false
        },
        milestoneDetails: [
            {
                isLabel: false,
                label: "Handover",
                activeLabel: "Handover",
                estimate: "container_handover",
                count: () => {
                    return { parentKey: "containerlist", childKey: "container_handover" }
                },
                actual: (booking, isMilestoneAchieved, index) => {
                    let key = "container_handover";
                    if (index > -1) {
                        return booking["containerlist"][index][key]
                    }
                    if (isMilestoneAchieved) {
                        if (booking["containerlist"] && booking["containerlist"].length > 0) {
                        let dayDiff = booking["containerlist"].map((container) => Utils.getDateDifferenceForProgress(container[key], new Date()));
                        let maxIndex = 0;
                        for (let i = 1; i < dayDiff.length; i++) {
                            if (dayDiff[maxIndex] > dayDiff[i]) {
                                maxIndex = i;
                            }
                        }
                        return booking["containerlist"][maxIndex][key];
                    }
                    }
                    return "";
                }
            },
            {
                isLabel: false,
                label: "Gate-Out",
                activeLabel: "Gate-Out",
                estimate: "por_to_pol_movement",
                count: () => {
                    return { parentKey: "containerlist", childKey: "por_to_pol_movement" }
                },
                actual: (booking, isMilestoneAchieved, index) => {
                    let key = "por_to_pol_movement";
                    if (index > -1) {
                        return booking["containerlist"][index][key]
                    }
                    if (isMilestoneAchieved) {
                        if (booking["containerlist"] && booking["containerlist"].length > 0) {
                        let dayDiff = booking["containerlist"].map((container) => Utils.getDateDifferenceForProgress(container[key], new Date()));
                        let maxIndex = 0;
                        for (let i = 1; i < dayDiff.length; i++) {
                            if (dayDiff[maxIndex] > dayDiff[i]) {
                                maxIndex = i;
                            }
                        }
                        return booking["containerlist"][maxIndex][key];
                    }
                    }
                    return "";
                }
            }
        ]
    },
    {
        milestone: "pol",
        location: "pol_location",
        dynamicIcon: true,
        isMilestoneAddsToThePath: () => true,
        downStreamMilestone: () => true,
        milestoneAchievedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_pol"]
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    console.log("the index of the value is ", index, " ", booking["containerlist"][index])
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        vesselNameKey: "fl_vessel_name",
        vesselNumberKey: "fl_vessel_number",
        milestoneAdvancedBasedOn: ["sob_pol"],
        isPortMilestone: true,
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_fpod"]
            let transhipment = ["ts1_arrival", "ts2_arrival", "pod_arrival"];
            if (Utils.isValueExists(transhipment, booking)) {
                return true;
            }
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneDetails: [
            {
                isLabel: false,
                label: "Gate In",
                activeLabel: "Gate In",
                estimate: "gate_cut_off",
                count: () => {
                    return { parentKey: "containerlist", childKey: "gatein_pol" }
                },
                actual: (booking, isMilestoneAchieved, index) => {
                    let key = "gatein_pol";
                    if (index > -1) {
                        return booking["containerlist"][index][key]
                    }
                    // console.log("the trdhb ytdcgf tfcrfc ggvgg ",isMilestoneAchieved);
                    // if (isMilestoneAchieved) {
                        if(booking["containerlist"] && booking["containerlist"].length>0){
                        let dayDiff = booking["containerlist"].map((container) => Utils.getDateDifferenceForProgress(container[key], new Date()));

                        let maxIndex = 0;
                        for (let i = 1; i < dayDiff.length; i++) {
                            if (dayDiff[maxIndex] > dayDiff[i]) {
                                maxIndex = i;
                            }
                        }
                        // console.log("the index of the container is ",maxIndex);
                        return booking["containerlist"][maxIndex][key];
                    }
                    // }
                    return "";
                }
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "SOB",
                estimate: "etd_at_pol",
                actual: "sob_pol"
            }
        ]
    },
    {
        milestone: "ts1_port",
        location: "ts1_port_location",
        dynamicIcon: true,
        downStreamMilestone: () => true,
        isMilestoneAddsToThePath: ({ ts1_port }) => ts1_port && ts1_port.length > 0,
        milestoneAchievedBasedOn: ["ts1_arrival"],
        milestoneAdvancedBasedOn: ["ts1_departure"],
        isPortMilestone: true,
        vesselNameKey: "ts1_vessel_name",
        vesselNumberKey: "ts1_vessel_number",
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_fpod"]
            let otherMileStoneKeys = ["ts2_arrival", "ts3_arrival", "ts4_arrival", "pod_arrival"];
            if (Utils.isValueExists(otherMileStoneKeys, booking)) {
                return true;
            }
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "ts1_arrival_estimate",
                actual: "ts1_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "ts1_departure_estimate",
                actual: "ts1_departure"
            }
        ]
    },
    {
        milestone: "ts2_port",
        location: "ts2_port_location",
        dynamicIcon: true,
        isPortMilestone: true,
        isMilestoneAddsToThePath: ({ ts2_port }) => ts2_port && ts2_port.length > 0,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        milestoneAchievedBasedOn: ["ts2_arrival"],
        milestoneAdvancedBasedOn: ["ts2_departure"],
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_fpod"]
            let otherMileStoneKeys = ["ts3_arrival", "ts4_arrival", "pod_arrival"];
            if (Utils.isValueExists(otherMileStoneKeys, booking)) {
                return true;
            }
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        vesselNameKey: "ts2_vessel_name",
        vesselNumberKey: "ts2_vessel_number",
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "ts2_arrival_estimate",
                actual: "ts2_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "ts2_departure_estimate",
                actual: "ts2_departure"
            }
        ]
    },
    {
        milestone: "ts3_port",
        location: "ts3_port_location",
        dynamicIcon: true,
        isMilestoneAddsToThePath: ({ ts3_port }) => ts3_port && ts3_port.length > 0,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        vesselNameKey: "ts3_vessel_name",
        vesselNumberKey: "ts3_vessel_number",
        milestoneAchievedBasedOn: ["ts3_arrival"],
        milestoneAdvancedBasedOn: ["ts3_departure"],
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_fpod"]
            let otherMileStoneKeys = ["ts4_arrival", "pod_arrival"];
            if (Utils.isValueExists(otherMileStoneKeys, booking)) {
                return true;
            }
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "ts3_arrival_estimate",
                actual: "ts3_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "ts3_departure_estimate",
                actual: "ts3_departure"
            }
        ]
    },
    {
        milestone: "ts4_port",
        location: "ts1_port_location",
        dynamicIcon: true,
        isMilestoneAddsToThePath: ({ ts4_port }) => ts4_port && ts4_port.length > 0,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        vesselNameKey: "ts4_vessel_name",
        vesselNumberKey: "ts4_vessel_number",
        milestoneAchievedBasedOn: ["ts4_arrival"],
        milestoneAdvancedBasedOn: ["ts4_departure"],
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_fpod"]
            let otherMileStoneKeys = ["pod_arrival"];
            if (Utils.isValueExists(otherMileStoneKeys, booking)) {
                return true;
            }
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "ts4_arrival_estimate",
                actual: "ts4_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "ts4_departure_estimate",
                actual: "ts4_departure"
            }
        ]
    },
    {
        milestone: "pod",
        location: "pod_location",
        dynamicIcon: true,
        isMilestoneAddsToThePath: ({ pod, fpod }) => pod !== fpod,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        milestoneAchievedBasedOn: ["pod_arrival"],
        milestoneAdvancedBasedOn: (booking, index) => {
            let milestoneKeys = ["gateout_to_fpod"]
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_fpod"]
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "eta_at_pod",
                actual: "pod_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "etd_pod",
                count: () => {
                    return { parentKey: "containerlist", childKey: "gateout_to_fpod" }
                },
                actual: (booking, isMilestoneAchieved, index) => {
                    let key = "gateout_to_fpod";
                    if (index > -1) {
                        return booking["containerlist"][index][key]
                    }
                    if (isMilestoneAchieved) {
                        if (booking["containerlist"] && booking["containerlist"].length > 0) {
                        let dayDiff = booking["containerlist"].map((container) => Utils.getDateDifferenceForProgress(container[key], new Date()));
                        let maxIndex = 0;
                        for (let i = 1; i < dayDiff.length; i++) {
                            if (dayDiff[maxIndex] > dayDiff[i]) {
                                maxIndex = i;
                            }
                        }
                        return booking["containerlist"][maxIndex][key];
                    }
                    }
                    return "";
                }
            }
        ]
    },
    {
        milestone: "fpod",
        location: "fpod_location",
        dynamicIcon: true,
        isMilestoneAddsToThePath: () => true,
        isPortMilestone: false,
        milestoneAchievedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_fpod"];
            let keys = ["cargo_picked_up_by_consignee"];
            if (Utils.isValueExists(keys, booking)) {
                return true;
            }
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneAdvancedBasedOn: (booking, index) => {
            let milestoneKeys = ["container_pickup_fpod"]
            let keys = ["cargo_picked_up_by_consignee"];
            if (Utils.isValueExists(keys, booking)) {
                return true;
            }
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["container_delivery"];
            let dockMileStone = ["actual_delivery_date"];
            let keys = ["cargo_picked_up_by_consignee"];
            if (Utils.isValueExists(keys, booking)) {
                return true;
            }

            if (booking.shipmentType.split("/")[1].includes("Dock")) {
                if (booking["vdes"] && booking["vdes"].length > 0) {
                    return booking["vdes"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
                }
            } else {

                if (booking["containerlist"] && booking["containerlist"].length > 0) {
                    if (index > -1) {
                        return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                    }
                    return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
                }
            }
            return false
        },
        downStreamMilestone: ({ shipmentScope }) => shipmentScope.split("-")[2].toLowerCase() === "door",
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "eta_fpod",
                count: () => {
                    return { parentKey: "containerlist", childKey: "gatein_fpod" }
                },
                actual: (booking, isMilestoneAchieved, index) => {
                    let key = "gatein_fpod";
                    if (index > -1) {
                        return booking["containerlist"][index][key]
                    }

                    if (isMilestoneAchieved) {
                        if (booking["containerlist"] && booking["containerlist"].length > 0) {
                        let dayDiff = booking["containerlist"].map((container) => Utils.getDateDifferenceForProgress(container[key], new Date()));
                        let maxIndex = 0;
                        for (let i = 1; i < dayDiff.length; i++) {
                            if (dayDiff[maxIndex] > dayDiff[i]) {
                                maxIndex = i;
                            }
                        }
                        return booking["containerlist"][maxIndex][key];
                    }
                    }
                    return "";
                }
            },
            {
                isLabel: false,
                label: "Picked-Up",
                activeLabel: "Picked-Up",
                estimate: "container_pickup_fpod",
                count: () => {
                    return { parentKey: "containerlist", childKey: "container_pickup_fpod" }
                },
                actual: (booking, isMilestoneAchieved, index) => {
                    let key = "container_pickup_fpod";
                    let milestoneKey = "cargo_picked_up_by_consignee";
                    if (booking[milestoneKey] && booking[milestoneKey].length > 0) {
                        return booking[milestoneKey];
                    }
                    if (index > -1) {
                        return booking["containerlist"][index][key]
                    }

                    if (isMilestoneAchieved) {
                        if (booking["containerlist"] && booking["containerlist"].length > 0) {
                        let dayDiff = booking["containerlist"].map((container) => Utils.getDateDifferenceForProgress(container[key], new Date()));
                        let maxIndex = 0;
                        for (let i = 1; i < dayDiff.length; i++) {
                            if (dayDiff[maxIndex] > dayDiff[i]) {
                                maxIndex = i;
                            }
                        }
                        return booking["containerlist"][maxIndex][key];
                    }
                    }
                    return "";
                }
            }
        ]
    },
    {
        milestone: "destination",
        dynamicIcon: false,
        location:(booking)=>{
            return Utils.getCity(booking.destination,true)
        },
        isPortMilestone: false,
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["container_delivery"];
            let dockMileStone = ["actual_delivery_date"];
            if (booking.shipmentType.split("/")[1].includes("Dock") && booking.shipmentScope.split("-")[2] === "Door") {
                if (booking["vdes"] && booking["vdes"].length > 0) {
                    return booking["vdes"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
                }
            } else {
                if (booking["containerlist"] && booking["containerlist"].length > 0) {

                    if (index > -1) {
                        return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                    }
                    return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
                }
            }
            return false
        },
        milestoneAdvancedBasedOn: (booking, index) => {
            let milestoneKeys = ["container_delivery"];
            let dockMileStone = ["actual_delivery_date"];
            if (booking.shipmentType.split("/")[1].includes("Dock") && booking.shipmentScope.split("-")[2] === "Door") {
                if (booking["vdes"] && booking["vdes"].length > 0) {
                    return booking["vdes"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
                }
            } else {
                if (booking["containerlist"] && booking["containerlist"].length > 0) {
                    if (index > -1) {
                        return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                    }
                    return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
                }
            }
            return false
        },
        milestoneAchievedBasedOn: (booking, index) => {
            let milestoneKeys = ["container_delivery"];
            let dockMileStone = ["actual_delivery_date"];

            if (booking.shipmentType.split("/")[1].includes("Dock") && booking.shipmentScope.split("-")[2] === "Door") {
                if (booking["vdes"] && booking["vdes"].length > 0) {
                    return booking["vdes"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
                }
            } else {
                if (booking["containerlist"] && booking["containerlist"].length > 0) {
                    if (index > -1) {
                        return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                    }
                    return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
                }
            }
            return false
        },
        downStreamMilestone: () => false,
        isMilestoneAddsToThePath: ({ shipmentScope }) => shipmentScope.split("-")[2].toLowerCase() === "door",
        milestoneDetails: [
            {
                isLabel: false,
                label: "EDD",
                activeLabel: "Delivered On",
                count: (booking) => {
                    let key = "container_delivery";
                    let from = "containerlist";
                    if (booking.shipmentType.split("/")[1].includes("Dock") && booking.shipmentScope.split("-")[2] === "Door") {
                        from = "vdes";
                        key = "actual_delivery_date"
                    }
                    return { parentKey: from, childKey: key }
                },
                estimate: (booking, isMilestoneAchieved, index) => {
                    let key = "estimate_delivery_date_to_consignee";
                    let from = "containerlist";
                    if (booking.shipmentType.split("/")[1].includes("Dock") && booking.shipmentScope.split("-")[2] === "Door") {
                        from = "vdes";

                    }

                   
                        if (index > -1 && booking[from].length < index && from === "containerlist") {
                            return booking[from][index][key]
                        }
                        if(booking[from] && booking[from].length>0){
                        
                            let actualDates = [];
                            for(let i = 1;i<=5;i++){
                                let theKey = from
                                if(i>1){
                                    theKey = `${from}${i}`
                                }
                                if(booking[theKey] && booking[theKey].length > 0){
                                    booking[theKey].forEach((vehicle)=>{
                                        if(vehicle[key]?.length>0 ){
                                            actualDates.push(vehicle[key]);
                                        }
                                    })
                                }else{
                                    break;
                                }
                            }
                    }
                    return "";
                },
                actual: (booking, isMilestoneAchieved, index) => {
                    let key = "container_delivery";
                    let from = "containerlist";
                    if (booking.shipmentType.split("/")[1].includes("Dock") && booking.shipmentScope.split("-")[2] === "Door") {
                        from = "vdes"
                        key = "actual_delivery_date"
                    }

                    if (isMilestoneAchieved) {
                        if (index > -1 && booking[from].length < index &&  from === "containerlist") {
                            return booking[from][index][key]
                        }
                        
                        let actualDates = [];
                        for(let i = 1;i<=5;i++){
                            let theKey = from
                            if(i>1){
                                theKey = `${from}${i}`
                            }
                            if(booking[theKey] && booking[theKey].length > 0){
                                booking[theKey].forEach((vehicle)=>{
                                    if(vehicle[key]?.length>0 ){
                                        actualDates.push(vehicle[key]);
                                    }
                                })
                            }else{
                                break;
                            }
                        }
                       
                        if(actualDates.length>0){
                            return Utils.getClosestDate(actualDates);
                        }
                    
                    }
                    return "";
                }
            }
        ]
    },
]



export const LCL = [
    {
        downStreamMilestone: () => true,
        milestone: "origin",
        location:(booking)=>{
            return Utils.getCity(booking.origin,true)
        },
        dynamicIcon: false,
        isPortMilestone: false,
        milestoneAchievedBasedOn: (booking) => {
            let dockMileStone = ["cargo_pickup"];
            let portMilestone = ["container_handover"];
            if (Utils.isValueExists(portMilestone, booking)) {
                return true;
            }
            if (booking.shipmentScope.split("-")[0].toLowerCase() === "door") {
                if (booking["vorgin"] && booking["vorgin"].length > 0) {
                    return booking["vorgin"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
                }
            }
            return false
        },
        milestoneAdvancedBasedOn: (booking) => {
            let dockMileStone = ["cargo_pickup"];
            if (booking.shipmentScope.split("-")[0].toLowerCase() === "door") {
                if (booking["vorgin"] && booking["vorgin"].length > 0) {
                    return booking["vorgin"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
                }
            }
            return false
        },
        milestoneCompletedBasedOn: ["cargo_pickup_date", "container_handover", "gatein_pol", "vgm_cut_off"],
        isMilestoneAddsToThePath: ({ shipmentScope }) => shipmentScope.split("-")[0].toLowerCase() === "door",
        milestoneDetails: [
            {
                isLabel: false,
                label: "Picked-Up",
                estimate: "cargo_pickup_date",
                activeLabel: "Picked-Up",
                count: () => {
                    return { parentKey: "vorgin", childKey: "cargo_pickup" }
                },
                actual: (booking, isMilestoneAchieved) => {
                    let key = "cargo_pickup";
                    if (booking["vorgin"] && booking["vorgin"].length > 0) {
                        let dayDiff = booking["vorgin"].map((container) => Utils.getDateDifferenceForProgress(container[key], new Date()));
                        console.log("the vorigin ", dayDiff);
                        let maxIndex = 0;
                        for (let i = 1; i < dayDiff.length; i++) {
                            if (dayDiff[maxIndex] > dayDiff[i]) {
                                maxIndex = i;
                            }
                        }
                        return booking["vorgin"][maxIndex][key];
                    }
                    return "";
                }
            }
        ]
    },
    {
        milestone: "por",
        location: "por_location",
        dynamicIcon: true,
        downStreamMilestone: () => true,
        isPortMilestone: false,
        isMilestoneAddsToThePath: ({ por, pol }) => por !== pol,
        milestoneAchievedBasedOn: ["container_handover"],
        milestoneAdvancedBasedOn: ["por_to_pol_movement"],
        milestoneCompletedBasedOn: ["gatein_pol"],
        milestoneDetails: [
            // {
            //     isLabel: true,
            //     label: "Cargo Received",
            // },
            {
                isLabel: false,
                label: "Handover",
                activeLabel: "Handover",
                estimate: "container_handover",
                actual: "container_handover"
            },
            {
                isLabel: false,
                label: "Gate-Out",
                activeLabel: "Gate-Out",
                estimate: "por_to_pol_movement",
                actual: "por_to_pol_movement"
            }
        ]
    },
    {
        milestone: "pol",
        location: "pol_location",
        dynamicIcon: true,
        isPortMilestone: true,
        isMilestoneAddsToThePath: () => true,
        downStreamMilestone: () => true,
        milestoneAchievedBasedOn: ["gatein_pol"],
        vesselNameKey: "fl_vessel_name",
        vesselNumberKey: "fl_vessel_number",
        milestoneAdvancedBasedOn: ["sob_pol"],
        isPortMilestone: true,
        milestoneCompletedBasedOn: ["reworking_pol_arrival", "ts1_arrival", "pod_arrival", "gatein_fpod"],
        milestoneDetails: [
            {
                isLabel: false,
                label: "Gate In",
                activeLabel: "Gate In",
                estimate: "gatein_pol",
                actual: "gatein_pol"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "SOB",
                estimate: "etd_at_pol",
                actual: "sob_pol"
            }
        ]
    },
    {
        milestone: "re-working",
        location: "reworking_pol_port_location",
        dynamicIcon: true,
        isMilestoneAddsToThePath: ({ reworking_pol_port }) => reworking_pol_port && reworking_pol_port.length > 0,
        downStreamMilestone: () => true,
        milestoneAchievedBasedOn: ["reworking_pol_arrival"],
        vesselNameKey: "reworking_pol_vessel_name",
        vesselNumberKey: "reworking_pol_vessel_number",
        milestoneAdvancedBasedOn: ["reworking_pol_departure"],
        isPortMilestone: true,
        milestoneCompletedBasedOn: ["ts1_arrival", "pod_arrival", "gatein_fpod"],
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "reworking_pol_arrival_estimate",
                actual: "reworking_pol_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "reworking_pol_departure_estimate",
                actual: "reworking_pol_departure"
            }
        ]
    },
    {
        milestone: "ts1_port",
        location: "ts1_port_location",
        dynamicIcon: true,
        downStreamMilestone: () => true,
        isMilestoneAddsToThePath: ({ ts1_port }) => ts1_port && ts1_port.length > 0,
        milestoneAchievedBasedOn: ["ts1_arrival"],
        milestoneAdvancedBasedOn: ["ts1_departure"],
        isPortMilestone: true,
        vesselNameKey: "ts1_vessel_name",
        vesselNumberKey: "ts1_vessel_number",
        milestoneCompletedBasedOn: ["reworking_pod_arrival", "pod_arrival", "gatein_fpod"],
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "ts1_arrival_estimate",
                actual: "ts1_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "ts1_departure_estimate",
                actual: "ts1_departure"
            }
        ]
    },
    {
        milestone: "ts2_port",
        location: "ts2_port_location",
        dynamicIcon: true,
        isPortMilestone: true,
        isMilestoneAddsToThePath: ({ ts2_port }) => ts2_port && ts2_port.length > 0,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        milestoneAchievedBasedOn: ["ts2_arrival"],
        milestoneAdvancedBasedOn: ["ts2_departure"],
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_fpod"]
            let otherMileStoneKeys = ["ts3_arrival", "ts4_arrival", "reworking_pod_arrival", "pod_arrival"];
            if (Utils.isValueExists(otherMileStoneKeys, booking)) {
                return true;
            }
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1 ) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        vesselNameKey: "ts2_vessel_name",
        vesselNumberKey: "ts2_vessel_number",
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "ts2_arrival_estimate",
                actual: "ts2_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "ts2_departure_estimate",
                actual: "ts2_departure"
            }
        ]
    },
    {
        milestone: "ts3_port",
        location: "ts3_port_location",
        dynamicIcon: true,
        isMilestoneAddsToThePath: ({ ts3_port }) => ts3_port && ts3_port.length > 0,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        vesselNameKey: "ts3_vessel_name",
        vesselNumberKey: "ts3_vessel_number",
        milestoneAchievedBasedOn: ["ts3_arrival"],
        milestoneAdvancedBasedOn: ["ts3_departure"],
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_fpod"]
            let otherMileStoneKeys = ["ts4_arrival", "reworking_pod_arrival", "pod_arrival"];
            if (Utils.isValueExists(otherMileStoneKeys, booking)) {
                return true;
            }
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "ts3_arrival_estimate",
                actual: "ts3_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "ts3_departure_estimate",
                actual: "ts3_departure"
            }
        ]
    },
    {
        milestone: "ts4_port",
        location: "ts4_port_location",
        dynamicIcon: true,
        isMilestoneAddsToThePath: ({ ts4_port }) => ts4_port && ts4_port.length > 0,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        vesselNameKey: "ts4_vessel_name",
        vesselNumberKey: "ts4_vessel_number",
        milestoneAchievedBasedOn: ["ts4_arrival"],
        milestoneAdvancedBasedOn: ["ts4_departure"],
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_fpod"]
            let otherMileStoneKeys = ["reworking_pod_arrival", "pod_arrival"];
            if (Utils.isValueExists(otherMileStoneKeys, booking)) {
                return true;
            }
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "ts4_arrival_estimate",
                actual: "ts4_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "ts4_departure_estimate",
                actual: "ts4_departure"
            }
        ]
    },
    {
        milestone: "re-working-pod",
        location:({ reworking_pod_port_location ,booking : {contractNum,schedule}})=>{
            if(contractNum==="SPOT" && schedule.rep?.length >0) return schedule.rep;
            return reworking_pod_port_location;
        },
        dynamicIcon: true,
        isMilestoneAddsToThePath: ({ reworking_pod_port ,booking : {contractNum,schedule}}) => reworking_pod_port && reworking_pod_port.length > 0 || (contractNum==="SPOT" && schedule.rep?.length >0),
        downStreamMilestone: () => true,
        milestoneAchievedBasedOn: ["reworking_pod_arrival"],
        vesselNameKey: "reworking_pod_vessel_name",
        vesselNumberKey: "reworking_pod_vessel_number",
        milestoneAdvancedBasedOn: ["reworking_pod_departure"],
        isPortMilestone: true,
        milestoneCompletedBasedOn: ["pod_arrival", "gatein_fpod"],
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "reworking_pod_arrival_estimate",
                actual: "reworking_pod_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "reworking_pod_departure_estimate",
                actual: "reworking_pod_departure"
            }
        ]
    },
    {
        milestone: "pod",
        location: "pod_location",
        dynamicIcon: true,
        isMilestoneAddsToThePath: ({ pod, fpod }) => pod !== fpod,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        milestoneAchievedBasedOn: ["pod_arrival"],
        milestoneAdvancedBasedOn: ["gateout_to_fpod"],
        milestoneCompletedBasedOn: ["gatein_fpod"],
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "eta_at_pod",
                actual: "pod_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "etd_pod",
                actual: "gateout_to_fpod"
            }
        ]
    },
    {
        milestone: "fpod",
        location: "fpod_location",
        dynamicIcon: true,
        isMilestoneAddsToThePath: () => true,
        isPortMilestone: false,
        milestoneAchievedBasedOn: ["gatein_fpod"],
        milestoneAdvancedBasedOn: (booking) => {
            let dockMileStone = ["atdfrompod"];
            let keys = ["cargo_picked_up_by_consignee"];
            if (Utils.isValueExists(keys, booking)) {
                return true;
            }
            if (booking.shipmentScope.split("-")[2].toLowerCase() === "door") {
                if (booking["vdes"] && booking["vdes"].length > 0) {
                    return booking["vdes"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
                }
            }
            return false
        },
        milestoneCompletedBasedOn: (booking) => {
            let keys = ["cargo_picked_up_by_consignee"];
            if (Utils.isValueExists(keys, booking)) {
                return true;
            }
            let dockMileStone = ["actual_delivery_date"];

            if (booking.shipmentScope.split("-")[2].toLowerCase() === "door") {
                if (booking["vdes"] && booking["vdes"].length > 0) {
                    return booking["vdes"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
                }
            }
            return false
        },
        downStreamMilestone: ({ shipmentScope }) => shipmentScope.split("-")[2].toLowerCase() === "door",
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "eta_fpod",
                actual: "gatein_fpod"
            },
            {
                isLabel: false,
                label: "Picked-Up",
                activeLabel: "Picked-Up",
                estimate: "container_pickup_fpod",
                count: () => {
                    return { parentKey: "vdes", childKey: "atdfrompod" }
                },
                actual: (booking, isMilestoneAchieved) => {
                    let key = "atdfrompod";
                    let milestoneKey = "cargo_picked_up_by_consignee";

                    
                        if (booking[milestoneKey] && booking[milestoneKey].length > 0) {
                            return booking[milestoneKey];
                        }
                    //     if (booking["vdes"] && booking["vdes"].length > 0) {
                    //         let dayDiff = booking["vdes"].map((container) => Utils.getDateDifferenceForProgress(container[key], new Date()));
                    //         let maxIndex = 0;
                    //         for (let i = 1; i < dayDiff.length; i++) {
                    //             if (dayDiff[maxIndex] > dayDiff[i]) {
                    //                 maxIndex = i;
                    //             }
                    //         }
                    //         return booking["vdes"][maxIndex][key];
                    //     }
                    // }
                    // return "";
                    let actualDates = [];
                    for(let i = 1;i<=5;i++){
                        let theKey = `vdes`
                        if(i>1){
                            theKey = `vdes${i}`
                        }
                        if(booking[theKey] && booking[theKey].length > 0){
                            booking[theKey].forEach((vehicle)=>{
                                if(vehicle[key]?.length>0 ){
                                    actualDates.push(vehicle[key]);
                                }
                            })
                        }else{
                            break;
                        }
                    }
                
                    if(actualDates.length>0){
                        return Utils.getClosestDate(actualDates);
                    }
                
                    return "";
                }
            }
        ]
    },
    {
        milestone: "destination",
        dynamicIcon: false,
        location : (booking)=>{
            return Utils.getCity(booking.destination,true)
        },
        isPortMilestone: false,
        milestoneAchievedBasedOn: (booking) => {
            let dockMileStone = ["actual_delivery_date"];
            if (booking.shipmentScope.split("-")[2].toLowerCase() === "door") {
                if (booking["vdes"] && booking["vdes"].length > 0) {
                    return booking["vdes"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
                }
            }
            return false
        },
        milestoneAdvancedBasedOn: (booking) => {
            let dockMileStone = ["actual_delivery_date"];
            if (booking.shipmentScope.split("-")[2].toLowerCase() === "door") {
                if (booking["vdes"] && booking["vdes"].length > 0) {
                    return booking["vdes"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
                }
            }
            return false
        },
        downStreamMilestone: () => false,
        isMilestoneAddsToThePath: ({ shipmentScope }) => shipmentScope.split("-")[2].toLowerCase() === "door",
        milestoneDetails: [
            // {
            //     isLabel: true,
            //     label: "Cargo Delivery"
            // },
            {
                isLabel: false,
                label: "EDD",
                activeLabel: "Delivered On",
                count: () => {
                    return { parentKey: "vdes", childKey: "actual_delivery_date" }
                },
                estimate: (booking, isMilestoneAchieved) => {
                    let key = "estimate_delivery_date_to_consignee";
                    let actualDates = [];
                    for(let i = 1;i<=5;i++){
                        let theKey = `vdes`
                        if(i>1){
                            theKey = `vdes${i}`
                        }
                        if(booking[theKey] && booking[theKey].length > 0){
                            booking[theKey].forEach((vehicle)=>{
                                if(vehicle[key]?.length>0 ){
                                    actualDates.push(vehicle[key]);
                                }
                            })
                        }else{
                            break;
                        }
                    }
                    
                    if(actualDates.length>0){
                        return Utils.getClosestDate(actualDates);
                    }
                    return "";
                },
                actual: (booking, isMilestoneAchieved) => {
                    let key = "actual_delivery_date";
                    let actualDates = [];
                    for(let i = 1;i<=5;i++){
                        let theKey = `vdes`
                        if(i>1){
                            theKey = `vdes${i}`
                        }
                        if(booking[theKey] && booking[theKey].length > 0){
                            booking[theKey].forEach((vehicle)=>{
                                if(vehicle[key]?.length>0 ){
                                    actualDates.push(vehicle[key]);
                                }
                            })
                        }else{
                            break;
                        }
                    }
                    
                    if(actualDates.length>0){
                        return Utils.getClosestDate(actualDates);
                    }
                    return "";

                    // if (booking["vdes"] && booking["vdes"].length > 0) {
                    //     let dayDiff = booking["vdes"].map((container) => Utils.getDateDifferenceForProgress(container[key], new Date()));
                    //     let maxIndex = 0;
                    //     for (let i = 1; i < dayDiff.length; i++) {
                    //         if (dayDiff[maxIndex] > dayDiff[i]) {
                    //             maxIndex = i;
                    //         }
                    //     }
                    //     return booking["vdes"][maxIndex][key];
                    // }
                }
            }
        ]
    },
]

export const AIR = [
    {
        downStreamMilestone: () => true,
        milestone: "origin",
        location:(booking)=>{
            return Utils.getCity(booking.origin,true)
        },
        dynamicIcon: false,
        isPortMilestone: false,
        milestoneAchievedBasedOn: (booking) => {
            let dockMileStone = ["cargo_pickup"];
            if (booking.shipmentScope.split("-")[0].toLowerCase() === "door") {
                if (booking["vorgin"] && booking["vorgin"].length > 0) {
                    return booking["vorgin"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
                }
            }
            return false
        },
        milestoneAdvancedBasedOn: (booking) => {
            let dockMileStone = ["cargo_pickup"];
            if (booking.shipmentScope.split("-")[0].toLowerCase() === "door") {
                if (booking["vorgin"] && booking["vorgin"].length > 0) {
                    return booking["vorgin"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
                }
            }
            return false
        },
        milestoneCompletedBasedOn: ["loaded_on_board"],
        // isMilestoneAddsToThePath: ({ departure_airport, origin, shipmentScope }) => !origin.includes(departure_airport) && shipmentScope.split("-")[0].toLowerCase() === "door",
        isMilestoneAddsToThePath: ({ shipmentScope }) => shipmentScope.split("-")[0].toLowerCase() === "door",
        milestoneDetails: [

            {
                isLabel: false,
                label: "Picked-Up",
                activeLabel: "Picked-Up",
                count: () => {
                    return { parentKey: "vorgin", childKey: "cargo_pickup" }
                },
                estimate: "cargo_pickup",
                actual: "cargo_pickup",
                actual: (booking, isMilestoneAchieved, index) => {
                    let vehicleOriginKey = "cargo_pickup";
                    let findFrom = "vorgin";
                    let keys = vehicleOriginKey;

                    if (isMilestoneAchieved) {
                        if(booking[findFrom] && booking[findFrom].length>0){
                        let dayDiff = booking[findFrom].map((container) => Utils.getDateDifferenceForProgress(container[keys], new Date()));
                        let maxIndex = 0;

                        for (let i = 1; i < dayDiff.length; i++) {
                            if (dayDiff[maxIndex] > dayDiff[i]) {
                                maxIndex = i;
                            }
                        }
                        return booking[findFrom][maxIndex][keys];
                    }
                    }
                    return "";
                }
            }
        ]
    },
    {
        milestone: "aod",
        location: "departure_airport_location",
        dynamicIcon: true,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        flightKey: "flight_number_departure_airport",
        isMilestoneAddsToThePath: () => true,
        milestoneAchievedBasedOn: (booking)=>{
           let doorKeys = ["arrival_dispatch_airport"];
            if( booking.shipmentScope.split("-")[0].toLowerCase() === "door"){
                if (booking["vorgin"] && booking["vorgin"].length > 0) {
                    return booking["vorgin"].map((container) => doorKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
                }
            }else{
                return Utils.isValueExists(["loaded_on_board"],booking)
            }
        },
        milestoneAdvancedBasedOn: ["loaded_on_board"],
        milestoneCompletedBasedOn: ["ts1_arrival", "ata_at_arrival_airport"],
        milestoneDetails: [
            {
                isLabel: false,
                label: "Gate-In",
                activeLabel: "Gate-In",
                count: () => {
                    return { parentKey: "vorgin", childKey: "arrival_dispatch_airport" }
                },
                include: ({ shipmentScope }) => shipmentScope.split("-")[0].toLowerCase() === "door",
                estimate: "arrival_dispatch_airport",
                actual: (booking, isMilestoneAchieved, index) => {
                    let vehicleOriginKey = "arrival_dispatch_airport";
                    let findFrom = "vorgin";
                    let keys = vehicleOriginKey;

                    if (isMilestoneAchieved) {
                        if(booking[findFrom] && booking[findFrom].length>0){
                        let dayDiff = booking[findFrom].map((container) => Utils.getDateDifferenceForProgress(container[keys], new Date()));
                        let maxIndex = 0;

                        for (let i = 1; i < dayDiff.length; i++) {
                            if (dayDiff[maxIndex] > dayDiff[i]) {
                                maxIndex = i;
                            }
                        }
                        return booking[findFrom][maxIndex][keys];
                    }
                    }
                    return "";
                }
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                // count:"loaded_on_board",

                estimate: "etd_airport_of_departure",
                actual: "loaded_on_board"
            }
        ]
    },
    {
        milestone: "ts1_airport",
        location: "ts1_airport_location",
        dynamicIcon: true,
        flightKey : "flight_number_ts1",
        downStreamMilestone: () => true,
        isMilestoneAddsToThePath: ({ ts1_airport }) => ts1_airport && ts1_airport.length > 0,
        milestoneAchievedBasedOn: ["ts1_arrival"],
        milestoneAdvancedBasedOn: ["ts1_departure"],
        isPortMilestone: true,
        vesselNameKey: "ts1_vessel_name",
        vesselNumberKey: "flight_number_ts1",
        milestoneCompletedBasedOn: ["ts2_arrival", "ata_at_arrival_airport"],
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "ts1_arrival_estimate",
                actual: "ts1_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "ts1_departure_estimate",
                actual: "ts1_departure"
            }
        ]
    },
    {
        milestone: "ts2_airport",
        location: "ts2_airport_location",
        dynamicIcon: true,
        flightKey : "flight_number_ts2",
        downStreamMilestone: () => true,
        isMilestoneAddsToThePath: ({ ts2_airport }) => ts2_airport && ts2_airport.length > 0,
        milestoneAchievedBasedOn: ["ts2_arrival"],
        milestoneAdvancedBasedOn: ["ts2_departure"],
        isPortMilestone: true,
        vesselNameKey: "ts1_vessel_name",
        vesselNumberKey: "flight_number_ts2",
        milestoneCompletedBasedOn: ["ata_at_arrival_airport"],
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "ts2_arrival_estimate",
                actual: "ts2_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "ts2_departure_estimate",
                actual: "ts2_departure"
            }
        ]
    },
    {
        milestone: "aoa",
        location: "arrival_airport_location",
        dynamicIcon: true,
        // downStreamMilestone: ({ arrival_airport, destination,shipmentScope }) => !destination.includes(arrival_airport) && shipmentScope.split("-")[2].toLowerCase() === "door",
        downStreamMilestone: ({ shipmentScope }) => shipmentScope.split("-")[2].toLowerCase() === "door",
        isPortMilestone: false,
        isMilestoneAddsToThePath: () => true,
        milestoneAchievedBasedOn: ["ata_at_arrival_airport"],
        milestoneAdvancedBasedOn: (booking) => {
            let key = ["dispatched_from_arrival_airport"]
            if (booking.shipmentScope.split("-")[2].toLowerCase() === "door") {
                if (booking["vdes"] && booking["vdes"].length > 0) {
                    return booking["vdes"].map((container) => key.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
                }
            }
            return Utils.isValueExists(["ata_at_arrival_airport"], booking);
        },
        milestoneCompletedBasedOn: (booking) => {
            let dockMileStone = ["cargo_delivery"];
            if (booking.shipmentScope.split("-")[2].toLowerCase() === "door") {
                if (booking["vdes"] && booking["vdes"].length > 0) {
                    return booking["vdes"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
                }
            } else {
                return Utils.isValueExists(["ata_at_arrival_airport"], booking)
            }
            return false
        },
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "eta_at_arrival_airport",
                actual: "ata_at_arrival_airport"
            },
            {
                isLabel: false,
                label: "Gate-Out",
                activeLabel: "Gate-Out",
                count : ()=>{
                    return {parentKey:"vdes",childKey:"dispatched_from_arrival_airport"}
                },
                include: ({ shipmentScope }) => shipmentScope.split("-")[2].toLowerCase() === "door",
                estimate: "dispatched_from_arrival_airport",
                actual: (booking, isMilestoneAchieved, index) => {
                    let vehicleOriginKey = "dispatched_from_arrival_airport";
                    let findFrom = "vdes";
                    let keys = vehicleOriginKey;

                    

                    // if (isMilestoneAchieved) {
                        if(booking[findFrom] && booking[findFrom].length>0){
                        // let dayDiff = booking[findFrom].map((container) => Utils.getDateDifferenceForProgress(container[keys], new Date()));
                        // let maxIndex = 0;

                        // for (let i = 1; i < dayDiff.length; i++) {
                        //     if (dayDiff[maxIndex] > dayDiff[i]) {
                        //         maxIndex = i;
                        //     }
                        // }
                        // if (booking[findFrom] && booking[findFrom].length > 0) {
                        // return booking[findFrom][maxIndex][keys];
                        // }
                        let actualDates = [];
                        for(let i = 1;i<=5;i++){
                            let theKey = `vdes`
                            if(i>1){
                                theKey = `vdes${i}`
                            }
                            if(booking[theKey] && booking[theKey].length > 0){
                                booking[theKey].forEach((vehicle)=>{
                                    if(vehicle[keys]?.length>0 ){
                                        actualDates.push(vehicle[keys]);
                                    }
                                })
                            }else{
                                break;
                            }
                        }
                        
                        if(actualDates.length>0){
                            return Utils.getClosestDate(actualDates);
                        }
                        return "";
    
                    }
                // }
                    return "";
                }
            }
        ]
    },
    {
        milestone: "destination",
        dynamicIcon: false,
        location:(booking)=>{
            return Utils.getCity(booking.destination,true)
        },
        isPortMilestone: false,
        milestoneAdvancedBasedOn: (booking) => {
            let dockMileStone = ["actual_delivery_date"];

            if (booking["vdes"] && booking["vdes"].length > 0) {
                return booking["vdes"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneCompletedBasedOn: (booking) => {
            let dockMileStone = ["actual_delivery_date"];

            if (booking["vdes"] && booking["vdes"].length > 0) {
                return booking["vdes"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneAchievedBasedOn: (booking) => {
            let dockMileStone = ["actual_delivery_date"];

            if (booking["vdes"] && booking["vdes"].length > 0) {
                return booking["vdes"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },

        downStreamMilestone: () => false,
        // isMilestoneAddsToThePath: ({ arrival_airport, destination }) => !destination.includes(arrival_airport),
        isMilestoneAddsToThePath: ({ shipmentScope }) => shipmentScope.split("-")[2].toLowerCase() === "door",
        milestoneDetails: [
            // {
            //     isLabel: true,
            //     label: "Cargo Delivery"
            // },
            {
                isLabel: false,
                label: "EDD",
                activeLabel: "Delivered On",
                count :()=>{
                    return {parentKey:"vdes",childKey:"actual_delivery_date"}
                },
                estimate: (booking,index)=>{
                  let key = "estimate_delivery_date_to_consignee";
                  let findFrom = "vdes";
                  let actualDates = [];
                  for(let i = 1;i<=5;i++){
                      let theKey = `vdes`
                      if(i>1){
                          theKey = `vdes${i}`
                      }
                      if(booking[theKey] && booking[theKey].length > 0){
                          booking[theKey].forEach((vehicle)=>{
                              if(vehicle[key]?.length>0 ){
                                  actualDates.push(vehicle[key]);
                              }
                          })
                      }else{
                          break;
                      }
                  }
                  
                  if(actualDates.length>0){
                      return Utils.getClosestDate(actualDates);
                  }
                  return "";
                //   if(booking[findFrom] && booking[findFrom].length>0){
                //     let dayDiff = booking[findFrom].map((container) => Utils.getDateDifferenceForProgress(container[key], new Date()));
                //     let maxIndex = 0;

                //     for (let i = 1; i < dayDiff.length; i++) {
                //         if (dayDiff[maxIndex] > dayDiff[i]) {
                //             maxIndex = i;
                //         }
                //     }
                //     return booking[findFrom][maxIndex][key];
                // }
                // return "";
                },
                actual: (booking, isMilestoneAchieved, index) => {
                    let vehicleOriginKey = "actual_delivery_date";
                    let findFrom = "vdes";
                    let keys = vehicleOriginKey;

                    let actualDates = [];
                    for(let i = 1;i<=5;i++){
                        let theKey = `vdes`
                        if(i>1){
                            theKey = `vdes${i}`
                        }
                        if(booking[theKey] && booking[theKey].length > 0){
                            booking[theKey].forEach((vehicle)=>{
                                if(vehicle[keys]?.length>0 ){
                                    actualDates.push(vehicle[keys]);
                                }
                            })
                        }else{
                            break;
                        }
                    }
                    
                    if(actualDates.length>0){
                        return Utils.getClosestDate(actualDates);
                    }
                    return "";

                    // if (isMilestoneAchieved) {
                    //     if(booking[findFrom] && booking[findFrom].length>0){
                    //     let dayDiff = booking[findFrom].map((container) => Utils.getDateDifferenceForProgress(container[keys], new Date()));
                    //     let maxIndex = 0;

                    //     for (let i = 1; i < dayDiff.length; i++) {
                    //         if (dayDiff[maxIndex] > dayDiff[i]) {
                    //             maxIndex = i;
                    //         }
                    //     }
                    //     return booking[findFrom][maxIndex][keys];
                    // }
                    // }
                    // return "";
                }
            }
        ]
    },
]


export const SPOTOCEAN = [
    {
        downStreamMilestone: () => true,
        milestone: "origin",
        location : ({dummyContract})=>{
           
            return dummyContract["origin"]
        },
        dynamicIcon: false,
        isPortMilestone: false,
        milestoneAchievedBasedOn: ()=>false,
        milestoneAdvancedBasedOn: ()=>false,
        milestoneCompletedBasedOn:()=>false,
        isMilestoneAddsToThePath: ({quote:{ quoteData : { shipmentScope }}}) => shipmentScope.split("-")[0].toLowerCase() === "door",
        milestoneDetails: [
            // {
            //     isLabel: true,
            //     label: "Cargo Picked-Up",

            // },
            // {
            //     isLabel: false,
            //     label: "Picked-Up",
            //     activeLabel: "Picked-Up",
            //     estimate: "loaded_and_dispatch",
            //     count: (booking) => {
            //         let key = "loaded_and_dispatch";
            //         let vehicleOriginKey = "cargo_pickup";
            //         let findFrom = "containerlist";
            //         let keys = key;
            //         if (booking.shipmentType.split("/")[0].includes("Dock") && booking.shipmentScope.split("-")[0] === "Door") {
            //             findFrom = "vorgin";
            //             keys = vehicleOriginKey;
            //         }
            //         return { parentKey: findFrom, childKey: keys }
            //     },
            //     actual: (booking, isMilestoneAchieved, index) => {
            //         let key = "loaded_and_dispatch";
            //         let vehicleOriginKey = "cargo_pickup";
            //         let findFrom = "containerlist";
            //         let keys = key;
            //         if (booking.shipmentType.split("/")[0].includes("Dock") && booking.shipmentScope.split("-")[0] === "Door") {
            //             findFrom = "vorgin";
            //             keys = vehicleOriginKey;
            //         }
            //         if (isMilestoneAchieved) {
            //             if (index > -1 && index < booking[findFrom].length && findFrom === "containerlist") {
            //                 return booking[findFrom][index][keys]
            //             }
            //             if(booking[findFrom] && booking[findFrom].length>0){
            //             let dayDiff = booking[findFrom].map((container) => Utils.getDateDifferenceForProgress(container[keys], new Date()));
            //             let maxIndex = 0;

            //             for (let i = 1; i < dayDiff.length; i++) {
            //                 if (dayDiff[maxIndex] > dayDiff[i]) {
            //                     maxIndex = i;
            //                 }
            //             }
            //             return booking[findFrom][maxIndex][keys];
            //         }
            //         }
            //         return "";
            //     }
            // }
        ]
    },
    {
        milestone: "por",
        location: ({quote,dummyContract:{schedules}},index)=>{
            if(index>-1){
               return schedules[index]["por"]
            }
            return quote?.quoteData?.portOfReceipt;
        },
        dynamicIcon: true,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        isMilestoneAddsToThePath: ({ por, pol }) => por !== pol,
        milestoneAchievedBasedOn: ()=>false,
        milestoneAdvancedBasedOn: ()=>false,
        milestoneCompletedBasedOn:()=>false,
        milestoneDetails: [
            {
                isLabel: false,
                label: "Cut-off",
                estimate : ({quote,dummyContract},__,index)=>{
                    if(index>-1){
                        const {schedules} = dummyContract
                        return schedules[index]["cutoffpor"]
                    }
                    return ""
                }
            }
        ]
    },
    {
        milestone: "pol",
        location: ({quote,dummyContract:{schedules}},index)=>{
            if(index>-1){
               return schedules[index]["pol"]
            }
            return quote?.quoteData?.portOfLoading;
        },
        dynamicIcon: true,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        vesselNameKey: ({quote,dummyContract},index)=>{
           if(index>-1){
            const {schedules,shipmentType} = dummyContract
            if(shipmentType?.toLowerCase()==="LCL"){
                return schedules[index]["VESSEL NAME"]
            }else{
                return schedules[index]["v1name"]
            }
           }
           return "";
        },
        vesselNumberKey: ({quote,dummyContract},index)=>{
            if(index>-1){
             const {schedules,shipmentType} = dummyContract
             if(shipmentType?.toLowerCase()==="LCL"){
                 return schedules[index]["VOYAGE"]
             }else{
                return schedules[index]["s1voy"]
             }
            }
            return "";
         },
         carrier : ({quote,dummyContract},index)=>{
            if(index>-1){
             const {schedules} = dummyContract
                return schedules[index]["CARRIER"]
           }
           return "";
         },
        isMilestoneAddsToThePath: () => true,
        milestoneAchievedBasedOn: ()=>false,
        milestoneAdvancedBasedOn: ()=>false,
        milestoneCompletedBasedOn:()=>false,
        milestoneDetails: [
            {
                isLabel: false,
                label: "Cut-off",
                estimate : ({quote,dummyContract},__,index)=>{
                    if(index>-1){
                     const {schedules} = dummyContract
                     console.log("the schedules ",schedules[index],"the index is ",index, "   ",dummyContract)
                        return schedules[index]["cutoffpor"]
                    }
                    return ""
                }
            },
            {
                isLabel: false,
                label: "ETD",
                estimate : ({quote,dummyContract},__,index)=>{
                    if(index>-1){
                     const {schedules} = dummyContract
                        return schedules[index]["etdpol"]
                    }
                    return ""
                }
            }
        ]
    },
    {
        milestone: "ts1",
        location: ({quote,dummyContract:{schedules}},index)=>{
            if(index>-1){
               return schedules[index]["ts1name"]
            }
            return "";
        },
        dynamicIcon: true,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        isMilestoneAddsToThePath: ({dummyContract:{schedules}},index) => {
            if(index>-1){
                return schedules[index]["ts1name"] && schedules[index]["ts1name"].length > 0;
            }
            return false;
        },
        vesselNameKey: ({quote,dummyContract},index)=>{
            if(index>-1){
             const {schedules,shipmentType} = dummyContract
                 return schedules[index]["v2name"]
            }
            return "";
         },
         vesselNumberKey: ({quote,dummyContract},index)=>{
             if(index>-1){
              const {schedules,shipmentType} = dummyContract
                 return schedules[index]["s2voy"]
             }
             return "";
          },
        milestoneAchievedBasedOn: ()=>false,
        milestoneAdvancedBasedOn: ()=>false,
        milestoneCompletedBasedOn:()=>false,
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                estimate : ({quote,dummyContract},__,index)=>{
                    if(index>-1){
                     const {schedules} = dummyContract
                        return schedules[index]["ts1eta"]
                    }
                    return ""
                }
            },
            {
                isLabel: false,
                label: "ETD",
                estimate : ({quote,dummyContract},__,index)=>{
                    if(index>-1){
                     const {schedules} = dummyContract
                        return schedules[index]["ts1etd"]
                    }
                    return ""
                }
            }
        ]
    },
    {
        milestone: "ts2",
        location: ({quote,dummyContract:{schedules}},index)=>{
            if(index>-1){
               return schedules[index]["ts2name"]
            }
            return "";
        },
        dynamicIcon: true,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        isMilestoneAddsToThePath: ({dummyContract:{schedules}},index) => {
            if(index>-1){
                return schedules[index]["ts2name"] && schedules[index]["ts2name"].length > 0;
            }
            return false;
        },
        vesselNameKey: ({quote,dummyContract},index)=>{
            if(index>-1){
             const {schedules,shipmentType} = dummyContract
                 return schedules[index]["v3name"]
            }
            return "";
         },
         vesselNumberKey: ({quote,dummyContract},index)=>{
             if(index>-1){
              const {schedules,shipmentType} = dummyContract
                 return schedules[index]["s3voy"]
             }
             return "";
          },
        milestoneAchievedBasedOn: ()=>false,
        milestoneAdvancedBasedOn: ()=>false,
        milestoneCompletedBasedOn:()=>false,
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                estimate : ({quote,dummyContract},__,index)=>{
                    if(index>-1){
                     const {schedules} = dummyContract
                        return schedules[index]["ts2eta"]
                    }
                    return ""
                }
            },
            {
                isLabel: false,
                label: "ETD",
                estimate : ({quote,dummyContract},__,index)=>{
                    if(index>-1){
                     const {schedules} = dummyContract
                        return schedules[index]["ts2etd"]
                    }
                    return ""
                }
            }
        ]
    },
    {
        milestone: "pod",
        location: ({quote,dummyContract:{schedules}},index)=>{
            if(index>-1){
               return schedules[index]["pod"]
            }
            return quote?.quoteData?.portOfDischarge;
        },
        dynamicIcon: true,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        isMilestoneAddsToThePath: ({ pod, fpod }) => pod !== fpod,
        milestoneAchievedBasedOn: ()=>false,
        milestoneAdvancedBasedOn: ()=>false,
        milestoneCompletedBasedOn:()=>false,
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                estimate : ({quote,dummyContract},__,index)=>{
                    if(index>-1){
                     const {schedules} = dummyContract
                        return schedules[index]["etapod"]
                    }
                    return ""
                }
            }
        ]
    },
    {
        milestone: "fpod",
        location: ({quote,dummyContract:{schedules}},index)=>{
            if(index>-1){
               return schedules[index]["fpod"]
            }
            return quote?.quoteData?.finalPlaceOfDelivery;
        },
        dynamicIcon: true,
        downStreamMilestone: ({quote:{ quoteData : { shipmentScope }}}) => shipmentScope.split("-")[2].toLowerCase() === "door",
        isPortMilestone: true,
        isMilestoneAddsToThePath: () => true,
        milestoneAchievedBasedOn: ()=>false,
        milestoneAdvancedBasedOn: ()=>false,
        milestoneCompletedBasedOn:()=>false,
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                estimate :({quote,dummyContract},__,index)=>{
                    if(index>-1){
                     const {schedules} = dummyContract
                        return schedules[index]["etapod"]
                    }
                    return ""
                }
            }
        ]
    },
    {
        downStreamMilestone: () => false,
        milestone: "destination",
        location : ({dummyContract})=>{
           
            return dummyContract["destination"]
        },
        dynamicIcon: false,
        isPortMilestone: false,
        milestoneAchievedBasedOn: ()=>false,
        milestoneAdvancedBasedOn: ()=>false,
        milestoneCompletedBasedOn:()=>false,
        isMilestoneAddsToThePath: ({quote:{ quoteData : { shipmentScope }}}) => shipmentScope.split("-")[2].toLowerCase() === "door",
        milestoneDetails: [
        //     // {
        //     //     isLabel: true,
        //     //     label: "Cargo Picked-Up",

        //     // },
        //     {
        //         isLabel: false,
        //         label: "Picked-Up",
        //         activeLabel: "Picked-Up",
        //         estimate: "loaded_and_dispatch",
        //         count: (booking) => {
        //             let key = "loaded_and_dispatch";
        //             let vehicleOriginKey = "cargo_pickup";
        //             let findFrom = "containerlist";
        //             let keys = key;
        //             if (booking.shipmentType.split("/")[0].includes("Dock") && booking.shipmentScope.split("-")[0] === "Door") {
        //                 findFrom = "vorgin";
        //                 keys = vehicleOriginKey;
        //             }
        //             return { parentKey: findFrom, childKey: keys }
        //         },
        //         actual: (booking, isMilestoneAchieved, index) => {
        //             let key = "loaded_and_dispatch";
        //             let vehicleOriginKey = "cargo_pickup";
        //             let findFrom = "containerlist";
        //             let keys = key;
        //             if (booking.shipmentType.split("/")[0].includes("Dock") && booking.shipmentScope.split("-")[0] === "Door") {
        //                 findFrom = "vorgin";
        //                 keys = vehicleOriginKey;
        //             }
        //             if (isMilestoneAchieved) {
        //                 if (index > -1 && index < booking[findFrom].length && findFrom === "containerlist") {
        //                     return booking[findFrom][index][keys]
        //                 }
        //                 if(booking[findFrom] && booking[findFrom].length>0){
        //                 let dayDiff = booking[findFrom].map((container) => Utils.getDateDifferenceForProgress(container[keys], new Date()));
        //                 let maxIndex = 0;

        //                 for (let i = 1; i < dayDiff.length; i++) {
        //                     if (dayDiff[maxIndex] > dayDiff[i]) {
        //                         maxIndex = i;
        //                     }
        //                 }
        //                 return booking[findFrom][maxIndex][keys];
        //             }
        //             }
        //             return "";
        //         }
        //     }
        ]
    },
]


export const SPOTAIR = [
    {
        downStreamMilestone: () => true,
        milestone: "origin",
        location : ({dummyContract})=>{
           
            return dummyContract["origin"]
        },
        dynamicIcon: false,
        isPortMilestone: false,
        milestoneAchievedBasedOn: ()=>false,
        milestoneAdvancedBasedOn: ()=>false,
        milestoneCompletedBasedOn:()=>false,
        isMilestoneAddsToThePath: ({quote:{ quoteData : { shipmentScope }}}) => shipmentScope.split("-")[0].toLowerCase() === "door",
        milestoneDetails: [
            // {
            //     isLabel: true,
            //     label: "Cargo Picked-Up",

            // },
            // {
            //     isLabel: false,
            //     label: "Picked-Up",
            //     activeLabel: "Picked-Up",
            //     estimate: "loaded_and_dispatch",
            //     count: (booking) => {
            //         let key = "loaded_and_dispatch";
            //         let vehicleOriginKey = "cargo_pickup";
            //         let findFrom = "containerlist";
            //         let keys = key;
            //         if (booking.shipmentType.split("/")[0].includes("Dock") && booking.shipmentScope.split("-")[0] === "Door") {
            //             findFrom = "vorgin";
            //             keys = vehicleOriginKey;
            //         }
            //         return { parentKey: findFrom, childKey: keys }
            //     },
            //     actual: (booking, isMilestoneAchieved, index) => {
            //         let key = "loaded_and_dispatch";
            //         let vehicleOriginKey = "cargo_pickup";
            //         let findFrom = "containerlist";
            //         let keys = key;
            //         if (booking.shipmentType.split("/")[0].includes("Dock") && booking.shipmentScope.split("-")[0] === "Door") {
            //             findFrom = "vorgin";
            //             keys = vehicleOriginKey;
            //         }
            //         if (isMilestoneAchieved) {
            //             if (index > -1 && index < booking[findFrom].length && findFrom === "containerlist") {
            //                 return booking[findFrom][index][keys]
            //             }
            //             if(booking[findFrom] && booking[findFrom].length>0){
            //             let dayDiff = booking[findFrom].map((container) => Utils.getDateDifferenceForProgress(container[keys], new Date()));
            //             let maxIndex = 0;

            //             for (let i = 1; i < dayDiff.length; i++) {
            //                 if (dayDiff[maxIndex] > dayDiff[i]) {
            //                     maxIndex = i;
            //                 }
            //             }
            //             return booking[findFrom][maxIndex][keys];
            //         }
            //         }
            //         return "";
            //     }
            // }
        ]
    },
    {
        milestone: "aod",
        location: ({quote},index)=>{
            return quote?.quoteData?.portOfLoading;
        },
        dynamicIcon: true,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        isMilestoneAddsToThePath: ({ por, pol }) => por !== pol,
        milestoneAchievedBasedOn: ()=>false,
        milestoneAdvancedBasedOn: ()=>false,
        milestoneCompletedBasedOn:()=>false,
        milestoneDetails: [
            {
                isLabel: false,
                label: "Cut-off",
                estimate : ({quote,dummyContract},__,index)=>{
                    if(index>-1){
                        const {schedules} = dummyContract
                        return schedules[index]["cutoffpor"]
                    }
                    return ""
                }
            }
        ]
    },
    {
        milestone: "aoa",
        location: ({quote},index)=>{
            return quote?.quoteData?.portOfDischarge;
        },
        dynamicIcon: true,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        vesselNameKey: ({quote,dummyContract},index)=>{
           if(index>-1){
            const {schedules} = dummyContract
                return schedules[index]["VESSEL NAME"]
           }
           return "";
        },
        vesselNumberKey: ({quote,dummyContract},index)=>{
            if(index>-1){
             const {schedules} = dummyContract
                 return schedules[index]["VOYAGE"]
            }
            return "";
         },
         carrier : ({quote,dummyContract},index)=>{
            if(index>-1){
             const {schedules} = dummyContract
                return schedules[index]["CARRIER"]
           }
           return "";
         },
        isMilestoneAddsToThePath: () => true,
        milestoneAchievedBasedOn: ()=>false,
        milestoneAdvancedBasedOn: ()=>false,
        milestoneCompletedBasedOn:()=>false,
        milestoneDetails: [
            {
                isLabel: false,
                label: "Cut-off",
                estimate : ({quote,dummyContract},__,index)=>{
                    if(index>-1){
                     const {schedules} = dummyContract
                     console.log("the schedules ",schedules[index],"the index is ",index, "   ",dummyContract)
                        return schedules[index]["cutoffpor"]
                    }
                    return ""
                }
            },
            {
                isLabel: false,
                label: "ETD",
                estimate : ({quote,dummyContract},__,index)=>{
                    if(index>-1){
                     const {schedules} = dummyContract
                        return schedules[index]["etdpol"]
                    }
                    return ""
                }
            }
        ]
    },
    {
        downStreamMilestone: () => false,
        milestone: "destination",
        location : ({dummyContract})=>{
            return dummyContract["destination"]
        },
        dynamicIcon: false,
        isPortMilestone: false,
        milestoneAchievedBasedOn: ()=>false,
        milestoneAdvancedBasedOn: ()=>false,
        milestoneCompletedBasedOn:()=>false,
        isMilestoneAddsToThePath: ({quote:{ quoteData : { shipmentScope }}}) => shipmentScope.split("-")[2].toLowerCase() === "door",
        milestoneDetails: [
        //     // {
        //     //     isLabel: true,
        //     //     label: "Cargo Picked-Up",

        //     // },
        //     {
        //         isLabel: false,
        //         label: "Picked-Up",
        //         activeLabel: "Picked-Up",
        //         estimate: "loaded_and_dispatch",
        //         count: (booking) => {
        //             let key = "loaded_and_dispatch";
        //             let vehicleOriginKey = "cargo_pickup";
        //             let findFrom = "containerlist";
        //             let keys = key;
        //             if (booking.shipmentType.split("/")[0].includes("Dock") && booking.shipmentScope.split("-")[0] === "Door") {
        //                 findFrom = "vorgin";
        //                 keys = vehicleOriginKey;
        //             }
        //             return { parentKey: findFrom, childKey: keys }
        //         },
        //         actual: (booking, isMilestoneAchieved, index) => {
        //             let key = "loaded_and_dispatch";
        //             let vehicleOriginKey = "cargo_pickup";
        //             let findFrom = "containerlist";
        //             let keys = key;
        //             if (booking.shipmentType.split("/")[0].includes("Dock") && booking.shipmentScope.split("-")[0] === "Door") {
        //                 findFrom = "vorgin";
        //                 keys = vehicleOriginKey;
        //             }
        //             if (isMilestoneAchieved) {
        //                 if (index > -1 && index < booking[findFrom].length && findFrom === "containerlist") {
        //                     return booking[findFrom][index][keys]
        //                 }
        //                 if(booking[findFrom] && booking[findFrom].length>0){
        //                 let dayDiff = booking[findFrom].map((container) => Utils.getDateDifferenceForProgress(container[keys], new Date()));
        //                 let maxIndex = 0;

        //                 for (let i = 1; i < dayDiff.length; i++) {
        //                     if (dayDiff[maxIndex] > dayDiff[i]) {
        //                         maxIndex = i;
        //                     }
        //                 }
        //                 return booking[findFrom][maxIndex][keys];
        //             }
        //             }
        //             return "";
        //         }
        //     }
        ]
    },
]