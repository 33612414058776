import { useFieldArray } from "react-hook-form";
import { viewBookingStore } from "../state/CommonState";
import LabeledTextField from "./LabeledTextField";
import BookingsApi from "../state/bookings-api";
import { useCallback } from "react";
import Utils from "../util/Utils";
import destinationIcon from "../assets/images/destinationimg.svg";
import { Add } from "@mui/icons-material";
import deleteicon from "../assets/images/deletei.svg";

const generateId = (id,i,list)=> {
    if(id==undefined){
        let a = i + Date.now() + '-' + Math.floor(Math.random() * 1000)
        // list[i].id = a
        return a
    }else{
        return id
    }
}

export function FbaDetails({form,autoUpdate=true}){
    const { watch,getValues } = form;
    const fbaDetailsList = getValues("fbaList")
    if(!fbaDetailsList || fbaDetailsList.length===0) return <></>

    return fbaDetailsList.map((fba,i)=>{
        let key = generateId(undefined,i,fbaDetailsList)
          return <FbaDetail autoUpdate={autoUpdate} key={key} ind={i} form={form} />
    })
}

function FbaDetail({form,ind,autoUpdate}){
    const { control,getValues,setValue,trigger,watch } = form;
    const fbaListString = `fbaList.${ind}`;
    
    const { fields: fbaList, append: appendFba, remove: removeFba } = useFieldArray({
        control: control,
        name: fbaListString
      })
    //   const fbaLists = watch(fbaListString)
      const fbaDetailsList = getValues("fbaList")
    const booking = viewBookingStore.current;
    const isMandatory = process.env.REACT_APP_FBA_Mandatory?.toLowerCase() === "yes" ?  true : false;

    const updateFbaDetails = (fbaList)=>{
        BookingsApi.setfbadetailsBooking(booking._id,fbaList,booking.booking.merchantID,true,()=>{
            booking.booking.fbaDetails = fbaList;
        })
      }
      
    const updateFbaDetailOnAdditionAndRemoval = (fbaListToBeUpdated=getValues("fbaList"))=>{
       updateFbaDetails(fbaListToBeUpdated);
    } 
    
    const validateInput = async()=>{
        let errors = await trigger();
        if(!errors){
            throw new Error("Form validation failed")
        }
    }

    const deBouncingCall = useCallback(Utils.debounce(async(fbaList)=>{
        try{
            await validateInput()
            updateFbaDetails(fbaList)
        }catch(e){
            console.error(e?.message)
        }
      },1000),[])

      

    const removeHandler = (index) => {
        removeFba(index);
        // console.log(" after removel is ",getValues(fbaListString))
        // setValue(fbaListString, getValues(fbaListString)); // Syncs form data with the field array
      };

    return  <div key={ind} className="flex flex-col w-full gap-2">
       {fbaDetailsList?.length>1 && <div className={`flex flex-row w-full h-[35px] items-center border-[0.5px] border-solid border-[#DBDEFF] border-b-0 px-[12px] gap-2 rounded-t-md`} style={{background:"#DBDEFF"}}>
          <img src={destinationIcon}/>
          <p className="font-bold text-blue-dark">{`Destination ${ind+1} : `}</p>
          <p className="text-sm">{ind==0? booking.destination : booking[`destination${ind+1}`]}</p>
          </div>}
        {fbaList.map((fba,i)=> <div key={generateId(fba.id,i,fba)} className="flex flex-row w-full gap-5">
          <LabeledTextField type="text" inputProps={{maxLength:12}} key={fba.id+"fba"} onChange={async(v)=>{
            let currentValue = `${fbaListString}.${i}.fbaID`
            setValue(currentValue,v);
            if(autoUpdate){ 
              deBouncingCall(getValues("fbaList"));
            }
          }} fontSize="12px" height="34.25px" labelStyle={{fontSize:"12px",fontWeight:'bold',color:"black"}} fullwidth={true} rules={{pattern: {
            value: /^[a-zA-Z0-9]+$/,
            message: "Only alphanumeric characters are allowed",
          },validate:(val)=>{
            if(isMandatory && !val && val.length===0){
                return "Fba ID is required"
            }
          if(val?.length>12){
              return "Value should not exceed 12"
          }
        }}} placeholder="Enter your Fba Id" control={control} label={`FBA ID ${isMandatory?"*":""}`} name={`${fbaListString}.${i}.fbaID`}  />
        
<LabeledTextField type="text" inputProps={{maxLength:8}} key={fba.id+"purchase"} onChange={async(v)=>{
            let currentValue = `${fbaListString}.${i}.purchaseOrderID`
            setValue(currentValue,v);
            if(autoUpdate){ 
              deBouncingCall(getValues("fbaList"));
            }
          }} fontSize="12px" height="34.25px" labelStyle={{fontSize:"12px",fontWeight:'bold',color:"black"}} fullwidth={true} rules={{pattern: {
            value: /^[a-zA-Z0-9]+$/,
            message: "Only alphanumeric characters are allowed",
          },validate:(val)=>{
            if(isMandatory && !val && val.length===0){
                return "Amazon Reference ID is required"
            }
          if(val?.length>8){
              return "Value should not exceed 8"
          }
        }}} placeholder="Enter your Amazon Reference ID" control={control} label={`Amazon Reference ID ${isMandatory?"*":""}`} name={`${fbaListString}.${i}.purchaseOrderID`}  />
        <div onClick={async(e)=>{
            appendFba({purchaseOrderID:"",fbaID:""})
            e.stopPropagation();
            if(autoUpdate){ 
            try{
                if(!isMandatory){
                await validateInput()
                updateFbaDetailOnAdditionAndRemoval()
                }
            }catch(e){
                 console.log(e?.message)
            }
        }
        }} className="flex felx-row gap-2 self-end">
           { i===fbaList.length-1 && <div className="flex justify-center items-center bg-[#F7F7F7] rounded-[3px] w-[38px] h-[35px] cursor-pointer">
           <Add sx={{fontSize:"22px",color:"#2C358A"}}/>
           </div>}
          {fbaList.length>1 && <div onClick={async(e)=>{
            removeHandler(i) 
            e.stopPropagation();
            
            if(autoUpdate){
                let isFound = booking.booking.fbaDetails[ind][i];
                let updatedFbaList;
                try{
                   await validateInput();
                   if(!isFound){
                       return;
                   }
                }catch(e){
                   if(isFound){
                       if(booking.booking.fbaDetails[ind].length===1){
                         return;
                       }
                       booking.booking.fbaDetails[ind].splice(i,1);
                       updatedFbaList = booking.booking.fbaDetails;
                   }else{
                       return;
                   }
                }
              updateFbaDetailOnAdditionAndRemoval(updatedFbaList)
            }
          }} className="flex justify-center items-center bg-[#FFDCE2] rounded-[3px] w-[38px] h-[35px] cursor-pointer">
           <img src={deleteicon}/>
           </div>}
           {(i<fbaList.length-1 || fbaList.length===1)  && <div className="flex justify-center items-center bg-white rounded-[3px] w-[38px] h-[35px]">
           </div>}
        </div>
        </div>)}
              </div>
}