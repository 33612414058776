import { myactionsstore } from "../my-actions/store-myactions"
import Utils from "../util/Utils"
import Api from "./Api"
import { contractsStore, loginStore } from "./CommonState"

export default class Newapi {
    static async post(url, payload, method = 'POST') {
        const requestOptions = {
            method: method,
            headers: Api.H(),
            body: JSON.stringify(payload)
        }
        return await fetch(url, requestOptions)
            .then(res => res.json())
    }
    static async put(url, payload) {
        return await Newapi.post(url, payload, 'PUT')
    }
    static async get(url) {
        const requestOptions = {
            headers: Api.H()
        }
        return await fetch(url, requestOptions).then(res => res.json())
    }
    static async acquireContractLock(contractNum) {
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/contracts/acquire/lock`
        let payload = {
            contractNum,
            email: loginStore.email
        }
        let ret = await Newapi.post(url, payload)
        console.log('FROM ACQUIRE CONTRACT LOCK=', ret)
        return ret
    }
    static async releaseContractLock(contractNum) {
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/contracts/release/lock`
        let payload = {
            contractNum,
            email: loginStore.email
        }
        let ret = await Newapi.post(url, payload)
        console.log('FROM RELEASE CONTRACT LOCK=', ret)
        return ret
    }
    static async getAllDsr() {
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions`
        let ret = await Newapi.get(url)
        console.log('GOT RETURN FROM ALL DSR', ret)
        myactionsstore.setList(ret)
        return ret
    }
    static async getAllDsr4Display() {
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/get4display`
        let ret = await Newapi.get(url)
        if (myactionsstore.navigateFromCharts) ret = ret.filter(e => e.booking.status === 'INPROGRESS')
        console.log('GOT RETURN FROM ALL DSR', ret)
        myactionsstore.setList(ret)
        return ret
    }

    static async getAllDsr4Displaybyid(bookingNum) {
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/get4display/`+bookingNum
        let ret = await Newapi.get(url)
        myactionsstore.setList(ret)
        return ret
    }
    static async getContracts(callback = f => f) {
        callback([])
        // let url = `${process.env.REACT_APP_API_SERVER}/api/v1/contracts`
        // let data = await Newapi.get(url)
        // if (!contractsStore.navigateFromCharts) contractsStore.list = data
        // console.log('*** LOADED NEW CONTRACTS ***')
        // callback(data)
    }
    static async getToExpireContracts(callback = f => f) {
        callback([])
        // let curDate = Utils.getCurrentDateString()
        // let nextWeek = Utils.getCurrentDateString(Utils.addDays(new Date(), 7))
        // let url = `${process.env.REACT_APP_API_SERVER}/api/v1/contracts`
        // let data = await Newapi.get(url)
        // data = data.filter(e => e.validUpto >= curDate && e.validUpto < nextWeek && e.mode === 'CONTRACT')
        // contractsStore.navigateFromCharts = true
        // contractsStore.list = data
        // callback(data)
    }
    static async getLocationName(unloc) {
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/common/get4unloc/${unloc}`
        let data = null
        try {
            data = await Newapi.get(url)
        } catch (e) { }
        if (!data || !data.location) return unloc
        return data.location
    }
    static async downloaddsr() {
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/downloadxl`
        const res = await Newapi.get(url);
        const blob = await res.blob();
        const file = window.URL.createObjectURL(blob);
        window.location.assign(file);
    }
}