import { Box, Collapse, IconButton, OutlinedInput, TextField, Tooltip, Typography } from "@mui/material";
import { VisibilityTracker, VisibilityTrackerV2 } from "../mui-components/VisibilityTracker";
import Utils from '../util/Utils'
import downloadIcon from "../assets/images/DownloadIcon1.svg";
import arrowDropDown from "../assets/images/arrowdropdown.svg";
// import editIcon from "../assets/images/EditIcon1.svg";
import editIcon1 from "../assets/images/EditableIcon.svg";
// import closecontained from "../assets/images/closecontained.svg";
// import pdf from "../assets/images/pdfIcon.svg"
// import DownloadIcon1 from "../assets/images/DownloadIcon2.svg"
import arrowDown from "../assets/images/arrowdown.svg";
import { useEffect, useRef, useState } from "react";
import { AIR, FFF, LCL } from "./visibilityRules.js";
import agraga from "../assets/images/logo.svg";
import refresh from "../assets/images/RefreshIcon.svg"
import rightIcon from "../assets/images/RightIcon.svg"
import deleteicon from "../assets/images/DeleteIcon.svg"
import Api from "../state/Api.js";
import { AgMuiSelect } from "../mui-components/AgMuiSelect.js";
import NewBookingPopup from "./NewBookingPopup.js";
import trackMyBookingOceanStore from "./TrackMyBooking-store.js";
// import { visibilityStore } from "./visibilityStore.js";
import DeletePopup from "./DeletePopup.js";
import { configStore, newDashboardState } from "../state/CommonState.js";
// import { UploadFileComponent } from "../mui-components/UploadFileComponent.js";
import schLogoMap from "../enquiry/carrier-logo.js";
import { ChooseFile } from "../mui-components/ChooseFile.js";
// import { logDOM } from "@testing-library/react";
import airlogoMap from "../enquiry/air-logo.js";
// const documentList = []

export function BookingDetailCard({shipmentType, booking, refreshCb, data }) {
    const defaultTrackPoints = {
        // origin: '',
        por: '',
        pol: '',
        pod: '',
        fpod: '',
        // destination: '',
        dummy: true
    }
    booking.shipmentType = booking.type === 'Air' ? 'Air' : 'FCL (Factory/Factory)'
    function getRule(shipmentType) {
        const rules = {
            "LCL": LCL,
            "FCL (Factory/Factory)": FFF,
            "FCL (Dock/Factory)": FFF,
            "FCL (Factory/Dock)": FFF,
            "FCL (Dock/Dock)": FFF,
            "Air": AIR
        }
        return rules[shipmentType];
    }
    function getTrackerPoints(booking, containerIndex) {
        let trackerGraph = [];
        if (booking['tsUpdatedBy'] === 'invalid' || booking['tsUpdatedBy'] === 'new' || !booking["tsUpdatedBy"]) {
            booking = { ...booking, ...defaultTrackPoints }
        }
        const getValueForLabel = (actualMilestone, details, index, currentBooking) => {
            if (index === 0) {
                if (actualMilestone.milestoneAchieved) {
                    if (typeof details.actual === "function") {
                        return details.actual(currentBooking, actualMilestone.milestoneAchieved, containerIndex)
                    } else {
                        return currentBooking[details.actual];
                    }
                } else {
                    if (typeof details.estimate === "function") {
                        return details.estimate(currentBooking, actualMilestone.milestoneAchieved, containerIndex)
                    } else {
                        return currentBooking[details.estimate];
                    }
                }
            } else if (index === 1) {
                if (actualMilestone.milestoneAdvanced) {
                    if (typeof details.actual === "function") {
                        return details.actual(currentBooking, actualMilestone.milestoneAdvanced, containerIndex)
                    } else {
                        return currentBooking[details.actual];
                    }
                } else {
                    if (typeof details.estimate === "function") {
                        return details.estimate(currentBooking, actualMilestone.milestoneAdvanced, containerIndex)
                    } else {
                        return currentBooking[details.estimate];
                    }
                }

            }
        }
        getRule(booking.shipmentType)?.forEach((milestone) => {
            if (milestone.isMilestoneAddsToThePath(booking)) {
                let currentMilestone = { milestone: "", milestoneAchieved: false, carrier: "", isPathCompleted: false, milestoneAdvanced: false, milestoneCompleted: false, estimateDetails: ["N/A", "N/A"], shipmentType: "", dynamicIcon: milestone.dynamicIcon, isPortMilestone: false, vesselName: "", vesselNumber: "", flightNumber: "", bookingId: booking._id, location: milestone.milestone };
                currentMilestone.isPortMilestone = milestone.isPortMilestone;
                currentMilestone["milestone"] = milestone.milestone;
                currentMilestone.shipmentType = booking.shipmentType;
                currentMilestone.carrier = booking.carrier;
                let isBookingClosed;

                if (milestone.location) {
                    currentMilestone.location = booking[milestone.location];
                }

                if (milestone.milestoneAchievedBasedOn) {

                    if (isBookingClosed) {
                        currentMilestone.milestoneAchieved = true;
                    } else if (typeof milestone.milestoneAchievedBasedOn === "function") {
                        currentMilestone.milestoneAchieved = milestone.milestoneAchievedBasedOn(booking, containerIndex)

                    } else {
                        currentMilestone.milestoneAchieved = Utils.isValueExists(milestone.milestoneAchievedBasedOn, booking)
                    }

                }

                if (milestone.milestoneAdvancedBasedOn) {
                    if (isBookingClosed) {
                        currentMilestone.milestoneAdvanced = true;
                    } else if (typeof milestone.milestoneAdvancedBasedOn === "function") {
                        currentMilestone.milestoneAdvanced = milestone.milestoneAdvancedBasedOn(booking, containerIndex)
                    } else {
                        currentMilestone.milestoneAdvanced = Utils.isValueExists(milestone.milestoneAdvancedBasedOn, booking)
                    }
                }
                let downstreamStatus = !milestone.downStreamMilestone(booking); // This indicates whether this is the last milestone of the path
                currentMilestone.isPathCompleted = downstreamStatus;

                /** If this is not the last milestone of the path, we check for the completion of the milestone 
                 * based on some keys that is configured in the rules
                 */
                if (!downstreamStatus) {

                    if (isBookingClosed) {
                        currentMilestone.milestoneCompleted = true;
                    } else if (typeof milestone.milestoneCompletedBasedOn === "function") {

                        currentMilestone.milestoneCompleted = milestone.milestoneCompletedBasedOn(booking, containerIndex);

                    } else {

                        currentMilestone.milestoneCompleted = Utils.isValueExists(milestone.milestoneCompletedBasedOn, booking)
                    }
                }

                currentMilestone.estimateDetails = milestone.milestoneDetails.map((estDetail, index) => {
                    return estDetail.isLabel ? { ...estDetail } :
                        { ...estDetail, value: getValueForLabel(currentMilestone, estDetail, index, booking) }
                });

                if (milestone.isPortMilestone) {
                    if (booking?.shipmentType?.toLowerCase() === "air") {
                        currentMilestone.flightNumber = booking[milestone.flightKey]
                    } else {
                        currentMilestone.vesselName = booking[milestone.vesselNameKey]
                        currentMilestone.vesselNumber = booking[milestone.vesselNumberKey];
                    }
                }
                // if(currentMilestone.milestoneCompleted){
                //     currentMilestone.milestoneAchieved = true;
                //     currentMilestone.milestoneAdvanced = true;
                // }

                trackerGraph.push(currentMilestone);
            }
        })
        let previousMilestoneAchieved = false;
        for (let index = trackerGraph.length - 1; index >= 0; index--) {
            if (previousMilestoneAchieved) {
                // trackerGraph[index].milestoneAchieved = true;
                // trackerGraph[index].milestoneAdvanced = true;
                trackerGraph[index].milestoneCompleted = true;
            }
            if (trackerGraph[index].milestoneAchieved || trackerGraph[index].milestoneAdvanced || trackerGraph[index].milestoneCompleted) {
                previousMilestoneAchieved = true;
            }
        }

        return trackerGraph;
    }
    function getStatusColor(status) {
        switch (status) {
            case 'In-transit':
                return {
                    backgroundColor: "#EBB02D33",
                    color: "#D59A17"
                };
            case 'Yet to Commence':
                return {
                    backgroundColor: "#717AD233",
                    color: "#717AD2"
                };
            case 'Completed':
                return {
                    backgroundColor: "#2C8A5733",
                    color: "#2C8A57"
                };
            case 'Invalid':
                return {
                    backgroundColor: "#F25B5B33",
                    color: "#F25B5B"
                };
            default:
                return {
                    backgroundColor: "white",
                    color: "black"
                };
        }
    }
    function Points({ propertyKey, value, labelWidth = "70px", isStatus }) {
        let legendColor = getStatusColor(value);
        return <Box sx={{ display: "flex", flexDirection: "row", minWidth: "187px", width: "max-content", flex: 1, gap: "10px" }}>
            <Typography sx={{ fontWeight: "bold", fontFamily: "Figtree", fontSize: "12px", lineHeight: '14.4px', minWidth: labelWidth || "70px", maxWidth: labelWidth || "70px", display: "flex", color: "black" }}>{propertyKey}</Typography>
            <Typography sx={{ fontFamily: "Figtree", fontSize: "12px" }}>:</Typography>
            {isStatus ?
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontFamily: "Figtree", borderRadius: "46px", paddingY: "2px", paddingX: "8px", color: legendColor.color, backgroundColor: legendColor.backgroundColor, height: "20px", fontSize: "14px" }}>{value}</Box> :
                <Tooltip title={value} componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: "white",
                            color: "black",
                            boxShadow: "4px 4px 20px 0px #00000033"

                        }
                    }
                }} style={{ backgroundColor: "white", color: "black" }}>
                    <Typography sx={{ fontFamily: "Figtree", fontSize: "12px", textOverflow: "ellipsis", width: "max-content", maxWidth: "138px", display: "flex", color: "black", overflow: "hidden", whiteSpace: "nowrap" }}>{value}</Typography>
                </Tooltip>
            }
        </Box>
    }

    const getStatus = {
        "active": "In-transit",
        // "Origin Departure": "In-transit",
        // "Trans Shipment Departure": "In-transit",
        // "Arrived": "In-transit",
        "new": "Yet to Commence",
        "invalid": "Invalid",
        "completed": "Completed",
        // "Tracking Ended": "Completed",
        // "inActive/inCorrect AWB Number": "Invalid",
        "Delivered": "Completed"
    }

    const [containerIndex, setContainerIndex] = useState(-1);
    const containers = [];
    const isFClWithContainerList = booking.containerlist && booking.containerlist?.length > 1;
    if (isFClWithContainerList) {
        containers.push({ lable: "All Container", value: -1 });
        booking.containerlist?.forEach((container, index) => {
            containers.push({ lable: ` ${container.container_types}`, value: index });
        });
    }

    useEffect(() => {
        setCustReference(booking['customerReference']);
    }, [booking])

    const [custReference, setCustReference] = useState('')
    const [open, setOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [input, setInput] = useState(true)
    const [docOpen, setDocOpen] = useState(false)
    const [documentList, setDocumentList] = useState(booking?.files && booking?.files?.length > 0 ? booking?.files : [])
    const [docName, setDocName] = useState('');
    const [error, setError] = useState(false)

    const [popupMessage, setPopupMessage] = useState('')
    const refreshProject = (obj) => {
        data(obj)
        // visibilityStore.recheck === 0 ? visibilityStore.recheck = 1 : visibilityStore.recheck = 0;
    }

    useEffect(() => {
        // if (documentList.length > 0) {  // Ensure there are items to update
        // console.log(booking._id, documentList, "filtered data 3");
        Api.updateDocumentById(booking._id, documentList)
            .then(response => {
                console.log('API update successful:', response);
            })
            .catch(error => {
                console.error('API update failed:', error);
            });
        // }
    }, [documentList, booking._id]);

    let url = `${process.env.REACT_APP_FILE_SERVER}/api/v1/filestore/storefile`
    return <>
        <Box sx={{ display: "flex", flexDirection: "column", backgroundColor: "white", gap: 1, border: "1px solid #D4D4D4", borderRadius: "12px", position: "relative" }}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "space-between", padding: "10px" }}>
                <Box sx={{ display: "flex", direction: "row", height: "50px", gap: 2 }}>
                    <Box sx={{ height: '60px', width: '60px' }}>
                        {/* {booking['carrier'] ? <img style={{ objectFit: 'fill', height: '100%', width: '100%' }} src={schLogoMap[booking['carrier'] || booking['carrier_scac']] || agraga} alt="" /> : <img style={{ objectFit: 'fill', height: '100%', width: '100%' }} src={agraga} alt="" />
                            //    booking['carrier'] ? <img src={schLogoMap[booking['carrier']] || agraga} alt="" /> : <img src={airlogoMap[booking?.flight_number_ts1?.split(" ")[0]] || agraga} alt="" />
                        } */}
                    </Box>

                    {/* <img src={agraga} alt="" /> */}
                    <Box sx={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between" }}>
                        {booking["tsUpdatedBy"] ?
                            <Points propertyKey="Status" value={getStatus[booking["tsUpdatedBy"]] || booking["tsUpdatedBy"]} isStatus={true} /> : ''}
                        <Points propertyKey="Booking Id" value={booking["_id"]} />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, justifyContent: "space-between" }}>
                    <Typography sx={{ fontWeight: "bold", fontFamily: "Figtree", lineHeight: '13.2px', fontSize: "11px", display: "flex", color: "black" }}>Customer Reference Number</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <TextField sx={{
                            width: '200.36px',
                            height: '30.01px',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderRadius: '7px 0px 0px 7px'
                                },
                                '& input': {
                                    padding: '4px 8px',
                                    // borderRadius: '7px 0px 0px 7px'
                                },
                                // border: 'none'
                            }
                        }}
                            disabled={input}
                            placeholder="Enter Number"
                            value={custReference}
                            onChange={(e) => {
                                setCustReference(e.target.value);
                            }}
                        />
                        <IconButton sx={{ '&:hover': { backgroundColor: '#2C358A', }, width: '35px', height: "30px", backgroundColor: '#2C358A', borderRadius: '0px 7px 7px 0px' }}
                            onClick={() => {
                                if (!input) {
                                    if (custReference !== '' && custReference !== booking['customerReference']) {
                                        Api.updateCustomerReferenceId(booking["_id"], custReference)
                                        setInput(!input)
                                    } else {
                                        setInput(!input)
                                    }
                                } else {
                                    setInput(!input)
                                }
                            }}>
                            {
                                input ? <img src={editIcon1} alt="" /> : <img src={rightIcon} alt="" />
                            }
                        </IconButton>

                    </Box>
                </Box>
                {isFClWithContainerList && <Box sx={{ display: "flex", flexDirection: "column", gap: 1, justifyContent: "space-between" }}>
                    <Typography sx={{ fontWeight: "bold", fontFamily: "Figtree", fontSize: "11px", lineHeight: '13.2px', display: "flex", color: "black" }}>Select Container</Typography>
                    <AgMuiSelect iconComponent={arrowDropDown} fontSize="14px" onChange={(e) => {
                        setContainerIndex(e.target.value);
                    }} value={containerIndex} options={containers.map(({ lable }) => lable)} values={containers.map(({ value }) => value)} height="30px" width="160px" style={{ borderRadius: "7px" }} />
                </Box>}
                <Box sx={{ display: "flex", flexDirection: "column", height: '100%', justifyContent: "space-between" }}>
                    <Points propertyKey={booking["carrierBookingRefNumber"] ? 'Carrier Booking Number' : booking["containerNumber"] ? 'Container Booking' : booking['MAWBNumber'] ? "MAWB Number" : "MBL Number"} value={booking["carrierBookingRefNumber"] ? booking["carrierBookingRefNumber"] : booking["containerNumber"] ? booking["containerNumber"] : booking["carrierMBLNumber"] ? booking["carrierMBLNumber"] : booking['MAWBNumber']} labelWidth={booking["carrierBookingRefNumber"] ? "140px" : "110px"} />
                    {booking["tsUpdatedOn"] ? <Points propertyKey="Last Updated" value={Utils.formatToDateTime(Number(booking["tsUpdatedOn"]), true)} labelWidth={booking["carrierBookingRefNumber"] ? "140px" : "110px"} /> : ''}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 2 }}>
                    {<IconButton sx={{ '&:hover': { backgroundColor: '#2C358A', }, width: '35px', height: '35px', backgroundColor: '#2C358A', borderRadius: '5px' }}
                        onClick={() => {
                            if (booking["tsUpdatedBy"] === 'invalid') {
                                if (booking?.carrierMBLNumber) {
                                    trackMyBookingOceanStore.carrierMBLNumber = booking?.carrierMBLNumber
                                    trackMyBookingOceanStore.textfield = 'Carrier MBL Number'
                                } else if (booking?.carrierBookingRefNumber) {
                                    trackMyBookingOceanStore.carrierBookingRefNumber = booking?.carrierBookingRefNumber
                                    trackMyBookingOceanStore.textfield = 'Carrier Booking Reference#'
                                } else if (booking?.containerNumber) {
                                    trackMyBookingOceanStore.containerNumber = booking?.containerNumber
                                    trackMyBookingOceanStore.textfield = 'Container Number'
                                } else {
                                    trackMyBookingOceanStore.MAWBNumber = booking?.MAWBNumber
                                }
                                trackMyBookingOceanStore.carrier = booking?.carrier
                                trackMyBookingOceanStore.bookingId = booking?._id
                                trackMyBookingOceanStore.customerReference = booking?.customerReference
                                setOpen(true);
                            } else {
                                if (booking.type === 'Ocean') {
                                    Api.getproject44BookingsData(booking?._id, refreshProject);
                                } else {
                                    Api.getIntermoBookingsData(booking.MAWBNumber, refreshProject);
                                }
                            }
                        }}>
                        {booking["tsUpdatedBy"] === 'invalid' ? <img src={editIcon1} alt="" /> : <img src={refresh} alt="" />}
                    </IconButton>}
                    {
                        booking["tsUpdatedBy"] === 'invalid' || (!booking['project44Id'] && booking['type'] === 'Ocean') || (booking['type'] === 'Air' && !booking['intermo']) ? <IconButton sx={{ '&:hover': { backgroundColor: '#FE3839', }, width: '35px', height: '35px', backgroundColor: '#FE3839', borderRadius: '5px' }}
                            onClick={() => {
                                setDeleteOpen(true)
                            }}>
                            <img src={deleteicon} alt="" />
                        </IconButton> : ''
                    }
                </Box>
            </Box>
            {
                booking["tsUpdatedBy"] === 'invalid' || !booking["tsUpdatedBy"] ? <>
                    <Box sx={{ width: "100%", paddingX: "20px",display:"flex",flexDirection:'row',alignItems:"center"  }}>
                    {booking['carrier'] ? <img style={{objectFit: 'contain', height: '60px', width: '60px'}} src={schLogoMap[booking['carrier'] || booking['carrier_scac']] || agraga} alt="" /> : <img style={{ objectFit: 'contain', height: '60px', width: '60px'  }} src={agraga} alt="" />
                            //    booking['carrier'] ? <img src={schLogoMap[booking['carrier']] || agraga} alt="" /> : <img src={airlogoMap[booking?.flight_number_ts1?.split(" ")[0]] || agraga} alt="" />
                        }
                        <VisibilityTrackerV2 mode={booking.shipmentType} isViewFull={true} container={isFClWithContainerList && containerIndex > -1 ? <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black", fontSize: "12px" }}>{containers[containerIndex + 1].lable} : <span style={{ color: "#555555", fontWeight: "normal" }}>{booking.containerlist[containerIndex].container_types}</span></Typography> : null} booking={getTrackerPoints(booking, containerIndex)} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <span style={{ fontFamily: "Figtree", fontWeight: "bold", lineHeight: '30px', fontSize: "20px", color: "black", position: "absolute", bottom: booking['carrierMBLNumber'] || booking['carrierBookingRefNumber'] ? 19 :  "5px"  }}>
                            {booking["tsUpdatedBy"] === 'invalid' ?
                                `"${booking['carrierMBLNumber'] ? 'Carrier MBL Number' : booking['carrierBookingRefNumber'] ? 'Carrier Booking Number' : 'Container Number'} which you have entered may be incorrect.` :
                                '"Waiting for Tracking to Start"'
                            } </span>
                        <span style={{ fontFamily: "Figtree", fontWeight: "bold", lineHeight: '30px', fontSize: "20px", color: "black" }}>
                            {booking["tsUpdatedBy"] === 'invalid' ? `Please enter a valid ${booking['carrierMBLNumber'] ? 'Carrier MBL Number' : booking['carrierBookingRefNumber'] ? 'Carrier Booking Number' : 'Container Number'}."` : ''}
                        </span>
                    </Box>
                </>
                    : <> <Box sx={{ width: "100%", paddingX: "20px",textAlign:"center",display:"flex",flexDirection:'row',alignItems:"center" }}>
                         {booking['carrier'] ? <img style={{ objectFit: 'contain', height: '60px', width: '60px' }} src={schLogoMap[booking['carrier'] || booking['carrier_scac']] || agraga} alt="" /> : <img style={{ objectFit: 'contain', height: '60px', width: '60px'  }} src={agraga} alt="" />
                            //    booking['carrier'] ? <img src={schLogoMap[booking['carrier']] || agraga} alt="" /> : <img src={airlogoMap[booking?.flight_number_ts1?.split(" ")[0]] || agraga} alt="" />
                        }
                        <VisibilityTrackerV2 mode={booking.shipmentType} isViewFull={true} container={isFClWithContainerList && containerIndex > -1 ? <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black", fontSize: "12px" }}>{booking.containerlist[containerIndex].container_types} : <span style={{ color: "#555555", fontWeight: "normal" }}>{booking.containerlist[containerIndex].container_number}</span></Typography> : null} booking={getTrackerPoints(booking, containerIndex)} />
                    </Box>
                        <Collapse in={docOpen} timeout="auto" unmountOnExit={false}>
                            <Box sx={{ padding: '20px' }}>
                                <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", fontSize: "18px", color: "black" }}>Upload Documents</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: "column", gap: 2, padding: '20px 0px  0px 20px' }} >
                                {
                                    documentList && documentList?.map((element, index) =>
                                        <ChooseFile 
                                    key={element?.label + element?.filename?.length}
                                            onFileSelected={(file, updateFileState, updateFailureState) => {
                                                const formData = new FormData()
                                                formData.append('myFile', file)
                                                formData.append('label', element.label)
                                                formData.append('key', booking._id)
                                                fetch(url, {
                                                    method: 'POST',
                                                    headers: Api.H1(),
                                                    body: formData
                                                })
                                                    .then(async response => await response.json())
                                                    .then(data => {
                                                        if (data.error) {
                                                            if (data.error !== 'No Error' || data.error !== 'Invalid Token') {
                                                                if (updateFailureState) {
                                                                    updateFailureState();
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            if (updateFileState) {
                                                                updateFileState(()=>{
                                                                    setDocumentList((prevDocumentList) => {
                                                                        const updatedDocumentList = [...prevDocumentList];
                                                                        updatedDocumentList[index]?.filename?.push(data.key.filename);
                                                                        return updatedDocumentList;
                                                                    })
                                                                });
                                                                // Api.updateDocumentById(booking._id, documentList)
                                                            }
                                                        }
                                                    }).catch((error) => {
                                                        if (updateFailureState) {
                                                            updateFailureState();
                                                        }
                                                    })
                                            }} onFileDeleted={(files, succes, failure) => {
                                                if (succes) {
                                                    succes(()=>{
                                                        setDocumentList((prevDocumentList) => {
                                                            const updatedDocumentList = [...prevDocumentList];
                                                            updatedDocumentList[index]?.filename?.pop(files);
                                                            const filteredData = updatedDocumentList.filter(doc => doc.filename && doc.filename.length > 0);
                                                            return filteredData;
                                                        })
                                                    });
                                                    // Api.updateDocumentById(booking._id, documentList)
                                                }
                                            }} direction="row" title={element?.label} data={element} isEditable={true} editLabel={(editedLabel) => {
                                                const updatedDocumentList = [...documentList]
                                                updatedDocumentList[index].label = editedLabel
                                                setDocumentList(updatedDocumentList)
                                                // Api.updateDocumentById(booking._id, documentList)
                                            }} id={booking._id} documentView={true} />
                                    )
                                }
                                <Box sx={{ display: 'flex', flexDirection: 'row', paddingBottom: '10px', width: '100%', gap: 2 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <OutlinedInput sx={{
                                            width: '200.36px',
                                            height: '30.01px',
                                            '& input': {
                                                padding: '0px 10px',
                                            }, borderRadius: '7px 0px 0px 7px'
                                        }}
                                            error={error}
                                            placeholder="Enter Document Name"
                                            value={docName}
                                            onChange={(e) => {
                                                setError(false)
                                                setDocName(e.target.value)
                                            }} />
                                        <IconButton sx={{ '&:hover': { backgroundColor: '#2B6ED4', }, width: '35px', height: '30px', backgroundColor: '#2B6ED4', borderRadius: '0px 7px 7px 0px' }}
                                            onClick={() => {
                                                if (docName !== '') {
                                                    setDocumentList((prev) => [...prev, { label: docName, filename: [] }])
                                                    setDocName('')
                                                } else {
                                                    setError(true)
                                                }
                                            }}>
                                            <img src={rightIcon} alt="" />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Collapse>
                        <Box onClick={() => {
                            setDocOpen((prev) => !prev);
                        }} sx={{
                            width: "100%", backgroundColor: "#DCEDF9", gap: 1, justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "row", borderBottomLeftRadius: "12px", borderBottomRightRadius: "12px", paddingY: "4px", "&:hover": {
                                cursor: "pointer"
                            }
                        }}>
                            <img style={{ width: "16px", height: "16px" }} src={downloadIcon} alt="" />
                            <Typography sx={{ fontWeight: "bold", fontFamily: "Figtree", fontSize: '14px', lineHeight: '16.8px', color: "black" }}>{"Upload Documents"}</Typography>
                            <img style={{ width: "12px", height: "12px", transform: open ? "rotate(180deg)" : "none" }} src={arrowDown} alt="" />
                        </Box></>
            }
            <NewBookingPopup shipmentType={shipmentType} open={open} onCancel={() => {
                setOpen(false);
                trackMyBookingOceanStore.setTrackBooking()
            }} onSubmit={(data) => {
                Api.addTrackBookings(data, shipmentType, refreshCb)
                setOpen(false)
                trackMyBookingOceanStore.setTrackBooking()
            }} booking={booking} />
            <DeletePopup open={deleteOpen} onCancel={() => {
                setDeleteOpen(false)
            }} onSubmit={async () => {
                const response = await Api.deleteBookings(booking._id)
                setPopupMessage(response)
                setDeleteOpen(true)
                setTimeout(() => {
                    setDeleteOpen(false)
                    refreshCb();
                }, 1500)
            }} booking={booking} popupMessage={popupMessage} />
        </Box>
    </>
}

