import Api from "./Api"
import { configStore, contractsStore } from "./CommonState"
const SCHURI = {
    GET: (id) => `${process.env.REACT_APP_API_SERVER}/api/v1/schedules/${id}`,
    SAVE: (id) => `${process.env.REACT_APP_API_SERVER}/api/v1/schedules/${id}`,
    MULTISAVE: (id) => `${process.env.REACT_APP_API_SERVER}/api/v1/schedules/multischedules/${id}`,
}
export default class SchApi {
    static async getSchedules(id, callback = f => f) {
        if(id != undefined && id.trim().length>0){
            Api.fetch(SCHURI.GET(id), (data) => {
                console.log('fetched schedules for', id, data)
                callback(data)
            })
        }
    }
    static saveSchedules(id, schedules) {
        if(id != undefined && id.trim().length>0){
            console.log('about to call save schedules')
            Api.post(SCHURI.SAVE(id), ( contractsStore.current.hasToShowDummySchedule  ? ({ hasToShowDummySchedule : contractsStore.current.hasToShowDummySchedule }) : schedules ), (data) => {
                configStore.setModalMessage('Schedules Saved')
            })
        }
    }

    static savemultiSchedules(id, schedules,contract) {
        if(id != undefined && id.trim().length>0){
            console.log('about to call save multi schedules')
            let payload = {contract:contract,schedules:schedules}
            Api.post(SCHURI.MULTISAVE(id),payload,(data)=>{
                configStore.setModalMessage('Schedules Saved')
            })
        }
    }
}