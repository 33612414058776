import {
  configStore,
  quoteStore,
  setCommonState,
  quoteDataRec,
  containerStore,
  cargoStore,
  chargesStore1,
  fclChargesStore,
  toEmailStore,
  ccEmailStore,
  loginStore,
  customerStore,
  airChargesStore,
  contractsStore,
  dataListStore,
  quotatationImportStore,
  aguserStore,
  routerStore,
  vehicleStore,
  entityStore,
  EntityV2Store,
  AgmodalStore
} from "./CommonState";
import QuoteView from "../QuoteView";
import EntityApi from "../state/entity-api";
import ReactDOMServer from "react-dom/server";
import { ServerLists } from "../StaticData";
import Utils from "../util/Utils";
import QuoteCalc from "../util/quote-calc";
import { PricingContractSaved, PricingQuoteSaved, QuoteSavedMessage, QuoteSavedMessage1 } from "../components/AgComponents";
import { enquiryStore } from "../enquiry/enquiry-data";
import { calcCargoDetails } from "../CargoComp";
import AguserApi from "../state/AguserApi";
import { menucountStore } from "./menuStore";
import { VehicleType } from "./Types";
import MonApi from "./mongo-api";
import QuoteViewv2 from "../quotes/quote-view-v2";
import QuoteCalcV2 from "../util/quote-calc-v2";
import trackMyBookingOceanStore from "../trackmybookings/TrackMyBooking-store";
import airlineCodes from "../static-data/airlines";
import { useEffect } from "react";
import EnquiriesApi from "./enq-api";
import { rtpStore } from "../pricing/pricing-store";
import { loader } from "../util/loader";
import QuoteViewv3 from "../quotesv3/quote-view-v3";

const GET_QUOTES_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/quotes`;
const GET_QUOTE_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/quotes`;

const POST_QUOTE_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/quotes`;
const GET_CONTRACTS_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/contracts`;
const CREATE_CONTRACT_FROM_QUOTE_URI = (id) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/contracts/${id}`;
const GET_CONTRACT_URI = (id) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/contracts/${id}`;
const DECLINE_CONTRACT = (id) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/contracts/decline/${id}`;
const SCHEDULES_CONTRACT = (id) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/contracts/schedules/${id}`;
const WON_QUOTES = (id) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/won/${id}`;
const LOST_QUOTES = (id) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/lost/${id}`;
const INVALID_QUOTES = (id) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/invalid/${id}`;
const AGRAGA_ENQUIRY_STATUS = (id) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/agragaenquirystatus/${id}`;
const GETCUSTOMSCLEARENCEFILES = (entityId) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/customs/files/${entityId}`;
const CHECKKYCTRIGGERED = (bookingId) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/checkKyc/${bookingId}`;
const TRIGGERKYCREQUIREMENT = (bookingId) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/triggerKyc/${bookingId}`;

const LASTUPLOAD = (type) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/common/getlastupload/${type}`;
const POST_EMAIL_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/send/email`;
const POST_EMAIL2_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/sendv2/email`;
const POST_EMAIL3_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/sendv3/email`;
const SEND_FILE_ATTACHEMENT = `${process.env.REACT_APP_FILE_SERVER}/api/v1/filestore/email/attachment`;
const POST_EMAILWITHPDF_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/send/emailwithpdf`;
const VALIDATE_EMAIL_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/common/getcustidforemail`;
const GET_USER4EMAILPASSWORD = `${process.env.REACT_APP_API_SERVER}/api/v1/common/getuser4emailpassword`;
const UPDATE_QUOTE_STATUS = `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/update/status`;
const GET_CUSTOMERS_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/customer`;
const GET_UNLOC_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/common/getunloc`;
const GET_4UNLOC_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/common/get4unloc`;
const GET_MAP_UNLOC_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/common/getmapunloc`;
const GET_MAP_UNLOC_URI1 = `${process.env.REACT_APP_API_SERVER}/api/v1/ppschedules/getmapfcllclunloc`;

const GET_EXCHANGERATES_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/common/getexchangerates`;
const CREATE_CUSTOMER_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/customer`;
const TRACK_BOOKINGS_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/trackmybookings`;
const GET_COUNTRYLIST_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/common/getcountrylist`;
const GET_ROLES_PERMISSIONS = `${process.env.REACT_APP_API_SERVER}/api/v1/common/getRolesPermissions`;
const SEND_CLIENTMAIL_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/common/sendclientmail`;
const SEND_CLIENT_DOCUMENTMAIL_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/common/senddocumentrequestmail`;
const SEND_VENDOR_MAIL_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/common/sendvendormail`;
const SEND_REJECTION_MAIL_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/common/sendrejectionmail`
const UPDATE_BOOKINGS_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/bookings/update/documentrequest`;
const SEND_CLIENTMAIL_URI1 = `${process.env.REACT_APP_API_SERVER}/api/v1/common/sendclientmail1`;
const SEND_CLIENTMAIL_URI3 = `${process.env.REACT_APP_API_SERVER}/api/v1/common/sendcustomermail`;
const SEND_CLIENTMAIL_URI2 = `${process.env.REACT_APP_API_SERVER}/api/v1/common/sendclientmail2`;
const UPDATE_CUSTOMER_REFERENCE_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/trackmybookings/updateCustomerReference`
const UPDATE_FINANCE_INFORMATION_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/entity/update/financedetails`
const UPDATE_LOGISTIC_INFORMATION_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/entity/update/logisticdetails`
const ADD_DOCUMENT_URL = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/adddocumenttypes`
const UPDATE_DOCUMENT_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/trackmybookings/update/documents`
const UPDATE_PHONE_NUMBER_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/trackmybookings/updatePhoneNumber`
const DELETE_BOOKING_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/trackmybookings/delete/`
const TRIGGER_SB_ACTION = (bookingId) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/trigger/sb/${bookingId}`;
const GET_BEST_MATCH4ENQUIRY = `${process.env.REACT_APP_API_SERVER}/api/v1/contracts/get/contract/for/enquiry`;
const GET_QUOTATION_DOCUMENT_URI = (quoteNum) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/getQuotationDocument/${quoteNum}`;
const GET_QUOTATION_DOCUMENT_URI1 = (quoteNum) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/getQuotationDocument1/${quoteNum}`;
const GET_QUOTATION_DOCUMENT_URI2 = (quoteNum) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/getQuotationDocument2/${quoteNum}`;
const GET_CAF_DOC_URI = (CafId) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/getCreditDocument/${CafId}`;
const GET_CAF_FORM_URI = (CafId) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/getCreditFormDoc/${CafId}`;
const QUOTATIONS_SHEET_IMPORT_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/import`;
const GET_ENQUIRYSTATUS_URI = (id) => `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/setenquirystatus/${id}`;
const DELETE_QUOTE_URI = (quoteNum) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/delete/${quoteNum}`;
const GET_FCL_CARRIER_LIST_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/dataList/getFCLCarrierList`;
const GET_P44_FCL_CARRIER_LIST_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/dataList/getP44FCLCarrierList`;
const CHECK_DRIVER_STATUS_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/trackmybookings/checkDriverStatus/`
const END_TRIP_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/trackmybookings/endTrip/`
const ENTITY_DETAILS = `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/`
const VERIFIED_VENDOR_DETAILS = `${process.env.REACT_APP_API_SERVER}/api/v1/entity/get/verifiedvendors`
const GET_DOCUMENT_TYPE_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/getdocumenttypes`
const UPDATE_OUTCOME_STATUS_URI = (id) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/quotes/updateOutCome/${id}`;

const GETLCLSCHEDULESMASTER = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/getschedules/lcl`;

const UPDATESCHEDULEDEVIATIONS = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/deviations/update`;
const UPLOADCUSTOMSCLEARENCEFILES = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/customs/upload`;
const UPLOADADDITIONALDOCUMENTFILES = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/additionaldocument/upload`;
const CHECKUNLOC = `${process.env.REACT_APP_API_SERVER}/api/v1/common/checkunloc`;
const MENUCOUNT =
  `${process.env.REACT_APP_API_SERVER}/api/v1/common/getcount/` +
  Utils.getCurrentDateString();
const EDITDSR = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/edituploaddsr`;
const CHECKPHNUMBER = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/checkphnumber`;
const UPDATETRACKING = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/updatetracking`;
const STARTTRIP = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/starttrip`;
const ADDCUSTOMDETAILS = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/customdetails`;
const GETPROJECT44ID = (id) => `${process.env.REACT_APP_API_SERVER}/api/v1/trackmybookings/getByBookingId/${id}`;
const GETINTERMOID = (id) => `${process.env.REACT_APP_API_SERVER}/api/v1/trackmybookings/getintermo/${id}`;
const GETPROJECT44 = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/getproject44`;
const GETDPWORLD = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/getdpworld`;
const GETINTERMO = (id) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/getintermo/${id}`;

const CARTINGORDER = (id) =>
  `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/cartingorder/acknowledge/${id}`;
const GET_APP_VERSION = `${process.env.REACT_APP_API_SERVER}/api/v1/common/getAppVersion`
const GET_PP_SCHEDULES = `${process.env.REACT_APP_API_SERVER}/api/v1/ppschedules/apiP44Schedules`
const verifySbNo = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/verifySbNo`;
const getIcegateData = `${process.env.REACT_APP_API_SERVER}/api/v1/common/getIcegateData`;
const GET_COI_Generation = `${process.env.REACT_APP_API_SERVER}/api/v1/securenow/apiCoiGeneration`
const GET_Invoice_File = `${process.env.REACT_APP_API_SERVER}/api/v1/securenow/apiGetInvoiceFilepath`
const GET_COI_Premium = `${process.env.REACT_APP_API_SERVER}/api/v1/securenow/apiCoiPremium`
const GET_SB_BE_NO =  (id,type) =>`${process.env.REACT_APP_API_SERVER}/api/v1/common/getSbAndBeNo/${id}/${type}`

export default class Api {
  static H(headers = {}) {
    let h = { ...headers };
    let Entity = "ALL";
    let Branch = "ALL";
    // console.log("kkk", loginStore.isClient());
    if (loginStore.isClient()) {
      Entity = configStore.currentSelectedEntity?.entityId ; 
      Branch = configStore.currentSelectedBranch?.branchId ; 
      h["BranchList"] = JSON.stringify(configStore.currentSelectedEntity?.branches);
    }
    h["Aguser"] = loginStore.email;
    h["Role"] =
      loginStore.userRec && loginStore.userRec.aguserRec
        ? loginStore.userRec.aguserRec.role
        : "none";
    h["Current-Entity"] = Entity;
    h["Current-Branch"] = Branch;
    // h["BranchList"] = Entity
    h["Content-Type"] = "application/json";
    h["Timestamp"] = Date.now();
    h["agtoken"] = "310c3d1d-85a3-4a8d-93ad-1709c7c7cca2";
    h["Devicetoken"] = localStorage.getItem("devicetoken");
    return h;
  }
  static H1(headers = {}) {
    let h = { ...headers };
    let Entity = "ALL";
    let Branch = "ALL";
    console.log("kkk", loginStore.isClient());
    if (loginStore.isClient()) {
      Entity = configStore.currentSelectedEntity.entityId ; 
      Branch = configStore.currentSelectedBranch.branchId ; 
      h["BranchList"] = JSON.stringify(configStore.currentSelectedEntity?.branches);
      // h["BranchList"] = '';
    }
    h["Aguser"] = loginStore.email;
    h["Role"] = loginStore.userRec.aguserRec
      ? loginStore.userRec.aguserRec.role
      : "none"; 
      h["Current-Entity"] = Entity;
      h["Current-Branch"] = Branch;
      // h["Current-Entity"] = '';
      // h["Current-Branch"] = '';
      h["BranchList"] = Entity;
    h["Timestamp"] = Date.now();
    h["Devicetoken"] = localStorage.getItem("devicetoken");
    return h;
  }
  static fetch(url, datacallback = (f) => f, errcallback = (f) => f) {
    let entity = "ALL";
    if (loginStore.isClient()) entity = configStore.currentSelectedEntity?.entityId;
    const requestOptions = {
      headers: Api.H(),
    };
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        //console.log('Url: ' + url + ' Ret=', JSON.stringify(data))
        if (data.error) {
          if (data.error != "No Error" || data.error != "Invalid Token") {
            if (data.ajvErrors) {
              configStore.setModalMessage(data.error, data.ajvErrors);
              if (errcallback) {
                window.location.replace('/')
              }
            } else {
              configStore.setModalMessage(data.error);
              if (errcallback) {
                window.location.replace('/')
              }
            }
          }
        } else {
          datacallback(data);
        }
      })
      .catch((err) => {
        if (err != "No Error" || err != "Invalid Token") {
          console.log("Communication Error. Url: " + url + " Err=", err);
          configStore.setModalMessage(
            "Server is not running. Please check browser console for specific error."
          );
        }
      });
  }
  static async fetch1(url, datacallback = (f) => f, errcallback = (f) => f) {
    let entity = "ALL";
    if (loginStore.isClient()) entity = configStore.currentSelectedEntity?.entityId;
    const requestOptions = {
      headers: Api.H(),
    };
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        //console.log('Url: ' + url + ' Ret=', JSON.stringify(data))
        if (data.error) {
          if (data.error != "No Error" || data.error != "Invalid Token") {
            if (data.ajvErrors) {
              configStore.setModalMessage(data.error, data.ajvErrors);
            } else {
              configStore.setModalMessage(data.error);
            }
          } else {
            localStorage.removeItem("checkcount");
          }
        } else {
          datacallback(data);
        }
      })
      .catch((err) => {
        if (err != "No Error" || err != "Invalid Token") {
          console.log("Communication Error. Url: " + url + " Err=", err);
          configStore.setModalMessage(
            "Server is not running. Please check browser console for specific error."
          );
        }
      });
  }
  static async fetch2(url, datacallback = (f) => f, errcallback = (f) => f) {
    let entity = "ALL";
    if (loginStore.isClient()) entity = configStore.currentSelectedEntity.entityId;
    const requestOptions = {
      headers: Api.H(),
    };
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        //console.log('Url: ' + url + ' Ret=', JSON.stringify(data))
        if (data.error) {
          if (data.error != "No Error" || data.error != "Invalid Token") {
            if (data.ajvErrors) {
              configStore.setModalMessage(data.error, data.ajvErrors);
            } else {
              configStore.setModalMessage(data.error);
            }
          } else {
            localStorage.removeItem("checkcount");
          }
        } else {
          // console.log("lkn" , data);
          datacallback(data);
        }
      })
      .catch((err) => {
        if (err != "No Error" || err != "Invalid Token") {
          console.log("Communication Error. Url: " + url + " Err=", err);
          configStore.setModalMessage(
            "Server is not running. Please check browser console for specific error."
          );
        }
      });
  }
  static postOrPut(method, url, payload, callback, errCallback) {
    let entity = "ALL";
    console.log('lklklkl',configStore.currentSelectedEntity)
    if (loginStore.isClient()) entity = configStore.currentSelectedEntity.entityId;
    const requestOptions = {
      method: method,
      headers: Api.H(),
      body: JSON.stringify(payload),
    };
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {

        // console.log("Url: " + url + " Ret=", JSON.stringify(data) , "ll"-errCallback);
        if (data.error) {
          if (data.error != "No Error" || data.error != "Invalid Token") {
            if (data.ajvErrors) {
              configStore.setModalMessage(data.error, data.ajvErrors);
              if (errCallback) {
                errCallback(false)
              }
            } else {
              if (data.error !== "Error: No recipients defined") {
                configStore.setModalMessage(data.error);
              }
              if (errCallback) {
                errCallback(false)
              }
            }
          }
        } else {
          callback(data);
        }
      })
      .catch((err) => {
        if (err != "No Error" || err != "Invalid Token") {
          console.log("Communication Error. Url: " + url + " Err=", err);
          configStore.setModalMessage(
            "Server is not running. Please check browser console for specific error."
          );
          if (errCallback) {
            errCallback(false)
          }
        }
      });
  }
  static post(url, payload, callback = (f) => f, errCallback) {
    Api.postOrPut("POST", url, payload, callback, errCallback);
  }
  static put(url, payload, callback = (f) => f, errCallback) {
    console.log("put called ...");
    Api.postOrPut("PUT", url, payload, callback, errCallback);
  }

  //================================================================
  static loadCountryList() {
    fetch(GET_COUNTRYLIST_URI, { headers: Api.H() })
      .then((res) => res.json())
      .then((data) => {
        ServerLists.setCountryList(data);

        console.log("GOT LIST OF COUNTRIES =", ServerLists.countryList.length);
      })
      .catch((err) => {
        console.log("Url: " + GET_COUNTRYLIST_URI + " Err=", err);
      });
  }
  static async getRolesPermissions() {
    await fetch(GET_ROLES_PERMISSIONS, { headers: Api.H() })
      .then((res) => res.json())
      .then((data) => {
        ServerLists.setRolePermissionsList(data.roles);
        configStore.homeUsersList = data.homeTabUsers
        // console.log("GOT LIST OF user permission =", data);
      })
      .catch((err) => {
        console.log("Url: " + GET_ROLES_PERMISSIONS + " Err=", err);
      });
  }
  static getQuotes(callback = (f) => f) {
    Api.fetch(GET_QUOTES_URI, (data) => {
      quoteStore.setQuoteList(data);
      callback(data.quotesList);
    });
  }
  static getQuote(id) {
    Api.fetch(`${GET_QUOTE_URI}/${id}`, setCommonState);
  }

  static getretrunQuote(id, callback) {
    Api.fetch(`${GET_QUOTE_URI}/${id}`, callback);
  }

  static async quoteregenerate(id) {
    await Api.fetch(`${GET_QUOTE_URI}/regenerate/${id}`, (data) => {
      console.log(data);
      if (data.data.length > 0) {
        Api.fetch(`${GET_QUOTE_URI}/${data.data[0]}`, setCommonState);
        configStore.disableShipmentType = "EDIT";
        configStore.quoteCurrentView = "edit";
        AgmodalStore.modalPage = "Quotev3";
        AgmodalStore.pageVisible = true;
      }
    });
  }
  static async getAppVersion(){
    try {
      const response = await fetch(GET_APP_VERSION, { headers: Api.H() });
      const data = await response.json();
      console.log("DATA: ", data);
      return data;
    } catch (error) {
      console.log("Url: " + GET_APP_VERSION + " Err=", error);
    }

  }

  static cloneQuote(id) {
    Api.fetch(`${GET_QUOTE_URI}/${id}`, (quote) => {
      quote._id = undefined;
      quote.quoteData.status = "DRAFT";
      quote.quoteData.entityId = "";
      quote.quoteData.quotationNum = "";
      quote.quoteData.customerEmail = "";
      quote.quoteData.customerReferenceNum = "";
      quote.quoteData.sender = "";
      quote.quoteData.isFromEnquiry = false;
      quote.quoteData.cargoReadinessDate = "";
      quote.quoteData.validUpto = "";
      quote.quoteData.quoteStatus = "DRAFT";
      quote.status = "DRAFT";
      quote.quoteData.hasToShowDummySchedule = false
      quote.quoteData.procurement = "";
      if(quote.quoteData.multicharge != undefined && quote.quoteData.multicharge.length>0){
        for (let i = 0; i < quote.quoteData.multicharge.length; i++) {
          quote.quoteData.multicharge[i]['procurement'] = ""
        }
      }
      delete quote.decline_reason;
      delete quote.tsDeclineBy;
      delete quote.tsDeclineOn;
      delete quote.LostReason;
      delete quote.tsLostBy;
      delete quote.tsLostOn;
      delete quote.InvalidReason;
      delete quote.tsInvalidBy;
      delete quote.tsInvalidOn;
      delete quote.tsWonBy;
      delete quote.tsWonOn;
      delete quote.decline_reason;
      delete quote.quoteData.isfromAgragaEnquiry
      delete quote.quoteData.checkAgCustomer
      delete quote.quoteData.countryFromEnquiry
      delete quote._tbaid
      setCommonState(quote);
    });
  }

  static setsaveQuote(status,i){
    console.log("INSIDE SAVE QUOTE");
      const getmaxdate = (a1) => {
        let r = [];
        for (let i = 0; i < a1.length; i++) {
            r.push(Number(`${a1[i].split("-")[0]}${a1[i].split("-")[1]}${a1[i].split("-")[2]}`))
        }
        r = r.sort((a, b) => {
            return a - b;
        })
        r = r[r.length-1].toString()
        return `${r.slice(0, 4)}-${r.slice(4, 6)}-${r.slice(6, 8)}`
      }
      const removemutitc = (l) =>{
        l = l.split('\n')
        let k = []
        for (let j = 0; j < l.length; j++) {
          if(l[j].trim().length>0 && l[j].toLowerCase().indexOf('additional days will incur detention charges as per applicable rates.')==-1){
            k.push(l[j])
          }
        }
        if(k.length>0){
          return k.join('\n')
        }else{
          return ""
        }
      }
      quoteDataRec.customTC = removemutitc(quoteDataRec.customTC)
    if(quoteDataRec.multicharge!=undefined && quoteDataRec.multicharge.length==1){
      quoteDataRec.multicharge[0].customTC = removemutitc(quoteDataRec.multicharge[0].customTC)
      quoteDataRec.carrier=quoteDataRec.multicharge[0].carrier
      quoteDataRec.airline=quoteDataRec.multicharge[0].airline
      quoteDataRec.cargoReadinessDate = quoteDataRec.multicharge[0].cargoReadinessDate
      quoteDataRec.validUpto = quoteDataRec.multicharge[0].validUpto
      quoteDataRec.transitTime = quoteDataRec.multicharge[0].transitTime
      quoteDataRec.procurement = quoteDataRec.multicharge[0].procurement
      if (quoteDataRec.shipmentType.indexOf("FCL") >= 0) {
        let l = quoteDataRec.multicharge[0].customTC 
        if(quoteDataRec.multicharge[0].destinationFreeTime!=undefined && quoteDataRec.multicharge[0].destinationFreeTime > 0){
          quoteDataRec.customTC = `Destination free time at ${quoteDataRec.finalPlaceOfDelivery} Port is ${quoteDataRec.multicharge[0].destinationFreeTime} days. Additional days will incur detention charges as per applicable rates.`+(l.trim().length>0?`\n${l}`:"")
        }else{
          quoteDataRec.customTC = quoteDataRec.multicharge[0].customTC
        }
      }else{
        quoteDataRec.customTC = quoteDataRec.multicharge[0].customTC
      }
      quoteDataRec.destinationFreeTime = quoteDataRec.multicharge[0].destinationFreeTime
    }else{
      if(quoteDataRec.multicharge!=undefined && quoteDataRec.multicharge.length>1){
        let a = []
        for (let i = 0; i < quoteDataRec.multicharge.length; i++) {
          quoteDataRec.multicharge[i].customTC = removemutitc(quoteDataRec.multicharge[i].customTC)
          a.push(quoteDataRec.multicharge[i].validUpto)
          if (quoteDataRec.shipmentType.indexOf("FCL") >= 0) {
            let l = quoteDataRec.multicharge[i].customTC 
            if(quoteDataRec.multicharge[i].destinationFreeTime!=undefined && quoteDataRec.multicharge[i].destinationFreeTime > 0){
              quoteDataRec.multicharge[i].customTC = `Destination free time at ${quoteDataRec.finalPlaceOfDelivery} Port is ${quoteDataRec.multicharge[i].destinationFreeTime} days. Additional days will incur detention charges as per applicable rates.`+(l.trim().length>0?`\n${l}`:"")
            }
          }
        }
        if(a.length>0){
          quoteDataRec.validUpto = getmaxdate(a)
        }
      }
    }
    let chargesList;
    if(i==1){
      cargoStore.cargoList = cargoStore.cargoList.map(calcCargoDetails);
    }
    cargoStore.calculateCargoTotals();
    if (quoteDataRec.shipmentType.indexOf("LCL") >= 0) {
      chargesStore1.calculateLCLChargesTotals();
      chargesList = chargesStore1.list;
    } else if (quoteDataRec.shipmentType.indexOf("FCL") >= 0) { 
      if(quoteDataRec.multicharge!=undefined && quoteDataRec.multicharge.length==1){
        if(i==0){
          let valid = fclChargesStore.calculateChargesTotals(quoteDataRec.emptycontainerweight,"quote");
          if(valid === false){
            return [false]
          }
        }else{
          fclChargesStore.calculateChargesTotals(quoteDataRec.emptycontainerweight);
        }    
        chargesList = fclChargesStore.list;
      }else{
        for (let i = 0; i < quoteDataRec.multicharge.length; i++) {
          let list = "list"+(i==0?"":i+1)
          if(i==0){
            let valid = fclChargesStore.calculateChargesTotalsindex(list,quoteDataRec.emptycontainerweight,"quote");
            if(valid === false){
              return [false]
            }
          }else{
            fclChargesStore.calculateChargesTotalsindex(list,quoteDataRec.emptycontainerweight);
          }
          quoteDataRec.multicharge[i]['chargesList'] = fclChargesStore[list]
        }
      }
    } else if (quoteDataRec.shipmentType.indexOf("Air") >= 0) {
      quoteDataRec.originAirport = quoteDataRec.portOfLoading;
      quoteDataRec.destinationAirport = quoteDataRec.portOfDischarge;
      if(quoteDataRec.multicharge!=undefined && quoteDataRec.multicharge.length==1){
        airChargesStore.calculateChargesTotals();
        chargesList = airChargesStore.list;
      }else{
        for (let i = 0; i < quoteDataRec.multicharge.length; i++) {
          let list = "list"+(i==0?"":i+1)
          airChargesStore.calculateChargesTotalsindex(list);
          chargesList = airChargesStore.list;
          quoteDataRec.multicharge[i]['chargesList'] = airChargesStore[list]
        }
      }
    }
    quoteDataRec.sender = loginStore.userRec.email;
    const e = quoteDataRec;
    e.portOfReceipt =
      e.portOfReceipt !== "" ? e.portOfReceipt : e.portOfLoading;
    e.portOfDischarge =
      e.portOfDischarge !== "" ? e.portOfDischarge : e.finalPlaceOfDelivery;
      let quoteSummary = {}
      if(i==0){
        quoteSummary = {
          sender: e.sender,
          shipmentType: e.shipmentType,
          shipmentScope: e.shipmentScope,
          origin: e.origin,
          destination: e.destination,
          customerEmail: e.customerEmail,
          entityId: e.entityId,
        };
      }else{
        quoteSummary = {
          sender: e.sender,
          shipmentType: e.shipmentType,
          shipmentScope: e.shipmentScope,
          origin: e.origin,
          destination: e.destination,
          customerEmail: e.customerEmail,
          gst: e.gst,
          entityId: e.entityId,
        };
      }
    
    if(e.multidest!=undefined && e.multidest.length>1){
      for (let i = 0; i < e.multidest.length; i++) {
        if(i==0){
          quoteSummary.destination = e.multidest[i]['destination']
        }else{
            let k = i+1
            quoteSummary['destination'+k] = e.multidest[i]['destination']
        }
      }
    }else{
      quoteSummary.destination = e.destination
    }
    if(i!=1){
      if (quoteDataRec.isfromAgragaEnquiry == 'Yes') {
        quoteDataRec.status = 'DRAFT'
      }
    }
    let quoteDocument = {
      quoteSummary,
      quoteData: quoteDataRec,
      containerList: containerStore.list,
      cargoList: cargoStore.cargoList,
      cargoTotals: cargoStore.totals,
      chargesList: chargesList,
      status,
    };
    return [true,quoteDocument]
  }

  static saveQuote(status) {
    let k = Api.setsaveQuote(status,0)
    if(k[0]==false){
      return
    }
    let quoteDocument = k[1]
    Api.post(POST_QUOTE_URI, quoteDocument, (data) => {
      quoteDataRec.quotationNum = data.quoteData.quotationNum;
      //console.log(JSON.stringify(data))
      if (data.quoteData.status !== 'DRAFT(Enquiry)') {
        // if(data.quoteData.isfromAgragaEnquiry != 'Yes'){
        configStore.setModalMessage(
          [<QuoteSavedMessage quotationRefNum={data.quoteData.quotationNum} />],
          [],
          false
        );
      } else {
        configStore.setModalMessage(
          [<QuoteSavedMessage1 quotationRefNum={data.quoteData.quotationNum} />],
          [],
          false
        );
      }
      configStore.isDisable = false;
    });
  }
  static saveQuote1(status) {
    let k = Api.setsaveQuote(status,1)
    let quoteDocument = k[1]
    const e = quoteDataRec;
    if(e.isFromEnquiry!=undefined && e.isFromEnquiry && e.isfromAgragaEnquiry=='Yes'){
      EnquiriesApi.getEnquiry(e.quotationNum)
    }
    console.log("contained in cargo totals", quoteDocument.cargoTotals);
    Api.post(POST_QUOTE_URI, quoteDocument, (data) => {
      quoteDataRec.quotationNum = data.quoteData.quotationNum;
      if (data.quoteData.status !== 'DRAFT(Enquiry)') {
        configStore.setModalMessage(
          [<QuoteSavedMessage quotationRefNum={data.quoteData.quotationNum} />],
          [],
          false
        );
      } else {
        configStore.setModalMessage(
          [<QuoteSavedMessage1 quotationRefNum={data.quoteData.quotationNum} />],
          [],
          false
        );
      }

    });
  }
  static saveQuote2(status) {
    let k = Api.setsaveQuote(status,1)
    let quoteDocument = k[1]
    const e = quoteDataRec;

    if(e.isFromEnquiry!=undefined && e.isFromEnquiry && e.isfromAgragaEnquiry=='Yes'){
      EnquiriesApi.getEnquiry(e.quotationNum)
    }
    
    let CustomerName;


    Api.post(POST_QUOTE_URI, quoteDocument, (data) => {
      console.log(enquiryStore,'enquiryStore..');
      quoteDataRec.quotationNum = data.quoteData.quotationNum;
      let hr = Utils.getDateDifference(Number(data.tsUpdatedOn),Number(enquiryStore.current.tsCreatedOn)).hours
      let mm = Utils.getDateDifference(Number(data.tsUpdatedOn),Number(enquiryStore.current.tsCreatedOn)).minutes
      let to
      if(data.quoteData.entityId === "TBA"){
        // if(data.quoteData.gst == 'GSTTBA000000000'){
        to = `${enquiryStore.current.tsCreatedBy}`;
        CustomerName = 'Yet To Be Assigned'
      }else{
        // to = `${S_Email} ,${C_Email}`;
        // CustomerName = entityStore.getCustomer4gstOnly(quoteDataRec.gst)
        EntityApi.getEntity(e.entityId,entityData=>{
          to = Utils.getRespectiveMember(entityData.customer)
          CustomerName = entityData.entityName
        })
      }
        setTimeout(()=>{
        let from = `Agraga <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
        let subject = 'Quotation Creation - '
        let message = `Hi Team,<br />
         <br /> 
         &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Quotation for the required enquiry has been created.<br />
         &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Quotation ID : ${data.quoteData.quotationNum}.<br />
         &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Entity Name :${CustomerName}.<br />
         &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Origin: ${e.origin}.<br />`
         if(data.quoteData.multidest!=undefined && data.quoteData.multidest.length>1){
          for (let i = 0; i < data.quoteData.multidest.length; i++) {
              message=message+`&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;        Destination${i+1}: ${data.quoteData.multidest[i]['destination']}.<br />`
          }
      }else{
      message=message+`&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;        Destination: ${e.destination}.<br />`
      }
         message=message+`&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Shipment Type: ${e.shipmentType}.<br />
         &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Scope : ${e.shipmentScope}.<br />
         &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Enquiry Created On : ${Utils.formatToDateTime(Number(enquiryStore.current.tsCreatedOn))}.<br />
         &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Enquiry Created By : ${enquiryStore.current.tsCreatedBy}.<br />
         &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Quotation Created On : ${Utils.formatToDateTime(Number(data.tsUpdatedOn))}.<br />
         &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Quotation Created By : ${data.tsUpdatedBy}.<br />
         
         <br />
         This Quotation has been created with in ${(hr > 0) ? hr : "0"} hrs:${(mm > 0) ? mm : "0"}minutes.
         <br/>
          A quotation for the required enquiry has been created. Please <a href=${window.location.href}quotation/edit/${data.quoteData.quotationNum} target="_blank">[Click Here]</a> to access them.
         <br/>
         Team Agraga<br />`
            Api.sendClientMailwithoutMessage(from, to,subject, message)
      }, 3000)


      configStore.setModalMessage(
        [<QuoteSavedMessage quotationRefNum={data.quoteData.quotationNum} />],
        [],
        false
      );
      configStore.isDisable = false;
    });
  }
  static quoteAccountTransformHelper(quote) {
    let q = JSON.parse(JSON.stringify(quote))
    /*console.log('\n\n\n###################\nCHARGES LENGTHS=',
      q.chargesList.length, q.shipperchargesList.length, q.consigneechargesList.length, '\n##########'
    )*/
    for (let i = 0; i < q.chargesList.length; i++) {
      let e = q.chargesList[i]
      e.onaccount = 'On Your Account'
    }
    if (q.shipperchargesList && q.shipperchargesList.length) {
      for (let i = 0; i < q.shipperchargesList.length; i++) {
        let e = q.shipperchargesList[i]
        e.onaccount = `Shipper's Account`
        q.chargesList.push(e)
      }
    }
    if (q.consigneechargesList && q.consigneechargesList.length) {
      for (let i = 0; i < q.consigneechargesList.length; i++) {
        let e = q.consigneechargesList[i]
        e.onaccount = `Consignee's Account`
        q.chargesList.push(e)
      }

    }
    return q
  }
  static saveQuoteNoTransform(rec) {
    let quoteDocument = Api.quoteAccountTransformHelper(rec.quote)
    if(quoteDocument.isFBA==true){
      quoteDocument.quoteData.fba='Yes'
    }
    let airline = airlineCodes.find(e => e.code == rec.iata)
    if (airline) {
      quoteDocument.quoteSummary.shipmentType = 'Air'
      quoteDocument.quoteData.airline = airline.name
    } else if (quoteDocument.quoteData.shipmentType.startsWith('FCL')) {
      quoteDocument.quoteSummary.shipmentType = quoteDocument.quoteData.shipmentType
      if (rec.dummyContract.oih && rtpStore.shipperpda == ' ') {
        quoteDocument.chargesList = quoteDocument.chargesList.filter(e => e.chargeDescription != 'Origin Inland Haulage')
      }
      //quoteDocument.quoteData.origin = quoteDocument.quoteSummary.origin
    }

    quoteDocument.status = 'DRAFT'
    quoteDocument.quoteStatus = 'DRAFT'
    quoteDocument.quoteData.status = 'DRAFT'
    quoteDocument.quoteData.procurement = ""
    console.log("INSIDE SAVE QUOTE");
    console.log("\n\n\n\n\nSSSSSSSSSSSSSSSSSSS",quoteDocument ,  quoteDocument.quoteSummary.shipmentType, quoteDocument.quoteData.shipmentType,quoteDocument.quoteData.origin);
    Api.post(POST_QUOTE_URI, quoteDocument, (data) => {
      quoteDataRec.quotationNum = data.quoteData.quotationNum;
      let schedules = [...rec.dummyContract.schedules]
      for (let i = 0; i < schedules.length; i++) {
        schedules[i].fromPricing = 'true'
      }
      if (schedules && schedules.length) {
        MonApi.insertOne('Schedules', {
          _id: data.quoteData.quotationNum + 'C',
          schedules: schedules
        })
      }
      //console.log(JSON.stringify(data))
      configStore.setModalMessage(
        [<PricingQuoteSaved quotationRefNum={data.quoteData.quotationNum} />],
        [],
        false
      );
    });
  }
  static async saveQuoteAndContract(rec, isDummy,callback) {
    let quoteDocument = Api.quoteAccountTransformHelper(rec.quote)
    let airline = airlineCodes.find(e => e.code == rec.iata)
    if (airline) {
      quoteDocument.quoteSummary.shipmentType = 'Air'
      quoteDocument.quoteData.airline = airline.name
    } else if (quoteDocument.quoteData.shipmentType.startsWith('FCL')) {
      if (rec.dummyContract.oih && rtpStore.shipperpda == ' ') {
        quoteDocument.chargesList = quoteDocument.chargesList.filter(e => e.chargeDescription != 'Origin Inland Haulage')
      }
    }
    quoteDocument.quoteData.procurement = "pricing@agraga.com"
    quoteDocument.status = 'DRAFT'
    console.log("INSIDE SAVE QUOTE AND Contract");
    console.log("contained in cargo totals", quoteDocument.cargoTotals)
    let data = await MonApi.apost(POST_QUOTE_URI, quoteDocument)

    let ret = await MonApi.apost(`${process.env.REACT_APP_API_SERVER}/api/v1/quotes/finalize/quote/for/pricing`,
      { quoteNum: data.quoteData.quotationNum })
    if (isDummy) {
      await MonApi.updateOne('Contracts', { _id: ret.contract._id }, { hasToShowDummySchedule: true })
    }
    let schedules = [...rec.dummyContract.schedules]
    for (let i = 0; i < schedules.length; i++) {
      schedules[i].fromPricing = 'true'
    }
    if (schedules && schedules.length) {
      await MonApi.insertOne('Schedules', {
        _id: ret.contract._id,
        schedules: schedules
      })
    }
    console.log('\n\n\n\n\nRETURN FROM CONTACT=', ret)
    if(callback){
      callback(ret.contract._id);
      return;
    }
    configStore.setModalMessage(
      [<PricingContractSaved contractNum={ret.contract._id} />],
      [],
      false
    );
  }
  static async sendMailCommon(url, callback = (f) => f) {
    // assumed to be invoked only from QuoteViewMain
    configStore.setModalMessage("Sending Email ...");
    const agUserList = await AguserApi.getAguserList();
    console.log(toEmailStore.emails, ccEmailStore.emails);
    let checkedToMailIds = []
    let checkedToMailIdsvalid = []
    let checkedCCMailIds = []
    let checkedCCMailIdsvalid = []
    for (let i = 0; i < toEmailStore.emails.length; i++) {
      let email = toEmailStore.emails[i]
      if(!agUserList.map(({ email }) => email).includes(email)){
        checkedToMailIds.push(email)
      }else{
        checkedToMailIdsvalid.push(email)
      }
    }
    for (let i = 0; i < ccEmailStore.emails.length; i++) {
      let email = ccEmailStore.emails[i]
      if(!agUserList.map(({ email }) => email).includes(email)){
        checkedCCMailIds.push(email)
      }else{
        checkedCCMailIdsvalid.push(email)
      }
    }
    
    // let checkedToMailIds = toEmailStore.emails.filter(
    //   (email) =>
    //     Utils.isEmail(email) &&
    //     (email.split("@")[1] === "agraga.com" ||
    //       agUserList.map(({ email }) => email).includes(email))
    // );
    // let checkedCCMailIds = ccEmailStore.emails.filter(
    //   (email) =>
    //     Utils.isEmail(email) &&
    //     (email.split("@")[1] === "agraga.com" ||
    //       agUserList.map(({ email }) => email).includes(email))
    // );
    let rm = EntityV2Store.EntityRec.customer.crossBorder.relationshipManager
    let cs = EntityV2Store.EntityRec.customer.crossBorder.customerService
    if(rm!=undefined && rm.trim().length!=0){
      checkedCCMailIdsvalid.push(rm)
    }
    if(cs!=undefined && cs.trim().length!=0){
      checkedCCMailIdsvalid.push(cs)
    }
    if((rm==undefined && cs==undefined) || (rm.trim().length==0 && cs.trim().length==0)){
      checkedCCMailIdsvalid.push("pat@agraga.com")
    }
    let quoteTemplate = ReactDOMServer.renderToString(
      <QuoteViewv3 isMailBody={true} />
    );
    quoteTemplate = quoteTemplate.replace(
      "Sales Person",
      "Relationship Manager"
    );
    console.log();
    const emailDocument = {
      fromEmail : EntityV2Store.EntityRec.customer.crossBorder.customerService,
      quoteNum: quoteDataRec.quotationNum,
      toEmails: checkedToMailIdsvalid,
      ccEmails: checkedCCMailIdsvalid,
      reqdata: quoteDataRec.reqdata,
      quoteTemplate,
    };
    if (checkedToMailIds.length > 0) {
      configStore.setModalMessage(`No Valid TO EmailIds Found.(${checkedToMailIds.join(',')})`);
      callback(false)
      return;
    }
    if (checkedCCMailIds.length > 0) {
      configStore.setModalMessage(`No Valid CC EmailIds Found.(${checkedCCMailIds.join(',')})`);
      callback(false)
      return;
    }
    console.log(emailDocument);
    Api.post(url, emailDocument, (data) => {
      configStore.setModalMessage(`Email sent to ${data.message.accepted}`);
      configStore.quoteCurrentView = 'list'
    });
    AgmodalStore.apiCall = true;
    AgmodalStore.pageVisible = false;
    configStore.quoteCurrentView = 'list'
  }
  static async sendMail2Common(url) {
    // assumed to be invoked only from QuoteViewMain

    console.log("Sending Email ...");
    // const agUserList = await AguserApi.getAguserList();
    let checkedToMailIds = toEmailStore.emails
    // toEmailStore.emails.filter(
    //   (email) =>
    //     Utils.isEmail(email) &&
    //     (email.split("@")[1] === "agraga.com" ||
    //       agUserList.map(({ email }) => email).includes(email))
    // );


    let checkedCCMailIds = ccEmailStore.emails
    // ccEmailStore.emails.filter(
    //   (email) =>
    //     Utils.isEmail(email) &&
    //     (email.split("@")[1] === "agraga.com" ||
    //       agUserList.map(({ email }) => email).includes(email))
    // );
    let quoteTemplate = ReactDOMServer.renderToString(
      <QuoteViewv3 isMailBody={true} />
    );
    console.log(quoteTemplate,'quoteTemplate');
    quoteTemplate = quoteTemplate.replace(
      "Customer:",
      ""
    )
    quoteTemplate = quoteTemplate.replace(/YET TO BE ASSIGNED/g, "");
    quoteTemplate = quoteTemplate.replace(
      "Sales Person",
      "Relationship Manager"
    );
    console.log();
    const emailDocument = {
      quoteNum: quoteDataRec.quotationNum,
      toEmails: checkedToMailIds,
      ccEmails: checkedCCMailIds,
      reqdata: quoteDataRec.reqdata,
      quoteTemplate,
    };
    if (checkedToMailIds.length === 0) {
      configStore.setModalMessage(`No Valid EmailIds Found.`);
      return;
    }
    Api.post(url, emailDocument, (data) => {
      configStore.setModalMessage(`Email sent to ${data.message.accepted}`);
      configStore.quoteCurrentView = 'list'
    });
    AgmodalStore.apiCall = true;
    AgmodalStore.pageVisible = false;
    configStore.quoteCurrentView = 'list'
  }
  static sendMail() {
    // assumed to be invoked only from QuoteViewMain
    Api.sendMailCommon(POST_EMAIL_URI);
  }
  static send2Mail(callback=(f)=>f) {
    // assumed to be invoked only from QuoteViewMain
    Api.sendMailCommon(POST_EMAIL2_URI,callback);
  }
  static send3Mail() {
    // assumed to be invoked only from QuoteViewMain
    Api.sendMail2Common(POST_EMAIL3_URI);
  }
  static sendMailwithPDF() {
    Api.sendMailCommon(POST_EMAILWITHPDF_URI);
  }

  static getContracts(callback = (f) => f) {
    // Api.fetch(GET_CONTRACTS_URI, (data) => {
    //   console.log("GOT CONTRACTs DATA=", data.length);
    //   contractsStore.list = data;
    //   callback(data)
    // });
  }
  static getContractsdata(id,callback = (f) => f) {
    Api.fetch(GET_CONTRACT_URI(id), (data) => {
      data = new QuoteCalcV2(data).recalc();
      callback(data)
    });
  }

  static getContract(id, callback = (f) => f) {
    Api.fetch(GET_CONTRACT_URI(id), (data) => {
      console.log("GOT CONTRACT DATA=", data._id);
      if(contractsStore.current._id!=undefined && contractsStore.current._id==data._id){
        let l = contractsStore.current.chargesList
        if(l!=undefined){
          for (let i = 0; i < l.length; i++) {
            if(l[i]['chargeDescription']=='Marine Insurance'){
              if(data.chargesList!=undefined){
                data.chargesList.push(l[i])
              }else{
                data.chargesList = [l[i]]
              }
              data.quoteValue = contractsStore.current.quoteValue
              data.quoteValueUSD = contractsStore.current.quoteValue
            }
          }
        }
      }
      data = new QuoteCalcV2(data).recalc();
      console.log("AFTER CALCULATION=", data.quoteValue, data.quoteValueUSD);
      if(JSON.stringify(contractsStore.current)!=JSON.stringify(data)){
        contractsStore.current = data;
      }
      callback(data);
    });
  }
  static getContractv2(id, callback = (f) => f) {
    Api.fetch(GET_CONTRACT_URI(id), (data) => {
      console.log("GOT CONTRACT DATA=", data._id);
      data = new QuoteCalcV2(data).recalc();
      console.log("AFTER CALCULATION=", data.quoteValue, data.quoteValueUSD);
      if(JSON.stringify(contractsStore.current)!=JSON.stringify(data)){
        contractsStore.current = data;
      }
      callback(data);
    });
  }

  static getlastupload(type, callback = (f) => f) {
    Api.fetch(LASTUPLOAD(type), (data) => {
      callback(data);
    });
  }

  static setWonquote(id, callback = (f) => f) {
    Api.post(WON_QUOTES(id), {}, (data) => {
      callback(data);
    });
  }
  static updateQuote(id, callback = (f) => f) {
    Api.fetch(GET_ENQUIRYSTATUS_URI(id), (quote) => {
      console.log(">>>SDDATA", quote)
      callback(quote)
    });
  }
  static setAgragaEnquiryquote(id, callback = (f) => f) {
    Api.post(AGRAGA_ENQUIRY_STATUS(id), {}, (data) => {
      callback(data);
    });
  }
  static setLostQuote(id, reason, callback = (f) => f) {
    Api.post(LOST_QUOTES(id), reason, (data) => {
      callback(data);
    });
  }
  static setInvalidQuote(id, reason, callback = (f) => f) {
    Api.post(INVALID_QUOTES(id), reason, (data) => {
      callback(data);
    });
  }

  static getMergedContract(callback = (f) => f) {
    console.log("ABOUT TO CALL MERGED CONTRACT");
    Api.post(GET_BEST_MATCH4ENQUIRY, enquiryStore.current, (data) => {
      if (!data._id) {
        if(JSON.stringify(contractsStore.current)!=JSON.stringify(data)){
          contractsStore.current = data;
        }
        callback(data);
        configStore.displaySchedules = false;
        return;
      }
      console.log("======================");
      console.log(data._id);
      let newdata = enquiryStore.current.mergeIntoContract(data);
      console.log(
        "AFTER CALCULATION=",
        newdata.quoteValue,
        newdata.quoteValueUSD
      );
      if(JSON.stringify(contractsStore.current)!=JSON.stringify(newdata)){
        contractsStore.current = newdata;
      }
      callback(newdata);
    });
  }
  static createContractFromQuote(id, callback = (f) => f) {
    console.log("ABOUT TO CALL createContractFromQuote");
    Api.post(CREATE_CONTRACT_FROM_QUOTE_URI(id), {}, (data) => {
      console.log("GOT CONTRACT DATA=", data._id);
      callback(data);
    });
  }
  static declineContract(id, reason, callback = (f) => f) {
    console.log("ABOUT TO CALL createContractFromQuote");
    console.log("ABOUT", reason)
    Api.post(DECLINE_CONTRACT(id), reason, (data) => {
      console.log("GOT CONTRACT DATA=", data);
      console.log("GOT CONTRACT DATA=", contractsStore.current);
      if (contractsStore.current.isfromAgragaEnquiry == 'Yes') {
        let toUsers = Utils.getRespectiveMember(EntityV2Store.EntityRec.customer)
        // if(rec.isfromAgragaEnquiry == 'Yes' && rec.gst == 'GSTTBA000000000'){
        //     S_Email = rec.createdBy
        //     C_Email = rec.createdBy
        // }else{
        // S_Email = entityStore.getCustomerSEmail(rec.gst)
        // C_Email = entityStore.getCustomerCEmail(rec.gst)
        // }
        // Api.post(ENQUIRIESURI.POST, enquiryStore.current, (data) => {
        //     console.log("QQQQQ",enquiryStore.current)
        setTimeout(() => {
          let from = `Agraga <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
          let to = toUsers;
          let subject = 'CONTRACT - ' + contractsStore.current._id
          let message = `Hi Team,<br />
                <br />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;        Contract Number:${contractsStore.current._id}.<br />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Shipment Type: ${contractsStore.current.shipmentType}.<br />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Scope : ${contractsStore.current.shipmentScope}.<br />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;       Origin: ${contractsStore.current.origin}.<br />`
                if(contractsStore.current.multidest!=undefined && contractsStore.current.multidest.length>1){
          for (let i = 0; i < contractsStore.current.multidest.length; i++) {
              message=message+`&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;        Destination${i+1}: ${contractsStore.current.multidest[i]['destination']}.<br />`
          }
      }else{
      message=message+`&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;        Destination: ${contractsStore.current.destination}.<br />`
      }
                message = message+`The Quotation created submitted by the customer has been rejected for the below reason: ${reason.reason}.<br />
                <br />
                Team Agraga<br />`

          Api.sendClientMailwithoutMessage1(from, to, subject, message)
        }, 8000)
        // if(callback){
        //     callback();
        // }
        // setTimeout(() => {
        //     configStore.setEnquiryModalMessage(data._id)
        // }, 5000);


        // })
      }
      callback(data);
    });
  }
  static acquireContractLock(id, callback = (f) => f) {
    console.log("ABOUT TO CALL createContractFromQuote");
    Api.post(CREATE_CONTRACT_FROM_QUOTE_URI(id), {}, (data) => {
      console.log("GOT CONTRACT DATA=", data._id);
      callback(data);
    });
  }
  static sendClientMail(from, to, subject, message, modalMessagePrefix = "") {
    // configStore.setModalMessage("Sending Email ...");
    const doc = {
      from,
      to,
      subject,
      message,
    };
    return Api.post(SEND_CLIENTMAIL_URI, doc, (data) => {
      // configStore.setModalMessage(
      //   `${modalMessagePrefix} Email sent to ${data.message.accepted}`
      // );
      routerStore.agusersCurrentView = "list"
    });
  }

  static sendClientMailwithoutMessage(
    from,
    to,
    subject,
    message,
    cc,
    shipType
  ) {
    const doc = {
      from,
      to,
      shipType,
      subject,
      message,
      cc
    };
    Api.post(SEND_CLIENTMAIL_URI, doc, (data) => {
      console.log(data);
    });
  }

  static sendDocumentRequestCustomerMail(doc) {
    Api.post(SEND_CLIENT_DOCUMENTMAIL_URI, doc, (data) => {
      console.log(data);
    });
  }

  static sendVendorMail(doc) {
    Api.post(SEND_VENDOR_MAIL_URI, doc, (data) => {
      console.log(data);
    });
  }
  
  static sendRejectionMail(doc) {
    Api.post(SEND_REJECTION_MAIL_URI, doc, (data) => {
      console.log(data);
    });
  }
  

  static updateBookingDetails(doc) {
    Api.post(UPDATE_BOOKINGS_URI, doc, (data) => {
      console.log(data);
    });
  }

  static sendClientMailwithoutMessage1(
    from,
    to,
    subject,
    message,
    shipmenttype,
    modalMessagePrefix = ""
  ) {
    const doc = {
      from,
      to,
      subject,
      message,
      shipmenttype
    };
    Api.post(SEND_CLIENTMAIL_URI1, doc, (data) => {
      console.log(data);
    });
  }

  static sendClientMailwithoutMessage2(
    from,
    to,
    subject,
    message,
    cc,
    shipmenttype
  ) {
    const doc = {
      from,
      to,
      subject,
      message,
      cc,
      shipmenttype
    };
    Api.post(SEND_CLIENTMAIL_URI2, doc, (data) => {
      console.log(data);
    });
  }

  static sendClientMailMessage(
    from,
    to,
    subject,
    cc,customerService,relationManager,task,userlist
  ) {
    const doc = {
      from,
      to,
      subject,
      cc,
      customerService,relationManager,task,userlist
    };
    Api.post(SEND_CLIENTMAIL_URI3, doc, (data) => {
      console.log(data);
    });
  }

  static isEmailValid(email, callback) {
    Api.fetch(`${VALIDATE_EMAIL_URI}/${email}`, (data) => {
      let isValid = false;
      if (data.custId) {
        if (parseInt(data.custId) > 0) {
          isValid = true;
        }
      }
      callback(isValid);
    });
  }
  static async getUser(email, password, callback = (f) => f) {
    Api.fetch(`${GET_USER4EMAILPASSWORD}/${email}/${password}`, (user) => {
      console.log("Got user = ", user);
      loginStore.userRec = user;
      console.log("value of userRec=", loginStore.userRec);
      callback(user);
      if (!user.userFound)
        configStore.setModalMessage("Invalid username/password");
    });
  }
  static updateQuoteStatus(id, status, callback) {
    const url = `${UPDATE_QUOTE_STATUS}/${id}/${status}`;
    fetch(url, { headers: Api.H() })
      .then((res) => res.json())
      .then((data) => {
        callback(data);
      })
      .catch((err) => {
        console.log("Url: " + url + " Err=", err);
        callback({ error: "" + err });
      });
  }
  static getCustomers() {
    //console.log('inside getCustomers')
    fetch(GET_CUSTOMERS_URI, { headers: Api.H() })
      .then((res) => res.json())
      .then((data) => {
        let listData = data.map((e) => {
          return {
            key: e.gst,
            value: e.entityName,
          };
        });
        listData = ["", ...listData];
        customerStore.list = listData;
        //console.log('Customer list1=', JSON.stringify(customerStore.list))
      })
      .catch((err) => {
        console.log("Url: " + GET_CUSTOMERS_URI + " Err=", err);
      });
  }
  static getUnloc(prefix, callback) {
    prefix?.trim();
    if (prefix.length < 3) {
      callback([]);
      return;
    }
    fetch(`${GET_UNLOC_URI}/${prefix}`, { headers: Api.H() })
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data)) {
          callback(data);
          return;
        }
        throw new Error(data["error"] || data.toString());
      })
      .catch((err) => {
        console.log("Url: " + GET_UNLOC_URI + " Err=", err);
        callback([]);
      });
  }

  static sendEmailAttachment(data,callback){
    Api.post(SEND_FILE_ATTACHEMENT,data,(res)=>{
      if(callback){
        callback(res)
      }
    })
  }

  static async triggerSBAction(id) {
    Api.fetch(TRIGGER_SB_ACTION(id), (data) => {
      console.log(data,"response");
    });
  }
  static async triggerPaymentAction(task) {
    Api.post(`${process.env.REACT_APP_API_SERVER}/api/v1/myactions/trigger/paymentdetails`,task, (data) => {
      console.log(data,"response");
    });
  }

  static getMapUnloc(data, callback) {
    Api.post(GET_MAP_UNLOC_URI, data, (result) => {
      callback(result);
    });
  }

  static getMapUnloc2(data, callback) {
    console.log("XC",data)
    Api.post(GET_MAP_UNLOC_URI1, data, (result) => {
      callback(result);
    });
  }

  static getMapUnloc1(data, callback) {
    Api.post(GET_MAP_UNLOC_URI, data, (result) => {
      callback(result);
    });
  }
  static getppchedules(data,callback) {
    loader.show()
    console.log("DS",data)
    Api.post(GET_PP_SCHEDULES, data, (result) => {
      setTimeout(() => {
        loader.hide();
      }, 1500);
      callback(result.data)

    });
  }
  static createCoiCreation(data,callback) {
    //loader.show()
    console.log("DS",data)
    Api.post(GET_COI_Generation, data, (result) => {
      console.log("results",result.data)
      callback(result.data)

    });
  }

  static CoiPremium(data,callback) {
    //loader.show()
    console.log("DS",data)
    Api.post(GET_COI_Premium, data, (result) => {
      console.log("results",result.data)
      callback(result.data)

    });
  }

  static  getInvoiceData(data,callback) {
    //loader.show()
    console.log("DS1",data)
    Api.post(GET_Invoice_File, data,(result) => {
      console.log("results1",result)
      callback(result)

    });
   
  }
 
  static get4Unloc(unloc, callback) {
    if(unloc!=undefined && unloc.trim().length>0){
      fetch(`${GET_4UNLOC_URI}/${unloc}`, { headers: Api.H() })
      .then((res) => res.json())
      .then((data) => {
        callback(data);
      })
      .catch((err) => {
        console.log("Url: " + GET_UNLOC_URI + " Err=", err);
        callback({});
      });
    }else{
      callback({});
    }
  }
  static async getExchangerates() {
    try {
      const requestOptions = {
        headers: Api.H(),
      };
      let res = await fetch(`${GET_EXCHANGERATES_URI}`, requestOptions)
      let data = await res.json();
      console.log(" the country rate list is ",data);
      if (data.error) {
        console.log(data.error)
      } else {
        data.unshift({
          currency: "INR",
          exchangeRate: 1.0,
        })
        if (JSON.stringify(configStore.currencyList) != JSON.stringify(data)) {
          configStore.currencyList = data;
        }
      }
    } catch (err) {
      console.log("Url: " + GET_EXCHANGERATES_URI + " Err=", err);
      configStore.currencyList = ["INR"];
    }
  }
  static getLiveExchangerates(callback) {
    fetch(`${GET_EXCHANGERATES_URI}`, { headers: Api.H() })
      .then((res) => res.json())
      .then((data) => {
        configStore.currencyList = data;
        configStore.currencyList.unshift({
          currency: "INR",
          exchangeRate: 1.0,
        });
        callback(data)
      })
      .catch((err) => {
        console.log("Url: " + GET_EXCHANGERATES_URI + " Err=", err);
        configStore.currencyList = ["INR"];
      });
  }
  static addCustomer(entityName, gst) {
    let customer = {
      entityName,
      gst,
    };
    Api.post(CREATE_CUSTOMER_URI, customer, (data) => {
      Api.getCustomers();
      configStore.setModalMessage("Customer Added");
    });
  }

  static addTrackBookingsAPI() {
    fetch(TRACK_BOOKINGS_URI, { headers: Api.H(), method: 'POST' })
      .then((res) => res.json())
      .then((data) => {
        trackMyBookingOceanStore.setTrackBooking()
      })
      .catch((err) => {
        console.log("Url: " + + " Err=", err);
      });
  }

  static addTrackBookings(bookings, type, callBack, search) {
    bookings.type = type
    bookings.entityId = configStore.currentSelectedEntity._id;
    bookings.branchId = configStore.currentSelectedBranch._id
    Api.post(TRACK_BOOKINGS_URI, bookings, (data) => {
      if (data.status === 'Failed') {
        callBack()
        configStore.setModalMessage(data.msg)
        search(data)
      } else {
        callBack()
        configStore.setModalMessage(data.msg);
      }
    });
  }

  static async getFinanceDetails(Id) {
    try {
      let header = Api.H()
      header['Content-Type'] = 'application/pdf'
      header['Accept'] = '*/*'
      let response = await fetch(ENTITY_DETAILS + `${Id}`, { method: "GET", headers: header })
      const data = await response.json()
      return data
      } catch (error) {
      configStore.setModalMessage(error.message)
    }
  }

  static async getVerifiedVendors() {
    try {
      let header = Api.H()
      header['Content-Type'] = 'application/pdf'
      header['Accept'] = '*/*'
      let response = await fetch(VERIFIED_VENDOR_DETAILS, { method: "GET", headers: header })
      const data = await response.json()
      return data
      } catch (error) {
      configStore.setModalMessage(error.message)
    }
  }

  static async getDocumentType() {
    try {
      let header = Api.H()
      header['Content-Type'] = 'application/pdf'
      header['Accept'] = '*/*'
      let response = await fetch(GET_DOCUMENT_TYPE_URI, { method: "GET", headers: header })
      const data = await response.json()
      return data
      } catch (error) {
      configStore.setModalMessage(error.message)
    }
  }

  static async checkDriverStatus(phnumber) {
    try {
      let header = Api.H()
      header['Content-Type'] = 'application/pdf'
      header['Accept'] = '*/*'
      let response = await fetch(CHECK_DRIVER_STATUS_URI + `${phnumber}`, { method: "GET", headers: header })
      return await response.json()
    } catch (error) {

    }
  }

  static async endTrip(bookingId, tripId) {
    try {
      let header = Api.H()
      header['Content-Type'] = 'application/pdf'
      header['Accept'] = '*/*'
      let response = await fetch(END_TRIP_URI + `${bookingId}/${tripId}`, { method: "GET", headers: header })
      const data = await response.json()
      console.log("endTripResponse: " + data);
      configStore.modalMessage(data)
    } catch (error) {

    }
  }

  static getFCLCarrierList(callback = (result) => result) {
    fetch(`${GET_FCL_CARRIER_LIST_URI}`, { headers: Api.H() })
      .then((res) => res.json())
      .then((data) => {
        dataListStore["FCLCarrierList"] = data;
      })
      .catch((err) => {
        console.log("Url: " + GET_UNLOC_URI + " Err=", err);
        callback([]);
      });
  }

  static getFCLCarrierListspot(callback) {
    fetch(`${GET_FCL_CARRIER_LIST_URI}`, { headers: Api.H() })
      .then((res) => res.json())
      .then((data) => {
        callback(data)
      })
      .catch((err) => {
        console.log("Url: " + GET_UNLOC_URI + " Err=", err);
        callback([]);
      });
  }

  static getP44FCLCarrierList(callback = (result) => result) {
    fetch(`${GET_P44_FCL_CARRIER_LIST_URI}`, { headers: Api.H() })
      .then((res) => res.json())
      .then((data) => {
        dataListStore.P44CarrierList = data;
      })
      .catch((err) => {
        console.log("Url: " + GET_UNLOC_URI + " Err=", err);
        callback([]);
      });
  }


  static async getQuotationDocumentById(quoteNum) {
    let isFailed = false;
    let header = Api.H()
    header['Content-Type'] = 'application/pdf'
    header['Accept'] = '*/*'
    try {
      let a = await fetch(GET_QUOTATION_DOCUMENT_URI(quoteNum), { method: "GET", headers: header })
      a = await a.json()
      return a.data
    } catch (err) {
      configStore.setModalMessage(err?.error || err.toString())
    }
  }

  static async getQuotationDocumentByIdv2(quoteNum) {
    let isFailed = false;
    let header = Api.H()
    header['Content-Type'] = 'application/pdf'
    header['Accept'] = '*/*'
    try {
      let a = await fetch(GET_QUOTATION_DOCUMENT_URI1(quoteNum), { method: "GET", headers: header })
      a = await a.json()
      return a.data
    } catch (err) {
      configStore.setModalMessage(err?.error || err.toString())
    }
  }
  static async getQuotationDocumentByIdv3(quoteNum) {
    let isFailed = false;
    let header = Api.H()
    header['Content-Type'] = 'application/pdf'
    header['Accept'] = '*/*'
    try {
      let a = await fetch(GET_QUOTATION_DOCUMENT_URI2(quoteNum), { method: "GET", headers: header })
      a = await a.json()
      return a.data
    } catch (err) {
      configStore.setModalMessage(err?.error || err.toString())
    }
  }
  static async getCafDocumentById(CafId) {
    let isFailed = false;
    let header = Api.H()
    header['Content-Type'] = 'application/pdf'
    header['Accept'] = '*/*'
    try {
      let a = await fetch(GET_CAF_DOC_URI(CafId), { method: "GET", headers: header })
      a = await a.json()
      return a.data
    } catch (err) {
      configStore.setModalMessage(err?.error || err.toString())
    }
  }
  static async getCafFormDownload(CafId) {
    let isFailed = false;
    let header = Api.H()
    header['Content-Type'] = 'application/pdf'
    header['Accept'] = '*/*'
    try {
      let a = await fetch(GET_CAF_FORM_URI(CafId), { method: "GET", headers: header })
      a = await a.json()
      return a.data
    } catch (err) {
      configStore.setModalMessage(err?.error || err.toString())
    }
  }
  static async importQuotationsFromSheet() {
    const body = new FormData();
    body.append("file", quotatationImportStore.quotationSheetFile);
    body.append("entity", quotatationImportStore.entity);
    body.append("shipmentType", quotatationImportStore.selectedShipmentType);
    fetch(QUOTATIONS_SHEET_IMPORT_URI, {
      headers: { ...Api.H1() },
      method: "POST",
      body,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result?.error) throw result;
        configStore.setModalMessage(result?.message || result.toString());
        quotatationImportStore.reset();
        Api.getQuotes();
      })
      .catch((err) => {
        configStore.setModalMessage(err?.error || err.toString());
      });
  }

  static async deleteQuote(quoteNum) {
    fetch(DELETE_QUOTE_URI(quoteNum), {
      method: "DELETE",
      headers: { ...Api.H1() },
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.count > 0) {
          configStore.setModalMessage(`${quoteNum} got deleted successfully`);
        } else throw result;
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: Api.js ~ line 432 ~ Api ~ deleteQuote ~ error",
          error
        );
        configStore.setModalMessage(`Unable to delete quote ${quoteNum}`);
      });
  }


  static async updateCustomerReferenceId(objectId, customerReferenceId) {
    try {
      const data = {
        objectId: objectId,
        customerReferenceId: customerReferenceId
      }
      const response = await fetch(UPDATE_CUSTOMER_REFERENCE_URI, {
        method: "POST",
        headers: { ...Api.H1(), "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
      const message = await response.json()
      configStore.setModalMessage(message)
    } catch (error) {
      configStore.setModalMessage(
        `Unable to update Customer Reference ID`
      );
    }
  }

  static async updateFinanceInformation(id,finance) {
    try {
      const data = {
        entityId: id,
        financeInformation: finance,
      }
      const response = await fetch(UPDATE_FINANCE_INFORMATION_URI, {
        method: "POST",
        headers: { ...Api.H1(), "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
      const message = await response.json()
    } catch (error) {
      console.log(error);
    }
  }

  static async updateLogisticsInformation(obj) {
    try {
      
      const response = await fetch(UPDATE_LOGISTIC_INFORMATION_URI, {
        method: "POST",
        headers: { ...Api.H1(), "Content-Type": "application/json" },
        body: JSON.stringify(obj),
      })
      const message = await response.json()
    } catch (error) {
      console.log(error);
    }
  }

  static async addDocumentType(obj) {
    try {
      const response = await fetch(ADD_DOCUMENT_URL, {
        method: "POST",
        headers: { ...Api.H1(), "Content-Type": "application/json" },
        body: JSON.stringify(obj),
      })
      const message = await response.json()
    } catch (error) {
      console.log(error);
    }
  }

  static async updateDocumentById(objectId, files) {
    try {
      const data = {
        objectId: objectId,
        files: files
      }
      const response = await fetch(UPDATE_DOCUMENT_URI, {
        method: "POST",
        headers: { ...Api.H1(), "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
      const message = await response.json() 
      return message
      // if (str === 'del') {
      //   configStore.setModalMessage('File Deleted Successfully')
      // } else {
      //   // configStore.setModalMessage(message)
      // }
    } catch (error) {
      configStore.setModalMessage(
        `Unable to update files`
      );
    }
  }

  static async deleteBookings(bookingId) {
    try {
      let header = Api.H()
      header['Content-Type'] = 'application/pdf'
      header['Accept'] = '*/*'
      let response = await fetch(DELETE_BOOKING_URI + `${bookingId}`, { method: "GET", headers: header })
      const message = await response.json()
      return message;
      // callback()
      // deletePopup(message)
      // configStore.setModalMessage(message)
    } catch (error) {
      configStore.setModalMessage(
        `Unable to Delete Booking: ${error.message}`
      );
    }
  }

  static async updatePhoneNumber(bookingId, phoneNumber, tripId) {
    try {
      const data = {
        bookingId: bookingId,
        drivernumber: phoneNumber,
        tripId: tripId
      }
      const response = await fetch(UPDATE_PHONE_NUMBER_URI, {
        method: "POST",
        headers: { ...Api.H1(), "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
      const message = await response.json()
      configStore.setModalMessage(message)
    } catch (error) {
      configStore.setModalMessage(
        `Unable to update Phone Number: ${error.message}`
      );
    }
  }

  static async updateOutCome(quoteNum, outCome, callBack) {
    // Api.put(UPDATE_OUTCOME_STATUS_URI(quoteNum), {outCome}, (result) => {
    //     if(result?.modifiedCount > 0) configStore.setModalMessage(`Outcome Status Update successfully`);
    //     else throw result;
    // })
    fetch(UPDATE_OUTCOME_STATUS_URI(quoteNum), {
      method: "PUT",
      headers: { ...Api.H1(), "Content-Type": "application/json" },
      body: JSON.stringify({ outCome }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result?.modifiedCount > 0) {
          configStore.setModalMessage(`Outcome Status Update successfully`);
          callBack(result);
        } else throw result;
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: Api.js ~ line 465 ~ Api ~ updateOutCome ~ error",
          error
        );
        configStore.setModalMessage(
          `Unable to update quote outcome status ${quoteNum}`
        );
      });
  }

  static async getSchedulesList(quoteNum, resdata, callback = (f) => f) {
    Api.post(SCHEDULES_CONTRACT(quoteNum), resdata, (data) => {
      callback(data);
    });
  }

  static checkUnloc(unloc, callback = (f) => f) {
    Api.post(CHECKUNLOC, { unloc }, (d) => {
      callback(d);
    });
  }

  static async getmenucount() {
    localStorage.setItem("checkcount", "1");
    await Api.fetch1(MENUCOUNT, (data) => {
      menucountStore.setcount(data);
      localStorage.removeItem("checkcount");
    });
  }

  static getSchedulesLCLURL() {
    return GETLCLSCHEDULESMASTER;
  }

  static updateScheduleDeviations(payload) {
    Api.post(UPDATESCHEDULEDEVIATIONS, payload, (data) => {
      // callback(data);
    });
  }
  static getCustomClearenceFiles(entityId, callback = (f) => f) {
    Api.fetch(GETCUSTOMSCLEARENCEFILES(entityId), (data) => {
      callback(data);
    });
  }

  static checkKycTriggered(bookingId, callback = (f) => f) {
    Api.fetch(CHECKKYCTRIGGERED(bookingId), (data) => {
      callback(data);
    });
  }

  static triggerKycRequirement(bookingId, callback = (f) => f) {
    Api.fetch(TRIGGERKYCREQUIREMENT(bookingId), (data) => {
      callback(data);
    });
  }

  static uploadCustomClearenceFiles (payload){
    Api.post(UPLOADCUSTOMSCLEARENCEFILES, payload, (data) => {
      // callback(data);
    });
  }

  static uploadAdditionalDocumentFiles (payload){
    Api.post(UPLOADADDITIONALDOCUMENTFILES, payload, (data) => {
      // callback(data);
    });
  }
  static apieditdsr(payload, callback = (f) => f) {
    Api.post(EDITDSR, payload, (data) => {
      callback(data);
    });
  }

  static checkPhNumber(payload, callback = (f) => f) {
    Api.post(CHECKPHNUMBER, payload, (data) => {
      callback(data);
    });
  }

  static updateTracking(payload, callback = (f) => f) {
    Api.post(UPDATETRACKING, payload, (data) => {
      callback(data);
    });
  }

  static startTrip(payload, callback = (f) => f) {
    Api.post(STARTTRIP, payload, (data) => {
      callback(data);
    });
  }

  

  static apiAddCustomDetails(payload, callback = (f) => f) {
    Api.post(ADDCUSTOMDETAILS, payload, (data) => {
      // configStore.setModalMessage(data)
      // callback(data);
    });
  }

  static getproject44data(refreshObj, callback = (f) => f) {
      Api.post(GETPROJECT44, refreshObj, (data) => {
        console.log(data);
        // configStore.setModalMessage(data)
        callback(data);
      });
  }

  static getDpWorldData(refreshObj, callback = (f) => f) {
    Api.post(GETDPWORLD, refreshObj, (data) => {
      console.log(data);
      // configStore.setModalMessage(data)
      callback(data);
    });
}
  static getintermodata(id, callback = (f) => f) {
    Api.fetch(GETINTERMO(id), (data) => {
      callback(data);
    });
  }
  static getproject44BookingsData(id, callback = (f) => f, refresh) {
    try {
      Api.fetch(GETPROJECT44ID(id), (data) => {
        callback(data);
      });
    } catch (error) {
      configStore.setModalMessage('Something Went Wrong')
    }
  }
  // static postBinStacking(payload) {
  //   console.log("inise",payload);
  //   Api.post(BINSTACKING, payload, (data, error) => {
  //     console.log(data,error,"ssssssssss");
  //     // callback(data);
  //   });
  // }

  static getIntermoBookingsData(id, callback = (f) => f, refresh) {
    Api.fetch(GETINTERMOID(id), (data) => {
      callback(data);
    });
  }


  // ============================ Approval Api =============================
  static async approval_API(url, datacallback = (f) => f, errcallback = (f) => f) {
    let entity = "ALL";
    if (loginStore.isClient()) entity = configStore.currentSelectedEntity.gst;
    const requestOptions = {
      headers: Api.H(),
    };
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        //console.log('Url: ' + url + ' Ret=', JSON.stringify(data))
        if (data.error) {
          if (data.error != "No Error" || data.error != "Invalid Token") {
            if (data.ajvErrors) {
              configStore.setModalMessage(data.error, data.ajvErrors);
            } else {
              configStore.setModalMessage(data.error);
            }
          } else {
            localStorage.removeItem("checkcount");
          }
        } else {
          datacallback(data);
        }
      })
      .catch((err) => {
        if (err != "No Error" || err != "Invalid Token") {
          console.log("Communication Error. Url: " + url + " Err=", err);
          configStore.setModalMessage(
            "Server is not running. Please check browser console for specific error."
          );
        }
      });
  }

  static async cartingorderAcknowledge(id, callback = (f) => f) {
    Api.fetch(CARTINGORDER(id), (data) => {
      callback(data);
    });
  }

  static verifySbNummber(payload, callback = (f) => f) {
    Api.post(verifySbNo, payload, (data) => {
      console.log(data);
      // configStore.setModalMessage(data)
      callback(data);
    });
  }
  static getICEGATEData(payload, callback = (f) => f) {
    Api.post(getIcegateData, payload, (data) => {
      console.log(data);
      // configStore.setModalMessage(data)
      callback(data);
    });
  }
  static async getSbAndBeNo({id,type}, callback = (f) => f) {
    Api.fetch(GET_SB_BE_NO(id,type), (data) => {
      callback(data);
    });
  }
}



