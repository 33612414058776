
import { useEffect } from "react";
import cargoDetail from "../assets/images/worldwide-shipping.svg";
import { DetailsText2 } from "../mui-components/DetailsText";
import { ControlledDetailSwitch } from "./AgControlledDetailSwitch";
import { filtersState } from "../state/CommonState";
import { ShipmentScopeTag } from "../mui-components/ShipmentScope";
import { SHIPMENT_SCOPE } from "../util/constants";

const clearResults = () => {
    filtersState.initialize();
}

export function ServiceOptIns({ form, viewonly = { viewonly },applyDefault }) {
    const { control, watch, setValue, getValues } = form;
    const isFBA = watch("isFba")
    const mode = watch("mode")
    const ior = watch("ior");
    const occ = watch("occ");
    const dcc = watch("dcc");
    const survey = watch("survey");
    const storageAtDestination = watch("storageAtDestination");
    const palletizationandlabelling = watch("palletizationandlabelling");
    const showPalletLabel = watch("showPalletLabel");
    const multidest = getValues('multidest')
    const Scope = watch("scope")
    const pick_up_by_amazon = watch("pick_up_by_amazon")
    if (Scope.toLowerCase().indexOf("to-port") == -1 && pick_up_by_amazon != false) {
        setValue("pick_up_by_amazon", false)
    } else if (Scope.toLowerCase().indexOf("to-port") != -1 && isFBA == true && pick_up_by_amazon == false) {
        setValue("pick_up_by_amazon", true)
    }
    useEffect(() => {
        let l = 0
        if (multidest != undefined) {
            for (let i = 0; i < multidest.length; i++) {
                if (multidest[i].cargoDetails != undefined)
                    for (let j = 0; j < multidest[i].cargoDetails.length; j++) {
                        const currentType = multidest[i].cargoDetails[j].packageType;
                        if (currentType === "Loose Cartons") {
                            l = 1
                        }
                    }
            }
        }
        if (l == 0) {
            if (showPalletLabel) {
                setValue("showPalletLabel", false)
            }
        } else {
            if (!showPalletLabel) {
                setValue("showPalletLabel", true)
            }
        }
    }, [JSON.stringify(multidest)])

    useEffect(() => {
       
        if (!viewonly && applyDefault) {

        if((mode==="LCL") || (!isFBA && (mode.includes("FCL") || mode.toLowerCase()==="air")) ){
            if (Scope === SHIPMENT_SCOPE.p2p) {
                setValue("occ",false) 
                setValue("dcc",false)
            } else if (Scope === SHIPMENT_SCOPE.d2p) {
                setValue("occ",true)
                setValue("dcc",false)
            } else if (Scope === SHIPMENT_SCOPE.p2d) {
                setValue("occ",false)
                setValue("dcc",true)
            } else if(Scope===SHIPMENT_SCOPE.d2d) {
                setValue("occ",true)
                setValue("dcc",true)
            }
        }else{
            setValue("occ",false) 
            setValue("dcc",false)
        }
    }
    }, [Scope,mode,isFBA])

    useEffect(() => {
        if (!isFBA) {
            setValue("ior", false)
            setValue("survey", false)
            setValue("storageAtDestination", false)
            setValue("pick_up_by_amazon", false)
            setValue("days", 0)
        }

    }, [isFBA])
    return <div className="flex flex-col w-full rounded-md bg-white shadow-custom p-[18px] gap-[18px]">
        <div className="flex flex-row justify-between items-center">
            <div className="flex flex-row gap-[10px]">
                <img src={cargoDetail} />
                <p className="text-black font-bold">Service Opt-ins</p>
            </div>
        </div>
        <div className="grid grid-cols-3 place-content-between gap-2.5">
            {isFBA && <ControlledDetailSwitch control={control} disable={viewonly} formKey="ior" isChecked={ior} title="IOR Required?" onChange={clearResults} />}
            <ControlledDetailSwitch control={control} disable={viewonly} formKey="occ" isChecked={occ} title="Origin Customs Clearance" onChange={clearResults} />
            <ControlledDetailSwitch control={control} disable={viewonly} formKey="dcc" isChecked={dcc} title="Destination Customs Clearance" onChange={clearResults} />
            {isFBA && <ControlledDetailSwitch control={control} disable={viewonly} formKey="survey" isChecked={survey} title="Survey" onChange={clearResults} />}
            {isFBA && showPalletLabel && <ControlledDetailSwitch disable={palletizationandlabelling} control={control} formKey="palletizationandlabelling" isChecked={true} title="Palletization and labeling" onChange={clearResults} />}
            {isFBA && <ControlledDetailSwitch onChange={clearResults} control={control} disable={viewonly} formKey="storageAtDestination" isChecked={storageAtDestination} title="Storage at Destination?" />}
            {isFBA && pick_up_by_amazon && <ControlledDetailSwitch onChange={clearResults} control={control} disable={true} formKey="pick_up_by_amazon" isChecked={pick_up_by_amazon} title="Pick-up By Amazon" />}
            {isFBA && storageAtDestination && <DetailsText2 disabled={viewonly} label={"Number of days required"} fontSize="12px" size="small" fullwidth control={control} name={"days"} />}
        </div>
    </div>
}