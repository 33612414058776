import { useEffect, useState } from "react";
import { Chart } from "react-google-charts"
import { useSnapshot } from "valtio";
import { configStore, entityStore, loginStore } from "../state/CommonState";
import Utils from "../util/Utils";
import ChartApi from "./chart.api";
import ChartBookings from "./chart.bookings";
import { ChartBlueBox, ChartBlueHeading } from "./chart.common";
import ChartContracts from "./chart.contracts";
import ChartEnquiries from "./chart.enquiries";
import ChartFinance from "./chart.finance";
import ChartNewContracts from "./chart.newcontracts";
import { ChartOthers, Combo1 } from "./chart.others";
import PercentChart from "./chart.percent";
import ChartStatus from "./chart.status";
import chartStore from "./chart.store";
import { Combo2 } from "./chart1";
import { Combo3 } from "./chart2";
import { BsFillEyeFill } from "react-icons/bs";
import "./charts.css";
const getcliptext = (t, list) => {

    let o = ""

    if(!loginStore.isClient()) o=o+"Customer\t"
    if(t!='4') o=o+"Booking #\t"
    if(t=='4'){o=o+"Voucher #\t"}else if(t=='5'){o=o+"Voucher #\t"}else{o=o+"Invoice #\t"}
    if(t=='4'){o=o+"Receipt Date\t"}else if(t=='5'){o=o+"Voucher Date\t"}else{o=o+"Invoice Date\t"}
    if(t!='4' && t!='5') o=o+"Due Date\t"
    if(t=='1' || t ==  '2' || t == '3') o += "Credit Days\tCurrency\t" 
    if((t === '1' || t == '2' || t == '3')) o=o+"Outstanding Amount\tOutstanding Amount(INR)\t"
    if (t!='4' &&  t!='5') o=o+"Customer Reference #\t"
    if(t=='4') o=o+"Unmatched Credit(INR)\t"
    if(t == '1' || t == '4' || t == '2' || t == '3') o=o+"House Number\t"
     if (t == '1'  || t == '3') o = o + "Ageing\t"
    if (t == '2' ) o = o + "Document Ageing\t"
    if (t == '3') o = o + "Overdue Days\t"
    if (t == '5') o = o + "Amount(INR)\t"
    if (t=='4' || t=='5') o = o + "Remarks\t"
    o=o+'\n'

    list.map(e => {
         if(!loginStore.isClient()) o=o+e.CUSTOMER_NAME+"\t"
        if(t!='4') o=o+e.bookingNum+"\t"
         o=o+e.DOCUMENT_NO+"\t"
          o=o+Utils.getDateString(e['DOCUMENT_DATE'])+"\t"
        if(t!='4' && t!='5') o=o+Utils.getDateString(e['DUE DAYS'])+"\t"
        if(t=='1' || t ==  '2' || t == '3') o = o+(e.CREDIT_DAY? e?.CREDIT_DAY : "") +"\t"+e.CURRENCY_CODE +"\t" 
        if((t === '1' || t == '2' || t == '3')) o=o+e.OUTSTANDING_CURRENCY_AMOUNT+"\t" +e.OUTSTANDING_LOCAL_AMOUNT+ "\t"
        if (t!='4' &&  t!='5') o=o+e.customerReferenceNum+"\t"
        if(t=='4') o=o+e.OUTSTANDING_LOCAL_AMOUNT +"\t"
        if(t == '1' || t == '4' || t == '2' || t == '3') o=o+e.HOUSE_NO+"\t"
        if (t == '1'  || t == '3') o = o + Utils.docAgeing(e)+"\t"
        if (t == '2' ) o = o + Utils.docAgeing(e)  +"\t"
        if (t == '3') o = o + Math.abs(e.overduedays) +"\t"
        if (t == '4') o = o + e.NARRATION +"\t"
        if ( t=='5') o = o +e.OUTSTANDING_LOCAL_AMOUNT +"\t" + e.NARRATION +"\t"
        o=o+'\n'
    }
    )
    return o
}
export function FinanceList(props) {
    let t = props.type
    let list = []
    if (t == '1') list = props.results.totalList
    else if (t == '2') list = props.results.upcomingList
    else if (t == '3')list = props.results.overdueList
    else if (t == '4')list = props.results.pendinglist
    else if (t == '5')list = props.results.accountingHealthList
    let tableTitle = ''
    if (t == '1') tableTitle = 'Total Credit Utilized'
    else if (t == '2') tableTitle = 'Upcoming Dues'
    else if (t == '3')tableTitle = 'Overdue Amount'
    else if (t == '4')tableTitle = 'Pending Reconciliation'
    else if (t == '5')tableTitle = 'Accounting Error'
    console.log(list)
    return (
        <div style={{
           
            width: '100%',
            display: 'block', justifyContent: 'center',
            alignItems: 'center',
            overflowY:"scroll"
        }}>
            <div className="columns">
                <div className="column is-8">
                    <div className="title is-3" style={{ margin: 'auto', width: 'max-content' }}>{tableTitle}</div>
                </div>
                <div className="column is-2">
                    <button className="button is-warning" onClick={(e) => {
                        navigator.clipboard.writeText(getcliptext(t, list))
                        configStore.setModalMessage('Data copied to clipboard')
                    }}>Copy to Clipboard</button>
                </div>
                <div className="column is-1">
                    <button className="button islink"
                        onClick={(e) => {
                            props.setPopup(false)
                        }}>
                        <span className="icon ">
                            <i className="fas fa-xmark"></i>
                        </span>
                    </button>
                </div>
            </div>
            <div style={{ height: '70%',overflow:"scroll",  margin: 'auto', width: 'max-content' }}
            >
                <table className="table is-bordered is-striped is-narrow is-hoverable ">
                    <thead>
                        <tr>
                            {
                                (!loginStore.isClient()) ?
                                    <th>Customer</th>
                                    : <></>
                            }

                            {
                                (t!='4')?<th>Booking #</th>:<></>
                            }

                            
                            {
                                (t=='4')?
                                <>
                                        <th>Voucher #</th>
                                    </>:<>{
                                (t=='5')?
                                <>
                                        <th>Voucher #</th>
                                    </>:<><th>Invoice #</th></>
                            }</>
                            }
                            {
                                (t=='4')?<th>Receipt Date</th>:<>{(t=='5')?<th>Voucher Date</th>:<th>Invoice Date</th>}</>
                            }
                            {
                                (t!='4' && t!='5' )?<th>Due Date</th>:<></>
                            }
                           
                            {
                                (t == '1' || t ==  '2' || t == '3') ?
                                      <>
                                      <th>Credit Days</th>
                                        <th>Currency</th>
                                        </>
                                    
                                    :<></>
                            }
                            {
                                (t === '1' || t == '2' || t == '3') && (
                                    <>
                                    <th>Outstanding Amount</th>
                                        <th>Outstanding Amount(INR)</th>
                                    </>
                                    )
                            }
                            {
                                (t!='4' &&  t!='5') ? <th>Customer Reference #</th> : <></>
                            }
                            {
                                (t=='4' )?
                   
                                        <th>Unmatched Credit(INR)</th>
                                    :<></>
                            }
                           
                            {
                                (t == '1' || t == '4' || t == '2' || t == '3') ?
                                   
                                        <th>House Number</th>
                                    
                                    :<></>
                            }
                            {
                                (t == '1'  || t == '3') ?
                                <>
                                        <th>Ageing </th>
                                    </>  :<></>
                                
                            }
                            {
                                (t == '2' ) ?
                                <>
                                        <th>Document Ageing </th>
                                    </>  :<></>
                                
                            }
                          
                                                    {
                                (t=='3')?
                                <>
                                                              <th>Overdue Days</th>
                                    </>:<></>
                            }
                           
                            { t=='5' ? 
                                <th>Amount(INR)</th>
                                :<></>

                            }
                            {
                                (t=='4' || t=='5')?
                   
                                        <th>Remarks</th>
                                    :<></>
                            }
                           
                        </tr>
                    </thead>
                    <tbody>
                        {
                            list?.map(e => {
                                return (
                                    <tr>
                                        {
                                            (!loginStore.isClient()) ?
                                                <td>{e['CUSTOMER_NAME']}</td>
                                                : <></>
                                        }
                                        {
                                (t!='4')?<td>{e['bookingNum']}</td>:<></>
                            }
                            
                                        
                                        <td>{e['DOCUMENT_NO']}</td>
                                        <td style={{ textAlign: 'right' }}>{Utils.getDateString(e['DOCUMENT_DATE'])}</td>
                                        {/* <td>{e.Date.replace('-22', '-2022')}</td> */}

                                        {

                                (t!='4' && t!='5')?<td style={{ textAlign: 'right' }}>{Utils.getDateString(e['DUE DAYS'])}</td>:
                                <></>
                            }
                            {
                                (t == '1' || t == '2' || t == '3') ?
                                    <>
                                        <td style={{ textAlign: 'right' }}>{e['CREDIT_DAY']}</td>
                                    </>
                                    :<></>

                            }
                            {
                                (t == '1' || t == '2' || t == '3') ?
                                    <>
                                        <td style={{ textAlign: 'right' }}>{e['CURRENCY_CODE']}</td>
                                    </>
                                    :<></>
                            }

                       
                                        
                                        {
                                (t == '1'|| t == '2' || t == '3') ?
                                    <>
                                    <td style={{ textAlign: 'right' }}>{Utils.N2(e['OUTSTANDING_CURRENCY_AMOUNT'])}</td>
                                        <td style={{ textAlign: 'right' }}>{Utils.N2(e['OUTSTANDING_LOCAL_AMOUNT'])}</td>
                                    </>
                                    :<></>
                            }
                            {
                                (t != '4' && t != '5')? <td>{e['customerReferenceNum']}</td>:<></>
                            }
                            {
                                (t == '4' ) ?
                                    <>
                                        <td style={{ textAlign: 'right' }}>{Utils.N2(e['OUTSTANDING_LOCAL_AMOUNT'])}</td>
                                    </>
                                    :<></>
                            }
                          
                            {
                                (t == '1' || t == '4' || t == '2' || t == '3' ) ?
                                    <>
                                        <td style={{ textAlign: 'right' }}>{e['HOUSE_NO']}</td>
                                    </>
                                    :<></>
                            }
                            {
                                (t == '1' || t == '2' || t == '3') ?
                                    <>
                                        <td style={{ textAlign: 'right' }}>{Utils.docAgeing(e)}</td>
                                    </> :<></>
                            }
         
                                     {
                                (t=='3')?
                                <>
                                        <td style={{ textAlign: 'right' }}>{Math.abs(e['overduedays'])}</td>
                                    </>:<></>
                            }
                           
                            {
                                (t=='4')?
                   
                                <td style={{ textAlign: 'right' }}>{e['NARRATION']}</td>
                                    :<></>
                            }
                          
                            {
                                (t == '5')? 
                                <>
                                <td style={{ textAlign: 'right' }}>{e['OUTSTANDING_LOCAL_AMOUNT']}</td>
                                <td style={{ textAlign: 'right' }}>{e['NARRATION']}</td>
                                </>
                                :<></>
                            }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {/*<button className="button islink" onClick={(e) => props.setPopup(false)}>Close</button>*/}
            </div>
        </div>
    )
}
export function ChartNewHome() {
    useSnapshot(configStore)
    useSnapshot(chartStore)
    useSnapshot(entityStore)
    const [results, setResults] = useState({})
    const [popupType, setPopupType] = useState('1')
    const [popup, setPopup] = useState(false)
    const [entityFinance, setEntityFinance] = useState("ALL")
    const [chartSelectedEntities, setChartSelectedEntities] = useState([])
    // const [entList,setEntList] = useState(entityStore.list.sort((a, b) => {
    //     return compareStrings(a.entityName, b.entityName);
    //   }))
    const [entList,setEntList] = useState([])

    function compareStrings(a, b) {
        // Assuming you want case-insensitive comparison
        a = a?.toLowerCase();
        b = b?.toLowerCase();
        return (a < b) ? -1 : (a > b) ? 1 : 0;
    }
    useEffect(() => {
        ChartApi.getFinanceData(setResults , entityFinance)
    }, [entityFinance])
    useEffect(()=> {
        setEntList(results?.entityList) 
        let entityIds = []
        if (entityFinance === "ALL") {
            entityIds = results?.entityList?.filter(e => e.erpnumber && e.entityId)?.map(e => e.entityId) || []
            setChartSelectedEntities(entityIds)
        } 
    },[results])
    if (popup) {
        return <FinanceList type={popupType} results={results} setPopup={setPopup} />
    }
    if (chartStore.pieChartFull || chartStore.comboChartFull) {
        return (
            <div className="columns" style={{ height: '90vh' }}>
                <div className="column is-12" style={{height: "500px"}}>
                    <ChartBlueBox headingText='Product-wise Analysis' >
                        <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                            <ChartOthers />
                        </div>
                    </ChartBlueBox>
                </div>
            </div>
        )
    }

    const setFinanceCustomer = (value) => {
        let entityIds = []
        let customerCode = value.includes('/') ? value.split('/')[0] : value
        if (customerCode === "ALL") {
            entityIds = entList?.filter(e => e.erpnumber && e.entityId)?.map(e => e.entityId) || []
        } else {
            const selectedEntity = entList?.find(e => e.erpnumber === customerCode)
            if (selectedEntity?.entityId) {
                entityIds = [selectedEntity.entityId] 
            }
        }
    
        setChartSelectedEntities(entityIds)
        setEntityFinance(value)
    }
    
    

  
    return (
        <>
            <div className="columns" style={{flex:1}}>
                {
                    (configStore.currentSelectedEntity.isCreditCustomer && configStore.currentSelectedEntity.isCreditCustomer.toLowerCase() == 'no') ?
                        <div className="column is-1">
                        </div>
                        :
                        <div className="column is-3">
                            <div className="columns" style={{ height: '8%', padding: '5px' }}>
                                <ChartBlueHeading headingText='Finance'  />
                                {
                                    (!loginStore.isClient())?<div class="level-item level-left ml-6">
                                    <div class="field">
                                        <p class="control has-icons-left has-icons-right">
                                            <span class="select" style={{"width":"115px"}}> 
                                                <select name="status" id="status" style={{"padding-right": "2.5em","paddingLeft":"2.5em","maxWidth": "98%",}}
                                                  value={entityFinance} onChange={(e) => setFinanceCustomer(e.target.value ? e.target.value : "")}
                                                 >
                                                    <option value="ALL">ALL</option>
                                                    {
                                                       entList?.map(e=>{
                                                           return  <option value={ e?.erpnumber+'/'+e.entityName }>{e.entityName}</option> 
                                                       })
                                                    }
                                                    
                                                </select>
                                                 <span class="icon">
                                                    <i class="fa-solid fa-filter"></i>
                                                </span>
                                            </span>
                                        </p>
                                    </div>
                                </div>:<></>
                                }
                                
                            </div>
                            <div className="columns" style={{ height: '25%', padding: '5px', cursor: 'pointer' }}
                                onClick={(e) => {
                                    setPopup(true)
                                    setPopupType('1')
                                }}>
                                <ChartBlueBox >
                                    <div style={{ height: '10px' }}></div>
                                    <div style={{ fontSize: '2rem', fontWeight: 'bold', color: '#2c358a', marginTop: '0px' }}>
                                        {Utils.N1(results['totalCreditUtilized'])}
                                    </div>
                                    <div style={{ fontSize: '1.3rem', fontStyle: 'italic' }} id="mix1">Total Credit Utilized
                                    
                                    </div>
                                  
                                </ChartBlueBox>
                                {/* <span>
                                    <a
                                      href={
                                        process.env.REACT_APP_API_SERVER +
                                        "/api/v1/s3filestore/getfilefinance/" +
                                        "Debtors_Outstanding_Foreign_Currency_15.10.24.xlsx" +
                                        "/" +
                                        "Debtors_Outstanding_Foreign_Currency_15.10.24.xlsx"
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                      alt="">
                                    <BsFillEyeFill  />
                                    </a>
                                    </span> */}
                            </div>
                            <div className="columns" style={{ height: '25%', padding: '5px' }}
                            >
                                {
                                    (loginStore.isClient())?<ChartBlueBox >
                                    <div style={{ height: '10px' }}></div>
                                    <div style={{ fontSize: '2rem', fontWeight: 'bold', color: '#2c358a', marginTop: '0px',cursor:"pointer" }} onClick={(e) => {
                                    setPopup(true)
                                    setPopupType('4')
                                }}>
                                        {Utils.N1(results['pendingReconciliation'])}
                                    </div>
                                    <div style={{ fontSize: '1.3rem', fontStyle: 'italic',cursor:"pointer"}} id="mix2" onClick={(e) => {
                                    setPopup(true)
                                    setPopupType('4')
                                }}>Pending Reconciliation</div>
                                </ChartBlueBox>:<ChartBlueBox >
                                    <div style={{ height: '10px' }}></div>
                                    <div style={{ fontSize: '2rem', fontWeight: 'bold', color: '#2c358a', marginTop: '0px',cursor:"pointer" }} onClick={(e) => {
                                    setPopup(true)
                                    setPopupType('4')
                                }}>
                                        {Utils.N1(results['pendingReconciliation'])}
                                    </div>
                                    <div style={{ fontSize: '1.3rem', fontStyle: 'italic',cursor:"pointer" }} id="mix3" onClick={(e) => {
                                    setPopup(true)
                                    setPopupType('4')
                                }}>Pending Reconciliation</div>
                                    <div style={{ fontSize: '1.3rem', fontStyle: 'italic',cursor:"pointer"}} id="mix4"
                                    onClick={(e) => {
                                        setPopup(true)
                                        setPopupType('5')
                                    }}
                                    >Accounting Error</div>
                                </ChartBlueBox>
                                }
                            </div>
                            <div className="columns" style={{ height: '25%', padding: '5px', cursor: 'pointer' }}
                                onClick={(e) => {
                                    setPopup(true)
                                    setPopupType('2')
                                }}>
                                <ChartBlueBox >
                                    <div style={{ height: '10px' }}></div>
                                    <div style={{ fontSize: '2rem', fontWeight: 'bold', color: '#2c358a', marginTop: '0px' }}>
                                        {Utils.N1(results['upcomingDue'])}
                                    </div>
                                    <div style={{ fontSize: '1.3rem', fontStyle: 'italic' }} id="mix5">Upcoming Dues</div>
                                </ChartBlueBox>
                            </div>
                            <div className="columns" style={{ height: '25%', padding: '5px', cursor: 'pointer' }}
                                onClick={(e) => {
                                    setPopup(true)
                                    setPopupType('3')
                                }}>
                                <ChartBlueBox>
                                    <div style={{ height: '10px' }}></div>
                                    <div style={{ fontSize: '2rem', fontWeight: 'bold', color: '#f00', marginTop: '0px' }}>
                                        {Utils.N1(results['overdueAmount'])}
                                    </div>
                                    <div style={{ fontSize: '1.3rem', fontStyle: 'italic'}} id="mix6">Overdue Amount</div>
                                </ChartBlueBox>
                            </div>
                        </div>
                }
                <div className="column is-9">
                    <div className="columns" style={{ height: '25%', padding: '5px' }}>
                        <ChartBlueBox headingText='Bookings' >
                            <ChartBookings chartSelectedEntity={chartSelectedEntities}/>
                        </ChartBlueBox>
                    </div>
                    <div className="columns" style={{ height: '25%', padding: '5px' }}>
                        <ChartBlueBox headingText='Contracts'>
                            <ChartNewContracts chartSelectedEntity={chartSelectedEntities}/>
                        </ChartBlueBox>
                    </div>
                    <div className="columns" style={{ flex : 1, padding: '5px' }}>
                        <ChartBlueBox headingText='Product-wise Analysis' >
                            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <ChartOthers />
                            </div>
                        </ChartBlueBox>
                    </div>
                </div>
            </div>
        </>
    )
}
