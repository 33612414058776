import { Box, Button, Typography } from "@mui/material";
import { AttributeDetails } from "../mui-components/AttributeValueBox";
import { PricingDetailsCard } from "../mui-components/PricingDetailsCard";
import { useSnapshot } from "valtio";
import { LCLBookingPricingDetail } from "./LCLBookingPricingDetail";
import { AIRBookingPricingDetail } from "./AIRBookingPricingDetail";
import { FCLBookingPricingDetail } from "./FCLBookingPricingDetail";
import Utils from "../util/Utils";
import { useCallback, useEffect, useState } from "react";
import { ServerLists } from "../StaticData";
import { useFieldArray, useForm } from "react-hook-form";
import LabeledTextField from "../mui-components/LabeledTextField";
import destinationIcon from "../assets/images/destinationimg.svg";
import BookingsApi from "../state/bookings-api";
import { taskStore } from "../mytasks/task-store";
import cancel from "../assets/images/cancel.svg";
import { CancelBookingRequest } from "../mui-components/CancelBookingRequest";
import { enquiryStore } from "../enquiry/enquiry-data";
import { loader } from "../util/loader";
import Taskapi, { Task } from "../mytasks/tasks-api";
import { getPayload } from "../mytasks/tasks-static-data"
import { bookingsStore, breadCrumbStore, configStore, entityStore, viewBookingStore } from "../state/CommonState";
import { FbaDetails } from "../mui-components/FbaDetails";

const bpookingDetails = (isImport=false)=>{
  return [
    { label: "First Name", key: "firstName", type : "string" },
    { label: "Last Name", key: "lastName", type: "string" },
    { label: "Contact Email", key: "emailid", type: "string" },
    { label: "Contact Number", key: "phone" , type: "string"},
    { label: isImport ? "Consignee Name" : "Shipper Name", key: isImport ? "consignee" : "shipper" , type: "string"},
    { label: "Address", key: "address" , type: "string"},
    { label: "PIN / ZIP Code", key: "pin" , type: "string"},
    { label: "Country", key: "country" , type: "string"}, 
]

}
const commonDetails = [
    { label: "Special Instructions", key: "specialInstructions", type: "string" },
    { label: "Commodity Type", key: "commodityType", type: "string" },
    { label: "Incoterms", key: "incoTerms" , type: "string"},
    { label: "Merchant ID", key: "merchantID" , type: "string",notToShowDefault:true},
]

const serviceOptions = ()=>{
  return [
    {label: "Destination Customs Clearance", key: "fbaDCC", type: "string" },
    {label: "Origin Customs Clearance", key: "fbaOCC", type: "string" }
  ]
}

const consigneeDetails = (isExport)=>{
  return [
    { label:  isExport ? "Consignee Name" : "Shipper Name", key: "companyName", type : "string" },
    { label: "Address", key: "address" , type: "string"},
    { label: "State", key: "state", type: "string" },
    { label: "Country", key: "country" , type: "string"},
    { label: "PIN / ZIP Code", key: "pincode" , type: "string"},
    { label: "First Name", key: "firstName", type : "string" },
    { label: "Last Name", key: "lastName", type: "string" },
    { label: "Contact Email", key: "emailId", type: "string" },
    { label: "Contact Number", key: "mobileNumber" , type: "string"}, 
]
}


export function BookingDetailsComponent() {
   const booking = viewBookingStore.current
const [showConsignee,setShowConsignee] = useState(false)
const theMatch = Utils.extractCountry(booking?.booking?.contract?.origin);
const theDestinationMatch = Utils.extractCountry(booking?.booking?.contract?.destination);
const isExport = booking?.booking?.country === theMatch
const isImport = booking?.booking?.country === theDestinationMatch
const shipperDetailsKeys = bpookingDetails(isImport);
const consigneeDetailsKeys = consigneeDetails(isExport);
const isFbaBooking = booking.booking.fba && booking.booking.fba.toLowerCase() === "yes"
const defaultForm = useForm({
  defaultValues : isFbaBooking ? {
    fbaList : booking.booking.fbaDetails
  } : {fbaList : []}
})
const [open,setOpen] = useState(false);
const {control,trigger,setValue,getValues} = defaultForm

    return <Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "start",paddingX:"10px" }}>
       <Box sx={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"end",alignItems:"center",gap:2}}>
            {/* <BreadCrumb2 /> */}
{ booking.booking.status?.toLowerCase() === "submitted" && !viewBookingStore.commonView && Utils.isMyBooking(booking?.booking?.contract?.entityId) && <Button sx={{textTransform:"capitalize",backgroundColor:"#F25B5B",paddingX:"3px",color:"white",fontSize:"14px",height:"35px",fontFamily:"Figtree",width:"187px",borderRadius:"5px","&:hover":{
backgroundColor:"#F25B5B",color:"white"
}}} onClick={()=>{
                taskStore.current.actionName = 'Request for Booking Cancellation';
                setOpen(true)
            }} startIcon={<img src={cancel} />}>Request Cancellation</Button>}
        </Box>
      <Box sx={{display:"flex",flexDirection:"column",gap:1}}>
        <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"16px",color:"black"}}>Shipper Details</Typography>
        {isImport || !isExport ?  booking.Consignee ? 
         <AttributeDetails details={consigneeDetailsKeys.map((bookingDetail)=>{
            const objectToBeSearched =  booking.Consignee;
        return  {title:bookingDetail.label,value:objectToBeSearched[bookingDetail.key]}
      })} /> : <></> :
         <AttributeDetails details={shipperDetailsKeys.map((bookingDetail)=>{
              const objectToBeSearched = booking.booking;
          return  {title:bookingDetail.label,value:objectToBeSearched[bookingDetail.key]}
        })} />
}
     
      
      </Box>
    
       {  isExport && booking.Consignee  && <Box sx={{display:"flex",flexDirection:"column",gap:1}}>
      <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"16px",color:"black"}}>Consignee Details</Typography>
        <AttributeDetails details={consigneeDetailsKeys.map((bookingDetail)=>{
              const objectToBeSearched =  booking.Consignee;
          return  {title:bookingDetail.label,value:objectToBeSearched[bookingDetail.key]}
        })} /> 
      </Box>
}

{  isImport && <Box sx={{display:"flex",flexDirection:"column",gap:1}}>
      <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"16px",color:"black"}}>Consignee Details</Typography>
        <AttributeDetails details={shipperDetailsKeys.map((bookingDetail)=>{
              const objectToBeSearched =  booking.booking;
          return  {title:bookingDetail.label,value:objectToBeSearched[bookingDetail.key]}
        })} /> 
      </Box>
}
<Box sx={{display:"flex",flexDirection:"column",gap:1}}>
        <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"16px",color:"black"}}>Service Opt-ins</Typography>
         <AttributeDetails details={serviceOptions().map((bookingDetail)=>{
              const objectToBeSearched = booking.booking.contract;
          return  {title:bookingDetail.label,value:objectToBeSearched[bookingDetail.key],notToShowDefault:bookingDetail.notToShowDefault}
        })} />
        </Box>
      <Box sx={{display:"flex",flexDirection:"column",gap:1}}>
        <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"16px",color:"black"}}>Shipment Details</Typography>
         <AttributeDetails details={commonDetails.map((bookingDetail)=>{
              const objectToBeSearched = bookingDetail.nested ? booking.booking.contract : booking.booking;
          return  {title:bookingDetail.label,value:objectToBeSearched[bookingDetail.key],notToShowDefault:bookingDetail.notToShowDefault}
        })} />
        </Box>
          {/* {isFbaBooking &&   fbaList.map((fba,i)=> 
            <div key={fba} className="flex flex-col w-full gap-2">
            { fbaList.length > 1 && <div className={`flex flex-row w-full h-[35px] items-center border-[0.5px] border-solid border-[#DBDEFF] border-b-0 px-[12px] gap-2 rounded-t-md`} style={{background:"#DBDEFF"}}>
              <img src={destinationIcon}/>
              <p className="font-bold text-blue-dark">{`Destination ${i+1} : `}</p>
              <p className="text-sm">{i==0? booking.destination : booking[`destination${i+1}`]}</p>
              </div>}
            <div className="flex flex-row w-full gap-5">
              <LabeledTextField type="text" inputProps={{maxLength:12}} key={fba.id+"fba"} onChange={async(v)=>{
                let currentValue = `fbaList.${i}.fbaID`
                setValue(currentValue,v);
                
                  deBouncingCall(getValues("fbaList"),currentValue);
                
              }} fontSize="12px" height="34.25px" labelStyle={{fontSize:"12px",fontWeight:'bold',color:"black"}} fullwidth={true} rules={{required:"FBA ID is required",pattern: {
                value: /^[a-zA-Z0-9]+$/,
                message: "Only alphanumeric characters are allowed",
              },validate:(val)=>{
              if(val?.length>12){
                  return "Value should not exceed 12"
              }
            }}} placeholder="Enter your Fba Id" control={control} label="FBA ID" name={`fbaList.${i}.fbaID`}  />
            
  <LabeledTextField type="text" inputProps={{maxLength:8}} key={fba.id+"purchase"} onChange={async(v)=>{
                let currentValue = `fbaList.${i}.purchaseOrderID`
                setValue(currentValue,v);
                
                  deBouncingCall(getValues("fbaList"),currentValue);
                
              }} fontSize="12px" height="34.25px" labelStyle={{fontSize:"12px",fontWeight:'bold',color:"black"}} fullwidth={true} rules={{required:"Amazon Reference ID is required",pattern: {
                value: /^[a-zA-Z0-9]+$/,
                message: "Only alphanumeric characters are allowed",
              },validate:(val)=>{
              if(val?.length>8){
                  return "Value should not exceed 8"
              }
            }}} placeholder="Enter your Amazon Reference ID" control={control} label="Amazon Reference ID" name={`fbaList.${i}.purchaseOrderID`}  />
            </div>
                  </div>)} */}
                  {isFbaBooking && <FbaDetails form={defaultForm} />}
    {(!viewBookingStore.commonView &&  Utils.isMyBooking(booking?.booking?.contract?.entityId)) && <Box sx={{ width: "100%" }}>
        { booking.booking.contract?.shipmentType.indexOf("LCL")>=0 && <LCLBookingPricingDetail disableInclusion={true} contract={booking.booking.contract} />}
        { booking.booking.contract?.shipmentType.indexOf("Air")>=0 && <AIRBookingPricingDetail disableInclusion={true} contract={booking.booking.contract} />}
        { booking.booking.contract?.shipmentType.indexOf("FCL")>=0 && <FCLBookingPricingDetail disableInclusion={true}  contract={booking.booking.contract} />}
    </Box>}
    <Button sx={{textTransform:"capitalize",alignSelf:"end",backgroundColor:"#4ba7dd",color:"white","&:hover":{
      backgroundColor:"#4ba7dd",
      color:"white"
    }}} onClick={()=>{
     const rec = booking?.booking?.contract;
         let customTC = []
         if (rec['customTC']) {
             customTC = rec['customTC'].split(/\r?\n/)
         }
         rec['tc']?.map(e => customTC.push(e))
         rec['temptc']?.map(e => customTC.push(e))
         configStore.setModalMessage("", [], false, customTC)
    }}>View Terms and Condition </Button>
      <CancelBookingRequest onSubmit={async()=>{
            if (bookingsStore.requestCancellationReason === null || bookingsStore.requestCancellationReason == '') {
                configStore.setModalMessage(`Invalid Reason.`);
        }else{
            try{

                    loader.show();
                    taskStore.current = new Task('')
                    taskStore.current.bookingNum = booking._id
                    enquiryStore.initializeWithConract(booking.booking.contract);
                    taskStore.current.initialize(booking.booking, entityStore.entityRec)
                    bookingsStore.current = booking.booking
                    taskStore.current.actionName = "Request for Booking Cancellation"
                    Object.assign(taskStore.current, getPayload("Request for Booking Cancellation"))
                    taskStore.current.reason = bookingsStore.requestCancellationReason
                taskStore.current.entityId=booking.booking.entityId
                taskStore.current.branchId=booking.booking.branchId
                    bookingsStore.current._id = booking._id
                    bookingsStore.current.branchId = booking.booking.branchId

                    // taskStore.setCustomerComplete()
                    taskStore.current.stage = 1
                    taskStore.current.pendingWith = 'AGRAGA'
                    await Taskapi.saveTask(taskStore.current);
                BookingsApi.cancelBooking(booking._id, bookingsStore.requestCancellationReason,()=>{
                        loader.hide();
                        bookingsStore.requestCancellationReason = null;
                        setOpen(false);
                        breadCrumbStore.back();
                    });
            }catch(e){
                console.log(" the error is ",e);
                    configStore.setModalMessage("Something went wrong")
                    breadCrumbStore.back();
            }finally{
                    bookingsStore.requestCancellationReason = null;
                    setOpen(false);
                    loader.hide();
                }

            }
   }} open={open} onCancel={()=>{
            setOpen(false);
        }} />
    </Box>
}